import React, { FC } from 'react';
import { Icon24 } from '../../Icons/Icon';
import {
  Container,
  CtaButton,
  CtaTitle,
  LearnMoreContainer,
  LearnMoreTitle,
  LearnMoreText,
  LearnMoreGuarantee,
  LearnMoreButton,
  LearnMoreIcon,
} from './styles';

interface CustomTemplateCtaProps {
  onClick(): void;
  customButtonText?: string;
}

const CustomTemplateCta: FC<CustomTemplateCtaProps> = ({ onClick, customButtonText }) => {
  return (
    <Container>
      <CtaButton onClick={onClick}>
        <CtaTitle>Add a custom AI template to your workspace</CtaTitle>
      </CtaButton>

      <LearnMoreContainer>
        <LearnMoreTitle>
          <LearnMoreIcon>
            <Icon24.ChevronBadge />
          </LearnMoreIcon>
          Add-on
        </LearnMoreTitle>
        <LearnMoreText>
          Create a one of a kind AI template to instantly capture things you care about in a format
          that’s customized for you.
        </LearnMoreText>
        <LearnMoreGuarantee>Guaranteed or your money back</LearnMoreGuarantee>
        <LearnMoreButton onClick={onClick}>{customButtonText || 'Learn more'}</LearnMoreButton>
      </LearnMoreContainer>
    </Container>
  );
};

export default CustomTemplateCta;
