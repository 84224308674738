import React, { useRef, useState } from 'react';

const EditableText = ({
  onSave,
  text,
}: {
  onSave?: ((text: string) => void | Promise<void>) | null;
  text?: string;
}): JSX.Element => {
  const [isFocused, setIsFocused] = useState(!text);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    if (!onSave) return;
    await onSave(inputRef?.current?.value || 'Untitled');
    setIsFocused(false);
  };

  return (
    <>
      {!isFocused && text && (
        <div
          onClick={() => {
            if (!onSave) return;
            setIsFocused(true);
          }}
          className="my-2"
        >
          {text}
        </div>
      )}
      {(isFocused || !text) && (
        <div className="my-0.5">
          <input
            ref={inputRef}
            defaultValue={text}
            autoFocus={isFocused}
            onBlur={async () => {
              await handleSave();
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await handleSave();
              }
            }}
            className="appearance-none w-full text-primary-purple  py-2 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Folder name"
            readOnly={!onSave}
          />
        </div>
      )}
    </>
  );
};

export default EditableText;
