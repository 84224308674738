import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useNotes from '../../Hooks/useNotes';
import { useHistory } from 'react-router';
import {
  useDeleteDocument,
  useFetchDocument,
  useUpdateDocument,
  useCreateDocument,
} from '../../Hooks/useDocuments';
import { Loader } from '../../Components';
import Button from '../../Components/Button';
import SecondaryToolbar from '../../Components/SecondaryToolbar';
import Well from '../../Components/Well';
import { Tag } from '../../Models';
import { toast } from 'react-toastify';
import Avatar from '../../Components/Avatar';
import usePermissions from '../../Hooks/usePermissions';
import { TrashIcon, DocumentDuplicateIcon } from '@heroicons/react/outline';
import moment from 'moment';

import ParticipantPicker from '../../Components/ParticipantPicker';
import TagsPicker from '../../Components/TagsPicker';
import TipTapEditor from '../../Components/TipTapEditor';
import TagsPanel from '../../Components/TagsPanel/TagsPanel';
import {
  CalendarIconI,
  Details,
  DetailsRow,
  EditorContainer,
  EditorLeftPanel,
  EditorRightPanel,
  Label,
  LabelWithIcon,
  NameInput,
  TagIconI,
  UserIconI,
  UserName,
} from './styles';
import { useDebouncedCallback } from 'use-debounce';
import { HighlightsContext, HighlightsContextProvider } from '../../Context/HighlightsContext';
import NavigationPrompt from '../../Components/NavigationPrompt';
import { Editor } from '@tiptap/react';
import { SummaryFeedbackContextProvider } from '../../Context/SummaryFeedbackContext';
import useAnalytics from '../../Hooks/useAnalytics';
import { ProjectChecklistContext } from '../../Context/ProjectChecklistContext';

const DOCUMENT_EDITOR_VERSION = 2;

function Document(): JSX.Element {
  const { dashboardId, documentId } = useParams<{ dashboardId: string; documentId: string }>();
  const [editorInitialized, setEditorInitialized] = useState(false);
  const [hasNewHighlights, setHasNewHighlights] = useState(false); // set false when we have added flag on backend
  const [enableStopNavigation, setEnableStopNavigation] = useState(true);
  const [showReaddPrompt, setShowReaddPrompt] = useState(false);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const [loading, document] = useFetchDocument(documentId);
  const [editorContent, setEditorContent] = useState('');
  const { canEditDocuments } = usePermissions();
  const history = useHistory();
  const { analytics } = useAnalytics();

  const [updateDoc] = useUpdateDocument();
  const [deleteDoc] = useDeleteDocument();
  const [createDocument] = useCreateDocument();

  const { createNotesFromEntitiesWithTags } = useNotes(dashboardId);
  const { highlights, setHighlightsAddedToAnalysis, updateDocumentTags } = useContext(
    HighlightsContext
  );

  useEffect(() => {
    if (highlights) {
      setHasNewHighlights(
        !highlights.every((highlight) => {
          return highlight.addedToAnalysis === true;
        })
      );
    }
  }, [highlights]);

  useEffect(() => {
    analytics.sendEvent('pageView.Document', {
      id: documentId,
      projectId: dashboardId,
    });
  }, [analytics, dashboardId, documentId]);

  useEffect(() => {
    if (!loading && document) {
      setEditorContent(JSON.parse(document.content));
      setEditorInitialized(true);
    }
  }, [loading, document]);

  useEffect(() => {
    updateDocumentTags(document?.tagsList || []);
  }, [document?.tagsList]);

  async function createNotes(force = false) {
    if (highlights.length) {
      const toastId = toast.loading('Creating notes from highlighted text...');

      setEnableStopNavigation(false); // fix it when we have added flag on backend

      await createNotesFromEntitiesWithTags(highlights, {
        documentId,
        participantId: document.participantId,
        tags: document.tagsList,
        force: !hasNewHighlights,
      });

      setHighlightsAddedToAnalysis(highlights);

      toast.update(toastId, {
        render: 'Successfully created notes from document!',
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      });
    }
  }

  async function updateName(newName: string) {
    updateDoc(documentId, {
      name: newName,
    });
  }

  async function updateParticipant(participantId: string | null) {
    if (!canEditDocuments) return;
    updateDoc(documentId, {
      participantId,
    });
  }
  async function updateTags(tags: Array<Tag> | null) {
    updateDoc(documentId, {
      tagsDocuments: {
        deleteOthers: true,
        create: tags?.map((tag) => ({ tagId: tag.id })) || [],
      },
    });
    updateDocumentTags(tags || []);
  }

  const handleContentChange = useDebouncedCallback(async (content: any) => {
    await updateDoc(documentId, {
      content: JSON.stringify(content),
      contentVersion: DOCUMENT_EDITOR_VERSION,
    });
  }, 500);

  async function handleCopy() {
    if (!canEditDocuments) return;
    const doc = await createDocument(dashboardId, {
      content: document.content,
      name: document.name + ' copy',
      participantId: document.participantId,
      tagsDocuments: {
        create: document.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
      },
      createdBy: document.createdBy,
    });
    history.push(`/projects/${dashboardId}/data/docs/${doc.id}`);
  }

  async function handleDelete() {
    if (!canEditDocuments) return;
    if (!confirm(`Are you sure you want to delete "${document.name}"? This cannot be undone.`)) {
      return;
    }
    setEnableStopNavigation(false);
    await deleteDoc(dashboardId, documentId);

    analytics.sendEvent('DeleteDocument', {
      id: documentId,
    });
    history.push('/projects/' + dashboardId + '/data');
  }

  const addHighlightsToAnalysis = async () => {
    if (!canEditDocuments) return;
    if (!hasNewHighlights) {
      setShowReaddPrompt(true);
    } else {
      await createNotes();
      history.push('/projects/' + dashboardId + '/notes');
    }
  };

  const handleContainerRef = useCallback((node) => {
    setContainerElement(node);
  }, []);

  if (loading || !editorInitialized) {
    return <Loader />;
  }

  const createEnabled = !!highlights.length;
  return (
    <div className={'flex-col'}>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between border-b border-secondary-purple-light">
          <div>
            <h1 className={'text-l font-medium mt-1'}>
              <Link to={`/projects/${dashboardId}/data`}>Data</Link> /{' '}
              {document.name ?? 'Untitled document'}
            </h1>
          </div>
          {canEditDocuments && (
            <div className={'flex'}>
              <Button
                className={'mr-2 flex-inline'}
                onClick={() => handleCopy()}
                type={'secondary'}
              >
                <DocumentDuplicateIcon className={'w-4 h-4 mr-1'} />
                Copy
              </Button>
              <Button
                className={'mr-2 flex-inline'}
                onClick={() => handleDelete()}
                type={'secondary'}
              >
                <TrashIcon className={'w-4 h-4 mr-1'} />
                Delete
              </Button>
              {canEditDocuments && (
                <Button
                  className={createEnabled ? '' : 'pointer-events-none opacity-75'}
                  onClick={addHighlightsToAnalysis}
                >
                  Add highlights to Analysis
                </Button>
              )}
            </div>
          )}
        </div>
      </SecondaryToolbar>
      <EditorContainer className={'document-editor h-full'}>
        <EditorLeftPanel ref={handleContainerRef}>
          <Well wellKey="document-record-well">
            Take research notes, create an interview guide, or copy and paste research papers to
            analyze. Highlight and tag important parts to analyze then click “Add highlights to
            analysis.”
          </Well>
          <Details className="pb-4">
            <NameInput
              defaultValue={document?.name}
              onBlur={(e) => updateName(e.target.value)}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              placeholder="Untitled document"
              className={'text-3xl my-6 w-full'}
              autoFocus={!document?.name}
              readOnly={!canEditDocuments}
            />
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Created by</Label>
              </LabelWithIcon>
              <div className="flex items-center">
                <Avatar user={document?.userByCreatedBy} />
                <UserName>{document?.userByCreatedBy?.name || ''}</UserName>
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Participant</Label>
              </LabelWithIcon>
              <div>
                <ParticipantPicker
                  participant={document.participant}
                  onChange={updateParticipant}
                  readOnly={!canEditDocuments}
                />
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <CalendarIconI />
                <Label>Date</Label>
              </LabelWithIcon>
              <div className="cursor-pointer">
                <DatePicker
                  onChange={(date) => {
                    updateDoc(documentId, {
                      customDate: date,
                    });
                  }}
                  customInput={
                    <div>
                      {moment(document?.customDate || document?.createdAt).format('MM/DD/YYYY')}
                    </div>
                  }
                  selected={new Date(document?.customDate || document?.createdAt)}
                  popperPlacement="bottom"
                />
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <TagIconI />
                <Label>Tags</Label>
              </LabelWithIcon>
              <div>
                <TagsPicker
                  tags={document.tagsList}
                  onChange={updateTags}
                  dashboardId={dashboardId}
                  readOnly={!canEditDocuments}
                />
              </div>
            </DetailsRow>
          </Details>
          {containerElement && (
            <TipTapEditor
              content={editorContent}
              onChange={handleContentChange}
              editable={canEditDocuments}
              containerElt={containerElement}
              placeholder="Start taking notes..."
              withHighlights
              withToolbar
              getSummaryGenerationData={(editor: Editor | null) => ({
                content: editor ? editor.view.dom.innerText.split('.') : [],
                documentId,
              })}
            />
          )}
        </EditorLeftPanel>
        <EditorRightPanel>
          <TagsPanel editable={canEditDocuments} />
        </EditorRightPanel>
      </EditorContainer>

      <NavigationPrompt
        name="prompt-start-analysis"
        title="Ready to start analysis?"
        text="Click “Add to analysis” to add new highlights to the Analysis section of a project. 
          Every highlight becomes a sticky note on a canvas and row in a table to analyze."
        confirmationText="Yes, add to analysis"
        cancelText="Not yet"
        onConfirm={createNotes}
        when={hasNewHighlights && enableStopNavigation}
        locationAfterConfirm={'/projects/' + dashboardId + '/notes'}
      />

      <NavigationPrompt
        name="prompt-readd-highlights"
        title="Add highlights to analysis?"
        text="Highlights have already been added as notes to analysis. Do you want to add them again?"
        confirmationText="Yes, add to analysis"
        cancelText="Not yet"
        onCancel={() => setShowReaddPrompt(false)}
        onConfirm={() => createNotes(true)}
        when={showReaddPrompt}
        basedOnNavigation={false}
        locationAfterConfirm={'/projects/' + dashboardId + '/notes'}
      />
    </div>
  );
}

const DocumentPage = () => {
  const { dashboardId, documentId } = useParams<{ dashboardId: string; documentId: string }>();
  return (
    <HighlightsContextProvider
      key={dashboardId + documentId}
      dashboardId={dashboardId}
      documentId={documentId}
    >
      <SummaryFeedbackContextProvider>
        <Document key={dashboardId + documentId} />
      </SummaryFeedbackContextProvider>
    </HighlightsContextProvider>
  );
};

export default DocumentPage;
