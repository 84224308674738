import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import TagBlockComponent from './TagBlockComponent';

export interface NoteBlockOptions {
  HTMLAttributes: Record<string, any>;
  shared: boolean;
}

const TagBlock = Node.create<NoteBlockOptions>({
  name: 'tagBlock',
  group: 'block',
  content: 'inline*',

  addOptions() {
    return {
      HTMLAttributes: {},
      shared: false,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },
      dashboardId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-dashboard-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.dashboardId) {
            return {};
          }

          return {
            'data-dashboard-id': attributes.dashboardId,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `tag`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['tag', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TagBlockComponent);
  },
});

export default TagBlock;
