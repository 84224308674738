import styled from 'styled-components';

export const Container = styled.div<{ isActive: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 15px 20px 20px;
  border: 1px solid #d7d3dc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `

    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      bottom: -2px;
      right: -2px;
      border: 2px solid #382152;
      border-radius: 8px;
    }
  `}

  &:last-child {
    margin: 0;
  }
`;

export const ChevronIcon = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  right: -12px;
  top: -12px;
  color: #ffffff;
  background-color: #382152;
  z-index: 1;
`;

export const TemplateName = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  max-width: 172px;
  margin-right: 4px;
`;

export const TemplateDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);
`;

export const IconContainer = styled.div<{ src: string }>`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const TemplateCardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: rgba(59, 38, 81, 0.4);
`;

export const TemplateCardTitle = styled.div`
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const LikeIcon = styled.div<{ active?: boolean }>`
  color: ${({ active }) => (active ? '#FF6C6C' : 'rgba(59, 38, 81, 0.4)')};
  cursor: pointer;
`;

export const TemplateNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const DetailsButton = styled.div`
  padding: 10px 8px;
  border: 1px solid #d7d3dc;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 0px #d7d3dc;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const NameWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const Category = styled.div``;

export const IconWrapper = styled.div`
  margin-right: 5px;
  color: rgba(59, 38, 81, 0.5);
  opacity: 0.4;
`;
