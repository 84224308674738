import React, { FC, useContext, useEffect, useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { Container, Text } from './styles';
import VideoClip from '../../../VideoClip';
import { EvidenceContext } from '../../../../Context/EvidenceContext';
import Loader from '../../../Loader';

const NoteBlockComponent: FC = (props: any) => {
  const { isInitialized, findEvidenceById } = useContext(EvidenceContext);
  const [note, setNote] = useState<any | null>(null);

  useEffect(() => {
    if (isInitialized) {
      const note = findEvidenceById(props.node.attrs.id)?.note || null;
      note && setNote({ ...note, options: JSON.parse(note.options) });
    }
  }, [isInitialized]);

  return (
    <NodeViewWrapper>
      {!note ? (
        <Loader />
      ) : (
        <Container contentEditable={false}>
          {note?.transcription?.file?.id && (
            <VideoClip
              src={note.transcription?.file?.signedVideoUrl}
              offset={note.options?.timeOffset || 0}
            />
          )}
          <Text
            onClick={() => {
              const url = `${window.location.origin}/projects/${note?.dashboardId}/notes/${note?.id}`;
              window?.open(url, '_blank')?.focus();
            }}
          >
            {note?.text}
          </Text>
        </Container>
      )}
    </NodeViewWrapper>
  );
};

export default NoteBlockComponent;
