import { useEffect, useState } from 'react'

type MediaQueryResult = {
  isPhone: boolean,
  isTablet: boolean,
  isLaptop: boolean,
  isDesktop: boolean,
}

const useMediaQuery = (): MediaQueryResult => {
  const [matchMedia, setMatchMedia] = useState<MediaQueryResult>({
    isPhone: window.matchMedia('(max-width: 600px)').matches,
    isTablet: window.matchMedia('(max-width: 768px)').matches,
    isLaptop: window.matchMedia('(max-width: 1024px)').matches,
    isDesktop: window.matchMedia('(max-width: 1440px)').matches
  });

  useEffect(() => {
    const handleResize = () => {
      setMatchMedia({
        isPhone: window.matchMedia('(max-width: 600px)').matches,
        isTablet: window.matchMedia('(max-width: 768px)').matches,
        isLaptop: window.matchMedia('(max-width: 1024px)').matches,
        isDesktop: window.matchMedia('(max-width: 1440px)').matches
      })
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  return matchMedia;
}

export default useMediaQuery;