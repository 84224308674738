import React, { SyntheticEvent, useRef, useEffect } from 'react';
import useMinimizeOnScroll from '../Hooks/useMinimizeOnScroll';
import { TimeOffset } from '../Pages/Transcript/Transcript';

export default function VideoPlayer({
  src,
  timeOffset,
  disableMinimization,
  scrollingContainer,
}: {
  src: string;
  timeOffset: TimeOffset;
  disableMinimization?: boolean;
  scrollingContainer?: HTMLDivElement | null;
}): JSX.Element {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [isMinimized, playerRect, handleOnLoad] = useMinimizeOnScroll(
    playerRef,
    scrollingContainer
  );

  const onVideoLoad = (e: SyntheticEvent<HTMLVideoElement>) => {
    handleOnLoad();
    if (timeOffset.offset) e.currentTarget.currentTime = timeOffset.offset;
  };

  useEffect(() => {
    if (playerRef.current && timeOffset.offset) {
      playerRef.current.currentTime = timeOffset.offset;
      if (timeOffset.shouldPlay) {
        playerRef.current.play();
      }
    }
  }, [timeOffset]);

  return (
    <div className={' bg-white max-w-[800px] mx-auto'}>
      {isMinimized && !disableMinimization && (
        <div style={{ height: playerRect.height, width: '100%' }}></div>
      )}
      <video
        className="my-0"
        onLoadedData={onVideoLoad}
        ref={playerRef}
        style={
          !isMinimized || disableMinimization
            ? {
                width: '100%',
                zIndex: 3,
              }
            : {
                position: 'fixed',
                left: '12px',
                bottom: '72px',
                width: '20vw',
                zIndex: 3,
              }
        }
        controls
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
}
