export function humanFileSize(bytes: number | string, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  bytes = parseInt(bytes as string);

  if (!bytes) {
    return '';
  }

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export function formatDuration(seconds: number) {
  const minutes = Math.ceil(seconds / 60);

  if (minutes > 1) {
    return `${minutes} min`;
  }

  return `${seconds} sec`;
}
