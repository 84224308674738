import styled from 'styled-components';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
  `}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 999;
  `}
`;

export const Container = styled.div<{ isOpen: boolean; isGeneration: boolean; maxHeight: number }>`
  width: 100%;
  max-width: ${({ isGeneration }) => (isGeneration ? '1020px' : '680px')};
  background-color: #ffffff;
  border-radius: 6px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  transition: opacity 0.12s ease-in;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  display: flex;
  flex-direction: column;

  ${({ isGeneration, maxHeight }) =>
    isGeneration &&
    `
    max-height: ${maxHeight}px;
    height: 100%;
  `};

  ${({ isOpen }) =>
    isOpen &&
    `
    z-index: 9999;
    pointer-events: all;
    opacity: 1;
  `}
`;

export const Header = styled.div`
  width: 100%;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  margin-right: 10px;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
  flex-shrink: 0;

  &:last-child {
    margin: 0;
  }
`;

export const Content = styled.div<{ withoutPaddings?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  ${({ withoutPaddings = false }) =>
    !withoutPaddings &&
    `
    padding: 0 20px 20px;
  `}
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
