import React, { ReactNode } from 'react';
import TranscriptionHoursWidget from '../Components/TranscriptionHoursWidget';
import InviteTeamWidget from '../Components/InviteTeamWidget';
import usePermissions from '../Hooks/usePermissions';

import { Container, ContentContainer, Sidebar } from './ProjectsPageLayout.styles';

type DefaultLayoutProps = {
  children: ReactNode;
};

function ProjectsPageLayout({ children }: DefaultLayoutProps): JSX.Element {
  const { canEditTeam, canCreateData } = usePermissions();
  if (!canCreateData && !canEditTeam) return <ContentContainer>{children}</ContentContainer>;
  return (
    <Container>
      <Sidebar>
        {canCreateData && <TranscriptionHoursWidget className="mb-5" />}
        {canEditTeam && <InviteTeamWidget />}
      </Sidebar>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
}

export default ProjectsPageLayout;
