import React, { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useCloudRecordings } from '../../Hooks/useZoom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../Loader';
import moment from 'moment';
import Button from '../Button';
import { CloudFile } from '../../Models';
import { nanoid } from 'nanoid';
import SearchInput from '../SearchInput';
import languageOptions from '../../Consts/languageOptions';

import {
  ButtonWrapper,
  Buttons,
  CloseButton,
  Container,
  Controls,
  DatePickerInput,
  DatePickerText,
  FilterDate,
  FilterSearch,
  Filters,
  Header,
  Recording,
  RecordingDate,
  RecordingHeader,
  RecordingInfo,
  RecordingTitle,
  RecordingsContainer,
  SelectTitle,
  SelectWrapper,
  Title,
  Video,
  LoaderContainer,
} from './styles';
import { Icon24 } from '../../Icons/Icon';
import { Checkbox } from '@chakra-ui/react';
import Select from '../Select';
import { useLocalStorage } from 'usehooks-ts';
import { OptionTypeBase } from 'react-select';
import { add, differenceInDays, sub, subMonths } from 'date-fns';

interface ZoomListViewProps {
  onContinue: (files: CloudFile[], language: OptionTypeBase) => void;
  onCancel: () => void;
  onClose: () => void;
}

const ProcessingContainer = styled.div`
  color: var(--brand-hint-text, rgba(59, 38, 81, 0.4));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 160px;
  border-radius: 6px;
  background: rgba(215, 211, 220, 0.6);
`;

function toDateStr(a: Date | null) {
  return a?.toISOString().split('T').shift() ?? '';
}

const ZoomCloudRecordingsListView: React.FC<ZoomListViewProps> = ({
  onClose,
  onContinue,
  onCancel,
}) => {
  const today = new Date();

  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(subMonths(today, 1));
  const [endDate, setEndDate] = useState<Date | null>(today);
  const [selectedVideos, setSelectedVideos] = useState<CloudFile[]>([]);
  const [currentLanguage, setCurrentLanguage] = useLocalStorage<OptionTypeBase>(
    'dataTranscriptionLanguage',
    languageOptions[0]
  );

  const [loading, result, fetchMore, refetch] = useCloudRecordings(
    toDateStr(startDate),
    toDateStr(endDate)
  );

  useEffect(() => {
    if (result?.nextPageToken) {
      fetchMore(result?.nextPageToken);
    }
  }, [result]);

  interface CustomInputProps {
    value: string;
    onClick(): void;
  }
  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(({ value, onClick }, ref) => (
    <DatePickerInput onClick={onClick} ref={ref}>
      <Icon24.Calendar />
      <DatePickerText>{value || 'Anytime'}</DatePickerText>
      <Icon24.ChevronDown />
    </DatePickerInput>
  ));

  CustomInput.displayName = 'CustomInput';

  const handleChange = (range: [Date | null, Date | null]) => {
    const [startDate, endDate] = range;
    if (endDate && startDate) {
      const days = differenceInDays(endDate, startDate);
      setStartDate(startDate);
      setEndDate(days > 30 ? add(startDate, { days: 30 }) : endDate);
    } else {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const handleCheckboxChange = (checked: boolean, record: any) => {
    if (checked) {
      const status = record.transcript ? 'READY' : 'PROCESSING';
      setSelectedVideos([
        ...selectedVideos,
        {
          sourceId: nanoid(5),
          name: record.topic,
          size: record.size,
          source: 'zoom',
          mimeType: 'video/mp4',
          duration: record.duration,
          url: record.videoDownloadUrl,
          sourceUrl: record.videoDownloadUrl,
          status: status,
          transcript: record.transcript,
        },
      ]);
    } else {
      setSelectedVideos(selectedVideos.filter((v) => v.sourceUrl !== record.videoDownloadUrl));
    }
  };

  const visibleRecordings = useMemo(
    () =>
      result?.recordings
        ? result.recordings.filter((record: any) =>
            record?.topic?.toLowerCase().includes(searchText.toLowerCase())
          )
        : [],
    [result, searchText]
  );

  return (
    <Container>
      <Header>
        <Title>Import Videos</Title>
        <CloseButton onClick={onClose}>
          <Icon24.Close />
        </CloseButton>
      </Header>
      <Filters>
        <FilterSearch>
          <SearchInput value={searchText} onChange={setSearchText} />
        </FilterSearch>
        <FilterDate>
          <DatePicker
            customInput={React.createElement(CustomInput)}
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={today}
            selectsRange
          />
        </FilterDate>
      </Filters>
      {loading && (
        <LoaderContainer>
          <Loader isFullScreen={false} />
        </LoaderContainer>
      )}
      {!loading && visibleRecordings?.length === 0 && (
        <div className={'flex items-center min-h-[400px] justify-center pb-20'}>
          <div className={'text-center p-5'}>
            🧐 Hmm, we could not find any{' '}
            <a
              className={'underline'}
              href={'https://zoom.us/recording'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              Zoom cloud recordings
            </a>{' '}
            for this date range.
            <br />
            Try searching for a different 30-day date range.
            <br />
            <br />
            Make sure your Zoom account is connected in{' '}
            <a className={'underline'} href={'/settings'}>
              Settings
            </a>
            .
          </div>
        </div>
      )}
      {!loading && visibleRecordings?.length > 0 && (
        <RecordingsContainer>
          {visibleRecordings.map((record: any, index: number) => (
            <Recording key={index}>
              <RecordingHeader>
                <Checkbox
                  isChecked={
                    selectedVideos.find((v) => v.sourceUrl === record.videoDownloadUrl) !==
                    undefined
                  }
                  colorScheme="purple"
                  onChange={(e) => handleCheckboxChange(e.target.checked, record)}
                />
                <RecordingInfo>
                  <RecordingTitle>{record.topic}</RecordingTitle>
                  <RecordingDate>{record.duration}</RecordingDate>
                  <RecordingDate> {moment(record.startTime).format('MMM Do YYYY')}</RecordingDate>
                </RecordingInfo>
              </RecordingHeader>
              {record.videoDownloadUrl ? (
                <Video src={record.videoDownloadUrl} controls preload="none" />
              ) : (
                <ProcessingContainer>
                  This video is still processing. Check back in a few minutes.
                </ProcessingContainer>
              )}
            </Recording>
          ))}
        </RecordingsContainer>
      )}
      {!loading && visibleRecordings?.length > 0 && (
        <Controls>
          <SelectWrapper>
            <SelectTitle>Language</SelectTitle>
            <Select
              options={languageOptions}
              onChange={(option) => {
                if (!option) return;
                setCurrentLanguage(option);
              }}
              value={currentLanguage}
              isSearchable={true}
              elementWithDividerNumber={2}
            />
          </SelectWrapper>

          <Buttons>
            <ButtonWrapper>
              <Button type="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </ButtonWrapper>
            <Button
              type={'primary'}
              disabled={!selectedVideos.length}
              onClick={() => onContinue(selectedVideos, currentLanguage)}
            >
              Next, choose a Template
            </Button>
          </Buttons>
        </Controls>
      )}
    </Container>
  );
};

export default ZoomCloudRecordingsListView;
