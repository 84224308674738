import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #382152;
  opacity: 0.2;
  z-index: -1;
`

export const ModalContainer = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  padding: 40px 20px 20px;
  background-color: #ffffff;
  border-radius: 6px;
  z-index: 1;
`

export const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px;
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`

export const ConfirmButton = styled.div`
  padding: 12px 10px;
  background-color: #382152;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  margin-right: 10px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
`

export const CancelButton = styled.div`
  padding: 11px 10px;
  border: 1px solid #D7D3DC;
  box-shadow: 0px 1px 0px #D7D3DC, 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
`

export const CheckboxWrapper = styled.div`
  margin-bottom: 16px;
`
