import React, { FC, useEffect, useState } from 'react';
import { Icon24 } from '../../../Icons/Icon';
import Avatar from '../../Avatar';
import {
  Reaction,
  AvatarAndEmojis,
  EmojiItem,
  EmojiList,
  Emojis,
  EmojisPanel,
  InlineEmoji,
  Tooltip,
  TooltipMessage,
  TooltipName,
} from './styles';

const emojiList = [
  '🤔',
  '🧠',
  '👏',
  '🙌',
  '🤩',
  '🥳',
  '🎉',
  '💜',
  '🧐',
  '💎',
  '🍿',
  '😊',
  '😱',
  '👀',
  '💯',
  '🤌',
  '💋',
  '🤯',
  '✨',
  '🔥',
];

interface NewReactionButtonProps {
  emojis?: string[];
  currentUser?: { picture: string };
  readonly?: boolean;
  onSelect: (emoji: string) => void;
  onDelete?: () => void;
}

const NewReactionButton: FC<NewReactionButtonProps> = ({
  emojis,
  currentUser,
  readonly,
  onSelect,
  onDelete,
}) => {
  const [showPanel, setShowPanel] = useState(false);
  const buttonRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setShowPanel(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [buttonRef]);

  return (
    <Reaction
      onClick={() => !readonly && setShowPanel(!showPanel)}
      ref={buttonRef}
      isActive={!!emojis?.length}
    >
      <AvatarAndEmojis
        onClick={(e) => {
          e.stopPropagation();
          if (readonly) return;
          onDelete && emojis?.length ? onDelete() : setShowPanel(!showPanel);
        }}
        enableHover={!!emojis?.length}
      >
        <Avatar user={currentUser} />
        {!!emojis?.length && (
          <Emojis>
            {emojis?.map((emoji, index) => (
              <InlineEmoji key={index}>{emoji}</InlineEmoji>
            ))}
          </Emojis>
        )}

        {!!emojis?.length && (
          <Tooltip>
            <TooltipName>You</TooltipName>
            <TooltipMessage>Click to remove</TooltipMessage>
          </Tooltip>
        )}
      </AvatarAndEmojis>

      {!readonly && <Icon24.AddEmoji />}

      <EmojisPanel show={showPanel}>
        <EmojiList>
          {emojiList.map((emoji, index) => (
            <EmojiItem key={index} onClick={() => onSelect(emoji)}>
              {emoji}
            </EmojiItem>
          ))}
        </EmojiList>
      </EmojisPanel>
    </Reaction>
  );
};

export default NewReactionButton;
