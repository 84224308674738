import React, { FC } from 'react';
import Button from '../../Components/Button';

import {
  ButtonText,
  ButtonWrapper,
  Container,
  Overlay,
  ModalWindow,
  Content,
  Header,
  Title,
  CloseButton,
  Tags,
  Tag,
  TagName,
  TagCount,
  Text,
  Buttons,
  DotsDivider,
} from './styles';
import { Icon24 } from '../../Icons/Icon';
import { ID } from '../../Models';

export type Tag = {
  id?: ID;
  name: string;
  description?: string;
  color?: string;
  keywords?: string;
  tagsDocumentsConnection: {
    totalCount: number;
  };
  tagsNotesConnection: {
    totalCount: number;
  };
  tagsTranscriptionsConnection: {
    totalCount: number;
  };
};

interface DeleteTagModalProps {
  checkedTags: Tag[];
  onConfirm(): void;
  onCancel(): void;
}

const DeleteTagConfirmationModal: FC<DeleteTagModalProps> = ({
  checkedTags,
  onConfirm,
  onCancel,
}) => {
  const isLongTagList = checkedTags.length > 5;

  const renderTag = (tag: Tag) => {
    return (
      <Tag key={tag.id}>
        <TagName color={tag.color}>{tag.name}</TagName>
        <TagCount>
          {tag.tagsDocumentsConnection.totalCount +
            tag.tagsNotesConnection.totalCount +
            tag.tagsTranscriptionsConnection.totalCount}
        </TagCount>
      </Tag>
    );
  };

  const renderTags = () => {
    return isLongTagList ? (
      <>
        {checkedTags.slice(0, 2).map((tag) => renderTag(tag))}
        <DotsDivider>...</DotsDivider>
        {checkedTags.slice(checkedTags.length - 2, checkedTags.length).map((tag) => renderTag(tag))}
      </>
    ) : (
      checkedTags.map((tag) => renderTag(tag))
    );
  };

  return (
    <Container>
      <Overlay />
      <ModalWindow>
        <Header>
          <Title>Delete tags</Title>
          <CloseButton onClick={onCancel}>
            <Icon24.Close />
          </CloseButton>
        </Header>
        <Content>
          <Tags>{renderTags()}</Tags>
          <Text>
            All instances will be un-tagged and the tags will be permanently deleted. This cannot be
            undone.
          </Text>
          <Buttons>
            <ButtonWrapper>
              <Button className="text-red-500" type="secondary" onClick={onConfirm}>
                <Icon24.Trash />
                <ButtonText>Delete</ButtonText>
              </Button>
            </ButtonWrapper>

            <ButtonWrapper>
              <Button type="secondary" onClick={onCancel}>
                <ButtonText>Cancel</ButtonText>
              </Button>
            </ButtonWrapper>
          </Buttons>
        </Content>
      </ModalWindow>
    </Container>
  );
};

export default DeleteTagConfirmationModal;
