import { Node, mergeAttributes } from '@tiptap/core';

import { ReactNodeViewRenderer } from '@tiptap/react';
import HighlightTagComponent from './HighlightTagComponent';
import { Ref } from 'react';

export interface TagOptions {
  HTMLAttributes: Record<string, any>;
  parentContainer?: HTMLDivElement | null;
  editorContainerRef?: Ref<HTMLDivElement>;
  editable?: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tag: {
      setHighlightTag: (attributes?: { id: string; type: 'tag' | 'highlight' }) => ReturnType;
      unsetHighlightTag: () => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

type NodeText = {
  text: string;
  pos: number;
  nodeSize: number;
  hasHighlight: boolean;
};

const HighlightTag = Node.create<TagOptions>({
  name: 'highlightTag',
  group: 'inline',
  inline: true,
  content: 'text*',

  addOptions() {
    return {
      HTMLAttributes: {},
      parentContainer: null,
      editable: true,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },
      serialNumber: {
        default: null,
        parseHTML: (element: HTMLElement) => Number(element.getAttribute('data-number')),
        renderHTML: (attributes: any) => {
          if (!attributes.serialNumber) {
            return {};
          }

          return {
            'data-number': attributes.serialNumber,
          };
        },
      },
      newTag: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
      0,
    ];
  },

  addCommands() {
    return {
      setHighlightTag: (attributes) => ({ chain, view }) => {
        const { from, to } = view.state.selection;
        const selectedTexts: NodeText[] = [];

        let listItemCounter = 0;
        let isSummaryNode = false;
        view.state.selection.content().content.nodesBetween(0, to - from, (node, pos) => {
          if (node.isBlock) {
            let resultText = '';
            let nodeSize = 0;
            let hasHighlight = false;

            if (node.type.name === 'listItem') {
              listItemCounter += 2;
            }

            if (node.type.name === 'summary') {
              isSummaryNode = true;
            }

            node.content.forEach((innerNode) => {
              if (innerNode.isText && innerNode.text?.length) {
                resultText += innerNode.text;
              } else if (innerNode.type.name === this.name) {
                resultText += innerNode.content.firstChild?.text || '';
                hasHighlight = true;
              }
              nodeSize += innerNode.nodeSize;
            });

            if (!resultText) return;
            let currentPos = pos;
            let currentNodeSize = nodeSize;

            if (listItemCounter) {
              currentPos = currentPos - listItemCounter;
              currentNodeSize += 2;
              listItemCounter -= 2;
            }

            if (isSummaryNode) {
              currentPos = currentPos - 1;
            }

            selectedTexts.push({
              text: resultText,
              pos: currentPos,
              nodeSize: currentNodeSize,
              hasHighlight,
            });
          }
        });

        let counter = 0;
        selectedTexts.forEach((selectedText, index) => {
          const params = {
            type: this.name,
            attrs: { ...attributes, newTag: attributes?.type === 'tag', serialNumber: index },
            content: [
              {
                type: 'text',
                text: selectedText.text,
              },
            ],
          };

          chain()
            .focus()
            .insertContentAt(
              {
                from: from + selectedText.pos + counter,
                to: from + selectedText.pos + selectedText.nodeSize + counter,
              },
              params
            )
            .run();

          if (!selectedText.hasHighlight) {
            counter += 2;
          }
        });
        return chain()
          .focus()
          .setTextSelection(from + 1)
          .run();
      },

      unsetHighlightTag: () => ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from } = selection;

        if ($from.parent.type !== this.type) {
          return false;
        }

        let counter = 0;
        let editorUpdateChain = editor.chain();
        state.doc.content.nodesBetween(0, state.doc.content.size, (node, pos) => {
          if (node.attrs.id === $from.parent.attrs.id) {
            editorUpdateChain = editorUpdateChain.command(({ tr }) => {
              tr.insertText(node.textContent, pos - counter, pos + node.nodeSize - counter);
              return true;
            });
            counter += 2;
          }
        });
        editorUpdateChain.run();
        return true;
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        if ($from.pos === $from.start()) {
          editor
            .chain()
            .command(({ tr }) => {
              tr.delete($from.pos - 2, $from.pos);
              return true;
            })
            .run();
          return true;
        }

        return false;
      },
      // exit node on double enter
      Space: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const endsWithSpace = $from.parent.textContent.endsWith(' ');

        if (endsWithSpace) {
          editor
            .chain()
            .focus()
            .setTextSelection($from.end() + 1)
            .run();
          editor
            .chain()
            .command(({ tr }) => {
              tr.delete($from.pos - 1, $from.pos);
              tr.insertText(' ');
              return true;
            })
            .run();
          return true;
        }
        return false;
      },

      Enter: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from, empty, from } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;
        const isAtStart = $from.parentOffset === 0;

        if (isAtEnd) {
          return editor.commands.setHardBreak();
        } else if (isAtStart) {
          return editor
            .chain()
            .setTextSelection(from - 1)
            .setHardBreak()
            .setTextSelection(from + 1)
            .run();
        }

        const text = editor.state.doc.textBetween(from, $from.end());
        const params = {
          type: this.name,
          attrs: { ...$from.parent.attrs, serialNumber: $from.parent.attrs.id + 1 },
          content: [
            {
              type: 'text',
              text: text,
            },
          ],
        };

        return editor
          .chain()
          .command(({ tr }) => {
            tr.delete(from, $from.end());
            return true;
          })
          .setHardBreak()
          .insertContent(params)
          .setTextSelection(from + 3)
          .run();
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(HighlightTagComponent);
  },
});

export default HighlightTag;
