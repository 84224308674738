import styled from 'styled-components';

export const IconWrapper = styled.div`
  margin-right: 4px;
`;

export const BetaBadge = styled.div`
  padding: 0 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  background-color: #d8c0f8;
  border-radius: 3px;
  margin-left: 8px;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #efedf1;
  border-radius: 20px;
  margin-left: 8px;
`;

export const ButtonIconWrapper = styled.div`
  margin-left: 4px;
`;

export const LabelIconWrapper = styled.div`
  margin-right: 4px;
`;
