import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
  `}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 999;
  `}
`;

export const Container = styled.div<{ isOpen: boolean; maxHeight: number }>`
  width: 100%;
  height: 100%;
  max-width: 1020px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  background-color: #ffffff;
  border-radius: 6px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  transition: opacity 0.12s ease-in;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
    z-index: 9999;
    pointer-events: all;
    opacity: 1;
  `}

  ${breakpoint.Mobile`
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    border-radius: 0;
  `}
`;

export const ContentWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const Sidebar = styled.div`
  width: 250px;
  border-right: 1px solid #d7d3dc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${breakpoint.Mobile`
    display: none;
  `}
`;

export const SidebarTop = styled.div`
  width: 100%;
`;

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 20px 20px 0;
`;

export const BackIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 20px;
  cursor: pointer;
`;

export const MainTitle = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
`;

export const SidebarCategory = styled.div`
  padding: 5px 20px;
`;

export const SidebarButton = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 6px;

  ${({ active }) =>
    active &&
    `
    background-color: rgba(56, 33, 82, 0.1);
  `}
`;

export const SidebarBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  position: relative;
  flex: 1;
  padding: 16px 20px 30px;
  overflow-y: auto;
`;

export const BottomButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  border-top: 1px solid #d7d3dc;

  ${breakpoint.Mobile`
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  `}
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-right: 20px;

  ${breakpoint.Mobile`
    margin-bottom: 10px;
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GenerateButton = styled.div<{ disabled?: boolean }>`
  padding: 12px 10px;
  background-color: #382152;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    background-color: rgba(59, 38, 81, 0.4);
  `}
`;

export const CancelButton = styled.div`
  padding: 11px 10px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
`;

export const NewBadge = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-left: 10px;
  padding: 3px 10px;
  background: linear-gradient(90deg, #d1deff 0%, #d8c0f8 100%);
  border-radius: 6px;
`;

export const CreditsLeft = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
`;

export const GetMoreCredits = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #d9d9d9;
  margin-bottom: 8px;
`;

export const ProgressBarLine = styled.div<{ scaleRatio: number }>`
  height: 4px;
  width: 100%;
  background-color: #3b2651;
  transform-origin: 0 center;
  transform: scaleX(${({ scaleRatio }) => (scaleRatio > 1 ? 1 : scaleRatio)});
  transition: transform 0.2s ease;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7d3dc;
  margin: 20px 0;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  padding: 20px 20px 4px;
  border-radius: 0 6px 0 0;
`;

export const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
  color: rgba(59, 38, 81, 0.5);
  opacity: 0.4;
`;
