import React from 'react';

const TextIcon = (): JSX.Element => (
  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16995 2.47596H13.2898V4.5359H15.3498V1.44599C15.3498 1.17282 15.2413 0.910844 15.0481 0.717687C14.8549 0.52453 14.593 0.416016 14.3198 0.416016H1.96015C1.68698 0.416016 1.425 0.52453 1.23185 0.717687C1.03869 0.910844 0.930176 1.17282 0.930176 1.44599V4.5359H2.99012V2.47596H7.11V15.5223H4.36341V17.5822H11.9165V15.5223H9.16995V2.47596Z"
      fill="#3B2651"
    />
    <path
      d="M19.4968 14.7701V12.0888H22.5868V10.0288H19.4968V7.28223H17.4369V10.0288H14.6903V12.0888H17.4369V14.7701C17.4369 15.5159 17.7331 16.2311 18.2605 16.7584C18.7878 17.2857 19.503 17.5819 20.2487 17.5819H22.9301V15.522H20.2487C20.0493 15.522 19.8581 15.4428 19.7171 15.3018C19.5761 15.1608 19.4968 14.9695 19.4968 14.7701Z"
      fill="#3B2651"
    />
  </svg>
);

export default TextIcon;
