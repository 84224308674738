import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import Button from '../Components/Button';
import usePermissions from '../Hooks/usePermissions';
import { checkOrganizationLimits, useOrganization } from '../Hooks/useOrganization';
import useBoards from '../Hooks/useBoards';
import { useHistory } from 'react-router-dom';
import useAnalytics from '../Hooks/useAnalytics';
export function NewProjectButton(): JSX.Element {
  const history = useHistory();
  const { analytics } = useAnalytics();
  const [, org] = useOrganization();
  const { canCreateProjects } = usePermissions();
  const { createBoard } = useBoards();

  const handleCreateBoard = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();

    if (!checkOrganizationLimits(org, history, ['projects'])) {
      return false;
    }

    const dashboard = await createBoard('');

    analytics.sendEvent('CreateProject', { id: dashboard.id });

    history.push(`/projects/${dashboard.id}/data`);
  };
  return (
    <>
      {canCreateProjects && (
        <Button onClick={handleCreateBoard}>
          <PlusIcon className="text-white w-5 h-5" />
          <div className="pl-2">New Project</div>
        </Button>
      )}
    </>
  );
}
