import React, { FC, useContext } from 'react';
import { UploadFilesContext } from '../../Context/UploadFilesContext';
import PortalNew from '../PortalNew';
import { UploadFileModalMinimized } from '../UploadFileModal';

import { PopupContainer } from './styles';

const PopupManager: FC = () => {
  const { uploadingFiles, filesUploadModalOpen, cancelUploading } = useContext(UploadFilesContext);
  const isOpen =
    !!uploadingFiles.filter((item) => item.isUploading).length && !filesUploadModalOpen;

  return (
    <PortalNew wrapperId="uploadFileModalMinimized">
      <PopupContainer show={isOpen}>
        {uploadingFiles.map((item) => (
          <UploadFileModalMinimized
            key={item.file.name}
            fileName={item.file.name}
            fileSize={item.file.size}
            progress={item.uploadingProgress}
            onCancel={() => cancelUploading(item.file.name)}
          />
        ))}
      </PopupContainer>
    </PortalNew>
  );
};

export default PopupManager;
