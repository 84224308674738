import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { DropDownMenu } from './DropDownMenu';
import { useFetchBoard } from '../Hooks/useBoards';
import { exportToCsv } from '../Utils/exportToCSV';
import { useApolloClient } from '@apollo/client';
import { FETCH_NOTES, FETCH_PARTICIPANTS, FETCH_TAGS, FETCH_THEMES } from '../GraphQL/queries';
import { parse } from 'csv-parse/browser/esm/sync';
import useNotes from '../Hooks/useNotes';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/browser';
import { isNil, uniq } from 'lodash-es';
import { useCreateParticipant } from '../Hooks/useParticipants';
import QuestionMarkIcon from '../Icons/QuestionMarkIcon';
import { STICKY_WIDTH, Tag } from '../Models';
import { useCreateTag } from '../Hooks/useTags';
import useThemes from '../Hooks/useThemes';
import { calcStartPosition, calcThemeSizeFromStickies } from '../Utils/canvas';
import usePermissions from '../Hooks/usePermissions';

type Props = {
  dashboardId: string;
  getCanvas: () => string | undefined;
};

type ImportFromCSVRecord = {
  content?: string;
  note?: string;
  text?: string;
  tags?: string;
  date?: string;
  participant?: string;
  theme?: string;
};

const getNoteText = (record: ImportFromCSVRecord): string | undefined => {
  return record.note || record.content || record.text;
};

const getSentimentLabel = (sentimentScore?: number): string | null | undefined => {
  if (isNil(sentimentScore)) return sentimentScore;
  if (sentimentScore > 0.5) return 'positive';
  if (sentimentScore < -0.5) return 'negative';
  return 'neutral';
};

export default function ExportMenu({ dashboardId, getCanvas }: Props): JSX.Element {
  const [loadingDashboard, dashboard] = useFetchBoard(dashboardId);
  const { createNote } = useNotes(dashboardId);
  const { createTheme } = useThemes(dashboardId);
  const [createParticipant] = useCreateParticipant();
  const { canCreateNotes } = usePermissions();
  const createTag = useCreateTag();
  const client = useApolloClient();
  const handleExportToCsv = async () => {
    const {
      data: { notes },
    } = await client.query({
      query: FETCH_NOTES,
      variables: {
        condition: {
          dashboardId,
        },
      },
    });

    exportToCsv(`${dashboard.name}.csv`, [
      ['content', 'theme', 'tags', 'participant', 'sentiment'],
      ...notes.map((note: any) => [
        note.text,
        note.theme?.name || '',
        (note.tagsList?.map((tag: Tag) => tag.name) || []).join(', '),
        note.participant?.name || '',
        getSentimentLabel(note.sentimentScore),
      ]),
    ]);
  };

  const handleExportToPng = async () => {
    // getCanvas
    const base64image = getCanvas();
    if (!base64image) return;
    const link = document.createElement('a');
    link.download = `${dashboard.name}.png`;
    link.href = base64image;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImportFromCsv = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      if (!target.files || !target.files.length) return;

      const file = target.files[0];

      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');

      reader.onload = async (readerEvent) => {
        let content = readerEvent?.target?.result as string;
        if (!content) return;
        const toastId = toast.loading('Importing notes...');

        try {
          let additionalRecord = null;
          if (isMiroFormat(content)) {
            content = convertFromMiroFormat(content);
          }
          const records: any[] = parse(content, {
            columns: (header) => {
              const columns: string[] = header.map((column: string) => column.toLowerCase());
              const hasHeader = ['note', 'content', 'text'].some((r) => columns.includes(r));
              if (!hasHeader) {
                additionalRecord = { note: columns[0] };
                return ['note', ...columns];
              }
              return columns;
            },
            skip_empty_lines: true,
            relax_column_count: true,
          });

          // handle issue with columns option removing header row
          if (additionalRecord) records.push(additionalRecord);

          const newNotesData: any[] = [];

          const participantNames = uniq(
            records
              .filter((record: ImportFromCSVRecord) => getNoteText(record) && record.participant)
              .map((record: ImportFromCSVRecord) => {
                return record.participant;
              })
          );

          let participants: { id: string; name: string }[] = [];

          if (participantNames?.length > 0) {
            const {
              data: { participants: existingParticipants = [] },
            } = await client.query({
              query: FETCH_PARTICIPANTS,
              fetchPolicy: 'network-only',
            });

            const newParticipantNames = participantNames.filter(
              (name) =>
                !existingParticipants.some(
                  (participant: { name: string }) =>
                    participant.name?.toLowerCase() === name?.toLowerCase()
                )
            );

            const newParticipants = await Promise.all(
              newParticipantNames.map((name) => createParticipant({ name }))
            );

            participants = [...existingParticipants, ...newParticipants];
          }

          const themeNames: string[] = uniq(
            records
              .filter((record: ImportFromCSVRecord) => getNoteText(record) && record.theme)
              .map((record: ImportFromCSVRecord) => {
                return record.theme || '';
              })
          );

          let existingThemes: any[] = [];

          if (themeNames.length > 0) {
            existingThemes =
              (
                await client.query({
                  query: FETCH_THEMES,
                  variables: {
                    dashboardId,
                  },
                  fetchPolicy: 'network-only',
                })
              )?.data?.themes || [];
          }

          const tagNames: string[] = uniq(
            records
              .filter((record: ImportFromCSVRecord) => getNoteText(record) && record.tags)
              .map((record: ImportFromCSVRecord) => {
                return record?.tags?.split(',') || [];
              })
              .flat()
          );

          let existingTags: any[] = [];

          if (tagNames.length > 0) {
            existingTags =
              (
                await client.query({
                  query: FETCH_TAGS,
                  variables: {
                    filter: {
                      or: [
                        { isGlobal: { equalTo: true } },
                        { dashboardId: { equalTo: dashboardId } },
                      ],
                    },
                    withDetails: false,
                  },
                  fetchPolicy: 'network-only',
                })
              )?.data?.tags || [];
          }

          const newTagNames = tagNames.filter(
            (name) =>
              !existingTags.some(
                (tag: { name: string }) => tag.name?.toLowerCase() === name?.toLowerCase()
              )
          );

          const newTags = await Promise.all(
            newTagNames.map((name) => createTag({ name, dashboardId }))
          );

          const tags = [...existingTags, ...newTags];

          const {
            data: { notes: existingNotes },
          } = await client.query({
            query: FETCH_NOTES,
            variables: {
              condition: {
                dashboardId,
              },
            },
          });

          const startPosition = calcStartPosition(existingNotes, existingThemes);

          records.forEach((record: ImportFromCSVRecord) => {
            const recordText = getNoteText(record);
            if (recordText) {
              const allTags = record.tags
                ?.split(',')
                ?.filter((x) => x)
                .map((tagName) => ({
                  tagId: tags?.find((tag) => tag.name?.toLowerCase() === tagName?.toLowerCase())
                    ?.id,
                }));

              const noteData: {
                text: string;
                createdAt: string;
                tagsNotes: any;
                participantId?: string;
                themeId: any;
              } = {
                text: recordText,
                createdAt: record.date
                  ? new Date(record.date).toISOString()
                  : new Date().toISOString(),
                tagsNotes: {},
                themeId: null,
              };
              if (allTags) {
                noteData.tagsNotes = {
                  create: allTags,
                };
              }

              if (record.participant) {
                const participant = participants.find(
                  (participant) => participant.name === record.participant
                );
                noteData.participantId = participant?.id;
              }

              if (record.theme) {
                noteData.themeId = record.theme;
              }

              newNotesData.push(noteData);
            }
          });

          const newNotesWithPositions = newNotesData
            .sort((a, b) => {
              if (b.themeId && !a.themeId) {
                return -1;
              }
              if (a.themeId && !b.themeId) {
                return 1;
              }
              return 0;
            })
            .map((note, index) => {
              if (note.themeId) return note;
              return {
                ...note,
                x: startPosition.x + (index % 5) * (STICKY_WIDTH + 32),
                y: startPosition.y + Math.floor(index / 5) * (STICKY_WIDTH + 32),
              };
            });

          const themesStartPosition = calcStartPosition(
            [...existingNotes, ...newNotesWithPositions],
            existingThemes
          );

          const newThemeNames = themeNames.filter(
            (name) =>
              !existingThemes.some(
                (theme: { name: string }) => theme.name?.toLowerCase() === name?.toLowerCase()
              )
          );

          const newThemes = await Promise.all(
            newThemeNames.map((name, index) => {
              const themeData = {
                name: name,
                x: themesStartPosition.x,
                y: themesStartPosition.y,
                dashboardId,
              };

              const themeNotes = newNotesWithPositions.filter((note) => note.themeId === name);
              const [themeWidth, themeHeight, rowSize] = calcThemeSizeFromStickies(themeNotes);

              themesStartPosition.x += themeWidth + 64;

              return createTheme(dashboardId, themeData, []);
            })
          );

          const themes = [...existingThemes, ...newThemes];

          const notesToAdd = newNotesWithPositions.map((note) => {
            if (note.themeId) {
              const theme = themes.find((theme) => theme.name === note.themeId);

              return {
                ...note,
                themeId: theme?.id,
              };
            }
            return note;
          });

          await Promise.all(notesToAdd.map((noteData) => createNote(dashboardId, noteData)));

          toast.update(toastId, {
            render: 'Successfully imported notes from csv!',
            type: 'success',
            isLoading: false,
            autoClose: 1000,
          });
        } catch (error) {
          captureException(error);
          toast.update(toastId, {
            render: 'Unable to parse the file.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        }
      };
    };
    input.click();
  };

  return (
    <DropDownMenu
      alignLeft={true}
      button={
        <Menu.Button className="flex flex-row items-center flex-shrink font-medium m-3 text-sm">
          File
          <ChevronDownIcon className="ml-2 h-3 w-3" />
        </Menu.Button>
      }
    >
      <Menu.Item disabled={!canCreateNotes}>
        <div
          onClick={handleImportFromCsv}
          className={`group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium cursor-pointer ${
            !canCreateNotes && 'text-primary-purple-light-60'
          }`}
        >
          Import notes <span className="ml-1 text-primary-purple-light-60">.csv</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={handleExportToCsv}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium cursor-pointer"
        >
          Export notes <span className="ml-1 text-primary-purple-light-60">.csv</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={handleExportToPng}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium cursor-pointer"
        >
          Export canvas <span className="ml-1 text-primary-purple-light-60">.png</span>
        </div>
      </Menu.Item>
      <hr />
      <Menu.Item>
        <a
          rel="noreferrer"
          href="https://help.notably.ai/en/collections/3509008-importing-exporting"
          target="_blank"
          className="group flex items-center px-4 py-2 text-sm text-primary-purple-light-40"
        >
          <QuestionMarkIcon />
          <div className="ml-1">Help with import &amp; export</div>
        </a>
      </Menu.Item>
    </DropDownMenu>
  );
}

function isMiroFormat(s: string): boolean {
  // Regex for a line with block name
  const blockNameRegex = /^"[^",]*"$/;

  // Regex for a content line
  const contentLineRegex = /^"[^"]*",("https?:\/\/[^"]*"|""|),(("[^"]*"|"[^"]*,[^"]*"))*$/;

  // Split the string into blocks
  const blocks = s.split('\n\n');

  // Validate each block
  for (const block of blocks) {
    // Split the block into lines
    const lines = block.split('\n');

    // If the first line is not empty and does not match the block name regex,
    // then it must match the content line regex. Otherwise, the format is invalid.
    if (lines[0] !== '' && !blockNameRegex.test(lines[0]) && !contentLineRegex.test(lines[0])) {
      return false;
    }

    // Validate the rest of the lines in the block
    for (let i = 1; i < lines.length; i++) {
      if (!contentLineRegex.test(lines[i]) && lines[i]) {
        return false;
      }
    }
  }

  return true;
}

function convertFromMiroFormat(input: string): string {
  const blocks = input.split('\n\n');
  const header = 'content,link,tags,theme\n';
  let result = header;

  for (const block of blocks) {
    const lines = block.split('\n');
    let theme = '';
    let start = 0;

    // Check if the first line is a theme line
    if (!lines[0].includes(',')) {
      theme = lines[0].replace(/"/g, '');
      start = 1;
    }

    for (let i = start; i < lines.length; i++) {
      const line = lines[i];
      // Append the theme at the end of the line
      result += `${line},${theme}\n`;
    }
  }

  return result;
}
