import { OperationVariables, gql } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client';
import { FETCH_INTEGRATIONS } from '../GraphQL/queries';

export interface Integration {
  type: string;
  createdBy?: string;
}

export interface FetchIntegrationsResponse {
  integrations: Integration[];
}

export function useFetchIntegrations(): QueryResult<FetchIntegrationsResponse, OperationVariables> {
  return useQuery<FetchIntegrationsResponse>(FETCH_INTEGRATIONS);
}
