import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 672px;
  margin: 0 auto;
  padding: 20px;
  background-color: #FEF4F4;
  border-radius: 8px;
`

export const Illustration = styled.div<{ src: string }>`
  width: 400px;
  height: 200px;
  margin: 0 auto 20px;
  background-image: url(${({ src }) => src});
  background-size: contain;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const TitleText = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
`

export const TitleIconWrapper = styled.div`
  width:  24px;
  height: 24px;
  margin-right: 4px;
  color: #3B2651;
`

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-size: 500;
  margin-bottom: 20px;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  margin-right: 20px;

  &:last-child {
    margin: 0;
  }
`