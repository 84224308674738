import styled, { keyframes } from 'styled-components';
import emptyStateImageSrc from '../../assets/empty-states/search.png';
import breakpoint from '../../Shared/breakpoint';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 680px;

  ${breakpoint.Mobile`
    display: none;
  `}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 20px;
  padding: 6px 10px;
  background-color: rgba(56, 33, 82, 0.08);
  border-radius: 30px;
  cursor: pointer;

  ${breakpoint.DesktopSmall(`
    margin: 0 5px;
  `)};
`;

export const SearchIcon = styled.div`
  margin-right: 10px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const SearchPanelContainer = styled.div<{
  isAdvancedSearch: boolean;
}>`
  position: fixed;
  top: 0;
  left: calc(50% - 450px);
  width: 100%;
  max-width: 900px;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
  padding: 10px 20px;
  overflow: hidden;

  transform-origin: center center;

  ${({ isAdvancedSearch }) =>
    isAdvancedSearch &&
    `
    padding: 10px 20px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
  `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 0.5s linear infinite;
  margin-bottom: 10px;
  height: 70px;
`;

export const ResultContainer = styled.div`
  width: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px 0;
`;

export const ResultTitle = styled.div`
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 10px;

  &:first-child {
    margin: 0;
  }
`;

export const ResultCount = styled.span`
  font-size: 14px;
  line-height: 32px;
  color: rgba(59, 38, 81, 0.4);
`;

export const ResultItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #eae9ec;
  }
`;

export const ResultItemName = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProjectName = styled.div`
  margin-left: 8px;
  color: rgba(59, 38, 81, 0.4);
`;

export const EmptyStateText = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DropDowns = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const SearchInputWrapper = styled(Container)<{ isAdvancedSearch: boolean }>`
  padding: 10px;

  ${({ isAdvancedSearch }) =>
    isAdvancedSearch
      ? `
    margin: 0 20px 0 0;
    max-width: 880px;
  `
      : `margin: 0 10px 0 0;`}
`;

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
`;

export const ProjectDropDownWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
  max-width: 300px;
  width: 100%;
`;

export const EntityDropDownWrapper = styled(ProjectDropDownWrapper)`
  max-width: 130px;
  margin-right: 20px;
`;

export const FilterDropDownWrapper = styled(ProjectDropDownWrapper)`
  max-width: 135px;

  &:last-child {
    margin: 0;
  }
`;

export const ResultCardsBlock = styled.div<{ small?: boolean }>`
  padding: 0 20px;
  ${({ small }) =>
    small &&
    `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const ResultGrid = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ResultItemCard = styled.div`
  width: 100%;
  max-width: 700px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid rgba(215, 211, 220, 1);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
`;

export const ResultItemDataCard = styled(ResultItemCard)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  flex-shrink: 0;
`;

export const ResultItemCardImage = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  height: 120px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
`;

export const ResultItemCardTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgba(215, 211, 220, 1);
`;

export const ResultItemDataTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ResultItemCardProjectNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const ResultItemDataProjectNameContainer = styled(ResultItemCardProjectNameContainer)`
  padding: 0;
`;

export const ProjectLogoImage = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  object-fit: 'cover';
  border-radius: 2px;
`;

export const ResultItemCardProjectName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-right: 8px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RoundDivider = styled.div`
  margin-right: 8px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #e2e8f0;
`;

export const CreatedAt = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: rgba(59, 38, 81, 0.4);
  min-width: 100px;
`;

export const NewFilterButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin: 20px 5px;
  cursor: pointer;
`;

export const PlusIconWrapper = styled.div`
  margin-right: 5px;
`;

export const Placeholder = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const MaximaizeButton = styled.div`
  cursor: pointer;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterIcon = styled.div`
  margin-right: 10px;
`;

export const FilterDivider = styled.div`
  width: 1px;
  height: 38px;
  background-color: #d7d3dc;
  margin: 0 10px;
`;

export const TagsContainer = styled.div<{ isAdvancedSearch: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 44px;
  max-width: ${({ isAdvancedSearch }) => (isAdvancedSearch ? ' 742px' : '262px')};
  padding: 5px 6px 5px 10px;
  background-color: rgba(56, 33, 82, 0.08);
  border-radius: 20px;
  margin-right: 44px;
  cursor: pointer;
`;

export const TagsContent = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const TagItem = styled.div<{ color?: string }>`
  padding: 5px 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  background-color: ${({ color }) => (color ? color : '#d8c0f880')};
  margin-right: 5px;
  border-radius: 3px;
  flex-shrink: 0;

  &:last-child {
    margin: 0;
  }
`;

export const ChevronIcon = styled.div`
  transform: rotate(90deg);
`;

export const TagsPlaceholder = styled.div`
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  margin-right: 10px;
`;

export const TagMore = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 5px 8px;
  border-radius: 3px;
  background-color: #d7d3dc;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -31px;
    background: linear-gradient(270deg, #efedf1 0%, rgba(239, 237, 241, 0) 100%);
  }
`;

export const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateImage = styled.div`
  width: 250px;
  height: 250px;
  background-image: url(${emptyStateImageSrc});
  background-size: contain;
  margin-bottom: 40px;
`;

export const EmptyStateTitle = styled.div`
  font-size: 18px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const EmptyStateDescription = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const ResultFullContainer = styled.div<{ type?: 'full' | 'light' }>`
  width: 100%;
  height: 100%;
  ${({ type }) => type === 'light' && `max-height: 600px`};
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: ${({ type }) => (type === 'light' ? '20px' : '90px')};
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const NoteText = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  padding: 0 10px;
`;

export const DocumentNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
`;
