import React from 'react';
export default function InsightsIcon2({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1V5V1ZM1 3H5H1ZM4 15V19V15ZM2 17H6H2ZM11 1L13.2857 7.85714L19 10L13.2857 12.1429L11 19L8.7143 12.1429L3 10L8.7143 7.85714L11 1Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
