import React, { FC, useMemo } from 'react';
import { Container } from './styles';
import PortalNew from '../PortalNew';

interface PopupProps {
  children?: React.ReactNode;
  show: boolean;
}

const Popup: FC<PopupProps> = ({ show, children }) => {
  const popupId = useMemo(() => `popup-${Math.floor(Math.random() * Date.now()).toString(16)}`, []);

  return (
    <PortalNew wrapperId={popupId}>
      <Container show={show}>{children}</Container>
    </PortalNew>
  );
};

export default Popup;
