import React, { useRef, useEffect, useState } from 'react';
import { Arrow, Circle, Group, Line, Transformer } from 'react-konva';
import LineMenu from './LineMenu';
import * as KonvaUtils from 'react-konva-utils';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

const MENU_BOTTOM_MARGIN = 4;
const MENU_WIDTH = 160;

export type LineStyles = {
  type: string;
  stroke: string;
  strokeWidth: string;
  dash: boolean;
  zIndex?: number;
};

interface Props {
  id: string;
  x: number;
  y: number;
  points: number[];
  styles: LineStyles;
  onUpdate: (updates: {
    points?: number[];
    styles?: LineStyles;
    x?: number;
    y?: number;
  }) => Promise<void>;
  onDelete: () => void;
  setSelected: () => void;
  selected: boolean;
  stageScale: number;
  draggable?: boolean;
}

function LineComponent({
  id,
  x,
  y,
  points,
  styles,
  onUpdate,
  onDelete,
  setSelected,
  selected,
  stageScale,
  draggable = true,
}: Props) {
  const lineRef = useRef<Konva.Line>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const arrowRef = useRef<Konva.Arrow>(null);
  const lineGroupRef = useRef<Konva.Group>(null);
  const circleBack = useRef<Konva.Circle>(null);
  const circleFront = useRef<Konva.Circle>(null);

  const [showMenu, setShowMenu] = useState<boolean>(true);

  useEffect(() => {
    if (trRef.current && trRef.current.nodes && lineRef.current && arrowRef.current) {
      if (styles.type === 'line') {
        trRef.current.nodes([lineRef.current]);
        trRef.current.getLayer()?.batchDraw();
      } else {
        trRef.current.nodes([arrowRef.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [styles.type]);

  const handleOnDragMove = () => {
    if (circleBack.current && circleFront.current && arrowRef.current && lineRef.current) {
      if (showMenu) setShowMenu(false);
      arrowRef.current.points([
        circleBack?.current?.x(),
        circleBack?.current?.y(),
        circleFront?.current?.x(),
        circleFront.current.y(),
      ]);
      lineRef.current.points([
        circleBack.current?.x(),
        circleBack.current?.y(),
        circleFront.current?.x(),
        circleFront.current?.y(),
      ]);
    }
  };

  const handleOnDragEnd = async () => {
    if (circleBack.current && circleFront.current) {
      await onUpdate({
        points: [
          Math.round(circleBack.current.x()),
          Math.round(circleBack.current.y()),
          Math.round(circleFront.current.x()),
          Math.round(circleFront.current.y()),
        ],
      });

      setShowMenu(true);
    }
  };

  return (
    <Group
      id={id}
      ref={lineGroupRef}
      draggable={draggable}
      onDragEnd={() => {
        const node = lineGroupRef.current;
        onUpdate({
          x: node?.attrs.x,
          y: node?.attrs.y,
        });
      }}
      x={x}
      y={y}
      onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
          if (container.style.cursor == 'default') {
            container.style.cursor = 'pointer';
          }
        }
      }}
      onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
          if (container.style.cursor == 'pointer') {
            container.style.cursor = 'default';
          }
        }
      }}
    >
      <Group>
        {selected && showMenu && (
          <KonvaUtils.Html
            divProps={{ style: { zIndex: 100 } }}
            transformFunc={({ x, y, ...attrs }) => {
              const minY = Math.min(points[1], points[3]);
              const minX = Math.min(points[0], points[2]);
              const xPos = minX + Math.abs(points[0] - points[2]) / 2;
              return {
                ...attrs,
                scaleX: 1,
                scaleY: 1,
                x: x + xPos * attrs.scaleX - MENU_WIDTH / 2,
                y: y + minY * attrs.scaleY - MENU_BOTTOM_MARGIN,
              };
            }}
          >
            <LineMenu styles={styles} onUpdate={onUpdate} onDelete={onDelete} />
          </KonvaUtils.Html>
        )}
      </Group>

      <Arrow
        onClick={(e) => {
          e.cancelBubble = true;
          setSelected();
        }}
        points={points && points}
        stroke={styles.stroke}
        strokeWidth={parseInt(styles.strokeWidth)}
        ref={arrowRef}
        dash={
          styles.dash ? [parseInt(styles.strokeWidth) * 2, parseInt(styles.strokeWidth)] : undefined
        }
        visible={styles.type !== 'line'}
        pointerAtBeginning={styles.type === 'arrowLeft' || styles.type === 'arrowBoth'}
        pointerAtEnding={styles.type === 'arrowRight' || styles.type === 'arrowBoth'}
        hitStrokeWidth={10 / stageScale}
      />
      <Line
        onClick={(e) => {
          e.cancelBubble = true;
          setSelected();
        }}
        points={points && points}
        stroke={styles.stroke}
        strokeWidth={parseInt(styles.strokeWidth)}
        ref={lineRef}
        dash={
          styles.dash ? [parseInt(styles.strokeWidth) * 2, parseInt(styles.strokeWidth)] : undefined
        }
        visible={styles.type === 'line'}
        hitStrokeWidth={10 / stageScale}
      />
      <Circle
        onMouseDown={(e: KonvaEventObject<MouseEvent>) => {
          e.cancelBubble = true;
        }}
        ref={circleBack}
        x={
          arrowRef?.current || lineRef?.current
            ? styles.type !== 'line'
              ? arrowRef?.current?.points()[0]
              : lineRef?.current?.points()[0]
            : points[0]
        }
        y={
          arrowRef?.current || lineRef?.current
            ? styles.type !== 'line'
              ? arrowRef?.current?.points()[1]
              : lineRef?.current?.points()[1]
            : points[1]
        }
        radius={5}
        draggable={true}
        fill={'white'}
        stroke={'black'}
        strokeWidth={2}
        onDragMove={handleOnDragMove}
        onDragEnd={(e: KonvaEventObject<MouseEvent>) => {
          handleOnDragEnd();
        }}
        onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
          const container = e.target.getStage()?.container();
          if (container) {
            container.style.cursor = 'move';
          }
        }}
        onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
          const container = e.target.getStage()?.container();
          if (container) {
            container.style.cursor = 'default';
          }
        }}
        visible={selected}
        hitStrokeWidth={10 / stageScale}
      />
      <Circle
        onMouseDown={(e: KonvaEventObject<MouseEvent>) => {
          e.cancelBubble = true;
        }}
        ref={circleFront}
        x={
          arrowRef?.current || lineRef?.current
            ? styles.type !== 'line'
              ? arrowRef?.current?.points()[2]
              : lineRef?.current?.points()[2]
            : points[2]
        }
        y={
          arrowRef?.current || lineRef?.current
            ? styles.type !== 'line'
              ? arrowRef?.current?.points()[3]
              : lineRef?.current?.points()[3]
            : points[3]
        }
        radius={5}
        draggable={true}
        fill={'white'}
        stroke={'black'}
        strokeWidth={2}
        onDragMove={handleOnDragMove}
        onDragEnd={(e: KonvaEventObject<MouseEvent>) => {
          handleOnDragEnd();
        }}
        onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
          const container = e.target.getStage()?.container();
          if (container) {
            container.style.cursor = 'all-scroll';
          }
        }}
        onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
          const container = e.target.getStage()?.container();
          if (container) {
            container.style.cursor = 'default';
          }
        }}
        visible={selected}
        hitStrokeWidth={10 / stageScale}
      />
    </Group>
  );
}

export default LineComponent;
