import { Editor } from '@tiptap/react';

export const generateNewSpeakers = (
  editor: Editor,
  setSpeakersNamesRef: React.MutableRefObject<(speakerNames: string[]) => void>
): void => {
  const newSpeakers: string[] = [];

  editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node) => {
    if (node.type.name === 'speaker') {
      newSpeakers.push(node.attrs.speakerName);
    }
  });
  setSpeakersNamesRef.current(newSpeakers);
};
