import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 6px 10px 10px;
  background-color: rgba(56, 33, 82, 0.08);
  border-radius: 20px;
  cursor: pointer;
`;

export const Title = styled.div<{ size: 'small' | 'big'; dontCutTitle: boolean }>`
  font-size: 14px;
  line-height: ${({ size }) => (size === 'small' ? '16px' : '24px')};
  font-weight: 500;
  margin-right: 5px;
  user-select: none;
  ${({ dontCutTitle }) =>
    !dontCutTitle &&
    `
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;`}
`;

export const Icon = styled.div<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? '-90deg' : '90deg')});
`;

export const Menu = styled.div<{
  isOpen: boolean;
  top: number;
  bottom: number;
  right: number;
  width: number;
  zIndex: number;
  maxHeight?: number;
  align: 'top' | 'bottom';
}>`
  position: absolute;
  ${({ top, align }) =>
    align === 'bottom' &&
    `
  top: ${top + 4}px
  `};
  ${({ bottom, align }) =>
    align === 'top' &&
    `
  bottom: ${bottom + 4}px
  `};
  right: ${({ right }) => right}px;
  max-width: ${({ width }) => width}px;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
    `}
  background-color: #ffffff;
  border-radius: 6px;
  display: none;
  border: 1px solid #d7d3dc;
  z-index: ${({ zIndex }) => zIndex};
  overflow: hidden;
  z-index: 999;
  overflow-y: auto;

  ${({ isOpen }) => isOpen && `display: block;`}
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 8px 10px;
  cursor: pointer;
  white-space: nowrap;

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    margin: 0;
    border-radius: 0 0 6px 6px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const MenuItemLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const Placeholder = styled.div<{ size: 'small' | 'big' }>`
  font-size: 14px;
  line-height: ${({ size }) => (size === 'small' ? '16px' : '24px')};
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const OptionIcon = styled.div<{ size: 'small' | 'big' }>`
  margin-right: 8px;
  width: ${({ size }) => (size === 'small' ? '18px' : '24px')};
  height: ${({ size }) => (size === 'small' ? '18px' : '24px')};
`;

export const TickIconWrapper = styled.div`
  flex-shrink: 0;
`;
