import { Reference, useMutation, useQuery } from '@apollo/client';
import { FETCH_NOTES, FETCH_THEMES, FETCH_THEME } from '../GraphQL/queries';
import { ID, Sticky, Theme } from '../Models';
import { CREATE_THEME, DELETE_THEME, UPDATE_THEME } from '../GraphQL/mutations';
import { ThemeInput } from '../GraphQL/__generated__/globalTypes';
import { STICKY_COLORS } from '../Consts';

export const useFetchTheme = (themeId: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_THEME, {
    variables: { themeId },
  });
  return [loading, data?.theme];
};

function useThemes(dashboardId: ID) {
  if (!dashboardId) {
    throw new Error(`dashboardId parameter is required`);
  }
  const { loading, data: dataThemes } = useQuery(FETCH_THEMES, {
    variables: { dashboardId },
  });

  const [deleteThemeMutation] = useMutation(DELETE_THEME);
  const [createThemeMutation] = useMutation(CREATE_THEME);
  const [updateThemeMutation] = useMutation(UPDATE_THEME);

  function fetchThemes() {
    return [loading, dataThemes?.themes || []];
  }

  async function createTheme(
    dashboardId: ID,
    theme: ThemeInput,
    stickies: Sticky[]
  ): Promise<Theme> {
    const stickySpace = 10;
    // Starting font of the sticky
    const stickyFont = 14;
    // Default spacing step for padding/margin/etc.
    const defaultStep = 8;

    const themeProps = {
      x: theme.x ?? stickies[0]?.x - stickySpace,
      y: theme.y ?? stickies[0]?.y - defaultStep * 2 - stickyFont * 1.25,
      name: theme.name ?? 'Untitled',
      dashboardId,
      color: STICKY_COLORS[Math.floor(Math.random() * STICKY_COLORS.length)],
    };
    const {
      data: {
        createTheme: { theme: record },
      },
    } = await createThemeMutation({
      variables: {
        input: {
          ...themeProps,
          ...theme,
          notes: {
            connectById: stickies.map((x) => ({
              id: x.id,
            })),
          },
        },
      },
      optimisticResponse: {
        createTheme: {
          theme: {
            __typename: 'Theme',
            id: Math.random() * -1000,
            userByCreatedBy: null,
            createdAt: null,
            ...themeProps,
            ...theme,
            notes: stickies.map((x) => ({
              __typename: 'Note',
              ...x,
            })),
          },
        },
      },
      update: (
        cache,
        {
          data: {
            createTheme: { theme },
          },
        }
      ) => {
        cache.modify({
          fields: {
            themes(prev, { toReference }) {
              return [...prev, toReference(theme)];
            },
          },
        });
        theme.notes.forEach((n: Sticky) => {
          cache.modify({
            id: cache.identify({
              __typename: 'Note',
              id: n.id,
            }),
            fields: {
              theme(_, { toReference }) {
                return toReference(theme);
              },
            },
          });
        });
      },
    });
    return record;
  }

  async function deleteTheme(id: ID) {
    await deleteThemeMutation({
      variables: {
        id,
      },
      update(
        cache,
        {
          data: {
            deleteTheme: { theme },
          },
        }
      ) {
        const result: { notes: Sticky[] } | null = cache.readQuery({
          query: FETCH_NOTES,
          variables: {
            condition: {
              dashboardId,
            },
          },
        });
        const notes = result?.notes ?? [];
        notes.forEach((n: Sticky) => {
          cache.modify({
            id: cache.identify({
              __typename: 'Note',
              id: n.id,
            }),
            fields: {
              theme(prev, { readField }) {
                return readField('id', prev) == '' + id ? null : prev;
              },
            },
          });
        });
        cache.modify({
          fields: {
            themes(existingRefs, { readField }) {
              return existingRefs.filter((ref: Reference) => id !== readField('id', ref));
            },
          },
        });
      },
    });
  }

  async function updateTheme(id: ID, input: Partial<ThemeInput>) {
    return updateThemeMutation({
      variables: {
        id,
        input: input,
      },
    });
  }

  function getFromStickies(stickies: Sticky[]): Theme[] {
    return Object.values(mapFromStickies(stickies));
  }

  function mapFromStickies(stickies: Sticky[]): { [key: string]: Theme } {
    const themesMap: { [key: string]: Theme } = {};
    stickies.forEach((sticky) => {
      if (!sticky.theme) {
        return;
      }
      const themeId = sticky.theme.id;
      // add theme to map if it is not there
      if (!themesMap[themeId]) {
        themesMap[themeId] = { ...sticky.theme, notes: [] };
      }
      // add note to theme
      themesMap[themeId].notes.push(sticky);
    });

    return themesMap;
  }

  function searchThemeByText(items: Theme[], query: string, themeIds?: number[]): Theme[] {
    let filteredThemes = items;

    if (themeIds && themeIds.length) {
      filteredThemes = filteredThemes.filter((theme) => themeIds.includes(+theme.id));
    }

    query = query ?? '';

    if (!query) {
      return filteredThemes;
    }

    const lcQuery = query.toLowerCase();

    return filteredThemes.filter((x) => x.name?.toLowerCase().includes(lcQuery));
  }

  return {
    deleteTheme,
    createTheme,
    updateTheme,
    fetchThemes,
    mapFromStickies,
    getFromStickies,
    searchThemeByText,
  };
}

export default useThemes;
