import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import {
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  PuzzleIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import useFeatureFlags from '../Hooks/useFeatureFlags';
import usePermissions from '../Hooks/usePermissions';
import { Icon24 } from '../Icons/Icon';

const SettingsSidebar = (): JSX.Element => {
  const match = useRouteMatch({
    path: '/settings',
    exact: false,
  });

  const { isFeatureEnabled } = useFeatureFlags();
  const { canEditWorkspace, canEditTeam, canChangePlan } = usePermissions();

  const navigation: {
    name: string;
    href: string;
    icon: JSX.Element;
  }[] = [];
  if (canEditWorkspace) {
    navigation.push({
      name: 'General',
      href: '/general',
      icon: <CogIcon className={'w-6 h-6'} />,
    });
  }
  navigation.push({
    name: 'Profile',
    href: '/profile',
    icon: <UserIcon className={'w-6 h-6'} />,
  });
  if (canChangePlan) {
    navigation.push({
      name: 'Plans',
      href: '/plans',
      icon: <CurrencyDollarIcon className={'w-6 h-6'} />,
    });
  }
  if (canChangePlan) {
    navigation.push({
      name: 'Billing',
      href: '/billing',
      icon: <CreditCardIcon className={'w-6 h-6'} />,
    });
  }
  if (canEditTeam) {
    navigation.push({
      name: 'Licenses & Team',
      href: '/users',
      icon: <UsersIcon className={'w-6 h-6'} />,
    });
  }
  if (isFeatureEnabled('zoom')) {
    navigation.push({
      name: 'Integrations',
      href: '/integrations',
      icon: <PuzzleIcon className={'w-6 h-6'} />,
    });
  }

  navigation.push({
    name: 'Global tags',
    href: '/tags',
    icon: <Icon24.Tags />,
  });

  return (
    <div className="flex flex-col w-56 border-r border-gray-200 nav-sidebar">
      <div className="flex-1 flex flex-col">
        <div className="p-3 text-lg font-medium">Settings</div>
        <nav className="flex-1 px-2 bg-white space-y-1">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              activeClassName="bg-secondary-purple-light"
              to={`${match?.url}${item.href}`}
              className={
                'hover:bg-secondary-purple-light group flex items-center px-2 py-2 text-sm font-medium rounded-md'
              }
            >
              <span className={'inline-flex pr-3'}>{item.icon}</span>
              {item.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default SettingsSidebar;
