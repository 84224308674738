import React, { Dispatch, FC, SetStateAction } from 'react';
import Button from '../../Button';
import ReactLoading from 'react-loading';
import useKeyPressHandler from '../../../Hooks/useKeyPressHandler';
import {
  ButtonWrapper,
  Container,
  Content,
  ContentForm,
  FormTitle,
  InputWrapper,
  Step,
  Subtitle,
  Title,
} from './styles';
import Input from '../../Input/Input';

type IntroductionStepProps = {
  loading: boolean;
  totalSteps: number;
  userInvited: boolean;
  fullName: string;
  companyName: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  setFullName: Dispatch<SetStateAction<string>>;
  onContinue: () => void;
};

const IntroductionStep: FC<IntroductionStepProps> = ({
  loading,
  totalSteps,
  userInvited,
  fullName,
  companyName,
  setCompanyName,
  setFullName,
  onContinue,
}) => {
  useKeyPressHandler(onContinue, 'Enter');

  return (
    <Container>
      <Content>
        <Step>{`Step 1 of ${totalSteps}`}</Step>
        <Title>Welcome to Notably</Title>
        <Subtitle>Start discovering insights from your research instantly with AI.</Subtitle>

        <ContentForm>
          <FormTitle>Set up your workspace</FormTitle>
          <InputWrapper>
            <Input
              id="name"
              name="name"
              label="Your name"
              placeholder="Full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </InputWrapper>

          {!userInvited && (
            <InputWrapper>
              <Input
                id="company-name"
                name="company-name"
                label="Workspace name"
                value={companyName}
                placeholder="Hint: most people use their company name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </InputWrapper>
          )}
        </ContentForm>
        <ButtonWrapper>
          {loading && <ReactLoading type={'spin'} color={'#382152'} height={'10%'} width={'10%'} />}
          {!loading && (
            <Button
              size={'lg'}
              className="w-full flex justify-center"
              disabled={userInvited ? !fullName : !fullName || !companyName}
              onClick={onContinue}
            >
              Continue
            </Button>
          )}
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default IntroductionStep;
