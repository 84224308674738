import { useMutation } from '@apollo/client';
import { OIDC_DEFAULT_SCOPES } from '@azure/msal-browser';
import classNames from 'classnames';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SettingsIcon from '../../../assets/projects/default-icons/settings-icon.svg';
import Button from '../../../Components/Button';
import { TableCell, TableRow } from '../../../Components/TableSimple';
import { AUTHORIZE_ONEDRIVE } from '../../../GraphQL/mutations';
import { Integration } from '../../../Hooks/useFetchIntegrations';
import usePermissions from '../../../Hooks/usePermissions';
import { getIntegrationLogo, getIntegrationShortDescription } from '../utils/integrationsUtils';
import { defaultMSALInstance, defaultRedirectURI } from '../utils/msal';

interface IntegrationInfoProps {
  name: string;
  connection?: Integration;
  onConnect?: () => void;
}

export default function OneDriveIntegrationInfo(props: IntegrationInfoProps): JSX.Element {
  const { name, connection, onConnect } = props;
  const history = useHistory();
  const { path } = useRouteMatch();
  const { canChangeIntegrations } = usePermissions();
  const [authorizeOneDriveMutation] = useMutation(AUTHORIZE_ONEDRIVE);

  function handleSettingsOnClick() {
    history.push(`${path}/${name}`);
  }

  async function handleConnectOnClick() {
    await defaultMSALInstance.initialize();
    // TODO attempt to get API permissions consented with the client app
    const response = await defaultMSALInstance.loginPopup({prompt: 'consent', scopes: ['api://56a6d9c9-56d4-4f41-a578-c16231016d31/.default', ...OIDC_DEFAULT_SCOPES]});
    defaultMSALInstance.setActiveAccount(response.account);
    await authorizeOneDriveMutation({
      variables: { code: response.accessToken, redirectUri: defaultRedirectURI },
    });
    onConnect?.();
  }

  return (
    <TableRow
      className={classNames(
        'rounded-lg border border-solid border-gray-200 bg-F6FAF6 shadow px-5 py-1',
        {
          'border-[#3B2651]': connection,
        }
      )}
      hover={false}
    >
      <TableCell>
        <div className={'flex-col'}>
          <div className="flex items-center">
            <div className="flex-col p-5">
              <div className="flex items-center">
                <img
                  src={getIntegrationLogo(name)}
                  alt="SVG Image"
                  style={{ height: '28px', width: '28px' }}
                />
                <span className="font-bold ml-2 ">{name}</span>
                {connection && (
                  <span className="bg-[#A0E4CA] ml-2 text-brand-purple text-sm font-semibold leading-5 px-1 rounded-sm gap-10">
                    Connected
                  </span>
                )}
              </div>
              <div className={'text-sm py-1 block ml-[37px]'}>
                {getIntegrationShortDescription(name)}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      {canChangeIntegrations && (
        <TableCell>
          {connection ? (
            <button onClick={handleSettingsOnClick}>
              <img src={SettingsIcon} />
            </button>
          ) : (
            <Button onClick={handleConnectOnClick} type={'secondary'} size={'sm'}>
              Connect
            </Button>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
