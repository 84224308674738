import { gql } from '@apollo/client/core';

export const THEME_CORE_FIELDS = gql`
  fragment ThemeCoreFields on Theme {
    id
    color
    dashboardId
    userByCreatedBy {
      id
      name
      avatar
    }
    createdAt
    name
    x
    y
  }
`;

export const NOTE_CORE_FIELDS = gql`
  fragment NoteCoreFields on Note {
    id
    text
    x
    y
    color
    dashboardId
    url
    sentimentScore
    participantId
    customDate
    options
    document {
      id
      name
    }
    transcription {
      id
      name
      file {
        type
        id
      }
    }
    tagsList {
      id
      name
      color
    }
    userByCreatedBy {
      name
    }
    createdAt
  }
`;

export const BOARD_CORE_FIELDS = gql`
  fragment BoardCoreFields on Dashboard {
    id
    name
    createdAt
    lastActivityAt
    updatedAt
    createdBy
    insights
    folderId
    isVisited
    isSample
    shareId
    cover
    userByCreatedBy {
      id
      name
      email
      picture
    }
  }
`;

export const TRANSCRIPT_CORE_FIELDS = gql`
  fragment TranscriptionCoreFields on Transcription {
    id
    name
    createdAt
    fileId
    folderId
    customDate
    file {
      type
    }
    tagsList {
      id
      name
      color
    }
    userByCreatedBy {
      id
      name
      email
      picture
    }
  }
`;

export const FILE_CORE_FIELDS = gql`
  fragment FileCoreFields on File {
    id
    name
    language
    aiTemplate
    type
    mimeType
    metadata
    status
    statusText
    folderId
    createdAt
    isCopy
    size
    duration
    s3VideoPath
    s3AudioPath
    userByCreatedBy {
      id
      name
      email
      picture
    }
  }
`;

export const PROJECT_INFO_CORE_FIELDS = gql`
  fragment ProjectInfoCoreFields on ProjectInfo {
    id
    description
    updatedAt
    createdAt
    dashboardId
    contentVersion
    userByCreatedBy {
      id
      name
    }
  }
`;

export const PROJECT_INSIGHT_CORE_FIELDS = gql`
  fragment ProjectInsightCoreFields on ProjectInsight {
    id
    clientId
    content
    title
    cover
    customCover
    updatedAt
    createdAt
    customDate
    dashboardId
    shareId
    userByCreatedBy {
      id
      name
      picture
    }
  }
`;

export const DOCUMENT_CORE_FIELDS = gql`
  fragment DocumentCoreFields on Document {
    id
    name
    updatedAt
    createdAt
    dashboardId
    customDate
    folderId
    contentVersion
    tagsList {
      id
      name
      color
    }
    createdBy
    userByCreatedBy {
      id
      name
      picture
    }
  }
`;

export const PARTICIPANT_CORE_FIELDS = gql`
  fragment ParticipantCoreFields on Participant {
    id
    name
    company
    role
    email
    phone
    lastParticipated
    description
    updatedAt
    createdAt
    picture
    color
    userByCreatedBy {
      id
      name
      picture
    }
  }
`;

export const USER_CORE_FIELDS = gql`
  fragment UserCoreFields on User {
    id
    name
    email
    role
    picture
    inviteAccepted
  }
`;

export const HIGHLIGHT_CORE_FIELDS = gql`
  fragment HighlightCoreFields on Highlight {
    id
    documentId
    entityId
    createdBy
    createdAt
    dashboardId
    addedToAnalysis
    tagsList {
      id
      name
      color
    }
    # tagsHighlights {
    #   id
    #   tag {
    #     id
    #     name
    #     keywords
    #     isGlobal
    #     isDefault
    #     color
    #   }
    # }
  }
`;
