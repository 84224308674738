import React, { FC } from 'react';
import { Icon24 } from '../../Icons/Icon';
import { Container } from './styles';

interface SpinnerProps {
  size?: 'large' | 'small';
}

const Spinner: FC<SpinnerProps> = ({ size = 'small' }) => {
  return (
    <Container size={size}>
      <Icon24.AISpinner />
    </Container>
  );
};

export default Spinner;
