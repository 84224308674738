import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const ProjectIconWrapper = styled.div`
  ${breakpoint.Mobile`
    display: none;
  `}
  ${breakpoint.DesktopSmall`
    display: none;
  `}
`;

export const ProjectName = styled.div`
  overflow: hidden;
  padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 36rem;

  ${breakpoint.Mobile`
    max-width: 150px;
  `}

  ${breakpoint.MobileSmall`
    max-width: 106px;
  `}
`;

export const RightSideContainer = styled.div`
  display: flex;
  text-align: left;
  white-space: nowrap;
  align-items: center;
`;
export const FolderInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  ${breakpoint.Mobile`
    display: none;
  `}

  ${breakpoint.DesktopSmall`
    display: none;
  `}
`;

export const FolderName = styled.div`
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.14px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 11rem;

  ${breakpoint.DesktopSmall`
    width: auto;
  `}

  ${breakpoint.Mobile`
    width: auto;
  `}
`;

export const AvatarWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 8px;
`;

export const UserName = styled.div`
  overflow: hidden;
  margin-left: 0.5rem;
  white-space: nowrap;
  flex: 1 1 0%;

  ${breakpoint.Mobile`
    display: none;
  `}

  ${breakpoint.DesktopSmall`
    display: none;
  `}
`;

export const ProjectDate = styled.div`
  color: rgba(59, 38, 81, 0.6);

  ${breakpoint.Mobile`
    font-size: 14px;
    line-height: 22px;
  `}
`;

export const ViewOnly = styled.div`
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin-left: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.125rem;
  background-color: rgba(243, 239, 246, 1);

  ${breakpoint.Mobile`
    display: none;
  `}

  ${breakpoint.DesktopSmall`
    display: none;
  `}
`;

export const ProjectDateContainer = styled.div`
  display: flex;
  padding-left: 12px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${breakpoint.Mobile`
    padding-left: 0;
  `}
`;
