import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Checklist, { ChecklistItem } from '.';
import ribbonSrc from '../../assets/projectChecklistRibbon.png';
import { useCreateDocument } from '../../Hooks/useDocuments';
import { useFetchBoard } from '../../Hooks/useBoards';
import usePermissions from '../../Hooks/usePermissions';
import { ProjectChecklistContext } from '../../Context/ProjectChecklistContext';

interface Props {
  dashboardId: string;
}

const ProjectChecklist = ({ dashboardId }: Props): JSX.Element => {
  const history = useHistory();
  const [loadingDashboard, dashboard] = useFetchBoard(dashboardId);
  const { canCreateData } = usePermissions();

  const [createDocument] = useCreateDocument();

  const {
    dataAdded,
    highlightAdded,
    summaryAdded,
    insightAdded,
    notesAdded,
    customTemplateAdded,
    teammateAdded,
  } = useContext(ProjectChecklistContext);

  const completed =
    dataAdded &&
    highlightAdded &&
    summaryAdded &&
    insightAdded &&
    notesAdded &&
    customTemplateAdded &&
    teammateAdded;

  const checklistItems: ChecklistItem[] = [
    {
      header: 'Add data',
      description: 'Add video or audio recordings, documents, or notes to Data.',
      cta: 'New document',
      onCta: async () => {
        const doc = await createDocument(dashboardId);
        history.push(`/projects/${dashboardId}/data/docs/${doc.id}`);
      },
      completed: dataAdded,
    },
    {
      header: 'AI Summary',
      description: 'Summarize a data file from one of our dozens of AI Summary Templates.',
      learnMore: 'Learn more about AI Templates',
      learnMoreHref: 'https://help.notably.ai/en/articles/7263946-what-are-ai-summary-templates',
      completed: summaryAdded,
    },
    {
      header: 'Highlight & tag',
      description: 'Highlight and tag data or do it automatically with AI.',
      learnMore: 'Learn more about tagging',
      learnMoreHref: 'http://help.notably.ai/en/articles/6050951-using-tags-in-notably',
      completed: highlightAdded,
    },
    {
      header: 'Add highlights to Analysis',
      description: 'Add Data to Analysis to discover Insight.',
      learnMore: 'Learn more about adding highlights to Analysis',
      learnMoreHref: 'https://help.notably.ai/en/articles/5269885-what-is-a-note',
      completed: notesAdded,
    },
    {
      header: 'New Insight',
      description:
        'Create a new insight from an AI Insight Template with AI or create one from scratch.',
      cta: 'Create insight',
      onCta: async () => {
        history.push(`/projects/${dashboardId}/insights`);
      },
      completed: insightAdded,
    },
    {
      header: 'Custom Template',
      description: 'Ask your data anything with a custom AI template.',
      cta: 'Create template',
      onCta: async () => {
        history.push(`/templates`);
      },
      completed: customTemplateAdded,
    },
    {
      header: 'Invite your Team',
      description: 'Invite at least one other person as an editor or viewer to learn together.',
      cta: 'Invite team',
      onCta: async () => {
        history.push(`/settings/users`);
      },
      completed: teammateAdded,
    },
  ];

  if (loadingDashboard || dashboard?.isSample || !canCreateData || completed) return <></>;

  return (
    <Checklist
      id={dashboardId}
      position="left"
      ribbonSrc={ribbonSrc}
      checklistItems={checklistItems}
      heading="New project checklist"
    />
  );
};

export default ProjectChecklist;
