import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

const AUTH0_AUDIENCE = (import.meta.env.VITE_AUTH0_AUDIENCE ||
  'http://notably-dev.herokuapp.com/graphql') as string;
const AUTH0_DOMAIN = (import.meta.env.VITE_AUTH0_DOMAIN || 'auth.notably.ai') as string;
const AUTH0_CLIENT_ID = (import.meta.env.VITE_AUTH0_CLIENT_ID ||
  '8KVYfyySTU106qtEbsNsFub6XKenfV3H') as string;

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      audience={AUTH0_AUDIENCE}
      responseType={'token id_token'}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
