import React from 'react';
import { HeroImage } from '../../Components/EmptyState2';
import participantsEmptyImage from '../../assets/empty-states/participants.png';
import Button from '../../Components/Button';
import { PlusIcon } from '@heroicons/react/outline';

interface Props {
  canCreateParticipants: boolean;
  handleCreate: (e: React.MouseEvent) => void;
}

const ParticipantDetailEmpty = ({ canCreateParticipants, handleCreate }: Props): JSX.Element => {
  return (
    <div className={'flex-col overflow-hidden justify-center items-center flex w-full h-auto'}>
      <div className="text-center">
        <HeroImage src={participantsEmptyImage} wide></HeroImage>
        <p className="text-md font-medium mt-1 px-2 pb-2 pt-3">
          Select a participant from the list or create a new participant.
        </p>
        <div>
          {canCreateParticipants && (
            <Button type={'primary'} onClick={handleCreate}>
              <PlusIcon className="text-white w-5 h-5 mr-1" />
              New participant
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticipantDetailEmpty;
