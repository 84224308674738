import React, { FC, useRef, useState } from 'react';
import { Icon24 } from '../../Icons/Icon';
import {
  ButtonWrapper,
  CommentsContainer,
  CommentsNumber,
  Container,
  Header,
  InputButtons,
  InputContainer,
  InputHeader,
  InputUserName,
  Title,
} from './styles';
import Comment from './Comment/Comment';
import Avatar from '../Avatar';
import Input from '../Input/Input';
import Button from '../Button';
import { ID } from '../../Models';
import { useCreateInsightComment, useFetchInsightComments } from '../../Hooks/useInsights';
import useUsers from '../../Hooks/useUsers';

interface CommentsProps {
  insightId: ID;
  readonly?: boolean;
}

const Comments: FC<CommentsProps> = ({ insightId, readonly }) => {
  const { data: comments } = useFetchInsightComments(insightId);
  const { currentUser: getCurrentUser } = useUsers();
  const [, currentUser] = getCurrentUser();
  const [createComment] = useCreateInsightComment();
  const [comment, setComment] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCreateComment = () => {
    if (!comment.length) return;
    createComment(insightId, comment);
    setComment('');
  };

  return (
    <Container>
      <Header>
        <Icon24.Chat /> {!!comments?.length && <CommentsNumber>{comments.length}</CommentsNumber>}
        <Title>Comments</Title>
      </Header>

      <CommentsContainer>
        {comments?.map((comment) => (
          <Comment key={comment.id} insightId={insightId} comment={comment} readonly={readonly} />
        ))}
      </CommentsContainer>

      {!readonly && (
        <InputContainer>
          <InputHeader>
            <Avatar user={currentUser} />
            <InputUserName>{currentUser.name} (you)</InputUserName>
          </InputHeader>
          <Input
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCreateComment();
              }
            }}
            placeholder="Add a comment..."
            onFocus={() => setShowButtons(true)}
            ref={inputRef}
          />
          <InputButtons show={showButtons}>
            <ButtonWrapper>
              <Button
                type="secondary"
                onClick={() => {
                  setComment('');
                  setShowButtons(false);
                  inputRef.current?.blur();
                }}
              >
                Cancel
              </Button>
            </ButtonWrapper>
            <Button disabled={!comment} onClick={handleCreateComment}>
              Comment
            </Button>
          </InputButtons>
        </InputContainer>
      )}
    </Container>
  );
};

export default Comments;
