import React from 'react';

export default function ComboLineTypeIcon({ dashed = false }) {
  return (
    <>
      {dashed ? (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="1"
            y1="10.8993"
            x2="10.8995"
            y2="0.999768"
            stroke="#3B2651"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="3 3"
          />
        </svg>
      ) : (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="1"
            y1="10.8993"
            x2="10.8995"
            y2="0.999768"
            stroke="#3B2651"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
}
