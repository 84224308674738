import React from 'react';

export default function EmojiNeutralIcon({ ...props }) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 10C16.75 13.7279 13.7279 16.75 10 16.75C6.27208 16.75 3.25 13.7279 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.7279 3.25 16.75 6.27208 16.75 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM14 12H5.99998V13H14V12ZM13.5 8.125C13.5 8.60825 13.1082 9 12.625 9C12.1418 9 11.75 8.60825 11.75 8.125C11.75 7.64175 12.1418 7.25 12.625 7.25C13.1082 7.25 13.5 7.64175 13.5 8.125ZM7.125 9C7.60825 9 8 8.60825 8 8.125C8 7.64175 7.60825 7.25 7.125 7.25C6.64175 7.25 6.25 7.64175 6.25 8.125C6.25 8.60825 6.64175 9 7.125 9Z"
        fill="#382152"
        fillOpacity="0.4"
      />
    </svg>
  );
}
