import { useMutation, useApolloClient, gql } from '@apollo/client';

const FETCH_IMAGE_GENERATION_TASK = gql`
  query fetchImageGenerationTask($taskId: BigInt!) {
    imageGenerationTask(id: $taskId) {
      id
      result
      status
    }
  }
`;

const GENERATE_INSIGHT_COVER = gql`
  mutation generateInsightCover($dashboardId: String, $stylePreset: String, $prompt: String) {
    generateInsightCover(
      input: { dashboardId: $dashboardId, stylePreset: $stylePreset, prompt: $prompt }
    ) {
      status
      success
      taskId
    }
  }
`;

const GENERATE_SD_PROMPT = gql`
  mutation generateSDPrompt($text: String, $stylePreset: String) {
    generateSDPrompt(input: { text: $text, stylePreset: $stylePreset }) {
      prompt
      success
    }
  }
`;

type useImageGenerationApi = {
  generateSDPrompt: (
    text: string,
    stylePreset: string
  ) => Promise<{ prompt: string; success: boolean }>;
  generateInsightCover: (
    dashboardId: string,
    stylePreset: string,
    prompt: string
  ) => Promise<{ status: string; success: boolean; taskId: string }>;

  fetchImageGenerationTask: (
    taskId: string
  ) => Promise<{ result: string; status: string; id: string }>;
};

export const useImageGeneration = (): useImageGenerationApi => {
  const client = useApolloClient();

  const [generateSDPromptMutation] = useMutation(GENERATE_SD_PROMPT);
  const [generateInsightCoverMutation] = useMutation(GENERATE_INSIGHT_COVER);

  const generateSDPrompt = async (text: string, stylePreset: string) => {
    const res = await generateSDPromptMutation({
      variables: {
        text,
        stylePreset,
      },
    });
    return res?.data?.generateSDPrompt;
  };
  const generateInsightCover = async (dashboardId: string, stylePreset: string, prompt: string) => {
    const res = await generateInsightCoverMutation({
      variables: {
        dashboardId,
        stylePreset,
        prompt,
      },
    });
    return res?.data?.generateInsightCover;
  };

  const fetchImageGenerationTask = async (
    taskId: string
  ): Promise<{ result: string; status: string; id: string }> => {
    const {
      data: { imageGenerationTask },
    } = await client.query({
      query: FETCH_IMAGE_GENERATION_TASK,
      variables: { taskId },
      fetchPolicy: 'network-only',
    });
    return imageGenerationTask;
  };

  return {
    generateSDPrompt,
    generateInsightCover,
    fetchImageGenerationTask,
  };
};
