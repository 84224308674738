import React from 'react';

export default function HelpIcon({ ...p }) {
  return (
    <svg
      {...p}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <circle cx="18" cy="18" r="16" fill="#382152" />
        <path
          d="M16.1436 21.2444H18.0186V21.1506C18.0499 19.2131 18.5499 18.3694 19.9249 17.51C21.2999 16.6741 22.1124 15.4709 22.1124 13.7444C22.1124 11.3069 20.3311 9.52563 17.5811 9.52563C15.0499 9.52563 13.0421 11.0881 12.9249 13.7444H14.8936C15.0108 11.9006 16.2999 11.1506 17.5811 11.1506C19.0499 11.1506 20.2374 12.1194 20.2374 13.6506C20.2374 14.8928 19.5265 15.7834 18.6124 16.3381C17.0811 17.2678 16.1671 18.1741 16.1436 21.1506V21.2444ZM17.1436 25.8694C17.9171 25.8694 18.5499 25.2366 18.5499 24.4631C18.5499 23.6897 17.9171 23.0569 17.1436 23.0569C16.3702 23.0569 15.7374 23.6897 15.7374 24.4631C15.7374 25.2366 16.3702 25.8694 17.1436 25.8694Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
