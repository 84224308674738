import { useMutation, useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { nanoid } from 'nanoid';
import Showdown from 'showdown';
import { generateJSON } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import {
  FETCH_INSIGHT_COMMENTS,
  FETCH_INSIGHT_REACTIONS,
  FETCH_PROJECT_INSIGHTS,
  FETCH_PROJECT_INSIGHT_BY_CLIENT_ID,
  FETCH_RECENT_INSIGHTS,
} from '../GraphQL/queries';
import {
  CREATE_PROJECT_INSIGHT,
  UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID,
  DELETE_INSIGHT_BY_CLIENT_ID,
  CREATE_INSIGHT_REACTION,
  CREATE_INSIGHT_COMMENT,
  CREATE_INSIGHT_LIKE,
  DELETE_INSIGHT_REACTION,
  UPDATE_INSIGHT_COMMENT,
  DELETE_INSIGHT_COMMENT,
  DELETE_INSIGHT_LIKE,
  UPDATE_INSIGHT_REACTION,
} from '../GraphQL/mutations';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ID, Insight, TComment, TReaction } from '../Models';
import { TEditorEvidence } from '../Context/EvidenceContext';
import { useCreateEvidence } from './useEvidences';
import useUsers, { CurrentUserContext } from './useUsers';
import { ProjectChecklistContext } from '../Context/ProjectChecklistContext';

export const useFetchInsights = (dashboardId: string): { loading: boolean; data: any } => {
  const { loading, data } = useQuery(FETCH_PROJECT_INSIGHTS, {
    variables: { dashboardId },
  });
  return { loading, data: data?.projectInsights };
};

export const useFetchInsightByClientId = (
  clientId: string
): { loading: boolean; data: Insight; refetch: any } => {
  const { loading, data, refetch } = useQuery(FETCH_PROJECT_INSIGHT_BY_CLIENT_ID, {
    variables: { clientId },
  });
  return { loading, data: data?.projectInsightByClientId, refetch };
};

export const useFetchRecentInsights = (): { loading: boolean; data: Insight[] | undefined } => {
  const { loading, data } = useQuery(FETCH_RECENT_INSIGHTS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  return { loading, data: data?.projectInsights };
};

export const useFetchInsightReactions = (
  insightId: ID
): { loading: boolean; data: TReaction[]; refetch: () => void } => {
  const { loading, data, refetch } = useQuery(FETCH_INSIGHT_REACTIONS, {
    variables: { insightId },
  });
  return { loading, data: data?.reactions, refetch };
};

export const useFetchInsightComments = (
  insightId: ID
): { loading: boolean; data: TComment[]; refetch: () => void } => {
  const { loading, data, refetch } = useQuery(FETCH_INSIGHT_COMMENTS, {
    variables: { insightId },
  });
  return { loading, data: data?.comments, refetch };
};

export const useCreateInsightReaction = (): Array<
  (insightId: ID, emoji: string, clusterId: string) => void
> => {
  const currentUser = useContext(CurrentUserContext);

  const [createInsightReactionMutation] = useMutation(CREATE_INSIGHT_REACTION);

  return [
    (insightId: ID, emoji: string, clusterId: string) => {
      const res = createInsightReactionMutation({
        variables: {
          insightId,
          emoji,
          clusterId,
        },
        optimisticResponse: {
          createReaction: {
            __typename: 'CreateReactionPayload',
            reaction: {
              __typename: 'Reaction',
              id: Math.round(Math.random() * -1000000),
              clusterId: clusterId,
              emoji,
              insightId,
              createdAt: new Date().toISOString(),
              createdBy: currentUser?.id,
            },
          },
        },
        update: (cache, { data: { createReaction } }) => {
          if (!createReaction) return;

          const data: any = cache.readQuery({
            query: FETCH_INSIGHT_REACTIONS,
            variables: { insightId },
          });

          const newReaction = {
            ...createReaction.reaction,
            userByCreatedBy: currentUser,
          };

          cache.writeQuery({
            query: FETCH_INSIGHT_REACTIONS,
            variables: { insightId },
            data: {
              reactions: [...data.reactions, newReaction],
            },
          });
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_REACTIONS,
            variables: { insightId },
          },
        ],
      });
      return res;
    },
  ];
};

export const useUpdateInsightReaction = (): Array<
  (reactionId: ID, insightId: ID, clusterId: string) => void
> => {
  const [updateInsightReactionMutation] = useMutation(UPDATE_INSIGHT_REACTION);

  return [
    (reactionId: ID, insightId: ID, clusterId: string) => {
      return updateInsightReactionMutation({
        variables: {
          reactionId,
          input: {
            clusterId,
          },
        },
        optimisticResponse: {
          updateReaction: {
            reaction: {
              __typename: 'Reaction',
              id: reactionId,
              clusterId,
            },
          },
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          },
        ],
      });
    },
  ];
};

export const useDeleteInsightReaction = (): Array<(insightId: ID, reactionId: ID) => any> => {
  const currentUser = useContext(CurrentUserContext);
  const [deleteInsightReactionMutation] = useMutation(DELETE_INSIGHT_REACTION);

  return [
    async (insightId: ID, reactionId: ID) => {
      const res = deleteInsightReactionMutation({
        variables: {
          id: reactionId,
        },
        optimisticResponse: {
          deleteReaction: {
            __typename: 'DeleteReactionPayload',
            reaction: {
              __typename: 'Reaction',
              id: reactionId,
              clusterId: '',
              insightId,
              createdBy: currentUser?.id,
            },
          },
        },
        update: (cache, { data: { deleteReaction } }) => {
          const data: any = cache.readQuery({
            query: FETCH_INSIGHT_REACTIONS,
            variables: { insightId },
          });

          cache.writeQuery({
            query: FETCH_INSIGHT_REACTIONS,
            variables: { insightId },
            data: {
              reactions: data.reactions.filter((x: any) => x.id !== deleteReaction.reaction.id),
            },
          });
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_REACTIONS,
            variables: { insightId },
          },
        ],
      });
      return res;
    },
  ];
};

export const useCreateInsightComment = (): Array<(insightId: ID, content: string) => void> => {
  const currentUser = useContext(CurrentUserContext);
  const [createInsightCommentMutation] = useMutation(CREATE_INSIGHT_COMMENT);

  return [
    (insightId: ID, content: string) => {
      return createInsightCommentMutation({
        variables: {
          insightId,
          content,
        },
        optimisticResponse: {
          createComment: {
            __typename: 'CreateCommentPayload',
            comment: {
              __typename: 'Comment',
              id: Math.round(Math.random() * -1000000),
              content,
              likes: [],
              createdAt: new Date().toISOString(),
              createdBy: currentUser?.id,
            },
          },
        },
        update: (cache, { data: { createComment } }) => {
          if (!createComment) return;

          const data: any = cache.readQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          });

          const newComment = {
            id: Math.round(Math.random() * -1000000),
            ...createComment.comment,
            userByCreatedBy: currentUser,
          };

          cache.writeQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
            data: {
              comments: [...data.comments, newComment],
            },
          });
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          },
        ],
      });
    },
  ];
};

export const useUpdateInsightComment = (): Array<
  (commentId: ID, insightId: ID, content: string) => void
> => {
  const [updateInsightCommentMutation] = useMutation(UPDATE_INSIGHT_COMMENT);

  return [
    (commentId: ID, insightId: ID, content: string) => {
      return updateInsightCommentMutation({
        variables: {
          commentId,
          input: {
            content,
          },
        },
        optimisticResponse: {
          updateComment: {
            comment: {
              __typename: 'Comment',
              id: commentId,
              content,
            },
          },
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          },
        ],
      });
    },
  ];
};

export const useDeleteInsightComment = (): Array<(commentId: ID, insightId: ID) => void> => {
  const [deleteInsightCommentMutation] = useMutation(DELETE_INSIGHT_COMMENT);

  return [
    (commentId: ID, insightId: ID) => {
      return deleteInsightCommentMutation({
        variables: {
          commentId,
        },
        optimisticResponse: {
          deleteComment: {
            comment: {
              __typename: 'Comment',
              id: commentId,
            },
          },
        },
        update: (cache, { data: { deleteComment } }) => {
          const data: any = cache.readQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          });

          cache.writeQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
            data: {
              comments: data.comments.filter((x: any) => x.id !== deleteComment.comment.id),
            },
          });
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          },
        ],
      });
    },
  ];
};

export const useCreateCommentLike = (): Array<(insightId: ID, commentId: ID) => void> => {
  const currentUser = useContext(CurrentUserContext);
  const [createCommentLikeMutation] = useMutation(CREATE_INSIGHT_LIKE);

  return [
    (insightId: ID, commentId: ID) => {
      return createCommentLikeMutation({
        variables: {
          commentId,
        },
        optimisticResponse: {
          createLike: {
            __typename: 'CreateLikePayload',
            like: {
              __typename: 'Like',
              id: Math.round(Math.random() * -1000000),
              createdAt: new Date().toISOString(),
              createdBy: currentUser?.id,
            },
          },
        },
        update: (cache, { data: { createLike } }) => {
          if (!createLike) return;

          const data: any = cache.readQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          });

          const newLike = {
            id: Math.round(Math.random() * -1000000),
            ...createLike.like,
            userByCreatedBy: currentUser,
          };

          const newCommentsArr = data.comments.map((x: any) =>
            x.id === commentId ? { ...x, likes: [...x.likes, newLike] } : x
          );

          cache.writeQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
            data: {
              comments: newCommentsArr,
            },
          });
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          },
        ],
      });
    },
  ];
};

export const useDeleteCommentLike = (): Array<(insightId: ID, likeId: ID) => void> => {
  const [deleteCommentLikeMutation] = useMutation(DELETE_INSIGHT_LIKE);

  return [
    (insightId: ID, likeId: ID) => {
      return deleteCommentLikeMutation({
        variables: {
          likeId,
        },
        optimisticResponse: {
          deleteLike: {
            like: {
              __typename: 'Like',
              id: likeId,
            },
          },
        },
        update: (cache, { data: { deleteLike } }) => {
          const data: any = cache.readQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          });

          const newCommentsArr = data.comments.map((x: any) => ({
            ...x,
            likes: x.likes.filter((like: any) => like.id !== deleteLike.like.id),
          }));

          cache.writeQuery({
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
            data: {
              comments: newCommentsArr,
            },
          });
        },
        refetchQueries: [
          {
            query: FETCH_INSIGHT_COMMENTS,
            variables: { insightId },
          },
        ],
      });
    },
  ];
};

type TContentBlock = {
  type: string;
  attrs: TEditorEvidence;
};

type TDocument = {
  type: string;
  content: TContentBlock[];
};

export const useCreateInsight = (): Array<
  (options: {
    dashboardId: string;
    clientId?: string;
    themeIds?: string[];
    tagIds?: ID[];
    title?: string;
    summary?: string;
  }) => void
> => {
  const [createInsightMutation] = useMutation(CREATE_PROJECT_INSIGHT);
  const [createEvidence] = useCreateEvidence();
  const { insightAdded, markInsightAdded } = useContext(ProjectChecklistContext);

  return [
    async (options: {
      dashboardId: string;
      clientId?: string;
      themeIds?: string[];
      tagIds?: ID[];
      title?: string;
      summary?: string;
    }) => {
      const { dashboardId, clientId, themeIds, tagIds, title, summary } = options;

      const content: TDocument = { type: 'doc', content: [] };
      let entityIdsForThemes: string[] = [];
      let entityIdsFoTags: string[] = [];

      if (title && summary) {
        const converter = new Showdown.Converter();
        const convertedText = converter.makeHtml(summary.trim());
        const jsonContent = generateJSON(convertedText || '', [StarterKit]).content;
        content.content = jsonContent;

        if (themeIds?.length) {
          entityIdsForThemes = themeIds.map(() =>
            Math.floor(Math.random() * Date.now()).toString(16)
          );
          themeIds.forEach((themeId, index) => {
            content.content.push({
              type: 'themeBlock',
              attrs: {
                id: entityIdsForThemes[index],
                dashboardId: dashboardId,
              },
            });
          });
        }
        if (tagIds?.length) {
          entityIdsFoTags = tagIds.map(() => Math.floor(Math.random() * Date.now()).toString(16));
          tagIds.forEach((tagId, index) => {
            content.content.push({
              type: 'tagBlock',
              attrs: {
                id: entityIdsFoTags[index],
                dashboardId: dashboardId,
              },
            });
          });
        }
      }

      const { data } = await createInsightMutation({
        variables: {
          input: {
            dashboardId,
            clientId: clientId || nanoid(10),
            content: content.content.length ? JSON.stringify(content) : '',
            title,
          },
        },
        refetchQueries: [
          {
            query: FETCH_PROJECT_INSIGHTS,
            variables: { dashboardId },
          },
        ],
      });

      const insight = data?.createProjectInsight?.projectInsight;

      if (themeIds?.length) {
        for (const themeId of themeIds) {
          await createEvidence({
            dashboardId: dashboardId,
            entityId: entityIdsForThemes[themeIds.indexOf(themeId)],
            parentInsightId: insight.id,
            themeId: themeId,
          });
        }
      }
      if (tagIds?.length) {
        for (const tagId of tagIds) {
          await createEvidence({
            dashboardId: dashboardId,
            entityId: entityIdsFoTags[tagIds.indexOf(tagId)],
            parentInsightId: insight.id,
            tagId,
          });
        }
      }

      if (!insightAdded) {
        markInsightAdded();
      }

      return data;
    },
  ];
};

type TInsightRes = {
  projectInsight: any;
};

export const useUpdateInsightByClientId = (): Array<
  (clientId: string, input: any) => Promise<TInsightRes>
> => {
  const [updateInsightMutation] = useMutation(UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID);

  return [
    async (clientId: string, input: any) => {
      const res = await updateInsightMutation({
        variables: {
          clientId,
          input: { ...input, updatedAt: new Date().toISOString() },
        },
        refetchQueries: [
          {
            query: FETCH_PROJECT_INSIGHT_BY_CLIENT_ID,
            variables: { clientId },
          },
        ],
      });
      return res.data?.updateProjectInsight;
    },
  ];
};

export const useDeleteInsight = (): Array<(dashboardId: string, clientId: string) => void> => {
  const [deleteInsightMutation] = useMutation(DELETE_INSIGHT_BY_CLIENT_ID);

  return [
    (dashboardId: string, clientId: string) => {
      return deleteInsightMutation({
        variables: {
          clientId,
        },
        refetchQueries: [
          {
            query: FETCH_PROJECT_INSIGHTS,
            variables: { dashboardId },
          },
        ],
      });
    },
  ];
};

export default {
  useFetchInsights,
  useFetchInsightByClientId,
  useCreateInsight,
  useUpdateInsightByClientId,
  useDeleteInsight,
};
