import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import './index.css';
import App from './App';
import AuthorizedApolloProvider from './Components/ApolloProvider';
import { ErrorBoundary } from './Components';
import { IntercomProvider } from 'react-use-intercom';
import { init as initSentry, ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SegmentProvider } from 'react-segment-hooks';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import defaultTheme from './Consts/defaultTheme';
import Auth0ProviderWithHistory from './Components/Auth0ProviderWithHistory';

const intercomAppId = (import.meta.env.INTERCOM_APP_ID || 'ez1svbxn') as string;

initSentry({
  dsn: 'https://1e458dcf623b413bac0fca23c095f370@o1325500.ingest.sentry.io/6735152',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
  enabled: window.location.host.includes('app.notably'),
});

FullStory.init({
  orgId: 'o-1F9QQA-na1',
  devMode: !window.location.host.includes('app.notably'),
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <SentryErrorBoundary>
        <SegmentProvider apiKey="QoG4UOa8CM9ENFEdftSsRHqAw1HZ1b8P">
          <IntercomProvider appId={intercomAppId}>
            <BrowserRouter>
              <Auth0ProviderWithHistory>
                <AuthorizedApolloProvider>
                  <ThemeProvider theme={defaultTheme}>
                    <App />
                  </ThemeProvider>
                </AuthorizedApolloProvider>
              </Auth0ProviderWithHistory>
            </BrowserRouter>
          </IntercomProvider>
        </SegmentProvider>
      </SentryErrorBoundary>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
