/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';
import Popup from '../Components/Popup';
import SummaryFeedbackForm from '../Components/SummaryFeedbackForm';
import { RadioButtonOption } from '../Components/RadioButtons/RadioButtons';
import useAnalytics from '../Hooks/useAnalytics';
import { useShareFeedback } from '../Hooks/useFeedback';

type SummaryReject = {
  id: string;
  status: 'keep' | 'discard' | 'retry' | 'delete';
};

interface SummaryFeedbackContextProps {
  addSummaryForFeedback(summary: SummaryReject): void;
  trackKeepSummary(id: string): void;
}

export const SummaryFeedbackContext = React.createContext<SummaryFeedbackContextProps>({
  addSummaryForFeedback: () => {},
  trackKeepSummary: () => {},
});

interface SummaryFeedbackContextProviderProps {
  children: React.ReactNode;
}

export const SummaryFeedbackContextProvider: FC<SummaryFeedbackContextProviderProps> = ({
  children,
}) => {
  const { analytics } = useAnalytics();
  const [shareFeedback] = useShareFeedback();
  const [summaries, setSummaries] = useState<SummaryReject[]>([]);

  const addSummary = (summary: SummaryReject) => {
    setSummaries((currentSummaries) => currentSummaries.concat(summary));
  };

  const saveFeedback = (selectedOptions: RadioButtonOption[], comment: string) => {
    for (const summary of summaries) {
      analytics.sendEvent('summary_feedback', {
        summary_id: summary.id,
        status: summary.status,
        categories: selectedOptions.map((option) => option.value).join(', '),
        comment: comment,
      });

      shareFeedback(summary.id, {
        feedbackCategories: selectedOptions.map((option) => option.value),
        feedbackMessage: comment,
        feedbackStatus: summary.status,
      });
    }

    setSummaries([]);
  };

  const trackKeepSummary = (id: string) => {
    shareFeedback(id, {
      feedbackCategories: null,
      feedbackMessage: null,
      feedbackStatus: 'keep',
    });
  };

  const cancelFeedback = () => {
    analytics.sendEvent('summary_feedback_skip');
    setSummaries([]);
  };

  return (
    <SummaryFeedbackContext.Provider
      value={{
        addSummaryForFeedback: addSummary,
        trackKeepSummary,
      }}
    >
      <>
        <Popup show={!!summaries.length}>
          <SummaryFeedbackForm onConfirm={saveFeedback} onCancel={cancelFeedback} />
        </Popup>

        {children}
      </>
    </SummaryFeedbackContext.Provider>
  );
};
