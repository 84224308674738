import styled from "styled-components";

export const ComponentWrapper = styled.div<{ align: string }>`
  display: flex;
  margin: 24px 0;
  justify-content: ${({ align }) => {
    switch(align) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
    }
  }}
`

export const ResizePoint = styled.div`
  display: none;
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: -6px;
  right: -6px;
  border: 2px solid #3B2651;
  background-color: #ffffff;
  cursor: nwse-resize;
`

export const ButtonPanel = styled.div`
  display: none;
  position: absolute;
  border-radius: 8px;
  width: 100%;
  max-width: 182px;
  bottom: 16px;
  left: calc(50% - 91px);
  align-items: center;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
`

export const ImageWrapper = styled.div<{ selected?: boolean }>`
  position: relative;
  border: 2px solid white;
  pointer-events: none;
  display: inline-block;

  ${({ selected }) => selected && `
    pointer-events: all;
    border-color: #3B2651;

    & ${ResizePoint} {
      display: block;
    }

    & ${ButtonPanel} {
      display: flex;
    }
  `}
`

export const AlignButton = styled.div<{ selected: boolean }>`
  padding: 4px 8px;
  background-color: ${({ selected }) => selected ? '#D7D3DC' : '#ffffff'};
  cursor: pointer;
  border: 1px solid #D7D3DC; 

  &:first-child {
    border-radius: 8px 0 0 8px;
    padding-left: 16px;
    border-right: none;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
    padding-right: 16px;
    border-left: none;
  }

  &:hover {
    background-color: #EBE7EA
  }
`
