import React, { FC } from 'react';
import { Icon24 } from '../../../../Icons/Icon';

import { Container, MenuButton, ButtonText, TagsCount } from './styles';

interface HighlightMenuProps {
  isTagActive: boolean;
  tagsCount?: number;
  onHighlight(): void;
  onSetTag(): void;
}

const HighlightMenu: FC<HighlightMenuProps> = ({
  isTagActive,
  tagsCount,
  onHighlight,
  onSetTag,
}) => {
  return (
    <Container>
      <MenuButton onClick={onHighlight} isActive={isTagActive}>
        <Icon24.Highlight />
        <ButtonText>{isTagActive ? ' Unhighlight' : 'Highlight'}</ButtonText>
      </MenuButton>
      <MenuButton onClick={onSetTag}>
        <Icon24.Tag />
        <ButtonText>Tag</ButtonText>
        {!!tagsCount && <TagsCount>{tagsCount}</TagsCount>}
      </MenuButton>
    </Container>
  );
};

export default HighlightMenu;
