import React, { useState, useRef, useEffect, FC } from 'react';
import { Container, HiddenSpan, Input } from './styles';

interface AutoScalingInputProps {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange(value: string): void;
  onBlur?(): void;
  onFocus?(): void;
  onEnter?(): void;
}

const AutoScalingInput: FC<AutoScalingInputProps> = ({
  value,
  placeholder,
  disabled,
  autoFocus,
  onChange,
  onBlur,
  onEnter,
  onFocus,
}) => {
  const [width, setWidth] = useState(0);
  const span = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    span.current && setWidth(span.current.offsetWidth);
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget?.blur();
      onEnter?.();
    }
  };

  return (
    <Container>
      <HiddenSpan id="hide" ref={span}>
        {value}
      </HiddenSpan>
      <Input
        type="text"
        style={{ width: width + 4 }}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </Container>
  );
};

export default AutoScalingInput;
