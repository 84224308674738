import React, { FC } from 'react';
import Masonry from 'react-masonry-css';
import { useHistory } from 'react-router-dom';

import { documentRoute, insightRoute, noteRoute, transcriptRoute } from '../../Utils/routeBuilder';

import {
  ResultTitle,
  ResultCount,
  EmptyStateText,
  ResultItemCard,
  ResultItemCardImage,
  ResultItemCardTitle,
  ResultItemCardProjectName,
  ResultCardsBlock,
  ResultFullContainer,
  ResultItemCardProjectNameContainer,
  RoundDivider,
  CreatedAt,
  ProjectLogoImage,
  AvatarContainer,
  NoteText,
  ResultItemDataCard,
  ResultItemDataTitle,
  ResultItemDataProjectNameContainer,
  DocumentNameContainer,
  IconWrapper,
} from './styles';
import { defaultCoverImages } from '../../Consts/coverImages';
import { format } from 'date-fns';
import { defaultProjectIcons } from '../../Consts/projectIcons';
import Avatar from '../Avatar';
import {
  DocumentIcon,
  DocumentTextIcon,
  MusicNoteIcon,
  VideoCameraIcon,
} from '@heroicons/react/outline';

interface SearchResultFullProps {
  data: any;
  dashboards: any;
  type?: 'full' | 'light';
  onClose: () => void;
}

const SearchResultFull: FC<SearchResultFullProps> = ({
  data,
  dashboards,
  onClose,
  type = 'full',
}) => {
  const history = useHistory();

  if (
    !data ||
    (!data.documents?.length &&
      !data.notes?.length &&
      !data.notes?.length &&
      !data.transcriptions?.length &&
      !data.projectInsights?.length)
  ) {
    return <EmptyStateText>No data found</EmptyStateText>;
  }
  return (
    <ResultFullContainer type={type}>
      {!!data.projectInsights?.length && (
        <ResultCardsBlock>
          <ResultTitle>
            Insights <ResultCount>{data.projectInsights.length}</ResultCount>
          </ResultTitle>
          <Masonry
            breakpointCols={type === 'light' ? 2 : 3}
            className="basic-masonry-grid full-width-masonry-grid"
            columnClassName="basic-masonry-grid_column"
          >
            {data.projectInsights.map((insight: any) => {
              const selectedImage = insight?.customCover
                ? { name: 'custom', url: insight.customCover, position: '50% 50%' }
                : defaultCoverImages.find((image) => image.name === insight?.cover);
              const currentProject = dashboards.find(
                (dashboard: any) => dashboard.id === insight.dashboardId
              );
              const icon =
                defaultProjectIcons.find((icon) => icon.name === currentProject.cover) ||
                defaultProjectIcons[0];
              return (
                <ResultItemCard
                  key={insight.id}
                  onClick={() => {
                    history.push(insightRoute(insight.dashboardId, insight.clientId));
                    onClose();
                  }}
                >
                  {selectedImage && <ResultItemCardImage src={selectedImage.url} />}

                  <ResultItemCardTitle>{insight.title || 'Untitled'}</ResultItemCardTitle>
                  <ResultItemCardProjectNameContainer>
                    <ProjectLogoImage src={icon?.url} alt="Project cover" />
                    <ResultItemCardProjectName>
                      {currentProject?.name || 'Untitled'}
                    </ResultItemCardProjectName>
                    <RoundDivider />
                    <CreatedAt>{format(new Date(insight.createdAt), 'LLL d, yyyy')}</CreatedAt>
                  </ResultItemCardProjectNameContainer>
                </ResultItemCard>
              );
            })}
          </Masonry>
        </ResultCardsBlock>
      )}

      {!!data.notes?.length && (
        <ResultCardsBlock small>
          <ResultTitle>
            Notes <ResultCount>{data.notes.length}</ResultCount>
          </ResultTitle>
          <Masonry
            breakpointCols={type === 'light' ? 2 : 3}
            className="basic-masonry-grid full-width-masonry-grid"
            columnClassName="basic-masonry-grid_column"
          >
            {data.notes.map((item: any) => {
              const currentProject = dashboards.find(
                (dashboard: any) => dashboard.id === item.dashboardId
              );
              const icon =
                defaultProjectIcons.find((icon) => icon.name === currentProject.cover) ||
                defaultProjectIcons[0];

              return (
                <ResultItemCard
                  key={item.id}
                  onClick={() => {
                    history.push(noteRoute(item.dashboardId, item.id));
                    onClose();
                  }}
                >
                  <ResultItemCardProjectNameContainer>
                    <ProjectLogoImage src={icon?.url} alt="Project cover" />
                    <ResultItemCardProjectName>
                      {currentProject?.name || 'Untitled'}
                    </ResultItemCardProjectName>
                    <RoundDivider />
                  </ResultItemCardProjectNameContainer>

                  <NoteText>{item.text || 'Untitled'}</NoteText>

                  <ResultItemCardProjectNameContainer>
                    <AvatarContainer>
                      <Avatar user={item.userByCreatedBy?.name} />
                    </AvatarContainer>
                    <ResultItemCardProjectName>
                      {item.userByCreatedBy?.name || 'Unknown'}
                    </ResultItemCardProjectName>
                    <RoundDivider />
                    <CreatedAt>{format(new Date(item.createdAt), 'LLL d, yyyy')}</CreatedAt>
                  </ResultItemCardProjectNameContainer>
                </ResultItemCard>
              );
            })}
          </Masonry>
        </ResultCardsBlock>
      )}

      {(!!data.documents?.length || !!data.transcriptions?.length) && (
        <ResultCardsBlock>
          <ResultTitle>
            Data <ResultCount>{data.documents.length + data.transcriptions.length}</ResultCount>
          </ResultTitle>

          {data.documents?.map((item: any) => {
            const currentProject = dashboards.find(
              (dashboard: any) => dashboard.id === item.dashboardId
            );
            const icon =
              defaultProjectIcons.find((icon) => icon.name === currentProject.cover) ||
              defaultProjectIcons[0];

            return (
              <ResultItemDataCard
                key={item.id}
                onClick={() => {
                  history.push(documentRoute(item.dashboardId, item.id));
                  onClose();
                }}
              >
                <DocumentNameContainer>
                  <IconWrapper>
                    <DocumentIcon className={'w-6 h-6 mr-2'} />
                  </IconWrapper>
                  <ResultItemDataTitle>{item.name || 'Untitled'}</ResultItemDataTitle>
                </DocumentNameContainer>

                <ResultItemDataProjectNameContainer>
                  <ProjectLogoImage src={icon?.url} alt="Project cover" />
                  <ResultItemCardProjectName>
                    {currentProject?.name || 'Untitled'}
                  </ResultItemCardProjectName>
                  <RoundDivider />
                  <CreatedAt>{format(new Date(item.createdAt), 'LLL d, yyyy')}</CreatedAt>
                </ResultItemDataProjectNameContainer>
              </ResultItemDataCard>
            );
          })}

          {data.transcriptions?.map((item: any) => {
            const currentProject = dashboards.find(
              (dashboard: any) => dashboard.id === item.dashboardId
            );
            const icon =
              defaultProjectIcons.find((icon) => icon.name === currentProject.cover) ||
              defaultProjectIcons[0];

            return (
              <ResultItemDataCard
                key={item.id}
                onClick={() => {
                  history.push(transcriptRoute(item.dashboardId, item.id));
                  onClose();
                }}
              >
                <DocumentNameContainer>
                  <IconWrapper>
                    {item.file ? (
                      item.fileType === 'audio' ? (
                        <MusicNoteIcon className={'w-6 h-6 mr-2'} />
                      ) : (
                        <VideoCameraIcon className={'w-6 h-6 mr-2'} />
                      )
                    ) : (
                      <DocumentTextIcon className={'w-6 h-6 mr-2'} />
                    )}
                  </IconWrapper>
                  <ResultItemDataTitle>{item.name || 'Untitled'}</ResultItemDataTitle>
                </DocumentNameContainer>
                <ResultItemDataProjectNameContainer>
                  <ProjectLogoImage src={icon?.url} alt="Project cover" />
                  <ResultItemCardProjectName>
                    {currentProject?.name || 'Untitled'}
                  </ResultItemCardProjectName>
                  <RoundDivider />
                  <CreatedAt>{format(new Date(item.createdAt), 'LLL d, yyyy')}</CreatedAt>
                </ResultItemDataProjectNameContainer>
              </ResultItemDataCard>
            );
          })}
        </ResultCardsBlock>
      )}
    </ResultFullContainer>
  );
};

export default SearchResultFull;
