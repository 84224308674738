export const ZOOM = 'zoom';
export const SLACK = 'slack';
export const GOOGLE = 'google';
export const ONEDRIVE = 'onedrive';
export const REDIRECT_URI =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '') +
  '/settings/integrations';

export const GOOGLE_REDIRECT_URI =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '');

export const GoogleClientId =
  import.meta.env.GOOGLE_CLIENT_ID ??
  '682452238278-7ihrjt3b19mm9s056q2519q116qjubo7.apps.googleusercontent.com';
