import { useMutation, useQuery } from '@apollo/client';

import { FETCH_CANVAS_IMAGES, FETCH_CANVAS_IMAGE } from '../GraphQL/queries';
import {
  CREATE_CANVAS_IMAGE,
  UPDATE_CANVAS_IMAGE,
  DELETE_CANVAS_IMAGE,
} from '../GraphQL/mutations';
import { ID } from '../Models';

export const useFetchCanvasImages = (dashboardId: string): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_CANVAS_IMAGES, {
    variables: { dashboardId },
  });
  return [
    loading,
    data?.canvasImages?.map((canvasImage: any) => ({
      ...canvasImage,
      styles: canvasImage?.styles ? JSON.parse(canvasImage.styles) : null,
    })) || [],
  ];
};

export const useFetchCanvasImage = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_CANVAS_IMAGE, {
    variables: { id },
  });
  return [loading, data?.canvasImage];
};

export const useCreateCanvasImage = (): [(dashboardId: string, canvasImage?: any) => any] => {
  const [createMutation] = useMutation(CREATE_CANVAS_IMAGE);

  return [
    async (dashboardId: string, canvasImage?: any) => {
      const preparedInput = { ...canvasImage };
      if (canvasImage.styles) preparedInput.styles = JSON.stringify(canvasImage.styles);
      const res = await createMutation({
        variables: {
          input: {
            dashboardId,
            ...(preparedInput || {}),
          },
        },
        refetchQueries: [
          {
            query: FETCH_CANVAS_IMAGES,
            variables: { dashboardId },
          },
        ],
      });
      return res?.data?.createCanvasImage?.canvasImage;
    },
  ];
};

export const useUpdateCanvasImage = (): [(id: ID, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_CANVAS_IMAGE);

  return [
    (id: ID, input: any) => {
      const preparedInput = { ...input };
      if (input.styles) preparedInput.styles = JSON.stringify(input.styles);
      return updateMutation({
        variables: {
          id,
          input: preparedInput,
        },
        refetchQueries: [
          {
            query: FETCH_CANVAS_IMAGE,
            variables: { id },
          },
        ],
      });
    },
  ];
};

export const useDeleteCanvasImage = (): [(dashboardId: string, id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_CANVAS_IMAGE);

  return [
    (dashboardId: string, id: string) => {
      return deleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_CANVAS_IMAGES,
            variables: { dashboardId },
          },
        ],
      });
    },
  ];
};

export default {
  useFetchCanvasImage,
  useFetchCanvasImages,
  useCreateCanvasImage,
  useUpdateCanvasImage,
  useDeleteCanvasImage,
};
