import React, { useEffect, useState } from 'react';

import AITemplates from '../AITemplates';

import { Wrapper, Overlay, Container } from './styles';
import { TPrompt } from '../../Hooks/usePrompts';

interface SummaryGenerationModalProps {
  isOpen: boolean;
  onClose(): void;
  onConfirm(template: TPrompt): void;
}

const SummaryGenerationModal: React.FC<SummaryGenerationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const handleGenerateSummary = async (template: TPrompt) => {
    if (!template) return;
    onConfirm(template);
    onClose();
  };

  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(
        window.innerHeight - 240 > 600 ? window.innerHeight - 240 : window.innerHeight - 120
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen}>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <Container isOpen={isOpen} maxHeight={maxHeight}>
        {isOpen && (
          <AITemplates type="transcript" onConfirm={handleGenerateSummary} onCancel={onClose} />
        )}
      </Container>
    </Wrapper>
  );
};

export default SummaryGenerationModal;
