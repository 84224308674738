import React, { useEffect, useState } from 'react';
import { Modal } from '../';
import Button from '../Button';

interface EditorLinkModalProps {
  text: string;
  url: string;
  isOpen: boolean;
  onComplete({ text, url }: { text: string; url: string }): void;
  onClose(): void;
}

const EditorLinkModal: React.FC<EditorLinkModalProps> = ({
  text,
  url,
  isOpen,
  onComplete,
  onClose,
}) => {
  const [currentUrl, setCurrentUrl] = useState(url);
  const [currentText, setCurrentText] = useState(text);

  const handleSave = () => {
    onComplete({ url: currentUrl, text: currentText });
  };

  const handleCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    setCurrentUrl(url);
    setCurrentText(text);
  }, [text, url]);

  return (
    <Modal title={'Add Link'} isOpen={isOpen} onClose={handleCloseModal} maxWidth="400px">
      <div>
        <label htmlFor="url" className="block text-sm font-medium">
          URL
        </label>
        <input
          id={'url'}
          placeholder={'https://www.notably.ai'}
          required
          value={currentUrl}
          onChange={(e) => setCurrentUrl(e.target.value)}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
        />
      </div>
      <div className="pt-4">
        <label htmlFor="text" className="block text-sm font-medium">
          Text
        </label>
        <input
          id={'text'}
          placeholder={'notably'}
          required
          value={currentText}
          onChange={(e) => setCurrentText(e.target.value)}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
        />
      </div>
      <Button
        className={'mt-4 flex-inline justify-center mr-2'}
        onClick={handleSave}
        type={'primary'}
      >
        Save
      </Button>
      <Button
        className={'mt-4 flex-inline justify-center mr-2'}
        onClick={handleCloseModal}
        type={'secondary'}
      >
        Cancel
      </Button>
    </Modal>
  );
};

export default EditorLinkModal;
