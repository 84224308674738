import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ID, Insight, TagWithDetails } from '../../../Models';
import { Editor } from '@tiptap/react';
import { DownloadData, SummaryGenerationData } from '../../InfoEditorToolbar/InfoEditorToolbar';
import { useDashboardTags } from '../../../Hooks/useTags';
import { TInnerHighlight, HighlightsContext } from '../../../Context/HighlightsContext';
import { EvidenceContext, TEditorEvidence } from '../../../Context/EvidenceContext';
import { THighlight } from '../../../Models/Highlight';

interface WithHighlightsOptons {
  content?: string;
  editable?: boolean;
  withHighlights?: boolean;
  withSpeakers?: boolean;
  withEvidences?: boolean;
  withToolbar?: boolean;
  withToolbarMargin?: boolean;
  containerElt?: HTMLDivElement;
  placeholder?: string;
  insight?: Insight;
  isInsightsPanel?: boolean;
  onChange(content: any): void;
  onChangeText?(content: string): void;
  getSummaryGenerationData?(editor: Editor | null): SummaryGenerationData;
  getDownloadData?(editor: Editor | null): DownloadData;
}

export interface PassedComponentProps {
  content?: string;
  editable?: boolean;
  withHighlights?: boolean;
  withSpeakers?: boolean;
  withEvidences?: boolean;
  withToolbar?: boolean;
  withToolbarMargin?: boolean;
  containerElt?: HTMLDivElement;
  placeholder?: string;
  insight?: Insight;
  isInsightsPanel?: boolean;
  setHighlightsListRef?: React.MutableRefObject<(highlights: TInnerHighlight[]) => void>;
  setEvidencesListRef?: React.MutableRefObject<(evidences: TEditorEvidence[]) => void>;
  rawHighlightsRef?: React.MutableRefObject<THighlight[]>;
  removeHighlightFromDBRef?: React.MutableRefObject<(ids: ID[]) => void>;
  onChange?(content: any): void;
  onChangeText?(content: string): void;
  getSummaryGenerationData?(editor: Editor | null): SummaryGenerationData;
  getDownloadData?(editor: Editor | null): DownloadData;
}

const withApiCallbacks = (
  PassedComponent: React.ComponentType<PassedComponentProps>
): React.FC<WithHighlightsOptons> => {
  const WrappedComponent: React.FC<WithHighlightsOptons> = ({
    content,
    editable,
    withHighlights,
    withSpeakers,
    withEvidences,
    withToolbar,
    withToolbarMargin,
    containerElt,
    placeholder,
    insight,
    isInsightsPanel,
    onChange,
    onChangeText,
    getSummaryGenerationData,
    getDownloadData,
  }) => {
    const { setHighlightsList, setTags, rawHighlights, removeHighlightsFromDB } = useContext(
      HighlightsContext
    );
    const { setEditorEvidencesList } = useContext(EvidenceContext);
    const { dashboardId } = useParams<{ dashboardId: string }>();
    const [loadingTags, tags, refetchTags] = useDashboardTags(dashboardId, true, true);

    const setHighlightsListRef = useRef(setHighlightsList);
    setHighlightsListRef.current = setHighlightsList;

    const setEvidencesListRef = useRef(setEditorEvidencesList);
    setEvidencesListRef.current = setEditorEvidencesList;

    const rawHighlightsRef = useRef(rawHighlights);
    rawHighlightsRef.current = rawHighlights;

    const removeHighlightFromDBRef = useRef(removeHighlightsFromDB);
    removeHighlightFromDBRef.current = removeHighlightsFromDB;

    useEffect(() => {
      if (!loadingTags) {
        setTags(tags as TagWithDetails[]);
      }
    }, [loadingTags, tags]);

    return (
      <PassedComponent
        content={content}
        editable={editable}
        withHighlights={withHighlights}
        withSpeakers={withSpeakers}
        withEvidences={withEvidences}
        withToolbar={withToolbar}
        withToolbarMargin={withToolbarMargin}
        containerElt={containerElt}
        placeholder={placeholder}
        insight={insight}
        onChange={onChange}
        onChangeText={onChangeText}
        getSummaryGenerationData={getSummaryGenerationData}
        getDownloadData={getDownloadData}
        setHighlightsListRef={setHighlightsListRef}
        setEvidencesListRef={setEvidencesListRef}
        isInsightsPanel={isInsightsPanel}
        rawHighlightsRef={rawHighlightsRef}
        removeHighlightFromDBRef={removeHighlightFromDBRef}
      />
    );
  };

  return WrappedComponent;
};

export default withApiCallbacks;
