import { useOrganization } from './useOrganization';

type Features = {
  globalTagsEnabled: boolean;
  globalTagsEditable: boolean;
};

export const useFeatures = (): Features => {
  const [, org] = useOrganization();
  const plan = org?.plan;
  return {
    globalTagsEnabled: true,
    globalTagsEditable: plan !== 'free' && plan !== 'pro',
  };
};

export default useFeatures;
