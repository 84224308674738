export const insightPromptBoilerplate = `Summarize quotes and generate the title for the result. The title should be in the beginning of the text.

The body of the text should include the following sections:
”What we saw/heard”
”What it means”
”Why it matters”

First, summarize the data in 2-3 sentences.

In the section titled “What we saw/heard”

- Outline key thoughts, feelings, and statements from people.
- Use direct quotes as evidence.

In the section titled “What it means”

- Identify central patterns or theme in the data
- Offer insights into why people might think/feel/act this way.

In the section titled “Why it matters”

- Translate themes into actionable outcomes.
- Suggest steps to move this forward in a meaningful way.
`;

export const summaryPromptBoilerplate = `The body of the text should include the following sections:
”Challenges & Goals”
”Surprising Takeaways”
”Emerging Patterns”

First, summarize the data in 2-3 sentences.

In the section titled “Challenges & Goals”

- List main challenges discussed.
- Outline goals or motivations mentioned.
- Include direct quotes as examples.

In the section titled “Surprising Takeaways”

- Highlight unexpected patterns.
- Note surprising moments or statements.
- Use direct quotes for illustration.

In the section titled “Emerging Patterns”

- Identify 3-5 early patterns or themes.
- Explain the significance of each pattern.
- Support with specific examples from the conversation.
`;
