import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
`;

export const StatsItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 246px;
  margin-right: 10px;
  border-right: 1px solid #d7d3dc;

  &:last-child {
    border-right: none;
    margin-right: 0;
  }
`;

export const StatsContent = styled.div``;

export const StatsTitle = styled.div`
  font-weight: 600;
  line-height: 22px;
  font-size: 16px;
`;

export const StatsValue = styled.div`
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  color: rgba(59, 38, 81, 0.8);
`;

export const IconContainer = styled.div`
  margin-right: 10px;
  color: rgba(59, 38, 81, 0.4);
`;
