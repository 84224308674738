import React, { useState } from 'react';
import { PopupModal } from 'react-calendly';
import Button from '../Button';
import illustrationSrc from '../../assets/empty-states/tags.png';

import {
  Container,
  Illustration,
  Title,
  TitleText,
  TitleIconWrapper,
  Subtitle,
  Buttons,
  ButtonWrapper,
} from './styles';
import { Icon24 } from '../../Icons/Icon';
import { useOrganization } from '../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

const TagsUpgradeSuggestion: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const rootElt = document.getElementById('root');
  const [_, org] = useOrganization();
  const { user } = useAuth0();

  return (
    <Container>
      <Illustration src={illustrationSrc} />
      <Title>
        <TitleIconWrapper>
          <Icon24.ChevronBadge />
        </TitleIconWrapper>{' '}
        <TitleText>Available for Teams and Enterprise</TitleText>
      </Title>
      <Subtitle>
        Global tags are shared across all projects in your entire Notably workspace. Upgrade or book
        a demo to try it free for 14 days.
      </Subtitle>

      <Buttons>
        <ButtonWrapper>
          <Link to="/settings/plans">
            <Button>Upgrade</Button>
          </Link>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button type="secondary" onClick={() => setIsOpen(true)}>
            Book a demo
          </Button>
        </ButtonWrapper>
      </Buttons>

      {rootElt && (
        <PopupModal
          url="https://calendly.com/notably-sales/notably-onboarding"
          pageSettings={{
            hideEventTypeDetails: true,
            hideGdprBanner: true,
            textColor: '382152',
            primaryColor: '3b2651',
          }}
          prefill={{
            email: user?.email || '',
            name: user?.name || '',
            customAnswers: { a1: org.name || '' },
          }}
          onModalClose={() => setIsOpen(false)}
          open={isOpen}
          rootElement={rootElt}
        />
      )}
    </Container>
  );
};

export default TagsUpgradeSuggestion;
