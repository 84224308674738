import React, { FC } from 'react';
import {
  ChevronIcon,
  Container,
  IconContainer,
  NameWithIcon,
  TemplateCardHeader,
  TemplateCardTitle,
  TemplateDescription,
  TemplateName,
  TemplateNameWrapper,
} from './styles';
import { CATEGORIES } from '../../Consts/promptTemplates';
import { Icon24 } from '../../Icons/Icon';

interface TemplateCardProps {
  template: any;
  isActive?: boolean;
  onClick?: () => void;
}

const TemplateCard: FC<TemplateCardProps> = ({ template, isActive = false, onClick }) => {
  return (
    <Container key={template.id} onClick={onClick} isActive={isActive}>
      <ChevronIcon show={isActive}>
        <Icon24.Chevron />
      </ChevronIcon>
      <TemplateCardHeader>
        <TemplateCardTitle>
          {CATEGORIES.find((item) => item.id === template.category)?.title}
        </TemplateCardTitle>
      </TemplateCardHeader>
      <TemplateNameWrapper>
        <NameWithIcon>
          <IconContainer src={template.icon} />
          <TemplateName>{template.title}</TemplateName>
        </NameWithIcon>
      </TemplateNameWrapper>
      <TemplateDescription>{template.description}</TemplateDescription>
    </Container>
  );
};

export default TemplateCard;
