import React, { FC } from 'react';
import { Icon16, Icon24 } from '../../Icons/Icon';
import { DocumentTextIcon } from '@heroicons/react/outline';
import {
  FileItem,
  FileItemDeleteButton,
  FileItemContent,
  FileItemTitleIcon,
  FileItemTitle,
  FileItemTitleText,
  FileItemSize,
  ProgressBarContainer,
  ProgressBarLineUnderlayer,
  ProgressBarLine,
} from './styles';

interface FileCardProps {
  name: string;
  size: number;
  fileType?: string;
  progress: number;
  onDelete(name: string): void;
}

const formatFileSize = (fileSize: number) => {
  const i = Math.floor(Math.log(fileSize) / Math.log(1024));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (fileSize / Math.pow(1024, i)).toFixed(2) * 1 + ['b', 'kb', 'mb', 'gb', 'tb'][i];
};

const FileCard: FC<FileCardProps> = ({ name, size, progress, fileType, onDelete }) => {
  return (
    <FileItem key={name}>
      <FileItemDeleteButton onClick={() => onDelete(name)}>
        <Icon16.CloseBold />
      </FileItemDeleteButton>
      <FileItemContent>
        <FileItemTitleIcon>
          {fileType?.includes('officedocument') || fileType?.includes('text') ? (
            <DocumentTextIcon width={22} />
          ) : (
            <Icon24.Video />
          )}
        </FileItemTitleIcon>
        <FileItemTitle>
          <FileItemTitleText>{name}</FileItemTitleText>
          <FileItemSize>{formatFileSize(size)}</FileItemSize>
        </FileItemTitle>
      </FileItemContent>

      <ProgressBarContainer>
        <ProgressBarLineUnderlayer />
        <ProgressBarLine progress={progress} />
      </ProgressBarContainer>
    </FileItem>
  );
};

export default FileCard;
