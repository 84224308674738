import React, { ChangeEvent, ComponentProps, HTMLProps, memo, useContext } from 'react';
import { Text, Image, Group } from 'react-konva';
import useImage from 'use-image';
import { Selectable } from './Selectable';
import { isEqual } from 'lodash-es';
import StickyMenu from './StickyMenu';
import { DEFAULT_STEP, STICKY_FONT, STICKY_WIDTH, ParticipantBase, Tag } from '../Models';
import { NodeConfig } from 'konva/lib/Node';
import { TagIcon, UserIcon } from '@heroicons/react/outline';
import ReactDOMServer from 'react-dom/server';
import Konva from 'konva';
import { GroupByContext } from './InfiniteCanvas';

interface StickyProps extends NodeConfig {
  text: string;
  color: string;
  tags?: Tag[];
  showText: boolean;

  editorProps?: HTMLProps<HTMLTextAreaElement>;
  selectableProps?: Partial<ComponentProps<typeof Selectable>>;
  participant?: ParticipantBase;
  handToolActive: boolean;
}

function StickyInner({
  id,
  x,
  y,
  dashboardId,
  color,
  text,
  width,
  height,
  tags = [],
  selectableProps,
  participant,
  menu = true,
  showText,
  editorProps,
  updateNote,
  deleteNote,
  createNote,
  openNote,
  handToolActive,
}: StickyProps): JSX.Element {
  const groupRef = React.useRef<Konva.Group>(null);
  const textRef = React.useRef<Konva.Text>(null);
  // const { updateNote, deleteNote, createNote } = useNotes(dashboardId);

  const groupedBy = useContext(GroupByContext);

  const handleStickyInput = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    await updateNote('' + id, { text: e.target.value });
  };

  const onColorChange = (c: string) => updateNote('' + id, { color: c });

  const onDelete = () => deleteNote('' + id);

  const onEdit = () => {
    if (handToolActive) return;
    selectableProps?.onChange?.({
      selected: true,
      editable: !selectableProps?.editable,
    });
  };

  const onOpen = async () => {
    openNote(id);
  };

  const onCopy = async () => {
    await createNote(dashboardId, {
      color,
      text,
      x: (x ?? 0) + STICKY_WIDTH + DEFAULT_STEP,
      y,
      tagsNotes: {
        create: tags?.map((tag) => ({ tagId: tag.id })) || [],
      },
      participantId: participant?.id,
    });
  };

  function getSvgDataUrl(el: JSX.Element) {
    return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup(el));
  }

  const userImage = React.useMemo(
    () => getSvgDataUrl(<UserIcon style={{ color: '#382152' }} />),
    []
  );
  const tagImage = React.useMemo(() => getSvgDataUrl(<TagIcon style={{ color: '#382152' }} />), []);

  const [userIcon] = useImage(userImage);
  const [tagIcon] = useImage(tagImage);

  // TODO: we don't need to re-cache on every component update
  // we can skip it when only position is changed, probably in some other cases too
  // React.useEffect(() => {
  //   groupRef.current?.cache();
  // });

  return (
    <Selectable
      id={`sticky-${id}`}
      name={`${id}`}
      x={x}
      y={y}
      width={width}
      height={height}
      color={color}
      simplified={!showText}
      handToolActive={handToolActive}
      {...selectableProps}
      menu={
        menu && (
          <StickyMenu
            color={color}
            colorChangeEnabled={groupedBy === 'theme'}
            onCopy={onCopy}
            onOpen={onOpen}
            onEdit={onEdit}
            onDelete={onDelete}
            onColorChange={onColorChange}
            readOnly={editorProps?.readOnly}
          />
        )
      }
      editor={
        <textarea
          id={`sticky-canvas-input-${id}`}
          defaultValue={text ?? ''}
          onFocus={(e) => e.target.select()}
          className="border-none shadow-stickies outline-none font-body font-medium"
          style={{
            top: y,
            left: x,
            width: `${width}px`,
            height: `${width}px`,
            fontSize: `${STICKY_FONT}px`,
            lineHeight: `${STICKY_FONT * 1.25}px`,
            padding: `${DEFAULT_STEP * 2}px ${DEFAULT_STEP * 2}px`,
            background: color,
            overflowWrap: 'break-word',
            overflow: 'hidden',
            resize: 'none',
          }}
          autoFocus
          onBlur={handleStickyInput}
          {...editorProps}
        />
      }
    >
      {showText && (
        <Group ref={groupRef}>
          <Text
            ref={textRef}
            hitStrokeWidth={0}
            strokeScaleEnabled={false}
            shadowEnabled={false}
            transformsEnabled={'position'} // position transform only
            text={text}
            fill="#382152"
            fontSize={STICKY_FONT}
            lineHeight={1.25}
            align="left"
            position={{ x: DEFAULT_STEP * 2, y: DEFAULT_STEP * 2 }}
            fontFamily="Inter"
            height={
              (height ?? 0) -
              (participant ? DEFAULT_STEP * 8 : DEFAULT_STEP * (tags?.length > 0 ? 6 : 3))
            }
            width={(width ?? 0) - DEFAULT_STEP * 4}
            wrap="word"
            ellipsis
            listening={false}
          />
          {participant && (
            <>
              <Image
                transformsEnabled={'position'} // position transform only
                listening={false} // no events
                image={userIcon}
                width={15}
                height={15}
                position={{ x: DEFAULT_STEP * 2 - 4, y: DEFAULT_STEP * 20 - 2 }}
              />
              <Text
                hitStrokeWidth={0}
                strokeScaleEnabled={false}
                shadowEnabled={false}
                transformsEnabled={'position'} // position transform only
                listening={false} // no events
                fill="#382152"
                wrap="word"
                fontStyle="500"
                fontSize={12}
                width={(width ?? 0) - DEFAULT_STEP * 4}
                height={DEFAULT_STEP}
                position={{ x: DEFAULT_STEP * 2 + 14, y: DEFAULT_STEP * 20 }}
                key={`sticky-canvas-participant-${id}`}
                ellipsis={true}
                text={participant?.name}
              />
            </>
          )}
          {!!tags?.length && (
            <>
              <Image
                transformsEnabled={'position'} // position transform only
                listening={false} // no events
                width={15}
                height={15}
                image={tagIcon}
                position={{ x: DEFAULT_STEP * 2 - 4, y: DEFAULT_STEP * 22 - 2 }}
              />
              <Text
                hitStrokeWidth={0}
                strokeScaleEnabled={false}
                shadowEnabled={false}
                transformsEnabled={'position'} // position transform only
                listening={false} // no events
                fill="#382152"
                wrap="word"
                fontStyle="500"
                fontSize={12}
                width={(width ?? 0) - DEFAULT_STEP * 4}
                height={DEFAULT_STEP}
                position={{ x: DEFAULT_STEP * 2 + 14, y: DEFAULT_STEP * 22 }}
                key={`sticky-canvas-tags-${id}`}
                ellipsis={true}
                text={(tags?.map((tag) => tag.name) || []).join(', ')}
              />
            </>
          )}
        </Group>
      )}
    </Selectable>
  );
}

function propsAreEqual(prev: StickyProps, current: StickyProps) {
  return (
    prev.showText == current.showText &&
    prev.text == current.text &&
    prev.x == current.x &&
    prev.y == current.y &&
    prev.color == current.color &&
    prev.participant?.name == current.participant?.name &&
    isEqual(prev.selectableProps?.selected, current.selectableProps?.selected) &&
    isEqual(prev.selectableProps?.editable, current.selectableProps?.editable) &&
    isEqual(prev.tags, current.tags) &&
    prev.handToolActive == current.handToolActive
  );
}

const Sticky = memo(StickyInner, propsAreEqual);

export default Sticky;
