/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';

import Button from '../Button';
import CoverPhotoPopover from '../Popovers/CoverPhotoPopover';
import { defaultCoverImages } from '../../Consts/coverImages';
import { CoverProps } from '../../Models';
import usePermissions from '../../Hooks/usePermissions';
import Loader from '../Loader';
import {
  Container,
  EmptyState,
  EmptyStateContent,
  ImageUploader,
  ImageUploaderIconWrapper,
  ImageUploaderText,
  LoaderContainer,
} from './styles';
import CoverPhotoGenerationModal from '../CoverPhotoGenerationModal';
import { Icon24 } from '../../Icons/Icon';
import useFiles from '../../Hooks/useFiles';
import axios from 'axios';

type InsightCoverPhotoProps = {
  data: any;
  updateInsight: (input: any) => void;
  isInsightsPanel: boolean;
  expanded?: boolean;
  onShowGenerationModal?: () => void;
};

const InsightCoverPhoto = ({
  data,
  updateInsight,
  isInsightsPanel,
  onShowGenerationModal,
  expanded = false,
}: InsightCoverPhotoProps): JSX.Element => {
  const { uploadFileToS3 } = useFiles();
  const cancel = axios.CancelToken.source();
  const [selectedImage, setSelectedImage] = useState<null | CoverProps>(null);
  const [showHoverCoverImageOptions, setShowHoverCoverImageOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { canEditInsights } = usePermissions();
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data) {
      const currentImage = data?.customCover
        ? { name: 'custom', url: data.customCover, position: '50% 50%' }
        : defaultCoverImages.find((image) => image.name === data?.cover);

      setSelectedImage(currentImage || null);
    }
  }, [data]);

  const onImageClick = async (image: CoverProps) => {
    setSelectedImage(image);
    if (image.name === 'custom') {
      updateInsight({ customCover: image.url });
      return;
    }
    updateInsight({ cover: image.name, customCover: null });
  };

  const handleUploadFile = async (file: File) => {
    try {
      setIsLoading && setIsLoading(true);
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        isPublic: true,
      });
      setIsLoading && setIsLoading(false);
      onImageClick({ name: 'custom', url: result.url, position: '50% 50%' });
    } catch {
      if (axios.isCancel(file)) {
        return;
      }
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      handleUploadFile(file);
    }
  };

  const handleBrowseFilesClick = () => {
    inputRef.current?.click();
  };

  return (
    <Container>
      {isLoading && (
        <LoaderContainer>
          <Loader size="32" isFullScreen={false} />
        </LoaderContainer>
      )}
      {selectedImage && (
        <div
          className="relative"
          onMouseEnter={() => setShowHoverCoverImageOptions(true)}
          onMouseLeave={() => setShowHoverCoverImageOptions(false)}
        >
          <img
            src={selectedImage.url}
            alt="cover image"
            style={{
              width: '100%',
              height: isInsightsPanel ? 250 : 275,
              objectFit: 'cover',
              objectPosition: selectedImage.position,
            }}
          />
          {showHoverCoverImageOptions && canEditInsights && (
            <div className="absolute bottom-0 left-0 mb-1 ml-1 flex flex-row">
              <CoverPhotoPopover
                classNames="mr-1"
                title="Change"
                position="left"
                onImageClick={onImageClick}
                setIsLoading={setIsLoading}
                onShowGenerationModal={onShowGenerationModal}
              />
              <Button
                type="secondary"
                className="button-border ml-1 hover:bg-primary-purple-light"
                onClick={() => {
                  setSelectedImage(null);
                  updateInsight({ cover: null, customCover: null });
                }}
              >
                <TrashIcon className="w-4 h-4 mr-1" />
                Remove
              </Button>
            </div>
          )}
        </div>
      )}
      {!selectedImage && canEditInsights && (
        <EmptyState>
          <EmptyStateContent>
            <Button type="secondary" onClick={onShowGenerationModal}>
              <Icon24.Insight /> Create Image with AI
            </Button>
            <ImageUploader>
              <ImageUploaderText onClick={handleBrowseFilesClick}>
                <ImageUploaderIconWrapper>
                  <Icon24.Upload />
                </ImageUploaderIconWrapper>
                or upload a file
              </ImageUploaderText>
              <input
                id={'cover_upload'}
                name={'cover_upload'}
                accept={'.png, .jpg, .jpeg, .gif'}
                type={'file'}
                onChange={handleFileInputChange}
                className={'hidden'}
                ref={inputRef}
              />
            </ImageUploader>
          </EmptyStateContent>
        </EmptyState>
      )}
    </Container>
  );
};

export default InsightCoverPhoto;
