import styled from 'styled-components';

type PortalCoordinates = {
  left: number;
  top: number;
};

export const Wrapper = styled.div<{ coordinates: PortalCoordinates, visible?: boolean }>`
  position: absolute;
  z-index: 9999;
  left: ${({ coordinates }) => coordinates.left}px;
  top: ${({ coordinates }) => coordinates.top}px;

  ${({ visible }) => !visible && `
    opacity: 0;
  `}
`