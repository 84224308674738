import React from 'react';

export default function UnderlineIcon() {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 15V17H0.5V15H14.5ZM11.5 8.215C11.4671 8.87498 11.2711 9.51653 10.9297 10.0823C10.5883 10.6481 10.1121 11.1205 9.54352 11.4572C8.97497 11.794 8.33184 11.9847 7.67161 12.0122C7.01138 12.0397 6.35461 11.9033 5.76 11.615C5.07464 11.3185 4.49341 10.8241 4.09077 10.1952C3.68813 9.56633 3.48242 8.83154 3.5 8.085V0.00500011H1.5V8.215C1.53383 9.15641 1.78885 10.0766 2.24442 10.9012C2.69998 11.7257 3.34329 12.4314 4.12227 12.9611C4.90125 13.4908 5.794 13.8296 6.72827 13.9502C7.66254 14.0707 8.61207 13.9696 9.5 13.655C10.6811 13.2613 11.7059 12.5012 12.4252 11.485C13.1446 10.4689 13.5211 9.24981 13.5 8.005V0.00500011H11.5V8.215ZM11.5 0H13.5H11.5ZM3.5 0H1.5H3.5Z"
        fill="#3B2651"
      />
    </svg>
  );
}
