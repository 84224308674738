import React, { FC, useState } from 'react';

import { Icon24 } from '../../Icons/Icon';
import RadioButtons from '../RadioButtons';
import { RadioButtonOption } from '../RadioButtons/RadioButtons';
import Button from '../Button';

import {
  Header,
  HeaderIcon,
  HeaderText,
  RadioButtonsWrapper,
  ButtonsWrapper,
  ShareFeedbackButton,
  Textarea,
  Container,
  CloseButton,
  Title,
} from './styles';

const feedbackRadioOptions = [
  { label: 'Inaccurate', value: 'innacurate' },
  { label: 'Offensive', value: 'offensive' },
  { label: 'Harmful', value: 'harmful' },
  { label: 'Unhelpful', value: 'unhelpful' },
  { label: 'Something else', value: 'else' },
];

interface SummaryFeedbackFormProps {
  onConfirm: (selectedOptions: RadioButtonOption[], comment: string) => void;
  onCancel: () => void;
}

const SummaryFeedbackForm: FC<SummaryFeedbackFormProps> = ({ onConfirm, onCancel }) => {
  const [selectedOptions, setSelectedOptions] = useState<RadioButtonOption[]>([]);
  const [comment, setComment] = useState('');

  const handleConfirm = () => {
    setSelectedOptions([]);
    setComment('');
    onConfirm(selectedOptions, comment);
  };

  const handleCancel = () => {
    setSelectedOptions([]);
    setComment('');
    onCancel();
  };

  return (
    <Container>
      <Header>
        <Title>
          <HeaderIcon>
            <Icon24.AIStar />
          </HeaderIcon>
          <HeaderText>What did you not like about this summary?</HeaderText>
        </Title>
        <CloseButton onClick={handleCancel}>
          <Icon24.Close />
        </CloseButton>
      </Header>

      <RadioButtonsWrapper>
        <RadioButtons
          options={feedbackRadioOptions}
          selectedOptions={selectedOptions}
          onChange={setSelectedOptions}
        />
      </RadioButtonsWrapper>
      <Textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Describe how this summary could improve..."
      />
      <ButtonsWrapper>
        <ShareFeedbackButton>
          <Button onClick={handleConfirm} type="primary">
            Share feedback
          </Button>
        </ShareFeedbackButton>
        <Button type="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

export default SummaryFeedbackForm;
