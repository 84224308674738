import React, { FC } from 'react';
import { Button, Container } from './styles';

export type RadioButtonOption = {
  value: string;
  label: string;
};

interface RadioButtonsProps {
  options: RadioButtonOption[];
  selectedOptions: RadioButtonOption[];
  onChange(options: RadioButtonOption[]): void;
}

const RadioButtons: FC<RadioButtonsProps> = ({ options, selectedOptions, onChange }) => {
  const handleSelectOption = (option: RadioButtonOption) => {
    if (selectedOptions.includes(option)) {
      const newOptions = selectedOptions.filter((selectedOption) => selectedOption !== option);
      onChange(newOptions);
      return;
    }
    const newOptions = [...selectedOptions, option];
    onChange(newOptions);
  };

  return (
    <Container>
      {options.map((option) => (
        <Button
          key={option.value}
          isActive={selectedOptions.includes(option)}
          onClick={() => handleSelectOption(option)}
        >
          {option.label}
        </Button>
      ))}
    </Container>
  );
};

export default RadioButtons;
