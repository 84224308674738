import React, { FC, useMemo } from 'react';
import { Icon24 } from '../../Icons/Icon';
import {
  AvatarWrapper,
  Avatars,
  Container,
  Emoji,
  Emojis,
  IconContainer,
  Reaction,
  ReactionsNumber,
  Title,
  TitleText,
  Tooltip,
  TooltipMessage,
  TooltipName,
} from './styles';
import Avatar from '../Avatar';
import NewReactionButton from './NewReactionButton';
import {
  useCreateInsightReaction,
  useDeleteInsightReaction,
  useFetchInsightReactions,
  useUpdateInsightReaction,
} from '../../Hooks/useInsights';
import { ID, TReaction } from '../../Models';
import useUsers from '../../Hooks/useUsers';
import getClustersFromReactions, { TReactionCluster } from './utils';

interface ReactionsProps {
  insightId: ID;
  readonly?: boolean;
}

const Reactions: FC<ReactionsProps> = ({ insightId, readonly }) => {
  const { data: reactions } = useFetchInsightReactions(insightId);
  const { currentUser: getCurrentUser } = useUsers();
  const [, currentUser] = getCurrentUser();
  const [createReaction] = useCreateInsightReaction();
  const [deleteReaction] = useDeleteInsightReaction();
  const [updateReaction] = useUpdateInsightReaction();

  const reactionsByCluster = useMemo(() => {
    return getClustersFromReactions(reactions, currentUser);
  }, [reactions, currentUser]);

  const reactionsByClusterView = useMemo(() => {
    return reactionsByCluster.map((cluster) => {
      const reactions: TReaction[] = [];
      cluster.reactions.forEach((reaction) => {
        const shouldAddReaction = !reactions.find(
          (item) =>
            reaction.emoji === item.emoji && reaction.userByCreatedBy.id !== item.userByCreatedBy.id
        );
        if (shouldAddReaction) {
          reactions.push(reaction);
        }
      });
      return {
        ...cluster,
        reactions,
      };
    });
  }, [reactionsByCluster]);

  const reactionHasCurrentUser = (cluster: TReactionCluster) => {
    return !!cluster.users.find((user) => user.id === currentUser?.id);
  };

  const onlyCurrentUserCluster = (cluster: TReactionCluster) => {
    return cluster.users.length === 1 && cluster.users[0].id === currentUser?.id;
  };

  const countOfReactions = useMemo(() => {
    return reactionsByClusterView.filter((cluster) => !!cluster.reactions.length).length;
  }, [reactionsByClusterView]);

  const handleReactionClick = async (clusterId: string) => {
    if (readonly) return;
    const cluster = reactionsByCluster.find((cluster) => cluster.clusterId === clusterId);
    if (!cluster) return;

    const hasCurrentUser = cluster.users.find((user) => user.id === currentUser?.id);
    if (hasCurrentUser) {
      const reactionIdsByCurrentUser = cluster.reactions
        .filter((item) => item.userByCreatedBy.id === currentUser?.id)
        .map((item) => item.id);

      for (const reactionId of reactionIdsByCurrentUser) {
        deleteReaction(insightId, reactionId);
      }

      const usersAfterRemoving = cluster.users.filter((user) => user.id !== currentUser?.id);
      if (usersAfterRemoving.length === 1) {
        const anotherSingleUserCluster = reactionsByCluster.find(
          (item) => item.users.length === 1 && item.users[0].id === usersAfterRemoving[0].id
        );
        if (anotherSingleUserCluster) {
          const reactionIds = anotherSingleUserCluster.reactions.map((item) => item.id);

          for (const reactionId of reactionIds) {
            updateReaction(reactionId, insightId, clusterId);
          }
        }
      }
    } else {
      const emojis = cluster.reactions.map((item) => item.emoji);
      for (const emoji of emojis) {
        createReaction(insightId, emoji, cluster.clusterId);
      }
    }
  };

  const handleDeleteCurrentUserReactions = (clusterId: string) => {
    if (readonly) return;
    const cluster = reactionsByCluster.find((cluster) => cluster.clusterId === clusterId);
    if (!cluster) return;
    const reactionIds = cluster.reactions.map((item) => item.id);

    for (const reactionId of reactionIds) {
      if (parseInt(reactionId.toString()) < 0) continue;
      deleteReaction(insightId, reactionId);
    }
  };

  const handleSelectEmoji = async (emoji: string) => {
    if (readonly) return;
    const existingClusterId = reactionsByCluster.find(
      (cluster) => cluster.users.length === 1 && cluster.users[0].id === currentUser?.id
    )?.clusterId;
    createReaction(
      insightId,
      emoji,
      existingClusterId || Math.floor(Math.random() * Date.now()).toString(16)
    );
  };

  const renderNewReactionButton = (cluster: TReactionCluster) => {
    return readonly ? (
      !!cluster.reactions.length && (
        <NewReactionButton
          key={cluster.clusterId}
          onSelect={handleSelectEmoji}
          emojis={cluster.reactions.map((item) => item.emoji)}
          onDelete={() => handleDeleteCurrentUserReactions(cluster.clusterId)}
          currentUser={currentUser}
          readonly={readonly}
        />
      )
    ) : (
      <NewReactionButton
        onSelect={handleSelectEmoji}
        emojis={cluster.reactions.map((item) => item.emoji)}
        onDelete={() => handleDeleteCurrentUserReactions(cluster.clusterId)}
        currentUser={currentUser}
        readonly={readonly}
      />
    );
  };

  return (
    <Container>
      <Title>
        <IconContainer>
          <Icon24.Smile />
        </IconContainer>
        {!!countOfReactions && <ReactionsNumber>{countOfReactions}</ReactionsNumber>}
        <TitleText>Reactions</TitleText>
      </Title>

      <Container>
        {reactionsByClusterView?.map((cluster) =>
          onlyCurrentUserCluster(cluster) ? (
            renderNewReactionButton(cluster)
          ) : (
            <Reaction
              key={cluster.clusterId}
              onClick={() => handleReactionClick(cluster.clusterId)}
              isActive={reactionHasCurrentUser(cluster)}
            >
              <Avatars>
                {cluster.users.map((user) => (
                  <AvatarWrapper
                    key={user.id}
                    multiple={cluster.users.length > 1}
                    isActive={reactionHasCurrentUser(cluster)}
                  >
                    <Avatar user={user} />
                  </AvatarWrapper>
                ))}
              </Avatars>

              <Emojis withLeftMargin={cluster.users.length < 2}>
                {cluster.reactions.map((reaction) => (
                  <Emoji key={reaction.id}>{reaction.emoji}</Emoji>
                ))}
              </Emojis>

              <Tooltip>
                <TooltipName>
                  {cluster.users
                    .map((user) => (user.id === currentUser.id ? 'You' : user.name))
                    .slice(0, 2)
                    .join(', ')}
                </TooltipName>
                {cluster.users.length === 1 ? (
                  <TooltipMessage>{cluster.users[0].email}</TooltipMessage>
                ) : (
                  cluster.users.length > 2 && <TooltipMessage> and others</TooltipMessage>
                )}
              </Tooltip>
            </Reaction>
          )
        )}
      </Container>
    </Container>
  );
};

export default Reactions;
