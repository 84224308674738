import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';

const Input = styled.input.attrs((props) => ({
  className: classNames(
    'appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline',
    props.className
  ),
}))``;

export default Input;
