import React, { useCallback, useEffect, useRef, useState } from 'react';
import Portal from '../Portal';

import { Wrapper } from './styles';

type PortalCoordinates = {
  left: number;
  top: number;
};

interface WrapperProps {
  coordinates?: PortalCoordinates;
  targetElement?: Element;
  isInsideEditor?: boolean;
  children: React.ReactNode;
}

const PortalWrapper: React.FC<WrapperProps> = ({
  coordinates,
  targetElement,
  children,
  isInsideEditor,
}) => {
  const [currentCoordinates, setCurrentCoordinates] = useState<PortalCoordinates>(
    coordinates ? coordinates : { left: 0, top: 0 }
  );
  const currentPortalRef = useRef<HTMLDivElement>(null);
  const [childrenHeight, setChildrenHeight] = useState(0);

  const updateCoordinates = useCallback(() => {
    if (!targetElement || !childrenHeight) return;

    const rect = targetElement.getBoundingClientRect();
    const isPageBottomIntersects = window.innerHeight < rect.y + childrenHeight;
    const isPageTopIntersects = rect.y < childrenHeight;

    let top = rect.y + window.scrollY;

    if (isPageBottomIntersects) {
      top = top - childrenHeight + rect.height;

      if (isInsideEditor) {
        top += rect.height + 30;
      }
    } else {
      if (isInsideEditor) {
        top += rect.height - 5;
      }
    }

    if (isPageTopIntersects && isPageBottomIntersects) {
      top = top + childrenHeight / 2;
    }

    setCurrentCoordinates({
      left: rect.x,
      top,
    });
  }, [targetElement, isInsideEditor, childrenHeight]);

  useEffect(() => {
    if (targetElement) {
      updateCoordinates();
    } else if (coordinates) {
      setCurrentCoordinates(coordinates);
    }
  }, [targetElement, coordinates, updateCoordinates]);

  useEffect(() => {
    window.addEventListener('resize', updateCoordinates);
    return () => window.removeEventListener('resize', updateCoordinates);
  }, [updateCoordinates]);

  useEffect(() => {
    if (currentPortalRef.current) {
      setChildrenHeight(currentPortalRef.current?.getBoundingClientRect().height);
    }
  }, [currentPortalRef]);

  return (
    <Portal>
      <Wrapper
        coordinates={currentCoordinates}
        visible={currentCoordinates.left !== 0 && currentCoordinates.top !== 0}
      >
        <div ref={currentPortalRef}>{children}</div>
      </Wrapper>
    </Portal>
  );
};

export default PortalWrapper;
