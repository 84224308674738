import styled from 'styled-components';

export const DROPDOWN_WIDTH = 262;

export const Container = styled.div``;

export const Button = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  margin: 0 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  user-select: none;
`;

export const NewDocDropdown = styled.div<{ top: number; left: number }>`
  position: fixed;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: 100%;
  max-width: ${DROPDOWN_WIDTH}px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

export const NewDocDropdownItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }
`;

export const NewdocDropdownItemIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #efedf1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NewDocDropdownItemContent = styled.div`
  width: 100%;
`;

export const NewDocDropdownItemTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const NewDocDropdownItemSubtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(59, 38, 81, 0.6);
`;

export const NewDocDropDownDivider = styled.div`
  width: 90%;
  height: 1px;
  background-color: #d7d3dc;
  margin: 5px auto;
`;

export const NewDocDropdownItemWrapper = styled.div`
  width: 100%;
`;
