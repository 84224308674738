import React, { MutableRefObject } from 'react';
import InPortal from './InPortal';

type TextAreaEditorProps = {
  value: string;
  cell?: {
    domRef: MutableRefObject<HTMLElement>;
  };
  onChange?: () => void;
  onComplete?: () => void;
  onTabNavigation?: (a: boolean, b: number) => void;
};

export default function TextAreaEditor({ cell, value, ...props }: TextAreaEditorProps) {
  const rect = cell?.domRef?.current?.getBoundingClientRect();

  if (!rect) {
    return <></>;
  }

  const { top = 0, left = 0 } = rect;

  const className =
    'w-80 h-40 shadow-md border-2 text-sm p-2 no-resize rounded border-primary-purple outline-none focus:border-primary-purple';
  return (
    <InPortal top={top} left={left}>
      <textarea
        autoFocus
        onBlur={props.onComplete}
        className={className}
        defaultValue={value ?? ''}
        onChange={props.onChange}
        onKeyDown={(e) => {
          if (e.key == 'Enter' && !e.shiftKey) {
            e.preventDefault();
            props.onComplete?.();
          }
          if (e.key == 'Tab') {
            e.preventDefault();
            props.onTabNavigation?.(true, e.shiftKey ? -1 : 1);
          }
        }}
      />
    </InPortal>
  );
}
