import { CheckCircleIcon } from '@heroicons/react/outline';
import { differenceInDays } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';
import styled from 'styled-components';
import { default as AICoverPhoto } from '../assets/default-cover-photos/AI-Cover-Photo.png';
import IntegrationsSVG from '../assets/integrations/Integrations.svg';
import DiscussionSVG from '../assets/projects/default-icons/Discussion-transparent.svg';
import Eye from '../assets/projects/default-icons/eye2.svg';
import SparklesSVG from '../assets/projects/default-icons/Sparkles-transparent.svg';
import UserTestingSVG from '../assets/projects/default-icons/User-Testing-Transparent.svg';
import useAnalytics from '../Hooks/useAnalytics';
import useBoards from '../Hooks/useBoards';
import { useFetchIntegrations } from '../Hooks/useFetchIntegrations';
import { checkOrganizationLimits, useOrganization } from '../Hooks/useOrganization';
import usePermissions from '../Hooks/usePermissions';
import breakpoint from '../Shared/breakpoint';
import Button from './Button';

const lsKeyEditorConnectIntegrationsClicked = 'trial-banner-editor-connect-integrations-clicked';
const lsKeyEditorExploreSampleProjectClicked = 'trial-banner-editor-explore-sample-project-clicked';
const lsKeyEditorNewProjectClicked = 'trial-banner-editor-new-project-clicked';
const lsKeyEditorBookDemoClicked = 'trial-banner-editor-book-demo-clicked';
const lsKeyViewerExploreSampleProjectClicked = 'trial-banner-viewer-explore-sample-project-clicked';
const lsKeyViewerSearchClicked = 'trial-banner-viewer-search-clicked';
const lsKeyViewerBrowseTemplatesClicked = 'trial-banner-viewer-browse-templates-clicked';
const lsKeyViewerBookGroupDemoClicked = 'trial-banner-viewer-book-group-demo-clicked';

const Section = styled.section`
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;

  border: 1px solid var(--light-purple-gray, #d7d3dc);
  background: #fff;
  /* Card Shadow */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

const HeaderImgDiv = styled.div`
  height: 24px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
  background: url(${AICoverPhoto}), lightgray 50% / cover no-repeat;
  background-position: top 174px left -280px;
`;

const HeaderTitle = styled.h2`
  align-self: stretch;
  color: #3b2651;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: 0.2px;
`;

const BannerBody = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const HeaderAndProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 0;
  height: 5px;
  background: var(--light-purple-gray, #d7d3dc);
`;

const ProgressText = styled.div`
  color: rgba(59, 38, 81, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.14px;
`;

const ProgressFill = styled.div`
  width: calc(100% * ${(props: { progress: number }) => props.progress});
  height: 5px;
  background: var(--brand-purple, #3b2651);
`;

const Content = styled.div`
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  ${breakpoint.Tablet`
    flex-direction: column;
  `}
`;

const Card = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  align-items: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--light-purple-gray, #d7d3dc);
  background: #fff;
  min-width: 146px;
  ${breakpoint.Tablet`
    flex-direction: row;
  `}
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  ${breakpoint.Tablet`
    flex-direction: row;
  `}
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${breakpoint.Tablet`
    width: inherit;
  `}
`;

const CardHeaderIcon = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
`;

const CardCompletionIcon = styled.div`
  position: absolute;
  opacity: 50%;
  ${breakpoint.Tablet`
    display: none;
  `}
`;

const CardTitleAndBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  align-self: stretch;
  color: var(--brand-purple, #3b2651);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
  flex: 0 0 auto;
`;

const CardBodyText = styled.div`
  align-self: stretch;
  color: var(--brand-purple, #3b2651);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  flex: 1;
`;

const CardActions = styled.div``;

export function HomeTrialBanner(): JSX.Element {
  const history = useHistory();
  const { analytics } = useAnalytics();

  const { fetchBoards, updateBoard, createBoard } = useBoards();
  const [, dashboards] = fetchBoards();
  const sampleDashboard = dashboards?.find((dashboard) => dashboard.isSample);

  const [, org] = useOrganization();
  const { canCreateProjects } = usePermissions();

  const trialDaysTotal = Math.max(
    differenceInDays(new Date(org.trialExpireAt), new Date(org.trialStartAt)) + 1,
    0
  );
  const trialDaysRemaining = Math.max(
    differenceInDays(new Date(org.trialExpireAt), new Date()) + 1,
    0
  );
  const trialDaysRemainingText = `${trialDaysRemaining} ${
    trialDaysRemaining === 1 ? 'day' : 'days'
  }`;
  const trialDaysElapsed = Math.max(trialDaysTotal - trialDaysRemaining, 1);
  const trialProgress = trialDaysElapsed / trialDaysTotal;

  const editorExploreSampleProjectClicked = localStorage.getItem(
    lsKeyEditorExploreSampleProjectClicked
  );
  const editorNewProjectClicked = localStorage.getItem(lsKeyEditorNewProjectClicked);
  const editorBookDemoClicked = localStorage.getItem(lsKeyEditorBookDemoClicked);
  const viewerExploreSampleProjectClicked = localStorage.getItem(
    lsKeyViewerExploreSampleProjectClicked
  );
  const viewerSearchClicked = localStorage.getItem(lsKeyViewerSearchClicked);
  const viewerBrowseTemplatesClicked = localStorage.getItem(lsKeyViewerBrowseTemplatesClicked);
  const viewerBookGroupDemoClicked = localStorage.getItem(lsKeyViewerBookGroupDemoClicked);

  const { data: integrations } = useFetchIntegrations();

  const onSampleProjectButtonClick = async () => {
    localStorage.setItem(lsKeyEditorExploreSampleProjectClicked, 'true');
    localStorage.setItem(lsKeyViewerExploreSampleProjectClicked, 'true');
    if (sampleDashboard) {
      history.push(`/projects/${sampleDashboard.id}/info`);
      updateBoard(sampleDashboard.id, {
        isVisited: true,
      });
    }
  };

  const onNewProjectButtonClick = async () => {
    localStorage.setItem(lsKeyEditorNewProjectClicked, 'true');
    if (!checkOrganizationLimits(org, history, ['projects'])) {
      return false;
    }

    const dashboard = await createBoard('');

    analytics.sendEvent('CreateProject', { id: dashboard.id });

    history.push(`/projects/${dashboard.id}/data`);
  };
  return (
    <Section>
      <HeaderImgDiv></HeaderImgDiv>
      <BannerBody>
        <HeaderAndProgress>
          <HeaderTitle>Getting started with Notably</HeaderTitle>
          <Progress>
            <ProgressBar>
              <ProgressFill progress={trialProgress} />
            </ProgressBar>
            <ProgressText>{trialDaysRemainingText} left in trial</ProgressText>
          </Progress>
        </HeaderAndProgress>
        <Content>
          {canCreateProjects && (
            <Cards>
              <Card id="trial-banner-card-integrations">
                <CardHeader>
                  {(integrations?.integrations?.length || 0) > 0 && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={IntegrationsSVG} alt="Connect Integrations" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Connect Integrations</CardTitle>
                    <CardBodyText>Add data and share insights faster.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button
                      type="secondary"
                      onClick={() => {
                        localStorage.setItem(lsKeyEditorConnectIntegrationsClicked, 'true');
                        history.push('/settings/integrations');
                      }}
                    >
                      Connect
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>

              <Card id="trial-banner-card-explore-sample-project">
                <CardHeader>
                  {editorExploreSampleProjectClicked && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={Eye} alt="explore icon" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Explore sample project</CardTitle>
                    <CardBodyText>Play with sample data in Notably.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button type="secondary" onClick={onSampleProjectButtonClick}>
                      Explore project
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>

              <Card id="trial-banner-card-new-project">
                <CardHeader>
                  {editorNewProjectClicked && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={UserTestingSVG} alt="Add you own data" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Add your own data</CardTitle>
                    <CardBodyText>Transcribe and summarize a video.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button type="secondary" onClick={onNewProjectButtonClick}>
                      Create a project
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>

              <Card id="trial-banner-card-book-demo">
                <CardHeader>
                  {editorBookDemoClicked && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={DiscussionSVG} alt="Book a 1:1 demo with our team" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Book a 1:1 demo with our team</CardTitle>
                    <CardBodyText>We’ll share how we can help you.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button
                      type="secondary"
                      onClick={() => {
                        localStorage.setItem(lsKeyEditorBookDemoClicked, 'true');
                        window.open(
                          'https://calendly.com/notably-sales/notably-onboarding',
                          '_blank'
                        );
                      }}
                    >
                      Find a time
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </Cards>
          )}

          {!canCreateProjects && (
            <Cards>
              <Card id="trial-banner-card-explore-sample-project">
                <CardHeader>
                  {viewerExploreSampleProjectClicked && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={Eye} alt="explore icon" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Explore sample project</CardTitle>
                    <CardBodyText>Play with sample data in Notably.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button type="secondary" onClick={onSampleProjectButtonClick}>
                      Explore project
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>

              <Card id="trial-banner-card-search">
                <CardHeader>
                  {viewerSearchClicked && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={SparklesSVG} alt="Search and find new insights" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Search and find new insights</CardTitle>
                    <CardBodyText>Use the search bar to find research insights.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button
                      type="secondary"
                      onClick={() => {
                        localStorage.setItem(lsKeyViewerSearchClicked, 'true');
                        document.getElementById('global-search-container')?.click();
                      }}
                    >
                      Search
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>

              <Card id="trial-banner-card-browse-templates">
                <CardHeader>
                  {viewerBrowseTemplatesClicked && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={UserTestingSVG} alt="Browse AI Templates" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Browse AI Templates</CardTitle>
                    <CardBodyText>Browse AI templates for every use case.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button
                      type="secondary"
                      onClick={() => {
                        localStorage.setItem(lsKeyViewerBrowseTemplatesClicked, 'true');
                        history.push('/templates');
                      }}
                    >
                      Browse
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>

              <Card id="trial-banner-card-book-group-demo">
                <CardHeader>
                  {viewerBookGroupDemoClicked && (
                    <CardCompletionIcon>
                      <CheckCircleIcon width={24} height={24} />
                    </CardCompletionIcon>
                  )}
                  <CardHeaderIcon>
                    <img src={DiscussionSVG} alt="Join a Notably Group Demo" />
                  </CardHeaderIcon>
                </CardHeader>
                <CardContent>
                  <CardTitleAndBody>
                    <CardTitle>Join a Notably Group Demo</CardTitle>
                    <CardBodyText>Attend a live demo to see the latest features.</CardBodyText>
                  </CardTitleAndBody>
                  <CardActions>
                    <Button
                      type="secondary"
                      onClick={() => {
                        localStorage.setItem(lsKeyViewerBookGroupDemoClicked, 'true');
                        window.open(
                          'https://calendly.com/notably-sales/notably-onboarding',
                          '_blank'
                        );
                      }}
                    >
                      Save your seat
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </Cards>
          )}
        </Content>
      </BannerBody>
    </Section>
  );
}
