import { useMutation } from '@apollo/client';
import { ID } from '../Models';
import { CREATE_PROJECT_CHECKLIST, UPDATE_PROJECT_CHECKLIST } from '../GraphQL/mutations';
import { FETCH_PROJECT_CHECKLIST } from '../GraphQL/queries';

export const useCreateProjectChecklist = (): ((currentUserId: string) => Promise<any>) => {
  const [createMutation] = useMutation(CREATE_PROJECT_CHECKLIST);

  return async (currentUserId: string) => {
    const res = await createMutation({
      variables: {
        input: {},
      },
      refetchQueries: [
        {
          query: FETCH_PROJECT_CHECKLIST,
          variables: {
            createdBy: currentUserId,
          },
        },
      ],
    });
    return res?.data?.createProjectChecklist;
  };
};

export const useUpdateProjectChecklist = (): ((id: ID, input: any) => Promise<any>) => {
  const [updateMutation] = useMutation(UPDATE_PROJECT_CHECKLIST);

  return async (id: ID, input: any) => {
    return updateMutation({
      variables: {
        id,
        input,
      },
    });
  };
};
