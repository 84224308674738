import React from 'react';
import { defaultUserImages } from '../Consts/userImages';

const Avatar = ({ user }: { user?: { picture: string } }): JSX.Element => {
  const currImage =
    defaultUserImages.find((image) => image.name === user?.picture) || defaultUserImages[0];
  return (
    <div className="flex-none items-center justify-center">
      <img className="rounded-full h-6 w-6" src={currImage.url} />
    </div>
  );
};

export default Avatar;
