import React, { useState } from 'react';
import Button from '../../Button';
import ReactLoading from 'react-loading';
import useKeyPressHandler from '../../../Hooks/useKeyPressHandler';
import {
  ButtonWrapper,
  Container,
  Content,
  ContentForm,
  InputWrapper,
  Step,
  Subtitle,
  Title,
  AddTeammateButton,
  AddTeammateText,
  SkipButton,
} from './styles';
import Input from '../../Input/Input';
import { Icon24 } from '../../../Icons/Icon';
import useUsers from '../../../Hooks/useUsers';

type InviteStepProps = {
  onContinue: () => void;
  totalSteps: number;
};

const InviteStep = ({ onContinue, totalSteps }: InviteStepProps): JSX.Element => {
  const [emailList, setEmailList] = useState<string[]>(['', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [errorIndexes, setErrorIndexes] = useState<number[]>([]);
  const { inviteUser } = useUsers();

  useKeyPressHandler(onContinue, 'Enter');

  const isValidEmail = (email: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const handleEmailChange = (index: number, value: string) => {
    const newEmailList = [...emailList];
    newEmailList[index] = value;
    setEmailList(newEmailList);
    handleValidateEmail(index, value);
  };

  const handleValidateEmail = (index: number, value: string) => {
    if (value && !isValidEmail(value)) {
      setErrorIndexes(errorIndexes.concat(index));
    } else {
      setErrorIndexes(errorIndexes.filter((errorIndex) => errorIndex !== index));
    }
  };

  const handleContinue = async () => {
    const filteredEmailList = emailList.filter((email) => email !== '');
    if (filteredEmailList.length) {
      setIsLoading(true);
      for (const email of filteredEmailList) {
        try {
          await inviteUser(email, 'user');
        } catch (e) {}
      }
      setIsLoading(false);
    }
    onContinue();
  };

  return (
    <Container>
      <Content>
        <Step>{`Step 2 of ${totalSteps}`}</Step>
        <Title>Invite collaborators, free for 7 days</Title>
        <Subtitle>Discover new insights together in Notably.</Subtitle>

        <ContentForm>
          {emailList.map((email, index) => (
            <InputWrapper key={index}>
              <Input
                id="name"
                name="name"
                placeholder="name@company.com"
                value={email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                onBlur={(e) => handleValidateEmail(index, e.target.value.trim())}
                redBorder={errorIndexes.includes(index)}
              />
            </InputWrapper>
          ))}

          <AddTeammateButton onClick={() => setEmailList(emailList.concat(''))}>
            <Icon24.Plus /> <AddTeammateText>Add teammate</AddTeammateText>
          </AddTeammateButton>
        </ContentForm>
        <ButtonWrapper>
          {isLoading && (
            <ReactLoading type={'spin'} color={'#382152'} height={'10%'} width={'10%'} />
          )}
          {!isLoading && (
            <Button
              size={'lg'}
              className="w-full flex justify-center"
              onClick={handleContinue}
              disabled={!!errorIndexes.length}
            >
              Continue
            </Button>
          )}
        </ButtonWrapper>

        <ButtonWrapper>
          <SkipButton onClick={onContinue}>Do this later</SkipButton>
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default InviteStep;
