import React from 'react';

export default function ItalicIcon() {
  return (
    <svg width="5" height="14" viewBox="0 0 5 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.26006 4H4.26006L2.06006 14H0.0600586L2.26006 4ZM3.94006 0C3.74228 0 3.54894 0.0586491 3.38449 0.16853C3.22004 0.278412 3.09187 0.434591 3.01618 0.617317C2.94049 0.800043 2.92069 1.00111 2.95927 1.19509C2.99786 1.38907 3.0931 1.56725 3.23295 1.70711C3.3728 1.84696 3.55099 1.9422 3.74497 1.98079C3.93895 2.01937 4.14002 1.99957 4.32274 1.92388C4.50547 1.84819 4.66165 1.72002 4.77153 1.55557C4.88141 1.39112 4.94006 1.19778 4.94006 1C4.94006 0.734784 4.8347 0.48043 4.64717 0.292893C4.45963 0.105357 4.20527 0 3.94006 0Z"
        fill="#3B2651"
      />
    </svg>
  );
}
