import React from 'react';
import { toast, ToastContainer, Slide, ToastClassName } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { XIcon as Close } from '@heroicons/react/outline';

class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error): void {
    toast.error(error);
  }

  render(): JSX.Element {
    const contextClass = {
      success: 'bg-green-100 text-green-600',
      error: 'bg-red-100 text-red-600',
      info: 'bg-blue-100 text-blue-600',
      warning: 'bg-yellow-100 text-yellow-600',
      default: 'toastify-default-card text-gray-600',
      dark: 'bg-gray-600 text-white-600',
    };

    const closeButton = <Close className="h-6 w-6 m-2" />;

    const toastClassName: ToastClassName = (context) => {
      return (
        contextClass[context?.type || 'default'] +
        ' relative flex mb-2 px-3 py-2 min-h-10 rounded-sm-md ' +
        'font-medium text-sm justify-between overflow-hidden cursor-pointer'
      );
    };

    return (
      <>
        {this.props.children}
        <ToastContainer
          style={{
            top: '4.75rem',
            minWidth: '50%',
          }}
          toastClassName={toastClassName}
          closeButton={closeButton}
          position="top-center"
          autoClose={115000}
          hideProgressBar
          newestOnTop={false}
          transition={Slide}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

export default ErrorBoundary;
