import React from 'react';

const ShapesIcon = (): JSX.Element => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.57141"
      y="5.57324"
      width="15.4264"
      height="15.4264"
      rx="1.28571"
      stroke="#3B2651"
      strokeWidth="2"
    />
    <circle cx="16.5616" cy="7.36841" r="6.36841" fill="white" stroke="#3B2651" strokeWidth="2" />
  </svg>
);

export default ShapesIcon;
