import { useMutation, useQuery } from '@apollo/client';
import { CREATE_DATA_FOLDER, DELETE_DATA_FOLDER, UPDATE_DATA_FOLDER } from '../GraphQL/mutations';

import { FETCH_DATA_FOLDERS } from '../GraphQL/queries';

export const useFetchFolders = (dashboardId: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_DATA_FOLDERS, {
    variables: { dashboardId },
  });
  return [loading, data?.dataFolders];
};

export const useCreateFolder = (dashboardId: string): [(name?: string) => void] => {
  const [createFolderMutation] = useMutation(CREATE_DATA_FOLDER);

  return [
    (name?: string) => {
      return createFolderMutation({
        variables: {
          name,
          dashboardId,
        },
        refetchQueries: [
          {
            query: FETCH_DATA_FOLDERS,
            variables: {
              dashboardId,
            },
          },
        ],
      });
    },
  ];
};

export const useUpdateFolder = (dashboardId: string): [(id: string, name: string) => void] => {
  const [updateFolderMutation] = useMutation(UPDATE_DATA_FOLDER);

  return [
    (id: string, name: string) => {
      return updateFolderMutation({
        variables: {
          id,
          name,
        },
        refetchQueries: [
          {
            query: FETCH_DATA_FOLDERS,
            variables: {
              dashboardId,
            },
          },
        ],
      });
    },
  ];
};

export const useDeleteFolder = (dashboardId: string): [(id: string) => void] => {
  const [deleteFolderMutation] = useMutation(DELETE_DATA_FOLDER);

  return [
    (id: string) => {
      return deleteFolderMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_DATA_FOLDERS,
            variables: {
              dashboardId,
            },
          },
        ],
      });
    },
  ];
};
