import { useContext } from 'react';
import { CurrentDashboardContext } from './useBoards';
import { useViewOnlyProjects } from './useOrganization';
import { CurrentUserContext } from './useUsers';

type Permissions = {
  canCreateProjects: boolean;
  canEditProjects: boolean;
  canCreateParticipants: boolean;
  canEditParticipants: boolean;
  canEditWorkspace: boolean;
  canEditTeam: boolean;
  canAddAdmins: boolean;
  canChangePlan: boolean;
  canEditInfoPage: boolean;
  canCreateInsights: boolean;
  canEditInsights: boolean;
  canCreateData: boolean;
  canEditData: boolean;
  canCreateDocuments: boolean;
  canEditDocuments: boolean;
  canCreateTranscripts: boolean;
  canEditTranscripts: boolean;
  canCreateNotes: boolean;
  canEditNotes: boolean;
  canEditTags: boolean;
  canChangeIntegrations: boolean;
  canEditAndPreviewPrompts: boolean;
};

export const ADMIN_ROLE = 'admin';
export const USER_ROLE = 'user';
export const VIEWER_ROLE = 'viewer';

export const usePermissions = (): Permissions => {
  const user = useContext(CurrentUserContext);
  const dashboardId = useContext(CurrentDashboardContext);
  const [, viewOnlyProjectIds] = useViewOnlyProjects();

  const isViewOnlyProject = dashboardId ? viewOnlyProjectIds.includes(dashboardId) : false;

  const isAdminOrUser = user?.role === ADMIN_ROLE || user?.role === USER_ROLE;
  const isAdmin = user?.role === ADMIN_ROLE;

  return {
    canCreateProjects: isAdminOrUser,
    canEditProjects: isAdminOrUser,
    canCreateParticipants: isAdminOrUser,
    canEditParticipants: isAdminOrUser,
    canEditWorkspace: isAdmin,
    canEditTeam: isAdminOrUser,
    canAddAdmins: isAdmin,
    canChangePlan: isAdmin,
    canEditInfoPage: isAdminOrUser && !isViewOnlyProject,
    canCreateInsights: isAdminOrUser && !isViewOnlyProject,
    canEditInsights: isAdminOrUser && !isViewOnlyProject,
    canCreateData: isAdminOrUser && !isViewOnlyProject,
    canEditData: isAdminOrUser && !isViewOnlyProject,
    canCreateDocuments: isAdminOrUser && !isViewOnlyProject,
    canEditDocuments: isAdminOrUser && !isViewOnlyProject,
    canCreateTranscripts: isAdminOrUser && !isViewOnlyProject,
    canEditTranscripts: isAdminOrUser && !isViewOnlyProject,
    canCreateNotes: isAdminOrUser && !isViewOnlyProject,
    canEditNotes: isAdminOrUser && !isViewOnlyProject,
    canEditTags: isAdminOrUser && !isViewOnlyProject,
    canChangeIntegrations: isAdminOrUser && !isViewOnlyProject,
    canEditAndPreviewPrompts: isAdminOrUser,
  };
};

export default usePermissions;
