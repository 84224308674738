import React, { useEffect, useRef, useState } from 'react';
import { ID } from '../../Models';
import {
  SelectedItemDeleteButton,
  SelectedItem,
  Container,
  ContentWrapper,
  Header,
  IconWrapper,
  NoOptionsText,
  PlaceholderBold,
  SelectedItems,
  ItemInstances,
  Item,
  ItemList,
  ItemName,
  ItemsContainer,
  SublistButtonWrapper,
  SublistButton,
  SublistButtonText,
  FolderPanel,
  FolderPanelWrapper,
  HeaderText,
  HeaderTextCount,
  ItemIcon,
  ItemIconWithName,
  SearchField,
  SearchIconContainer,
  SelectedItemsContainer,
  Content,
  RelativeContainer,
} from './styles';
import { Icon16, Icon24 } from '../../Icons/Icon';
import Input from '../Input/Input';

export type TEntityProperty = {
  itemColor?: string;
  iconColor?: string;
  avatarSrc?: string;
  count?: number;
};

export type TEntityOption = {
  value: string;
  label: string;
  properties?: TEntityProperty;
};

export type TOptionSublist = {
  id: ID;
  name: string;
  options: TEntityOption[];
};

interface EntityDropdownProps {
  sublists?: TOptionSublist[];
  options: TEntityOption[];
  value?: TEntityOption[];
  multiselect?: boolean;
  noOptionsPlaceholder?: string;
  ghost?: boolean;
  hideSelected?: boolean;
  alignMenu?: 'bottom' | 'right';
  placeholder?: string;
  maxListHeight?: string;
  onChange?(chosenOptions: TEntityOption[]): void;
  onBlur?(): void;
  closeOnSelect?: boolean;
}

type TDivRef = { [key: string]: HTMLDivElement | null };

const EntityDropdown: React.FC<EntityDropdownProps> = ({
  options,
  value,
  multiselect,
  noOptionsPlaceholder,
  maxListHeight,
  ghost,
  placeholder,
  alignMenu = 'bottom',
  sublists,
  onChange,
  onBlur,
  hideSelected,
  closeOnSelect,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showList, setShowList] = useState(false);
  const folderButtonRefs = useRef<TDivRef>({});
  const [showFolderPanelId, setShowFolderPanelId] = useState<ID | null>(null);
  const [chosenOptions, setChosenOptions] = useState<TEntityOption[]>(value || []);

  const [currentOptions, setCurrentOptions] = useState<TEntityOption[]>(
    options
      .filter(
        (option) => !chosenOptions.find((chosenOption) => chosenOption.value === option.value)
      )
      .slice()
  );
  const [currentSublists, setCurrentSublists] = useState<TOptionSublist[]>(
    sublists?.filter((sublist) =>
      sublist.options.some(
        (option) => !chosenOptions.find((chosenOption) => chosenOption.value === option.value)
      )
    ) || []
  );
  const [searchText, setSearchText] = useState<string>('');
  const selectedItemsRef = useRef<HTMLDivElement>(null);
  const relativeContainerRef = useRef<HTMLDivElement>(null);
  const folderPanelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setChosenOptions(value || []);
  }, [value]);

  useEffect(() => {
    if (searchText) {
      const optionsFromSublists = sublists
        ?.flatMap((sublist) => sublist.options)
        .filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));
      const filteredOptions = options.filter(
        (option) =>
          option.label.toLowerCase().includes(searchText.toLowerCase()) &&
          !chosenOptions.find((chosenTag) => chosenTag.value === option.value)
      );
      setCurrentOptions((optionsFromSublists || []).concat(filteredOptions));
    } else {
      const filteredOptions: TEntityOption[] = options.filter(
        (option) => !chosenOptions.find((chosenTag) => chosenTag.value === option.value)
      );
      setCurrentOptions(filteredOptions);
    }
  }, [options, sublists, chosenOptions, searchText]);

  useEffect(() => {
    if (!sublists?.length) return;
    if (searchText) {
      setCurrentSublists([]);
      return;
    }
    const filteredSublists = sublists
      .filter((sublist) =>
        sublist.options.some(
          (option) => !chosenOptions.find((chosenOption) => chosenOption.value === option.value)
        )
      )
      .map((sublist) => ({
        ...sublist,
        options: sublist.options.filter(
          (option) => !chosenOptions.find((chosenOption) => chosenOption.value === option.value)
        ),
      }))
      .filter((group) => group.options.length);

    setCurrentSublists(filteredSublists);
  }, [sublists, chosenOptions, searchText]);

  const handleChange = (chosenOption: TEntityOption, action?: 'add' | 'delete') => {
    if (closeOnSelect) {
      setShowList(false);
      setSearchText('');
    }
    if (action === 'delete') {
      const newChosenTags = chosenOptions.filter((option) => option.value !== chosenOption.value);
      onChange && (!multiselect ? onChange([]) : onChange(newChosenTags));
      return;
    }
    onChange &&
      (!multiselect ? onChange([chosenOption]) : onChange(chosenOptions.concat(chosenOption)));
  };

  const handleBlur = () => {
    setShowList(false);
    setSearchText('');
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        !containerRef.current?.contains(e.target as Node) &&
        !itemsContainerRef.current?.contains(e.target as Node)
      ) {
        e.stopPropagation();
        handleBlur();
        onBlur && onBlur();
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key == 'Escape') {
        e.preventDefault();
        handleBlur();
        onBlur && onBlur();
      }
    };

    window.addEventListener('mousedown', handleClick);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('mousedown', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onBlur]);

  useEffect(() => {
    inputRef && inputRef.current?.focus({ preventScroll: true });
  }, [inputRef]);

  const renderItemContent = (option: TEntityOption) => {
    if (option.properties?.itemColor) {
      return (
        <>
          <ItemName color={option.properties.itemColor}>{option.label}</ItemName>
          {option.properties?.count && <ItemInstances>{option.properties.count}</ItemInstances>}
        </>
      );
    }

    if (option.properties?.iconColor) {
      return (
        <>
          <ItemIconWithName>
            <ItemIcon color={option.properties.iconColor} />
            <ItemName>{option.label}</ItemName>
          </ItemIconWithName>
          {option.properties?.count && <ItemInstances>{option.properties.count}</ItemInstances>}
        </>
      );
    }

    return (
      <>
        <ItemName>{option.label}</ItemName>
        {option.properties?.count && <ItemInstances>{option.properties.count}</ItemInstances>}
      </>
    );
  };

  const renderSelectedItemContent = (option: TEntityOption) => {
    if (option.properties?.iconColor) {
      return (
        <>
          <ItemIconWithName>
            <ItemIcon color={option.properties.iconColor} />
            <ItemName selected>{option.label}</ItemName>
          </ItemIconWithName>
          {option.properties?.count && <ItemInstances>{option.properties.count}</ItemInstances>}
        </>
      );
    }

    return (
      <>
        <ItemName selected>{option.label}</ItemName>
        {option.properties?.count && <ItemInstances>{option.properties.count}</ItemInstances>}
      </>
    );
  };

  const renderSublist = () => {
    const sublist = currentSublists.find((sublist) => sublist.id === showFolderPanelId);
    if (!sublist) return null;

    const buttonRef = folderButtonRefs.current[sublist.id];
    const relativeContainer = relativeContainerRef.current;
    if (!buttonRef || !relativeContainer) return null;

    const {
      top: buttonTop,
      left: buttonLeft,
      width: buttonWidth,
    } = buttonRef.getBoundingClientRect();
    const { top: relativeTop, left: relativeLeft } = relativeContainer.getBoundingClientRect();

    const sublistTop = buttonTop - relativeTop;
    const sublistLeft = buttonLeft - relativeLeft;

    return (
      <FolderPanelWrapper
        left={sublistLeft + buttonWidth}
        top={sublistTop}
        ref={folderPanelRef}
        onMouseLeave={(e) => {
          setShowFolderPanelId(null);
        }}
      >
        <FolderPanel>
          <ItemList>
            {sublist.options.map((option) => (
              <Item
                key={option.value}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleChange(option);
                  setShowFolderPanelId(null);
                }}
              >
                {renderItemContent(option)}
              </Item>
            ))}
          </ItemList>
        </FolderPanel>
      </FolderPanelWrapper>
    );
  };

  return (
    <Container
      ref={containerRef}
      onClick={() => {
        setShowList(!showList);
      }}
      show={showList}
      ghost={ghost}
    >
      <ContentWrapper>
        <Header>
          <HeaderText>
            <PlaceholderBold>{placeholder || 'Choose option'}</PlaceholderBold>
            {!hideSelected && (
              <HeaderTextCount>{chosenOptions.length ? chosenOptions.length : ''}</HeaderTextCount>
            )}
          </HeaderText>

          <IconWrapper show={showList}>
            <Icon24.ChevronBold />
          </IconWrapper>
        </Header>

        <ItemsContainer
          ref={itemsContainerRef}
          show={showList}
          top={
            selectedItemsRef.current?.clientHeight
              ? selectedItemsRef.current?.clientHeight + 10
              : 36
          }
          alignMenu={alignMenu}
          onClick={(e) => e.stopPropagation()}
        >
          <SelectedItemsContainer>
            <SearchField>
              <SearchIconContainer>
                <Icon24.Search />
              </SearchIconContainer>
              <Input
                value={searchText}
                onFocus={(e) => e.stopPropagation()}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search"
                inputType="ghost"
              />
            </SearchField>
            {!!chosenOptions.length && !hideSelected && (
              <SelectedItems
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {chosenOptions.map((option) => (
                  <SelectedItem key={option.value} color={option.properties?.itemColor}>
                    {renderSelectedItemContent(option)}
                    <SelectedItemDeleteButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleChange(option, 'delete');
                      }}
                    >
                      <Icon16.CloseBold />
                    </SelectedItemDeleteButton>
                  </SelectedItem>
                ))}
              </SelectedItems>
            )}
          </SelectedItemsContainer>

          <RelativeContainer ref={relativeContainerRef}>
            <Content maxHeight={maxListHeight}>
              {currentSublists.map((sublist) => (
                <SublistButtonWrapper
                  ref={(el) => {
                    if (el) {
                      folderButtonRefs.current[sublist.id] = el as HTMLDivElement;
                    }
                  }}
                  key={sublist.id}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseEnter={(e) => {
                    setShowFolderPanelId(sublist.id);
                  }}
                  onMouseLeave={(e) => {
                    if (e.relatedTarget !== folderPanelRef.current) setShowFolderPanelId(null);
                  }}
                >
                  <SublistButton
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SublistButtonText>{sublist.name}</SublistButtonText>
                    <Icon16.Arrow />
                  </SublistButton>
                </SublistButtonWrapper>
              ))}

              {!!currentOptions.length && (
                <ItemList>
                  {currentOptions.map((option) => (
                    <Item
                      key={option.value}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleChange(option);
                      }}
                    >
                      {renderItemContent(option)}
                    </Item>
                  ))}
                </ItemList>
              )}
              {!currentOptions.length && !currentSublists.length && (
                <NoOptionsText>{noOptionsPlaceholder || 'No options'}</NoOptionsText>
              )}
            </Content>
            {renderSublist()}
          </RelativeContainer>
        </ItemsContainer>
      </ContentWrapper>
    </Container>
  );
};

export default EntityDropdown;
