import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const Image = styled.div<{ src: string }>`
  width: 150px;
  height: 150px;
  background-image: url(${({src}) => src});
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  margin-bottom: 20px;
  text-align: center;
`

export const FAQLink = styled.a`
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`

export const QuestionIconWrapper = styled.div`
  margin-right: 4px;
`