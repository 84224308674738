import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Icon16, Icon24 } from '../../Icons/Icon';

import PortalNew from '../PortalNew';
import { useLazyQuery } from '@apollo/client';
import SEARCH_EVERYTHING from '../../GraphQL/queries/searchEverything';
import { useDebouncedCallback } from 'use-debounce';

import {
  Overlay,
  SearchPanelContainer,
  Spinner,
  SearchIcon,
  SearchInputWrapper,
  CloseButtonWrapper,
  Header,
  MaximaizeButton,
  Filters,
  FilterIcon,
  FilterDropDownWrapper,
  FilterDivider,
  ProjectDropDownWrapper,
  EntityDropDownWrapper,
  DropDowns,
  TagsContainer,
  TagItem,
  ChevronIcon,
  TagsPlaceholder,
  TagsContent,
  TagMore,
  EmptyState,
  EmptyStateDescription,
  EmptyStateImage,
  EmptyStateTitle,
} from './styles';
import Input from '../Input/Input';
import DropDown from '../Dropdown';
import ProjectsDropdown from '../ProjectsDropdown';
import { ProjectDropDownOption } from '../ProjectsDropdown/ProjectsDropdown';
import { defaultProjectIcons } from '../../Consts/projectIcons';
import SearchResultFull from './SearchResultFull';
import PortalWrapper from '../PortalWrapper';
import { AllTagsDropdown } from '../TagsDropdown/TagsDropdown';
import { TagWithDetails } from '../../Models';
import useBoards, { Folder, useFetchFolders } from '../../Hooks/useBoards';
import { getDashboards_dashboards } from '../../GraphQL/__generated__/getDashboards';

const DATA_SEARCH_ID = 'data';
const NOTES_SEARCH_ID = 'notes';
const INSIGHTS_SEARCH_ID = 'insights';

const dataFilterOptions = [
  { label: 'Insights', value: INSIGHTS_SEARCH_ID },
  { label: 'Notes', value: NOTES_SEARCH_ID },
  { label: 'Data', value: DATA_SEARCH_ID },
];

const sentimentFilterOptions = [
  { label: 'Any sentiment', value: 'any' },
  { label: 'Positive', value: 'positive' },
  { label: 'Negative', value: 'negative' },
  { label: 'Neutral', value: 'neutral' },
];

const dateFilterOptions = [
  { label: 'Anytime', value: 'anytime' },
  { label: 'This week', value: 'week' },
  { label: 'This month', value: 'month' },
  { label: 'The last 6 months', value: 'half_year' },
  { label: 'This year', value: 'year' },
];

const tagsFilterOptions = [
  { label: 'Is any of', value: 'any' },
  { label: 'Is all of', value: 'all' },
  { label: 'Is not', value: 'not' },
];

interface SearchPanelProps {
  onClose: () => void;
}

const SearchPanel: FC<SearchPanelProps> = ({ onClose }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const { fetchBoards } = useBoards();
  const [loadingDashboards, dashboards] = fetchBoards();
  const [foldersLoading, folders] = useFetchFolders();

  const [fetchSearchData, { loading, called, data }] = useLazyQuery(SEARCH_EVERYTHING);
  const [resultData, setResultData] = useState<any>(null);
  const [currentDataFilterOptions, setCurrentDataFilterOption] = useState(dataFilterOptions);
  const [currentSentimentFilterOption, setCurrentSentimentFilterOption] = useState(
    sentimentFilterOptions[0]
  );
  const [currentDateFilterOption, setCurrentDateFilterOption] = useState(dateFilterOptions[0]);
  const [currentTagsFilterOption, setCurrentTagsFilterOption] = useState(tagsFilterOptions[0]);
  const [currentProjectFilterOptions, setCurrentProjectFilterOptions] = useState<
    ProjectDropDownOption[]
  >([]);
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const [currentTags, setCurrentTags] = useState<TagWithDetails[]>([]);
  const tagsListRef = useRef<HTMLDivElement>(null);
  const tagsContainerRef = useRef<HTMLDivElement>(null);
  const [currentExtaTags, setCurrentExtraTags] = useState(0);
  const currentTagsRef = useRef<TagWithDetails[]>(currentTags);
  currentTagsRef.current = currentTags;

  const isSearchParamsSet =
    searchText.length > 2 ||
    currentTags?.length > 0 ||
    currentDateFilterOption.value !== 'anytime' ||
    currentSentimentFilterOption.value !== 'any';

  const debouncedFetchSearchData = useDebouncedCallback(
    (
      searchText: string,
      options: {
        dashboardIds: string[];
        searchTypes: string[];
        sentiment: string;
        dateFilter: string;
        tags: TagWithDetails[];
        tagsFilter: string;
      }
    ) => {
      const baseFilter: { [key: string]: any } = {
        dashboardId: { in: options.dashboardIds },
      };

      if (searchText) {
        baseFilter.textSearch = { matches: searchText };
      }

      if (options.dateFilter === 'week') {
        baseFilter.createdAt = {
          greaterThanOrEqualTo: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        };
      }

      if (options.dateFilter === 'month') {
        baseFilter.createdAt = {
          greaterThanOrEqualTo: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
        };
      }

      if (options.dateFilter === 'half_year') {
        baseFilter.createdAt = {
          greaterThanOrEqualTo: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000),
        };
      }

      if (options.dateFilter === 'year') {
        baseFilter.createdAt = {
          greaterThanOrEqualTo: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        };
      }

      const documentsFilter = {
        ...baseFilter,
      };
      const transcriptsFilter = {
        ...baseFilter,
      };
      const notesFilter = {
        ...baseFilter,
      };
      const insightsFilter = {
        ...baseFilter,
      };

      if (options.sentiment === 'negative') {
        notesFilter.sentimentScore = { lessThan: -0.5 };
      }
      if (options.sentiment === 'positive') {
        notesFilter.sentimentScore = { greaterThan: 0.5 };
      }
      if (options.sentiment === 'neutral') {
        notesFilter.sentimentScore = { lessThanOrEqualTo: 0.5, greaterThanOrEqualTo: -0.5 };
      }

      if (options.tagsFilter === 'any' && options.tags.length > 0) {
        notesFilter.tagsNotesConnection = {
          some: { tagId: { in: options.tags.map((tag) => tag.id) } },
        };
        documentsFilter.tagsDocumentsConnection = {
          some: { tagId: { in: options.tags.map((tag) => tag.id) } },
        };
        transcriptsFilter.tagsTranscriptionsConnection = {
          some: { tagId: { in: options.tags.map((tag) => tag.id) } },
        };
      }

      if (options.tagsFilter === 'all' && options.tags.length > 0) {
        notesFilter.and = options.tags.map((tag) => ({
          tagsNotesConnection: { some: { tagId: { equalTo: tag.id } } },
        }));

        documentsFilter.and = options.tags.map((tag) => ({
          tagsDocumentsConnection: { some: { tagId: { equalTo: tag.id } } },
        }));
        transcriptsFilter.and = options.tags.map((tag) => ({
          tagsTranscriptionsConnection: { some: { tagId: { equalTo: tag.id } } },
        }));
      }

      if (options.tagsFilter === 'not' && options.tags.length > 0) {
        notesFilter.tagsNotesConnection = {
          none: { tagId: { in: options.tags.map((tag) => tag.id) } },
        };
        documentsFilter.tagsDocumentsConnection = {
          none: { tagId: { in: options.tags.map((tag) => tag.id) } },
        };
        transcriptsFilter.tagsTranscriptionsConnection = {
          none: { tagId: { in: options.tags.map((tag) => tag.id) } },
        };
      }

      fetchSearchData({
        variables: {
          documentsFilter,
          transcriptsFilter,
          notesFilter,
          insightsFilter,
          withData: options.searchTypes.includes(DATA_SEARCH_ID),
          withNotes: options.searchTypes.includes(NOTES_SEARCH_ID),
          withInsights: options.searchTypes.includes(INSIGHTS_SEARCH_ID),
        },
      });
    },
    500
  );

  useEffect(() => {
    if (!loadingDashboards && dashboards?.length) {
      setCurrentProjectFilterOptions(
        dashboards.map((dashboard: any) => {
          const iconData = defaultProjectIcons.find((icon) => icon.name === dashboard.cover);

          return {
            label: dashboard.name,
            value: dashboard.id,
            icon: iconData?.url || defaultProjectIcons[0].url,
          };
        })
      );
    }
  }, [dashboards, loadingDashboards]);

  useEffect(() => {
    if (!loading && data && isSearchParamsSet) {
      setResultData(data);
    } else {
      setResultData(null);
    }
  }, [loading, data, searchText, isSearchParamsSet]);

  useEffect(() => {
    if (isSearchParamsSet && currentProjectFilterOptions.length) {
      debouncedFetchSearchData(searchText, {
        dashboardIds: currentProjectFilterOptions.map((item) => item.value),
        searchTypes: currentDataFilterOptions.map((item: any) => item.value),
        sentiment: currentSentimentFilterOption.value,
        tags: currentTags,
        dateFilter: currentDateFilterOption.value,
        tagsFilter: currentTagsFilterOption.value,
      });
    }
  }, [
    searchText,
    currentProjectFilterOptions,
    currentDataFilterOptions,
    debouncedFetchSearchData,
    currentTags,
    currentDateFilterOption,
    currentTagsFilterOption,
    currentSentimentFilterOption,
    isSearchParamsSet,
  ]);

  const projectFolderOptions = useMemo(() => {
    return (
      folders?.map((folder: Folder) => {
        const projectsInFolder = dashboards?.filter(
          (project: getDashboards_dashboards) => project.folderId === folder.id
        );
        return {
          folderId: folder.id,
          folderName: folder.name,
          options:
            projectsInFolder?.map((project) => {
              const iconData = defaultProjectIcons.find((icon) => icon.name === project.cover);
              return {
                value: project.id,
                label: project.name || 'Untitled project',
                icon: iconData?.url || defaultProjectIcons[0].url,
              };
            }) || [],
        };
      }) || []
    );
  }, [dashboards, folders]);

  const projectsWithoutFolderOptions = useMemo(() => {
    return (
      dashboards
        ?.filter((project: getDashboards_dashboards) => !project.folderId)
        .map((project) => {
          const iconData = defaultProjectIcons.find((icon) => icon.name === project.cover);
          return {
            value: project.id,
            label: project.name || 'Untitled project',
            icon: iconData?.url || defaultProjectIcons[0].url,
          };
        }) || []
    );
  }, [dashboards]);

  useEffect(() => {
    updateExtraNumber();
  }, [currentTags, isAdvancedSearch]);

  useEffect(() => {
    const handleResize = () => {
      updateExtraNumber();
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateExtraNumber = () => {
    if (!tagsListRef.current) return;

    let counter = 0;
    let sumWidth = 0;

    for (const item of tagsListRef.current.children) {
      const { width } = item.getBoundingClientRect();
      if (
        Math.floor(width + sumWidth) >
        Math.floor(tagsListRef.current.offsetWidth) + (currentExtaTags ? 32 : 0)
      ) {
        counter++;
        sumWidth += width + 5;
      } else {
        sumWidth += width + 5;
      }
    }

    setCurrentExtraTags(
      counter + currentTagsRef.current.length - tagsListRef.current.children.length
    );
  };

  return (
    <PortalNew wrapperId="searchBox">
      <Overlay
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
      <SearchPanelContainer isAdvancedSearch={isAdvancedSearch}>
        <Header>
          <DropDowns>
            <SearchInputWrapper isAdvancedSearch={isAdvancedSearch}>
              <SearchIcon>
                <Icon24.Search />
              </SearchIcon>
              <Input
                placeholder="Search your workspace"
                inputType="ghost"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                autoFocus
              />
            </SearchInputWrapper>

            <ProjectDropDownWrapper>
              <ProjectsDropdown
                folderOptions={projectFolderOptions}
                projectOptions={projectsWithoutFolderOptions}
                currentOptions={currentProjectFilterOptions}
                onChange={setCurrentProjectFilterOptions}
              />
            </ProjectDropDownWrapper>

            <EntityDropDownWrapper>
              <DropDown
                options={dataFilterOptions}
                multiselect
                size="big"
                currentOptions={currentDataFilterOptions}
                onChangeMultiple={setCurrentDataFilterOption}
              />
            </EntityDropDownWrapper>
          </DropDowns>

          {isAdvancedSearch ? (
            <CloseButtonWrapper
              onClick={() => {
                setIsAdvancedSearch(false);
                onClose();
              }}
            >
              <Icon24.Close />
            </CloseButtonWrapper>
          ) : (
            <MaximaizeButton onClick={() => setIsAdvancedSearch(true)}>
              <Icon24.Maximaize />
            </MaximaizeButton>
          )}
        </Header>
        <Filters>
          <FilterIcon>
            <Icon24.Smile />
          </FilterIcon>
          <FilterDropDownWrapper>
            <DropDown
              options={sentimentFilterOptions}
              placeholder="Any sentiment"
              currentOption={currentSentimentFilterOption}
              onChange={setCurrentSentimentFilterOption}
            />
          </FilterDropDownWrapper>

          <FilterIcon>
            <Icon24.Calendar />
          </FilterIcon>
          <FilterDropDownWrapper>
            <DropDown
              options={dateFilterOptions}
              placeholder="Any date"
              currentOption={currentDateFilterOption}
              onChange={setCurrentDateFilterOption}
            />
          </FilterDropDownWrapper>

          <FilterDivider />

          <FilterIcon>
            <Icon24.Tag />
          </FilterIcon>
          <FilterDropDownWrapper>
            <DropDown
              options={tagsFilterOptions}
              placeholder="Is any of"
              currentOption={currentTagsFilterOption}
              onChange={setCurrentTagsFilterOption}
            />
          </FilterDropDownWrapper>

          <TagsContainer
            isAdvancedSearch={isAdvancedSearch}
            onClick={() => setShowTagsDropdown(true)}
            ref={tagsContainerRef}
          >
            <TagsContent ref={tagsListRef}>
              {currentTags.map((tag) => (
                <TagItem key={tag.id} color={tag.color}>
                  {tag.name}
                </TagItem>
              ))}
            </TagsContent>
            {!currentTags.length && <TagsPlaceholder>All tags</TagsPlaceholder>}
            {!!currentExtaTags && <TagMore>+{currentExtaTags}</TagMore>}
            <ChevronIcon>
              <Icon16.Arrow />
            </ChevronIcon>
          </TagsContainer>

          {showTagsDropdown && (
            <PortalWrapper targetElement={tagsContainerRef.current as HTMLDivElement}>
              <AllTagsDropdown
                dashboardIds={currentProjectFilterOptions.map((item) => item.value)}
                value={currentTags}
                onChange={(tags) => {
                  setCurrentTags(tags);
                }}
                onBlur={() => setShowTagsDropdown(false)}
              />
            </PortalWrapper>
          )}
        </Filters>
        {loading && (
          <Spinner>
            <Icon24.AISpinner />
          </Spinner>
        )}

        {!isSearchParamsSet && isAdvancedSearch && (
          <EmptyState>
            <EmptyStateImage />
            <EmptyStateTitle>Learn something new</EmptyStateTitle>
            <EmptyStateDescription>
              Search by keyword and tags to start learning something new.
            </EmptyStateDescription>
          </EmptyState>
        )}

        {!!isSearchParamsSet &&
          !loading &&
          (isAdvancedSearch ? (
            <SearchResultFull data={resultData} dashboards={dashboards} onClose={onClose} />
          ) : (
            <SearchResultFull
              data={resultData}
              dashboards={dashboards}
              type="light"
              onClose={onClose}
            />
          ))}
      </SearchPanelContainer>
    </PortalNew>
  );
};

export default SearchPanel;
