import React, { FC, useEffect, useMemo, useState } from 'react';

import { useOrganization } from '../../Hooks/useOrganization';
import { CATEGORIES, PROMPT_ICONS } from '../../Consts/promptTemplates';
import PromptDefaultIcon from '../../assets/projects/default-icons/Red-Thread.svg';

import {
  ContentWrapper,
  Sidebar,
  SidebarTop,
  SidebarHeader,
  MainTitle,
  SidebarButton,
  SidebarCategory,
  SidebarBottom,
  ContentContainer,
  Content,
  NewBadge,
  CreditsLeft,
  ProgressBarContainer,
  ProgressBarLine,
  GetMoreCredits,
  Divider,
  CloseButton,
  CloseButtonWrapper,
  BottomButtons,
  Description,
  GenerateButton,
  CancelButton,
  BackIcon,
  IconWrapper,
  ButtonsContainer,
} from './styles';
import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router-dom';
import { Icon24 } from '../../Icons/Icon';
import PromptTemplates from '../PromptTemplates';
import { TPrompt, usePrompts } from '../../Hooks/usePrompts';
import CustomTemplateCta from '../CustomTemplateCta';

interface AITemplatesProps {
  type: 'transcript' | 'insight';
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm(template: TPrompt | null): void;
  onCancel(): void;
  onClickBack?(): void;
}

const AITemplates: FC<AITemplatesProps> = ({
  type,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  onClickBack,
}) => {
  const [, org, refetch] = useOrganization();
  const intercom = useIntercom();
  const history = useHistory();
  const [loadingPrompts, prompts] = usePrompts({ promptType: type });

  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>(null);
  const [currentCategory, setCurrentCategory] = useState('all');

  const hasCredits = org.aiCredits > 0;
  const creditTotalAmount = org.aiCreditLimitTotal || org.aiCreditLimitMonthly;
  const currentScaleRatio = org.aiCredits / creditTotalAmount;
  const customPrompts = prompts?.filter((prompt: any) => !prompt?.isPublic) || [];
  const hasFavorites = prompts.some((prompt) => prompt?.favoriteId);

  useEffect(() => {
    if (!currentTemplateId && prompts && prompts.length > 0) {
      setCurrentTemplateId(prompts[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompts]);

  const createIntercomMessage = (type: 'credits' | 'prompts') => {
    if (type === 'credits') {
      intercom.showNewMessage('Hi, I want to get more AI credits. Please help me out.');
    } else {
      intercom.showNewMessage(
        `Hi, I'd like to learn more about adding a custom AI template to my workspace.`
      );
    }
  };

  const getTemplatesByCategory = useMemo(() => {
    const promptTemplates =
      prompts?.map((prompt: any) => {
        return {
          id: prompt?.id,
          title: prompt?.title,
          description: prompt?.description,
          icon: prompt?.icon || PROMPT_ICONS.get(prompt?.defaultIcon || '') || PromptDefaultIcon,
          isPublic: prompt?.isPublic,
          category: prompt?.category,
          favoriteId: prompt?.favoriteId,
        };
      }) || [];
    const customTemplates = promptTemplates?.filter((template: any) => !template?.isPublic);

    if (currentCategory === 'custom') return customTemplates;
    if (currentCategory === 'all') return promptTemplates;
    if (currentCategory === 'favorites')
      return promptTemplates.filter((template) => template.favoriteId);

    return promptTemplates.filter((template: any) => template.category === currentCategory);
  }, [currentCategory, prompts]);

  return (
    <>
      <ContentWrapper>
        <Sidebar>
          <SidebarTop>
            <SidebarHeader>
              {onClickBack && (
                <BackIcon onClick={onClickBack}>
                  <Icon24.ArrowLeft />
                </BackIcon>
              )}
              <MainTitle>Templates</MainTitle>
            </SidebarHeader>

            <SidebarCategory>
              <SidebarButton
                onClick={() => setCurrentCategory('all')}
                active={currentCategory === 'all'}
              >
                All templates
              </SidebarButton>
            </SidebarCategory>

            <SidebarCategory>
              <SidebarButton
                onClick={() => setCurrentCategory('custom')}
                active={currentCategory === 'custom'}
              >
                Custom <NewBadge>New</NewBadge>
              </SidebarButton>
            </SidebarCategory>

            {CATEGORIES.map((category) => {
              if (!prompts.some((template: any) => template.category === category.id)) return null;
              return (
                <SidebarCategory key={category.id}>
                  <SidebarButton
                    onClick={() => setCurrentCategory(category.id)}
                    active={currentCategory === category.id}
                  >
                    {category.title}
                  </SidebarButton>
                </SidebarCategory>
              );
            })}

            {hasFavorites && (
              <>
                <SidebarCategory>
                  <SidebarButton
                    onClick={() => setCurrentCategory('favorites')}
                    active={currentCategory === 'favorites'}
                  >
                    <IconWrapper>
                      <Icon24.Heart />
                    </IconWrapper>
                    Favorites
                  </SidebarButton>
                </SidebarCategory>
              </>
            )}
          </SidebarTop>

          <SidebarBottom>
            <CreditsLeft>
              {hasCredits ? `${org.aiCredits} credits left` : 'You’re out of AI credits'}
            </CreditsLeft>
            <ProgressBarContainer>
              <ProgressBarLine scaleRatio={currentScaleRatio} />
            </ProgressBarContainer>

            <GetMoreCredits
              onClick={
                hasCredits
                  ? () => createIntercomMessage('credits')
                  : () => history.push('/settings/plans')
              }
            >
              {hasCredits ? 'Get more credits' : 'Upgrade for more credits'}
            </GetMoreCredits>
          </SidebarBottom>
        </Sidebar>

        <ContentContainer>
          <CloseButtonWrapper>
            <CloseButton onClick={onCancel}>
              <Icon24.Close />
            </CloseButton>
          </CloseButtonWrapper>
          <Content>
            {currentTemplateId && prompts && prompts.length > 0 && (
              <>
                <PromptTemplates
                  templates={getTemplatesByCategory}
                  currentTemplateId={currentTemplateId}
                  onChange={setCurrentTemplateId}
                />

                {currentCategory === 'all' && <Divider />}

                {(currentCategory === 'all' || currentCategory === 'custom') &&
                  (!customPrompts || customPrompts.length === 0) && (
                    <CustomTemplateCta onClick={() => createIntercomMessage('prompts')} />
                  )}
              </>
            )}
          </Content>
        </ContentContainer>
      </ContentWrapper>
      <BottomButtons>
        <Description>
          {hasCredits
            ? `This will use 1 of your ${org.aiCredits} available credits`
            : 'You’re out of AI credits'}
        </Description>

        <ButtonsContainer>
          <GenerateButton
            onClick={() =>
              onConfirm(prompts.find((template) => template.id === currentTemplateId) || null)
            }
            disabled={!hasCredits || !currentTemplateId}
          >
            {confirmButtonText ? confirmButtonText : 'Summarize'}
          </GenerateButton>
          <CancelButton onClick={onCancel}>
            {cancelButtonText ? cancelButtonText : 'Cancel'}
          </CancelButton>
        </ButtonsContainer>
      </BottomButtons>
    </>
  );
};

export default AITemplates;
