import React, { useContext, useState } from 'react';
import { Editor } from '@tiptap/react';
import {
  Button,
  Buttons,
  ButtonsContainer,
  Container,
  GenerateSummaryButton,
  IconWrapper,
} from './styles';
import { Icon24 } from '../../Icons/Icon';
import EditorLinkModal from '../EditorLinkModal';
import EditorImageModal from '../EditorImageModal';
import PortalNew from '../PortalNew';
import SummaryGenerationModal from '../SummaryGenerationModal';
import { useGenerateSummary } from '../../Hooks/useSummary';

import { ArrowDownTrayIcon } from '@heroicons-v2/react/24/outline';
import { TPrompt } from '../../Hooks/usePrompts';
import { EvidenceContext } from '../../Context/EvidenceContext';
import EvidenceModal from '../EvidenceModal';
import { Insight } from '../../Models';
import DefaultButton from '../Button';
import { ProjectChecklistContext } from '../../Context/ProjectChecklistContext';

export type SummaryGenerationData = {
  content: string[];
  documentId?: string;
  transcriptId?: string;
};

export type DownloadData = {
  filename: string;
  content: string;
};

function download(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

interface InfoEditorToolbarProps {
  editor: Editor | null;
  withToolbarMargin?: boolean;
  insight?: Insight;
  isInsightsPanel?: boolean;
  getSummaryGenerationData?(editor: Editor | null): SummaryGenerationData;
  getDownloadData?(editor: Editor | null): DownloadData;
}

const InfoEditorToolbar: React.FC<InfoEditorToolbarProps> = ({
  editor,
  withToolbarMargin,
  insight,
  isInsightsPanel,
  getSummaryGenerationData,
  getDownloadData,
}) => {
  const [modalWithLinkOpen, setModalWithLinkOpen] = useState({ isOpen: false, url: '', text: '' });
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [generationModalOpen, setGenerationModalOpen] = useState(false);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const { generateDataSummary } = useGenerateSummary();
  const { addEvidence } = useContext(EvidenceContext);
  const { markSummaryAdded } = useContext(ProjectChecklistContext);

  const setLink = () => {
    if (!editor) return;
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to, ' ');

    setModalWithLinkOpen({
      isOpen: true,
      url: editor.getAttributes('link').href || '',
      text: text,
    });
  };

  const setImage = () => {
    setIsImageModalOpen(true);
  };

  const handleAddImageComplete = (src: string) => {
    if (editor) {
      editor.chain().focus().setImage({ src }).run();
    }
    setIsImageModalOpen(false);
  };

  const handleLinkComplete = ({ url, text }: { url: string; text: string }) => {
    if (!editor) return;
    if (!url) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    } else {
      const { from, to } = editor.view.state.selection;
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
      editor.chain().focus().insertContentAt({ from, to }, text).run();
    }

    setModalWithLinkOpen({ isOpen: false, url: '', text: '' });
  };

  const handleGenerateSummaryClick = () => {
    setGenerationModalOpen(true);
  };

  const handleGenerateSummary = async (template: TPrompt) => {
    if (!getSummaryGenerationData || !editor) return;
    const { content, documentId, transcriptId } = getSummaryGenerationData(editor);

    const { success, summaryId, status } = await generateDataSummary({
      quotes: content,
      documentId,
      transcriptId,
      templateId: template.id,
    });

    const newSummaryNode = {
      type: 'summary',
      attrs: {
        id: summaryId,
        jobId: summaryId,
        status: 'generating',
        templateId: template.id,
        templateTitle: template.title,
      },
      content: [],
    };

    markSummaryAdded();
    setTimeout(() => editor.chain().focus().insertContentAt(0, newSummaryNode).run(), 0);
  };

  if (!editor) {
    return null;
  }

  return (
    <Container withToolbarMargin={withToolbarMargin}>
      <ButtonsContainer>
        <Buttons>
          {getSummaryGenerationData && (
            <GenerateSummaryButton onClick={handleGenerateSummaryClick}>
              <IconWrapper>
                <Icon24.AIStar />
              </IconWrapper>
              Summarize
            </GenerateSummaryButton>
          )}
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
          >
            <Icon24.Bold />
          </Button>

          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
          >
            <Icon24.Italic />
          </Button>

          <Button
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            isActive={editor.isActive('heading', { level: 1 })}
          >
            <Icon24.H1 />
          </Button>

          <Button
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            isActive={editor.isActive('heading', { level: 2 })}
          >
            <Icon24.H2 />
          </Button>

          <Button
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            isActive={editor.isActive('heading', { level: 3 })}
          >
            <Icon24.H3 />
          </Button>

          <Button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}
          >
            <Icon24.OrderedList />
          </Button>

          <Button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
          >
            <Icon24.BulletList />
          </Button>

          <Button onClick={setImage}>
            <Icon24.Image />
          </Button>

          <Button
            onClick={setLink}
            isActive={editor.isActive('link')}
            disabled={editor.view.state.selection.empty}
          >
            <Icon24.Link />
          </Button>
        </Buttons>

        <Buttons>
          {insight && !isInsightsPanel && (
            <DefaultButton type="secondary" onClick={() => setIsEvidenceModalOpen(true)}>
              <Icon24.Plus />
              Add
            </DefaultButton>
          )}

          {getDownloadData && (
            <Button
              onClick={() => {
                const { filename, content } = getDownloadData(editor);
                download(`${filename}.txt`, content);
              }}
            >
              <ArrowDownTrayIcon width={24} />
            </Button>
          )}
        </Buttons>
      </ButtonsContainer>

      <EditorLinkModal
        url={modalWithLinkOpen.url}
        text={modalWithLinkOpen.text}
        isOpen={modalWithLinkOpen.isOpen}
        onComplete={handleLinkComplete}
        onClose={() => setModalWithLinkOpen({ isOpen: false, url: '', text: '' })}
      />

      <EditorImageModal
        isOpen={isImageModalOpen}
        onComplete={handleAddImageComplete}
        onClose={() => setIsImageModalOpen(false)}
      />

      {insight && !isInsightsPanel && (
        <PortalNew wrapperId="plusButton">
          <EvidenceModal
            dashboardId={insight.dashboardId}
            insightId={insight.id}
            isOpen={isEvidenceModalOpen}
            onClose={() => setIsEvidenceModalOpen(false)}
            onSubmit={(evidences) => {
              for (const note of evidences.notes || []) {
                const id = Math.floor(Math.random() * Date.now()).toString(16);
                const newNoteBlock = {
                  type: 'noteBlock',
                  attrs: { id, dashboardId: note.dashboardId },
                };

                const anchor = editor.state.selection.anchor;
                const { size: endPosition } = editor.view.state.doc.content;

                addEvidence({
                  dashboardId: insight.dashboardId,
                  parentInsightId: insight.id,
                  entityId: id,
                  noteId: note.id,
                });

                setTimeout(() => {
                  editor
                    .chain()
                    .focus()
                    .insertContentAt(anchor === 1 ? endPosition : anchor, newNoteBlock)
                    .run();
                  editor.chain().focus(anchor).run();
                }, 0);
              }

              for (const currentInsight of evidences.insights || []) {
                const id = Math.floor(Math.random() * Date.now()).toString(16);
                const newInsightBlock = {
                  type: 'insightBlock',
                  attrs: {
                    id,
                    dashboardId: insight.dashboardId,
                  },
                };

                const anchor = editor.state.selection.anchor;

                addEvidence({
                  dashboardId: insight.dashboardId,
                  entityId: id,
                  parentInsightId: insight.id,
                  insightId: currentInsight.id,
                });

                setTimeout(
                  () =>
                    editor
                      .chain()
                      .focus()
                      .insertContentAt({ from: anchor, to: anchor + 1 }, newInsightBlock)
                      .run(),
                  0
                );
              }

              setIsEvidenceModalOpen(false);
            }}
          />
        </PortalNew>
      )}

      {getSummaryGenerationData && (
        <PortalNew wrapperId="summaryGenerationModalToolbar">
          <SummaryGenerationModal
            isOpen={generationModalOpen}
            onConfirm={(template) => handleGenerateSummary(template)}
            onClose={() => {
              setGenerationModalOpen(false);
            }}
          />
        </PortalNew>
      )}
    </Container>
  );
};

export default InfoEditorToolbar;
