import React, { FC } from 'react';

export const QuestionMarkIcon: FC<{ color?: string }> = ({ color = '#D7D3DC' }) => {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8.25" stroke={color} strokeWidth="1.5" />
      <path
        d="M8.216 10.907v-.145c.003-.497.047-.893.132-1.189.088-.295.216-.534.383-.716.168-.182.37-.346.606-.494.176-.114.333-.232.473-.354.139-.122.25-.257.332-.405.082-.15.123-.318.123-.503a.941.941 0 0 0-.14-.515.936.936 0 0 0-.38-.341 1.13 1.13 0 0 0-.52-.12c-.184 0-.359.042-.523.124a1 1 0 0 0-.405.358c-.105.156-.162.351-.17.584h-1.74c.015-.568.151-1.037.41-1.406.258-.372.6-.65 1.027-.831a3.51 3.51 0 0 1 1.41-.277c.566 0 1.066.094 1.5.281.435.185.776.453 1.023.805.247.353.37.777.37 1.275 0 .332-.055.627-.165.886a2.16 2.16 0 0 1-.456.682 3.533 3.533 0 0 1-.695.532c-.224.134-.41.273-.554.418a1.42 1.42 0 0 0-.32.503c-.068.19-.103.425-.106.703v.145H8.216Zm.844 2.727a1 1 0 0 1-.73-.298.98.98 0 0 1-.293-.725.962.962 0 0 1 .294-.716 1 1 0 0 1 .729-.298c.27 0 .507.1.711.299a.978.978 0 0 1 .162 1.231 1.102 1.102 0 0 1-.37.37.969.969 0 0 1-.503.137Z"
        fill={color}
      />
    </svg>
  );
};
export default QuestionMarkIcon;
