import styled from 'styled-components';
import promptsCtaImage from '../../assets/illustrations/prompts-cta.png';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
  `}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 999;
  `}
`;

export const Container = styled.div<{ isOpen: boolean; maxHeight: number }>`
  width: 100%;
  height: 100%;
  max-width: 1020px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  background-color: #ffffff;
  border-radius: 6px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  transition: opacity 0.12s ease-in;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
    z-index: 9999;
    pointer-events: all;
    opacity: 1;
  `}
`;
