import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Container = styled.nav`
  position: sticky;
  top: 0;
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-color: #e5e7eb;
`;

export const MobileMenuContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  z-index: 999;
  padding: 20px;
`;

export const MobileLogoWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileMenuLink = styled.a<{ selected?: boolean }>`
  display: block;
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 6px;
  background-color: ${({ selected }) => (selected ? 'rgba(133, 95, 168, 0.1)' : '#ffffff')};
  margin-bottom: 8px;

  &:last-child {
    margin: 0;
  }
`;

export const TrialUpgradeLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: #d2cef8;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 6px;
  border: 1px solid var(--Light-Purple-Gray, #d7d3dc);
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
`;

export const FullLogoContainer = styled.div<{ src: string }>`
  width: 120px;
  height: 26px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
`;

export const NewButtonWrapper = styled.div`
  margin-left: 36px;
`;
