import { gql, useMutation, useQuery } from '@apollo/client';
import { CURRENT_ORG } from '../GraphQL/queries';

const ADJUST_PAID_SEATS = gql`
  mutation adjustPaidSeats($seats: Int!) {
    adjustPaidSeats(input: { seats: $seats }) {
      success
    }
  }
`;

const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($lookupKey: String!, $seats: Int!) {
    createCheckoutSession(input: { lookupKey: $lookupKey, seats: $seats }) {
      checkoutUrl
    }
  }
`;

const CREATE_PORTAL_SESSION = gql`
  mutation createPortalSession {
    createPortalSession {
      portalUrl
    }
  }
`;

const FETCH_BILLING_INFO = gql`
  query billingInfo {
    billingInfo {
      portalUrl
      card {
        brand
        last4
        exp_month
        exp_year
      }
      upcomingInvoice {
        created
        status
        amount
        url
      }
      invoices {
        created
        status
        amount
        url
      }
    }
  }
`;

export const useBillingInfo = (): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_BILLING_INFO);
  return [loading, data?.billingInfo];
};

export function usePayments() {
  const [mutationCheckout] = useMutation(CREATE_CHECKOUT_SESSION);
  const [mutationPortal] = useMutation(CREATE_PORTAL_SESSION);

  async function createCheckoutSession(lookupKey: string, seats: number) {
    const { data } = await mutationCheckout({
      variables: {
        lookupKey,
        seats,
      },
    });

    return data.createCheckoutSession?.checkoutUrl;
  }

  async function createPortalSession() {
    const result = await mutationPortal();

    return result.data.createPortalSession?.portalUrl;
  }

  return {
    createCheckoutSession,
    createPortalSession,
  };
}

export const useAdjustPaidSeats = (): ((seats: number) => void) => {
  const [updateMutation] = useMutation(ADJUST_PAID_SEATS);

  return (seats: number) => {
    return updateMutation({
      variables: {
        seats,
      },
      refetchQueries: [
        {
          query: CURRENT_ORG,
        },
      ],
    });
  };
};
