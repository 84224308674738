import React, { SyntheticEvent, useEffect, useRef } from 'react';
import useMinimizeOnScroll from '../Hooks/useMinimizeOnScroll';
import { TimeOffset } from '../Pages/Transcript/Transcript';

export default function AudioPlayer({
  src,
  timeOffset,
  srcType,
  scrollingContainer,
}: {
  src: string;
  srcType: string;
  timeOffset: TimeOffset;
  scrollingContainer?: HTMLDivElement | null;
}): JSX.Element {
  const playerRef = useRef<HTMLAudioElement>(null);
  const [isMinimized, playerRect, handleOnLoad] = useMinimizeOnScroll(
    playerRef,
    scrollingContainer
  );

  const onAudioLoad = (e: SyntheticEvent<HTMLAudioElement>) => {
    handleOnLoad();
    if (timeOffset.offset) e.currentTarget.currentTime = timeOffset.offset;
  };

  useEffect(() => {
    if (playerRef.current && timeOffset.offset) {
      playerRef.current.currentTime = timeOffset.offset;

      if (timeOffset.shouldPlay) {
        playerRef.current.play();
      }
    }
  }, [timeOffset]);

  return (
    <div className={'bg-white flex justify-center'}>
      {isMinimized && <div style={{ height: playerRect.height, width: '100%' }}></div>}
      <audio
        onLoadedData={onAudioLoad}
        ref={playerRef}
        style={
          !isMinimized
            ? {
                width: '80%',
                zIndex: 3,
              }
            : {
                position: 'fixed',
                left: '12px',
                bottom: '72px',
                width: '20vw',
                zIndex: 3,
              }
        }
        controls
      >
        <source src={src} type={srcType} />
      </audio>
    </div>
  );
}
