import React from 'react';

const LineIcon = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="1"
      y1="16.5565"
      x2="16.5563"
      y2="1.00014"
      stroke="#3B2651"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default LineIcon;
