import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Container = styled.div`
  overflow: hidden;
  padding: 0 20px;
  flex-direction: column;
  height: 100%;

  ${breakpoint.Mobile`
    padding: 0 10px;
  `}
`;

export const SecondaryLabel = styled.span`
  margin-left: 2px;
  /* display: flex; */
  padding: 3px 3px;
  /* align-items: center; */
  gap: 5px;
  border-radius: 3px;
  background: rgba(56, 33, 82, 0.2);
  color: #382152;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
