import moment from 'moment';
import React, { FC } from 'react';
import { Icon24 } from '../../Icons/Icon';
import { useIntercom } from 'react-use-intercom';

import {
  Container,
  Header,
  Title,
  HeaderInfo,
  EndDateContainer,
  EndDateText,
  TextWithBackground,
  IconWrapper,
  GrayText,
  ContactUsText,
} from './styles';

interface TrialPlanPanelProps {
  endDate: moment.Moment;
}

const TrialPlanPanel: FC<TrialPlanPanelProps> = ({ endDate }) => {
  const intercom = useIntercom();
  const expired = endDate.isBefore(moment());
  const daysLeft = Math.max(endDate.diff(moment(), 'days') + 1, 0);
  const daysWord = daysLeft === 1 ? 'day' : 'days';
  return (
    <Container>
      <Header>
        <Title>Teams trial</Title>
        <HeaderInfo>
          <EndDateContainer>
            <EndDateText>Trial end date</EndDateText>
            <TextWithBackground type="date">
              <IconWrapper>
                <Icon24.Calendar />
              </IconWrapper>
              {endDate.format('MM/DD/YYYY').toString()}
            </TextWithBackground>
          </EndDateContainer>

          <TextWithBackground type={expired ? 'expired' : 'daysLeft'}>
            <IconWrapper>
              {expired ? <Icon24.ExclamationCircle /> : <Icon24.Lightning />}
            </IconWrapper>
            {expired ? 'Expired' : `${daysLeft} ${daysWord} left in trial`}
          </TextWithBackground>
        </HeaderInfo>
      </Header>
      <GrayText>
        Questions? Check out our Billing FAQ. Something not right?{' '}
        <ContactUsText
          onClick={() =>
            intercom.showNewMessage(
              'Hi, I am having problems with my trial period. Please help me out. '
            )
          }
        >
          Contact us.
        </ContactUsText>
      </GrayText>
    </Container>
  );
};

export default TrialPlanPanel;
