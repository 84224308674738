import React, { useCallback, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Avatar from '../Avatar';
import InsightCoverPhoto from '../InsightCoverPhoto';
import usePermissions from '../../Hooks/usePermissions';
import { Insight } from '../../Models';
import TipTapEditor from '../TipTapEditor';

import {
  InsightEditorContainer,
  Details,
  TitleInput,
  DetailsRow,
  LabelWithIcon,
  UserIconI,
  UserName,
  CalendarIconI,
  Label,
} from './styles';
import { EvidenceContextProvider } from '../../Context/EvidenceContext';
import { useFetchInsightEvidences } from '../../Hooks/useEvidences';
import CoverPhotoGenerationModal from '../CoverPhotoGenerationModal';
import { htmlToText } from 'html-to-text';

type Props = {
  width?: number;
  expanded?: boolean;
  isInsightsPanel: boolean;
  insight: Insight;
  onSave: (content: any) => void;
  onChangeText?: (content: string) => void;
  className?: string;
};

export default function InsightEditor({
  insight,
  onSave,
  onChangeText,
  width,
  className,
  expanded = true,
  isInsightsPanel,
}: Props): JSX.Element {
  const { canEditInsights } = usePermissions();
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
  const [loading, data, refetch] = useFetchInsightEvidences(insight.id);
  const [isGenerationModalOpen, setGenerationModalOpen] = React.useState(false);
  const [currentInsightContent, setCurrentInsightContent] = React.useState<string>('');

  const handleContainerRef = useCallback((node) => {
    setContainerElement(node);
  }, []);

  return (
    <InsightEditorContainer
      maxWidth={width}
      className={`insight-editor h-full ${className}`}
      ref={handleContainerRef}
    >
      {isInsightsPanel && (
        <>
          <InsightCoverPhoto
            data={insight}
            expanded={expanded}
            isInsightsPanel={isInsightsPanel}
            updateInsight={onSave}
            onShowGenerationModal={() => setGenerationModalOpen(true)}
          />

          {isGenerationModalOpen && (
            <CoverPhotoGenerationModal
              insightContent={currentInsightContent}
              onClose={() => setGenerationModalOpen(false)}
              onChangeCover={onSave}
            />
          )}
        </>
      )}
      <Details maxWidth={width}>
        <TitleInput
          defaultValue={insight?.title}
          onBlur={(e) => onSave({ title: e.target.value })}
          onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
          placeholder="Untitled insight"
          className={'text-3xl my-6 w-full font-medium'}
          autoFocus={!insight?.title}
          readOnly={!canEditInsights}
        />
        <DetailsRow>
          <LabelWithIcon>
            <UserIconI />
            <Label>Created by</Label>
          </LabelWithIcon>
          <div className="flex items-center flex-1">
            <Avatar user={insight?.userByCreatedBy} />
            <UserName>{insight?.userByCreatedBy?.name || ''}</UserName>
          </div>
        </DetailsRow>
        <DetailsRow>
          <LabelWithIcon>
            <CalendarIconI />
            <Label>Date</Label>
          </LabelWithIcon>
          <div className="cursor-pointer">
            <DatePicker
              onChange={(date) => {
                onSave({
                  customDate: date,
                });
              }}
              customInput={
                <div>{moment(insight?.customDate || insight?.createdAt).format('MM/DD/YYYY')}</div>
              }
              selected={new Date(insight?.customDate || insight?.createdAt)}
              disabled={!canEditInsights}
              popperPlacement="bottom"
            />
          </div>
        </DetailsRow>
      </Details>
      {containerElement && (
        <EvidenceContextProvider loading={loading} data={data} refetch={refetch}>
          <TipTapEditor
            content={JSON.parse(insight.content || '{}')}
            placeholder="add more description..."
            editable={canEditInsights}
            containerElt={containerElement}
            onChange={(content) => onSave({ content: JSON.stringify(content) })}
            onChangeText={(text: string) => {
              onChangeText && onChangeText(text);
              setCurrentInsightContent(htmlToText(text, { wordwrap: 130 }));
            }}
            insight={insight}
            isInsightsPanel={isInsightsPanel}
            withEvidences
            withToolbar
            withToolbarMargin={!isInsightsPanel}
          />
        </EvidenceContextProvider>
      )}
    </InsightEditorContainer>
  );
}
