import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { STICKY_COLORS } from '../Consts';
import {
  FETCH_TAG,
  FETCH_TAGS,
  FETCH_TAG_GROUPS,
  FETCH_TAG_GROUP_SORTINGS,
} from '../GraphQL/queries';
import {
  ID,
  Sticky,
  TGroupSort,
  TGroupSortRecieved,
  TTagGroup,
  TUpdateTagGroup,
  Tag,
  TagInput,
  TagWithDetails,
} from '../Models';
import {
  CREATE_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  MERGE_TAGS,
  CREATE_TAG_GROUP,
  UPDATE_TAG_GROUP,
  DELETE_TAG_GROUP,
  CREATE_TAG_GROUP_SORTING,
  UPDATE_TAG_GROUP_SORTING,
} from '../GraphQL/mutations';
import useFeatures from './useFeatures';

export const useFetchTagGroups = (dashboardId: string): [boolean, TTagGroup[], any] => {
  const { globalTagsEnabled } = useFeatures();
  const filter = globalTagsEnabled
    ? { or: [{ isGlobal: { equalTo: true } }, { dashboardId: { equalTo: dashboardId } }] }
    : { dashboardId: { equalTo: dashboardId } };

  const { loading, data, refetch } = useQuery(FETCH_TAG_GROUPS, {
    variables: { filter },
  });

  return [loading, data?.tagGroups, refetch];
};

export const useFetchTagGroupsByDashboardIds = (
  dashboardIds: string[]
): [boolean, TTagGroup[], any] => {
  const { globalTagsEnabled } = useFeatures();
  const filter = globalTagsEnabled
    ? { or: [{ isGlobal: { equalTo: true } }, { dashboardId: { in: dashboardIds } }] }
    : { dashboardId: { in: dashboardIds } };

  const { loading, data, refetch } = useQuery(FETCH_TAG_GROUPS, {
    variables: { filter },
  });

  return [loading, data?.tagGroups, refetch];
};

export const useFetchGlobalTagGroups = (): [boolean, TTagGroup[], () => void] => {
  const { loading, data, refetch } = useQuery(FETCH_TAG_GROUPS, {
    variables: {
      filter: { isGlobal: { equalTo: true } },
    },
  });

  return [loading, data?.tagGroups || [], refetch];
};

export const useCreateTagGroup = (): ((dashboardId: string, isGlobal: boolean) => Promise<Tag>) => {
  const [createMutation] = useMutation(CREATE_TAG_GROUP);

  return async (dashboardId: string, isGlobal: boolean) => {
    const res = await createMutation({
      variables: {
        input: {
          dashboardId,
          name: 'New group',
          isGlobal,
        },
      },
    });

    return res?.data?.createTagGroup?.tagGroup;
  };
};

export const useCreateGlobalTagGroup = (): (() => Promise<Tag>) => {
  const [createMutation] = useMutation(CREATE_TAG_GROUP);

  return async () => {
    const res = await createMutation({
      variables: {
        input: {
          name: 'New group',
          isGlobal: true,
        },
      },
    });

    return res?.data?.createTagGroup?.tagGroup;
  };
};

export const useUpdateTagGroup = (): ((id: ID, input: TUpdateTagGroup) => Promise<void>) => {
  const [updateMutation] = useMutation(UPDATE_TAG_GROUP);

  return async (id: ID, input: TagInput) => {
    const res = await updateMutation({
      variables: {
        id,
        input,
      },
    });

    return res?.data?.updateTagGroup;
  };
};

export const useDeleteTagGroup = (): ((id: ID) => Promise<void>) => {
  const [deleteMutation] = useMutation(DELETE_TAG_GROUP);

  return async (id: ID) => {
    const res = await deleteMutation({
      variables: {
        id,
      },
    });
    return res?.data?.deleteTagGroup;
  };
};

export const useFetchTagGroupSorting = (dashboardId: string): [boolean, TGroupSort[], any] => {
  const { globalTagsEnabled } = useFeatures();
  const filter = globalTagsEnabled
    ? { or: [{ isGlobal: { equalTo: true } }, { dashboardId: { equalTo: dashboardId } }] }
    : { dashboardId: { equalTo: dashboardId } };

  const { loading, data, refetch } = useQuery(FETCH_TAG_GROUP_SORTINGS, {
    variables: { filter },
  });

  const sortings = useMemo(() => {
    const newSortings = data?.tagGroupSortings as TGroupSortRecieved[];
    if (!newSortings) return [];
    return newSortings.map((item) => ({
      ...item,
      sorting: JSON.parse(item.sorting),
    })) as TGroupSort[];
  }, [data?.tagGroupSortings]);

  return [loading, sortings, refetch];
};

export const useFetchGlobalTagGroupSorting = (): [boolean, TGroupSort | null, () => void] => {
  const { loading, data, refetch } = useQuery(FETCH_TAG_GROUP_SORTINGS, {
    variables: {
      filter: { isGlobal: { equalTo: true } },
    },
  });

  const sorting = useMemo(() => {
    const newSortings = data?.tagGroupSortings as TGroupSortRecieved[];

    return newSortings && !!newSortings.length
      ? ({
          ...newSortings[0],
          sorting: JSON.parse(newSortings[0].sorting),
        } as TGroupSort)
      : null;
  }, [data?.tagGroupSortings]);

  return [loading, sorting, refetch];
};

export const useCreateTagGroupSorting = (): ((
  dashboardId: string,
  sorting: ID[],
  isGlobal: boolean
) => Promise<TGroupSort>) => {
  const [createMutation] = useMutation(CREATE_TAG_GROUP_SORTING);

  return async (dashboardId: string, sorting: ID[], isGlobal: boolean) => {
    const res = await createMutation({
      variables: {
        input: {
          tagGroupSorting: {
            dashboardId,
            sorting: JSON.stringify(sorting),
            isGlobal,
          },
        },
      },
    });

    return res?.data?.createTagGroup?.tagGroup;
  };
};

export const useCreateGlobalTagGroupSorting = (): ((sorting: ID[]) => Promise<TGroupSort>) => {
  const [createMutation] = useMutation(CREATE_TAG_GROUP_SORTING);

  return async (sorting: ID[]) => {
    const res = await createMutation({
      variables: {
        input: {
          tagGroupSorting: {
            sorting: JSON.stringify(sorting),
            isGlobal: true,
          },
        },
      },
    });

    return res?.data?.createTagGroup?.tagGroup;
  };
};

export const useUpdateTagGroupSortings = (): ((id: ID, sorting: ID[]) => Promise<void>) => {
  const [updateMutation] = useMutation(UPDATE_TAG_GROUP_SORTING);

  return async (id: ID, sorting: ID[]) => {
    const res = await updateMutation({
      variables: {
        id,
        input: {
          sorting: JSON.stringify(sorting),
        },
      },
    });

    return res?.data?.updateTagGroup;
  };
};

export const useFetchTag = (tagId: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_TAG, {
    variables: { tagId },
  });
  return [loading, data?.tag];
};

function useTags() {
  const { loading, data } = useQuery(FETCH_TAGS);

  function fetchTags() {
    return [loading, data?.tags];
  }

  function getFromStickies(stickies: Sticky[]): Tag[] {
    return [...new Set(stickies.map((x) => x.tagsList).flat())];
  }

  return {
    fetchTags,
    getFromStickies,
  };
}

export const useDashboardTags = (
  dashboardId: string,
  detailed = false,
  includeGlobal = true
): [boolean, Tag[] | TagWithDetails[], () => void] => {
  const { globalTagsEnabled } = useFeatures();
  const filter =
    includeGlobal && globalTagsEnabled
      ? { or: [{ isGlobal: { equalTo: true } }, { dashboardId: { equalTo: dashboardId } }] }
      : { dashboardId: { equalTo: dashboardId } };
  const { loading, data, refetch } = useQuery(FETCH_TAGS, {
    variables: {
      filter: filter,
      withDetails: detailed,
    },
  });

  return [loading, data?.tags || [], refetch];
};

export const useDashboardsTags = (
  dashboardIds: string[],
  detailed = false,
  includeGlobal = true
): [boolean, Tag[] | TagWithDetails[], () => void] => {
  const { globalTagsEnabled } = useFeatures();
  const filter =
    includeGlobal && globalTagsEnabled
      ? { or: [{ isGlobal: { equalTo: true } }, { dashboardId: { in: dashboardIds } }] }
      : { dashboardId: { in: dashboardIds } };
  const { loading, data, refetch } = useQuery(FETCH_TAGS, {
    variables: {
      filter: filter,
      withDetails: detailed,
    },
  });

  return [loading, data?.tags || [], refetch];
};

export const useGlobalTags = (detailed = false): [boolean, Tag[], () => void] => {
  const { loading, data, refetch } = useQuery(FETCH_TAGS, {
    variables: {
      filter: { isGlobal: { equalTo: true } },
      withDetails: detailed,
    },
  });

  return [loading, data?.tags || [], refetch];
};

export const useCreateTag = (): ((tag: TagInput) => Promise<Tag>) => {
  const [createMutation] = useMutation(CREATE_TAG);

  return async (tag: TagInput) => {
    const res = await createMutation({
      variables: {
        input: {
          color: STICKY_COLORS[Math.floor(Math.random() * STICKY_COLORS.length)],
          ...tag,
        },
      },
    });
    return res?.data?.createTag?.tag;
  };
};

export const useUpdateTag = (): ((id: ID, input: TagInput) => Promise<Tag>) => {
  const [updateMutation] = useMutation(UPDATE_TAG);

  return async (id: ID, input: TagInput) => {
    const res = await updateMutation({
      variables: {
        id,
        input,
      },
    });
    return res?.data?.updateTag?.tag;
  };
};

export const useDeleteTag = (): ((id: ID) => Promise<void>) => {
  const [deleteMutation] = useMutation(DELETE_TAG);

  return async (id: ID) => {
    const res = await deleteMutation({
      variables: {
        id,
      },
    });
    return res?.data?.deleteTag?.tag;
  };
};

export const useMergeTags = (): ((
  name: string,
  ids: ID[],
  groupId: ID | null,
  isGlobal?: boolean,
  dashboardId?: string
) => Promise<void>) => {
  const [mergeTagsMutation] = useMutation(MERGE_TAGS);

  return async (
    name: string,
    ids: ID[],
    groupId: ID | null,
    isGlobal?: boolean,
    dashboardId?: string
  ) => {
    const res = await mergeTagsMutation({
      variables: {
        name,
        ids,
        groupId,
        dashboardId: dashboardId || null,
        isGlobal: !!isGlobal,
      },
    });

    return res?.data?.mergeTags;
  };
};

export default useTags;
