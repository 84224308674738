import React from 'react';
import GrabIcon from '../Icons/GrabIcon';
import PointerIcon from '../Icons/PointerIcon';

export function MouseMoveToolbar({
  setHandToolActive,
  handToolActive,
}: {
  setHandToolActive: (arg: boolean) => void;
  handToolActive: boolean;
}) {
  return (
    <div
      className="absolute z-30 flex items-center -translate-x-2/4  bg-white shadow-md border-gray-300 border"
      style={{ bottom: '16px', borderRadius: '3px', right: '156px' }}
    >
      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => setHandToolActive(true)}
        className={
          ' hover:bg-gray-100 h-8 w-8 flex items-center justify-center z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (handToolActive ? 'bg-gray-100' : '')
        }
      >
        <GrabIcon />
      </div>
      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => setHandToolActive(false)}
        className={
          ' hover:bg-gray-100 h-8 w-8 flex items-center justify-center z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (!handToolActive ? 'bg-gray-100' : '')
        }
      >
        <PointerIcon />
      </div>
    </div>
  );
}
