import React, {
  useRef,
  useState,
  ChangeEvent,
  BaseSyntheticEvent,
  useContext,
  useEffect,
  ReactText,
  createContext,
} from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Shape } from 'konva/lib/Shape';
import { Stage, Layer } from 'react-konva';

import { STICKY_COLORS, GROUPING_COLORS } from '../Consts';
import {
  DEFAULT_STEP,
  DEFAULT_SHOW_TEXT_SCALE,
  DEFAULT_STICKY_COLOR,
  ID,
  Sticky,
  STICKY_FONT,
  STICKY_SPACE,
  STICKY_WIDTH,
  Tag,
  Theme,
} from '../Models';
import { ApolloConsumer, ApolloProvider } from '@apollo/client';
import { Sticky as StickyComponent, Theme as ThemeComponent, StickyModal, Loader } from '.';
import { ThemeInput } from '../GraphQL/__generated__/globalTypes';
import { SelectableContext } from './Selectable';
import useThemes from '../Hooks/useThemes';
import useNotes, { useCopyNote } from '../Hooks/useNotes';
import Util = Konva.Util;
import { Group } from 'konva/lib/Group';
import debounce from 'lodash.debounce';
import { CanvasToolbar } from './CanvasToolbar';
import Well from './Well';
import GroupSelection from './GroupSelection';
import usePermissions from '../Hooks/usePermissions';
import { CurrentUserContext } from '../Hooks/useUsers';
import { useParams } from 'react-router-dom';
import TextComponent from './TextComponent/TextComponent';
import { actionHash, shapeHash, ShapeTypes } from '../Consts/canvasActions';
import { useCreateTextNote, useUpdateTextNote, useDeleteTextNote } from '../Hooks/useTextNotes';
import { useCreateLine, useUpdateLine, useDeleteLine } from '../Hooks/useLines';
import { useFetchCanvasElements } from '../Hooks/useCanvasElements';

import { ZoomLevelControl } from './ZoomLevelControl';
import LineComponent, { LineStyles } from './LineComponent/LineComponent';
import { CurrentDashboardContext } from '../Hooks/useBoards';
import { useCreateShape, useDeleteShape, useUpdateShape } from '../Hooks/useShapes';
import {
  useCreateCanvasImage,
  useDeleteCanvasImage,
  useUpdateCanvasImage,
} from '../Hooks/useCanvasImages';
import ShapeComponent, {
  ShapesStyles,
  shapeToSidesConversion,
} from './ShapeComponent/ShapeComponent';
import colors from '../Consts/colors';
import useFiles from '../Hooks/useFiles';
import ImageComponent, { ImageStyles } from './ImageComponent/ImageComponent';
import { backZIndex, frontZIndex } from '../Consts/canvasZIndex';
import { MouseMoveToolbar } from './MouseMoveToolbar';
import useAnalytics from '../Hooks/useAnalytics';

const DEFAULT_TEXT_WIDTH = 200;
const DEFAULT_TEXT_HEIGHT = 50;

const MIN_SCALE = 0.05;

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

type InfiniteCanvasProps = {
  dashboardId: string;
  stickies: Sticky[];
  groupBy: [string, any?];
  stageRef: React.RefObject<Konva.Stage>;
  openGenerateSummaryModal(themeId: string | null): void;
  focusThemeId?: string;
};

type GenericCanvasElement = {
  styles?: {
    zIndex?: number;
  };
};

export const GroupByContext = createContext<string>('theme');

// TODO: move hook somewhere?
const useDragBound = (ref: React.RefObject<Konva.Stage>) => {
  // we will use hook instead of event listeners on the components
  // to isolate the logic of the feature from the component
  React.useEffect(() => {
    const stage = ref.current!;
    let tableWidth = 0;
    const anim = new Konva.Animation(() => {
      const pos = stage.getPointerPosition();
      if (!pos) {
        return false;
      }

      const edgeDistance = 100;

      // const delta = 6 / stage.scaleX();
      const delta = 10;

      if (pos.x < edgeDistance + tableWidth) {
        stage.x(stage.x() + delta);
      }
      if (pos.x > stage.width() - edgeDistance) {
        stage.x(stage.x() - delta);
      }
      if (pos.y < edgeDistance) {
        stage.y(stage.y() + delta);
      }
      if (pos.y > stage.height() - edgeDistance) {
        stage.y(stage.y() - delta);
      }
      // return false to skip redraw
      return false;
    }, [stage]);
    stage.on('dragstart', (e) => {
      // do nothing if we are dragging the stage
      if (e.target === e.currentTarget) {
        return;
      }
      // TODO: are there any ways to width of the table?
      // TODO: should canvas size be smaller when we have opened table?
      tableWidth =
        (document.querySelector('.InovuaReactDataGrid') as HTMLDivElement)?.offsetWidth || 0;
      anim.start();
    });
    stage.on('dragend', () => {
      anim.stop();
    });
  }, []);
};

const calcThemeSize = (theme: Theme): [width: number, height: number, rowSize: number] => {
  const rowSize = Math.ceil(Math.sqrt(theme.notes.length));
  const themeWidth = (DEFAULT_STEP + STICKY_WIDTH) * rowSize + DEFAULT_STEP * 2;
  const themeHeight =
    (DEFAULT_STEP + STICKY_WIDTH) * Math.ceil(theme.notes.length / rowSize) +
    DEFAULT_STEP * 3 +
    STICKY_FONT * 1.25;
  return [themeWidth, themeHeight, rowSize];
};

function InfiniteCanvas({
  stickies,
  dashboardId,
  groupBy,
  stageRef,
  openGenerateSummaryModal,
  focusThemeId,
}: InfiniteCanvasProps): JSX.Element {
  const { noteId } = useParams<{ noteId: string }>();
  const [selected, setSelected, editable, setEditable] = useContext(SelectableContext);
  const currentUser = useContext(CurrentUserContext);

  const {analytics} = useAnalytics();

  const { canCreateNotes, canEditNotes } = usePermissions();

  const [creating, setCreating] = useState(false);
  const [showText, setShowText] = useState(true);

  const layerRef = useRef<Konva.Layer>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const selectionBoundingBoxRef = useRef<Konva.Rect>(null);
  const selectionBoundingBoxStartPos = useRef({
    x: 0,
    y: 0,
  });

  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  });
  const [stagePos, setStagePos] = useState({
    x: 0,
    y: 0,
  });
  const [stageScale, setStageScale] = useState(1);
  const [openedNoteId, setOpenedNoteId] = useState<string | null>(noteId);

  const { mapFromStickies, getFromStickies, updateTheme, createTheme } = useThemes(
    parseInt(dashboardId)
  );
  const { createNote, updateNote, deleteNote } = useNotes(dashboardId);

  const [copyElement, setCopyElement] = useState('');

  const cancel = axios.CancelToken.source();
  const [imgUploading, setImgUploading] = useState(false);
  const { uploadFileToS3 } = useFiles();
  const toastRef = useRef<ReactText | null>(null);

  const [copySticky] = useCopyNote();

  useDragBound(stageRef);

  const getSticky = (id: ID) => stickies.find((s) => s.id === id) as Sticky;
  const themes = getFromStickies(stickies);

  const openNoteModal = (id: string): void => {
    setOpenedNoteId(id);
  };

  const updateTags = async (stickyId: ID, tags: Tag[] | null): Promise<void> => {
    await updateNote(stickyId, {
      tagsNotes: {
        deleteOthers: true,
        create: tags?.map((tag) => ({ tagId: tag.id })) || [],
      },
    });
  };

  const handleStickyDragEnd = async (event: KonvaEventObject<DragEvent>) => {
    const { x, y, name: id } = event.target.attrs;

    const sticky = getSticky(id);
    // if dragging out of the theme
    if (sticky.theme) {
      const { x: parentX, y: parentY } = event.target.parent?.attrs;
      return await updateNote(id, { themeId: null, x: x + parentX, y: y + parentY });
    }

    return await updateNote(id, { x, y });
  };

  const handleThemeDragEnd = async (event: KonvaEventObject<DragEvent>) => {
    const { x, y, name: index }: { x: number; y: number; name: string } = event.target.attrs;
    const themesMap = mapFromStickies(stickies);
    await updateTheme(themesMap[index].id, { x, y });
  };

  const handleThemeInput = (id: ID) => async (e: ChangeEvent<HTMLInputElement>) => {
    const themesMap = mapFromStickies(stickies);
    await updateTheme(themesMap[id].id, { name: e.target.value });
  };

  const handleIntersection = async (e: KonvaEventObject<DragEvent>) => {
    if (!e.target.attrs?.id) return;
    if (e.target.attrs.id.startsWith('sticky')) {
      await handleStickyIntersection(e);
    } else if (e.target.attrs.id.startsWith('theme')) {
      await handleThemeDragEnd(e);
    }
  };

  const handleStickyIntersection = async (e: KonvaEventObject<DragEvent>) => {
    const target = e.target;
    const targetRect = e.target.getClientRect();

    const children = layerRef.current?.children || [];

    let intersected = false;

    for (const item of children) {
      if (item === target) {
        continue;
      }
      if (haveIntersection(item.getClientRect(), targetRect)) {
        if (item.attrs.id?.startsWith('sticky')) {
          await stickyOnStickyIntersection(item, target);
          intersected = true;
          // if we were in a group, drop out of group and into new theme
          transformerRef.current?.nodes(
            transformerRef.current.nodes().filter((node) => node.attrs.id !== target.attrs.id)
          );
          break; // break if we're intersecting
        } else if (item.attrs.id?.startsWith('theme')) {
          if (await stickyOnThemeIntersection(item, target)) {
            intersected = true;
            // if we were in a group, drop out of group and into new theme
            transformerRef.current?.nodes(
              transformerRef.current.nodes().filter((node) => node.attrs.id !== target.attrs.id)
            );
            break; // break if we're intersecting
          }
        }
      }
    }
    if (!intersected) {
      await handleStickyDragEnd(e);
    }
  };

  async function stickyOnStickyIntersection(
    item: Shape | Konva.Group,
    target: Shape | Konva.Stage
  ) {
    const itemId = item.attrs.name;
    const targetId = target.attrs.name;
    const { x, y } = item.attrs;
    const sticky1 = getSticky(itemId);
    const sticky2 = getSticky(targetId);

    await makeThemeFromStickies(x - STICKY_SPACE, y - DEFAULT_STEP * 2 - STICKY_FONT * 1.25, [
      sticky1,
      sticky2,
    ]);
  }

  async function makeThemeFromStickies(x: number, y: number, stickies: Sticky[]) {
    const themeColor = STICKY_COLORS[Math.floor(Math.random() * STICKY_COLORS.length)];

    const theme: ThemeInput = {
      name: 'Untitled',
      color: themeColor,
      x,
      y,
      dashboardId,
    };

    await createTheme(dashboardId, theme, stickies);
  }

  async function stickyOnThemeIntersection(item: Shape | Konva.Group, target: Shape | Konva.Stage) {
    const themeId = item.attrs.name;
    const stickyId = target.attrs.name;
    const themesMap = mapFromStickies(stickies);
    const theme = themesMap[themeId];

    const sticky = getSticky(stickyId);

    if (sticky.theme?.id == themeId) {
      return false;
    }

    await updateNote(sticky.id, { themeId: theme.id });

    return true;
  }

  function haveIntersection(
    r1: {
      width: number;
      height: number;
      x: number;
      y: number;
    },
    r2: {
      width: number;
      height: number;
      x: number;
      y: number;
    }
  ) {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  }

  // request update function will not update state imidiately
  // but it will do it in a short timeout
  // it use useful for throttling updates
  const timeout = useRef<number | undefined>(undefined);
  const requestUpdateState = (func: () => void) => {
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      func();
    }, 100);
  };

  function zoomStage(event: KonvaEventObject<WheelEvent>) {
    const scaleBy = 1.07;
    const stage = event.target.getStage();
    event.evt.preventDefault();
    event.cancelBubble = true;
    const layer = layerRef.current;

    if (!layer || !stage) {
      return;
    }

    if (event.evt.ctrlKey || event.evt.metaKey) {
      const pointer = stage?.getPointerPosition();
      const oldScale = stage?.scaleX();

      if (!pointer || !stage || !oldScale) {
        return;
      }
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };
      const newScale = Math.max(
        -event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy,
        0.05
      );

      setShowText(newScale > DEFAULT_SHOW_TEXT_SCALE);
      // it is better to set attributes of the stage directly, without changing state of the component
      // because it is faster
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      // but let's update state in a timeout
      // so we can recalculate positions of stickies
      requestUpdateState(() => {
        // use batched updates to have just one render instead of two
        ReactDOM.unstable_batchedUpdates(() => {
          setStageScale(newScale);
          setStagePos(newPos);
        });
      });
    } else {
      const dx = event.evt.deltaX;
      const dy = event.evt.deltaY;

      const x = stage.x() - dx;

      const y = stage.y() - dy;
      stage.position({ x, y });
      // update state in a timeout
      requestUpdateState(() => {
        setStagePos({ x, y });
      });
    }
  }

  const listenForKeyPress = (selected: string | null, editable: string | null) => async (
    e: BaseSyntheticEvent<KeyboardEvent>
  ) => {
    if (editable !== '') {
      return;
    }

    if (stageRef.current !== null) {
      let newScale = stageRef.current.scaleX();
      let deltaX = 0,
        deltaY = 0;
      const arrowStep = 100;

      if (e.nativeEvent.key == 'ArrowDown') {
        e.preventDefault();
        deltaY -= arrowStep;
      }
      if (e.nativeEvent.key == 'ArrowUp') {
        e.preventDefault();
        deltaY += arrowStep;
      }
      if (e.nativeEvent.key == 'ArrowLeft') {
        e.preventDefault();
        deltaX += arrowStep;
      }
      if (e.nativeEvent.key == 'ArrowRight') {
        e.preventDefault();
        deltaX -= arrowStep;
      }
      if ((e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) && e.nativeEvent.key === '=') {
        e.preventDefault();
        newScale *= 1.05;
        setShowText(newScale > DEFAULT_SHOW_TEXT_SCALE);
      } else if ((e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) && e.nativeEvent.key === '-') {
        e.preventDefault();
        newScale /= 1.05;
        setShowText(newScale > DEFAULT_SHOW_TEXT_SCALE);
      }
      if (
        (e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) &&
        (selected?.startsWith('sticky') ||
          selected?.startsWith('text') ||
          selected?.startsWith('shape') ||
          selected?.startsWith('image') ||
          selected?.startsWith('line')) &&
        !editable &&
        e.nativeEvent.key === 'c'
      ) {
        e.preventDefault();
        e.stopPropagation();
        setCopyElement(selected);
        return;
      }
      if ((e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) && e.nativeEvent.key === 'v') {
        const [itemType, itemId] = copyElement.split('-');
        if (!['sticky', 'text', 'line', 'shape', 'image'].includes(itemType)) return;
        e.preventDefault();
        e.stopPropagation();
        if (itemType === 'sticky') {
          const note = await copySticky(itemId);
          setSelected?.(('sticky-' + note.id) as string);
          analytics.sendEvent('CreateNote',{origin: 'Copy'});
        }

        if (itemType === 'text') {
          const textToCopy = canvasElements.textNotes.find((text) => text.id === itemId);
          const text = await createTextNote(dashboardId, {
            styles: textToCopy.styles,
            text: textToCopy.text,
            x: textToCopy.x + 32,
            y: textToCopy.y + 32,
          });
          setSelected?.(('text-' + text.id) as string);
          analytics.sendEvent('CreateText',{origin: 'Copy'});
        }

        if (itemType === 'line') {
          const lineToCopy = canvasElements.lines.find((line) => line.id === itemId);
          const line = await createLine(dashboardId, {
            styles: lineToCopy.styles,
            points: lineToCopy.points,
            x: lineToCopy.x + 32,
            y: lineToCopy.y + 32,
          });
          setSelected?.(('line-' + line.id) as string);
          analytics.sendEvent('CreateLine',{origin: 'Copy'});
        }

        if (itemType === 'shape') {
          const shapeToCopy = canvasElements.shapes.find((shape) => shape.id === itemId);
          const shape = await createShape(dashboardId, {
            styles: shapeToCopy.styles,
            x: shapeToCopy.x + shapeToCopy.styles.radius,
            y: shapeToCopy.y + shapeToCopy.styles.radius / 3,
          });

          setSelected?.(('shape-' + shape.id) as string);
          analytics.sendEvent('CreateShape',{origin: 'Copy'});
        }

        if (itemType === 'image') {
          const imageToCopy = canvasElements.canvasImages.find((image) => image.id === itemId);
          const image = await createImage(dashboardId, {
            styles: imageToCopy.styles,
            x: imageToCopy.x + 100,
            y: imageToCopy.y + 50,
            s3ImagePath: imageToCopy.s3ImagePath,
          });

          setSelected?.(('image-' + image.id) as string);
          analytics.sendEvent('CreateImage',{origin: 'Copy'});
        }

        setCopyElement('');

        return;
      }

      stageRef.current.scale({ x: newScale, y: newScale });
      stageRef.current.position({
        x: stageRef.current.x() + deltaX,
        y: stageRef.current.y() + deltaY,
      });
      stageRef.current.batchDraw();
    }

    if (e.nativeEvent.key == 'Backspace' && selected) {
      e.preventDefault();

      const [type, id] = selected.split('-');
      if (type === 'sticky') {
        await deleteNote(id);
        setSelected?.('');
      }
      if (type === 'text') {
        await deleteTextNote(dashboardId, id);
        setSelected?.('');
      }
      if (type === 'line') {
        await deleteLine(dashboardId, id);
        setSelected?.('');
      }
      if (type === 'shape') {
        await deleteShape(dashboardId, id);
        setSelected?.('');
      }
      if (type === 'image') {
        await deleteImage(dashboardId, id);
        setSelected?.('');
      }
      if (type === 'theme') {
        throw new Error('Theme deletion not implemented');
      }
    }

    if (e.nativeEvent.key == 'Escape' && selected) {
      e.preventDefault();
      setSelected?.('');
      setEditable?.('');
    }
    if (e.nativeEvent.code == 'Space' || e.nativeEvent.key == 'h') {
      setHandToolActive(true);
    } else if (e.nativeEvent.key == 'v') {
      setHandToolActive(false);
    }
  };

  function mousePointTo() {
    const stage = stageRef.current;
    const pointer = stage?.getPointerPosition();
    const oldScale = stage?.scaleX();
    if (pointer && stage && oldScale) {
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      return mousePointTo;
    }
  }

  async function handleUploadImage(imageAction: string) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg';

    input.onchange = async (e: Event) => {
      if (
        (e.target as HTMLInputElement).files == null &&
        !(e.target as HTMLInputElement)?.files?.length
      ) {
        setCreating(false);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const file = (e.target as HTMLInputElement)?.files[0];
      if (file.size > 8 * 1024 * 1024) {
        toastRef.current = toast(<>Max file size is 8mb. Please choose a smaller file.</>, {
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        setCreating(false);
        return;
      }

      setImgUploading(true);
      try {
        const result = await uploadFileToS3(file, {
          cancelToken: cancel.token,
        });

        if (!result.ok) {
          throw new Error('Error uploading file');
        }
        setImgUploading(false);
        if (imageAction === 'create') {
          const defaultImageStyle = {
            rotation: 0,
            scaleX: 1,
            scaleY: 1,
            skewX: 0,
            skewY: 0,
          };

          createImage(dashboardId, {
            ...mousePointTo(),
            s3ImagePath: result.path,
            styles: defaultImageStyle,
          }).then((r: { id: number }) => {
            setImgUploading(false);
            setCreating(false);
            setSelected?.(`image-${r.id}`);
          });
        }

        if (imageAction === 'update' && selected && selected.includes('image-')) {
          const imageID = selected.replace('image-', '');
          await updateImage(imageID, { s3ImagePath: result.path });
        }
      } catch {
        if (axios.isCancel(e)) {
          return;
        }

        toastRef.current = toast(<>Unexpected error while uploading file.</>, {
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        return;
      }
    };

    input.click();
    input.remove();
    setImgUploading(false);
  }

  async function handleStageClick(e: KonvaEventObject<MouseEvent>) {
    e.cancelBubble = true;
    if (creating) {
      switch (canvasAction) {
        case actionHash.sticky:
          analytics.sendEvent('CreateNote', {origin: 'CanvasToolbar'});

          createNote(dashboardId, {
            ...mousePointTo(),
          }).then((r) => {
            setCreating(false);
            setSelected?.(`sticky-${r.id}`);
            setEditable?.(`sticky-${r.id}`);
          });
          break;
        case actionHash.text:
          analytics.sendEvent('CreateText', {origin: 'CanvasToolbar'});
          createTextNote(dashboardId, { ...mousePointTo(), text: '' }).then((r: { id: number }) => {
            setCreating(false);
            setSelected?.(`text-${r.id}`);
          });
          break;
        case actionHash.line:
          const defaultStyle = {
            stroke: colors.text.purple,
            strokeWidth: '6',
            type: 'line',
            dash: false,
          };

          createLine(dashboardId, {
            ...mousePointTo(),
            points: [0, 0, 0, 100],
            styles: defaultStyle,
          }).then((r: { id: number }) => {
            setCreating(false);
            setSelected?.(`line-${r.id}`);
          });
          break;
        case actionHash.shape:
          const defaultShapeStyle = {
            shapeType: specificShape,
            sides: shapeToSidesConversion(specificShape),
            fill: colors.text.purple,
            radius: 100,
            strokeWidth: 0,
            stroke: '#8D4067',
            rotation: 0,
            scaleX: 1,
            scaleY: 1,
            skewX: 0,
            skewY: 0,
          };
          createShape(dashboardId, {
            ...mousePointTo(),
            styles: defaultShapeStyle,
          }).then((r: { id: number }) => {
            setCreating(false);
            setSelected?.(`shape-${r.id}`);
          });
          break;
        case actionHash.image:
          if (imgUploading) {
            setCreating(false);
            break;
          }

          await handleUploadImage('create');

          break;
      }
    }
    setCanvasAction(actionHash.default);

    if (e.target.parent?.attrs.name !== transformerRef.current?.name()) {
      setSelected?.('');
      setEditable?.('');
    }
  }

  // start drawing selection rectangle
  function handleMouseDown(e: KonvaEventObject<MouseEvent>) {
    const stage = stageRef.current;
    const selectionRectangle = selectionBoundingBoxRef.current;
    if (!stage) {
      return;
    }

    if (handToolActive) {
      if (selected) setSelected?.('');
      stage.container().style.cursor = 'grabbing';
      return;
    }

    if (!stage || !selectionRectangle || e.target !== stage || selectionRectangle.visible()) {
      const parentGroupId = e.target.parent?.attrs.id || e.target.parent?.parent?.attrs.id;

      if (selected !== parentGroupId) setSelected?.('');
      return;
    }
    if (!editable) e.evt.preventDefault();

    selectionBoundingBoxStartPos.current = {
      x: stage.getRelativePointerPosition().x,
      y: stage.getRelativePointerPosition().y,
    };

    selectionRectangle.setAttrs({
      x: stage.getRelativePointerPosition().x,
      y: stage.getRelativePointerPosition().y,
      width: 0,
      height: 0,
      visible: true,
    });
    setSelected?.('');
  }

  // continue drawing selection rectangle/adding stickies to the group (if active)
  function handleMouseMove(e: KonvaEventObject<MouseEvent>) {
    if (canvasAction !== actionHash.default) return;
    const stage = stageRef.current;
    const selectionRectangle = selectionBoundingBoxRef.current;
    const transformer = transformerRef.current;
    if (!stage || !selectionRectangle || !transformer || !selectionRectangle.isVisible()) {
      return;
    }
    e.evt.preventDefault();
    selectionRectangle.setAttrs({
      x: Math.min(stage.getRelativePointerPosition().x, selectionBoundingBoxStartPos.current.x),
      y: Math.min(stage.getRelativePointerPosition().y, selectionBoundingBoxStartPos.current.y),
      width: Math.abs(
        stage.getRelativePointerPosition().x - selectionBoundingBoxStartPos.current.x
      ),
      height: Math.abs(
        stage.getRelativePointerPosition().y - selectionBoundingBoxStartPos.current.y
      ),
      visible: true,
    });
    const stickies = stage.find((node: Konva.Node) => node.id().startsWith('sticky-'));
    const selectedBox = selectionRectangle.getClientRect();
    const selectedStickies = stickies.filter(
      (sticky) =>
        !getSticky(sticky.attrs.name)?.theme &&
        Util.haveIntersection(selectedBox, sticky.getClientRect())
    );
    const themes = stage.find((node: Konva.Node) => node.id().startsWith('theme-'));
    const selectedThemes = themes.filter((theme) =>
      Util.haveIntersection(selectedBox, theme.getClientRect())
    );
    const textComponents = stage.find((node: Konva.Node) => node.id().startsWith('text-'));
    const selectedTextComponents = textComponents.filter((textCmp) =>
      Util.haveIntersection(selectedBox, textCmp.getClientRect())
    );
    const lineComponents = stage.find((node: Konva.Node) => node.id().startsWith('line-'));
    const selectedLineComponents = lineComponents.filter((lineCmp) =>
      Util.haveIntersection(selectedBox, lineCmp.getClientRect())
    );
    const shapeComponents = stage.find((node: Konva.Node) => node.id().startsWith('shape-'));
    const selectedShapeComponents = shapeComponents.filter((shapeCmp) =>
      Util.haveIntersection(selectedBox, shapeCmp.getClientRect())
    );
    const imageComponents = stage.find((node: Konva.Node) => node.id().startsWith('image-'));
    const selectedImageComponents = imageComponents.filter((imageCmp) =>
      Util.haveIntersection(selectedBox, imageCmp.getClientRect())
    );

    transformer.nodes([
      ...selectedThemes,
      ...selectedStickies,
      ...selectedTextComponents,
      ...selectedLineComponents,
      ...selectedShapeComponents,
      ...selectedImageComponents,
    ]);
  }

  // finish drawing selection rectangle
  function handleMouseUp(e: KonvaEventObject<MouseEvent>) {
    const stage = stageRef.current;
    const selectionRectangle = selectionBoundingBoxRef.current;

    if (!stage) {
      return;
    }

    if (handToolActive) {
      stage.container().style.cursor = 'grab';
      return;
    }
    const transformer = transformerRef.current;
    if (selectionRectangle) {
      selectionRectangle.visible(false);
    }
    if (
      selectionRectangle &&
      selectionRectangle.height() === 0 &&
      selectionRectangle.width() === 0
    ) {
      transformerRef.current?.nodes([]);
    }
    if (
      !selectionRectangle ||
      !stage ||
      !transformer ||
      (selectionRectangle.width() === 0 && selectionRectangle.height() === 0)
    ) {
      return;
    }
    e.evt.preventDefault();
    if (transformer.nodes().length === 1) {
      setSelected?.(transformer.nodes()[0].id());
      transformer.nodes([]);
    } else if (transformer.nodes().length > 0) {
      setSelected?.(transformer.name());
    }
  }

  function isClientRectOnScreen(stage: Konva.Stage, rect: Konva.Node) {
    const screenRect = {
      x: 0,
      y: 0,
      width: stage.width(),
      height: stage.height(),
    };
    return Util.haveIntersection(screenRect, rect.getClientRect());
  }

  useEffect(() => {
    if (!divRef.current) {
      return;
    }

    const ref = divRef.current;

    const resizeObserver = new ResizeObserver(
      debounce((entries: ResizeObserverEntry[]) => {
        entries.forEach((entry) => {
          const { width, height } = entry.contentRect;

          setCanvasSize({ width, height });
        });
      }, 1000)
    );

    resizeObserver.observe(ref);

    // clean up function
    return () => {
      // remove resize listener
      resizeObserver.unobserve(ref);
    };
  }, [divRef]);

  const [handToolActive, setHandToolActive] = useState(false);

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage) {
      return;
    }

    if (handToolActive) {
      stage.container().style.cursor = 'grab';
    } else if (creating) {
      stage.container().style.cursor = 'crosshair';
    } else {
      stage.container().style.cursor = 'default';
    }

    if (!selected) {
      return;
    }

    if (!layerRef.current) {
      return;
    }

    const selectedNode = stage.findOne<Group>('#' + selected);

    if (!selectedNode) {
      return;
    }

    if (isClientRectOnScreen(stage, selectedNode)) {
      return;
    }

    const { x: xx, y: yy, width, height } = selectedNode.getClientRect({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      relativeTo: stage,
    });

    stage.to({
      duration: 0.5,
      scaleX: 1,
      scaleY: 1,
      x: (stage.width() - width) / 2 - xx,
      y: (stage.height() - height) / 2 - yy,
    });

    stage.batchDraw();
  }, [stageRef, creating, selected, handToolActive]);

  function getThemeColor(theme: Theme) {
    if (groupBy[0] != 'theme') {
      return GROUPING_COLORS.themeColor;
    }
    return theme.color;
  }

  function getColor(sticky: Sticky, theme?: Theme) {
    switch (groupBy[0]) {
      case 'sentiment':
        if (typeof sticky.sentimentScore === undefined) {
          return GROUPING_COLORS.default;
        }
        if (sticky.sentimentScore == null) {
          return GROUPING_COLORS.default;
        }
        if (sticky.sentimentScore == 0) {
          return GROUPING_COLORS.sentimentScore.neutral;
        }
        if (sticky.sentimentScore > 0) {
          return GROUPING_COLORS.sentimentScore.positive;
        }
        if (sticky.sentimentScore < 0) {
          return GROUPING_COLORS.sentimentScore.negative;
        }
        break;
      case 'tag':
        const found: Tag | undefined = sticky.tagsList.find((x: Tag) => {
          return x.id === groupBy[1]?.id;
        });
        if (found) {
          return found?.color || GROUPING_COLORS.tag;
        } else {
          return GROUPING_COLORS.default;
        }
      case 'participant':
        if (sticky.participantId !== groupBy[1].value && groupBy[1].value !== 'all') {
          return GROUPING_COLORS.default;
        } else {
          return sticky.participant?.color || GROUPING_COLORS.participant;
        }
    }
    return theme?.color ?? (sticky.color || DEFAULT_STICKY_COLOR);
  }

  // lets calculate viewport of visible area
  // but also we will make it bigger then "just visible" area
  // so some shapes, that are not on the screen, but very close to it,
  // will be still rendered
  const offset = 1;

  const viewport = {
    x: -stagePos.x / stageScale - (canvasSize.width / stageScale) * offset,
    y: -stagePos.y / stageScale - (canvasSize.height / stageScale) * offset,
    width: (canvasSize.width / stageScale) * (1 + 2 * offset),
    height: (canvasSize.height / stageScale) * (1 + 2 * offset),
  };

  const openedSticky = openedNoteId ? getSticky(openedNoteId) : null;

  const [loadingCanvasElements, canvasElements] = useFetchCanvasElements(dashboardId);
  const [createTextNote] = useCreateTextNote();
  const [updateTextNote] = useUpdateTextNote();
  const [deleteTextNote] = useDeleteTextNote();
  const [createLine] = useCreateLine();
  const [updateLine] = useUpdateLine();
  const [deleteLine] = useDeleteLine();
  const [createShape] = useCreateShape();
  const [updateShape] = useUpdateShape();
  const [deleteShape] = useDeleteShape();
  const [createImage] = useCreateCanvasImage();
  const [updateImage] = useUpdateCanvasImage();
  const [deleteImage] = useDeleteCanvasImage();
  const [canvasAction, setCanvasAction] = useState(actionHash.default);
  const [specificShape, setSpecificShape] = useState(shapeHash.square);
  const handleCanvasToolbar = (input: string, optionalInput?: ShapeTypes) => {
    if (optionalInput) {
      setSpecificShape(shapeHash[optionalInput]);
    }
    setCanvasAction(input);
    setCreating(true);
  };

  const fitToScreen = () => {
    if (!stageRef.current) return;
    const stage = stageRef.current;

    const stickyPositions = stickies
      .filter((sticky) => !sticky.theme)
      .map((sticky) => {
        return {
          x1: sticky.x,
          y1: sticky.y,
          x2: sticky.x + STICKY_WIDTH,
          y2: sticky.y + STICKY_WIDTH,
        };
      });

    const themePositions = themes.map((theme) => {
      const [themeWidth, themeHeight] = calcThemeSize(theme);
      return {
        x1: theme.x,
        y1: theme.y,
        x2: theme.x + themeWidth,
        y2: theme.y + themeHeight,
      };
    });

    const textPositions = canvasElements.textNotes.map((text) => {
      return {
        x1: text.x,
        y1: text.y,
        x2: text.x + DEFAULT_TEXT_WIDTH,
        y2: text.y + DEFAULT_TEXT_HEIGHT,
      };
    });

    const allPositions = [...stickyPositions, ...themePositions, ...textPositions];

    if (allPositions.length === 0) return;

    const minX = Math.min(...allPositions.map((pos) => pos.x1));
    const maxX = Math.max(...allPositions.map((pos) => pos.x2));
    const minY = Math.min(...allPositions.map((pos) => pos.y1));
    const maxY = Math.max(...allPositions.map((pos) => pos.y2));

    const tableWidth =
      (document.querySelector('.InovuaReactDataGrid') as HTMLDivElement)?.offsetWidth || 0;

    const canvasWidth = canvasSize.width - tableWidth;

    const scaleX = canvasWidth / (maxX + 200 / (canvasWidth / (maxX - minX)) - minX);
    const scaleY = canvasSize.height / (maxY + 200 / (canvasSize.height / (maxY - minY)) - minY);

    const calcScale = Math.min(scaleX, scaleY);
    const newScale = Math.max(Math.min(calcScale, 1), MIN_SCALE);

    const contentWidth = (maxX - minX) * newScale;
    const paddingX = (canvasWidth - contentWidth) / 2;

    const newPos = {
      x: -minX * newScale + tableWidth + paddingX,
      y: -minY * newScale + 50,
    };

    stage.scale({ x: newScale, y: newScale });

    stage.position(newPos);

    // but let's update state in a timeout
    // so we can recalculate positions of stickies
    requestUpdateState(() => {
      // use batched updates to have just one render instead of two
      ReactDOM.unstable_batchedUpdates(() => {
        setStageScale(newScale);
        setStagePos(newPos);
        setShowText(newScale > DEFAULT_SHOW_TEXT_SCALE);
      });
    });
  };

  const renderCanvasElements = (elementsFilter: (el: GenericCanvasElement) => boolean) => {
    return (
      <>
        {canvasElements.lines.filter(elementsFilter).map(
          (
            line: {
              id: string;
              dashboardId: string;
              x: number;
              y: number;
              points: number[];
              styles: LineStyles;
            },
            index: number
          ) => {
            const lineIDString = `line-${line.id}`;
            return (
              <LineComponent
                id={lineIDString}
                key={lineIDString}
                x={line.x}
                y={line.y}
                points={line.points}
                styles={line.styles}
                stageScale={stageScale}
                onUpdate={async (updates) => {
                  await updateLine(line.id, updates);
                }}
                onDelete={() => deleteLine(dashboardId, line.id)}
                setSelected={() => {
                  if (handToolActive) return;
                  setSelected?.(lineIDString);
                  transformerRef.current?.nodes([]);
                }}
                selected={selected === lineIDString}
                draggable={!handToolActive}
              />
            );
          }
        )}

        {canvasElements.shapes.filter(elementsFilter).map(
          (
            shape: {
              id: string;
              dashboardId: string;
              x: number;
              y: number;
              styles: ShapesStyles;
            },
            index: number
          ) => {
            const shapeIDString = `shape-${shape.id}`;
            return (
              <ShapeComponent
                id={shapeIDString}
                key={shapeIDString}
                x={shape.x}
                y={shape.y}
                styles={shape.styles}
                stageScale={stageScale}
                onUpdate={async (updates) => {
                  await updateShape(shape.id, updates);
                }}
                onDelete={() => deleteShape(dashboardId, shape.id)}
                setSelected={() => {
                  if (handToolActive) return;
                  setSelected?.(shapeIDString);
                  transformerRef.current?.nodes([]);
                }}
                selected={selected === shapeIDString}
                draggable={!handToolActive}
              />
            );
          }
        )}

        {canvasElements.textNotes.filter(elementsFilter).map((text) => {
          const textIDString = `text-${text.id}`;
          return (
            <TextComponent
              key={text.id}
              id={textIDString}
              x={text.x}
              y={text.y}
              text={text.text}
              styles={text.styles}
              onUpdate={async (updates) => {
                await updateTextNote(text.id, updates);
              }}
              onDelete={() => deleteTextNote(dashboardId, text.id)}
              setSelected={() => {
                if (handToolActive) return;
                setSelected?.(textIDString);
                transformerRef.current?.nodes([]);
              }}
              selected={selected === textIDString}
              draggable={!handToolActive}
            />
          );
        })}

        {canvasElements.canvasImages
          .filter(elementsFilter)
          .map(
            (image: {
              id: string;
              dashboardId: string;
              x: number;
              y: number;
              signedImageUrl: string;
              styles: ImageStyles;
            }) => {
              const imageIDString = `image-${image.id}`;

              return (
                <ImageComponent
                  id={imageIDString}
                  key={imageIDString}
                  x={image.x}
                  y={image.y}
                  stageScale={stageScale}
                  src={image.signedImageUrl}
                  onUpdate={async (updates) => {
                    await updateImage(image.id, updates);
                  }}
                  onDelete={() => deleteImage(dashboardId, image.id)}
                  onUpdateImage={async () => {
                    await handleUploadImage('update');
                  }}
                  setSelected={() => {
                    if (handToolActive) return;
                    setSelected?.(imageIDString);
                    transformerRef.current?.nodes([]);
                  }}
                  selected={selected === imageIDString}
                  styles={image.styles}
                  draggable={!handToolActive}
                />
              );
            }
          )}
      </>
    );
  };

  useEffect(() => {
    const currentTheme = themes.find((theme) => theme.id === focusThemeId);
    if (!currentTheme) return;

    const newStageScale = 1;
    stageRef.current?.scale({
      x: newStageScale,
      y: newStageScale,
    });
    setStageScale(newStageScale);
    setShowText(newStageScale > DEFAULT_SHOW_TEXT_SCALE);

    setStagePos({ x: 390 - currentTheme.x, y: 60 - currentTheme.y });
    setSelected && setSelected('theme-' + currentTheme.id);
  }, [focusThemeId]);

  return (
    <>
      {openedSticky && (
        <StickyModal
          sticky={openedSticky}
          themes={themes}
          updateNote={updateNote}
          updateTags={updateTags}
          onClose={() => {
            setOpenedNoteId(null);
          }}
        />
      )}
      <div className={'relative flex-auto h-full'}>
        <div className={'z-100 fixed left-1/4 top-10 w-1/2'}>
          <Well wellKey={'notes-well'}>
            Notes are tiny snippets of data to analyze with tags and themes. You can also turn on
            automatic sentiment analysis. Work with notes on the canvas or the table, they’re always
            in sync.
          </Well>
        </div>
        {canCreateNotes && (
          <CanvasToolbar
            handleOnClick={(input) => {
              setHandToolActive(false);
              handleCanvasToolbar(input);
            }}
            canvasAction={canvasAction}
          />
        )}
        <MouseMoveToolbar handToolActive={handToolActive} setHandToolActive={setHandToolActive} />
        <ZoomLevelControl
          zoomLevel={Math.round(stageScale * 100)}
          setZoomLevel={(level: number) => {
            const newStageScale = level / 100;
            stageRef.current?.scale({
              x: newStageScale,
              y: newStageScale,
            });
            setStageScale(newStageScale);
            setShowText(newStageScale > DEFAULT_SHOW_TEXT_SCALE);
          }}
          onFitToScreen={fitToScreen}
        />
      </div>
      <div
        ref={divRef}
        className={'outline-none background-grid left-0 fixed w-full h-full'}
        onKeyDown={listenForKeyPress(selected, editable)}
        onKeyUp={(e) => {
          if (e.nativeEvent.code == 'Space') {
            setHandToolActive(false);
          }
        }}
        tabIndex={-1}
      >
        {imgUploading && (
          <div className="fixed w-full h-full top-0 z-100">
            <Loader />
          </div>
        )}
        <ApolloConsumer>
          {(client) => (
            <Stage
              onWheel={zoomStage}
              ref={stageRef}
              x={stagePos.x}
              y={stagePos.y}
              width={canvasSize.width}
              height={canvasSize.height}
              perfectDrawEnabled={false} // Don't need perfect draw
              onClick={handleStageClick}
              onDragEnd={(e) => {
                setStagePos(e.currentTarget.position());
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              draggable={handToolActive}
            >
              <ApolloProvider client={client}>
                <CurrentUserContext.Provider value={currentUser}>
                  <CurrentDashboardContext.Provider value={dashboardId}>
                    <GroupByContext.Provider value={groupBy[0]}>
                      <SelectableContext.Provider
                        value={[selected, setSelected, editable, setEditable]}
                      >
                        <Layer
                          ref={layerRef}
                          transformsEnabled={'position'} // Reduce transform handlers
                          onDragEnd={handleIntersection}
                          perfectDrawEnabled={false} // Don't need perfect draw
                        >
                          {renderCanvasElements((el) => {
                            return el.styles?.zIndex === backZIndex;
                          })}

                          {renderCanvasElements((el) => {
                            return (
                              el.styles?.zIndex !== frontZIndex && el.styles?.zIndex !== backZIndex
                            );
                          })}

                          {renderCanvasElements((el) => {
                            return el.styles?.zIndex === frontZIndex;
                          })}

                          {themes.map((theme: Theme) => {
                            const [themeWidth, themeHeight, rowSize] = calcThemeSize(theme);
                            if (
                              theme.x + themeWidth < viewport.x ||
                              theme.x > viewport.x + viewport.width
                            ) {
                              return null;
                            }

                            if (
                              theme.y + themeHeight < viewport.y ||
                              theme.y > viewport.y + viewport.height
                            ) {
                              return null;
                            }

                            return (
                              <ThemeComponent
                                dashboardId={dashboardId}
                                notes={theme.notes}
                                key={`theme-${theme.id}`}
                                id={`${theme.id}`}
                                x={theme.x}
                                y={theme.y}
                                width={themeWidth}
                                height={themeHeight}
                                spacing={STICKY_SPACE}
                                color={getThemeColor(theme)}
                                defaultStep={DEFAULT_STEP}
                                fontSize={STICKY_FONT}
                                showText={showText}
                                editorProps={{
                                  readOnly: !canEditNotes,
                                  onBlur: handleThemeInput(theme.id),
                                }}
                                selectableProps={{
                                  selected: selected == `theme-${theme.id}`,
                                  editable: editable == `theme-${theme.id}`,
                                  onChange: ({ editable, selected }) => {
                                    setSelected?.(selected ? `theme-${theme.id}` : '');
                                    setEditable?.(editable ? `theme-${theme.id}` : '');
                                    transformerRef.current?.nodes([]);
                                  },
                                }}
                                text={theme.name}
                                handToolActive={handToolActive}
                                openGenerateSummaryModal={openGenerateSummaryModal}
                              >
                                {theme.notes.map((sticky, index) => {
                                  const x =
                                    STICKY_SPACE +
                                    (DEFAULT_STEP + STICKY_WIDTH) * (index % rowSize);
                                  const y =
                                    DEFAULT_STEP * 2 +
                                    STICKY_FONT * 1.25 +
                                    Math.floor(index / rowSize) * (STICKY_WIDTH + DEFAULT_STEP);

                                  const absX = x + theme.x;
                                  const absY = y + theme.y;
                                  // we sticky is not visible, just render nothing
                                  if (absX < viewport.x || absX > viewport.x + viewport.width) {
                                    return null;
                                  }
                                  if (absY < viewport.y || absY > viewport.y + viewport.height) {
                                    return null;
                                  }
                                  return (
                                    <StickyComponent
                                      // menu={false}
                                      dashboardId={dashboardId}
                                      editorProps={{
                                        readOnly: !canEditNotes,
                                      }}
                                      tags={sticky.tagsList}
                                      key={'theme-sticky-' + sticky.id}
                                      id={`${sticky.id}`}
                                      x={x}
                                      y={y}
                                      text={sticky.text}
                                      width={STICKY_WIDTH}
                                      height={STICKY_WIDTH}
                                      defaultStep={DEFAULT_STEP}
                                      color={getColor(sticky, theme)}
                                      selectableProps={{
                                        selected: selected == `sticky-${sticky.id}`,
                                        editable: editable == `sticky-${sticky.id}`,
                                        onChange: ({ editable, selected }) => {
                                          setSelected?.(selected ? `sticky-${sticky.id}` : '');
                                          setEditable?.(editable ? `sticky-${sticky.id}` : '');
                                          transformerRef.current?.nodes([]);
                                        },
                                      }}
                                      showText={showText}
                                      fontSize={STICKY_FONT}
                                      participant={sticky.participant}
                                      createNote={createNote}
                                      updateNote={updateNote}
                                      deleteNote={deleteNote}
                                      openNote={openNoteModal}
                                      handToolActive={handToolActive}
                                    />
                                  );
                                })}
                              </ThemeComponent>
                            );
                          })}

                          {stickies.map((sticky) => {
                            if (sticky.theme) {
                              return null;
                            }
                            // we sticky is not visible, just render nothing
                            if (sticky.x < viewport.x || sticky.x > viewport.x + viewport.width) {
                              return null;
                            }
                            if (sticky.y < viewport.y || sticky.y > viewport.y + viewport.height) {
                              return null;
                            }
                            return (
                              <StickyComponent
                                dashboardId={dashboardId}
                                editorProps={{
                                  readOnly: !canEditNotes,
                                }}
                                tags={sticky.tagsList}
                                key={`sticky-${sticky.id}`}
                                id={`${sticky.id}`}
                                x={sticky.x}
                                y={sticky.y}
                                width={STICKY_WIDTH}
                                height={STICKY_WIDTH}
                                color={getColor(sticky)}
                                selectableProps={{
                                  selected: selected == `sticky-${sticky.id}`,
                                  editable: editable == `sticky-${sticky.id}`,
                                  onChange: ({ editable, selected }) => {
                                    setSelected?.(selected ? `sticky-${sticky.id}` : '');
                                    setEditable?.(editable ? `sticky-${sticky.id}` : '');
                                    transformerRef.current?.nodes([]);
                                  },
                                }}
                                showText={showText}
                                text={sticky.text}
                                participant={sticky.participant}
                                createNote={createNote}
                                updateNote={updateNote}
                                deleteNote={deleteNote}
                                openNote={openNoteModal}
                                handToolActive={handToolActive}
                              />
                            );
                          })}
                          {canEditNotes && (
                            <GroupSelection
                              transformerRef={transformerRef}
                              selectionBoundingBoxRef={selectionBoundingBoxRef}
                              selected={selected === transformerRef.current?.name()}
                              dashboardId={dashboardId}
                              themes={themes}
                              makeThemeFromStickies={makeThemeFromStickies}
                              getSticky={getSticky}
                              unselect={() => setSelected?.('')}
                            />
                          )}
                        </Layer>
                      </SelectableContext.Provider>
                    </GroupByContext.Provider>
                  </CurrentDashboardContext.Provider>
                </CurrentUserContext.Provider>
              </ApolloProvider>
            </Stage>
          )}
        </ApolloConsumer>
      </div>
    </>
  );
}

export default InfiniteCanvas;
