import styled from 'styled-components';

export const Reaction = styled.div<{ isActive?: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #d7d3dc;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
    background-color: rgba(56, 33, 82, 0.08);
  `}
`;

export const EmojisPanel = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0;
  bottom: 40px;
  padding: 12px;
  z-index: 2;
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  display: ${({ show }) => (show ? 'block' : 'none')};
  border-radius: 8px;
`;

export const EmojiList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 14px;
  user-select: none;
`;

export const EmojiItem = styled.div`
  font-size: 24px;
  line-height: 24px;
`;

export const InlineEmoji = styled(EmojiItem)`
  font-size: 20px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Emojis = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding-right: 4px;
`;

export const Tooltip = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  padding: 10px 8px;
  border-radius: 20px;
  background-color: #3b2651;
  left: 0;
  bottom: 40px;
  white-space: nowrap;
`;

export const TooltipName = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #ffffff;
  flex-shrink: 0;
  margin-right: 4px;
`;

export const TooltipMessage = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
  flex-shrink: 0;
`;

export const AvatarAndEmojis = styled.div<{ enableHover: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
  transition: background-color 0.07s ease-in-out;
  margin-right: 5px;

  ${({ enableHover }) =>
    enableHover &&
    `
    &:hover {
      background-color: #d7d3dc;
    }

    &:hover ${Tooltip} {
      display: flex;
    }
  `}
`;
