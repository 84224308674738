import React, { useEffect, useState } from 'react';
import { CloudFile } from '../Models';
import { gql, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';

interface GooglePickerProps {
  onFileSelected: (files: CloudFile[]) => void;
}

declare const google: any;
declare const gapi: any;

const GooglePicker: React.FC<GooglePickerProps> = ({ onFileSelected, children }) => {
  const [pickerInited, setPickerInited] = useState<boolean>(false);
  const client = useApolloClient();
  const history = useHistory();

  function onApiLoad() {
    gapi.load('picker', () => {
      setPickerInited(true);
    });
  }

  async function pickerCallback(data: any) {
    if (!pickerInited) return;

    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      const docs = data[google.picker.Response.DOCUMENTS];
      const selectedFiles = docs.map((doc: any) => {
        return {
          name: doc[google.picker.Document.NAME],
          size: doc.sizeBytes,
          source: 'google',
          url: doc[google.picker.Document.URL],
          mimeType: doc[google.picker.Document.MIME_TYPE],
          sourceId: doc[google.picker.Document.ID],
          duration: doc.duration,
        };
      });
      onFileSelected(selectedFiles);
    }
  }

  const handleOpenPicker = async () => {
    const showPicker = (accessToken: string) => {
      const view = new google.picker.DocsView()
        .setIncludeFolders(true)
        .setMimeTypes('video/mp4,audio/mpeg,video/quicktime,audio/mp3,audio/m4a');
      // .setSelectFolderEnabled(true);
      const picker = new google.picker.PickerBuilder()
        // .addView(google.picker.ViewId.DOCS_VIDEOS)
        .addView(view)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setOAuthToken(accessToken)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    };

    const {
      data: { getGoogleToken },
    } = await client.query({
      query: gql`
        query getGoogleToken {
          getGoogleToken {
            token
          }
        }
      `,
    });

    const token = getGoogleToken.token;

    if (!token) {
      history.replace('/settings/integrations');
      return;
    }

    showPicker(token);
  };

  useEffect(() => {
    onApiLoad();
  }, []);

  return (
    <div
      onClick={() => {
        handleOpenPicker();
      }}
    >
      {children}
    </div>
  );
};

export default GooglePicker;
