import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #382152;
  opacity: 0.2;
  z-index: -1;
`

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 6px;
  background-color: #ffffff;
`

export const Content = styled.div`
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3B2651;
  margin-bottom: 8px;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);
`

export const CloseButton = styled.div`
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #331652;
  }
`

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`

export const Tag = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #D7D3DC;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const TagName = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 5px;
  border-radius: 3px;
  background-color: ${({ color }) => color ? color : '#d8c0f880'};
  margin-right: 4px;
  max-width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TagCount = styled.div`
  font-size: 14px;
  line-height: 20px;
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  margin-left: 12px;

  &:first-child {
    margin: 0;
  }
`

export const ButtonText = styled.div`
  margin-left: 4px;
`

export const DotsDivider = styled.div`
  font-size: 20px;
  line-height: 8px;
  text-align: center;
  margin-bottom: 18px;
  padding-left: 8px;
`

export const IconWrapper = styled.div`
  margin-right: 8px;
`