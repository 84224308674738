import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import ConfirmationModal from '../ConfirmationModal';

interface NavigationPromptProps {
  title: string;
  text: string;
  confirmationText: string;
  cancelText: string;
  when: boolean;
  basedOnNavigation?: boolean;
  locationAfterConfirm?: string;
  name: string;
  onConfirm?(): void;
  onCancel?(): void;
}

const NavigationPrompt: FC<NavigationPromptProps> = ({
  title,
  text,
  confirmationText,
  cancelText,
  when,
  basedOnNavigation = true,
  locationAfterConfirm,
  name = '',
  onConfirm,
  onCancel,
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [nextPath, setNextPath] = useState<string>('');
  const [checkbobxChecked, setCheckboxChecked] = useState(false);
  const [shouldBeShown, setShouldBeShown] = useLocalStorage(name, true);
  const history = useHistory();

  const handleConfirm = async () => {
    if (checkbobxChecked) {
      setShouldBeShown(false);
    }

    onConfirm && (await onConfirm());
    setShowPrompt(false);

    if (basedOnNavigation) {
      history.block(() => void 0);

      if (locationAfterConfirm) {
        history.push(locationAfterConfirm);
      } else {
        history.push(nextPath);
      }
    } else if (locationAfterConfirm) {
      history.push(locationAfterConfirm);
    }
  };

  const handleCancel = () => {
    if (checkbobxChecked) {
      setShouldBeShown(false);
    }

    onCancel && onCancel();
    setShowPrompt(false);

    if (basedOnNavigation) {
      history.block(() => void 0);
      history.push(nextPath);
    }
  };

  useEffect(() => {
    if (!shouldBeShown) {
      if (!basedOnNavigation && when) {
        onConfirm && onConfirm();
        locationAfterConfirm && history.push(locationAfterConfirm);
        return;
      }
      return;
    }

    if (basedOnNavigation) {
      if (when) {
        history.block((location) => {
          setNextPath(location.pathname);
          setShowPrompt(true);
          return false;
        });
      } else {
        history.block(() => void 0);
      }
    } else {
      if (when) {
        setShowPrompt(true);
      }
    }

    return () => {
      history.block(() => void 0);
    };
  }, [history, when, basedOnNavigation]);

  return showPrompt ? (
    <ConfirmationModal
      title={title}
      text={text}
      confirmationText={confirmationText}
      cancelText={cancelText}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      checkboxChecked={checkbobxChecked}
      onCheckboxChange={setCheckboxChecked}
    />
  ) : null;
};

export default NavigationPrompt;
