import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const Container = styled.div`
  max-width: 548px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  padding: 16px 20px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const HeaderIcon = styled.div`
  margin-right: 5px;
`;

export const HeaderText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin-right: 20px;
`;

export const FeedbackButtons = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;

export const FeedbackButton = styled.div<{ isBad?: boolean }>`
  padding: 2px 8px;
  border-right: 1px solid #d7d3dc;
  cursor: pointer;

  &:last-child {
    border: none;
  }

  ${({ isBad }) =>
    isBad &&
    `
    transform: rotate(180deg);
  `}
`;

export const RadioButtonsWrapper = styled.div`
  margin-bottom: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const ShareFeedbackButton = styled.div`
  margin-right: 8px;
`;

export const Textarea = styled(TextareaAutosize)`
  width: 100%;
  max-height: 600px;
  padding: 10px;
  outline: none;
  border: 1px solid #d7d3dc;
  border-radius: 6px;
  resize: none;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.div`
  align-self: flex-end;
  cursor: pointer;
`;
