import React, { FC } from 'react';
import Button from '../../Components/Button';

import {
  ButtonText,
  ButtonWrapper,
  Container,
  Overlay,
  ModalContainer,
  Content,
  Header,
  Title,
  CloseButton,
  Buttons,
  IconWrapper,
} from './styles';
import { Icon24 } from '../../Icons/Icon';

interface ModalWindowProps {
  children: JSX.Element;
  confirmText: string;
  cancelText: string;
  headerText?: string;
  isConfirmDisabled?: boolean;
  onConfirm(): void;
  onCancel(): void;
  onClose?(): void;
}

const ModalWindow: FC<ModalWindowProps> = ({
  headerText,
  children,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  onClose,
  isConfirmDisabled = false,
}) => {
  return (
    <Container>
      <Overlay onClick={onClose} />
      <ModalContainer>
        <Header>
          {headerText && !!headerText.length && (
            <Title>
              <IconWrapper>
                <Icon24.Badge />
              </IconWrapper>
              {headerText}
            </Title>
          )}
          <CloseButton onClick={onClose || onCancel}>
            <Icon24.Close />
          </CloseButton>
        </Header>
        <Content>
          {children}
          <Buttons>
            <ButtonWrapper>
              <Button onClick={onConfirm} disabled={isConfirmDisabled}>
                <ButtonText>{confirmText}</ButtonText>
              </Button>
            </ButtonWrapper>

            <ButtonWrapper>
              <Button type="secondary" onClick={onCancel}>
                <ButtonText>{cancelText}</ButtonText>
              </Button>
            </ButtonWrapper>
          </Buttons>
        </Content>
      </ModalContainer>
    </Container>
  );
};

export default ModalWindow;
