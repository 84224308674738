import { OperationVariables, gql } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client';

export interface SlackWebhook {
  id: string;
  channel: string;
  configurationUrl: string;
  teamName?: string;
  postNewSummaries?: boolean;
  postNewInsights?: boolean;
}

export interface FetchSlackWebhooksResponse {
  slackWebhooks: SlackWebhook[];
}

export function useFetchSlackWebhooks(): QueryResult<
  FetchSlackWebhooksResponse,
  OperationVariables
> {
  return useQuery<FetchSlackWebhooksResponse>(
    gql`
      query fetchSlackWebhooks {
        slackWebhooks(orderBy: CREATED_AT_DESC) {
          id
          channel
          configurationUrl
          teamName
          postNewSummaries
          postNewInsights
          __typename
        }
      }
    `
  );
}
