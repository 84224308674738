import React, { useEffect } from 'react';

export default function useDisableBackgroundScroll(isEnabled = true): void {
  useEffect(() => {
    document.body.style.overflow = isEnabled ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isEnabled]);
}
