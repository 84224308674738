import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ID } from '../../Models';

interface DropAreaProps<T> {
  onDrop: (item: T) => void;
  type: string;
}

interface DragItemProps<T> {
  item: T;
  type: string;
}

export const DropArea = <T extends { id?: ID }>({
  type,
  children,
  onDrop,
}: React.PropsWithChildren<DropAreaProps<T>>): JSX.Element => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: type,
    drop: (item: T) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <>
      {React.cloneElement(children as React.ReactElement<any>, {
        ref: drop,
        isOver: isOver,
      })}
    </>
  );
};

export const DragItem = <T extends { id?: ID }>({
  item,
  type,
  children,
}: React.PropsWithChildren<DragItemProps<T>>): JSX.Element => {
  const [{ isDragging, opacity }, drag] = useDrag(() => ({
    item: item,
    type: type,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.3 : 1,
    }),
  }));

  return (
    <>
      {React.cloneElement(children as React.ReactElement<any>, {
        ref: drag,
      })}
    </>
  );
};
