import React from 'react';

function NotablyIcon(): JSX.Element {
  return (
    <svg width="48" height="41" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.3327 4.05035e-06H4.66727C3.43068 0.00402946 2.24591 0.491773 1.37151 1.35679C0.497104 2.22181 0.00406908 3.39387 0 4.61718V36.2717C0.00406908 37.495 0.497104 38.6671 1.37151 39.5321C2.24591 40.3971 3.43068 40.8849 4.66727 40.8889H32.001C36.2437 40.8873 40.3122 39.2192 43.3122 36.2514C46.3122 33.2836 47.9984 29.2588 48 25.0616V4.61718C48.0008 4.01062 47.8806 3.40986 47.6464 2.84931C47.4121 2.28877 47.0684 1.77945 46.6348 1.35055C46.2012 0.921646 45.6864 0.581579 45.1198 0.349829C44.5531 0.118078 43.9459 -0.000799877 43.3327 4.05035e-06ZM43.3327 3.29799C43.5089 3.29678 43.6836 3.32997 43.8468 3.39565C44.01 3.46133 44.1585 3.55821 44.2838 3.68075C44.4091 3.80329 44.5088 3.94908 44.577 4.10976C44.6453 4.27045 44.6809 4.44288 44.6817 4.61718V9.43285H15.999V3.29799H43.3327ZM44.6817 25.1593H36.7671C35.6851 25.1651 34.623 25.4476 33.684 25.9795C32.745 26.5114 31.9611 27.2745 31.4084 28.1947H15.999V22.0598H44.6817V25.1593ZM12.6683 22.0598V28.1947H3.33376V22.0598H12.6683ZM3.33376 18.7619V12.6881H12.6683V18.7619H3.33376ZM15.9897 18.7619V12.6881H44.6817V18.7619H15.9897ZM4.66727 3.29799H12.6683V9.43285H3.33376V4.61718C3.33539 4.2678 3.4764 3.93319 3.72613 3.68614C3.97586 3.4391 4.3141 3.29959 4.66727 3.29799ZM4.66727 37.5909C4.31385 37.5901 3.97514 37.4509 3.72524 37.2036C3.47533 36.9564 3.33458 36.6213 3.33376 36.2717V31.4896H12.6683V37.597L4.66727 37.5909ZM15.999 31.4835H30.4329V37.5909H15.999V31.4835ZM33.7667 37.4535V31.5904C33.7667 29.8742 35.1002 28.4573 36.7671 28.4573H44.2001C43.5227 30.7928 42.1776 32.8849 40.3267 34.482C38.4757 36.0791 36.1977 37.1131 33.7667 37.4596V37.4535Z"
        fill="#3B2851"
      />
    </svg>
  );
}

export default NotablyIcon;
