import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: #ffffff;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  border-radius: 3px;
  border: 1px solid #D7D3DC;
`

export const MenuButton = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-right: 1px solid #D7D3DC;
  background-color: ${({ isActive }) => isActive ? '#F3F4F6' : '#ffffff' };
  cursor: pointer;

  &:first-child {
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-radius: 0 3px 3px 0;
    border: none;
  }

  &:hover {
    background-color: #F3F4F6;
  }
`

export const ButtonText = styled.div`
  margin-left: 4px;
`

export const TagsCount = styled.div`
  font-size: 14px;
  color: #B1A8B9;
  margin-left: 4px;
  margin-top: 2px;
`