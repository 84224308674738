import styled from 'styled-components';

export const ModalWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isOpen }) =>
    isOpen &&
    `
      width: 100%;
      height: 100%;
      z-index: 999;
  `}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
      width: 100%;
      height: 100%;
      opacity: 0.4;
      z-index: 999;
  `}
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 100%;
  max-width: 360px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  transition: opacity 0.12s ease-in;
  pointer-events: none;
  z-index: -1;
  opacity: 0;

  ${({ isOpen }) =>
    isOpen &&
    `
      z-index: 9999;
      pointer-events: all;
      opacity: 1;
  `}
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const BottomControls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const AddButton = styled.div<{ disabled?: boolean }>`
  padding: 8px 12px;
  background-color: #382152;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    background-color: rgba(59, 38, 81, 0.4);
  `}
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Speakers = styled.div``;

export const ChevronIconWrapper = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 4px;
  color: rgba(59, 38, 81, 0.4);
`;

export const PencilIconWrapper = styled.div`
  display: none;
  color: rgba(59, 38, 81, 0.4);
`;

export const Speaker = styled.div<{ isActive?: boolean; hidePencil?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 2px;
  border-radius: 6px;

  ${({ isActive, hidePencil }) =>
    isActive &&
    `
    background-color: rgba(133, 95, 168, 0.1);

    & ${ChevronIconWrapper} {
      display: block;
    }
  `}

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);

    ${({ hidePencil }) =>
      !hidePencil &&
      `
      & ${PencilIconWrapper} {
        display: block;
      }
    `}
  }
`;

export const LetterAvatarWrapper = styled.div`
  margin-right: 4px;
`;

export const SpeakerName = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-right: 4px;
`;

export const SpeakerNameField = styled.input`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-right: 4px;
  background-color: transparent;
  outline: none;
`;

export const NewNameField = styled.input`
  width: 100%;
  padding: 8px 12px;
  outline: none;
  border: 1px solid #e2e8f0;
  border-radius: 3px;
  margin-right: 8px;
`;
