import React, { useEffect, useRef, useState } from 'react';
import { Icon16, Icon24 } from '../../Icons/Icon';

import {
  FileItem,
  FileItemContent,
  FileItemTitle,
  FileItemTitleIcon,
  FileItemTitleText,
  FileItemSize,
  ProgressBarContainer,
  ProgressBarLineUnderlayer,
  ProgressBarLine,
  ContainerMinimized,
  FileItemCloseButton,
  DropdownMenu,
  DropDownItem,
  DropDownItemText,
} from './styles';

interface UploadFileModalMinimized {
  fileName: string;
  fileSize: number;
  progress: number;
  onCancel(): void;
}

const UploadFileModalMinimized: React.FC<UploadFileModalMinimized> = ({
  fileName,
  fileSize,
  progress,
  onCancel,
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const formatFileSize = (fileSize: number) => {
    const i = Math.floor(Math.log(fileSize) / Math.log(1024));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (fileSize / Math.pow(1024, i)).toFixed(2) * 1 + ['b', 'kb', 'mb', 'gb', 'tb'][i];
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setShowDropDownMenu(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <>
      <ContainerMinimized hidden={isHidden}>
        <FileItem minimized>
          <FileItemCloseButton onClick={() => setShowDropDownMenu(true)}>
            <Icon16.CloseBig />
            <DropdownMenu show={showDropDownMenu} ref={menuRef}>
              <DropDownItem onClick={() => setIsHidden(true)}>
                <Icon24.Hide />
                <DropDownItemText>Hide</DropDownItemText>
              </DropDownItem>
              <DropDownItem onClick={onCancel}>
                <Icon24.Close />
                <DropDownItemText>Stop uploading</DropDownItemText>
              </DropDownItem>
            </DropdownMenu>
          </FileItemCloseButton>
          <FileItemContent>
            <FileItemTitleIcon>
              <Icon24.Video />
            </FileItemTitleIcon>
            <FileItemTitle>
              <FileItemTitleText>{fileName}</FileItemTitleText>
              <FileItemSize>{formatFileSize(fileSize)}</FileItemSize>
            </FileItemTitle>
          </FileItemContent>

          <ProgressBarContainer>
            <ProgressBarLineUnderlayer />
            <ProgressBarLine progress={progress} />
          </ProgressBarContainer>
        </FileItem>
      </ContainerMinimized>
    </>
  );
};

export default UploadFileModalMinimized;
