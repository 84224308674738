import React, { FC } from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

const Icon24: FC<Props> = ({ children, ...restProps }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      {children}
    </svg>
  );
};

const Plus: FC = () => {
  return (
    <Icon24>
      <path
        d="M12 5V12M12 12V19M12 12H19M12 12H5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const PlusTagGroup: FC = () => {
  return (
    <Icon24>
      <path
        d="M5.99999 6.99999H6.00999M14.1642 18.6642L12.4142 20.4142C11.6332 21.1953 10.3668 21.1953 9.58579 20.4142L2.58579 13.4142C2.19526 13.0237 2 12.5118 2 12V6.99999C2 4.79086 3.79086 3 5.99999 3H11C11.5119 2.99999 12.0237 3.19525 12.4142 3.58579L14.3284 5.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7V12M17 12V17M17 12H22M17 12H12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Trash: FC = () => {
  return (
    <Icon24>
      <path
        d="M5 8.05556H19.2222M18.3333 8.05556L17.5624 18.8489C17.4959 19.7792 16.7218 20.5 15.7892 20.5H8.4331C7.50041 20.5 6.72629 19.7792 6.65984 18.8489L5.88889 8.05556H18.3333ZM10.3333 11.6111V16.9444V11.6111ZM13.8889 11.6111V16.9444V11.6111ZM14.7778 8.05556V5.38889C14.7778 4.89797 14.3798 4.5 13.8889 4.5H10.3333C9.84242 4.5 9.44444 4.89797 9.44444 5.38889V8.05556H14.7778Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Close: FC = () => {
  return (
    <Icon24>
      <path
        d="M6 6L18 18M6 18L18 6L6 18Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Arrow: FC = () => {
  return (
    <Icon24>
      <path
        d="M14.9142 6.5139L20.3999 11.9996M20.3999 11.9996L14.9142 17.4853M20.3999 11.9996L11.257 11.9996C8.51419 11.9996 4.3999 9.25675 4.3999 5.59961"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9142 17.4854L20.3999 11.9997M20.3999 11.9997L14.9142 6.51394M20.3999 11.9997L11.257 11.9997C9.42847 11.9997 4.3999 14.7425 4.3999 18.3997"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Warning: FC = () => {
  return (
    <Icon24>
      <path
        d="M12.5 8V12V8ZM12.5 16H12.51H12.5ZM21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Chevron: FC = () => {
  return (
    <Icon24>
      <path
        d="M5 13L9 17L19 7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const ChevronBadge: FC = () => {
  return (
    <Icon24>
      <path
        d="M9.00061 12.421L11.0006 14.421L15.0006 10.421M7.83535 5.11807C8.55288 5.06081 9.23407 4.77865 9.78193 4.31177C11.0604 3.22225 12.9408 3.22225 14.2193 4.31177C14.7672 4.77865 15.4483 5.06081 16.1659 5.11807C17.8403 5.25169 19.17 6.58133 19.3036 8.25576C19.3608 8.97329 19.643 9.65448 20.1099 10.2023C21.1994 11.4808 21.1994 13.3612 20.1099 14.6397C19.643 15.1875 19.3608 15.8687 19.3036 16.5863C19.17 18.2607 17.8403 19.5903 16.1659 19.724C15.4484 19.7812 14.7672 20.0634 14.2193 20.5303C12.9408 21.6198 11.0604 21.6198 9.78193 20.5303C9.23407 20.0634 8.55288 19.7812 7.83535 19.724C6.16092 19.5903 4.83128 18.2607 4.69766 16.5863C4.6404 15.8687 4.35824 15.1875 3.89136 14.6397C2.80184 13.3612 2.80184 11.4808 3.89136 10.2023C4.35824 9.65448 4.6404 8.97329 4.69766 8.25576C4.83128 6.58133 6.16092 5.25169 7.83535 5.11807Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Tags: FC = () => {
  return (
    <Icon24>
      <path
        d="M6.99999 6.99999H7.00999M6.99999 3H12C12.5119 2.99999 13.0237 3.19525 13.4142 3.58579L20.4143 10.5858C21.1953 11.3668 21.1953 12.6332 20.4143 13.4142L13.4142 20.4142C12.6332 21.1953 11.3668 21.1953 10.5858 20.4142L3.58579 13.4142C3.19526 13.0237 3 12.5118 3 12V6.99999C3 4.79086 4.79086 3 6.99999 3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Italic: FC = () => {
  return (
    <Icon24>
      <path
        d="M11.9285 9H13.9285L11.7285 19H9.72852L11.9285 9ZM13.6085 5C13.4107 5 13.2174 5.05865 13.0529 5.16853C12.8885 5.27841 12.7603 5.43459 12.6846 5.61732C12.6089 5.80004 12.5891 6.00111 12.6277 6.19509C12.6663 6.38907 12.7616 6.56725 12.9014 6.70711C13.0413 6.84696 13.2194 6.9422 13.4134 6.98079C13.6074 7.01937 13.8085 6.99957 13.9912 6.92388C14.1739 6.84819 14.3301 6.72002 14.44 6.55557C14.5499 6.39112 14.6085 6.19778 14.6085 6C14.6085 5.73478 14.5032 5.48043 14.3156 5.29289C14.1281 5.10536 13.8737 5 13.6085 5Z"
        fill="currentColor"
      ></path>
    </Icon24>
  );
};

const Bold: FC = () => {
  return (
    <Icon24>
      <path
        d="M7.43821 19V5.90909H12.2322C13.1612 5.90909 13.9304 6.0625 14.5398 6.36932C15.1491 6.67187 15.6051 7.0831 15.9077 7.60298C16.2102 8.11861 16.3615 8.70028 16.3615 9.34801C16.3615 9.89347 16.2614 10.3537 16.0611 10.7287C15.8608 11.0994 15.5923 11.3977 15.2557 11.6236C14.9233 11.8452 14.5568 12.0071 14.1562 12.1094V12.2372C14.5909 12.2585 15.0149 12.3991 15.4283 12.6591C15.8459 12.9148 16.1911 13.2791 16.4638 13.7521C16.7365 14.2251 16.8729 14.8004 16.8729 15.478C16.8729 16.147 16.7152 16.7479 16.3999 17.2805C16.0888 17.8089 15.6072 18.2287 14.9553 18.5398C14.3033 18.8466 13.4702 19 12.456 19H7.43821ZM9.41335 17.3061H12.2642C13.2102 17.3061 13.8878 17.1229 14.2969 16.7564C14.706 16.3899 14.9105 15.9318 14.9105 15.3821C14.9105 14.9687 14.8061 14.5895 14.5973 14.2443C14.3885 13.8991 14.0902 13.6243 13.7024 13.4197C13.3189 13.2152 12.8629 13.1129 12.3345 13.1129H9.41335V17.3061ZM9.41335 11.5724H12.0597C12.5028 11.5724 12.9013 11.4872 13.255 11.3168C13.6129 11.1463 13.8963 10.9077 14.1051 10.6009C14.3182 10.2898 14.4247 9.9233 14.4247 9.50142C14.4247 8.96023 14.2351 8.50639 13.8558 8.13991C13.4766 7.77344 12.8949 7.5902 12.1108 7.5902H9.41335V11.5724Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const H1: FC = () => {
  return (
    <Icon24>
      <path
        d="M2.43821 19V5.90909H4.41335V11.598H10.9396V5.90909H12.9212V19H10.9396V13.2919H4.41335V19H2.43821ZM20.5852 5.90909V19H18.6037V7.89062H18.527L15.3949 9.93608V8.04403L18.6612 5.90909H20.5852Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const H2: FC = () => {
  return (
    <Icon24>
      <path
        d="M1.43821 19V5.90909H3.41335V11.598H9.93963V5.90909H11.9212V19H9.93963V13.2919H3.41335V19H1.43821ZM14.0786 19V17.5682L18.5083 12.9787C18.9813 12.4801 19.3712 12.0433 19.678 11.6683C19.9891 11.2891 20.2214 10.929 20.3748 10.5881C20.5282 10.2472 20.6049 9.88494 20.6049 9.50142C20.6049 9.06676 20.5026 8.69176 20.2981 8.37642C20.0935 8.05682 19.8144 7.81179 19.4607 7.64134C19.107 7.46662 18.7086 7.37926 18.2654 7.37926C17.7966 7.37926 17.3876 7.47514 17.0381 7.6669C16.6887 7.85866 16.4202 8.12926 16.2327 8.47869C16.0452 8.82812 15.9515 9.23722 15.9515 9.70597H14.0658C14.0658 8.90909 14.2491 8.21236 14.6155 7.61577C14.982 7.01918 15.4849 6.55682 16.1241 6.22869C16.7633 5.89631 17.4898 5.73011 18.3038 5.73011C19.1262 5.73011 19.8506 5.89418 20.477 6.2223C21.1077 6.54616 21.5999 6.98935 21.9536 7.55185C22.3073 8.11009 22.4841 8.74077 22.4841 9.44389C22.4841 9.92969 22.3925 10.4048 22.2093 10.8693C22.0303 11.3338 21.7171 11.8516 21.2697 12.4226C20.8222 12.9893 20.2001 13.6776 19.4032 14.4872L16.8016 17.2102V17.3061H22.6951V19H14.0786Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const H3: FC = () => {
  return (
    <Icon24>
      <path
        d="M1.43821 19V5.90909H3.41335V11.598H9.93963V5.90909H11.9212V19H9.93963V13.2919H3.41335V19H1.43821ZM18.0961 19.179C17.2183 19.179 16.4342 19.0277 15.7439 18.7251C15.0578 18.4226 14.5145 18.0028 14.1139 17.4659C13.7176 16.9247 13.5045 16.2983 13.4747 15.5866H15.4818C15.5074 15.9744 15.6373 16.3111 15.8717 16.5966C16.1103 16.8778 16.4214 17.0952 16.8049 17.2486C17.1885 17.402 17.6146 17.4787 18.0834 17.4787C18.599 17.4787 19.0549 17.3892 19.4513 17.2102C19.8518 17.0312 20.165 16.782 20.3909 16.4624C20.6167 16.1385 20.7297 15.7656 20.7297 15.3438C20.7297 14.9048 20.6167 14.5192 20.3909 14.1868C20.1693 13.8501 19.8433 13.5859 19.4129 13.3942C18.9868 13.2024 18.4711 13.1065 17.866 13.1065H16.7602V11.4957H17.866C18.3518 11.4957 18.778 11.4084 19.1444 11.2337C19.5152 11.0589 19.8049 10.8161 20.0138 10.505C20.2226 10.1896 20.327 9.82102 20.327 9.39915C20.327 8.99432 20.2353 8.64276 20.0521 8.34446C19.8731 8.0419 19.6174 7.8054 19.2851 7.63494C18.9569 7.46449 18.5691 7.37926 18.1217 7.37926C17.6956 7.37926 17.2971 7.4581 16.9264 7.61577C16.5599 7.76918 16.2616 7.99077 16.0315 8.28054C15.8014 8.56605 15.6778 8.90909 15.6608 9.30966H13.7495C13.7709 8.60227 13.9797 7.98011 14.376 7.44318C14.7765 6.90625 15.3049 6.48651 15.9612 6.18395C16.6174 5.88139 17.3461 5.73011 18.1473 5.73011C18.9868 5.73011 19.7112 5.89418 20.3206 6.2223C20.9342 6.54616 21.4072 6.97869 21.7396 7.51989C22.0763 8.06108 22.2424 8.65341 22.2382 9.29688C22.2424 10.0298 22.0379 10.652 21.6245 11.1634C21.2155 11.6747 20.67 12.0178 19.9882 12.1925V12.2947C20.8575 12.4268 21.5308 12.772 22.0081 13.3303C22.4896 13.8885 22.7282 14.581 22.724 15.4077C22.7282 16.1278 22.528 16.7734 22.1231 17.3445C21.7226 17.9155 21.175 18.3651 20.4804 18.6932C19.7858 19.017 18.991 19.179 18.0961 19.179Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const OrderedList: FC = () => {
  return (
    <Icon24>
      <path
        d="M2.66895 16H4.66895V16.5H3.66895V17.5H4.66895V18H2.66895V19H5.66895V15H2.66895V16ZM3.66895 9H4.66895V5H2.66895V6H3.66895V9ZM2.66895 11H4.46895L2.66895 13.1V14H5.66895V13H3.86895L5.66895 10.9V10H2.66895V11ZM7.66895 6V8H21.6689V6H7.66895ZM7.66895 18H21.6689V16H7.66895V18ZM7.66895 13H21.6689V11H7.66895V13Z"
        fill="currentColor"
      ></path>
    </Icon24>
  );
};

const BulletList: FC = () => {
  return (
    <Icon24>
      <path
        d="M7.66895 6V8H21.6689V6H7.66895ZM7.66895 18H21.6689V16H7.66895V18ZM7.66895 13H21.6689V11H7.66895V13Z"
        fill="currentColor"
      ></path>
      <circle cx="4.25265" cy="7.01827" r="1.01827" fill="currentColor"></circle>
      <circle cx="4.25265" cy="12" r="1.01827" fill="currentColor"></circle>
      <circle cx="4.25265" cy="16.9814" r="1.01827" fill="currentColor"></circle>
    </Icon24>
  );
};

const Link: FC = () => {
  return (
    <Icon24>
      <path
        d="M14.0643 9.93571C13.4392 9.3108 12.5915 8.95975 11.7076 8.95975C10.8237 8.95975 9.97605 9.3108 9.35096 9.93571L6.01763 13.269C5.69926 13.5765 5.44532 13.9443 5.27062 14.351C5.09593 14.7577 5.00397 15.1951 5.00013 15.6377C4.99628 16.0803 5.08062 16.5192 5.24822 16.9289C5.41582 17.3385 5.66333 17.7107 5.97631 18.0237C6.28929 18.3367 6.66146 18.5842 7.07111 18.7518C7.48077 18.9194 7.9197 19.0037 8.3623 18.9999C8.8049 18.996 9.2423 18.9041 9.64898 18.7294C10.0557 18.5547 10.4235 18.3007 10.731 17.9824L11.6493 17.0649M11.0176 12.9824C11.6427 13.6073 12.4904 13.9583 13.3743 13.9583C14.2582 13.9583 15.1059 13.6073 15.731 12.9824L19.0643 9.64904C19.6715 9.02037 20.0075 8.17836 19.9999 7.30437C19.9923 6.43038 19.6417 5.59434 19.0237 4.97631C18.4057 4.35828 17.5696 4.00772 16.6956 4.00013C15.8216 3.99253 14.9796 4.32851 14.351 4.93571L13.4343 5.85237"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Image: FC = () => {
  return (
    <Icon24>
      <path
        d="M5 14.9975L8.82071 11.1768C9.13318 10.8644 9.55692 10.6889 9.99875 10.6889C10.4406 10.6889 10.8643 10.8644 11.1768 11.1768L14.9975 14.9975M13.3312 13.3312L14.6526 12.0099C14.9651 11.6975 15.3888 11.5221 15.8306 11.5221C16.2725 11.5221 16.6962 11.6975 17.0087 12.0099L18.33 13.3312M13.3312 8.3325H13.3396M6.66625 18.33H16.6637C17.1057 18.33 17.5295 18.1544 17.842 17.842C18.1544 17.5295 18.33 17.1057 18.33 16.6637V6.66625C18.33 6.22433 18.1544 5.80052 17.842 5.48803C17.5295 5.17555 17.1057 5 16.6637 5H6.66625C6.22433 5 5.80052 5.17555 5.48803 5.48803C5.17555 5.80052 5 6.22433 5 6.66625V16.6637C5 17.1057 5.17555 17.5295 5.48803 17.842C5.80052 18.1544 6.22433 18.33 6.66625 18.33Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Badge: FC = () => {
  return (
    <Icon24>
      <path
        d="M8.99963 12.0001L10.9996 14.0001L14.9996 10.0001M7.83437 4.69717C8.5519 4.63991 9.23309 4.35775 9.78095 3.89087C11.0594 2.80135 12.9398 2.80135 14.2183 3.89087C14.7662 4.35775 15.4473 4.63991 16.1649 4.69717C17.8393 4.83079 19.169 6.16043 19.3026 7.83486C19.3598 8.55239 19.642 9.23358 20.1089 9.78144C21.1984 11.0599 21.1984 12.9403 20.1089 14.2188C19.642 14.7666 19.3598 15.4478 19.3026 16.1654C19.169 17.8398 17.8393 19.1694 16.1649 19.3031C15.4474 19.3603 14.7662 19.6425 14.2183 20.1094C12.9398 21.1989 11.0594 21.1989 9.78095 20.1094C9.23309 19.6425 8.5519 19.3603 7.83437 19.3031C6.15994 19.1694 4.8303 17.8398 4.69668 16.1654C4.63942 15.4478 4.35726 14.7666 3.89038 14.2188C2.80086 12.9403 2.80086 11.0599 3.89038 9.78144C4.35726 9.23358 4.63942 8.55239 4.69668 7.83486C4.8303 6.16043 6.15994 4.83079 7.83437 4.69717Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Video: FC = () => {
  return (
    <Icon24>
      <path
        d="M15.2891 10L19.8419 7.72361C20.5068 7.39116 21.2891 7.87465 21.2891 8.61803V15.382C21.2891 16.1253 20.5068 16.6088 19.8419 16.2764L15.2891 14V10ZM5.28906 18H13.2891C14.3937 18 15.2891 17.1046 15.2891 16V8C15.2891 6.89543 14.3937 6 13.2891 6H5.28906C4.18449 6 3.28906 6.89543 3.28906 8V16C3.28906 17.1046 4.18449 18 5.28906 18Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const ZoomIcon: FC = () => {
  return (
    <Icon24>
      <path
        d="M1 8.5C1 7.39543 1.89543 6.5 3 6.5H13C15.2091 6.5 17 8.29086 17 10.5V17.5C17 18.6046 16.1046 19.5 15 19.5H5C2.79086 19.5 1 17.7091 1 15.5V8.5Z"
        fill="#2D8CFF"
      />
      <path
        d="M18 10.0662C18 9.71493 18.1843 9.38942 18.4855 9.2087L21.4855 7.4087C22.152 7.00878 23 7.4889 23 8.26619V17.7338C23 18.5111 22.152 18.9912 21.4855 18.5913L18.4855 16.7913C18.1843 16.6106 18 16.2851 18 15.9338V10.0662Z"
        fill="#2D8CFF"
      />
    </Icon24>
  );
};

const Hide: FC = () => {
  return (
    <Icon24>
      <path
        d="M12 7.38525C8.1761 7.38525 3 10.7705 3 11.8989C3 13.0273 8.1761 16.4125 12 16.4125C15.8239 16.4125 21 13.0273 21 11.8989C21 10.7705 15.8239 7.38525 12 7.38525Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.625 11.8989C14.625 13.3564 13.4476 14.5341 12 14.5341C10.5524 14.5341 9.375 13.3564 9.375 11.8989C9.375 10.4414 10.5524 9.26367 12 9.26367C13.4476 9.26367 14.625 10.4414 14.625 11.8989Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        x1="19.4912"
        y1="5.56677"
        x2="4.4912"
        y2="18.5668"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </Icon24>
  );
};

const Calendar: FC = () => {
  return (
    <Icon24>
      <path
        d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Lightning: FC = () => {
  return (
    <Icon24>
      <path
        d="M13 10V3L4 14H11V21L20 10H13Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const ExclamationCircle: FC = () => {
  return (
    <Icon24>
      <path
        d="M12 8V12M12 16H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const AISpinner: FC = () => {
  return (
    <Icon24>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1C5.13447 1.73159 1 6.33226 1 11.8986C1 17.9737 5.92487 22.8986 12 22.8986C17.5663 22.8986 22.167 18.7641 22.8986 13.3986H20.8756C20.1614 17.6552 16.4595 20.8986 12 20.8986C7.02944 20.8986 3 16.8691 3 11.8986C3 7.43909 6.24343 3.73714 10.5 3.02302V1Z"
        fill="url(#paint0_linear_2133_147)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2133_147"
          x1="11.9493"
          y1="1"
          x2="11.9493"
          y2="22.8986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8C0F8" />
          <stop offset="1" stopColor="#D1DDFF" />
        </linearGradient>
      </defs>
    </Icon24>
  );
};

const Insight: FC = () => {
  return (
    <Icon24>
      <path
        d="M5 3V7V3ZM3 5H7H3ZM6 17V21V17ZM4 19H8H4ZM13 3L15.2857 9.85714L21 12L15.2857 14.1429L13 21L10.7143 14.1429L5 12L10.7143 9.85714L13 3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Tag: FC = () => {
  return (
    <Icon24>
      <path
        d="M6.99999 6.99999H7.00999M6.99999 3H12C12.5119 2.99999 13.0237 3.19525 13.4142 3.58579L20.4143 10.5858C21.1953 11.3668 21.1953 12.6332 20.4143 13.4142L13.4142 20.4142C12.6332 21.1953 11.3668 21.1953 10.5858 20.4142L3.58579 13.4142C3.19526 13.0237 3 12.5118 3 12V6.99999C3 4.79086 4.79086 3 6.99999 3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Theme: FC = () => {
  return (
    <Icon24>
      <mask id="path-1-inside-1_2205_201" fill="white">
        <rect x="6" y="10.0859" width="6" height="6" rx="1.14286" />
      </mask>
      <rect
        x="6"
        y="10.0859"
        width="6"
        height="6"
        rx="1.14286"
        stroke="currentColor"
        strokeWidth="3"
        mask="url(#path-1-inside-1_2205_201)"
      />
      <mask id="path-2-inside-2_2205_201" fill="white">
        <rect x="13" y="10.0859" width="6" height="6" rx="1.14286" />
      </mask>
      <rect
        x="13"
        y="10.0859"
        width="6"
        height="6"
        rx="1.14286"
        stroke="currentColor"
        strokeWidth="3"
        mask="url(#path-2-inside-2_2205_201)"
      />
      <path
        d="M14 6.5L19.5 6.5C20.6046 6.5 21.5 7.39543 21.5 8.5V12.4857V17.0857C21.5 18.1903 20.6046 19.0857 19.5 19.0857H12.9641H5C3.89543 19.0857 3 18.1903 3 17.0857V7.5C3 6.94771 3.44772 6.5 4 6.5V6.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <rect
        x="6.75"
        y="5.13574"
        width="4.5"
        height="2.69096"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const ArrowLeft: FC = () => {
  return (
    <Icon24>
      <path
        d="M10 19L3 12M3 12L10 5M3 12H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Transcript: FC = () => {
  return (
    <Icon24>
      <path
        clipRule="evenodd"
        d="m6.704 13.868-4.335-.01c-.204-.001-.369-.155-.369-.344V6.343c0-.19.166-.343.37-.343h7.778c.205 0 .37.153.37.343v6.828C10.518 16.391 7.7 20 4.222 20c-.204 0-.37-.154-.37-.343v-2.743c0-.19.166-.343.37-.343 1.162 0 2.158-1.71 2.482-2.703Zm9 2.703c1.161 0 2.158-1.71 2.482-2.703l-4.335-.01c-.204-.001-.37-.155-.37-.344V6.343c0-.19.166-.343.37-.343h7.779c.204 0 .37.154.37.343v6.829C22 16.39 19.181 20 15.704 20c-.205 0-.37-.154-.37-.343v-2.743c0-.189.165-.343.37-.343Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Translate: FC = () => {
  return (
    <Icon24>
      <path
        d="M10.8333 21L16.0833 9.75L21.3333 21M12.3333 18H19.8333M3.33334 5.621C5.32374 5.37332 7.32759 5.24941 9.33334 5.25M9.33334 5.25C10.4533 5.25 11.5663 5.288 12.6673 5.364M9.33334 5.25V3M12.6673 5.364C11.5093 10.658 8.02334 15.08 3.33334 17.502M12.6673 5.364C13.5633 5.425 14.4523 5.511 15.3333 5.621M10.7443 14.116C9.10431 12.4486 7.80448 10.478 6.91734 8.314"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Refresh: FC = () => {
  return (
    <Icon24>
      <path
        d="M5.5 5.11829V9.49329H6.00883M6.00883 9.49329C7.04725 6.92793 9.56229 5.11829 12.5 5.11829C16.0696 5.11829 19.0153 7.79026 19.4458 11.2433M6.00883 9.49329H9.875M19.5 19.1183V14.7433H18.9912M18.9912 14.7433C17.9527 17.3086 15.4377 19.1183 12.5 19.1183C8.93033 19.1183 5.98474 16.4463 5.55415 12.9933M18.9912 14.7433H15.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Search: FC = () => {
  return (
    <Icon24>
      <path
        d="M21.5 21L15.5 15L21.5 21ZM17.5 10C17.5 13.866 14.366 17 10.5 17C6.63401 17 3.5 13.866 3.5 10C3.5 6.13401 6.63401 3 10.5 3C14.366 3 17.5 6.13401 17.5 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Person: FC = () => {
  return (
    <Icon24>
      <path
        d="M15.4669 12.3325C16.2619 11.4469 16.75 10.2806 16.75 9C16.75 6.24313 14.5069 4 11.75 4C8.99313 4 6.75 6.24313 6.75 9C6.75 11.7569 8.99313 14 11.75 14C12.7219 14 13.6269 13.7169 14.3956 13.235C17.1144 14.2594 18.9862 16.7475 19.2244 19.625H4.27625C4.43812 17.6881 5.34687 15.8819 6.825 14.5931C7.08562 14.3662 7.1125 13.9719 6.88563 13.7113C6.65938 13.4513 6.26438 13.4237 6.00375 13.6506C4.09438 15.3144 3 17.7194 3 20.25C3 20.5956 3.28 20.875 3.625 20.875H19.875C20.2206 20.875 20.5 20.5956 20.5 20.25C20.5 16.8212 18.5125 13.7594 15.4669 12.3325ZM8 9C8 6.93187 9.68187 5.25 11.75 5.25C13.8181 5.25 15.5 6.93187 15.5 9C15.5 11.0681 13.8181 12.75 11.75 12.75C9.68187 12.75 8 11.0681 8 9Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const SWOT: FC = () => {
  return (
    <Icon24>
      <path
        d="M8.13636 4.5H11.6108V11.1108H5V7.63636C5 5.9042 6.4042 4.5 8.13636 4.5Z"
        stroke="currentColor"
      />
      <path
        d="M5 12.8892H11.6108V19.5H8.13637C6.4042 19.5 5 18.0958 5 16.3636V12.8892Z"
        stroke="currentColor"
      />
      <path
        d="M13.3892 12.8892H20V16.3636C20 18.0958 18.5958 19.5 16.8636 19.5H13.3892V12.8892Z"
        stroke="currentColor"
      />
      <path
        d="M13.3892 4.5H16.8636C18.5958 4.5 20 5.9042 20 7.63636V11.1108H13.3892V4.5Z"
        stroke="currentColor"
      />
    </Icon24>
  );
};

const UsabilityTesting: FC = () => {
  return (
    <Icon24>
      <path
        d="M18.75 17.25V19.5H5.25V12.6225L7.9425 15.3075L9 14.25L4.5 9.75L0 14.25L1.0575 15.3075L3.75 12.6225V19.5C3.75 19.8978 3.90804 20.2794 4.18934 20.5607C4.47064 20.842 4.85218 21 5.25 21H18.75C19.1478 21 19.5294 20.842 19.8107 20.5607C20.092 20.2794 20.25 19.8978 20.25 19.5V17.25H18.75Z"
        fill="currentColor"
      />
      <path
        d="M20.25 5.25V12.1275L22.9425 9.4425L24 10.5L19.5 15L15 10.5L16.0575 9.4425L18.75 12.1275V5.25H9.75V3.75H18.75C19.1478 3.75 19.5294 3.90804 19.8107 4.18934C20.092 4.47064 20.25 4.85218 20.25 5.25Z"
        fill="currentColor"
      />
      <path
        d="M6 7.5H3C2.60218 7.5 2.22064 7.34196 1.93934 7.06066C1.65804 6.77936 1.5 6.39782 1.5 6V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H6C6.39782 1.5 6.77936 1.65804 7.06066 1.93934C7.34196 2.22064 7.5 2.60218 7.5 3V6C7.5 6.39782 7.34196 6.77936 7.06066 7.06066C6.77936 7.34196 6.39782 7.5 6 7.5ZM3 3V6H6V3H3Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const FocusGroup: FC = () => {
  return (
    <Icon24>
      <path
        d="M7.5 22.5H6V20.25H3V22.5H1.5V20.25C1.50046 19.8523 1.65864 19.471 1.93984 19.1898C2.22105 18.9086 2.60232 18.7505 3 18.75H6C6.39768 18.7505 6.77895 18.9086 7.06016 19.1898C7.34136 19.471 7.49954 19.8523 7.5 20.25V22.5Z"
        fill="currentColor"
      />
      <path
        d="M22.5 22.5H21V20.25H18V22.5H16.5V20.25C16.5005 19.8523 16.6586 19.471 16.9398 19.1898C17.221 18.9086 17.6023 18.7505 18 18.75H21C21.3977 18.7505 21.779 18.9086 22.0602 19.1898C22.3414 19.471 22.4995 19.8523 22.5 20.25V22.5Z"
        fill="currentColor"
      />
      <path
        d="M15 22.5H13.5V20.25H10.5V22.5H9V20.25C9.00046 19.8523 9.15864 19.471 9.43984 19.1898C9.72105 18.9086 10.1023 18.7505 10.5 18.75H13.5C13.8977 18.7505 14.279 18.9086 14.5602 19.1898C14.8414 19.471 14.9995 19.8523 15 20.25V22.5Z"
        fill="currentColor"
      />
      <path
        d="M12 18C12.8284 18 13.5 17.3284 13.5 16.5C13.5 15.6716 12.8284 15 12 15C11.1716 15 10.5 15.6716 10.5 16.5C10.5 17.3284 11.1716 18 12 18Z"
        fill="currentColor"
      />
      <path
        d="M4.5 18C5.32843 18 6 17.3284 6 16.5C6 15.6716 5.32843 15 4.5 15C3.67157 15 3 15.6716 3 16.5C3 17.3284 3.67157 18 4.5 18Z"
        fill="currentColor"
      />
      <path
        d="M19.5 18C20.3284 18 21 17.3284 21 16.5C21 15.6716 20.3284 15 19.5 15C18.6716 15 18 15.6716 18 16.5C18 17.3284 18.6716 18 19.5 18Z"
        fill="currentColor"
      />
      <path
        d="M15.75 15C16.5784 15 17.25 14.3284 17.25 13.5C17.25 12.6716 16.5784 12 15.75 12C14.9216 12 14.25 12.6716 14.25 13.5C14.25 14.3284 14.9216 15 15.75 15Z"
        fill="currentColor"
      />
      <path
        d="M8.25 15C9.07843 15 9.75 14.3284 9.75 13.5C9.75 12.6716 9.07843 12 8.25 12C7.42157 12 6.75 12.6716 6.75 13.5C6.75 14.3284 7.42157 15 8.25 15Z"
        fill="currentColor"
      />
      <path
        d="M19.5 10.5H4.5C4.10232 10.4995 3.72105 10.3414 3.43984 10.0602C3.15864 9.77895 3.00046 9.39768 3 9V3C3.00046 2.60232 3.15864 2.22105 3.43984 1.93984C3.72105 1.65864 4.10232 1.50046 4.5 1.5H19.5C19.8977 1.50046 20.279 1.65864 20.5602 1.93984C20.8414 2.22105 20.9995 2.60232 21 3V9C20.9995 9.39768 20.8414 9.77895 20.5602 10.0602C20.279 10.3414 19.8977 10.4995 19.5 10.5ZM4.5 3V9H19.5V3H4.5Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const LightBulb: FC = () => {
  return (
    <Icon24>
      <path
        d="M9.66348 17H14.3364M11.9999 3V4M18.3639 5.63603L17.6568 6.34316M21 11.9999H20M4 11.9999H3M6.34308 6.34316L5.63599 5.63603M8.46443 15.5356C6.51178 13.5829 6.51178 10.4171 8.46443 8.46451C10.417 6.51186 13.5829 6.51186 15.5355 8.46451C17.4881 10.4171 17.4881 13.5829 15.5355 15.5356L14.9884 16.0827C14.3555 16.7155 13.9999 17.5739 13.9999 18.469V19C13.9999 20.1046 13.1045 21 11.9999 21C10.8954 21 9.99993 20.1046 9.99993 19V18.469C9.99993 17.5739 9.6444 16.7155 9.0115 16.0827L8.46443 15.5356Z"
        stroke="currentColor"
        strokeWidth="1.52616"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const QuestionMark: FC = () => {
  return (
    <Icon24>
      <path
        d="M8.22766 9C8.77678 7.83481 10.2584 7 12.0001 7C14.2092 7 16.0001 8.34315 16.0001 10C16.0001 11.3994 14.7224 12.5751 12.9943 12.9066C12.4519 13.0106 12.0001 13.4477 12.0001 14M12 17H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Highlight: FC = () => {
  return (
    <Icon24>
      <path
        d="M17.9697 17.7162H1.59766L3.97903 15.3403"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M14.3574 1.23047L18.0789 4.95197L7.88645 15.1444L4.16496 15.1444L4.16496 11.4229L14.3574 1.23047Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
      <path
        d="M4.0127 11.3506C4.0127 11.3506 5.41377 11.3505 6.51987 12.454C7.62597 13.5575 7.62597 14.9552 7.62597 14.9552"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </Icon24>
  );
};

const AIStar: FC = () => {
  return (
    <Icon24>
      <path
        d="M11.25 6.75H21.25M15 12H21.25M11.25 17.25H21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6L8.42856 10.5714L12 12L8.42856 13.4286L7 18L5.57144 13.4286L2 12L5.57144 10.5714L7 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Upload: FC = () => {
  return (
    <Icon24>
      <path
        d="M4 16V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V16M16 8L12 4M12 4L8 8M12 4V16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const ThumbUp: FC = () => {
  return (
    <Icon24>
      <path
        d="M14.0556 10.2274H17.7608C18.9172 10.2274 19.6693 11.4443 19.1522 12.4786L16.43 17.923C16.1664 18.4501 15.6278 18.783 15.0386 18.783H11.9137C11.7865 18.783 11.6598 18.7673 11.5364 18.7365L8.61111 18.0052M14.0556 10.2274V6.33851C14.0556 5.4794 13.3591 4.78296 12.5 4.78296H12.4257C12.0372 4.78296 11.7222 5.09794 11.7222 5.48648C11.7222 6.04205 11.5578 6.58519 11.2496 7.04745L8.61111 11.0052V18.0052M14.0556 10.2274H12.5M8.61111 18.0052H7.05556C6.19645 18.0052 5.5 17.3088 5.5 16.4496V11.783C5.5 10.9238 6.19645 10.2274 7.05556 10.2274H9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Heart: FC<{ active?: boolean }> = ({ active }) => {
  return (
    <Icon24>
      <path
        d="M4.31802 6.31802C2.56066 8.07538 2.56066 10.9246 4.31802 12.682L12.0001 20.364L19.682 12.682C21.4393 10.9246 21.4393 8.07538 19.682 6.31802C17.9246 4.56066 15.0754 4.56066 13.318 6.31802L12.0001 7.63609L10.682 6.31802C8.92462 4.56066 6.07538 4.56066 4.31802 6.31802Z"
        stroke="currentColor"
        fill={active ? 'currentColor' : 'none'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const GoogleDriveIcon: FC = () => {
  return (
    <Icon24>
      <g clipPath="url(#a)">
        <path
          d="m2.024 16.102.912 1.577c.19.332.463.593.783.782l3.26-5.642H.459c0 .367.095.735.284 1.067l1.28 2.216Z"
          fill="#0066DA"
        />
        <path
          d="M10.807 6.181 7.547.539c-.32.19-.592.45-.782.782L.743 11.752A2.148 2.148 0 0 0 .46 12.82h6.52l3.828-6.638Z"
          fill="#00AC47"
        />
        <path
          d="M17.896 18.461c.32-.19.593-.45.782-.782l.38-.652 1.813-3.141c.19-.332.285-.7.285-1.067h-6.52l1.387 2.726 1.873 2.916Z"
          fill="#EA4335"
        />
        <path
          d="m10.807 6.18 3.26-5.642A2.084 2.084 0 0 0 13 .254H8.614c-.38 0-.747.107-1.067.284l3.26 5.643Z"
          fill="#00832D"
        />
        <path
          d="M14.636 12.819H6.98l-3.26 5.642c.32.19.687.285 1.067.285h12.043c.38 0 .747-.107 1.067-.285l-3.26-5.642Z"
          fill="#2684FC"
        />
        <path
          d="M17.86 6.537 14.85 1.32a2.153 2.153 0 0 0-.783-.782l-3.26 5.642 3.83 6.638h6.507c0-.367-.095-.735-.284-1.067l-3-5.215Z"
          fill="#FFBA00"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.645 .254)" d="M0 0h20.711v18.492H0z" />
        </clipPath>
      </defs>
    </Icon24>
  );
};

const OneDriveIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <Icon24 {...props}>
      <g id="STYLE_COLOR">
        <path
          d="M12.20245,11.19292l.00031-.0011,6.71765,4.02379,4.00293-1.68451.00018.00068A6.4768,6.4768,0,0,1,25.5,13c.14764,0,.29358.0067.43878.01639a10.00075,10.00075,0,0,0-18.041-3.01381C7.932,10.00215,7.9657,10,8,10A7.96073,7.96073,0,0,1,12.20245,11.19292Z"
          fill="#0364b8"
        />
        <path
          d="M12.20276,11.19182l-.00031.0011A7.96073,7.96073,0,0,0,8,10c-.0343,0-.06805.00215-.10223.00258A7.99676,7.99676,0,0,0,1.43732,22.57277l5.924-2.49292,2.63342-1.10819,5.86353-2.46746,3.06213-1.28859Z"
          fill="#0078d4"
        />
        <path
          d="M25.93878,13.01639C25.79358,13.0067,25.64764,13,25.5,13a6.4768,6.4768,0,0,0-2.57648.53178l-.00018-.00068-4.00293,1.68451,1.16077.69528L23.88611,18.19l1.66009.99438,5.67633,3.40007a6.5002,6.5002,0,0,0-5.28375-9.56805Z"
          fill="#1490df"
        />
        <path
          d="M25.5462,19.18437,23.88611,18.19l-3.80493-2.2791-1.16077-.69528L15.85828,16.5042,9.99475,18.97166,7.36133,20.07985l-5.924,2.49292A7.98889,7.98889,0,0,0,8,26H25.5a6.49837,6.49837,0,0,0,5.72253-3.41556Z"
          fill="#28a8ea"
        />
      </g>
    </Icon24>
  );
};

const Dots: FC = () => {
  return (
    <Icon24>
      <path
        d="M5 12H5.01H5ZM12 12H12.01H12ZM19 12H19.01H19ZM6 12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM20 12C20 12.5523 19.5523 13 19 13C18.4477 13 18 12.5523 18 12C18 11.4477 18.4477 11 19 11C19.5523 11 20 11.4477 20 12Z"
        stroke="currentColor"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const ChevronDown: FC = () => {
  return (
    <Icon24>
      <path
        d="M17 10L12 15L7 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};
const ChevronBack: FC = () => {
  return (
    <Icon24>
      <path
        d="M15 19L8 12L15 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Maximaize: FC = () => {
  return (
    <Icon24>
      <path
        d="M3.75 3.75V8.25M3.75 3.75H8.25M3.75 3.75L9 9M3.75 20.25V15.75M3.75 20.25H8.25M3.75 20.25L9 15M20.25 3.75H15.75M20.25 3.75V8.25M20.25 3.75L15 9M20.25 20.25H15.75M20.25 20.25V15.75M20.25 20.25L15 15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Smile: FC = () => {
  return (
    <Icon24>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12.0005 16.5C9.90367 16.5 8.14181 15.0659 7.64226 13.125H9.21856C9.6636 14.2244 10.7415 15 12.0005 15C13.2595 15 14.3374 14.2244 14.7824 13.125H16.3587C15.8592 15.0659 14.0973 16.5 12.0005 16.5ZM15.9375 9.89062C15.9375 10.4343 15.4968 10.875 14.9531 10.875C14.4095 10.875 13.9688 10.4343 13.9688 9.89062C13.9688 9.34697 14.4095 8.90625 14.9531 8.90625C15.4968 8.90625 15.9375 9.34697 15.9375 9.89062ZM8.76562 10.875C9.30928 10.875 9.75 10.4343 9.75 9.89062C9.75 9.34697 9.30928 8.90625 8.76562 8.90625C8.22197 8.90625 7.78125 9.34697 7.78125 9.89062C7.78125 10.4343 8.22197 10.875 8.76562 10.875Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const Folder: FC = () => {
  return (
    <Icon24>
      <path
        d="M3.33331 7V17C3.33331 18.1046 4.22874 19 5.33331 19H19.3333C20.4379 19 21.3333 18.1046 21.3333 17V9C21.3333 7.89543 20.4379 7 19.3333 7H13.3333L11.3333 5H5.33331C4.22874 5 3.33331 5.89543 3.33331 7Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const ChevronBold: FC = () => {
  return (
    <Icon24>
      <path
        d="M6.31659 9.34775C6.75259 8.90175 7.35959 8.86675 7.89259 9.34775L11.8006 13.0947L15.7086 9.34775C16.2416 8.86675 16.8496 8.90175 17.2826 9.34775C17.7186 9.79275 17.6906 10.5447 17.2826 10.9627C16.8766 11.3807 12.5876 15.4647 12.5876 15.4647C12.3706 15.6877 12.0856 15.7997 11.8006 15.7997C11.5156 15.7997 11.2306 15.6877 11.0116 15.4647C11.0116 15.4647 6.72459 11.3807 6.31659 10.9627C5.90859 10.5447 5.88059 9.79275 6.31659 9.34775Z"
        fill="currentColor"
      />
    </Icon24>
  );
};

const Filter: FC = () => {
  return (
    <Icon24>
      <path d="M3 6H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 12L18 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M10 18L14 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </Icon24>
  );
};

const Eye: FC = () => {
  return (
    <Icon24>
      <path
        d="M14.9998 12C14.9998 13.6569 13.6566 15 11.9998 15C10.3429 15 8.99976 13.6569 8.99976 12C8.99976 10.3431 10.3429 9 11.9998 9C13.6566 9 14.9998 10.3431 14.9998 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.45801 12C3.73228 7.94288 7.52257 5 12.0002 5C16.4778 5 20.2681 7.94291 21.5424 12C20.2681 16.0571 16.4778 19 12.0002 19C7.52256 19 3.73226 16.0571 2.45801 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const EyeCrossed: FC = () => {
  return (
    <Icon24>
      <path
        d="M14.3749 19.1996C13.7677 19.3148 13.1411 19.375 12.5005 19.375C8.02281 19.375 4.23251 16.4321 2.95825 12.375C3.30515 11.2705 3.83851 10.2486 4.52143 9.34618M10.3787 10.2537C10.9216 9.71079 11.6716 9.375 12.5 9.375C14.1569 9.375 15.5 10.7181 15.5 12.375C15.5 13.2034 15.1642 13.9534 14.6213 14.4963M10.3787 10.2537L14.6213 14.4963M10.3787 10.2537L7.08916 6.96416M14.6213 14.4963L17.9112 17.7862M7.08916 6.96416L3.5 3.375M7.08916 6.96416C8.64898 5.95854 10.5066 5.375 12.5004 5.375C16.9781 5.375 20.7684 8.31791 22.0426 12.375C21.3357 14.6257 19.8545 16.5335 17.9112 17.7862M17.9112 17.7862L21.5 21.375"
        stroke="currentColor"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const AtSign: FC = () => {
  return (
    <Icon24>
      <path
        d="M16.75 12C16.75 9.79086 14.9591 8 12.75 8C10.5409 8 8.75 9.79086 8.75 12C8.75 14.2091 10.5409 16 12.75 16C14.9591 16 16.75 14.2091 16.75 12ZM16.75 12V13.5C16.75 14.8807 17.8693 16 19.25 16C20.6307 16 21.75 14.8807 21.75 13.5V12C21.75 7.02944 17.7206 3 12.75 3C7.77944 3 3.75 7.02944 3.75 12C3.75 16.9706 7.77944 21 12.75 21M17.25 19.7942C15.8301 20.614 14.2796 21.0029 12.75 21.0015"
        stroke="currentColor"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Comment: FC = () => {
  return (
    <Icon24>
      <path
        d="M21.25 12C21.25 16.4183 17.2206 20 12.25 20C10.7107 20 9.26172 19.6565 7.99467 19.0511L3.25 20L4.64499 16.28C3.76156 15.0423 3.25 13.5743 3.25 12C3.25 7.58172 7.27944 4 12.25 4C17.2206 4 21.25 7.58172 21.25 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Chat: FC = () => {
  return (
    <Icon24>
      <path
        id="Vector"
        d="M21 12.375C21 16.7933 16.9706 20.375 12 20.375C10.4607 20.375 9.01172 20.0315 7.74467 19.4261L3 20.375L4.39499 16.655C3.51156 15.4173 3 13.9493 3 12.375C3 7.95672 7.02944 4.375 12 4.375C16.9706 4.375 21 7.95672 21 12.375Z"
        stroke="currentColor"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const AddEmoji: FC = () => {
  return (
    <Icon24>
      <path
        d="M15.3284 15.0159C13.7663 16.578 11.2337 16.578 9.67157 15.0159M9.5 10.1875H9.51M15.5 10.1875H15.51M21.5 12.1875C21.5 17.1581 17.4706 21.1875 12.5 21.1875C7.52944 21.1875 3.5 17.1581 3.5 12.1875C3.5 7.21694 7.52944 3.1875 12.5 3.1875"
        stroke="currentColor"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2998 2V5.5M19.2998 5.5V9M19.2998 5.5H22.7998M19.2998 5.5H15.7998"
        stroke="currentColor"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const HighlightAll: FC = () => {
  return (
    <Icon24>
      <path
        d="M20.372 21.4858H4L6.38137 19.1099"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7598 5L20.4813 8.7215L10.2889 18.9139H6.56738V15.1924L16.7598 5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M6.41504 15.1201C6.41504 15.1201 7.81611 15.12 8.92221 16.2235C10.0283 17.327 10.0283 18.7247 10.0283 18.7247"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M20 14V18M18 16H22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 2L7.14285 5.42857L10 6.5L7.14285 7.57145L6 11L4.85715 7.57145L2 6.5L4.85715 5.42857L6 2Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Clock: FC = () => {
  return (
    <Icon24>
      <path
        d="M12 6V12H16.5M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Document: FC = () => {
  return (
    <Icon24>
      <path
        d="M9.55556 12H14.8889H9.55556ZM9.55556 15.5556H14.8889H9.55556ZM16.6667 20H7.77778C6.79594 20 6 19.2041 6 18.2222V5.77778C6 4.79594 6.79594 4 7.77778 4H12.7429C12.9787 4 13.2048 4.09365 13.3715 4.26035L18.1841 9.07299C18.3508 9.23968 18.4444 9.46578 18.4444 9.70152V18.2222C18.4444 19.2041 17.6485 20 16.6667 20Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Folders: FC = () => {
  return (
    <Icon24>
      <path
        d="M18.2222 11.1111H5.77778M18.2222 11.1111C19.2041 11.1111 20 11.907 20 12.8889V18.2222C20 19.2041 19.2041 20 18.2222 20H5.77778C4.79594 20 4 19.2041 4 18.2222V12.8889C4 11.907 4.79594 11.1111 5.77778 11.1111M18.2222 11.1111V9.33333C18.2222 8.35149 17.4263 7.55556 16.4444 7.55556M5.77778 11.1111V9.33333C5.77778 8.35149 6.57372 7.55556 7.55556 7.55556M16.4444 7.55556V5.77778C16.4444 4.79594 15.6485 4 14.6667 4H9.33333C8.35149 4 7.55556 4.79594 7.55556 5.77778V7.55556M16.4444 7.55556H7.55556"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

const Participant: FC = () => {
  return (
    <Icon24>
      <path
        d="M16.0833 7.66667C16.0833 9.69171 14.4417 11.3333 12.4167 11.3333C10.3916 11.3333 8.75 9.69171 8.75 7.66667C8.75 5.64162 10.3916 4 12.4167 4C14.4417 4 16.0833 5.64162 16.0833 7.66667Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4167 14.0835C8.87284 14.0835 6 16.9563 6 20.5002H18.8333C18.8333 16.9563 15.9605 14.0835 12.4167 14.0835Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon24>
  );
};

export default {
  Plus,
  PlusTagGroup,
  Trash,
  Close,
  Arrow,
  Warning,
  Chevron,
  ChevronBadge,
  Tags,
  Italic,
  Bold,
  H1,
  H2,
  H3,
  OrderedList,
  BulletList,
  Link,
  Image,
  Badge,
  Video,
  ZoomIcon,
  Hide,
  Calendar,
  Lightning,
  ExclamationCircle,
  AISpinner,
  Insight,
  Tag,
  Theme,
  ArrowLeft,
  Transcript,
  Refresh,
  Search,
  Person,
  SWOT,
  UsabilityTesting,
  FocusGroup,
  LightBulb,
  QuestionMark,
  Highlight,
  AIStar,
  Upload,
  ThumbUp,
  Heart,
  GoogleDriveIcon,
  OneDriveIcon,
  Dots,
  ChevronDown,
  Maximaize,
  Smile,
  Folder,
  ChevronBold,
  Translate,
  Filter,
  Eye,
  EyeCrossed,
  AtSign,
  Comment,
  Chat,
  AddEmoji,
  HighlightAll,
  Clock,
  ChevronBack,
  Document,
  Folders,
  Participant,
};
