import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import withTagManagement, { withAllTagsManagement } from '../../Hocs/WithTagManagement';
import { Icon16, Icon20, Icon24 } from '../../Icons/Icon';
import { ID, TTagGroupWithTags, TagWithDetails } from '../../Models';

import {
  Container,
  InputField,
  GlobalSublistButton,
  GlobalSublistButtonText,
  Divider,
  TagList,
  TagItem,
  TagName,
  TagInstances,
  ManageTagsButton,
  ManageTagsText,
  NoOptionsText,
  ChosenTagsArea,
  ChosenTag,
  ChosenTagsList,
  ChosenTagName,
  ChosenTagCloseButton,
  FolderPanel,
  GlobalSublistButtonWrapper,
  CreateTagButton,
  ContentWrapper,
  ChosenTagsAreaWrapper,
  FolderPanelWrapper,
  TagListTitle,
  TagsContainer,
  Spinner,
} from './styles';
import PortalNew from '../PortalNew';
import { useEffectOnce } from 'usehooks-ts';

interface TagsDropdownProps {
  groups: TTagGroupWithTags[];
  tagsWithoutGroup: TagWithDetails[];
  suggestedTags?: TagWithDetails[];
  value?: TagWithDetails[];
  hideManageButton?: boolean;
  isLoading?: boolean;
  onRefetchTags(): void;
  onCreate?(tagName: string): Promise<TagWithDetails>;
  onChange?(chosenTags: TagWithDetails[]): void;
  onBlur?(): void;
}

type TDivRef = { [key: string]: HTMLDivElement | null };

const TagsDropdown: React.FC<TagsDropdownProps> = ({
  tagsWithoutGroup,
  groups,
  value,
  suggestedTags,
  hideManageButton,
  isLoading,
  onCreate,
  onChange,
  onBlur,
  onRefetchTags,
}) => {
  const [searchText, setSearchText] = useState('');
  const [showFolderPanelId, setShowFolderPanelId] = useState<ID | null>(null);
  const [chosenTags, setChosenTags] = useState<TagWithDetails[]>(value || []);
  const [currentSuggestedTags, setCurrentSuggestedTags] = useState<TagWithDetails[]>(
    suggestedTags?.filter((tag) => !chosenTags.find((chosenTag) => chosenTag.id === tag.id)) || []
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const folderButtonRefs = useRef<TDivRef>({});

  const [currentTags, setCurrentTags] = useState<TagWithDetails[]>(
    tagsWithoutGroup.filter(
      (tag) => !chosenTags.find((chosenTag) => chosenTag.id === tag.id)
      // && !currentSuggestedTags.find((suggestedTag) => suggestedTag.id === tag.id)
    )
  );

  const [currentGroups, setCurrentGroups] = useState<TTagGroupWithTags[]>(
    groups.filter((group) =>
      group.tags.some((tag) => !chosenTags.find((chosenTag) => chosenTag.id === tag.id))
    )
  );

  const match = useRouteMatch({
    path: '/projects/:dashboardId',
    exact: false,
  });

  const handleCreateTag = async () => {
    if (!onCreate) return;
    const newTag = await onCreate(searchText);
    setSearchText('');
    handleChange(newTag, 'add');
  };

  const handleChange = (chosenTag: TagWithDetails, action: 'add' | 'remove') => {
    const newList =
      action === 'add'
        ? chosenTags.concat([chosenTag])
        : chosenTags.filter((tag) => tag.id !== chosenTag.id);
    setChosenTags(newList);
    setSearchText('');
    onChange && onChange(newList);
  };

  const handleFieldBlur = (e: FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.target.focus({ preventScroll: true });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key == 'Enter') {
      if (
        searchText &&
        !currentTags.find(
          (tag) => tag.name.trim().toLowerCase() === searchText.trim().toLowerCase()
        )
      ) {
        handleCreateTag();
      }
      return;
    }

    if (e.key == 'Escape') {
      e.preventDefault();
      onBlur && onBlur();
    }
  };

  useEffectOnce(() => {
    onRefetchTags();
  });

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        onBlur && onBlur();
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [onBlur, containerRef]);

  useEffect(() => {
    inputRef && inputRef.current?.focus({ preventScroll: true });
  }, [inputRef]);

  useEffect(() => {
    if (searchText) {
      const groupTags = groups.flatMap((group) => group.tags);
      const filteredTags = [...tagsWithoutGroup, ...groupTags].filter(
        (tag) =>
          tag.name.trim().toLowerCase().includes(searchText.trim().toLowerCase()) &&
          !chosenTags.find((chosenTag) => chosenTag.id === tag.id)
        //  &&!currentSuggestedTags.find((suggestedTag) => suggestedTag.id === tag.id)
      );

      setCurrentTags(filteredTags);
    } else {
      const filteredTags: TagWithDetails[] = tagsWithoutGroup.filter(
        (tag) => !chosenTags.find((chosenTag) => chosenTag.id === tag.id)
        // && !currentSuggestedTags.find((suggestedTag) => suggestedTag.id === tag.id)
      );
      setCurrentTags(filteredTags);
    }
  }, [searchText, tagsWithoutGroup, groups, chosenTags, currentSuggestedTags]);

  useEffect(() => {
    if (searchText) {
      setCurrentGroups([]);
    } else {
      const filteredGroups = groups
        .filter((group) =>
          group.tags.some((tag) => !chosenTags.find((chosenTag) => chosenTag.id === tag.id))
        )
        .map((group) => ({
          ...group,
          tags: group.tags.filter(
            (tag) =>
              tag.name.trim().toLowerCase().includes(searchText.trim().toLowerCase()) &&
              !chosenTags.find((chosenTag) => chosenTag.id === tag.id)
            // && !currentSuggestedTags.find((suggestedTag) => suggestedTag.id === tag.id)
          ),
        }))
        .filter((group) => group.tags.length);

      setCurrentGroups(filteredGroups);
    }
  }, [searchText, groups, chosenTags, currentSuggestedTags]);

  useEffect(() => {
    setCurrentSuggestedTags(
      suggestedTags?.filter(
        (tag) =>
          !chosenTags.find((chosenTag) => chosenTag.id === tag.id) &&
          tag.name.trim().toLowerCase().includes(searchText.trim().toLowerCase())
      ) || []
    );
  }, [searchText, suggestedTags, chosenTags]);

  const folderPanelCoordinates = (groupId: ID) => {
    if (!folderButtonRefs.current[groupId]) return { left: 0, top: 0 };

    const { left, top, width } = folderButtonRefs.current[groupId]?.getBoundingClientRect() || {
      left: 0,
      top: 0,
      width: 0,
    };

    return { left: left + width - 10, top: top + window.scrollY };
  };

  return (
    <Container ref={containerRef}>
      <ChosenTagsAreaWrapper>
        <ChosenTagsArea>
          <ChosenTagsList>
            {chosenTags.map((chosenTag) => (
              <ChosenTag key={chosenTag.id} color={chosenTag.color}>
                <ChosenTagName>{chosenTag.name}</ChosenTagName>
                <ChosenTagCloseButton onClick={() => handleChange(chosenTag, 'remove')}>
                  <Icon16.Close />
                </ChosenTagCloseButton>
              </ChosenTag>
            ))}
          </ChosenTagsList>
          <InputField
            value={searchText}
            ref={inputRef}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={hideManageButton ? 'Search all tags' : 'Find or create tag'}
            onBlur={handleFieldBlur}
            onKeyDown={handleKeyDown}
          />
        </ChosenTagsArea>
      </ChosenTagsAreaWrapper>
      <ContentWrapper>
        <TagsContainer>
          {!!currentSuggestedTags.length && (
            <>
              <TagListTitle>Suggested</TagListTitle>
              <TagList>
                {currentSuggestedTags.map((tag) => (
                  <TagItem key={tag.id} onClick={() => handleChange(tag, 'add')}>
                    <TagName color={tag.color}>{tag.name}</TagName>
                    <TagInstances>
                      {tag.tagsDocumentsConnection.totalCount +
                        tag.tagsNotesConnection.totalCount +
                        tag.tagsTranscriptionsConnection.totalCount}
                    </TagInstances>
                  </TagItem>
                ))}
              </TagList>

              <Divider />
            </>
          )}

          {!isLoading &&
            currentGroups &&
            currentGroups.map((group) => (
              <GlobalSublistButtonWrapper
                ref={(el) => {
                  if (el) {
                    folderButtonRefs.current[group.id] = el as HTMLDivElement;
                  }
                }}
                key={group.id}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseEnter={() => {
                  setShowFolderPanelId(group.id);
                }}
                onMouseLeave={() => {
                  setShowFolderPanelId(null);
                }}
              >
                {showFolderPanelId === group.id && (
                  <PortalNew wrapperId="tagsFolder">
                    <FolderPanelWrapper
                      style={{
                        left: folderPanelCoordinates(group.id).left,
                        top: folderPanelCoordinates(group.id).top,
                      }}
                    >
                      <FolderPanel>
                        <TagList type="global">
                          {group.tags.map((tag) => (
                            <TagItem key={tag.id} onClick={() => handleChange(tag, 'add')}>
                              <TagName color={tag.color}>{tag.name}</TagName>
                              <TagInstances>
                                {tag.tagsDocumentsConnection.totalCount +
                                  tag.tagsNotesConnection.totalCount +
                                  tag.tagsTranscriptionsConnection.totalCount}
                              </TagInstances>
                            </TagItem>
                          ))}
                        </TagList>
                      </FolderPanel>
                    </FolderPanelWrapper>
                  </PortalNew>
                )}
                <GlobalSublistButton
                  onClick={(e) => {
                    e.stopPropagation();
                    // setShowFolderPanelId(showFolderPanelId ? null : group.id);
                  }}
                >
                  <GlobalSublistButtonText>{group.name}</GlobalSublistButtonText>
                  <Icon16.Arrow />
                </GlobalSublistButton>
              </GlobalSublistButtonWrapper>
            ))}

          {!isLoading && !!currentTags.length && (
            <TagList>
              {currentTags.map((tag) => (
                <TagItem key={tag.id} onClick={() => handleChange(tag, 'add')}>
                  <TagName color={tag.color}>{tag.name}</TagName>
                  <TagInstances>
                    {tag.tagsDocumentsConnection.totalCount +
                      tag.tagsNotesConnection.totalCount +
                      tag.tagsTranscriptionsConnection.totalCount}
                  </TagInstances>
                </TagItem>
              ))}
            </TagList>
          )}

          {!!searchText.length && !currentTags.length && !isLoading && (
            <NoOptionsText>No project tags</NoOptionsText>
          )}

          {isLoading && (
            <Spinner>
              <Icon24.AISpinner />
            </Spinner>
          )}

          {!!searchText.length && !hideManageButton && (
            <CreateTagButton onClick={handleCreateTag}>{`Create "${searchText}"`}</CreateTagButton>
          )}
        </TagsContainer>
      </ContentWrapper>
      {!hideManageButton && (
        <ManageTagsButton>
          <Icon20.GotoArrow />
          <Link to={`${match?.url}/tags`}>
            <ManageTagsText>Manage tags</ManageTagsText>
          </Link>
        </ManageTagsButton>
      )}
    </Container>
  );
};

export const AllTagsDropdown = withAllTagsManagement(TagsDropdown);

export default withTagManagement(TagsDropdown);
