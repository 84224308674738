import React from 'react';
export default function NotesIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10.7266 8.89624H22.4341C23.1244 8.89624 23.6841 9.45588 23.6841 10.1462V21.8537C23.6841 22.5441 23.1244 23.1037 22.4341 23.1037H10.7266C10.0362 23.1037 9.47656 22.5441 9.47656 21.8537V10.1462C9.47656 9.45588 10.0362 8.89624 10.7266 8.89624Z"
          stroke="#382152"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M8.72656 14.3181H24.4341"
          stroke="#382152"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M8.72656 18.9397H24.4341"
          stroke="#382152"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M13.3481 8.14624V23.8537"
          stroke="#382152"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(8.58008 8)" />
        </clipPath>
      </defs>
    </svg>
  );
}
