import { useMutation, useApolloClient } from '@apollo/client';
import {
  GENERATE_SUMMARY_BY_TAGS,
  GENERATE_SUMMARY_FROM_NOTES,
  GENERATE_DATA_SUMMARY,
  GENERATE_SUMMARY_TRANSLATION,
} from '../GraphQL/mutations';
import { FETCH_SUMMARY } from '../GraphQL/queries';
import { ID } from '../Models';

type useGenerateSummaryApi = {
  generateSummaryFromNotesApi: (
    dashboardId: string,
    themeIds: string[],
    tagIds: string[],
    templateId: string,
    length: number
  ) => any;
  generateSummaryByTagsApi: (
    tagIds: ID[],
    dashboardId: string,
    templateId: string,
    length: number
  ) => any;
  generateDataSummary: (options: {
    quotes: string[];
    transcriptId?: string;
    documentId?: string;
    templateId: string;
    length?: number;
  }) => any;
  generateSummaryTranslation: (options: {
    content: string;
    language: string;
    sourceSummaryId: string;
  }) => any;
  fetchSummary: (
    summaryId: string
  ) => Promise<{ content?: string; title?: string; status: string; id: string }>;
};

export const useGenerateSummary = (): useGenerateSummaryApi => {
  const client = useApolloClient();

  const [createMutationGenerateFromNotes] = useMutation(GENERATE_SUMMARY_FROM_NOTES);
  const [createMutationByTags] = useMutation(GENERATE_SUMMARY_BY_TAGS);
  const [createDataSummaryMutation] = useMutation(GENERATE_DATA_SUMMARY);
  const [createSummaryTranslation] = useMutation(GENERATE_SUMMARY_TRANSLATION);

  const generateSummaryTranslation = async ({
    content,
    language,
    sourceSummaryId,
  }: {
    content: string;
    language: string;
    sourceSummaryId: string;
  }) => {
    const res = await createSummaryTranslation({
      variables: {
        content,
        language,
        sourceSummaryId,
      },
    });
    return res?.data?.translateSummary;
  };

  const generateDataSummary = async ({
    quotes,
    transcriptId,
    documentId,
    templateId,
    length,
  }: {
    quotes: string[];
    transcriptId?: string;
    documentId?: string;
    templateId: string;
    length?: number;
  }) => {
    const res = await createDataSummaryMutation({
      variables: {
        quotes,
        transcriptId,
        documentId,
        templateId,
        length,
      },
    });
    return res?.data?.summarizeData;
  };

  const generateSummaryFromNotesApi = async (
    dashboardId: string,
    themeIds: string[],
    tagIds: string[],
    templateId: string,
    length: number
  ) => {
    const res = await createMutationGenerateFromNotes({
      variables: {
        dashboardId,
        themeIds,
        tagIds,
        templateId,
        length,
      },
    });
    return res?.data?.summarizeNotes;
  };

  const generateSummaryByTagsApi = async (
    tagIds: ID[],
    dashboardId: string,
    templateId: string,
    length: number
  ) => {
    const res = await createMutationByTags({
      variables: {
        tagIds,
        dashboardId,
        templateId,
        length,
      },
    });
    return res?.data?.summarizeTags;
  };

  const fetchSummary = async (
    summaryId: string
  ): Promise<{ content?: string; title?: string; status: string; id: string }> => {
    const {
      data: { summary },
    } = await client.query({
      query: FETCH_SUMMARY,
      variables: { id: summaryId },
      fetchPolicy: 'network-only',
    });
    return summary;
  };

  return {
    generateSummaryFromNotesApi,
    generateSummaryByTagsApi,
    generateDataSummary,
    generateSummaryTranslation,
    fetchSummary,
  };
};
