import React from 'react';

import { Label, LabelIconWrapper } from './styles';
import { Icon24 } from '../../../../Icons/Icon';
import { NodeViewWrapper } from '@tiptap/react';

export const TranscriptLabelComponent = (props: any) => {
  return (
    <NodeViewWrapper contentEditable={false}>
      <Label>
        <LabelIconWrapper>
          <Icon24.Transcript />
        </LabelIconWrapper>
        Transcript
      </Label>
    </NodeViewWrapper>
  );
};

export default TranscriptLabelComponent;
