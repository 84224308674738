import { TagWithDetails } from '../../Models';

const STOP_WORDS = ['with', 'or', 'and', 'of', 'to', 'at', 'the', 'a', 'is', 'are'];

type FreqMap = {
  [key: string]: number;
};

type FreqArr = [string, number];

type TagWithKeywords = {
  tag: TagWithDetails;
  keywords: string[];
};

type TextWithTags = {
  text: string;
  tags: TagWithDetails[];
};

const getTopFrequentWords = (words: string[]): string[] => {
  const freqMap: FreqMap = {};
  words.forEach(function (w) {
    if (!freqMap[w]) {
      freqMap[w] = 0;
    }
    freqMap[w] += 1;
  });
  const sortable: FreqArr[] = [];
  for (const word in freqMap) {
    sortable.push([word, freqMap[word]]);
  }

  sortable.sort((a, b) => b[1] - a[1]);
  return sortable.slice(0, sortable.length >= 5 ? 5 : sortable.length).map((item) => item[0]);
};

const getHighlightContents = (document: any, highlights: any): TextWithTags[] => {
  if (!document.content) return [];
  if (document.type === 'highlightTag') {
    return [
      {
        text: document.content[0].text,
        tags:
          highlights.find((highlight: any) => highlight.entityId === document.attrs.id)?.tagsList ||
          [],
      },
    ];
  }
  if (!document.content.reduce) return [];

  return document.content.reduce((sum: any[], block: any) => {
    return sum.concat(getHighlightContents(block, highlights));
  }, []);
};

const getTagsWithKeywords = (textsWithTags: TextWithTags[]): TagWithKeywords[] => {
  const tagsWithKeywords: TagWithKeywords[] = [];

  textsWithTags.forEach((textWithTag) => {
    if (!textWithTag.tags || !textWithTag.tags.length) return;
    textWithTag.tags.forEach((tag) => {
      const existingTagWithTextIndex = tagsWithKeywords.findIndex((tagWithText) => {
        return tagWithText.tag.id === tag.id;
      });

      if (existingTagWithTextIndex !== -1) {
        tagsWithKeywords[existingTagWithTextIndex] = {
          ...tagsWithKeywords[existingTagWithTextIndex],
          keywords: tagsWithKeywords[existingTagWithTextIndex].keywords.concat(
            textWithTag.text
              .trim()
              .toLowerCase()
              .split(/[\s,.!?:-]+/)
              .filter((str: string) => str.length && !STOP_WORDS.includes(str))
          ),
        };
      } else {
        tagsWithKeywords.push({
          tag,
          keywords: textWithTag.text
            .trim()
            .toLowerCase()
            .split(/[\s,.!?:-]+/)
            .filter((str: string) => str.length && !STOP_WORDS.includes(str)),
        });
      }
    });
  });

  return tagsWithKeywords.map((item) => ({
    ...item,
    keywords: getTopFrequentWords(item.keywords),
  }));
};

const getKeywordsCloseness = (
  currentKeywords: string[],
  tagKeywords: string[],
  defaultKeywords: string[]
): number => {
  let counter = 0;
  currentKeywords.forEach((keyword) => {
    const matchTextIndex = tagKeywords.findIndex((tagKeyword) => tagKeyword === keyword);
    const matchKeywordIndex = defaultKeywords.findIndex(
      (defaultKeyword) => defaultKeyword === keyword
    );

    if (matchTextIndex !== -1) {
      counter += tagKeywords.length - matchTextIndex;
    }

    if (matchKeywordIndex !== -1) {
      counter += (defaultKeywords.length - matchKeywordIndex) * 2;
    }
  });
  return counter;
};

type TagWithCloseness = [TagWithDetails, number];

const sortTags = (
  tags: TagWithDetails[],
  highlights: any,
  currentText: string,
  documents: any
): TagWithDetails[] => {
  const currentKeywords = currentText
    .trim()
    .toLowerCase()
    .split(/[\s,.!?:-]+/)
    .filter((str: string) => str.length && !STOP_WORDS.includes(str));
  if (!currentKeywords.length) return tags;

  const contentsWithTags = getHighlightContents(
    { content: documents.filter((document: any) => document !== null && document !== undefined) },
    highlights
  );
  const tagsWithKeywords = getTagsWithKeywords(contentsWithTags);

  const sortableTags: TagWithCloseness[] = tags.map((tag) => {
    const tagWithKeywords = tagsWithKeywords.find(
      (tagWithKeywords) => tagWithKeywords.tag.id === tag.id
    );
    return [
      tag,
      getKeywordsCloseness(
        currentKeywords,
        tagWithKeywords ? tagWithKeywords.keywords : [],
        tag.keywords ? tag.keywords.split(',').map((item) => item.trim().toLowerCase()) : []
      ),
    ];
  });

  const resArr = sortableTags
    .sort((a, b) => b[1] - a[1])
    .filter((item) => item[1] !== 0)
    .map((item) => item[0]);
  return resArr.slice(0, resArr.length >= 2 ? 2 : resArr.length);
};

export default sortTags;
