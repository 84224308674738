import React, { FC } from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Icon: FC<Props> = ({ children, className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

const Tags: FC<{ className: string }> = ({ className }) => {
  return (
    <Icon className={className}>
      <path
        d="M5.55555 5.55555H5.56443M5.55555 2H9.99998C10.455 1.99999 10.9099 2.17355 11.257 2.5207L17.4793 8.74291C18.1736 9.43713 18.1736 10.5628 17.4793 11.257L11.257 17.4792C10.5628 18.1736 9.43713 18.1736 8.74291 17.4792L2.5207 11.257C2.17356 10.9099 2 10.4549 2 9.99998V5.55555C2 3.59187 3.59187 2 5.55555 2Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const Chevron: FC = () => {
  return (
    <Icon>
      <path
        d="M4 11.1429L7.42857 14.5714L16 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const GotoArrow: FC = () => {
  return (
    <Icon>
      <path
        d="M10.8995 3.82844H16.5563M16.5563 3.82844V9.4853M16.5563 3.82844L3.82843 16.5564"
        stroke="#3B2651"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default { Tags, Chevron, GotoArrow };
