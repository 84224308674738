import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const EndDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const EndDateText = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-right: 10px;
`;

export const TextWithBackground = styled.div<{ type: 'date' | 'daysLeft' | 'expired' }>`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  background-color: ${({ type }) => {
    if (type === 'date') return 'rgba(215, 211, 220, 1)';
    if (type === 'daysLeft') return '#D2CEF8';
    if (type === 'expired') return '#FAB6B6';
  }};
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
`;

export const GrayText = styled.div`
  color: rgba(59, 38, 81, 0.4);
`;

export const ContactUsText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
