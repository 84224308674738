/* eslint-disable @typescript-eslint/no-empty-function */
import { nanoid } from 'nanoid';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentDashboardContext } from '../Hooks/useBoards';
import { useCreateInsight } from '../Hooks/useInsights';
import { useGenerateSummary } from '../Hooks/useSummary';
import { useIntercom } from 'react-use-intercom';
import { ID } from '../Models';
import { useLocalStorage, useInterval } from 'usehooks-ts';

export type InsightGenerationResult = {
  title: string;
  content: string;
};

type GenerationSettings = {
  outputSize: number;
  templateId: string;
  dashboardId?: string;
  onFinish?: (result: InsightGenerationResult) => void;
};

type GenerationData = {
  summaryId: string;
  themeIds?: string[];
  tagIds?: ID[];
};

interface InsightGenerationContextProps {
  generating: boolean;
  generationModalOpen: boolean;
  setGenerating(status: boolean): void;
  setGenerationModalOpen(status: boolean): void;
  generateInsightFromNotes(
    themeIds: string[],
    tagIds: string[],
    settings?: GenerationSettings
  ): void;
  generateInsightByTags(tagIds: ID[], settings?: GenerationSettings): void;
}

export const InsightGenerationContext = React.createContext<InsightGenerationContextProps>({
  generating: false,
  generationModalOpen: false,
  setGenerating: (status: boolean) => {},
  setGenerationModalOpen: (status: boolean) => {},
  generateInsightFromNotes: async (
    themeIds: string[],
    tagIds: string[],
    settings?: GenerationSettings
  ) => {},

  generateInsightByTags: async (tagIds: ID[], settings?: GenerationSettings) => {},
});

export const InsightGenerationContextProvider: FC = ({ children }) => {
  const [generating, setGenerating] = useState(false);
  const [generationModalOpen, setGenerationModalOpen] = useState(false);
  const [customOnFinish, setCustomOnFinish] = useState<
    ((result: InsightGenerationResult) => void) | null
  >();
  // const [currentGenerationData, setCurrentGenerationData] = useLocalStorage<GenerationData | null>(
  //   'ls_summary_data',
  //   null
  // );
  // TODO: come up with better solution for persisting the summary id, localstorage causes multiple tabs to run into interval check.
  const [currentGenerationData, setCurrentGenerationData] = useState<GenerationData | null>(null);

  const {
    generateSummaryFromNotesApi,
    generateSummaryByTagsApi,

    fetchSummary,
  } = useGenerateSummary();
  const [createInsightMutation] = useCreateInsight();
  const history = useHistory();
  const dashboardId = useContext(CurrentDashboardContext);
  const intercom = useIntercom();

  const saveSummary = async (
    summary: string,
    title: string,
    options: { themeIds?: string[]; tagIds?: ID[] }
  ) => {
    if (!dashboardId) return;
    const clientId = nanoid(10);

    const { themeIds, tagIds } = options;

    await createInsightMutation({
      dashboardId,
      clientId,
      themeIds,
      tagIds,
      title: title.trim(),
      summary: summary.trim(),
    });
    history.push('/projects/' + dashboardId + '/notes/insights/' + clientId);
  };

  useInterval(
    async () => {
      if (!currentGenerationData) return;
      const { summaryId, tagIds, themeIds } = currentGenerationData;

      const data = await fetchSummary(summaryId);

      if (data.status === 'ready') {
        setGenerating(false);
        customOnFinish
          ? customOnFinish({ content: data.content || '', title: data.title || '' })
          : saveSummary(data.content || '', data.title || '', { themeIds, tagIds });
        setCurrentGenerationData(null);
        setCustomOnFinish(null);
      } else if (data.status === 'error') {
        setGenerating(false);
        setCurrentGenerationData(null);
        intercom.showNewMessage('Hi, I am having trouble generating insight. Please help me out.');
      }
    },
    // Delay in milliseconds or null to stop it
    currentGenerationData && currentGenerationData.summaryId ? 3000 : null
  );

  useEffect(() => {
    if (!currentGenerationData) return;
    // history.push('/projects/' + dashboardId + '/notes');
    setGenerating(true);
  }, []);

  const generateInsightFromNotes = async (
    themeIds: string[],
    tagIds: string[],
    settings: GenerationSettings
  ) => {
    const chosenDasbhoardId = settings.dashboardId || dashboardId;
    if (!chosenDasbhoardId) return;
    setGenerating(true);
    !settings.onFinish && dashboardId && history.push('/projects/' + dashboardId + '/notes');

    const { summaryId } = await generateSummaryFromNotesApi(
      chosenDasbhoardId,
      themeIds,
      tagIds,
      settings.templateId,
      settings?.outputSize || 900
    );

    if (!summaryId) return setGenerating(false); // TODO: handle error and show error message to the user!

    settings.onFinish && setCustomOnFinish(() => settings.onFinish);
    setCurrentGenerationData({ summaryId, themeIds, tagIds });
  };

  const generateInsightByTags = async (tagIds: ID[], settings: GenerationSettings) => {
    if (!dashboardId) return;
    setGenerating(true);
    dashboardId && history.push('/projects/' + dashboardId + '/notes');
    const { summaryId } = await generateSummaryByTagsApi(
      tagIds,
      dashboardId,
      settings.templateId,
      settings?.outputSize || 900
    );

    if (!summaryId) return setGenerating(false); // TODO: handle error and show error message to the user!

    setCurrentGenerationData({ summaryId, tagIds });
  };

  return (
    <InsightGenerationContext.Provider
      value={{
        generating,
        generationModalOpen,
        setGenerating: (status: boolean) => setGenerating(status),
        setGenerationModalOpen: (status: boolean) => setGenerationModalOpen(status),
        generateInsightFromNotes,
        generateInsightByTags,
      }}
    >
      {children}
    </InsightGenerationContext.Provider>
  );
};
