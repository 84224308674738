import React from 'react';

export default function PointerIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8182 10.8182L7.54542 19L1 1L19 7.54545L10.8182 10.8182ZM10.8182 10.8182L19 19"
        stroke="#3B2651"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
