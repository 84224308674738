import Button from './Button';
import React, { PropsWithChildren } from 'react';
import useStickyState from '../Hooks/useStickyState';

type WellProps = {
  wellKey: string;
};

export default function Well({ wellKey, children }: PropsWithChildren<WellProps>) {
  const [dismissed, setDismissed] = useStickyState(false, wellKey);

  if (dismissed) {
    return null;
  }

  return (
    <div className="bg-white font-md rounded-md mx-10 mb-2 mt-5">
      <div className={'bg-secondary-purple-light rounded-md shadow-sm p-5'}>
        {children}
        <div className={'pt-3'}>
          <Button type={'secondary'} onClick={() => setDismissed(true)}>
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  );
}
