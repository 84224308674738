import styled from 'styled-components';

export const MenuButton = styled.div<{ show: boolean; neverShow: boolean }>`
  position: absolute;
  top: 5px;
  right: 5px;
  display: ${({ show, neverShow }) => (show && !neverShow ? 'flex' : 'none')};
  cursor: pointer;
`;

export const Container = styled.div<{ neverShowMenuButton: boolean }>`
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ neverShowMenuButton }) =>
    !neverShowMenuButton &&
    `
  &:hover ${MenuButton} { 
    display: flex; 
  }`}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const UserName = styled.div`
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  margin: 0 5px;
`;

export const DateText = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const Content = styled.div`
  margin-bottom: 10px;
`;

export const LikesContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
`;

export const LikeIcon = styled.div<{ isActive: boolean }>`
  ${({ isActive }) => isActive && `color: #35224F;`}
`;

export const LikesCount = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
`;

export const MenuPanel = styled.div<{ show: boolean }>`
  position: absolute;
  top: 30px;
  right: 0;
  white-space: nowrap;
  display: none;
  ${({ show }) => show && `display: block;`}

  border-radius: 3px;
  border: 1px solid var(--Light-Purple-Gray, #d7d3dc);
  background: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const MenuPanelItem = styled.div<{ itemColor?: string }>`
  width: 100%;
  padding: 6px 40px 6px 10px;
  cursor: pointer;
  user-select: none;

  ${({ itemColor }) => itemColor === 'red' && `color: #EA4335;`}

  &:hover {
    background: #f8f8f8;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 8px;
`;

export const LikesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const InputButtons = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.15s ease 0s;
`;

export const ButtonWrapper = styled.div`
  margin-right: 5px;
`;
