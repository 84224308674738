import React from 'react';

export default function DropdownMenuIcon() {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 4.42857L4.42857 1L7.85714 4.42857M7.85714 9.57143L4.42857 13L1 9.57143"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
