import React, { useContext } from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import InsightsListView from '../Components/InsightsListView';
import InsightsCardView from '../Components/InsightsCardView';
import Loader from '../Components/Loader';
import {
  useFetchInsights,
  useCreateInsight,
  useFetchInsightByClientId,
  useUpdateInsightByClientId,
} from '../Hooks/useInsights';
import InsightEditor from './InsightEditor';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  EmptyStateText,
  EmptyState,
  EmptyStateContainer,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import insightsEmptyImage from '../assets/empty-states/insights.png';
import usePermissions from '../Hooks/usePermissions';
import { InsightGenerationContext } from '../Context/InsightGenerationContext';
import AILoadingState from './AILoadingState';
import { Icon24 } from '../Icons/Icon';

type InsightsPanelProps = {
  dashboardId: string;
  width: number;
  expanded?: boolean;
};

const InsightsPanel: React.FC<InsightsPanelProps> = ({ dashboardId, width, expanded }) => {
  return (
    <Switch>
      <Route
        exact
        path="/projects/:dashboardId/notes/insights/:insightId"
        render={({ match }) => {
          return (
            <EditorPanel clientId={match.params.insightId} width={width} expanded={expanded} />
          );
        }}
      />
      <Route>
        <MultiViewPanel dashboardId={dashboardId} width={width} expanded={expanded} />
      </Route>
    </Switch>
  );
};

type MultiViewPanelProps = {
  dashboardId: string;
  width: number;
  expanded?: boolean;
};

function MultiViewPanel({ dashboardId, expanded }: MultiViewPanelProps): JSX.Element {
  const { loading, data } = useFetchInsights(dashboardId);
  const history = useHistory();
  const [createInsightMutation] = useCreateInsight();
  const { canCreateInsights } = usePermissions();
  const { generating, setGenerationModalOpen } = useContext(InsightGenerationContext);

  async function handleCreate(title?: string, summary?: string) {
    const clientId = nanoid(10);
    await createInsightMutation({ dashboardId, clientId, title, summary });
    history.push('/projects/' + dashboardId + '/notes/insights/' + clientId);
  }

  if (loading) return <Loader />;
  if (generating) return <AILoadingState withHeader />;

  if (!data?.length) {
    return (
      <EmptyStateContainer>
        <EmptyState>
          <HeroImage src={insightsEmptyImage}></HeroImage>
          <EmptyStateTitle>Create insights to share findings</EmptyStateTitle>
          <EmptyStateText>
            Insights are a space to document emerging insights and share research findings.
          </EmptyStateText>
          {canCreateInsights && (
            <>
              <AIinsightButton
                onClick={() => {
                  setGenerationModalOpen(true);
                }}
                className="flex items-center cursor-pointer py-1 px-2 select-none font-medium text-sm whitespace-nowrap"
              >
                <IconWrapper>
                  <Icon24.Insight />
                </IconWrapper>
                <span>New AI Insight</span>
              </AIinsightButton>

              <ScratchButton onClick={() => handleCreate()}>or, create from scratch</ScratchButton>
            </>
          )}
        </EmptyState>
      </EmptyStateContainer>
    );
  }

  if (expanded) {
    return <InsightsCardView insights={data} baseUrl={`/projects/${dashboardId}/notes/insights`} />;
  }

  return (
    <>
      {canCreateInsights && (
        <CreateControl onClick={() => handleCreate()}>Create a new insight...</CreateControl>
      )}
      <InsightsListView insights={data} dashboardId={dashboardId} />
    </>
  );
}

const CreateControl = styled.div`
  cursor: pointer;
  margin: 10px;
  width: 180px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(56, 33, 82, 0.4);
`;

const IconWrapper = styled.div`
  margin-right: 4px;
`;

const AIinsightButton = styled.div`
  margin-top: 16px;
  border-radius: 6px;
  border: 1px solid var(--sticky-purple, #d8c0f8);
  background: linear-gradient(90deg, #d1deff 0%, #d8c0f8 100%);
  /* Card Shadow */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  color: var(--brand-purple, #3b2651);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: 0.08px;
`;

const ScratchButton = styled.div`
  display: flex;
  height: 38px;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  border-radius: 6px;
  border: 1px solid var(--light-purple-gray, #d7d3dc);
  background: #fff;
  /* Card Shadow */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  color: var(--brand-purple, #3b2651);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: 0.08px;
  cursor: pointer;
`;

type EditorPanelProps = {
  clientId: string;
  width: number;
  expanded?: boolean;
};

function EditorPanel({ clientId, width, expanded }: EditorPanelProps): JSX.Element {
  const { loading, data } = useFetchInsightByClientId(clientId);

  const [updateInsightMutation] = useUpdateInsightByClientId();

  const updateInsight = async (input: any) => {
    await updateInsightMutation(clientId, input);
  };

  if (loading) return <Loader />;

  return (
    <InsightEditor
      insight={data}
      onSave={updateInsight}
      width={expanded ? 800 : width}
      isInsightsPanel
      expanded={expanded}
      className={`insight-editor_${expanded ? 'expanded' : 'small'}`}
    />
  );
}

export default InsightsPanel;
