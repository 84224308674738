import React, { useState, useRef } from 'react';
import usePermissions from '../Hooks/usePermissions';

type Props = {
  onSave: (text: string) => void;
  text?: string;
};

export default function ProjectTitle({ onSave, text = '' }: Props): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { canEditProjects } = usePermissions();

  const handleSave = async () => {
    await onSave(inputRef?.current?.value || '');
    setIsFocused(false);
  };

  return (
    <>
      {!isFocused && text && (
        <div
          onClick={() => {
            if (!canEditProjects) return;
            setIsFocused(true);
          }}
          className="my-2"
        >
          {text}
        </div>
      )}
      {(isFocused || !text) && (
        <div className="my-0.5">
          <input
            ref={inputRef}
            defaultValue={text}
            autoFocus={isFocused}
            onBlur={async () => {
              await handleSave();
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await handleSave();
              }
            }}
            className="appearance-none w-full text-primary-purple font-medium py-2 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Give your project a name"
            readOnly={!canEditProjects}
          />
        </div>
      )}
    </>
  );
}
