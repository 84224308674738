import { useQuery, useMutation } from '@apollo/client';
import { CREATE_PROJECT_INFO, UPDATE_PROJECT_INFO } from '../GraphQL/mutations';
import { FETCH_PROJECT_INFO_BY_DASHBOARD_ID } from '../GraphQL/queries';
import { ID } from '../Models';

export default function useProjectInfo(dashboardId: ID) {
  const { loading, data } = useQuery(FETCH_PROJECT_INFO_BY_DASHBOARD_ID, {
    variables: { dashboardId },
  });
  const [createProjectInfoMutation] = useMutation(CREATE_PROJECT_INFO, {
    refetchQueries: [
      {
        query: FETCH_PROJECT_INFO_BY_DASHBOARD_ID,
        variables: { dashboardId },
      },
    ],
  });
  const [updateProjectInfoMutation] = useMutation(UPDATE_PROJECT_INFO, {
    refetchQueries: [
      {
        query: FETCH_PROJECT_INFO_BY_DASHBOARD_ID,
        variables: { dashboardId },
      },
    ],
  });
  const projectInfo = data?.projectInfoByDashboardId;

  function fetchProjectInfo() {
    return {
      loading,
      projectInfo,
    };
  }

  async function createProjectInfo(input: any) {
    const {
      data: {
        createProjectInfo: { projectInfo },
      },
    } = await createProjectInfoMutation({
      variables: {
        input: {
          dashboardId,
          ...input,
        },
      },
    });
    return projectInfo;
  }

  async function updateProjectInfo(id: ID, input: any) {
    const {
      data: {
        updateProjectInfo: { projectInfo },
      },
    } = await updateProjectInfoMutation({
      variables: {
        id,
        input,
      },
    });
    return projectInfo;
  }

  async function upsertProjectInfo(input: any) {
    if (!projectInfo) {
      await createProjectInfo(input);
      return;
    }
    await updateProjectInfo(projectInfo.id, input);
  }

  return {
    createProjectInfo,
    fetchProjectInfo,
    upsertProjectInfo,
  };
}
