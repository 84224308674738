import React, { useEffect, useState } from 'react';
import { Icon24 } from '../../Icons/Icon';

import { Container, SearchIcon, Placeholder, Wrapper } from './styles';
import SearchPanel from './SearchPanel';
import useDisableBackgroundScroll from '../../Hooks/useDisableBackgroundScroll';

const GlobalSearch = (): JSX.Element => {
  const [showSearchPanel, setShowSearchPanel] = useState(false);
  useDisableBackgroundScroll(showSearchPanel);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowSearchPanel(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Wrapper>
      <Container onClick={() => setShowSearchPanel(true)} id="global-search-container">
        <SearchIcon>
          <Icon24.Search />
        </SearchIcon>
        <Placeholder>Search your workspace</Placeholder>
      </Container>
      {showSearchPanel && <SearchPanel onClose={() => setShowSearchPanel(false)} />}
    </Wrapper>
  );
};

export default GlobalSearch;
