import React, { FC, useContext, useMemo, useState } from 'react';
import {
  ButtonWrapper,
  Buttons,
  CloseButton,
  Container,
  Content,
  CountOfWords,
  DraftFromInsight,
  DraftFromInsightWrapper,
  DropdownWrapper,
  GenerationButtonWrapper,
  GenerationNotificationText,
  Header,
  HeaderSubtitle,
  HeaderTitle,
  IconWrapper,
  InputWrapper,
  LeftSide,
  LoadingState,
  LoadingStateText,
  NotificationBlock,
  NotificationText,
  Overlay,
  PromptGenerationLoadingState,
  RightSide,
  Sample,
  Samples,
  SelectLabel,
  SelectedIcon,
  Selects,
  Spinner,
  Wrapper,
} from './styles';
import { Icon16, Icon24 } from '../../Icons/Icon';
import Input from '../Input/Input';
import Button from '../Button';
import Dropdown from '../Dropdown';
import useDisableBackgroundScroll from '../../Hooks/useDisableBackgroundScroll';
import { useParams } from 'react-router-dom';
import { CoverPhotoGenerationContext, TSample } from '../../Context/CoverPhotoGenerationContext';
import { useLocalStorage } from 'usehooks-ts';
import { useOrganization } from '../../Hooks/useOrganization';

interface CoverPhotoGenerationModalProps {
  insightContent: string;
  onClose: () => void;
  onChangeCover: (input: any) => void;
}

const styleOptions = [
  {
    value: 'photographic',
    label: 'Photography',
  },
  {
    value: 'digital-art',
    label: 'Digital',
  },
  {
    value: 'isometric',
    label: 'Isometric',
  },
  {
    value: 'line-art',
    label: 'Line art',
  },
  {
    value: 'low-poly',
    label: 'Low poly',
  },
  {
    value: 'neon-punk',
    label: 'Neon punk',
  },
  {
    value: 'origami',
    label: 'Origami',
  },
  {
    value: 'pixel-art',
    label: 'Pixel art',
  },
  {
    value: 'tile-texture',
    label: 'Tile texture',
  },
];

const CoverPhotoGenerationModal: FC<CoverPhotoGenerationModalProps> = ({
  insightContent,
  onChangeCover,
  onClose,
}) => {
  const [, org] = useOrganization();
  const [currentImage, setCurrentImage] = useState<TSample>();
  const [currentStyleOption, setCurrentStyleOption] = useLocalStorage(
    'coverStyleOption',
    styleOptions[0]
  );
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const {
    prompt,
    generatedSamples,
    isCoverGenerating,
    isPromptGenerating,
    setPrompt,
    generatePromptByInsight,
    generateCoverPhotos,
  } = useContext(CoverPhotoGenerationContext);
  useDisableBackgroundScroll(true);

  const handleApplyImage = async () => {
    if (!currentImage) return;
    onChangeCover({ customCover: currentImage.src });
    onClose();
  };

  const countOfWords = useMemo(() => {
    return prompt.split(' ').filter((item) => !!item.length).length;
  }, [prompt]);
  return (
    <Wrapper>
      <Overlay onClick={onClose} />
      <Container>
        <Content>
          <LeftSide>
            <Header>
              <HeaderTitle>Create Images with AI</HeaderTitle>
              <HeaderSubtitle>
                Bring this Insight to life with imagery. Create Images from a description with AI.
                Start from scratch or use AI to draft an Image description for you.
              </HeaderSubtitle>
            </Header>
            <Selects>
              <DropdownWrapper>
                <SelectLabel>Style</SelectLabel>

                <Dropdown
                  options={styleOptions}
                  currentOption={currentStyleOption}
                  onChange={(o) => {
                    setCurrentStyleOption(o);
                  }}
                  menuWidth={150}
                  maxHeight={200}
                />
              </DropdownWrapper>
            </Selects>
            <InputWrapper>
              {isPromptGenerating && (
                <PromptGenerationLoadingState>
                  <Spinner>
                    <Icon24.AISpinner />
                  </Spinner>
                  <LoadingStateText>Bringing your insight to life...</LoadingStateText>
                </PromptGenerationLoadingState>
              )}
              <Input
                placeholder={
                  !isPromptGenerating
                    ? 'Type instructions here to create images from scratch. Include details such as the content of the image, the style, mood, etc.'
                    : ''
                }
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                disabled={isPromptGenerating}
                textarea
                customBottomPadding={45}
              />
              <DraftFromInsightWrapper>
                <DraftFromInsight
                  onClick={() => generatePromptByInsight(insightContent, currentStyleOption.label)}
                >
                  <IconWrapper>
                    <Icon24.Insight />
                  </IconWrapper>
                  Use AI to draft a description
                </DraftFromInsight>
              </DraftFromInsightWrapper>
            </InputWrapper>
            <CountOfWords>Min {countOfWords}/200</CountOfWords>
            <GenerationButtonWrapper>
              <GenerationNotificationText>
                This will use 1 of your available {org.aiCredits} credits
              </GenerationNotificationText>
              <Button
                type="secondary"
                disabled={!countOfWords || isCoverGenerating}
                onClick={() => generateCoverPhotos(dashboardId, currentStyleOption.value, prompt)}
              >
                <Icon24.Insight />{' '}
                {isCoverGenerating
                  ? 'Creating Images...'
                  : generatedSamples.length
                  ? 'Create Images'
                  : 'Create Images'}
              </Button>
            </GenerationButtonWrapper>
          </LeftSide>
          <RightSide>
            <CloseButton onClick={onClose}>
              <Icon16.CloseBig />
            </CloseButton>
            <Samples>
              {generatedSamples.map((sample, index) => (
                <Sample
                  key={sample.src || index}
                  src={sample.src}
                  onClick={() => sample.src && setCurrentImage(sample)}
                  disabled={!!currentImage && currentImage !== sample}
                  selected={currentImage === sample}
                  isGenerating={isCoverGenerating}
                >
                  <SelectedIcon>
                    <Icon16.Tick />
                  </SelectedIcon>
                </Sample>
              ))}
              {generatedSamples.length === 0 &&
                Array(4)
                  .fill('')
                  .map((item, index) => <Sample key={index} isGenerating={isCoverGenerating} />)}
            </Samples>
          </RightSide>
        </Content>
        <Buttons>
          <ButtonWrapper>
            <Button onClick={handleApplyImage} disabled={!currentImage}>
              Use Image
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button type="secondary" onClick={onClose}>
              Cancel
            </Button>
          </ButtonWrapper>
        </Buttons>
      </Container>
    </Wrapper>
  );
};

export default CoverPhotoGenerationModal;
