import { Checkbox } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Icon24 } from '../../Icons/Icon';
import {
  Container,
  Overlay,
  ModalContainer,
  CloseButton,
  Title,
  Text,
  Buttons,
  ConfirmButton,
  CancelButton,
  CheckboxWrapper,
} from './styles';

interface ConfirmationModalProps {
  title: string;
  text: string;
  confirmationText: string;
  cancelText: string;
  checkboxChecked: boolean;
  onCheckboxChange(value: boolean): void;
  onConfirm(): void;
  onCancel(): void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  text,
  confirmationText,
  cancelText,
  checkboxChecked,
  onCheckboxChange,
  onConfirm,
  onCancel,
}) => {
  return (
    <Container>
      <Overlay />
      <ModalContainer>
        <CloseButton onClick={onCancel}>
          <Icon24.Close />
        </CloseButton>
        <Title>{title}</Title>
        <Text>{text}</Text>

        <CheckboxWrapper>
          <Checkbox
            as="div"
            colorScheme="purple"
            isChecked={checkboxChecked}
            onChange={(e) => onCheckboxChange(e.target.checked)}
          >
            Don’t show this message again
          </Checkbox>
        </CheckboxWrapper>

        <Buttons>
          <ConfirmButton onClick={onConfirm}>{confirmationText}</ConfirmButton>
          <CancelButton onClick={onCancel}>{cancelText}</CancelButton>
        </Buttons>
      </ModalContainer>
    </Container>
  );
};

export default ConfirmationModal;
