import React from 'react';

const StickyIcon = (): JSX.Element => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8.75V2.75C16 1.64543 15.1046 0.75 14 0.75H2C0.895431 0.75 0 1.64543 0 2.75V14.75C0 15.8546 0.895432 16.75 2 16.75H7.72953L12.0302 12.7802L16 8.75ZM10.9866 11.7022L13.1557 9.5H9.72953C9.03917 9.5 8.47953 10.0596 8.47953 10.75V14.0163L10.9866 11.7022ZM6.97953 15.25V10.75C6.97953 9.23122 8.21075 8 9.72953 8H14.5V2.75C14.5 2.47386 14.2761 2.25 14 2.25H2C1.72386 2.25 1.5 2.47386 1.5 2.75V14.75C1.5 15.0261 1.72386 15.25 2 15.25H6.97953Z"
      fill="#382152"
    />
  </svg>
);

export default StickyIcon;
