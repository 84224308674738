import React from 'react';
import inviteWidgetBg from '../assets/inviteWidgetBg.png';
import Button from '../Components/Button';
import { useHistory } from 'react-router';

const InviteTeamWidget = ({ className }: React.PropsWithRef<any>): JSX.Element => {
  const history = useHistory();
  return (
    <div
      className={`flex flex-col rounded-md ${className}`}
      style={{
        border: '1px solid rgba(215, 211, 220, 1)',
        boxShadow: '0px 1px 0px #E5E5E5, 2px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div className="flex flex-row m-2">
        <img src={inviteWidgetBg} alt="cover image" className="rounded-t-md w-[116px]" />
      </div>
      <div className="flex flex-col pb-5 pt-2 px-2">
        <div className="text-lg font-secondary font-semibold mb-2">Invite your team</div>
        <div className="font-medium  mb-4">
          Learning is better together. <br />
          Invite people to collaborate in Notably.
        </div>
        <div>
          <Button onClick={() => history.push('/settings/users')} type={'secondary'}>
            Invite team
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InviteTeamWidget;
