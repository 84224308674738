import { gql } from '@apollo/client/core';
import {
  BOARD_CORE_FIELDS,
  FILE_CORE_FIELDS,
  NOTE_CORE_FIELDS,
  THEME_CORE_FIELDS,
  TRANSCRIPT_CORE_FIELDS,
  PROJECT_INFO_CORE_FIELDS,
  PROJECT_INSIGHT_CORE_FIELDS,
  DOCUMENT_CORE_FIELDS,
  PARTICIPANT_CORE_FIELDS,
  USER_CORE_FIELDS,
  HIGHLIGHT_CORE_FIELDS,
} from './fragments';

const RUN_SENTIMENT_ANALYSIS = gql`
  mutation runSentimentAnalysis($noteId: BigInt!) {
    runSentimentAnalysis(input: { noteId: $noteId }) {
      score
    }
  }
`;

const CREATE_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  mutation createDashboard($input: DashboardInput!) {
    createDashboard(input: { dashboard: $input }) {
      dashboard {
        ...BoardCoreFields
      }
    }
  }
`;

const UPDATE_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  mutation updateDashboard($id: BigInt!, $input: DashboardPatch!) {
    updateDashboard(input: { id: $id, patch: $input }) {
      dashboard {
        ...BoardCoreFields
      }
    }
  }
`;

const DELETE_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  mutation deleteDashboard($id: BigInt!) {
    deleteDashboard(input: { id: $id }) {
      dashboard {
        ...BoardCoreFields
      }
    }
  }
`;

const CREATE_NOTE = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation createNote($input: NoteInput!) {
    createNote(input: { note: $input }) {
      note {
        ...NoteCoreFields
        theme {
          ...ThemeCoreFields
          notes {
            ...NoteCoreFields
          }
        }
      }
    }
  }
`;

const DELETE_NOTE = gql`
  ${NOTE_CORE_FIELDS}
  mutation deleteNote($id: BigInt!) {
    deleteNote(input: { id: $id }) {
      note {
        ...NoteCoreFields
      }
    }
  }
`;

const DELETE_THEME = gql`
  mutation deleteTheme($id: BigInt!) {
    deleteTheme(input: { id: $id }) {
      theme {
        id
      }
    }
  }
`;

const UPDATE_NOTE = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation updateNote($id: BigInt!, $input: NotePatch!) {
    updateNote(input: { id: $id, patch: $input }) {
      note {
        ...NoteCoreFields
        theme {
          ...ThemeCoreFields
        }
      }
    }
  }
`;

const CREATE_THEME = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation createTheme($input: ThemeInput!) {
    createTheme(input: { theme: $input }) {
      theme {
        ...ThemeCoreFields
        notes {
          ...NoteCoreFields
        }
      }
    }
  }
`;

const UPDATE_THEME = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  mutation updateTheme($id: BigInt!, $input: ThemePatch!) {
    updateTheme(input: { id: $id, patch: $input }) {
      theme {
        ...ThemeCoreFields
        notes {
          ...NoteCoreFields
        }
      }
    }
  }
`;

const CREATE_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  mutation createTranscription($input: TranscriptionInput!) {
    createTranscription(input: { transcription: $input }) {
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const UPDATE_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  mutation updateTranscript($id: BigInt!, $input: TranscriptionPatch!) {
    updateTranscription(input: { id: $id, patch: $input }) {
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const DELETE_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  mutation deleteTranscription($id: BigInt!) {
    deleteTranscription(input: { id: $id }) {
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const CREATE_SIGNED_S3_URL = gql`
  mutation createSignedS3Url($fileType: String!, $fileName: String!, $isPublic: Boolean) {
    createSignedS3Url(input: { fileType: $fileType, fileName: $fileName, isPublic: $isPublic }) {
      uploadUrl
    }
  }
`;

const CREATE_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation createFile($input: FileInput!) {
    createFile(input: { file: $input }) {
      file {
        ...FileCoreFields
      }
    }
  }
`;

const IMPORT_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation importFile($dashboardId: BigInt!, $name: String!, $importUrl: String!, $status: String) {
    importFile(
      input: {
        dashboardId: $dashboardId
        name: $name
        importUrl: $importUrl
        type: "mp4"
        status: $status
      }
    ) {
      file {
        ...FileCoreFields
      }
      created
    }
  }
`;

const UPDATE_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation updateFile($id: BigInt!, $input: FilePatch!) {
    updateFile(input: { id: $id, patch: $input }) {
      file {
        ...FileCoreFields
      }
    }
  }
`;

const DELETE_FILE = gql`
  ${FILE_CORE_FIELDS}
  mutation deleteFile($id: BigInt!) {
    deleteFile(input: { id: $id }) {
      file {
        ...FileCoreFields
      }
    }
  }
`;

const CREATE_PROJECT_INFO = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  mutation createProjectInfo($input: ProjectInfoInput!) {
    createProjectInfo(input: { projectInfo: $input }) {
      projectInfo {
        ...ProjectInfoCoreFields
      }
    }
  }
`;

const UPDATE_PROJECT_INFO = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  mutation updateProjectInfo($id: BigInt!, $input: ProjectInfoPatch!) {
    updateProjectInfo(input: { id: $id, patch: $input }) {
      projectInfo {
        ...ProjectInfoCoreFields
      }
    }
  }
`;

const REGISTER = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      success
    }
  }
`;

const CREATE_PROJECT_INSIGHT = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  mutation createProjectInsight($input: ProjectInsightInput!) {
    createProjectInsight(input: { projectInsight: $input }) {
      projectInsight {
        ...ProjectInsightCoreFields
      }
    }
  }
`;

const UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  mutation updateProjectInsightByClientId($clientId: String!, $input: ProjectInsightPatch!) {
    updateProjectInsightByClientId(input: { clientId: $clientId, patch: $input }) {
      projectInsight {
        ...ProjectInsightCoreFields
      }
    }
  }
`;

const DELETE_INSIGHT_BY_CLIENT_ID = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  mutation deleteProjectInsightByClientId($clientId: String!) {
    deleteProjectInsightByClientId(input: { clientId: $clientId }) {
      projectInsight {
        ...ProjectInsightCoreFields
      }
    }
  }
`;

const AUTHORIZE_ZOOM = gql`
  mutation authorizeZoom($code: String!, $redirectUri: String!) {
    authorizeZoom(input: { code: $code, redirectUri: $redirectUri }) {
      success
    }
  }
`;

const AUTHORIZE_SLACK = gql`
  mutation authorizeSlack($code: String!, $redirectUri: String!) {
    authorizeSlack(input: { code: $code, redirectUri: $redirectUri }) {
      success
    }
  }
`;

const AUTHORIZE_GOOGLE = gql`
  mutation authorizeGoogle($code: String!, $redirectUri: String!) {
    authorizeGoogle(input: { code: $code, redirectUri: $redirectUri }) {
      success
    }
  }
`;

const AUTHORIZE_ONEDRIVE = gql`
  mutation authorizeOneDrive($code: String!, $redirectUri: String!) {
    authorizeOneDrive(input: { code: $code, redirectUri: $redirectUri }) {
      success
    }
  }
`;

const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($id: BigInt!) {
    deleteIntegration(input: { id: $id }) {
      integration {
        id
      }
    }
  }
`;

const UPDATE_SLACK_WEBHOOK = gql`
  mutation updateSlackWebhook($id: BigInt!, $postNewSummaries: Boolean!) {
    updateSlackWebhook(input: { id: $id, patch: { postNewSummaries: $postNewSummaries } }) {
      slackWebhook {
        id
        channel
        configurationUrl
        teamName
        postNewSummaries
        postNewInsights
      }
    }
  }
`;

const CREATE_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  mutation createDocument($input: DocumentInput!) {
    createDocument(input: { document: $input }) {
      document {
        ...DocumentCoreFields
      }
    }
  }
`;

const UPDATE_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  mutation updateDocument($id: BigInt!, $input: DocumentPatch!) {
    updateDocument(input: { id: $id, patch: $input }) {
      document {
        ...DocumentCoreFields
      }
    }
  }
`;

const DELETE_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  mutation deleteDocument($id: BigInt!) {
    deleteDocument(input: { id: $id }) {
      document {
        ...DocumentCoreFields
      }
    }
  }
`;

const CREATE_LIGHT_DOCUMENT = gql`
  mutation createLightDocument($input: LightDocumentInput!) {
    createLightDocument(input: { lightDocument: $input }) {
      lightDocument {
        id
        content
        participantId
        participant {
          id
          name
        }
        userByCreatedBy {
          id
          name
          email
          picture
        }
        createdAt
      }
    }
  }
`;

const UPDATE_LIGHT_DOCUMENT = gql`
  mutation updateLightDocument($id: BigInt!, $input: LightDocumentPatch!) {
    updateLightDocument(input: { id: $id, patch: $input }) {
      lightDocument {
        id
        content
        participantId
        participant {
          id
          name
        }
        userByCreatedBy {
          id
          name
          email
          picture
        }
        createdAt
      }
    }
  }
`;

const DELETE_LIGHT_DOCUMENT = gql`
  mutation deleteLightDocument($id: BigInt!) {
    deleteLightDocument(input: { id: $id }) {
      lightDocument {
        id
      }
    }
  }
`;

const CREATE_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  mutation createParticipant($input: ParticipantInput!) {
    createParticipant(input: { participant: $input }) {
      participant {
        ...ParticipantCoreFields
      }
    }
  }
`;

const UPDATE_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  mutation updateParticipant($id: BigInt!, $input: ParticipantPatch!) {
    updateParticipant(input: { id: $id, patch: $input }) {
      participant {
        ...ParticipantCoreFields
      }
    }
  }
`;

const DELETE_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  mutation deleteParticipant($id: BigInt!) {
    deleteParticipant(input: { id: $id }) {
      participant {
        ...ParticipantCoreFields
      }
    }
  }
`;

const INVITE_USER = gql`
  mutation inviteUser($email: String!, $role: String) {
    invite(input: { email: $email, role: $role }) {
      success
    }
  }
`;

const UPDATE_USER = gql`
  ${USER_CORE_FIELDS}
  mutation updateUser($id: BigInt!, $input: UserPatch!) {
    updateUser(input: { id: $id, patch: $input }) {
      user {
        ...UserCoreFields
      }
    }
  }
`;

const DELETE_USER = gql`
  ${USER_CORE_FIELDS}
  mutation deleteUser($id: BigInt!) {
    deleteUser(input: { id: $id }) {
      user {
        ...UserCoreFields
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      success
    }
  }
`;

const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($id: String!, $role: String!) {
    updateUserRole(input: { id: $id, role: $role }) {
      success
    }
  }
`;

const CREATE_DASHBOARD_FOLDER = gql`
  mutation createDashboardFolder($name: String) {
    createDashboardFolder(input: { dashboardFolder: { name: $name } }) {
      dashboardFolder {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATE_DASHBOARD_FOLDER = gql`
  mutation updateDashboardFolder($id: BigInt!, $name: String!) {
    updateDashboardFolder(input: { id: $id, patch: { name: $name } }) {
      dashboardFolder {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_DASHBOARD_FOLDER = gql`
  mutation deleteDashboardFolder($id: BigInt!) {
    deleteDashboardFolder(input: { id: $id }) {
      dashboardFolder {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const CREATE_DATA_FOLDER = gql`
  mutation createDataFolder($dashboardId: BigInt!, $name: String) {
    createDataFolder(input: { dataFolder: { name: $name, dashboardId: $dashboardId } }) {
      dataFolder {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATE_DATA_FOLDER = gql`
  mutation updateDataFolder($id: BigInt!, $name: String!) {
    updateDataFolder(input: { id: $id, patch: { name: $name } }) {
      dataFolder {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_DATA_FOLDER = gql`
  mutation deleteDataFolder($id: BigInt!) {
    deleteDataFolder(input: { id: $id }) {
      dataFolder {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword {
    resetPassword {
      url
    }
  }
`;

const CREATE_TEXT_NOTE = gql`
  mutation createTextNote($input: TextNoteInput!) {
    createTextNote(input: { textNote: $input }) {
      textNote {
        id
        styles
        text
        x
        y
        dashboardId
      }
    }
  }
`;

const UPDATE_TEXT_NOTE = gql`
  mutation updateTextNote($id: BigInt!, $input: TextNotePatch!) {
    updateTextNote(input: { id: $id, patch: $input }) {
      textNote {
        id
        styles
        text
        x
        y
        dashboardId
      }
    }
  }
`;

const DELETE_TEXT_NOTE = gql`
  mutation deleteTextNote($id: BigInt!) {
    deleteTextNote(input: { id: $id }) {
      textNote {
        id
        styles
        text
        x
        y
        dashboardId
      }
    }
  }
`;

const CREATE_LINE = gql`
  mutation createLine($input: LineInput!) {
    createLine(input: { line: $input }) {
      line {
        id
        styles
        points
        x
        y
        dashboardId
      }
    }
  }
`;

const UPDATE_LINE = gql`
  mutation updateLine($id: BigInt!, $input: LinePatch!) {
    updateLine(input: { id: $id, patch: $input }) {
      line {
        id
        styles
        points
        x
        y
        dashboardId
      }
    }
  }
`;

const DELETE_LINE = gql`
  mutation deleteLine($id: BigInt!) {
    deleteLine(input: { id: $id }) {
      line {
        id
        styles
        points
        x
        y
        dashboardId
      }
    }
  }
`;

const COPY_DASHBOARD = gql`
  mutation copyProject($dashboardId: BigInt!) {
    copyProject(input: { dashboardId: $dashboardId }) {
      dashboardId
    }
  }
`;

const CREATE_SHAPE = gql`
  mutation createShape($input: ShapeInput!) {
    createShape(input: { shape: $input }) {
      shape {
        id
        styles
        x
        y
        dashboardId
      }
    }
  }
`;

const UPDATE_SHAPE = gql`
  mutation updateShape($id: BigInt!, $input: ShapePatch!) {
    updateShape(input: { id: $id, patch: $input }) {
      shape {
        id
        styles
        x
        y
        dashboardId
      }
    }
  }
`;

const DELETE_SHAPE = gql`
  mutation deleteShape($id: BigInt!) {
    deleteShape(input: { id: $id }) {
      shape {
        id
        styles
        x
        y
        dashboardId
      }
    }
  }
`;

const CREATE_CANVAS_IMAGE = gql`
  mutation createCanvasImage($input: CanvasImageInput!) {
    createCanvasImage(input: { canvasImage: $input }) {
      canvasImage {
        id
        styles
        x
        y
        dashboardId
        s3ImagePath
      }
    }
  }
`;

const UPDATE_CANVAS_IMAGE = gql`
  mutation updateCanvasImage($id: BigInt!, $input: CanvasImagePatch!) {
    updateCanvasImage(input: { id: $id, patch: $input }) {
      canvasImage {
        id
        styles
        x
        y
        dashboardId
        s3ImagePath
      }
    }
  }
`;

const DELETE_CANVAS_IMAGE = gql`
  mutation deleteCanvasImage($id: BigInt!) {
    deleteCanvasImage(input: { id: $id }) {
      canvasImage {
        id
        styles
        x
        y
        dashboardId
      }
    }
  }
`;

const CREATE_TAG = gql`
  mutation createTag($input: TagInput!) {
    createTag(input: { tag: $input }) {
      tag {
        id
        name
        color
        dashboardId
        isGlobal
      }
    }
  }
`;

const UPDATE_TAG = gql`
  mutation updateTag($id: BigInt!, $input: TagPatch!) {
    updateTag(input: { id: $id, patch: $input }) {
      tag {
        id
        name
        color
        dashboardId
        isGlobal
        keywords
        description
      }
    }
  }
`;

const DELETE_TAG = gql`
  mutation deleteTag($id: BigInt!) {
    deleteTag(input: { id: $id }) {
      tag {
        id
        name
        color
        dashboardId
        isGlobal
        keywords
        description
      }
    }
  }
`;

const MARK_AS_TEAM = gql`
  mutation markAsTeam($isTeam: Boolean!) {
    markTeam(input: { isTeam: $isTeam }) {
      success
    }
  }
`;

const MERGE_TAGS = gql`
  mutation mergeTags(
    $name: String!
    $ids: [BigInt]!
    $dashboardId: BigInt
    $groupId: BigInt
    $isGlobal: Boolean
  ) {
    mergeTags(
      input: {
        name: $name
        ids: $ids
        dashboardId: $dashboardId
        groupId: $groupId
        isGlobal: $isGlobal
      }
    ) {
      success
      id
    }
  }
`;

const GENERATE_SUMMARY_FROM_NOTES = gql`
  mutation generateSummaryFromNotes(
    $dashboardId: String!
    $themeIds: [String]!
    $tagIds: [String]!
    $templateId: String
    $length: Int
  ) {
    summarizeNotes(
      input: {
        dashboardId: $dashboardId
        themeIds: $themeIds
        tagIds: $tagIds
        templateId: $templateId
        length: $length
      }
    ) {
      success
      summaryId
      status
    }
  }
`;

const GENERATE_SUMMARY_BY_TAGS = gql`
  mutation generateSummaryByTags(
    $tagIds: [String]!
    $dashboardId: String!
    $templateId: String
    $length: Int
  ) {
    summarizeTags(
      input: {
        tagIds: $tagIds
        dashboardId: $dashboardId
        templateId: $templateId
        length: $length
      }
    ) {
      success
      summaryId
      status
    }
  }
`;

const GENERATE_DATA_SUMMARY = gql`
  mutation generateDataSummary(
    $quotes: [String]!
    $transcriptId: String
    $documentId: String
    $templateId: String
    $length: Int
  ) {
    summarizeData(
      input: {
        quotes: $quotes
        transcriptId: $transcriptId
        documentId: $documentId
        templateId: $templateId
        length: $length
      }
    ) {
      success
      summaryId
      status
    }
  }
`;

const GENERATE_SUMMARY_TRANSLATION = gql`
  mutation generateSummaryTranslation(
    $content: String!
    $language: String!
    $sourceSummaryId: String!
  ) {
    translateSummary(
      input: { content: $content, language: $language, sourceSummaryId: $sourceSummaryId }
    ) {
      success
      summaryId
      status
    }
  }
`;

const MERGE_LOGINS = gql`
  mutation mergeLogins($email: String!) {
    mergeLogins(input: { email: $email }) {
      success
      status
    }
  }
`;

const CREATE_HIGHLIGHT = gql`
  ${HIGHLIGHT_CORE_FIELDS}
  mutation createHighlight($input: HighlightInput!) {
    createHighlight(input: { highlight: $input }) {
      highlight {
        ...HighlightCoreFields
      }
    }
  }
`;

const UPDATE_HIGHLIGHT = gql`
  ${HIGHLIGHT_CORE_FIELDS}
  mutation updateHighlight($id: BigInt!, $input: HighlightPatch!) {
    updateHighlight(input: { id: $id, patch: $input }) {
      highlight {
        ...HighlightCoreFields
      }
    }
  }
`;

const DELETE_HIGHLIGHT = gql`
  ${HIGHLIGHT_CORE_FIELDS}
  mutation deleteHighlight($id: BigInt!) {
    deleteHighlight(input: { id: $id }) {
      highlight {
        ...HighlightCoreFields
      }
    }
  }
`;

const UPDATE_SUMMARY = gql`
  mutation updateSummary($id: BigInt!, $input: SummaryPatch!) {
    updateSummary(input: { patch: $input, id: $id }) {
      clientMutationId
    }
  }
`;

const CREATE_TAG_GROUP = gql`
  mutation createTagGroup($input: TagGroupInput!) {
    createTagGroup(input: { tagGroup: $input }) {
      tagGroup {
        id
        name
      }
    }
  }
`;

const UPDATE_TAG_GROUP = gql`
  mutation updateTagGroup($id: BigInt!, $input: TagGroupPatch!) {
    updateTagGroup(input: { patch: $input, id: $id }) {
      clientMutationId
    }
  }
`;

const DELETE_TAG_GROUP = gql`
  mutation deleteTagGroup($id: BigInt!) {
    deleteTagGroup(input: { id: $id }) {
      clientMutationId
    }
  }
`;

const CREATE_EVIDENCE = gql`
  mutation addEvidence($input: EvidenceInput!) {
    createEvidence(input: { evidence: $input }) {
      clientMutationId
    }
  }
`;

const DELETE_EVIDENCE = gql`
  mutation deleteEvidence($id: BigInt!) {
    deleteEvidence(input: { id: $id }) {
      clientMutationId
    }
  }
`;

const UPLOAD_FROM_GOOGLE_TO_S3 = gql`
  mutation uploadFromGoogleToS3($cloudFileId: String!, $fileId: String!, $fileName: String!) {
    uploadFromGoogleToS3(
      input: { cloudFileId: $cloudFileId, fileId: $fileId, fileName: $fileName }
    ) {
      success
    }
  }
`;

export const UPLOAD_FROM_ONEDRIVE_TO_S3 = gql`
  mutation uploadFromOneDriveToS3($cloudFileId: String!, $fileId: String!, $fileName: String!) {
    uploadFromOneDriveToS3(
      input: { cloudFileId: $cloudFileId, fileId: $fileId, fileName: $fileName }
    ) {
      success
    }
  }
`;

const CREATE_TAG_GROUP_SORTING = gql`
  mutation addTagGroupSorting($input: CreateTagGroupSortingInput!) {
    createTagGroupSorting(input: $input) {
      clientMutationId
    }
  }
`;

const UPDATE_TAG_GROUP_SORTING = gql`
  mutation updateTagGroupSorting($id: BigInt!, $input: TagGroupSortingPatch!) {
    updateTagGroupSorting(input: { patch: $input, id: $id }) {
      clientMutationId
    }
  }
`;

const CREATE_INSIGHT_REACTION = gql`
  mutation createInsightReaction(
    $emoji: String = ""
    $insightId: BigInt = ""
    $clusterId: String!
  ) {
    createReaction(
      input: { reaction: { emoji: $emoji, insightId: $insightId, clusterId: $clusterId } }
    ) {
      reaction {
        id
        clusterId
        emoji
        insightId
        createdAt
        createdBy
      }
    }
  }
`;

const UPDATE_INSIGHT_REACTION = gql`
  mutation updateInsightReaction($reactionId: BigInt = "", $input: ReactionPatch!) {
    updateReaction(input: { id: $reactionId, patch: $input }) {
      reaction {
        id
        clusterId
        emoji
        insightId
        createdAt
        createdBy
      }
    }
  }
`;

const DELETE_INSIGHT_REACTION = gql`
  mutation deleteInsightReaction($id: BigInt = "") {
    deleteReaction(input: { id: $id }) {
      reaction {
        id
        clusterId
        insightId
        createdBy
      }
    }
  }
`;

const CREATE_INSIGHT_COMMENT = gql`
  mutation createInsightComment($insightId: BigInt = "", $content: String = "") {
    createComment(input: { comment: { insightId: $insightId, content: $content } }) {
      comment {
        id
        content
        createdBy
        createdAt
      }
    }
  }
`;

const UPDATE_INSIGHT_COMMENT = gql`
  mutation updateInsightComment($commentId: BigInt = "", $input: CommentPatch!) {
    updateComment(input: { id: $commentId, patch: $input }) {
      comment {
        id
        content
      }
    }
  }
`;

const DELETE_INSIGHT_COMMENT = gql`
  mutation deleteInsightComment($commentId: BigInt = "") {
    deleteComment(input: { id: $commentId }) {
      comment {
        id
        content
      }
    }
  }
`;

const CREATE_INSIGHT_LIKE = gql`
  mutation createInsightLike($commentId: BigInt = "") {
    createLike(input: { like: { commentId: $commentId } }) {
      like {
        id
        createdBy
        userByCreatedBy {
          id
          name
          email
          avatar
          picture
        }
      }
    }
  }
`;

const DELETE_INSIGHT_LIKE = gql`
  mutation deleteInsightLike($likeId: BigInt = "") {
    deleteLike(input: { id: $likeId }) {
      like {
        id
      }
    }
  }
`;

const CREATE_PROJECT_CHECKLIST = gql`
  mutation createProjectChecklist($input: ProjectChecklistInput!) {
    createProjectChecklist(input: { projectChecklist: $input }) {
      projectChecklist {
        id
        dataAdded
        highlightAdded
        summaryAdded
        insightAdded
        notesAdded
        customTemplateAdded
        teammateAdded
      }
    }
  }
`;

const UPDATE_PROJECT_CHECKLIST = gql`
  mutation updateProjectChecklist($id: BigInt!, $input: ProjectChecklistPatch!) {
    updateProjectChecklist(input: { id: $id, patch: $input }) {
      projectChecklist {
        id
        dataAdded
        highlightAdded
        summaryAdded
        insightAdded
        notesAdded
        customTemplateAdded
        teammateAdded
      }
    }
  }
`;

export {
  UPDATE_USER,
  DELETE_USER,
  INVITE_USER,
  AUTHORIZE_ZOOM,
  AUTHORIZE_SLACK,
  AUTHORIZE_GOOGLE,
  AUTHORIZE_ONEDRIVE,
  DELETE_INTEGRATION,
  UPDATE_SLACK_WEBHOOK,
  CREATE_SIGNED_S3_URL,
  CREATE_TRANSCRIPT,
  UPDATE_TRANSCRIPT,
  CREATE_NOTE,
  UPDATE_NOTE,
  CREATE_THEME,
  UPDATE_THEME,
  CREATE_DASHBOARD,
  UPDATE_DASHBOARD,
  DELETE_DASHBOARD,
  DELETE_NOTE,
  DELETE_THEME,
  RUN_SENTIMENT_ANALYSIS,
  CREATE_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  DELETE_TRANSCRIPT,
  CREATE_PROJECT_INFO,
  UPDATE_PROJECT_INFO,
  REGISTER,
  CREATE_PROJECT_INSIGHT,
  UPDATE_PROJECT_INSIGHT_BY_CLIENT_ID,
  DELETE_INSIGHT_BY_CLIENT_ID,
  IMPORT_FILE,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  CREATE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  DELETE_PARTICIPANT,
  UPDATE_ORGANIZATION,
  UPDATE_USER_ROLE,
  CREATE_DASHBOARD_FOLDER,
  UPDATE_DASHBOARD_FOLDER,
  DELETE_DASHBOARD_FOLDER,
  CREATE_DATA_FOLDER,
  UPDATE_DATA_FOLDER,
  DELETE_DATA_FOLDER,
  RESET_PASSWORD,
  CREATE_TEXT_NOTE,
  UPDATE_TEXT_NOTE,
  DELETE_TEXT_NOTE,
  CREATE_LINE,
  UPDATE_LINE,
  DELETE_LINE,
  COPY_DASHBOARD,
  CREATE_SHAPE,
  UPDATE_SHAPE,
  DELETE_SHAPE,
  CREATE_CANVAS_IMAGE,
  UPDATE_CANVAS_IMAGE,
  DELETE_CANVAS_IMAGE,
  CREATE_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  MARK_AS_TEAM,
  MERGE_TAGS,
  GENERATE_SUMMARY_FROM_NOTES,
  GENERATE_SUMMARY_BY_TAGS,
  GENERATE_DATA_SUMMARY,
  MERGE_LOGINS,
  CREATE_HIGHLIGHT,
  UPDATE_HIGHLIGHT,
  DELETE_HIGHLIGHT,
  UPDATE_SUMMARY,
  CREATE_TAG_GROUP,
  UPDATE_TAG_GROUP,
  DELETE_TAG_GROUP,
  CREATE_EVIDENCE,
  DELETE_EVIDENCE,
  UPLOAD_FROM_GOOGLE_TO_S3,
  CREATE_TAG_GROUP_SORTING,
  UPDATE_TAG_GROUP_SORTING,
  GENERATE_SUMMARY_TRANSLATION,
  CREATE_INSIGHT_REACTION,
  CREATE_INSIGHT_COMMENT,
  CREATE_INSIGHT_LIKE,
  DELETE_INSIGHT_REACTION,
  UPDATE_INSIGHT_COMMENT,
  DELETE_INSIGHT_COMMENT,
  DELETE_INSIGHT_LIKE,
  UPDATE_INSIGHT_REACTION,
  CREATE_LIGHT_DOCUMENT,
  UPDATE_LIGHT_DOCUMENT,
  DELETE_LIGHT_DOCUMENT,
  CREATE_PROJECT_CHECKLIST,
  UPDATE_PROJECT_CHECKLIST,
};
