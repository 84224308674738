import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
// import {
//   BackgroundImage,
//   Container,
//   Content,
//   CreatedAt,
//   Divider,
//   LogoImage,
//   Name,
//   NameContainer,
//   RoundDivider,
//   Title,
//   TitleContainer,
// } from './styles';
import { Icon24 } from '../../../../Icons/Icon';
import { EvidenceContext } from '../../../../Context/EvidenceContext';
import Loader from '../../../Loader';
import { Container } from './styles';

const TagBlockComponent: FC = (props: any) => {
  const { isInitialized, findEvidenceById } = useContext(EvidenceContext);
  const [tag, setTag] = useState<any | null>(null);
  const isShared = props.extension.options.shared;

  useEffect(() => {
    if (isInitialized) {
      setTag(findEvidenceById(props.node.attrs.id)?.tag || null);
    }
  }, [isInitialized]);

  const notesCount = useMemo(
    () =>
      tag?.tagsNotes?.filter((item: any) => item.note?.dashboardId === tag.dashboardId).length || 0,
    [tag]
  );

  if (isShared) return null;

  return (
    <NodeViewWrapper contentEditable={false}>
      {!tag ? (
        <Loader />
      ) : (
        <Container
          onClick={() => {
            const url = `${window.location.origin}/projects/${tag.dashboardId}/tags/all/${tag.id}`;
            window?.open(url, '_blank')?.focus();
          }}
        >
          <div className="flex justify-between items-center p-3">
            <div className="font-medium text-md text-primary-purple flex items-center">
              <div className="mr-2">
                <Icon24.Tag />
              </div>
              {tag?.name}
            </div>
            <div className="font-medium text-sm text-primary-purple-light-40">
              {notesCount} notes
            </div>
          </div>
        </Container>
      )}
    </NodeViewWrapper>
  );
};

export default TagBlockComponent;
