import React from 'react';
import Select, { StylesConfig } from 'react-select';

const placeholderStyle = {
  color: 'rgba(56, 33, 82, 0.41)',
  fontWeight: '500',
  fontSize: '14px',
};

const inputStyle = {
  color: '#382152',
  fontWeight: '500',
  fontSize: '14px',
};

const controlStyle = {
  outlineColor: 'transparent',
  color: '#382152',
  fontWeight: '500',
  fontSize: '14px',
  '&:active': {
    borderColor: 'rgb(56, 33, 82, 0.5)',
    boxShadow: '0 0 0 1px rgb(56, 33, 82, 0.5)',
  },
  '&:hover': {
    borderColor: 'rgb(56, 33, 82, 0.5)',
    boxShadow: '0 0 0 1px rgb(56, 33, 82, 0.5)',
  },
  '&:focused': {
    borderColor: 'rgb(56, 33, 82, 0.5)',
    boxShadow: '0 0 0 1px rgb(56, 33, 82, 0.5)',
  },
};

const singleValueStyle = {
  fontWeight: '500',
  fontSize: '14px',
  color: '#382152',
};

const multiValueLabelStyle = {
  fontSize: '14px',
  color: 'rgba(56, 33, 82, 1)',
  fontWeight: '500',
};

const CustomSelect = ({
  elementWithDividerNumber,
  ...props
}: React.ComponentProps<typeof Select>): JSX.Element => {
  const borderStyle = elementWithDividerNumber
    ? {
        '&:nth-child(2)': {
          borderBottom: '1px solid #D7D3DC',
        },
      }
    : {};

  const optionStyle = {
    fontWeight: '500',
    fontSize: '14px',
    color: '#382152',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F3EFF6',
    },
    zIndex: 9999,
    ...borderStyle,
  };

  return (
    <Select
      {...props}
      styles={{
        control(base, state) {
          return {
            ...base,
            ...controlStyle,
            ...(state.isFocused
              ? { borderColor: 'rgb(56, 33, 82, 0.5)', boxShadow: '0 0 0 1px rgb(56, 33, 82, 0.5)' }
              : {}),
          };
        },

        option(base, state) {
          return {
            ...base,
            ...optionStyle,
            ...(state.isSelected
              ? {
                  backgroundColor: 'rgb(56, 33, 82, 0.3)',
                  '&:hover': {
                    backgroundColor: 'rgb(56, 33, 82, 0.3)',
                  },
                }
              : {}),
          };
        },
        multiValue(base, { data: { color } }) {
          return {
            ...base,
            backgroundColor: color || 'rgba(56, 33, 82, 0.2)',
          };
        },
        multiValueLabel(base) {
          return {
            ...base,
            ...multiValueLabelStyle,
          };
        },
        singleValue(base) {
          return {
            ...base,
            ...singleValueStyle,
          };
        },
        placeholder(base) {
          return { ...base, ...placeholderStyle };
        },
        input(base) {
          return { ...base, ...inputStyle };
        },
        indicatorSeparator() {
          return { display: 'none' };
        },
      }}
    />
  );
};

export default CustomSelect;
