import styled from 'styled-components';
import { breakpoint } from '../Shared';

export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
`;

export const Sidebar = styled.div`
  position: sticky;
  top: 49px;
  align-self: flex-start;
  height: calc(100vh - 49px);
  flex-shrink: 0;
  display: flex;
  padding: 24px 10px;
  flex-direction: column;
  border-right: 1px solid #e5e7eb;
  width: 280px;

  ${breakpoint.Tablet`
    display: none;
  `};
`;
