import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateLightDocument } from '../../Hooks/useDocuments';
import { useCreateParticipant } from '../../Hooks/useParticipants';
import { useCreatePrompt } from '../../Hooks/usePrompts';
import { Icon24 } from '../../Icons/Icon';
import PortalNew from '../PortalNew';

import {
  Button,
  ButtonText,
  Container,
  DROPDOWN_WIDTH,
  NewDocDropdown,
  NewDocDropDownDivider,
  NewDocDropdownItem,
  NewDocDropdownItemContent,
  NewdocDropdownItemIcon,
  NewDocDropdownItemSubtitle,
  NewDocDropdownItemTitle,
  NewDocDropdownItemWrapper,
} from './styles';

const dropdownItems = [
  {
    id: 'newDoc',
    title: 'Document',
    subtitle: 'Quickly spin up a new doc to take notes.',
    icon: <Icon24.Document />,
  },
  // {
  //   id: 'newProject',
  //   title: 'Project from files',
  //   subtitle: 'Upload new data to analyze.',
  //   icon: <Icon24.Folders />,
  // },
  {
    id: 'newTemplate',
    title: 'AI Template',
    subtitle: 'Create a custom AI template.',
    icon: <Icon24.Insight />,
  },
  {
    id: 'newParticipant',
    title: 'Participant',
    subtitle: 'Create a new participant profile.',
    icon: <Icon24.Participant />,
  },
];

const NewDocButton: FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [createPrompt] = useCreatePrompt();
  const [createParticipant] = useCreateParticipant();
  const [createLightDocument] = useCreateLightDocument();
  const history = useHistory();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (buttonRef.current && buttonRef.current.contains(e.target as Node)) return;
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setIsDropdownOpen(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [dropdownRef]);

  const dropdownCoordinates = () => {
    if (!buttonRef.current) return { top: 0, left: 0 };

    const { top, left, width, height } = buttonRef.current.getBoundingClientRect();
    const widthDiff = DROPDOWN_WIDTH - width;

    return {
      top: top + height + 5,
      left: left - widthDiff,
    };
  };

  const handleCreatePrompt = async () => {
    setIsCreating(true);
    const newPrompt = await createPrompt({
      title: 'New template',
      description: '',
      isEnabled: false,
      mainPrompt: '',
      type: 'insight',
      outputLength: 'short',
    });
    setIsCreating(false);
    history.push(`/templates/${newPrompt.id}`);
  };

  const handleCreateParticipant = async () => {
    setIsCreating(true);
    const participant = await createParticipant();
    history.push(`/participants/${participant.id}`);
    setIsCreating(false);
  };

  const handleCreateDocument = async () => {
    setIsCreating(true);
    const document = await createLightDocument();
    history.push(`/notes/${document.id}`);
    setIsCreating(false);
  };

  const handleItemClick = (id: string) => {
    if (isCreating) return;
    setIsDropdownOpen(false);
    switch (id) {
      case 'newDoc':
        handleCreateDocument();
        break;
      case 'newProject':
        // history.push('/projects/new');
        break;
      case 'newTemplate':
        handleCreatePrompt();
        break;
      case 'newParticipant':
        handleCreateParticipant();
        break;
    }
  };

  return (
    <Container>
      <Button ref={buttonRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {isCreating ? <Icon24.AISpinner /> : <Icon24.Plus />}
        <ButtonText>New</ButtonText>
        <Icon24.ChevronDown />
      </Button>

      {isDropdownOpen && (
        <PortalNew wrapperId="NewDocDropdownPortal">
          <NewDocDropdown
            top={dropdownCoordinates().top}
            left={dropdownCoordinates().left}
            ref={dropdownRef}
          >
            {dropdownItems.map((item, index) => (
              <NewDocDropdownItemWrapper key={item.id}>
                <NewDocDropdownItem onClick={() => handleItemClick(item.id)}>
                  <NewdocDropdownItemIcon>{item?.icon}</NewdocDropdownItemIcon>
                  <NewDocDropdownItemContent>
                    <NewDocDropdownItemTitle>{item?.title}</NewDocDropdownItemTitle>
                    <NewDocDropdownItemSubtitle>{item?.subtitle}</NewDocDropdownItemSubtitle>
                  </NewDocDropdownItemContent>
                </NewDocDropdownItem>
                {index === 0 && <NewDocDropDownDivider />}
              </NewDocDropdownItemWrapper>
            ))}
          </NewDocDropdown>
        </PortalNew>
      )}
    </Container>
  );
};

export default NewDocButton;
