const colors = {
  primary: {
    purple: '#382152',
    purple10: 'rgba(56, 33, 82, 0.1)',
  },
  text: {
    purple: '#3B2651',
    purple80: 'rgba(59, 38, 81, 0.8)',
    placeholder: 'rgba(59, 38, 81, 0.6)',
    placeholder2: 'rgba(56, 33, 82, 0.4)',
  },
};

export default colors;
