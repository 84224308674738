import React, { PropsWithChildren } from 'react';

type SecondaryToolbarProps = {
  sticky?: boolean;
};

export default function SecondaryToolbar({
  children,
  sticky = false,
}: PropsWithChildren<SecondaryToolbarProps>): JSX.Element {
  return (
    <div
      className={`w-full bg-white flex justify-end z-[5] ${
        sticky ? 'secondary-toolbar_sticky' : ''
      }`}
    >
      {children}
    </div>
  );
}
