import React, { FC } from 'react';
import { PropmptTemplate } from '../../Consts/promptTemplates';
import Icon24 from '../../Icons/Icon/Icon24';

import {
  Container,
  TemplateCard,
  IconContainer,
  TemplateName,
  TemplateDescription,
  ChevronIcon,
} from './styles';

interface PromptTemplatesProps {
  templates: PropmptTemplate[];
  currentTemplateId: string;
  onChange(promptId: string): void;
}

const PromptTemplates: FC<PromptTemplatesProps> = ({ templates, currentTemplateId, onChange }) => {
  return (
    <Container>
      {templates.map((template) => (
        <TemplateCard
          key={template.id}
          onClick={() => onChange(template.id)}
          selected={currentTemplateId === template.id}
        >
          <ChevronIcon show={currentTemplateId === template.id}>
            <Icon24.Chevron />
          </ChevronIcon>
          <IconContainer src={template.icon} />

          <TemplateName>{template.title}</TemplateName>
          <TemplateDescription>{template.description}</TemplateDescription>
        </TemplateCard>
      ))}
    </Container>
  );
};

export default PromptTemplates;
