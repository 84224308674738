import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Avatar from '../Components/Avatar';
import { Insight } from '../Models';
import { Link } from 'react-router-dom';

import { defaultCoverImages } from '../Consts/coverImages';
import Masonry from 'react-masonry-css';

type InsightsCardViewProps = {
  insights: Insight[];
  baseUrl?: string;
  ignoreMarginX?: boolean;
};

interface TitleProps {
  readonly isEmpty?: boolean;
}

export default function InsightsCardView({
  insights = [],
  baseUrl,
  ignoreMarginX,
}: InsightsCardViewProps): JSX.Element {
  const insightCardsStyle: React.CSSProperties = {}
  if (ignoreMarginX) {
    insightCardsStyle.marginLeft = insightCardsStyle.marginRight = 0;
  }
  return (
    <InsightCards style={insightCardsStyle}>
      <Masonry
        breakpointCols={{
          default: 3,
          1220: 2,
          960: 1,
        }}
        style={{ width: '100%' }}
        className="basic-masonry-grid w-full"
        columnClassName="basic-masonry-grid_column"
      >
        {insights.map((insight) => {
          const selectedImage = insight?.customCover
            ? { name: 'custom', url: insight.customCover, position: '50% 50%' }
            : defaultCoverImages.find((image) => image.name === insight?.cover);
          const url = baseUrl ? `${baseUrl}/${insight.clientId}` : `/projects/${insight.dashboardId}/insights/${insight.clientId}`;
          return (
            <InsightCard key={insight.id}>
              <Link to={url}>
                {selectedImage && (
                  <img
                    src={selectedImage.url}
                    className="rounded-t"
                    alt="cover image"
                    style={{
                      width: '100%',
                      height: 150,
                      objectFit: 'cover',
                    }}
                  />
                )}
                <InsightCardContent>
                  <InsightTitle isEmpty={!insight.title}>
                    {insight.title || 'Untitled Insight'}
                  </InsightTitle>
                  <hr className="my-3" />
                  <div className="flex flex-row items-center justify-left ">
                    <InsightDate>{moment(insight.createdAt).format('MMM D, YYYY')}</InsightDate>
                    <div className="rounded-full bg-primary-purple-light h-1 w-1"></div>
                    <CreatedBy>
                      <Avatar user={insight.userByCreatedBy} />
                      <UserName>{insight.userByCreatedBy?.name}</UserName>
                    </CreatedBy>
                  </div>
                </InsightCardContent>
              </Link>
            </InsightCard>
          );
        })}
      </Masonry>
    </InsightCards>
  );
}

const InsightCards = styled.div`
  display: flex;
  margin: 32px 48px;
  flex-wrap: wrap;
`;

export const InsightCard = styled.div`
  margin-bottom: 20px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px #e5e5e5, 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const InsightCardContent = styled.div`
  padding: 12px;
`;

const InsightDate = styled.div`
  font-size: 14px;
  color: rgba(56, 33, 82, 1);
  padding-right: 12px;
`;

export const InsightTitle = styled.div<TitleProps>`
  color: ${(props) => props.theme.colors.text.purple};
  max-height: 160px;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;

  display: -webkit-box !important;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: normal;

  font-weight: ${(props) => props.isEmpty && 400};
  color: ${(props) => props.isEmpty && props.theme.colors.text.placeholder};
`;

const CreatedBy = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.primary.purple};
  padding-left: 12px;
`;

const UserName = styled.div`
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
`;
