import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FETCH_DASHBOARD } from '../GraphQL/queries';
import { Loader } from '.';
import useBoards from '../Hooks/useBoards';
import Input from './Input';
import Button from './Button';

function UpdateBoard({ dashboardId }: { dashboardId: string }): JSX.Element {
  const [name, setName] = useState('');

  const history = useHistory();

  const { loading, data } = useQuery(FETCH_DASHBOARD, { variables: { dashboardId } });

  const { updateBoard } = useBoards();

  useEffect(() => {
    if (!loading && data?.dashboard) {
      setName(data.dashboard.name);
    }
  }, [data, loading]);

  async function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await updateBoard(parseInt(dashboardId), {
      name,
    });

    history.push(`/`);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mb-3">
        <Input
          required={true}
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="name"
          type="text"
          placeholder="Give your board a name"
        />
      </div>
      <div className="flex items-center justify-between w-full">
        <Button onClick={handleClick}>Save</Button>
      </div>
    </>
  );
}

export default UpdateBoard;
