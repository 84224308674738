export const onboardingPages = [
  {
    header: 'Tell us about yourself',
    id: 'name',
    inputLabel: 'Full Name',
    placeholder: `what's your name?`,
    stepNumber: 1,
  },
  {
    header: 'Customize your workspace',
    id: 'company-name',
    inputLabel: 'Workspace name',
    placeholder: `Hint: most people use their company name`,
    stepNumber: 2,
  },
];
