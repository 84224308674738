import { MinusIcon, PlusIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';

export function ZoomLevelControl({
  setZoomLevel,
  zoomLevel,
  onFitToScreen,
}: {
  setZoomLevel: (level: number) => void;
  onFitToScreen: () => void;
  zoomLevel: number;
}): JSX.Element {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <div className="absolute z-30 flex  items-center bottom-4 right-8 bg-white rounded-sm shadow-md border-primary-purple-light border cursor-pointer font-medium text-sm select-none">
      <div
        className="p-2"
        onClick={() => {
          if (zoomLevel > 9) setZoomLevel(zoomLevel - 5);
        }}
      >
        <MinusIcon className="w-4 h-4" />
      </div>
      <div>
        <Popover>
          <Popover.Button ref={(ref: any) => setReferenceElement(ref)}>
            <div className="p-2 border-primary-purple-light border-x min-w-[72px] text-center flex items-center font-medium text-sm justify-center leading-4">
              {zoomLevel}%
              <ChevronUpIcon className="ml-1 h-3 w-3" />
            </div>
          </Popover.Button>

          <Popover.Panel
            ref={(ref: any) => setPopperElement(ref)}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="bg-white rounded-sm shadow-md border-primary-purple-light border text-sm  font-medium cursor-pointer">
              <Popover.Button className="flex items-center hover:bg-secondary-purple-light">
                <div
                  className="px-4 py-2"
                  onClick={() => {
                    setZoomLevel(100);
                    close();
                  }}
                >
                  Zoom to 100%
                </div>
              </Popover.Button>
              <Popover.Button className="flex hover:bg-secondary-purple-light w-full">
                <div
                  onClick={() => {
                    onFitToScreen();
                    close();
                  }}
                  className="px-4 py-2 w-full text-left"
                >
                  Zoom to fit
                </div>
              </Popover.Button>
            </div>
          </Popover.Panel>
        </Popover>
      </div>

      <div
        className="p-2"
        onClick={() => {
          setZoomLevel(zoomLevel + 5);
        }}
      >
        <PlusIcon className="w-4 h-4" />
      </div>
    </div>
  );
}
