import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';

import axios from 'axios';
import BACKEND_URL from '../Consts/backendUrl';
import { defaultCoverImages } from '../Consts/coverImages';

import Loader from '../Components/Loader';
import SharingLayout from '../Layouts/SharingLayout';
import { PureTipTapEditor } from '../Components/TipTapEditor/TipTapEditor';
import { EvidenceContextProvider, TEvidence } from '../Context/EvidenceContext';
import useUsers from '../Hooks/useUsers';
import StatsReactionsComments from '../Components/StatsReactionsComments';
import { ID } from '../Models';

export default function SharedInsight(): JSX.Element {
  const { currentUser: getCurrentUser } = useUsers();
  const [, currentUser] = getCurrentUser();
  const isUserAuthenticated = !!currentUser;

  const { dashboardToken } = useParams<{ dashboardToken: string }>();
  const { clientId } = useParams<{ clientId: string }>();
  const { token } = useParams<{ token: string }>();
  const { orgId } = useParams<{ orgId: string }>();
  const [insight, setInsight] = useState<
    | {
        id: ID;
        title?: string;
        content?: string;
        cover?: string;
        customCover?: string;
        clientId?: string;
      }
    | undefined
  >();
  const [dashboard, setDashboard] = useState<
    { name?: string; cover?: string; id?: string; shareId?: string } | undefined
  >();
  const [logo, setLogo] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState(true);
  const [evidences, setEvidences] = useState<TEvidence[]>([]);

  const history = useHistory();
  const analytics = useSegment();

  const fetchData = useCallback(async () => {
    if (!token && (!dashboardToken || !clientId)) {
      history.push('/404');
      return;
    }

    const targetUrl = token
      ? `${BACKEND_URL}/shared/${orgId}/insight/${token}`
      : `${BACKEND_URL}/shared/${orgId}/insights/${dashboardToken}/${clientId}`;

    const res = await axios.get(targetUrl);
    const content = res.data.insight?.content ? JSON.parse(res.data.insight?.content) : null;
    setInsight({ ...res.data.insight, content });
    setEvidences(res.data.evidences);
    setDashboard(res.data.dashboard);
    setLogo(res.data.logo);
    setLoading(false);

    analytics.page({
      name: 'Shared Insight',
      properties: {
        insightClientId: res.data.insight?.clientId,
      },
    });
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [token]);

  if (loading) return <Loader />;

  const coverImage = insight?.customCover
    ? { name: 'custom', url: insight.customCover, position: '50% 50%' }
    : defaultCoverImages.find((image) => image.name === insight?.cover);

  return (
    <SharingLayout dashboard={dashboard} logo={logo} isUserAuthenticated={isUserAuthenticated}>
      <div>
        <div>
          {coverImage && (
            <img
              src={coverImage.url}
              alt="cover image"
              style={{
                width: '100%',
                height: 350,
                objectFit: 'cover',
                objectPosition: coverImage.position,
              }}
            />
          )}
        </div>

        <div className="mx-auto max-w-[800px]">
          <h1 className="my-5 mt-8 mx-3 text-3xl font-semibold">
            {insight?.title || 'Untitled insight'}
          </h1>
          <div>
            <EvidenceContextProvider loading={loading} data={evidences} refetch={fetchData}>
              <PureTipTapEditor content={insight?.content} editable={false} withEvidences shared />
            </EvidenceContextProvider>
          </div>

          {/* <StatsReactionsComments insightId={insight?.id} readonly /> */}
        </div>
      </div>
    </SharingLayout>
  );
}
