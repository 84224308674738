import React, { Fragment } from 'react';
import { Listbox, Popover, Transition } from '@headlessui/react';
import { TEXT_COLORS, TEXT_FONT_SIZES } from '../../Consts';
import BoldIcon from '../../Icons/BoldIcon';
import ItalicIcon from '../../Icons/ItalicIcon';
import UnderlineIcon from '../../Icons/UnderlineIcon';
import LeftAlignIcon from '../../Icons/LeftAlignIcon';
import CenterAlignIcon from '../../Icons/CenterAlignIcon';
import DropdownMenuIcon from '../../Icons/DropdownMenuIcon';
import CheckmarkIcon from '../../Icons/CheckmarkIcon';
import { TextStyles } from './TextComponent';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';

import { Menu } from '@headlessui/react';
import { DropDownMenu } from '../DropDownMenu';
import DotsIcon from '../../Icons/DotsIcon';
import { backZIndex, frontZIndex } from '../../Consts/canvasZIndex';

export default function TextMenu({
  onUpdate,
  onDelete,
  textStyleObj,
  setTextAreaHasFocus,
}: {
  onUpdate: (updates: {
    text?: string;
    styles?: TextStyles;
    x?: number;
    y?: number;
  }) => Promise<void>;
  onDelete: () => void;
  textStyleObj: TextStyles;
  setTextAreaHasFocus: () => void;
}): JSX.Element {
  return (
    <div className={'-mt-12 z-100'}>
      <span className="relative z-0 inline-flex shadow-md rounded-md">
        <button
          onClick={setTextAreaHasFocus}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-gray-300 border border-r-0 rounded-l-md bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <PencilIcon className={'h-5 w-5'} />
        </button>
        <Listbox
          value={textStyleObj.fontSize}
          onChange={(value) => {
            onUpdate({ styles: { ...textStyleObj, fontSize: value } });
          }}
        >
          {({ open }) => (
            <>
              <Listbox.Button className="-ml-px relative inline-flex items-center px-2 py-1.5 border-gray-300 border border-r-0 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
                {textStyleObj.fontSize}{' '}
                <span style={{ marginLeft: '8px' }}>
                  <DropdownMenuIcon />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Listbox.Options
                  style={{ width: '56px', top: '34px' }}
                  className="z-100 origin-top-right absolute mt-1 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none border-gray-300 border rounded"
                >
                  {TEXT_FONT_SIZES.map((size, index) => (
                    <Listbox.Option key={index} value={size}>
                      {({ selected }) => (
                        <div
                          className="hover:cursor-pointer hover:bg-gray-50"
                          style={{
                            paddingLeft: '24px',
                            position: 'relative',
                          }}
                        >
                          {selected && (
                            <span
                              style={{
                                position: 'absolute',
                                left: '9px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                            >
                              <CheckmarkIcon />
                            </span>
                          )}
                          {size}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
        <button
          onClick={(e) => {
            onUpdate({ styles: { ...textStyleObj, fontStyleBold: !textStyleObj.fontStyleBold } });
          }}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-gray-300 border border-r-0 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <BoldIcon />
        </button>
        <button
          onClick={(e) => {
            onUpdate({
              styles: { ...textStyleObj, fontStyleItalic: !textStyleObj.fontStyleItalic },
            });
          }}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-t border-b border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <ItalicIcon />
        </button>
        <button
          onClick={(e) => {
            onUpdate({
              styles: {
                ...textStyleObj,
                textDecoration: textStyleObj.textDecoration === 'underline' ? '' : 'underline',
              },
            });
          }}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-t border-b border-r border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <UnderlineIcon />
        </button>
        <button
          onClick={(e) => {
            onUpdate({
              styles: {
                ...textStyleObj,
                align: 'left',
              },
            });
          }}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-t border-b border-l border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <LeftAlignIcon />
        </button>
        <button
          onClick={(e) => {
            onUpdate({
              styles: {
                ...textStyleObj,
                align: textStyleObj.align === 'left' ? 'center' : 'left',
              },
            });
          }}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-t border-b border-r border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <CenterAlignIcon />
        </button>
        <Popover className="relative">
          <Popover.Button className="-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none">
            <div
              className={'h-4 w-4 rounded-lg'}
              style={{
                backgroundColor: textStyleObj.fillColor,
              }}
            />
          </Popover.Button>
          <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md">
            <div className="grid gap-1 grid-cols-3 w-20">
              {TEXT_COLORS.map((fillColor, index) => (
                <a
                  href="#"
                  onClick={() => {
                    onUpdate({
                      styles: {
                        ...textStyleObj,
                        fillColor: fillColor,
                      },
                    });
                  }}
                  key={index}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="11" fill={fillColor} />
                    {textStyleObj.fillColor == fillColor && (
                      <path
                        d="M7 10.143L10.4286 13.5714L15 8.42871"
                        stroke="#FFF"
                        strokeWidth="1.5"
                      />
                    )}
                  </svg>
                </a>
              ))}
            </div>
          </Popover.Panel>
        </Popover>
        <button
          onClick={onDelete}
          style={{ height: 'auto' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none h-full"
        >
          <TrashIcon className={'h-5 w-5'} />
        </button>
        <DropDownMenu
          button={
            <Menu.Button
              style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
              className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none h-full rounded-r-md"
            >
              <DotsIcon />
            </Menu.Button>
          }
        >
          {' '}
          <Menu.Item>
            <label
              onClick={() => {
                onUpdate({ styles: { ...textStyleObj, zIndex: frontZIndex } });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Bring to front
            </label>
          </Menu.Item>
          <Menu.Item>
            <label
              onClick={() => {
                onUpdate({ styles: { ...textStyleObj, zIndex: backZIndex } });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Send to back
            </label>
          </Menu.Item>
        </DropDownMenu>
      </span>
    </div>
  );
}
