import React, { FC } from 'react';
import Button from '../../Components/Button';
import { Icon24 } from '../../Icons/Icon';

import {
  ButtonText,
  EmptyStateText,
  EmptyState,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateActionWrapper,
  FAQLink,
  QuestionIconWrapper,
} from './styles';

import tagsEmptyImage from '../../assets/empty-states/tags.png';

interface TagsEmptyStateProps {
  isGlobal?: boolean;
  isSimple?: boolean;
  onAddNewTag(): void;
}

const TagsEmptyState: FC<TagsEmptyStateProps> = ({ isGlobal, isSimple, onAddNewTag }) => {
  const showImage = !isSimple || (isSimple && !isGlobal);
  return (
    <EmptyState isSimple={!!isSimple}>
      {showImage && <EmptyStateImage src={tagsEmptyImage} />}
      {!isSimple && <EmptyStateTitle>Create tags to analyze data</EmptyStateTitle>}
      <EmptyStateText>
        {isGlobal
          ? 'Global tags are shared across all of your projects.'
          : 'Project tags are unique and relevant to each project.'}
      </EmptyStateText>
      <EmptyStateActionWrapper>
        <Button onClick={onAddNewTag} type={isSimple && isGlobal ? 'secondary' : 'primary'}>
          <Icon24.Plus />
          <ButtonText>{`New ${isGlobal ? 'global' : 'project'} tag`}</ButtonText>
        </Button>
      </EmptyStateActionWrapper>
      <FAQLink
        href="https://help.notably.ai/en/articles/6657217-what-are-the-types-of-tags-in-notably"
        target="_blank"
      >
        <QuestionIconWrapper>
          <Icon24.QuestionMark />
        </QuestionIconWrapper>{' '}
        Learn more about highlighting
      </FAQLink>
    </EmptyState>
  );
};

export default TagsEmptyState;
