import styled, { keyframes } from 'styled-components';

export const Container = styled.div<{ withHeader?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ withHeader }) => withHeader && 'margin-top: -48px'};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 0.5s linear infinite;
  margin-bottom: 10px;
`;

export const Text = styled.div<{ colored?: boolean }>`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: ${({ colored }) => colored ? '#3B2651' : 'rgba(59, 38, 81, 0.4)'};
  user-select: none;
`;

export const AdditionalText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(59, 38, 81, 0.4);
  margin-top: 8px;
`