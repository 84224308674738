import { useEffect } from 'react';

const useKeyPressHandler = (handler: (e: KeyboardEvent) => void, key: string): void => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === key) {
        event.preventDefault();
        handler(event);
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handler, key]);
}

export default useKeyPressHandler;