import React from 'react';

export default function TriangleShapeIcon() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.86602 1.5C9.68739 1.1906 9.35727 1 9 1C8.64273 1 8.31261 1.1906 8.13397 1.5L1.20577 13.5C1.02714 13.8094 1.02714 14.1906 1.20577 14.5C1.3844 14.8094 1.71453 15 2.0718 15H15.9282C16.2855 15 16.6156 14.8094 16.7942 14.5C16.9729 14.1906 16.9729 13.8094 16.7942 13.5L9.86602 1.5Z"
        stroke="#3B2651"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
