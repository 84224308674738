import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 6px;
  border-radius: 15px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  font-family: acumin-pro-wide;
  background: linear-gradient(90deg, #d1deff 0%, #d8c0f8 100%);
  color: rgba(59, 38, 81, 1);
  cursor: pointer;
`;

export const Text = styled.span`
  margin-left: 2px;
`;
