import React, { FC } from 'react';
import Select from '../../../Select';
import { PopoverBody, PopoverContent } from '@chakra-ui/react';
import Portal from '../../../PortalNew/PortalNew';

import { BottomDisclaimer, SelectTitle, SelectWrapper } from './styles';
import { languageNameOptions } from '../../../../Consts/languageOptions';
import { useLocalStorage } from 'usehooks-ts';
import { OptionTypeBase } from 'react-select';
import Button from '../../../Button';
import { useOrganization } from '../../../../Hooks/useOrganization';

interface LanguagePickerProps {
  onConfirm(lang: string): void;
}

const LanguagePicker: FC<LanguagePickerProps> = ({ onConfirm }) => {
  const [currentLanguage, setCurrentLanguage] = useLocalStorage<OptionTypeBase>(
    'dataSummaryLanguage',
    languageNameOptions[0]
  );
  const [, org] = useOrganization();
  return (
    <Portal wrapperId="langSelector">
      <PopoverContent _focus={{ boxShadow: 'none' }}>
        <PopoverBody>
          <SelectWrapper>
            <SelectTitle>Choose a language</SelectTitle>
            <Select
              options={languageNameOptions}
              onChange={(option) => {
                if (!option) return;
                setCurrentLanguage(option);
              }}
              value={currentLanguage}
              isSearchable={true}
            />
          </SelectWrapper>
          <Button
            type={'primary'}
            onClick={() => {
              onConfirm(currentLanguage.value);
            }}
          >
            Create Translation
          </Button>
          <BottomDisclaimer>
            This will use 1 of your {org.aiCredits} available credits
          </BottomDisclaimer>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
};

export default LanguagePicker;
