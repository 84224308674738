import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import ProjectChecklist from '../Components/Checklist/ProjectChecklist';
import Sidebar from '../Components/Sidebar';
import useMediaQuery from '../Hooks/useMediaQuery';

type DefaultLayoutProps = {
  children: ReactNode;
};

function SidebarLayout({ children }: DefaultLayoutProps): JSX.Element {
  const { dashboardId } = useParams<{ dashboardId: string; documentId: string }>();
  const { isPhone } = useMediaQuery();

  return (
    <div className={'flex-auto w-full flex'}>
      <Sidebar />
      <div className={'flex-auto'}>{children}</div>
      {!isPhone && <ProjectChecklist dashboardId={dashboardId} />}
    </div>
  );
}

export default SidebarLayout;
