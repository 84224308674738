import React, { PropsWithChildren, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import Header2 from '../Components/Header2/Header2';
import { useOrganization } from '../Hooks/useOrganization';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { useRouteMatch } from 'react-router-dom';
import { humanFileSize } from '../Utils/formatting';
import Button from '../Components/Button';
import { useEffectOnce } from 'usehooks-ts';

type DefaultLayoutProps = {
  children: ReactNode;
};

function Banner({ children }: PropsWithChildren<any>) {
  const history = useHistory();
  return (
    <div className="flex items-center justify-between px-5 py-2 bg-tooltip-purple">
      <div className="flex items-center">
        <InformationCircleIcon className="w-5 h-5 mr-2" />
        <div className="font-medium select-none">{children}</div>
      </div>
      <Button
        size="sm"
        className="min-w-[116px]"
        onClick={() => history.push('/settings/plans')}
        type="secondary"
      >
        Upgrade now
      </Button>
    </div>
  );
}

function DefaultLayout({ children }: DefaultLayoutProps): JSX.Element {
  const [loading, organization, refetchOrg] = useOrganization();

  const match = useRouteMatch<{ dashboardId: string }>('/projects/:dashboardId/notes');
  const matchPlans = useRouteMatch<{ dashboardId: string }>('/settings/plans');

  const paymentsEnabled = !match;
  const plan = organization?.plan || '';
  const upgradeAvailable = plan.toLowerCase().includes('free');
  const showTrialUpgradeButton = organization?.trialStatus === 'active' && !matchPlans;

  useEffectOnce(() => {
    setTimeout(() => {
      refetchOrg(); // fix for trials activation
    }, 3000);
  });

  if (loading) {
    return <></>;
  }

  let bannerMessage;
  if (organization) {
    if (organization.projectsLimitExceeded) {
      bannerMessage = `You've reached the project limit on the free plan. The most recent project is editable. To edit all projects or to create new projects, upgrade your plan.`;
    }

    if (organization.durationLimitExceeded) {
      bannerMessage = `You've reached your limit of ${
        parseInt(organization.durationLimitTotal) / 60 / 60 ||
        parseInt(organization.durationLimitMonthly) / 60 / 50
      } transcription hours in this workspace. Upgrade your plan to get more transcription hours.`;
    }

    if (organization.fileLimitExceeded) {
      bannerMessage = `You've reached your limit of ${humanFileSize(
        organization.fileSizeLimitTotal,
        true
      )} file storage in this workspace. Upgrade your plan to get more storage.`;
    }
  }

  return (
    <div className={'min-h-full bg-white w-full flex flex-col'}>
      {paymentsEnabled && bannerMessage && <Banner>{bannerMessage}</Banner>}
      <Header2
        showUpgradeBtn={upgradeAvailable && !bannerMessage}
        showTrialUpgradeButton={showTrialUpgradeButton}
      />
      {children}
    </div>
  );
}

export default DefaultLayout;
