import React, { FC } from 'react';

interface Props {
  children?: React.ReactNode;
}

const Icon32: FC<Props> = ({ children }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

const Tags: FC = () => {
  return (
    <Icon32>
      <path
        d="M11 11H11.01M11 7H16C16.5119 6.99999 17.0237 7.19525 17.4142 7.58579L24.4143 14.5858C25.1953 15.3668 25.1953 16.6332 24.4143 17.4142L17.4142 24.4142C16.6332 25.1953 15.3668 25.1953 14.5858 24.4142L7.58579 17.4142C7.19526 17.0237 7 16.5118 7 16V11C7 8.79086 8.79086 7 11 7Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon32>
  );
};

export default { Tags };
