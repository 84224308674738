import React from 'react';
import { Popover } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/outline';
import { STICKY_COLORS } from '../Consts';
import { ThemeIcon } from '../Icons';
import { Icon24 } from '../Icons/Icon';
import AIButton from './AIButton';

interface ThemeMenuProps {
  color: string;
  onColorChange: (value: string) => void;
  onDelete: () => void;
  onEdit: () => void;
  colorChangeEnabled?: boolean;
  themeId?: string;
  openGenerateSummaryModal(themeId: string | null): void;
}

export default function ThemeMenu({
  themeId,
  color,
  onDelete,
  onColorChange,
  onEdit,
  openGenerateSummaryModal,
  colorChangeEnabled = true,
}: ThemeMenuProps): JSX.Element {
  const handleGenerateSummaryClick = async () => {
    if (!themeId) return;
    openGenerateSummaryModal(themeId);
  };

  return (
    <div
      className={'-mt-12 center'}
      style={{
        marginLeft: '-50%',
      }}
    >
      <span className="relative z-0 inline-flex shadow-md rounded-md">
        <button
          onClick={() => onDelete()}
          className="relative inline-flex items-center px-3 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          style={{ color: '#3B2651' }}
        >
          <ThemeIcon />
          &nbsp;&nbsp;Untheme
        </button>
        <button
          onClick={() => onEdit()}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <PencilIcon stroke="#3B2651" className={'h-5 w-5'} />
        </button>
        {colorChangeEnabled && (
          <Popover className="relative">
            <Popover.Button className="-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <div
                className={'h-4 w-4 rounded-lg'}
                style={{
                  backgroundColor: color,
                }}
              />
            </Popover.Button>

            <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md">
              <div className="grid gap-1 grid-cols-5 w-[132px]">
                {STICKY_COLORS.map((x, index) => (
                  <a href="#" onClick={() => onColorChange(x)} key={index}>
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="11" cy="11" r="11" fill={x} />
                      {color == x && (
                        <path
                          d="M7 10.143L10.4286 13.5714L15 8.42871"
                          stroke="#382152"
                          strokeWidth="1.5"
                        />
                      )}
                    </svg>
                  </a>
                ))}
              </div>
            </Popover.Panel>
          </Popover>
        )}
        <div className="-ml-px relative inline-flex items-center px-1 rounded-r-md border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          <AIButton onClick={handleGenerateSummaryClick} />
        </div>
      </span>
    </div>
  );
}
