import React from 'react';

const StickyDuplicateIcon = ({ stroke = '#3B2651' }: { stroke?: string }): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_174_5979)">
        <path
          d="M6.37272 16.2864C5.93549 16.2864 5.51616 16.1124 5.20699 15.8027C4.89781 15.493 4.72412 15.0729 4.72412 14.6349V6.63489C4.72412 6.12851 4.92492 5.64287 5.28235 5.28481C5.63977 4.92674 6.12454 4.72559 6.63002 4.72559H14.6538C15.0759 4.72559 15.4807 4.89355 15.7791 5.19254C16.0776 5.49152 16.2453 5.89703 16.2453 6.31986V6.31986"
          stroke={stroke}
          strokeWidth="1.51"
        />
        <path
          d="M13.5104 19.2841H9.52712C9.2112 19.2841 8.90822 19.1583 8.68483 18.9345C8.46144 18.7108 8.33594 18.4072 8.33594 18.0907V9.49886C8.33594 9.18237 8.46144 8.87885 8.68483 8.65506C8.90822 8.43127 9.2112 8.30554 9.52712 8.30554H18.1037C18.4196 8.30554 18.7226 8.43127 18.9459 8.65506C19.1693 8.87885 19.2948 9.18237 19.2948 9.49886V13.7948L13.5104 19.2841Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M18.7896 13.8043H15.4162C14.9283 13.8036 14.4588 13.9903 14.1042 14.3259C13.7497 14.6616 13.5371 15.1207 13.5103 15.6086V18.7781"
          stroke={stroke}
          strokeWidth="1.46"
          strokeLinecap="square"
          strokeLinejoin="bevel"
        />
      </g>
      <defs>
        <clipPath id="clip0_174_5979">
          <rect width="16" height="16" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StickyDuplicateIcon;
