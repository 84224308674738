import { useMutation, useQuery } from '@apollo/client';

import { FETCH_HIGHLIGHTS } from '../GraphQL/queries';
import { CREATE_HIGHLIGHT, UPDATE_HIGHLIGHT, DELETE_HIGHLIGHT } from '../GraphQL/mutations';
import { ID } from '../Models';
import { useMemo } from 'react';
import { THighlight } from '../Models/Highlight';

export const useFetchDocumentHighlights = (documentId: string): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_HIGHLIGHTS, {
    variables: { condition: { documentId } },
  });
  return [loading, data?.highlights || []];
};

export const useFetchHighlights = ({
  documentId,
  transcriptionId,
  dashboardId,
}: {
  documentId?: string;
  transcriptionId?: string;
  dashboardId?: string;
}): [boolean, THighlight[], () => void] => {
  const emptyHighlights = useMemo(() => [], []);
  const { loading, data, refetch } = useQuery(FETCH_HIGHLIGHTS, {
    variables: { condition: { documentId, transcriptionId, dashboardId } },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  return [loading, data?.highlights || emptyHighlights, refetch];
};

export const useCreateHighlight = (): [(highlight: any) => any] => {
  const [createMutation] = useMutation(CREATE_HIGHLIGHT);

  return [
    async (highlight: any) => {
      const res = await createMutation({
        variables: {
          input: highlight,
        },
      });
      return res?.data?.createHighlight?.highlight;
    },
  ];
};

export const useUpdateHighlight = (): [(id: ID, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_HIGHLIGHT);

  return [
    (id: ID, input: any) => {
      return updateMutation({
        variables: {
          id,
          input: { ...input, updatedAt: new Date().toISOString() },
        },
      });
    },
  ];
};

export const useDeleteHighlight = (): [
  (id: ID, documentId?: string, transcriptionId?: string) => any
] => {
  const [deleteMutation] = useMutation(DELETE_HIGHLIGHT);

  return [
    async (id: ID, documentId?: string, transcriptionId?: string) => {
      if (!documentId && !transcriptionId) return;

      const res = deleteMutation({
        variables: {
          id,
        },
        optimisticResponse: {
          deleteHighlight: {
            highlight: {
              __typename: 'Highlight',
              id: id,
            },
          },
        },
        update: (cache, { data: { deleteHighlight } }) => {
          const data: any = cache.readQuery({
            query: FETCH_HIGHLIGHTS,
            variables: { condition: { documentId, transcriptionId } },
          });

          cache.writeQuery({
            query: FETCH_HIGHLIGHTS,
            variables: { condition: { documentId, transcriptionId } },
            data: {
              highlights: data.highlights.filter((x: any) => x.id !== deleteHighlight.highlight.id),
            },
          });
        },
        refetchQueries: [
          {
            query: FETCH_HIGHLIGHTS,
            variables: { condition: { documentId, transcriptionId } },
          },
        ],
      });

      return res;
    },
  ];
};

export default {
  useFetchDocumentHighlights,
  useFetchHighlights,
  useCreateHighlight,
  useUpdateHighlight,
  useDeleteHighlight,
};
