import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { TagIcon as TIcon, UserIcon as UIcon } from '@heroicons/react/outline';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  height: calc(100vh - 108px);
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BreadcrumbsItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 10px;

  &:first-child {
    margin-left: 0;
  }
`;

export const BreadcrumbsIcon = styled.div`
  margin-left: 5px;
`;

export const ProjectIcon = styled.div<{ src: string }>`
  width: 26px;
  height: 24px;
  margin-right: 10px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const CloseIcon = styled.div`
  margin-left: 20px;
  cursor: pointer;
`;

export const Details = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px;
`;

export const NameInput = styled(TextareaAutosize)`
  outline: none;
  color: #3b2651;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  width: 100%;
  resize: none;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
`;

export const UserIcon = styled(UIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  margin-right: 20px;
`;

export const UserName = styled.span`
  margin-left: 8px;
`;

export const TagIcon = styled(TIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const EditorContainer = styled.div`
  flex: 1;
  padding-left: 48px;
  padding-right: 48px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
`;

export const DotsButton = styled.div`
  position: relative;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
`;

export const MenuPanel = styled.div<{ show: boolean }>`
  position: absolute;
  top: 36px;
  right: 0;
  white-space: nowrap;
  display: none;
  ${({ show }) => show && `display: block;`}

  border-radius: 3px;
  border: 1px solid var(--Light-Purple-Gray, #d7d3dc);
  background-color: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

export const MenuPanelItem = styled.div<{ itemColor?: string }>`
  width: 100%;
  padding: 6px 40px 6px 10px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  ${({ itemColor }) => itemColor === 'red' && `color: #EA4335;`}

  &:hover {
    background: #f8f8f8;
  }
`;
