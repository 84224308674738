import React, { useRef } from 'react';
import { Popover } from '@headlessui/react';
import { PhotographIcon } from '@heroicons/react/outline';

import Button from '../Button';
import { CoverProps } from '../../Models';
import useFiles from '../../Hooks/useFiles';
import axios from 'axios';
import { Icon24 } from '../../Icons/Icon';
import { GrayText, IconContainer, UploadButton, UploadContainer } from './styles';

type CoverPhotoPopoverProps = {
  classNames?: string;
  title: string;
  position: string;
  onShowGenerationModal?: () => void;
  setIsLoading?: (isLoading: boolean) => void;
  onImageClick(image: CoverProps): void;
};

const CoverPhotoPopover = ({
  classNames,
  title,
  position,
  onShowGenerationModal,
  setIsLoading,
  onImageClick,
}: CoverPhotoPopoverProps): JSX.Element => {
  const { uploadFileToS3 } = useFiles();
  const cancel = axios.CancelToken.source();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleBrowseFilesClick = () => {
    inputRef.current?.click();
  };

  const handleUploadFile = async (file: File) => {
    try {
      setIsLoading && setIsLoading(true);
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        isPublic: true,
      });
      setIsLoading && setIsLoading(false);
      onImageClick({ name: 'custom', url: result.url, position: '50% 50%' });
    } catch {
      if (axios.isCancel(file)) {
        return;
      }
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      handleUploadFile(file);
    }
  };

  return (
    <Popover className="relative z-10">
      <Popover.Button className="">
        <Button className={classNames} type="secondary" as="div">
          <PhotographIcon className="w-4 h-4 mr-1" />
          {title}
        </Button>
      </Popover.Button>
      <Popover.Panel
        className={
          'absolute bg-white rounded-sm-md px-2 py-3' +
          (position === 'right' ? ' right-0' : ' left-0')
        }
        style={{
          width: '230px',
          boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #D7D3DC',
        }}
      >
        <UploadContainer>
          {onShowGenerationModal && (
            <Button type="secondary" onClick={onShowGenerationModal}>
              <IconContainer>
                <Icon24.Insight />
              </IconContainer>
              Create Image with AI
            </Button>
          )}

          <UploadButton onClick={handleBrowseFilesClick}>
            <IconContainer>
              <Icon24.Upload />
            </IconContainer>{' '}
            or upload a file
          </UploadButton>
          <GrayText>Images larger than 1200x500 work best .gif, .png, .jpg 5mb max</GrayText>
        </UploadContainer>
        <input
          id={'cover_upload'}
          name={'cover_upload'}
          accept={'.png, .jpg, .jpeg, .gif'}
          type={'file'}
          onChange={handleFileInputChange}
          className={'hidden'}
          ref={inputRef}
          multiple
        />
      </Popover.Panel>
    </Popover>
  );
};

export default CoverPhotoPopover;
