import React from 'react';

export default function StrokeWidthIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="none" />
      <line
        x1="3.75"
        y1="7.25"
        x2="20.25"
        y2="7.25"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="4.75"
        y1="17.25"
        x2="19.25"
        y2="17.25"
        stroke="#3B2651"
        strokeWidth="3.5"
        strokeLinecap="round"
      />
      <line x1="4" y1="12" x2="20" y2="12" stroke="#3B2651" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
