import React, { FC, ReactPropTypes } from 'react';

interface Props {
  children?: React.ReactNode;
}

const Icon: FC<Props> = ({ children }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

const Arrow: FC = () => {
  return (
    <Icon>
      <path
        d="M6 4L10 8L6 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const Close: FC = () => {
  return (
    <Icon>
      <path
        d="M4 4L12 12M4 12L12 4L4 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const CloseBig: FC = () => {
  return (
    <Icon>
      <path
        d="M1.28906 1L13.2891 13M1.28906 13L13.2891 1L1.28906 13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const CloseBold: FC = () => {
  return (
    <Icon>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.27616 4.39052C5.75545 3.86983 4.91123 3.86983 4.39052 4.39052C3.86983 4.91123 3.86983 5.75545 4.39052 6.27616L6.11439 8.00002L4.39052 9.72389C3.86983 10.2446 3.86983 11.0888 4.39052 11.6095C4.91123 12.1302 5.75545 12.1302 6.27616 11.6095L8.00002 9.88562L9.72389 11.6095C10.2446 12.1302 11.0888 12.1302 11.6095 11.6095C12.1302 11.0888 12.1302 10.2446 11.6095 9.72389L9.88562 8.00002L11.6095 6.27616C12.1302 5.75545 12.1302 4.91123 11.6095 4.39052C11.0888 3.86983 10.2446 3.86983 9.72389 4.39052L8.00002 6.11439L6.27616 4.39052Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const Chevron: FC = () => {
  return (
    <Icon>
      <path
        d="M12 6L8 10L4 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const SpinnerCircle: FC = () => {
  return (
    <Icon>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.8126 4.04799 14.9132 7.84015 14.9982H7.99957L7.99957 13.5C4.9622 13.4998 2.5 11.0374 2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8L15 8Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const Lightning: FC = () => {
  return (
    <Icon>
      <path
        d="M8.75 6.25V1L2 9.25H7.25V14.5L14 6.25H8.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const Pencil: FC = () => {
  return (
    <Icon>
      <path
        d="M10.1547 3.48522L12.512 5.83781M11.1547 2.48724C11.4673 2.17526 11.8912 2 12.3333 2C12.7754 2 13.1994 2.17526 13.512 2.48724C13.8246 2.79921 14.0002 3.22233 14.0002 3.66353C14.0002 4.10472 13.8246 4.52785 13.512 4.83982L4.33333 14H2V11.6235L11.1547 2.48724Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const Tick: FC = () => {
  return (
    <Icon>
      <path
        d="M3 8.85714L5.57143 11.4286L12 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const Dash: FC = () => {
  return (
    <Icon>
      <line
        x1="5.625"
        y1="8"
        x2="10.125"
        y2="8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Icon>
  );
};

const Delete: FC = () => {
  return (
    <Icon>
      <path
        d="M11.3341 11.0341C11.0058 11.3624 10.4738 11.3624 10.1462 11.0341L8.29052 8.91313L6.43482 11.0334C6.10652 11.3617 5.57452 11.3617 5.24692 11.0334C4.91862 10.7051 4.91862 10.1731 5.24692 9.84552L7.17752 7.64053L5.24623 5.43412C4.91793 5.10582 4.91793 4.57453 5.24623 4.24623C5.57453 3.91793 6.10582 3.91793 6.43412 4.24623L8.29052 6.36792L10.1462 4.24623C10.4745 3.91793 11.0058 3.91793 11.3341 4.24623C11.6624 4.57453 11.6624 5.10652 11.3341 5.43412L9.40352 7.64053L11.3341 9.84552C11.6624 10.1738 11.6624 10.7058 11.3341 11.0341Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const Insight: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.77778 1V4.55556V1ZM1 2.77778H4.55556H1ZM3.66667 13.4444V17V13.4444ZM1.88889 15.2222H5.44444H1.88889ZM9.88889 1L11.9206 7.09524L17 9L11.9206 10.9048L9.88889 17L7.85716 10.9048L2.77778 9L7.85716 7.09524L9.88889 1Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default {
  Arrow,
  Close,
  CloseBig,
  Chevron,
  SpinnerCircle,
  CloseBold,
  Lightning,
  Pencil,
  Tick,
  Dash,
  Delete,
  Insight,
};
