import React from 'react';
import ReactModal from 'react-modal';
import { XIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router';
import useDisableBackgroundScroll from '../Hooks/useDisableBackgroundScroll';

ReactModal.setAppElement('#root');

ReactModal.defaultStyles.content = {
  outline: 'none',
  border: '0',
  borderRadius: '4px',
  bottom: 'auto',
  minHeight: '10rem',
  left: '50%',
  position: 'relative',
  right: 'auto',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  minWidth: '20rem',
  maxWidth: '60rem',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 2rem)',
};

ReactModal.defaultStyles.overlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(26, 12, 41, 0.40)',
  zIndex: 100,
  height: '100vh',
  width: '100vw',
};

interface Props extends ReactModal.Props {
  title: string;
  classNames?: string;
  showTitle?: boolean;
  maxWidth?: string;
  onClose?: () => void;
}

export default function Modal({
  title,
  showTitle = true,
  classNames = '',
  maxWidth = '60rem',
  children,
  onClose,
  isOpen,
  ...rest
}: React.PropsWithChildren<Props>): JSX.Element {
  const history = useHistory();
  useDisableBackgroundScroll(isOpen);

  if (onClose === undefined) {
    onClose = () => history.goBack();
  }
  return (
    <ReactModal
      onRequestClose={onClose}
      style={{
        content: {
          maxWidth,
        },
      }}
      isOpen={isOpen}
      {...rest}
    >
      <div className="bg-white shadow-md rounded px-5 pt-5 pb-5">
        {showTitle && (
          <div className="font-body flex flex-row font-semibold text-lg pb-3 justify-between">
            <div>
              <div className={classNames}>{title}</div>
            </div>
            <div>
              <button onClick={() => onClose?.()}>
                <XIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
}
