import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  CloseButton,
  Container,
  EmptyStateText,
  Header,
  IconContainer,
  Item,
  ItemIcon,
  ItemName,
  ItemsList,
  Label,
  LoadingState,
  NewProjectButtonWrapper,
  NewProjectContainer,
  Overlay,
  SearchInputWrapper,
  Subtitle,
  Title,
  Wrapper,
} from './styles';
import { Icon24 } from '../../Icons/Icon';
import SearchInput from '../SearchInput';
import { useFetchLightBoards } from '../../Hooks/useBoards';
import { defaultProjectIcons } from '../../Consts/projectIcons';
import useTranscripts from '../../Hooks/useTranscripts';
import { useFetchAllDocuments } from '../../Hooks/useDocuments';
import {
  DocumentIcon,
  DocumentTextIcon,
  MusicNoteIcon,
  VideoCameraIcon,
} from '@heroicons/react/outline';
import Spinner from '../Spinner';
import { useApolloClient } from '@apollo/client';
import { FETCH_DOCUMENT, FETCH_TRANSCRIPT } from '../../GraphQL/queries';
import Button from '../Button';

export type TModalEntity = {
  id: string;
  name: string;
  cover?: string | null;
  file?: {
    type: string;
  };
  type?: 'transcript' | 'document';
  content?: string;
};

interface ChooseDataModalProps {
  type: 'transcript' | 'insight';
  title?: string;
  subtitile?: string;
  minimized?: boolean;
  position?: TPosition;
  onChooseEntity: (entity: TModalEntity) => void;
  onCreateNewProject?: (name: string) => void;
  onClose: () => void;
}

export type TPosition = {
  top: number;
  left: number;
};

const ChooseDataModal: FC<ChooseDataModalProps> = ({
  type,
  title,
  subtitile,
  minimized,
  position,
  onChooseEntity,
  onClose,
  onCreateNewProject,
}) => {
  const { fetchAllTranscripts } = useTranscripts();
  const [boardsLoading, dashboards] = useFetchLightBoards();
  const [transcriptsLoading, transcripts] = fetchAllTranscripts();
  const [loadingDocuments, documents] = useFetchAllDocuments();
  const [docLoadingId, setDocLoadingId] = useState('');
  const [searchText, setSearchText] = useState('');
  const client = useApolloClient();
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!minimized || (containerRef.current && containerRef.current.contains(e.target as Node)))
        return;
      onClose();
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [containerRef, minimized]);

  const filteredProjects = useMemo(() => {
    if (boardsLoading || !dashboards) {
      return;
    }
    return dashboards
      ?.filter((board) => board.name?.toLowerCase().includes(searchText.toLowerCase()))
      .map((board) => ({
        id: board.id,
        name: board.name,
        cover: board.cover,
        createdAt: new Date(board.createdAt).getTime(),
      }))
      .sort((a, b) => b.createdAt - a.createdAt);
  }, [searchText, boardsLoading, dashboards]);

  const filteredDocs = useMemo(() => {
    if (transcriptsLoading || loadingDocuments || !transcripts || !documents) {
      return [];
    }

    const filteredTranscripts = transcripts
      ?.filter((transcript: any) =>
        transcript.name?.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((transcript: any) => ({
        id: transcript.id,
        name: transcript.name,
        type: 'transcript',
        file: transcript.file,
      }));

    const filteredDocuments = documents
      ?.filter((document: any) => document.name?.toLowerCase().includes(searchText.toLowerCase()))
      .map((document: any) => ({
        id: document.id,
        name: document.name,
        type: 'document',
      }));

    return [...filteredTranscripts, ...filteredDocuments];
  }, [documents, searchText, transcripts, transcriptsLoading, loadingDocuments]);

  const getIconUrl = (cover?: string) => {
    const iconData = defaultProjectIcons.find((icon) => icon.name === cover);
    return iconData?.url || defaultProjectIcons[0].url;
  };

  const getFileIcon = (doc: TModalEntity) => {
    if (doc.type === 'transcript') {
      return doc.file ? (
        doc.file.type === 'audio' ? (
          <MusicNoteIcon className={'w-6 h-6 mr-2'} />
        ) : (
          <VideoCameraIcon className={'w-6 h-6 mr-2'} />
        )
      ) : (
        <DocumentTextIcon className={'w-6 h-6 mr-2'} />
      );
    }
    return <DocumentIcon className={'w-6 h-6 mr-2'} />;
  };

  const getTitle = () => {
    return title ? title : type === 'insight' ? 'Choose a project' : 'Choose a file';
  };

  const getSubtitle = () => {
    return subtitile
      ? subtitile
      : type === 'insight'
      ? 'Choose a project in your workspace to use as sample output to test your new AI template.'
      : 'Choose a file in your workspace to use as sample output to test your new AI template.';
  };

  const shouldShowLoading = () => {
    return (
      (type === 'insight' && boardsLoading) ||
      (type === 'transcript' && (transcriptsLoading || loadingDocuments))
    );
  };

  const handleChooseTranscript = async (entityId: string) => {
    setDocLoadingId(entityId);
    const {
      data: { transcription },
    } = await client.query({
      query: FETCH_TRANSCRIPT,
      variables: {
        id: entityId,
      },
    });

    setDocLoadingId('');
    onClose();
    onChooseEntity({
      id: transcription.id,
      name: transcription.name,
      type: 'transcript',
      file: transcription.file,
      content: transcription.text,
    });
  };

  const handleChooseDocument = async (entityId: string) => {
    setDocLoadingId(entityId);
    const {
      data: { document },
    } = await client.query({
      query: FETCH_DOCUMENT,
      variables: {
        id: entityId,
      },
    });

    setDocLoadingId('');
    onClose();
    onChooseEntity({
      id: document.id,
      name: document.name,
      type: 'document',
      content: document.content,
    });
  };

  const handleChooseEntity = (entity: TModalEntity) => {
    if (type === 'insight') {
      onChooseEntity(entity);
      onClose();
    } else {
      if (entity.type === 'transcript') {
        handleChooseTranscript(entity.id);
      } else {
        handleChooseDocument(entity.id);
      }
    }
  };

  const filteredData = type === 'insight' ? filteredProjects : filteredDocs;
  const showNewProjectButton =
    onCreateNewProject && type === 'insight' && !!searchText.length && !filteredData?.length;

  return (
    <Wrapper minimized={minimized} left={position?.left} top={position?.top}>
      {!minimized && <Overlay onClick={onClose} />}
      <Container minimized={minimized} ref={containerRef}>
        {!minimized && (
          <Header>
            <Title>{getTitle()}</Title>
            <CloseButton onClick={onClose}>
              <Icon24.Close />
            </CloseButton>
          </Header>
        )}

        <Subtitle minimized={minimized}>{getSubtitle()}</Subtitle>
        <SearchInputWrapper minimized={minimized}>
          <SearchInput
            value={searchText}
            onChange={setSearchText}
            placeholder={onCreateNewProject ? 'Find or create new project' : ''}
            size="small"
          />
        </SearchInputWrapper>

        {!!filteredData?.length && !searchText && (
          <Label minimized={minimized}>Recent {type === 'insight' ? 'projects' : 'files'}</Label>
        )}
        <ItemsList minimized={minimized}>
          {shouldShowLoading() && (
            <LoadingState>
              <Spinner />
            </LoadingState>
          )}

          {filteredData?.map((entity) => (
            <Item
              key={entity.id}
              onClick={() => {
                if (docLoadingId) return;
                handleChooseEntity(entity);
              }}
            >
              {docLoadingId === entity.id ? (
                <IconContainer>
                  <Spinner />
                </IconContainer>
              ) : type === 'insight' ? (
                <ItemIcon src={getIconUrl(entity.cover || '')} />
              ) : (
                <IconContainer>{getFileIcon(entity)}</IconContainer>
              )}
              <ItemName>{entity.name || 'Untitled project'}</ItemName>
            </Item>
          ))}

          {searchText.length > 0 && !filteredData?.length && (
            <EmptyStateText>No results</EmptyStateText>
          )}

          {showNewProjectButton && (
            <NewProjectContainer>
              <EmptyStateText>{`Create a new project "${searchText}"`}</EmptyStateText>
              <NewProjectButtonWrapper>
                <Button
                  type="primary"
                  size="md"
                  onClick={() => onCreateNewProject && onCreateNewProject(searchText)}
                >
                  Create new project
                </Button>
              </NewProjectButtonWrapper>
            </NewProjectContainer>
          )}
        </ItemsList>
      </Container>
    </Wrapper>
  );
};

export default ChooseDataModal;
