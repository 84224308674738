import { DEFAULT_STICKY_COLOR } from '../Models';

export const STICKY_COLORS = [
  '#FDE09E',
  '#FDD3D1',
  '#F3BEC9',
  '#FEE9F6',
  '#D5D0FD',
  '#F0DCF6',
  '#CEEAF8',
  '#DAEFF6',
  '#D0F7EA',
  '#D0F9B0',
  '#FBC96D',
  '#FCA5AB',
  '#DC94AA',
  '#FCC8F7',
  '#ABA3F4',
  '#B08ACA',
  '#6AA9D9',
  '#86B3CB',
  '#91CFC6',
  '#A1D680',
  '#D7D3DC',
  '#FEF1CE',
];

export const TEXT_COLORS = [
  '#134883',
  '#243D52',
  '#3B2651',
  '#E51F68',
  '#3B70E9',
  '#69AAA9',
  '#8E84EE',
  '#8D4067',
  '#000000',
  '#F38E0E',
  '#D12D28',
  '#FF7E7C',
  '#A69EAF',
  '#D7D3DC',
  '#E5E5E5',
];

export const GROUPING_COLORS = {
  themeColor: '#E5E5E5',
  default: '#fff' || DEFAULT_STICKY_COLOR,
  sentimentScore: {
    neutral: '#E5E5E5',
    positive: '#A0E4CA',
    negative: '#FAB6B6',
  },
  tag: '#D4C2F4',
  participant: '#D4C2F4',
};

export const TEXT_FONT_SIZES = [10, 12, 14, 16, 18, 20, 24, 32, 36, 48, 64, 96, 128];

export const TEXT_STROKE_WIDTH_SIZES = [1, 2, 3, 4, 5, 6, 7, 8];

export const LINE_ARROW_DETAILS = ['line', 'arrowLeft', 'arrowRight', 'arrowBoth'];

export const LINE_STROKE_SIZES = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20];
