import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 0px;
  margin-bottom: 16px;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GrowSpace = styled.div`
  flex-grow: 1;
`;

export const FeaturesCard = styled.div`
  width: 200px;
`;
export const BillingTermsSwitcher = styled.div`
  padding-bottom: 32px;
  height: 280px;
  display: flex;
  align-items: center;
`;
export const FeatureTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3b2651;
  height: 40px;
  border-top: 1px solid #d7d3dc;
  display: flex;
  align-items: center;
`;
export const PlansContainer = styled.div``;
export const PlansHeaders = styled.div`
  display: flex;
`;
export const PlanHeader = styled.div`
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  padding: 16px;
`;
export const PlansCards = styled.div`
  display: flex;
  flex: 1;
`;
export const PlanCard = styled.div<{ active?: boolean; isTrial?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 6px;
  margin-bottom: 2px;
  padding-top: 61px;

  ${({ active, isTrial }) =>
    active &&
    `
    padding-top: 0;
    border: 2px solid ${isTrial ? '#D2CEF8' : '#D7D3DC'};
    margin: -2px;
    margin-bottom: 0px;
    box-sizing: content-box;
    overflow: hidden;
  `}
`;
export const PlanCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 220px;
  font-weight: 500;
  font-size: 14px;
  color: #382152;
  padding: 8px;
`;
export const AdjustSeatsButton = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: rgba(56, 33, 82, 0.1);
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IncreaseSeatsButton = styled(AdjustSeatsButton)`
  background-color: rgba(59, 38, 81, 0.4);
`;
export const DecreaseSeatsButton = styled(AdjustSeatsButton)``;
export const SeatsCount = styled.div`
  user-select: none;
`;

export const PlanCardFeatures = styled.div``;
export const PlanCardFeature = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3b2651;
  height: 40px;
  border-top: 1px solid #d7d3dc;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
export const PlanName = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

export const PlanDescription = styled.div`
  height: 80px;
`;
export const PlanPriceContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;
export const PlanPrice = styled.span`
  font-weight: 700;
  font-size: 32px;
`;

export const BottomLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3b2651;
  margin-bottom: 64px;
`;
export const BottomLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div<{ mr?: string; ml?: string }>`
  height: 20px;
  width: 20px;
  margin-left: ${({ ml }) => (ml || 0) + 'px'};
  margin-right: ${({ mr }) => (mr || 0) + 'px'};
`;

export const TooltipWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
`;

export const ModalText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const PlanCardActiveHeader = styled.div<{ isTrial?: boolean }>`
  width: 100%;
  background-color: ${({ isTrial }) => (isTrial ? '#D2CEF8' : '#D7D3DC')};
  padding: 12px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
`;

export const PlansHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
`;

export const PlansWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const RadioInputs = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioInputCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d7d3dc;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3b2651;
  }
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const RadioInputContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 27px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  user-select: none;
  margin-right: 20px;

  & ${RadioInput}:checked ~ ${RadioInputCheckmark}:after {
    display: block;
  }
`;

export const CustomQuoteText = styled.div`
  font-size: 18px;
  line-height: 48px;
  font-weight: 500;
`;

export const FAQQuestion = styled.h3`
  font-size: 16px;
  font-weight: 700;
`;
export const FAQTitle = styled.h2`
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const ContactUsText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const FAQLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;
