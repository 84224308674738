/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';
import { useGenerateSummary } from '../Hooks/useSummary';
import { useIntercom } from 'react-use-intercom';
import { useInterval } from 'usehooks-ts';

export type SummaryGenerationResult = {
  content: string;
};

type GenerationSettings = {
  content: string[];
  documentId?: string;
  transcriptId?: string;
  outputSize: number;
  templateId: string;
  onFinish?: (result: SummaryGenerationResult) => void;
};

type GenerationData = {
  summaryId: string;
};

interface SummaryGenerationContextProps {
  generating: boolean;
  generateSummary(settings?: GenerationSettings): void;
}

export const SummaryGenerationContext = React.createContext<SummaryGenerationContextProps>({
  generating: false,
  generateSummary: async () => {},
});

export const SummaryGenerationContextProvider: FC = ({ children }) => {
  const [generating, setGenerating] = useState(false);
  const [customOnFinish, setCustomOnFinish] = useState<
    ((result: SummaryGenerationResult) => void) | null
  >();
  const [currentGenerationData, setCurrentGenerationData] = useState<GenerationData | null>(null);

  const { generateDataSummary, fetchSummary } = useGenerateSummary();
  const intercom = useIntercom();

  useInterval(
    async () => {
      if (!currentGenerationData) return;
      const { summaryId } = currentGenerationData;

      const data = await fetchSummary(summaryId);

      if (data.status === 'ready') {
        setGenerating(false);
        customOnFinish && customOnFinish({ content: data.content || '' });
        setCurrentGenerationData(null);
        setCustomOnFinish(null);
      } else if (data.status === 'error') {
        setGenerating(false);
        setCurrentGenerationData(null);
        intercom.showNewMessage('Hi, I am having trouble generating summary. Please help me out.');
      }
    },
    // Delay in milliseconds or null to stop it
    currentGenerationData && currentGenerationData.summaryId ? 3000 : null
  );

  const generateSummary = async (settings: GenerationSettings) => {
    setGenerating(true);

    const { success, summaryId, status } = await generateDataSummary({
      quotes: settings.content,
      documentId: settings.documentId,
      transcriptId: settings.transcriptId,
      templateId: settings.templateId,
    });

    if (!summaryId) return setGenerating(false); // TODO: handle error and show error message to the user!

    settings.onFinish && setCustomOnFinish(() => settings.onFinish);
    setCurrentGenerationData({ summaryId });
  };

  return (
    <SummaryGenerationContext.Provider
      value={{
        generating,
        generateSummary,
      }}
    >
      {children}
    </SummaryGenerationContext.Provider>
  );
};
