import React, { FC, useEffect, useMemo, useState } from 'react';
import { Icon24 } from '../../../Icons/Icon';
import { formatDistance } from 'date-fns';
import Avatar from '../../Avatar';
import { ID, TComment } from '../../../Models';

import {
  Container,
  Content,
  DateText,
  Header,
  InputWrapper,
  LikeIcon,
  LikesContainer,
  LikesCount,
  MenuButton,
  MenuPanel,
  MenuPanelItem,
  UserName,
  InputButtons,
  ButtonWrapper,
  LikesWrapper,
} from './styles';
import {
  useCreateCommentLike,
  useDeleteCommentLike,
  useDeleteInsightComment,
  useUpdateInsightComment,
} from '../../../Hooks/useInsights';
import useUsers from '../../../Hooks/useUsers';
import Input from '../../Input/Input';
import Button from '../../Button';

interface ICommentProps {
  insightId: ID;
  comment?: TComment;
  readonly?: boolean;
}

const Comment: FC<ICommentProps> = ({ insightId, comment, readonly }) => {
  const { currentUser: getCurrentUser } = useUsers();
  const [, currentUser] = getCurrentUser();
  const [createLike] = useCreateCommentLike();
  const [deleteLike] = useDeleteCommentLike();
  const [updateComment] = useUpdateInsightComment();
  const [deleteComment] = useDeleteInsightComment();
  const [showMenuPanel, setShowMenuPanel] = useState(false);
  const [updatedComment, setUpdatedComment] = useState('');
  const [editModeOn, setEditModeOn] = useState(false);

  useEffect(() => {
    if (comment) {
      setUpdatedComment(comment.content);
    }
  }, [comment]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      e.stopPropagation();
      setShowMenuPanel(false);
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleUpdateComment = () => {
    if (comment && comment.content !== updatedComment && updatedComment.length) {
      updateComment(comment.id, insightId, updatedComment);
      setEditModeOn(false);
    }
  };

  const hasCurrentUserLike = useMemo(
    () =>
      comment ? comment.likes.some((like) => like.userByCreatedBy.id === currentUser.id) : false,
    [comment, currentUser]
  );

  const handleLikeClick = () => {
    if (!comment || readonly) return;
    if (hasCurrentUserLike) {
      const like = comment.likes.find((like) => like.userByCreatedBy.id === currentUser.id);
      like && parseInt(like.id.toString()) > 0 && deleteLike(insightId, like.id);
    } else {
      createLike(insightId, comment.id);
    }
  };

  if (!comment) return null;
  return (
    <Container neverShowMenuButton={readonly || currentUser?.id !== comment.userByCreatedBy.id}>
      <Header>
        <Avatar user={comment.userByCreatedBy} />
        <UserName>{comment.userByCreatedBy.name}</UserName>
        <DateText>
          {formatDistance(new Date(comment.createdAt), new Date(), { addSuffix: true })}
        </DateText>
      </Header>
      {editModeOn ? (
        <InputWrapper>
          <Input
            value={updatedComment}
            onChange={(e) => setUpdatedComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleUpdateComment();
              }
            }}
            placeholder="Add a comment..."
            autoFocus
          />
        </InputWrapper>
      ) : (
        <Content>{comment.content}</Content>
      )}

      <LikesContainer>
        <LikesWrapper onClick={handleLikeClick}>
          <LikeIcon isActive={hasCurrentUserLike}>
            <Icon24.ThumbUp />
          </LikeIcon>
          {!!comment.likes.length && <LikesCount>{comment.likes.length}</LikesCount>}
        </LikesWrapper>

        {editModeOn && (
          <InputButtons>
            <ButtonWrapper>
              <Button
                type="secondary"
                onClick={() => {
                  setUpdatedComment(comment.content);
                  setEditModeOn(false);
                }}
              >
                Cancel
              </Button>
            </ButtonWrapper>
            <Button disabled={comment.content === updatedComment} onClick={handleUpdateComment}>
              Update
            </Button>
          </InputButtons>
        )}
      </LikesContainer>

      <MenuButton
        neverShow={readonly || currentUser?.id !== comment?.userByCreatedBy?.id}
        show={showMenuPanel}
        onClick={() => {
          if (readonly) return;
          setShowMenuPanel(!showMenuPanel);
        }}
      >
        <Icon24.Dots />

        <MenuPanel show={showMenuPanel}>
          <MenuPanelItem
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => setEditModeOn(true)}
          >
            Edit
          </MenuPanelItem>
          <MenuPanelItem
            itemColor="red"
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() =>
              comment &&
              parseInt(comment.id.toString()) > 0 &&
              deleteComment(comment?.id, insightId)
            }
          >
            Delete
          </MenuPanelItem>
        </MenuPanel>
      </MenuButton>
    </Container>
  );
};

export default Comment;
