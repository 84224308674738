import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  background-color: #ffffff;
  opacity: 0.5;
  z-index: 10;
`;

export const EmptyState = styled.div`
  width: 100%;
  padding: 20px;
`;

export const EmptyStateContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  border-radius: 12px;
  border: 1px solid rgba(215, 211, 220, 0.7);
`;

export const ImageUploader = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const ImageUploaderText = styled.div`
  display: flex;
  align-items: center;
  color: rgba(59, 38, 81, 0.6);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
`;

export const ImageUploaderIconWrapper = styled.div`
  margin-right: 4px;
`;
