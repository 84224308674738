/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';
import { useImageGeneration } from '../Hooks/useImageGeneration';
import { useInterval } from 'usehooks-ts';
import { useIntercom } from 'react-use-intercom';

export type TSample = {
  src: string;
};

export type TGenerationData = {
  id: string;
  status: string;
  samples: TSample[];
};

interface CoverPhotoGenerationContextProps {
  generatedSamples: TSample[];
  prompt: string;
  isCoverGenerating: boolean;
  isPromptGenerating: boolean;
  setPrompt(prompt: string): void;
  generatePromptByInsight(insightContent: string, styleLabel: string): void;
  generateCoverPhotos(dashboardId: string, styleOption: string, prompt: string): void;
}

export const CoverPhotoGenerationContext = React.createContext<CoverPhotoGenerationContextProps>({
  generatedSamples: [],
  prompt: '',
  isCoverGenerating: false,
  isPromptGenerating: false,
  setPrompt: () => {},
  generatePromptByInsight: async () => {},
  generateCoverPhotos: async () => {},
});

export const CoverPhotoGenerationContextProvider: FC = ({ children }) => {
  const [isCoverGenerating, setIsCoverGenerating] = useState(false);
  const [isPromptGenerating, setIsPromptGenerating] = useState(false);
  const [currentGenerationData, setCurrentGenerationData] = useState<TGenerationData | null>(null);
  const [prompt, setPrompt] = useState('');
  const [generatedSamples, setGeneratedSamples] = useState<TSample[]>([]);

  const { fetchImageGenerationTask, generateInsightCover, generateSDPrompt } = useImageGeneration();
  const intercom = useIntercom();

  useInterval(
    async () => {
      if (!currentGenerationData) return;
      const { id } = currentGenerationData;
      const data = await fetchImageGenerationTask(id);

      if (data.status === 'ready') {
        setIsCoverGenerating(false);
        const images = JSON.parse(data.result);
        setGeneratedSamples(images.map((image: string) => ({ src: image })));
        setCurrentGenerationData(null);
      } else if (data.status === 'error') {
        setIsCoverGenerating(false);
        setCurrentGenerationData(null);
        intercom.showNewMessage(
          'Hi, I am having trouble generating cover for the insight. Please help me out.'
        );
      }
    },
    currentGenerationData && currentGenerationData.id ? 3000 : null
  );

  const generatePromptByInsight = async (insightContent: string, styleLabel: string) => {
    if (!insightContent.length) return;

    setPrompt('');
    setIsPromptGenerating(true);

    const { prompt } = await generateSDPrompt(insightContent, styleLabel);

    setPrompt(prompt || '');
    setIsPromptGenerating(false);
  };

  const generateCoverPhotos = async (dashboardId: string, styleOption: string, prompt: string) => {
    if (!prompt) return;
    const { taskId } = await generateInsightCover(dashboardId, styleOption, prompt);
    setIsCoverGenerating(true);
    setGeneratedSamples([]);
    setCurrentGenerationData({ id: taskId, status: 'generating', samples: [] });
  };

  return (
    <CoverPhotoGenerationContext.Provider
      value={{
        prompt,
        generatedSamples,
        isCoverGenerating,
        isPromptGenerating,
        setPrompt,
        generatePromptByInsight,
        generateCoverPhotos,
      }}
    >
      {children}
    </CoverPhotoGenerationContext.Provider>
  );
};
