import React, { FC } from 'react';
import { Divider, ReactionsAndComments, ReactionsAndCommentsItem } from './styles';
import InsightStats from '../InsightStats';
import Reactions from '../Reactions';
import Comments from '../Comments';
import { ID } from '../../Models';

interface StatsReactionsCommentsProps {
  insightId: ID | undefined;
  readonly?: boolean;
  viewsCount?: number;
  evidenceCount?: number;
}

const StatsReactionsComments: FC<StatsReactionsCommentsProps> = ({
  insightId,
  readonly,
  viewsCount,
  evidenceCount,
}) => {
  return (
    <ReactionsAndComments>
      {insightId && (
        <ReactionsAndCommentsItem>
          <InsightStats
            insightId={insightId}
            viewsCount={viewsCount}
            evidenceCount={evidenceCount}
          />
        </ReactionsAndCommentsItem>
      )}
      {insightId && (
        <ReactionsAndCommentsItem>
          <Reactions insightId={insightId} readonly={readonly} />
        </ReactionsAndCommentsItem>
      )}
      <ReactionsAndCommentsItem>
        <Divider />
      </ReactionsAndCommentsItem>

      {insightId && (
        <ReactionsAndCommentsItem>
          <Comments insightId={insightId} readonly={readonly} />
        </ReactionsAndCommentsItem>
      )}
    </ReactionsAndComments>
  );
};

export default StatsReactionsComments;
