import { FC } from 'react';
import { Container, Step } from './styles';
import React from 'react';

interface StepProgressProps {
  totalSteps: number;
  currentStep: number;
}

const StepProgress: FC<StepProgressProps> = ({ totalSteps, currentStep }) => {
  return (
    <Container>
      {[...Array(totalSteps).keys()].map((_, index) => (
        <Step key={index} isActive={index <= currentStep - 1} />
      ))}
    </Container>
  );
};

export default StepProgress;
