import React from 'react';
export default function AnalysisIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.14648 0.896484H13.854C14.5443 0.896484 15.104 1.45613 15.104 2.14648V13.854C15.104 14.5443 14.5443 15.104 13.854 15.104H2.14649C1.45613 15.104 0.896484 14.5443 0.896484 13.854V2.14649C0.896484 1.45613 1.45613 0.896484 2.14648 0.896484Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M0.146484 6.31836H15.854" stroke="#3B2651" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M0.146484 10.9395H15.854" stroke="#3B2651" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M4.76807 0.146484V15.854" stroke="#3B2651" strokeWidth="1.5" strokeMiterlimit="10" />
    </svg>
  );
}
