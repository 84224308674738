import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Step = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 6px;
  background-color: ${({ isActive }) => (isActive ? '#3B2651' : '#D9D9D9')};
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;
