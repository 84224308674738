import React from 'react';
import { useDrag } from 'react-dnd';
import { useHistory } from 'react-router-dom';
import { MenuRow, TableRow } from '../TableSimple';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Avatar from '../Avatar';
import { DropDownMenu } from '../DropDownMenu';
import { Menu } from '@headlessui/react';

import { DotsHorizontalIcon, FolderIcon } from '@heroicons/react/outline';
import {
  AvatarWrapper,
  FolderInfo,
  FolderName,
  ProjectDate,
  ProjectDateContainer,
  ProjectIconWrapper,
  ProjectName,
  RightSideContainer,
  UserInfo,
  UserName,
  ViewOnly,
} from './styles';

export const ItemTypes = {
  LIST_ITEM: 'list_item',
};

export type TListItem = {
  icon?: { url?: string; element?: JSX.Element };
  id: string;
  itemType?: string;
  timestamp: string;
  user: { name: string; picture: string };
  name: string;
  folderId: string | null;
  folder?: { id: string; name: string };
  viewOnly: boolean;
  url?: string;
  actions?:
    | { handler: (item?: TListItem) => void | Promise<void>; name: string; icon: JSX.Element }[]
    | null;
};

type ListItemProps = {
  item: TListItem;
  className?: string;
  onItemClick?: (item: TListItem) => void;
};

// onRowClick?
const ListItem = ({ item, className, onItemClick }: ListItemProps): JSX.Element => {
  const [{ isDragging, opacity }, drag] = useDrag(() => ({
    item: item,
    type: ItemTypes.LIST_ITEM,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.3 : 1,
    }),
  }));
  const history = useHistory();

  return (
    <div
      ref={drag}
      key={item.id}
      style={{
        boxShadow: '0px 1px 0px #D7D3DC',
        backgroundColor: 'white',
        marginBottom: '1px',
        opacity,
      }}
    >
      <TableRow
        onClick={() => {
          if (onItemClick) {
            onItemClick(item);
          } else if (item.url) {
            history.push(item.url);
          }
        }}
        className={className}
      >
        <div className="flex text-left items-center justify-between">
          <div className="flex flex-row flex-1 text-ellipsis whitespace-nowrap overflow-hidden">
            <ProjectIconWrapper>
              {item.icon && item.icon.url && (
                <img
                  src={item.icon.url}
                  alt="cover image"
                  style={{
                    width: 24,
                    height: 24,
                    objectFit: 'cover',
                  }}
                />
              )}
              {item.icon && item.icon.element}
            </ProjectIconWrapper>
            <ProjectName>{item.name}</ProjectName>
          </div>
        </div>
        <RightSideContainer>
          {item.viewOnly && (
            <>
              <ViewOnly data-tip data-for="viewOnlyProject">
                View only
              </ViewOnly>

              <ReactTooltip
                id="viewOnlyProject"
                arrowColor="#3B2651"
                eventOff="none"
                effect="solid"
                // border={false}
                backgroundColor="#3B2651"
                textColor="white"
              >
                <span className="text-sm font-medium">
                  This project is view-only. To edit <br />
                  this project, upgrade to a paid plan.
                </span>
              </ReactTooltip>
            </>
          )}
          <div className="flex flex-row items-center flex-none justify-end pl-3 text-primary-purple-light-60">
            <FolderInfo>
              {item.folder && (
                <>
                  <FolderIcon
                    width={24}
                    height={24}
                    color="var(--brand-hint-text, rgba(59, 38, 81, 0.4))"
                  />
                  <FolderName>{item.folder.name}</FolderName>
                  <div className="bg-primary-purple-light rounded-full w-[3px] h-[3px]"></div>
                </>
              )}
            </FolderInfo>
          </div>
          <ProjectDateContainer>
            <ProjectDate className="text-primary-purple-light-60">
              {moment(item.timestamp).format('MMM Do, YYYY')}
            </ProjectDate>
            <div className="bg-primary-purple-light rounded-full w-[3px] h-[3px] mx-3"></div>
          </ProjectDateContainer>
          <UserInfo>
            <AvatarWrapper>
              <Avatar user={item.user} />
            </AvatarWrapper>
            <UserName>{item.user?.name}</UserName>
          </UserInfo>
          <div className={'w-30'}>
            {item.actions && item.actions.length > 0 && (
              <DropDownMenu icon={<DotsHorizontalIcon className={'mt-1 mx-2 w-4 h-4'} />}>
                {item.actions.map((action) => {
                  return (
                    <Menu.Item key={action.name}>
                      <MenuRow
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          action.handler(item);
                        }}
                      >
                        {action.icon}
                        {action.name}
                      </MenuRow>
                    </Menu.Item>
                  );
                })}
              </DropDownMenu>
            )}
          </div>
        </RightSideContainer>
      </TableRow>
    </div>
  );
};

export default ListItem;
