import React from 'react';
import { ParticipantFilter as ParticipantFilterType } from './ParticipantsWrapper';
import Select from '../../Components/Select';
import { OptionTypeBase } from 'react-select';
import { values } from 'lodash-es';

export const SORT_BY_OPTIONS = {
  newest: 'Newest',
  oldest: 'Oldest',
  impact: 'Impact',
  abc: 'Alphabetically',
  lastParticipated: 'Last participated',
};
export const LAST_PARTICIPATED_OPTIONS = {
  any: 'Any date',
  last30: 'Last 30 days',
  last6m: 'Last 6 months',
  lastYear: 'Last year',
  never: 'Never',
};

interface Props {
  companies: string[];
  roles: string[];
  filteredParams: ParticipantFilterType;
  setFilteredParams: (arg: ParticipantFilterType) => void;
}

const ParticipantFilter = ({
  companies,
  roles,
  filteredParams,
  setFilteredParams,
}: Props): JSX.Element => {
  return (
    <div
      className={
        'flex flex-col w-56 border-r border-gray-200 py-5 px-2 shrink-0 font-medium text-sm overflow-y-scroll'
      }
    >
      <p className="text-md font-medium mt-1">Filter</p>
      <br />

      <p className="mb-2">Companies</p>
      <Select
        options={[
          { value: 'All companies', label: 'All companies' },
          ...companies.map((company) => ({ value: company, label: company })),
        ]}
        onChange={(option) => {
          if (!option) return;
          setFilteredParams({ ...filteredParams, company: (option as OptionTypeBase).value });
        }}
        isSearchable
        value={{ value: filteredParams.company, label: filteredParams.company }}
      />

      <br />
      <p className="mb-2">Roles</p>
      <Select
        options={[
          { value: 'All roles', label: 'All roles' },
          ...roles.map((role) => ({ value: role, label: role })),
        ]}
        onChange={(option) => {
          if (!option) return;
          setFilteredParams({ ...filteredParams, role: (option as OptionTypeBase).value });
        }}
        isSearchable
        value={{ value: filteredParams.role, label: filteredParams.role }}
      />
      <br />

      <p className="mb-2">Last participated</p>
      <Select
        options={values(LAST_PARTICIPATED_OPTIONS).map((option) => ({
          value: option,
          label: option,
        }))}
        onChange={(option) => {
          if (!option) return;
          setFilteredParams({
            ...filteredParams,
            lastParticipated: (option as OptionTypeBase).value,
          });
        }}
        value={{ value: filteredParams.lastParticipated, label: filteredParams.lastParticipated }}
      />
      <br />

      <p className="mb-2">Sort by</p>
      <Select
        options={values(SORT_BY_OPTIONS).map((option) => ({ value: option, label: option }))}
        onChange={(option) => {
          if (!option) return;
          setFilteredParams({ ...filteredParams, sortBy: (option as OptionTypeBase).value });
        }}
        value={{ value: filteredParams.sortBy, label: filteredParams.sortBy }}
      />
    </div>
  );
};

export default ParticipantFilter;
