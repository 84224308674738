/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';

interface SpeakersContextProps {
  speakerNames: string[];
  setSpeakerNames(speakerNames: string[]): void;
}

export const SpeakersContext = React.createContext<SpeakersContextProps>({
  speakerNames: [],
  setSpeakerNames: () => {},
});

interface SpeakersContextProviderProps {
  children: React.ReactNode;
}

export const SpeakersContextProvider: FC<SpeakersContextProviderProps> = ({ children }) => {
  const [speakerNames, setSpeakerNames] = useState<Set<string>>(new Set());
  const addSpeakerNames = (speakerNames: string[]) => {
    setSpeakerNames((currentSpeakerNames) => new Set([...speakerNames]));
  };

  return (
    <SpeakersContext.Provider
      value={{
        speakerNames: [...speakerNames],
        setSpeakerNames: addSpeakerNames,
      }}
    >
      {children}
    </SpeakersContext.Provider>
  );
};
