const defaultRedirectURI =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '') +
  '/settings/integrations?notably-integration=slack';

export const slackOAuthRedirectURI =
  import.meta.env.VITE_SLACK_OAUTH_REDIRECT_URI ?? defaultRedirectURI;

if (!slackOAuthRedirectURI.toString().startsWith('https://')) {
  console.warn(
    `slackOAuthRedirectURI must be an https URL, but was ${slackOAuthRedirectURI}.
    If this is not set to an https URL, OAuth callback will not work.
    Ensure that the VITE_SLACK_OAUTH_REDIRECT_URI environment variable is injected properly if using a local tunnel.`
  );
}

// This is currently set to a test Slack app for early development, but will be replaced with an actual Notably Slack app
const rawSlackClientID = import.meta.env.VITE_SLACK_CLIENT_ID ?? '1664244780402.5727212186624';
export const SLACK_CLIENT_ID = typeof rawSlackClientID === 'string' ? rawSlackClientID : undefined;

// TODO add scope for getting reactions & messages
export const slackAuthorizationURL = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=${SLACK_CLIENT_ID}&redirect_uri=${slackOAuthRedirectURI}`;
