import React from 'react';

export default function ArrowOpenIcon(props: React.ComponentProps<'svg'>): JSX.Element {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.60717 1.73597H12.6355M12.6355 1.73597V6.76429M12.6355 1.73597L1.32177 13.0497"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
