import React, { FC, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Icon24 } from '../../Icons/Icon';

import { Container, Spinner, Text, AdditionalText } from './styles';

interface AILoadingStateProps {
  withHeader?: boolean;
  type?: 'summary' | 'insight';
}

const AILoadingState: FC<AILoadingStateProps> = ({ withHeader, type = 'insight' }) => {
  const [textMinWidth, setTextMinWidth] = useState(type === 'insight' ? '178px' : '216px'); // Hack to make the text not jump around
  return (
    <Container withHeader={withHeader}>
      <Spinner>
        <Icon24.AISpinner />
      </Spinner>
      <Text style={{ minWidth: textMinWidth }} colored={type !== 'insight'}>
        <TypeAnimation
          // Same String at the start will only be typed once, initially
          sequence={[
            type === 'insight' ? 'Having a think on this...' : 'Looking for early patterns...',
            2000,
            () => {
              setTextMinWidth(type === 'insight' ? '210px' : '237px');
            },
            type === 'insight' ? 'Figuring out what matters...' : 'Noting surprising takeaways...',
            2000,
            () => {
              setTextMinWidth(type === 'insight' ? '236px' : '171px');
            },
            type === 'insight' ? 'Understanding what it means...' : 'Jotting down topics...',
            2000,
            () => {
              setTextMinWidth(type === 'insight' ? '220px' : '233px');
            },
            type === 'insight' ? 'Writing up something fresh...' : 'Debriefing the conversation...',
            2000,
            () => {
              setTextMinWidth('206px');
            },
            'Putting a finishing touch...',
            2000,
            () => {
              setTextMinWidth(type === 'insight' ? '178px' : '216px');
            },
          ]}
          omitDeletionAnimation={true} // Omit the deletion animation
          speed={50} // Custom Speed from 1-99 - Default Speed: 40
          repeat={Infinity} // Repeat this Animation Sequence infinitely
        />
      </Text>
      {type === 'summary' && <AdditionalText>This can take a few minutes.</AdditionalText>}
    </Container>
  );
};

export default AILoadingState;
