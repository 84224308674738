import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import ReactLoading from 'react-loading';
import { TrashIcon } from '@heroicons/react/outline';

import Button from './Button';
import UserPhotoPopover from './Popovers/UserPhotoPopover';
import { CoverProps } from '../Models';
import { defaultUserImages } from '../Consts/userImages';

type ProfileFormProps = {
  currentPage: {
    header: string;
    id: string;
    inputLabel: string;
    placeholder: string;
    setValue: Dispatch<SetStateAction<string>>;
    stepNumber: number;
  };
  handleUpdateUserName?: (name: string) => void;
  isOnboarding: boolean;
  loading: boolean;
  onImageClick: (image: CoverProps) => () => void;
  onRemoveImage: () => void;
  selectedImage: CoverProps | null;
  updateCurrentPage?: (e: MouseEvent<HTMLElement>) => void;
  userName?: string;
};

const TOTAL_STEP_NUMBERS = 3;

const ProfileForm = ({
  currentPage,
  handleUpdateUserName,
  isOnboarding,
  loading,
  onImageClick,
  onRemoveImage,
  selectedImage,
  updateCurrentPage,
  userName,
}: ProfileFormProps): JSX.Element => {
  const { header, id, inputLabel, placeholder, setValue, stepNumber } = currentPage;
  return (
    <div>
      {isOnboarding && (
        <div>
          <div className="text-sm font-semibold">{`Step ${stepNumber} of ${TOTAL_STEP_NUMBERS}`}</div>
          <div className="text-xl font-semibold py-2 font-secondary">{header}</div>
        </div>
      )}
      {!isOnboarding && <div className="text-xl font-medium py-2">{'Profile details'}</div>}
      <div className="flex flex-col items-center">
        <div
          className="w-16 h-16 rounded-full mb-4"
          style={{
            backgroundImage: `url(${selectedImage ? selectedImage.url : defaultUserImages[0].url})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        ></div>
        <div className="flex flex-row">
          <UserPhotoPopover
            title={'Change'}
            position="left"
            onImageClick={onImageClick}
            isSettingsPage={true}
          />
          <Button className="ml-5" type="secondary" onClick={onRemoveImage}>
            <TrashIcon className="w-5 h-5 mr-1" />
            Remove
          </Button>
        </div>
      </div>

      <div className="pt-5 pb-4">
        <label htmlFor="name" className="block text-sm font-medium">
          {inputLabel}
        </label>
        <div className="mt-1">
          <input
            id={id}
            name={id}
            placeholder={placeholder}
            defaultValue={userName ?? ''}
            required
            onBlur={handleUpdateUserName ? (e) => handleUpdateUserName(e.target.value) : undefined}
            onChange={(e) => setValue(e.target.value)}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
          />
        </div>
      </div>

      {isOnboarding && (
        <div className="h-10 flex justify-center">
          {loading && <ReactLoading type={'spin'} color={'#382152'} height={'10%'} width={'10%'} />}
          {!loading && (
            <Button size={'lg'} className="w-full flex justify-center" onClick={updateCurrentPage}>
              Continue
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileForm;
