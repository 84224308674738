import React, { FC, useRef, useState } from 'react';
import Button from '../../Components/Button';
import { Icon16, Icon24 } from '../../Icons/Icon';
import DeleteTagConfirmationModal from '../../Components/DeleteTagConfirmationModal';

import Well from '../../Components/Well';

import {
  Container,
  Header,
  PageTitle,
  Buttons,
  ButtonText,
  ButtonWrapper,
  Content,
  RightSide,
  NewTagPanel,
  NewTagButton,
} from './styles';

import MergeTagsModal from '../../Components/MergeTagsModal';
import TagsNavigation from '../../Components/TagsNavigation';
import useHandleClickOutside from '../../Hooks/useHandleClickOutside';
import useFeatures from '../../Hooks/useFeatures';
import TagsUpgradeModal from './TagsUpgradeModal';
import { TagWithDetails } from '../../Models';

interface TagsLayoutProps {
  hideButtons?: boolean;
  children: React.ReactNode;
  title: string;
  checkedTags: TagWithDetails[];
  hideSidebar?: boolean;
  isGlobal: boolean;
  onCreateTag(): void;
  onDeleteTag(): void;
  onMakeGlobal?(): void;
  onMergeTags(newTagName: string): void;
  onChangeSort?(sortBy: string): void;
}

const TagsLayout: FC<TagsLayoutProps> = ({
  children,
  title,
  checkedTags,
  isGlobal,
  hideButtons,
  onDeleteTag,
  onMergeTags,
  onCreateTag,
  onMakeGlobal,
}) => {
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isMergeModalOpened, setIsMergeModalOpened] = useState(false);
  const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);
  const hasProjectTag = checkedTags.some((tag) => !tag.isGlobal);
  const { globalTagsEditable } = useFeatures();

  const handleConfirmDeletingTags = async () => {
    onDeleteTag();
    setIsDeleteModalOpened(false);
  };

  const handleConfirmMergingTags = async (newTagName: string) => {
    onMergeTags(newTagName);
    setIsMergeModalOpened(false);
  };

  const handleAddNewTag = async () => {
    if (isGlobal && !globalTagsEditable) {
      setUpgradeModalOpened(true);
      return;
    }
    onCreateTag();
  };

  const wellText = () => {
    if (!isGlobal) {
      return `Project tags are unique and relevant to the dataset in each project. 
        Create tags from your research goals or let the data inform them during analysis.`;
    } else {
      return `Global tags are shared across all projects. This is a limited list of tags that are timeless, 
        important, and relevant to all research data. Use the Notably default tags for free.`;
    }
  };

  return (
    <Container>
      {isDeleteModalOpened && (
        <DeleteTagConfirmationModal
          checkedTags={checkedTags}
          onConfirm={handleConfirmDeletingTags}
          onCancel={() => setIsDeleteModalOpened(false)}
        />
      )}
      {isMergeModalOpened && (
        <MergeTagsModal
          checkedTags={checkedTags}
          onConfirm={handleConfirmMergingTags}
          onCancel={() => setIsMergeModalOpened(false)}
        />
      )}
      {/* {!hideSidebar && <TagsNavigation sortBy={sortBy || 'tag'} onChangeSort={handleChangeSort} />} */}
      <Content>
        <Header>
          <PageTitle>{title}</PageTitle>
          {!hideButtons && (
            <Buttons>
              {!!checkedTags.length && (
                <>
                  {hasProjectTag && (
                    <ButtonWrapper>
                      <Button type="secondary" onClick={onMakeGlobal}>
                        <Icon24.Tags />
                        <ButtonText>Add to global</ButtonText>
                      </Button>
                    </ButtonWrapper>
                  )}

                  {checkedTags.length > 1 && (
                    <ButtonWrapper>
                      <Button type="secondary" onClick={() => setIsMergeModalOpened(true)}>
                        <Icon24.Arrow />
                        <ButtonText>Merge</ButtonText>
                      </Button>
                    </ButtonWrapper>
                  )}

                  <ButtonWrapper>
                    <Button
                      className="text-red-500"
                      type="secondary"
                      onClick={() => setIsDeleteModalOpened(true)}
                    >
                      <Icon24.Trash />
                      <ButtonText>Delete</ButtonText>
                    </Button>
                  </ButtonWrapper>
                </>
              )}

              <ButtonWrapper>
                <Button onClick={handleAddNewTag}>
                  <ButtonText>New {isGlobal ? 'global' : 'project'} tag</ButtonText>
                </Button>
              </ButtonWrapper>
            </Buttons>
          )}
        </Header>

        <RightSide>
          <Well wellKey="tags-well">{wellText()}</Well>

          {children}
        </RightSide>
      </Content>

      <TagsUpgradeModal isOpen={upgradeModalOpened} onClose={() => setUpgradeModalOpened(false)} />
    </Container>
  );
};

export default TagsLayout;
