import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const IconContainer = styled.div<{ src: string }>`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  width: 100%;
  justify-content: left;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  ${breakpoint.Mobile`
    grid-template-columns: repeat(1, 1fr);
  `}
`;

export const TemplateCard = styled.div<{ selected: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 20px;
  border: 1px solid #d7d3dc;
  border-radius: 8px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    border: 1px solid transparent;

    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      bottom: -2px;
      right: -2px;
      border: 2px solid #382152;
      border-radius: 8px;
    }
  `};

  &:last-child {
    margin: 0;
  }
`;

export const ChevronIcon = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  right: -8px;
  top: -8px;
  color: #ffffff;
  background-color: #382152;
  z-index: 1;
`;

export const TemplateName = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const TemplateDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);
`;
