import { useQuery } from '@apollo/client';

import { FETCH_CANVAS_ELEMENTS } from '../GraphQL/queries';

export const useFetchCanvasElements = (
  dashboardId: string
): [boolean, { textNotes: any[]; lines: any[]; shapes: any[]; canvasImages: any[] }] => {
  const { loading, data } = useQuery(FETCH_CANVAS_ELEMENTS, {
    variables: { dashboardId },
  });

  return [
    loading,
    {
      textNotes:
        data?.textNotes?.map((note: any) => ({
          ...note,
          styles: note?.styles ? JSON.parse(note.styles) : null,
        })) || [],
      lines:
        data?.lines?.map((line: any) => ({
          ...line,
          styles: line?.styles ? JSON.parse(line.styles) : null,
        })) || [],
      shapes:
        data?.shapes?.map((shape: any) => ({
          ...shape,
          styles: shape?.styles ? JSON.parse(shape.styles) : null,
        })) || [],
      canvasImages:
        data?.canvasImages?.map((canvasImage: any) => ({
          ...canvasImage,
          styles: canvasImage?.styles ? JSON.parse(canvasImage.styles) : null,
        })) || [],
    },
  ];
};

export default {
  useFetchCanvasElements,
};
