import React, { ComponentProps } from 'react';
import CreatableSelect from 'react-select/creatable';
import { OptionTypeBase, components } from 'react-select';

type Props = ComponentProps<typeof CreatableSelect>;
type FontWeight =
  | 'normal'
  | 'bold'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'undefined'
  | any;

export interface OptionType extends OptionTypeBase {
  value: any;
  label: string;
  color?: string;
}

function getStyles(color = 'transparent') {
  const menuStyle = {
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 0,
  };

  const controlStyle = {
    borderWidth: '2px',
    borderStyle: 'solid',
    boxShadow: 'none',
    borderColor: color,
    backgroundColor: 'transparent',
    outlineColor: 'transparent',
    borderRadius: 0,
    '&:hover': {
      borderColor: color,
    },
  };

  const containerStyle = {
    backgroundColor: 'white',
  };

  const optionStyle = {
    backgroundColor: 'white',
    color: 'rgba(56, 33, 82, 1)',
    padding: '5px 4px',
    fontSize: '14px',
    fontWeight: '500' as FontWeight,
    ':hover': {
      backgroundColor: 'rgba(56, 33, 82, 0.1)',
      borderRadius: 3,
    },
  };

  const singleValueStyle = {
    margin: '0',
    padding: '3px 5px',
    borderRadius: 2,
    fontSize: 14,
    color: 'rgba(56, 33, 82, 1)',
    fontWeight: '500' as FontWeight,
  };

  const placeholderStyle = {
    fontSize: '14px',
    color: 'rgba(56, 33, 82, 0.6)',
  };

  const contrastPlaceholderStyle = {
    color: 'rgba(56, 33, 82, 1)',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  };

  const menuListStyle = {
    padding: '10px',
  };

  const indicatorContainerStyle = {
    ':hover': {
      background: 'rgba(56, 33, 82, 0.1)',
      borderRadius: '3px',
    },
  };

  const indicatorSeparatorStyle = {
    background: 'none',
  };

  const dropdownIndicator = {
    color: 'rgba(56, 33, 82, 1)',
    ':hover': {
      color: 'rgba(56, 33, 82, 1)',
    },
  };

  const noOptionsMessageStyle = {
    padding: 0,
    color: 'rgba(56, 33, 82, 1)',
    fontSize: '14px',
    fontWeight: '500' as FontWeight,
  };

  const multiValueLabelStyle = {
    fontSize: '14px',
    color: 'rgba(56, 33, 82, 1)',
    fontWeight: '500' as FontWeight,
  };

  const valueContainer = {};

  return {
    containerStyle,
    contrastPlaceholderStyle,
    controlStyle,
    dropdownIndicator,
    indicatorContainerStyle,
    indicatorSeparatorStyle,
    menuListStyle,
    menuStyle,
    multiValueLabelStyle,
    noOptionsMessageStyle,
    optionStyle,
    placeholderStyle,
    singleValueStyle,
    valueContainer,
  };
}

export default function MultiSelect(props: Props): JSX.Element {
  const {
    containerStyle,
    contrastPlaceholderStyle,
    controlStyle,
    dropdownIndicator,
    indicatorContainerStyle,
    indicatorSeparatorStyle,
    menuListStyle,
    menuStyle,
    multiValueLabelStyle,
    noOptionsMessageStyle,
    optionStyle,
    placeholderStyle,
    singleValueStyle,
    valueContainer,
  } = getStyles(props.borderColor ?? 'transparent');

  // setTimeout(() => {
  //   debugger;
  // }, 5000);

  return (
    <CreatableSelect
      placeholder={props.placeholder}
      isClearable={false}
      isMulti
      autoFocus={props.autoFocus}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        // eslint-disable-next-line react/display-name
        Option: ({ data, ...rest }) => {
          return (
            <components.Option data={data} {...rest}>
              {data?.label}
            </components.Option>
          );
        },
        // eslint-disable-next-line react/display-name
        MenuList: ({ children, ...rest }) => {
          return (
            <>
              <components.MenuList {...rest}>{children}</components.MenuList>
            </>
          );
        },
      }}
      styles={{
        container(base) {
          return {
            ...base,
            ...containerStyle,
            border: props.contrastPlaceholder ? 'none' : '1px solid #E5E5E5',
            boxShadow: props.contrastPlaceholder ? 'none' : '0px 3px 4px rgba(0, 0, 0, 0.1)',
          };
        },
        control(base) {
          return { ...base, ...controlStyle };
        },
        menu(base) {
          return {
            ...base,
            ...menuStyle,
          };
        },
        menuList(base) {
          return {
            ...base,
            ...menuListStyle,
          };
        },
        option(base, { isSelected }) {
          return {
            ...base,
            ...optionStyle,
            fontWeight: isSelected ? ('700' as FontWeight) : ('500' as FontWeight),
          };
        },
        multiValue(base, { data: { color } }) {
          return {
            ...base,
            backgroundColor: color || 'rgba(56, 33, 82, 0.2)',
          };
        },
        multiValueLabel(base) {
          return {
            ...base,
            ...multiValueLabelStyle,
          };
        },
        singleValue(base, { data: { color } }) {
          return {
            ...base,
            ...singleValueStyle,
            backgroundColor: color || 'rgba(56, 33, 82, 0.2)',
          };
        },
        menuPortal(base) {
          return { ...base, zIndex: 9999 };
        },
        placeholder(base) {
          return {
            ...base,
            ...(props.contrastPlaceholder ? contrastPlaceholderStyle : placeholderStyle),
          };
        },
        indicatorsContainer(base) {
          return {
            ...base,
            ...indicatorContainerStyle,
          };
        },
        indicatorSeparator(base) {
          return {
            ...base,
            ...indicatorSeparatorStyle,
          };
        },
        dropdownIndicator(base) {
          return {
            ...base,
            ...dropdownIndicator,
          };
        },
        valueContainer(base) {
          return {
            ...base,
            ...valueContainer,
          };
        },
        noOptionsMessage(base) {
          return {
            ...base,
            ...noOptionsMessageStyle,
          };
        },
      }}
      {...props}
    />
  );
}
