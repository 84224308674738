import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.div<{ isActive?: boolean }>`
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 50px;
  background-color: rgba(56, 33, 82, 0.1);
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
  border: 1px solid #ffffff;

  &:last-child {
    margin-right: 0;
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: #ffffff;
    border: 1px solid #D7D3DC;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    color: rgba(59, 38, 81, 1);
  `}
`;
