import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #fefbf6;
  padding: 20px 40px 60px;

  ${breakpoint.Tablet`
    padding: 20px 20px 8px;
    background-image: none;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  ${breakpoint.Tablet`
    width: 140px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  ${breakpoint.Tablet`
    margin-top: 32px;
  `}
`;

export const OnboardingWindow = styled.div`
  width: 600px;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;

  ${breakpoint.Tablet`
    width: 100%;
    padding: 16px;
  `}
`;

export const Quote = styled.div`
  font-family: 'acumin-pro-wide';
  margin-left: 64px;
  max-width: 356px;

  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const QuoteText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const QuoteAuthor = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div<{ src?: string }>`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
`;

export const QuotePerson = styled.div``;

export const QuoteNickname = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const QuotePositionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const QuotePosition = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 6px;
  flex-shrink: 0;
`;

export const QuoteCompanyLogo = styled.div<{ src?: string }>`
  width: 120px;
  height: 30px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
`;

export const OnboardingWindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${breakpoint.Tablet`
    width: 100%;
  `}
`;

export const DoThisLaterLink = styled.a`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
`;
