import styled from 'styled-components';
import { breakpoint } from '../../../Shared';
import sampleVideoPreviewSrc from '../../../assets/onboarding/sample_video_preview.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentForm = styled.div<{ size?: string }>`
  width: 100%;
  max-width: ${({ size }) => (size === 'big' ? '625px' : '435px')};
  max-height: ${({ size }) => (size === 'big' ? '560px' : '500px')};
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  overflow-y: auto;

  ${breakpoint.Tablet`
    max-width: 100%;
    padding: 16px;
  `}
`;

export const Step = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 26px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const FormTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 435px;
  max-height: 50px;

  ${breakpoint.Tablet`
    max-width: 100%;
  `}
`;

export const StickyButtonWrapper = styled(ButtonWrapper)`
  position: sticky;
  bottom: 60px;
`;

export const StepNumberText = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const StepTitle = styled.div`
  font-family: 'acumin-pro-wide';
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  ${breakpoint.Tablet`
    font-size: 18px;
    line-height: 24px;
  `}
`;

export const StepSubtitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 24px;

  ${breakpoint.Tablet`
    font-size: 14px;
    line-height: 24px;
  `}
`;

export const PlanCards = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  ${breakpoint.Tablet`
    flex-direction: column;
  `}
`;

export const PlanCard = styled.div<{ isActive: boolean }>`
  position: relative;
  width: 100%;
  background-color: rgba(56, 33, 82, 0.1);
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 20px;
  margin-right: 20px;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: #ffffff;
    border: 2px solid #3b2651;
  `}

  ${breakpoint.Tablet`
    margin-right: 0;
    margin-bottom: 16px
  `}
`;

export const ActiveIndicator = styled.div`
  position: absolute;
  right: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3b2651;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

export const PlanCardTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const PlanCardText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const PlanCardFreeText = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const BookDemoImage = styled.div<{ src: string }>`
  width: 560px;
  height: 320px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  cursor: pointer;

  ${breakpoint.Tablet`
    width: 280px;
    height: 160px;
    margin: 0 auto;
    margin-bottom: 16px;
  `}
`;

export const AddTeammateButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AddTeammateText = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 10px;
`;

export const SkipButton = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
`;

export const SampleVideoButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const SampleVideoPreview = styled.div`
  width: 72px;
  height: 50px;
  margin-right: 20px;
  background-image: url(${sampleVideoPreviewSrc});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 6px;
`;

export const SampleVideoDescription = styled.div`
  display: flex;
  flex-direction: column; ;
`;

export const SampleVideoTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const SampleVideoSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const ArrowIconWrapper = styled.div`
  margin-left: 4px;
  transform: rotate(180deg);
`;

export const TemplateList = styled.div`
  width: 100%;
  max-width: 950px;
  justify-content: left;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  ${breakpoint.Tablet`
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  `}
`;

export const Notification = styled.div`
  width: 100%;
  max-width: 625px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  margin-bottom: 20px;
`;
