import React, { lazy, PropsWithChildren, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { DefaultLayout } from './Layouts';
import { Projects } from './Pages';
import {
  withAuthenticationRequired,
  useAuth0,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import { Loader, Modal, UpdateBoard } from './Components';
import { DataAttributesCompany, IntercomProps, useIntercom } from 'react-use-intercom';
const Notes = lazy(() => import('./Pages/Notes'));
import Tags from './Pages/Tags/Tags';
const Zoom = lazy(() => import('./Pages/Zoom'));
import HelpIcon from './Icons/HelpIcon';
import Transcript from './Pages/Transcript/Transcript';
import SidebarLayout from './Layouts/SidebarLayout';
import Insights from './Pages/Insights';
import Insight from './Pages/Insight';
import Info from './Pages/Info';
import Data from './Pages/Data';
import { useSegment } from 'react-segment-hooks';
import * as FullStory from '@fullstory/browser';
import Onboarding from './Components/Onboarding';
import { useHistory } from 'react-router';
import Integrations from './Pages/Integrations';
import Document from './Pages/Document';
import Billing from './Pages/Billing';
const Users = lazy(() => import('./Pages/Users'));
import SettingsLayout from './Layouts/SettingsLayout';
import ProjectsPageLayout from './Layouts/ProjectsPageLayout';
import GeneralSettings from './Pages/GeneralSettings';
import ProfileSettings from './Pages/ProfileSettings';
import TagsSettings from './Pages/Tags/TagsSettings';
import { useCurrentUser, CurrentUserContext } from './Hooks/useUsers';
import { useOrganization } from './Hooks/useOrganization';
import { CurrentDashboardContext } from './Hooks/useBoards';
import ParticipantsWrapper from './Pages/participants/ParticipantsWrapper';
import Plans from './Pages/Plans';
import TopLevelLayer from './Components/TopLevelLayer';
import { UploadFilesContextProvider } from './Context/UploadFilesContext';
import { InsightGenerationContextProvider } from './Context/InsightGenerationContext';
const Templates = lazy(() => import('./Pages/Templates'));
import IntegrationDetails from './Components/Integrations/IntegrationDetails/IntegrationDetails';
import { Home } from './Pages/Home';
import HomePageLayout from './Layouts/HomePageLayout';
const Charts = lazy(() => import('./Pages/Analytics/Charts'));
import { setUser } from '@sentry/react';
import EditTemplate from './Pages/EditTemplate';
import EditNote from './Pages/EditNote';
import { ProjectChecklistContextProvider } from './Context/ProjectChecklistContext';

function RequireOnboarding({ children }: PropsWithChildren<any>) {
  const { user } = useAuth0();
  const history = useHistory();
  useEffect(() => {
    if (!user) return;
    const onboardingStatus = localStorage.getItem('onboardingStatus');

    const onboarded =
      user['https://notably.ai/claims/user_id'] &&
      user['https://notably.ai/claims/org_id'] &&
      user['https://notably.ai/claims/user_invited'] !== true &&
      !onboardingStatus;

    if (!onboarded) {
      if (onboardingStatus === 'step_1_complete') {
        localStorage.setItem('onboardingStatus', 'pending_step_2');
      }
      history.push('/onboarding');
    }
  }, [history, user]);

  return children;
}

function getQueryParams() {
  const { search } = window.location;

  return new URLSearchParams(search);
}

function Authenticated(): JSX.Element {
  const { isLoading, user: auth0User } = useAuth0();
  const { boot } = useIntercom();
  const [, org] = useOrganization();
  const analytics = useSegment();
  const [, notablyUser] = useCurrentUser();

  useEffect(() => {
    if (auth0User && notablyUser && org) {
      const userId =
        auth0User['https://notably.ai/claims/user_id'] || auth0User.id || auth0User.user_id;

      const company: DataAttributesCompany = {
        companyId: org.id,
        name: org.name,
        createdAt: Math.ceil(new Date(org.createdAt).valueOf() / 1000),
        plan: org.plan,
        website: auth0User.email?.split('@')[1],
      };

      const userName = notablyUser?.name || auth0User.name;

      const intercomUser: IntercomProps = {
        hideDefaultLauncher: true,
        customLauncherSelector: '#intercomIcon',
        userId,
        name: userName,
        email: auth0User.email,
        avatar: {
          type: 'avatar',
          imageUrl: auth0User.picture,
        },
        company,
        customAttributes: {
          user_role: notablyUser.role,
          org_plan: org.plan,
          org_projects_count: org.projectsCount,
        },
      };

      boot(intercomUser);

      localStorage.setItem('showRecruitingPanel', 'true');

      analytics.identify({
        userId,
        traits: {
          name: userName,
          email: auth0User.email,
          avatar: {
            type: 'avatar',
            imageUrl: auth0User.picture,
          },
          company,
        },
      });

      FullStory.identify(userId, {
        displayName: userName,
        email: auth0User.email,
      });

      setUser({
        email: auth0User?.email,
        id: userId,
        username: userName,
        segment: org.id,
        orgId: org.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0User, notablyUser, org]);

  const isAnalysisBoard = useRouteMatch({ path: '/projects/:dashboardId/notes' });
  const matchesDashboadPath = useRouteMatch<{ dashboardId: string }>({
    path: '/projects/:dashboardId',
  });
  const dashboardId = matchesDashboadPath?.params?.dashboardId;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <a
        className={`fixed z-100 right-3.5 ${isAnalysisBoard ? 'bottom-10' : 'bottom-3.5'}`}
        id="intercomIcon"
        href="#"
      >
        <HelpIcon />
      </a>
      <CurrentUserContext.Provider value={notablyUser}>
        <CurrentDashboardContext.Provider value={dashboardId}>
          <InsightGenerationContextProvider>
            <UploadFilesContextProvider>
              <ProjectChecklistContextProvider>
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact path="/zoom" component={Zoom} />
                    <Route exact path="/onboarding" component={Onboarding} />
                    <Route>
                      <RequireOnboarding>
                        <DefaultLayout>
                          <TopLevelLayer />
                          <Switch>
                            <Route exact path="/">
                              <HomePageLayout>
                                <Route exact path="/" component={Home} />
                              </HomePageLayout>
                            </Route>
                            <Route exact path="/projects">
                              <ProjectsPageLayout>
                                <Route exact path="/projects" component={Projects} />
                              </ProjectsPageLayout>
                            </Route>
                            <Route exact path="/templates" component={Templates} />
                            <Route path="/templates/:templateId" component={EditTemplate} />
                            <Route
                              path="/participants/:participantId([0-9]+)?"
                              component={ParticipantsWrapper}
                            />
                            <Route
                              path="/projects/:dashboardId/edit"
                              render={(props) => (
                                <Modal
                                  title={'Update the board'}
                                  isOpen={true}
                                  style={{ overlay: { zIndex: 1000 } }}
                                >
                                  <UpdateBoard {...props.match.params} />
                                </Modal>
                              )}
                            />
                            <Route path="/projects/:dashboardId/notes/:noteId" component={Notes} />
                            <Route path="/projects/:dashboardId/notes" component={Notes} />
                            <Route path="/projects/:dashboardId">
                              <SidebarLayout>
                                <Switch>
                                  <Route
                                    exact
                                    path="/projects/:dashboardId/data/docs/:documentId([0-9]+)"
                                    component={Document}
                                  />
                                  <Route
                                    exact
                                    path="/projects/:dashboardId/data/:transcriptId([0-9]+)"
                                    component={Transcript}
                                  />
                                  <Route path="/projects/:dashboardId/data" component={Data} />
                                  <Route
                                    exact
                                    path="/projects/:dashboardId/insights"
                                    component={Insights}
                                  />
                                  <Route
                                    exact
                                    path="/projects/:dashboardId/insights/:clientId"
                                    component={Insight}
                                  />
                                  <Route
                                    exact
                                    path="/projects/:dashboardId/info"
                                    component={Info}
                                  />
                                  <Route
                                    exact
                                    path="/projects/:dashboardId/analysis/charts"
                                    component={Charts}
                                  />
                                  <Route exact path="/projects/:dashboardId/tags/project/:tagId?">
                                    <Tags type="project" />
                                  </Route>
                                  <Route exact path="/projects/:dashboardId/tags/global/:tagId?">
                                    <Tags type="global" />
                                  </Route>
                                  <Route exact path="/projects/:dashboardId/tags">
                                    <Redirect to="tags/project/" />
                                  </Route>
                                  <Redirect to="/404" />
                                </Switch>
                              </SidebarLayout>
                            </Route>
                            <Route path="/notes/:noteId" component={EditNote} />
                            <Route path="/settings">
                              <SettingsLayout>
                                <Switch>
                                  <Route exact path="/settings" component={Integrations} />
                                  <Route
                                    exact
                                    path="/settings/integrations"
                                    component={Integrations}
                                  />
                                  <Route
                                    exact
                                    path="/settings/integrations/:integrationName"
                                    component={IntegrationDetails}
                                  />
                                  <Route exact path="/settings/users" component={Users} />
                                  <Route exact path="/settings/plans" component={Plans} />
                                  <Route exact path="/settings/billing" component={Billing} />
                                  <Route
                                    exact
                                    path="/settings/profile"
                                    component={ProfileSettings}
                                  />
                                  <Route
                                    exact
                                    path="/settings/general"
                                    component={GeneralSettings}
                                  />
                                  <Route exact path="/settings/tags" component={TagsSettings} />
                                  <Route exact path="/plans" component={Plans} />
                                </Switch>
                              </SettingsLayout>
                            </Route>
                            <Redirect from="/signup" to="/" />
                            <Redirect to="/404" />
                          </Switch>
                        </DefaultLayout>
                      </RequireOnboarding>
                    </Route>
                    <Redirect to="/404" />
                  </Switch>
                </Suspense>
              </ProjectChecklistContextProvider>
            </UploadFilesContextProvider>
          </InsightGenerationContextProvider>
        </CurrentDashboardContext.Provider>
      </CurrentUserContext.Provider>
    </>
  );
}

const isSignup = window.location.href.indexOf('/signup') !== -1;
const query = getQueryParams();
const email = query.get('email');
const redirectUrl =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '');

const authOptions: WithAuthenticationRequiredOptions = {
  loginOptions: {
    screen_hint: isSignup ? 'signup' : 'login',
    redirect_uri: isSignup ? redirectUrl + '/onboarding' : window.location.href,
  },
};

if (email && authOptions.loginOptions) {
  authOptions.loginOptions.login_hint = email;
}

export default withAuthenticationRequired(Authenticated, authOptions);
