import React, { useEffect } from 'react';
import { useSegment } from 'react-segment-hooks';
import { useParams } from 'react-router-dom';

import { nanoid } from 'nanoid';
import { useHistory } from 'react-router';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import Well from '../Components/Well';
import InsightsCardView from '../Components/InsightsCardView';
import Loader from '../Components/Loader';
import Button from '../Components/Button';
import { useFetchInsights, useCreateInsight } from '../Hooks/useInsights';
import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyStateText,
  EmptyState,
  EmptyStateContainer,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import insightsEmptyImage from '../assets/empty-states/insights.png';
import usePermissions from '../Hooks/usePermissions';
import Sharing from '../Components/Sharing';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_SHAREID } from '../GraphQL/queries/dashboard';
import { useUpdateBoard } from '../Hooks/useBoards';

export default function Insights(): JSX.Element {
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const analytics = useSegment();
  const history = useHistory();
  const { canCreateInsights } = usePermissions();

  useEffect(() => {
    analytics.page({
      name: 'Insights',
      properties: {
        projectId: dashboardId,
      },
    });
  }, [analytics, dashboardId]);

  const { loading, data } = useFetchInsights(dashboardId);

  const { loading: loadingDashboard, data: dashboardRes } = useQuery(GET_DASHBOARD_SHAREID, {
    variables: { dashboardId },
  });

  const [createInsightMutation] = useCreateInsight();
  const [updateBoard] = useUpdateBoard();

  async function handleCreate() {
    const clientId = nanoid(10);
    await createInsightMutation({ dashboardId, clientId });
    history.push(`/projects/${dashboardId}/insights/${clientId}`);
  }

  const shareId = dashboardRes?.dashboard?.shareId;
  const orgId = dashboardRes?.currentOrganization?.id;

  const handleSharing = async (isShared: boolean) => {
    await updateBoard(dashboardId, { shareId: isShared ? nanoid(16) : null });
  };

  return (
    <div className={'flex-col h-full'}>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>Insights</h1>
          </div>
          {canCreateInsights && (
            <div className="flex">
              <Sharing
                onChange={handleSharing}
                type="secondary"
                link={shareId ? `${window.location.origin}/s/${orgId}/insights/${shareId}` : null}
              />
              <Button onClick={handleCreate} type={'primary'}>
                New Insight
              </Button>
            </div>
          )}
        </div>
      </SecondaryToolbar>
      {!data?.length && (
        <EmptyStateContainer>
          <EmptyState>
            <HeroImage src={insightsEmptyImage}></HeroImage>
            <EmptyStateTitle>Create insights to share findings</EmptyStateTitle>
            <EmptyStateText>
              Insights are a space to document emerging insights and share research findings.
            </EmptyStateText>
            {canCreateInsights && (
              <EmptyStateButton onClick={handleCreate}>
                <EmptyStatePlusIcon />
                New insight
              </EmptyStateButton>
            )}
          </EmptyState>
        </EmptyStateContainer>
      )}
      {Boolean(data?.length) && (
        <div className={'flex flex-auto justify-center'}>
          <div className={'flex-col h-full w-full pb-20'}>
            <Well wellKey="insights-well">
              Insights are a simple place to draft your research findings. Use insights to answer
              “what does it mean?” and “why does it matter?”
            </Well>
            {loading && <Loader />}
            {!loading && (
              <InsightsCardView insights={data} baseUrl={`/projects/${dashboardId}/insights`} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
