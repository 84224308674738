import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes, TListItem } from './ListItem';

type DropTargetProps = { onDrop: (item: TListItem) => void };
const ListItemDropTarget = ({
  onDrop,
  children,
}: React.PropsWithChildren<DropTargetProps>): JSX.Element => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.LIST_ITEM,
    drop: (item: TListItem) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className="box-border"
      style={
        isOver
          ? { border: '1px dotted #3B2651', margin: '-1px', borderRadius: '3px', minHeight: '56px' }
          : { minHeight: '8px' }
      }
    >
      {children}
    </div>
  );
};

export default ListItemDropTarget;
