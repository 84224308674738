import { useMutation, useQuery } from '@apollo/client';

import { FETCH_SHAPES, FETCH_SHAPE } from '../GraphQL/queries';
import { CREATE_SHAPE, UPDATE_SHAPE, DELETE_SHAPE } from '../GraphQL/mutations';
import { ID } from '../Models';

export const useFetchShapes = (dashboardId: string): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_SHAPES, {
    variables: { dashboardId },
  });
  return [
    loading,
    data?.shapes?.map((shape: any) => ({
      ...shape,
      styles: shape?.styles ? JSON.parse(shape.styles) : null,
    })) || [],
  ];
};

export const useFetchShape = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_SHAPE, {
    variables: { id },
  });
  return [loading, data?.shape];
};

export const useCreateShape = (): [(dashboardId: string, shape?: any) => any] => {
  const [createMutation] = useMutation(CREATE_SHAPE);

  return [
    async (dashboardId: string, shape?: any) => {
      const preparedInput = { ...shape };
      if (shape.styles) preparedInput.styles = JSON.stringify(shape.styles);
      const res = await createMutation({
        variables: {
          input: {
            dashboardId,
            ...(preparedInput || {}),
          },
        },
        refetchQueries: [
          {
            query: FETCH_SHAPES,
            variables: { dashboardId },
          },
        ],
      });
      return res?.data?.createShape?.shape;
    },
  ];
};

export const useUpdateShape = (): [(id: ID, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_SHAPE);

  return [
    (id: ID, input: any) => {
      const preparedInput = { ...input };
      if (input.styles) preparedInput.styles = JSON.stringify(input.styles);
      return updateMutation({
        variables: {
          id,
          input: preparedInput,
        },
        refetchQueries: [
          {
            query: FETCH_SHAPE,
            variables: { id },
          },
        ],
      });
    },
  ];
};

export const useDeleteShape = (): [(dashboardId: string, id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_SHAPE);

  return [
    (dashboardId: string, id: string) => {
      return deleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_SHAPES,
            variables: { dashboardId },
          },
        ],
      });
    },
  ];
};

export default {
  useFetchShapes,
  useFetchShape,
  useCreateShape,
  useUpdateShape,
  useDeleteShape,
};
