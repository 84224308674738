import React, { FC, useState } from 'react';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';

import {
  SpeakerInfo,
  SpeakerNameWrapper,
  LetterAvatarWrapper,
  SpeakerName,
  SpeakerTiming,
} from './styles';
import LetteredAvatar from '../../../LetteredAvatar';
import { formatTimestamp } from 'subtitle';
import Portal from '../../../PortalNew/PortalNew';
import SpeakerNameModal from '../../../SpeakerNameModal';

const beautifyTimestamp = (timestamp: number): string => {
  if (!timestamp) return '00:00:00';
  return formatTimestamp(timestamp).split(',')[0];
};

const SpeakerComponent: FC = (props: any) => {
  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  const speakerName = props.node.attrs.speakerName || 'Unknown Speaker';
  const startTime = beautifyTimestamp(props.node.attrs.startTime);
  const endTime = beautifyTimestamp(props.node.attrs.endTime);

  const handleChangeName = (newName: string) => {
    props.updateAttributes({ speakerName: newName });
  };

  const handleEditName = (editingName: string, newName: string) => {
    let editorUpdateChain = props.editor.chain();
    props.editor.state.doc.content.nodesBetween(
      0,
      props.editor.state.doc.content.size,
      (node: any, pos: number) => {
        if (node.type.name === 'speaker' && node.attrs.speakerName === editingName) {
          const newNode = {
            ...node.toJSON(),
            attrs: { ...node.attrs, speakerName: newName },
          };

          editorUpdateChain = editorUpdateChain.insertContentAt(
            {
              from: pos,
              to: pos + node.nodeSize,
            },
            newNode
          );
        }
      }
    );

    editorUpdateChain.run();
  };

  const handleTimestampClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const event = new CustomEvent('timestampClick', {
      detail: { startTime: props.node.attrs.startTime / 1000, shouldPlay: true },
    });
    document.dispatchEvent(event);
  };

  return (
    <NodeViewWrapper>
      <SpeakerInfo contentEditable={false} onClick={() => setIsChangeNameModalOpen(true)}>
        <SpeakerNameWrapper>
          <LetterAvatarWrapper>
            <LetteredAvatar name={speakerName} />
          </LetterAvatarWrapper>
          <SpeakerName>{speakerName}</SpeakerName>
        </SpeakerNameWrapper>
        <SpeakerTiming onClick={handleTimestampClick}>
          {startTime} - {endTime}
        </SpeakerTiming>
      </SpeakerInfo>

      {isChangeNameModalOpen && (
        <Portal wrapperId="speakerNameModal">
          <SpeakerNameModal
            isOpen={isChangeNameModalOpen}
            currentSpeakerName={speakerName}
            onEditName={handleEditName}
            onChangeName={handleChangeName}
            onClose={() => setIsChangeNameModalOpen(false)}
          />
        </Portal>
      )}

      <NodeViewContent as="span" />
    </NodeViewWrapper>
  );
};

export default SpeakerComponent;
