import { useMutation, useQuery } from '@apollo/client';

import { FETCH_TEXT_NOTES, FETCH_TEXT_NOTE } from '../GraphQL/queries';
import { CREATE_TEXT_NOTE, UPDATE_TEXT_NOTE, DELETE_TEXT_NOTE } from '../GraphQL/mutations';
import { ID } from '../Models';

export const useFetchTextNotes = (dashboardId: string): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_TEXT_NOTES, {
    variables: { dashboardId },
  });
  return [
    loading,
    data?.textNotes?.map((note: any) => ({
      ...note,
      styles: note?.styles ? JSON.parse(note.styles) : null,
    })) || [],
  ];
};

export const useFetchTextNote = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_TEXT_NOTE, {
    variables: { id },
  });
  return [loading, data?.textNote];
};

export const useCreateTextNote = (): [(dashboardId: string, textNote?: any) => any] => {
  const [createMutation] = useMutation(CREATE_TEXT_NOTE);

  return [
    async (dashboardId: string, textNote?: any) => {
      const preparedInput = { ...textNote };
      if (textNote.styles) preparedInput.styles = JSON.stringify(textNote.styles);
      const res = await createMutation({
        variables: {
          input: {
            dashboardId,
            ...(preparedInput || {}),
          },
        },
        refetchQueries: [
          {
            query: FETCH_TEXT_NOTES,
            variables: { dashboardId },
          },
        ],
      });
      return res?.data?.createTextNote?.textNote;
    },
  ];
};

export const useUpdateTextNote = (): [(id: ID, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_TEXT_NOTE);

  return [
    (id: ID, input: any) => {
      const preparedInput = { ...input };
      if (input.styles) preparedInput.styles = JSON.stringify(input.styles);
      return updateMutation({
        variables: {
          id,
          input: preparedInput,
        },
        refetchQueries: [
          {
            query: FETCH_TEXT_NOTE,
            variables: { id },
          },
        ],
      });
    },
  ];
};

export const useDeleteTextNote = (): [(dashboardId: string, id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_TEXT_NOTE);

  return [
    (dashboardId: string, id: string) => {
      return deleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_TEXT_NOTES,
            variables: { dashboardId },
          },
        ],
      });
    },
  ];
};

export default {
  useFetchTextNotes,
  useFetchTextNote,
  useCreateTextNote,
  useUpdateTextNote,
  useDeleteTextNote,
};
