import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { defaultUserImages } from '../../Consts/userImages';
import { Participant } from './ParticipantsWrapper';

interface Props {
  participants: Participant[];
  selected: string;
}

const ParticipantsList = ({ participants, selected }: Props): JSX.Element => {
  const history = useHistory();
  async function handleParticipantClick(id: string) {
    history.push(`/participants/${id}`);
  }
  const containerRef = useRef<HTMLDivElement>(null);

  const onArrowsDown = (event: KeyboardEvent) => {
    if (!['ArrowDown', 'ArrowUp'].includes(event.key) || participants.length === 0) return;
    let newId = participants[0].id;
    const selectedIndex = participants.findIndex((participant) => participant.id === selected);

    if (event.key === 'ArrowDown' && selected) {
      newId = participants[Math.min(selectedIndex + 1, participants.length - 1)].id;
    }
    if (event.key === 'ArrowUp' && selected) {
      newId = participants[Math.max(selectedIndex - 1, 0)].id;
    }
    handleParticipantClick(newId);
  };

  React.useEffect(() => {
    containerRef.current?.addEventListener('keydown', onArrowsDown);

    return () => {
      containerRef.current?.removeEventListener('keydown', onArrowsDown);
    };
  });

  return (
    <div ref={containerRef} tabIndex={0} className="focus:outline-primary-purple-light-40">
      {participants.map((item, index) => {
        const participantPicture =
          defaultUserImages.find((image) => image.name === item?.picture) || defaultUserImages[0];
        return (
          <div
            key={index}
            className={`hover:cursor-pointer flex p-2 border-b border-b-primary-purple-light ${
              selected === item.id ? 'bg-[#EBE9EE]' : ''
            }`}
            onClick={() => {
              handleParticipantClick(item.id);
            }}
          >
            <div className="flex items-center justify-center">
              <img className="rounded-full w-8 h-8" src={participantPicture.url} />
            </div>
            <div className="pl-3 flex items-center font-medium">
              <div>
                <div>{item.name}</div>
                <div className="text-primary-purple-light-40">{item.company}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ParticipantsList;
