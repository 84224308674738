import React, { FC, forwardRef, useRef } from 'react';
import { ID, TagWithDetails } from '../../Models';
import { CheckBoxWrapper, Placeholder, DataBlock, Container, TagName, TagNumber } from './styles';
import { Checkbox } from '@chakra-ui/react';

interface TagItemProps {
  tag: TagWithDetails;
  disabled?: boolean;
  isChecked: boolean;
  onCheckedChange(tagId: ID, isChecked: boolean): void;
  onTagClick(tagId: ID): void;
}

const TagItem = forwardRef<HTMLDivElement, TagItemProps>(
  ({ tag, disabled, isChecked, onCheckedChange, onTagClick }, ref) => {
    const checkBoxRef = useRef<HTMLInputElement>(null);

    const handleTagClick = (e: React.MouseEvent<HTMLElement>, tagId: ID) => {
      if (checkBoxRef.current?.parentElement?.contains(e.target as Node)) return;
      onTagClick && onTagClick(tagId);
    };

    return (
      <Container onClick={(e) => handleTagClick(e, tag.id)} ref={ref}>
        <DataBlock>
          {!disabled && (
            <CheckBoxWrapper>
              <Checkbox
                isChecked={isChecked}
                className="flex"
                colorScheme="purple"
                onChange={(e) => onCheckedChange(tag.id, e.target.checked)}
                style={{ padding: '12px' }}
                ref={checkBoxRef}
                disabled={disabled}
              />
            </CheckBoxWrapper>
          )}
          <TagName color={tag.color} withoutCheckbox={disabled}>
            {tag.name ? tag.name : <Placeholder>New tag</Placeholder>}
          </TagName>
          <TagNumber>
            {tag.tagsDocumentsConnection.totalCount +
              tag.tagsNotesConnection.totalCount +
              tag.tagsTranscriptionsConnection.totalCount}
          </TagNumber>
        </DataBlock>

        <DataBlock>{tag.description}</DataBlock>

        {/* <DataBlock>{tag.keywords}</DataBlock> */}
      </Container>
    );
  }
);

TagItem.displayName = 'TagItem';

export default TagItem;
