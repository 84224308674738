import React, { ReactText, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { toast } from 'react-toastify';
import NotFoundPage from './Pages/NotFoundPage';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Authenticated from './Authenticated';
import SharedInsight from './Pages/SharedInsight';
import SharedInsights from './Pages/SharedInsights';
import Maintance from './Pages/Maintance';

const colors = {
  purple: {
    600: '#382152',
    500: '#382152',
    80: 'rgba(59, 38, 81, 0.8)',
  },
  gray: {
    300: '#D7D3DC',
  },
};

const theme = extendTheme({
  colors,
  shadows: {
    outline: '0 0 0 3px #D7D3DC',
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          _focus: {
            boxShadow: 'none',
          },
          border: '1px solid #D7D3DC',
        },
      },
      variants: {
        card: {
          control: {
            marginTop: '2px',
          },
        },
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'purple.80',
      },
    },
  },
});

function App(): JSX.Element {
  const [offlineToast, setOfflineToast] = useState<ReactText>('');

  const setOnline = () => {
    toast.dismiss(offlineToast);
    toast.success('Notably is back online!', {
      autoClose: 5000,
    });
  };
  const setOffline = () => {
    setOfflineToast(
      toast.warning(
        "We're sorry. Notably is currently offline. Any changes made will not be saved. Please refresh to try again soon or reach out to us if this problem continues."
      )
    );
  };

  // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ChakraProvider theme={theme}>
        <Switch>
          {/* <Route path="/" component={Maintance} /> */}
          <Route exact path="/s/:orgId/insights/:dashboardToken">
            <SharedInsights />
          </Route>
          <Route exact path="/s/:orgId/insights/:dashboardToken/:clientId">
            <SharedInsight />
          </Route>
          <Route exact path="/s/:orgId/insight/:token">
            <SharedInsight />
          </Route>
          <Route exact path="/404" component={NotFoundPage} />
          <Authenticated />
        </Switch>
      </ChakraProvider>
    </>
  );
}

export default App;
