import React from 'react';
import { SlackWebhook, useFetchSlackWebhooks } from '../../Hooks/useSlackWebhooks';
import Select from '../Select';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { UPDATE_SLACK_WEBHOOK } from '../../GraphQL/mutations';

export function SlackLongDescription(): JSX.Element {
  const { data, refetch } = useFetchSlackWebhooks();
  const webhooks = data?.slackWebhooks || [];
  return (
    <>
      <h3 className="font-semibold text-sm py-2">Notifications</h3>
      <p>
        Share and get instant notifications to Slack on events such as new summaries and new
        insights.
      </p>
      <p>
        By default, all projects send to the channel selected here in workspace settings. Specify a
        different channel in project settings.
      </p>
      {webhooks.length > 0 && (
        <div id="#slack-webhooks-list" className={'text-sm py-1 block border-t mt-2 py-2'}>
          <p className="py-2">
            Your Slack Workspace: <strong>{webhooks[0]?.teamName}</strong> is connected.
          </p>
          {webhooks.map((webhook) => (
            <WebhookInfo webhook={webhook} key={webhook.id} onUpdate={() => refetch()} />
          ))}
        </div>
      )}
    </>
  );
}

interface WebhookInfoProps {
  webhook: SlackWebhook;
  onUpdate: () => void;
}
function WebhookInfo({ webhook }: WebhookInfoProps): JSX.Element {
  const options = [{ label: webhook.channel, value: webhook.channel }];

  const [updateSlackWebhookMutation] = useMutation<SlackWebhook>(UPDATE_SLACK_WEBHOOK);

  const handleCheckboxChange = (value: string[]) => {
    updateSlackWebhookMutation({
      variables: {
        id: webhook.id,
        postNewSummaries: value.includes('post_new_summaries'),
        postNewInsights: value.includes('post_new_insights'),
      },
    });
  }

  const selectedPostTriggers = [];
  if (webhook.postNewSummaries) {
    selectedPostTriggers.push('post_new_summaries');
  }
  if (webhook.postNewInsights) {
    selectedPostTriggers.push('post_new_insights');
  }

  return (
    <div className={'flex flex-row'} key={webhook.id}>
      <div className={'mr-2'}>
        All projects in your Notably workspace send to this channel.
        <Select
          options={options}
          value={options[0]}
          isSearchable={false}
          isDisabled={true}
          className="my-2"
        />
        <CheckboxGroup
          colorScheme="purple"
          value={selectedPostTriggers}
          onChange={handleCheckboxChange}
        >
          <Checkbox value={"post_new_summaries"} className="flex">New Summaries</Checkbox>
          {/* TODO add post_new_insights when we implement insight statuses */}
        </CheckboxGroup>
      </div>
    </div>
  );
}
