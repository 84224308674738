import React, { ReactNode } from 'react';
import SettingsSidebar from '../Components/SettingsSidebar';

type DefaultLayoutProps = {
  children: ReactNode;
};

function SettingsLayout({ children }: DefaultLayoutProps): JSX.Element {
  return (
    <div className={'flex-auto w-full flex'}>
      <SettingsSidebar />
      <div className={'flex-auto'}>{children}</div>
    </div>
  );
}

export default SettingsLayout;
