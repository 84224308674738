import { default as Discovery } from '../assets/projects/default-icons/Discovery.svg';
import { default as IdeaSeed } from '../assets/projects/default-icons/Idea-Seed.svg';
import { default as Observation } from '../assets/projects/default-icons/Observation.svg';
import { default as RedHerring } from '../assets/projects/default-icons/Red-Herring.svg';
import { default as RedThread } from '../assets/projects/default-icons/Red-Thread.svg';
import { default as SacredCow } from '../assets/projects/default-icons/Sacred-Cow.svg';
import { default as SynthesisSpark } from '../assets/projects/default-icons/Synthesis-Spark.svg';
import { default as UserTesting } from '../assets/projects/default-icons/User-Testing.svg';
import { default as Square } from '../assets/projects/default-icons/2x2.svg';
import { default as AI } from '../assets/projects/default-icons/AI.svg';
import { default as Discussion } from '../assets/projects/default-icons/Discussion.svg';
import { default as Eye } from '../assets/projects/default-icons/Eye.svg';
import { default as Idea } from '../assets/projects/default-icons/Idea.svg';
import { default as Journey } from '../assets/projects/default-icons/Journey.svg';
import { default as Money } from '../assets/projects/default-icons/Money.svg';
import { default as ResearchDocs } from '../assets/projects/default-icons/Research-Docs.svg';
import { default as Space } from '../assets/projects/default-icons/Space.svg';
import { default as Sparkles } from '../assets/projects/default-icons/Sparkles.svg';
import { default as Sticky } from '../assets/projects/default-icons/Sticky.svg';
import { default as Voting } from '../assets/projects/default-icons/Voting.svg';

export const defaultProjectIcons = [
  { name: 'Discovery', url: Discovery, position: '50% 50%' },
  { name: 'IdeaSeed', url: IdeaSeed, position: '50% 50%' },
  { name: 'Observation', url: Observation, position: '50% 50%' },
  { name: 'RedHerring', url: RedHerring, position: '50% 50%' },
  { name: 'RedThread', url: RedThread, position: '50% 50%' },
  { name: 'SacredCow', url: SacredCow, position: '50% 50%' },
  { name: 'SynthesisSpark', url: SynthesisSpark, position: '50% 50%' },
  { name: 'UserTesting', url: UserTesting, position: '50% 50%' },
  { name: 'Square', url: Square, position: '50% 50%' },
  { name: 'AI', url: AI, position: '50% 50%' },
  { name: 'Discussion', url: Discussion, position: '50% 50%' },
  { name: 'Eye', url: Eye, position: '50% 50%' },
  { name: 'Idea', url: Idea, position: '50% 50%' },
  { name: 'Journey', url: Journey, position: '50% 50%' },
  { name: 'Money', url: Money, position: '50% 50%' },
  { name: 'ResearchDocs', url: ResearchDocs, position: '50% 50%' },
  { name: 'Space', url: Space, position: '50% 50%' },
  { name: 'Sparkles', url: Sparkles, position: '50% 50%' },
  { name: 'Sticky', url: Sticky, position: '50% 50%' },
  { name: 'Voting', url: Voting, position: '50% 50%' },
];
