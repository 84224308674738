const defaultRedirectURI =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '') +
  '/settings/integrations';

export function getOneDrivePublishableUrl(redirectUri: string): string {
  return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
  client_id=aca9c131-fc95-44da-b928-f13400056354
  &response_type=code
  &redirect_uri=${defaultRedirectURI}
  &response_mode=query
  &scope=https%3A%2F%2Fgraph.microsoft.com%2FOnlineMeetings.Read
  `;
}
