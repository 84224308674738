import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';

import axios from 'axios';
import BACKEND_URL from '../Consts/backendUrl';
import { defaultCoverImages } from '../Consts/coverImages';

import Loader from '../Components/Loader';
import { defaultProjectIcons } from '../Consts/projectIcons';
import Masonry from 'react-masonry-css';
import { InsightCard, InsightTitle } from '../Components/InsightsCardView';
import SharingLayout from '../Layouts/SharingLayout';

export default function SharedInsights(): JSX.Element {
  const { dashboardToken } = useParams<{ dashboardToken: string }>();
  const { orgId } = useParams<{ orgId: string }>();
  const [dashboard, setDashboard] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState<string | null | undefined>(null);

  const history = useHistory();

  const analytics = useSegment();

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${BACKEND_URL}/shared/${orgId}/insights/${dashboardToken}`);

      if (!res.data.dashboard?.id) {
        history.push('/404');
        return;
      }

      setDashboard(res.data.dashboard);
      setLogo(res.data.logo);
      setLoading(false);

      analytics.page({
        name: 'Shared Insights',
        properties: {
          dashboardId: res.data.dashboard.id,
        },
      });
    };
    fetchData();
  }, [dashboardToken]);

  if (loading) return <Loader />;

  const icon =
    defaultProjectIcons.find((icon) => icon.name === dashboard?.cover) || defaultProjectIcons[0];

  return (
    <SharingLayout logo={logo}>
      <div className="mx-auto max-w-[800px] mt-5 px-3">
        <h1 className="flex items-center font-medium text-lg">
          <img
            className="rounded-md mr-3"
            src={icon?.url}
            alt="Project icon"
            style={{
              width: 48,
              height: 48,
              objectFit: 'cover',
            }}
          />
          <div>{dashboard?.name || 'Untitled'}</div>
        </h1>
        <hr className="mt-3 mb-5" />
        <Masonry
          breakpointCols={{
            default: 2,
            600: 1,
          }}
          className="basic-masonry-grid"
          columnClassName="basic-masonry-grid_column"
        >
          {dashboard.projectInsights.map((insight: any) => {
            const selectedImage = insight?.customCover
              ? { name: 'custom', url: insight.customCover, position: '50% 50%' }
              : defaultCoverImages.find((image) => image.name === insight?.cover);
            return (
              <InsightCard key={insight.clientId}>
                <Link to={`/s/${orgId}/insights/${dashboardToken}/${insight.clientId}`}>
                  {selectedImage && (
                    <img
                      src={selectedImage.url}
                      className="rounded-t"
                      alt="cover image"
                      style={{
                        width: '100%',
                        height: 150,
                        objectFit: 'cover',
                      }}
                    />
                  )}
                  <div className="p-3">
                    <InsightTitle isEmpty={!insight.title}>
                      {insight.title || 'Untitled Insight'}
                    </InsightTitle>
                  </div>
                </Link>
              </InsightCard>
            );
          })}
        </Masonry>
      </div>
    </SharingLayout>
  );
}
