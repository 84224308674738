import React from 'react';
const COLORS = [
  '#134883',
  '#243D52',
  '#3B2651',
  '#E51F68',
  '#3B70E9',
  '#69AAA9',
  '#8E84EE',
  '#8D4067',
  '#000000',
  '#F38E0E',
  '#D12D28',
  '#FF7E7C',
];

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = (hex.match(/\w\w/g) || ['00', '00', '00']).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const getColor = (s: string) => {
  const alphaVal = s.toLowerCase().charCodeAt(0) - 97 + 1;

  return COLORS[(alphaVal > 0 ? alphaVal : 0) % COLORS.length];
};

const LetteredAvatar = ({ name, size = 24 }: { name: string; size?: number }): JSX.Element => {
  const initials = name && name.length > 0 ? name[0].toUpperCase() : '?';
  const color = getColor(initials);

  const styles = {
    color: color,
    backgroundColor: hex2rgba(color, 0.4),
    width: size,
    height: size,
    lineHeight: `${size}px`,
    borderRadius: `${size}px`,
    fontSize: `100%`,
  };

  return (
    <div className="text-center flex items-center justify-center" style={styles} aria-label={name}>
      <div className="overflow-hidden whitespace-nowrap font-bold text-sm">{initials}</div>
    </div>
  );
};

export default LetteredAvatar;
