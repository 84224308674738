import Image from '@tiptap/extension-image';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';
import ImageResizeComponent from './ImageResizeComponent';

export interface ResizableImageOptions {
  HTMLAttributes: Record<string, any>;
  editable?: boolean;
}

const ResizableImage = Image.extend<ResizableImageOptions>({
  name: 'ResizableImage',
  draggable: true,
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: '100%',
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: 'auto',
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
      },
      align: {
        default: 'left',
        renderHTML: (attributes) => {
          return {
            align: attributes.align,
          };
        },
      },
      editable: true,
    };
  },
  parseHTML() {
    return [
      {
        tag: 'resizable-image',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['resizable-image', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageResizeComponent);
  },
});

export default ResizableImage;
