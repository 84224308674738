import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CalendarIcon, UserIcon, TagIcon } from '@heroicons/react/outline';

export const EditorContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 108px);
  overflow: hidden;
`;

export const EditorLeftPanel = styled.div`
  flex: 1;
  padding-left: 48px;
  padding-right: 48px;
  overflow-y: auto;
  position: relative;
`;
export const EditorRightPanel = styled.div``;

export const NameInput = styled(TextareaAutosize)`
  outline: none;
  transition: 0.3s ease 0s;
`;

export const Details = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px;
`;

export const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
`;

export const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

export const UserIconI = styled(UserIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const TagIconI = styled(TagIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const CalendarIconI = styled(CalendarIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const UserName = styled.span`
  margin-left: 8px;
`;
