import React, { ChangeEvent, ReactText, useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Button from '../Button';
import Loader from '../Loader';
import { Modal } from '..';
import useFiles from '../../Hooks/useFiles';

type ImageSourceProps = {
  isOpen: boolean;
  onComplete(src: string): void;
  onClose(): void;
};

const EditorImageModal = ({ isOpen, onComplete, onClose }: ImageSourceProps): JSX.Element => {
  const [imgSrc, setImgSrc] = useState('');
  const [link, setLink] = useState('');

  const toastRef = useRef<ReactText | null>(null);
  const { uploadFileToS3 } = useFiles();
  const cancel = axios.CancelToken.source();
  const [uploading, setUploading] = useState(false);

  const handleSave = () => {
    onComplete(imgSrc || link);
    setImgSrc('');
    setLink('');
  };

  const handleClose = () => {
    setImgSrc('');
    setLink('');
    onClose();
  };

  const onImgUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (uploading) {
      return;
    }

    if (e.target.files == null || e.target.files.length == 0) {
      return;
    }

    setUploading(true);

    const file = e.target.files[0];
    if (!file.name.endsWith('.png') && !file.name.endsWith('.jpg')) {
      toastRef.current = toast(<>Only .png and .jpg files are supported!</>, {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    try {
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        isPublic: true,
      });

      setImgSrc(result.url);

      if (!result.ok) {
        throw new Error('Error uploading file');
      }
    } catch {
      if (axios.isCancel(e)) {
        return;
      }

      toastRef.current = toast(<>Unexpected error while uploading file.</>, {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    setUploading(false);
  };

  return (
    <Modal title={'Add Image'} isOpen={isOpen} onClose={handleClose} maxWidth="400px">
      <div>
        {imgSrc && (
          <div className="my-4">
            <img
              src={imgSrc}
              alt="cover image"
              style={{
                width: 64,
                height: 64,
                objectFit: 'cover',
              }}
            />
          </div>
        )}
        {uploading && <Loader isFullScreen={false} size="25" />}
      </div>
      {!link && !imgSrc && !uploading && (
        <div>
          <label
            className="bg-white text-secondary-purple-dark focus:ring-secondary-purple-dark border-gray-200 inline-flex items-center px-3 py-2 border text-sm leading-4 font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default hover:bg-primary-purple-light focus:outline-none focus:ring-2 focus:ring-offset-2"
            htmlFor={'photo_upload'}
          >
            Upload Image
          </label>
          <input
            id={'photo_upload'}
            name={'photo_upload'}
            accept={'image/png, image/jpg'}
            type={'file'}
            className={'hidden'}
            onChange={onImgUpload}
          />
        </div>
      )}
      {!imgSrc && !uploading && (
        <div className="pt-4">
          <label htmlFor="text" className="block text-sm font-medium">
            Link
          </label>
          <input
            id={'link'}
            name={'link'}
            placeholder={'https://www.notably.ai/image.png'}
            required
            value={link}
            onChange={(e) => setLink(e.target.value)}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
          />
        </div>
      )}
      <Button
        className={'mt-4 flex-inline justify-center mr-2'}
        disabled={!imgSrc && !link}
        onClick={handleSave}
        type={'primary'}
      >
        Save
      </Button>
      <Button
        className={'mt-4 flex-inline justify-center mr-2'}
        onClick={handleClose}
        type={'secondary'}
      >
        Cancel
      </Button>
    </Modal>
  );
};

export default EditorImageModal;
