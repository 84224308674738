import styled from 'styled-components';

export const SortableContainer = styled.div<{ hoverDirection: string | null }>`
  position: relative;
  width: 100%;
  cursor: pointer;

  ${({ hoverDirection }) =>
    hoverDirection &&
    `
    &:after {
      content: '';
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #3b2651;

      ${hoverDirection === 'up' ? 'top: -11px' : 'bottom: -11px'};
    }
  `};
`;

export const Container = styled.div<{ isOver: boolean }>`
  position: relative;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: #3b2651;
    opacity: 0.1;
    display: none;
    ${({ isOver }) => (isOver ? { display: 'block' } : { display: 'none' })}
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  margin-right: 10px;
`;

export const TagNumber = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  margin-left: 4px;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const DotsButton = styled.div`
  position: relative;
  margin-right: 10px;
  cursor: pointer;
`;

export const CollapseButton = styled.div<{ isCollapsed: boolean }>`
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? '180deg' : '0deg')});
  cursor: pointer;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 5px 10px;
  display: flex;
  border-top: 1px solid #d7d3dc;
`;

export const NewTagButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
  user-select: none;
`;

export const NewTagButtonIcon = styled.div`
  margin-right: 10px;
`;

export const Name = styled.input`
  width: 100%;
  padding: 5px 0;
  outline: none;
  font-weight: 500;
  background: transparent;
  border-radius: 3px;
  resize: none;
`;

export const TableContainer = styled.div<{ isCollapsed: boolean; contentHeight: number }>`
  width: 100%;
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  /* max-height: ${({ isCollapsed, contentHeight }) => (isCollapsed ? '0' : contentHeight)}px; */
  transition: max-height 0.16s ease-in-out;
  overflow: hidden;
`;

export const Menu = styled.div<{ isOpen: boolean }>`
  min-width: 200px;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 0px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  transform: scaleY(${({ isOpen }) => (isOpen ? '1' : '0')});
  transform-origin: top;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity 0.1s ease, transform 0.12s ease;
  z-index: 2;
  overflow: hidden;
`;

export const MenuButton = styled.div`
  padding: 5px 10px;
  user-select: none;
  color: var(--brand-purple, #3b2651);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const NameWrapper = styled.div`
  font-size: 14px;
  margin-left: 8px;
`;
