import {
  Reference,
  useLazyQuery,
  useMutation,
  useQuery,
  useApolloClient,
  FetchResult,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';
import {
  CREATE_DASHBOARD,
  DELETE_DASHBOARD,
  UPDATE_DASHBOARD,
  CREATE_DASHBOARD_FOLDER,
  UPDATE_DASHBOARD_FOLDER,
  DELETE_DASHBOARD_FOLDER,
  CREATE_DATA_FOLDER,
  COPY_DASHBOARD,
} from '../GraphQL/mutations';
import { getDashboards, getDashboards_dashboards } from '../GraphQL/__generated__/getDashboards';
import {
  CURRENT_ORG,
  FETCH_DASHBOARD,
  FETCH_DASHBOARDS,
  FETCH_RECENT_DASHBOARDS,
  FETCH_DASHBOARD_FOLDERS,
  FETCH_DATA_FOLDERS,
  FETCH_LIGHT_DASHBOARDS,
} from '../GraphQL/queries';
import { createContext, useEffect, useState } from 'react';
import { ID, Sticky, Tag } from '../Models';
import { sortBy } from 'lodash-es';
import useNotes from './useNotes';
import useThemes from './useThemes';
import useProjectInfo from './useProjectInfo';
import useTranscripts from './useTranscripts';
import { useCreateDocument } from './useDocuments';
import useFiles from './useFiles';
import { Id } from 'react-toastify';
import { nanoid } from 'nanoid';

type FetchBoardsTuple = [boolean, getDashboards_dashboards[] | null];
type FetchBoardTuple = [boolean, getDashboards_dashboards | null];

type TBoardsLight = {
  id: string;
  name: string;
  cover: string;
  createdAt: number;
};

export const CurrentDashboardContext = createContext<string | null | undefined>(null);

type UseBoardsType = {
  fetchBoards: () => FetchBoardsTuple;
  fetchBoard: (id: number) => FetchBoardTuple;
  refetchBoards:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<getDashboards>>)
    | undefined;
  updateBoard: (id: ID, input: Partial<getDashboards_dashboards>) => Promise<void>;
  createBoard: (name: string, rest?: any) => Promise<getDashboards_dashboards>;
  deleteBoard: (id: Id) => Promise<void>;
  copyBoard: (board: getDashboards_dashboards) => Promise<getDashboards_dashboards>;
};

export default function useBoards(): UseBoardsType {
  const [createDashboardMutation] = useMutation(CREATE_DASHBOARD);
  const [deleteDashboardMutation] = useMutation(DELETE_DASHBOARD);
  const [updateDashboardMutation] = useMutation(UPDATE_DASHBOARD);
  const [
    fetchBoardsQuery,
    {
      called: fetchBoardsQueryCalled,
      loading: fetchBoardsQueryLoading,
      data: fetchBoardsQueryData,
      refetch: refetchBoards,
    },
  ] = useLazyQuery<getDashboards>(FETCH_DASHBOARDS);

  const client = useApolloClient();

  const [dashboards, setDashboards] = useState<getDashboards_dashboards[]>([]);
  const [dashboard, setDashboard] = useState<getDashboards_dashboards | null>(null);

  const { createNote } = useNotes(1);
  const { createTheme } = useThemes(1);
  const { createProjectInfo } = useProjectInfo(1);
  const { createTranscript } = useTranscripts();
  const { createFile } = useFiles();
  const [createDocument] = useCreateDocument();

  const [createDataFolderMutation] = useMutation(CREATE_DATA_FOLDER);

  const { loading, data, refetch } = useQuery(FETCH_DASHBOARD, { variables: { dashboardId: 0 } });

  async function createBoard(name: string, rest?: any) {
    const {
      data: {
        createDashboard: { dashboard },
      },
    } = await createDashboardMutation({
      variables: {
        input: { name, ...(rest ?? {}) },
      },
      optimisticResponse: {
        createDashboard: {
          dashboard: { name, ...(rest ?? {}) },
        },
      },
      refetchQueries: [
        {
          query: CURRENT_ORG,
        },
        {
          query: FETCH_DASHBOARDS,
        },
        {
          query: FETCH_RECENT_DASHBOARDS,
        },
      ],
    });

    return dashboard;
  }

  async function deleteBoard(dashboardId: ID) {
    await deleteDashboardMutation({
      variables: {
        id: dashboardId,
      },
      refetchQueries: [
        {
          query: CURRENT_ORG,
        },
        {
          query: FETCH_DASHBOARDS,
        },
        {
          query: FETCH_RECENT_DASHBOARDS,
        },
      ],
      update(cache) {
        cache.modify({
          fields: {
            dashboards(existingDashboardRefs, { readField }) {
              return existingDashboardRefs.filter(
                (dashboardRef: Reference) => dashboardId !== readField('id', dashboardRef)
              );
            },
          },
        });
      },
    });
  }

  useEffect(() => {
    if (!fetchBoardsQueryLoading && fetchBoardsQueryData && fetchBoardsQueryData.dashboards) {
      setDashboards(fetchBoardsQueryData.dashboards);
    }
  }, [fetchBoardsQueryLoading, fetchBoardsQueryData]);

  useEffect(() => {
    if (!loading && data && data.dashboard) {
      setDashboard(data.dashboard);
    }
  }, [loading, data]);

  function fetchBoards() {
    if (!fetchBoardsQueryCalled) {
      fetchBoardsQuery();
    }

    return [fetchBoardsQueryLoading, dashboards] as FetchBoardsTuple;
  }

  function fetchBoard(id: ID) {
    if (id && parseInt(dashboard?.id || 0) != id) {
      refetch({ dashboardId: parseInt(id as string) });
      return [true, dashboard] as FetchBoardTuple;
    }
    return [loading, dashboard] as FetchBoardTuple;
  }

  async function updateBoard(id: ID, input: Partial<getDashboards_dashboards>) {
    await updateDashboardMutation({
      variables: {
        id,
        input: input,
      },
      optimisticResponse: {
        updateDashboard: {
          dashboard: { ...data, ...input },
        },
      },
      refetchQueries: [
        {
          query: FETCH_DASHBOARDS,
        },
        {
          query: FETCH_RECENT_DASHBOARDS,
        },
      ],
    });
  }

  // TODO: this is not safe, need to move to a transaction on the backend
  // If the code below fails it will produce unexpected results
  async function copyBoard(boardToCopy: getDashboards_dashboards) {
    const newDataFolders: { [key: string]: string } = {};
    const {
      data: { dataFolders },
    } = await client.query({
      query: FETCH_DATA_FOLDERS,
      variables: { dashboardId: boardToCopy.id },
    });

    const random = nanoid(4);
    const newBoard = await createBoard('Copy of ' + boardToCopy.name, {
      insights: boardToCopy.insights,
    });
    const notes = boardToCopy.notes;
    if (loading) {
      return null;
    }
    const sortedNotes = sortBy<Sticky>(notes, 'themeId');

    let currentThemeId: ID | null = null;
    let newThemeId: ID | null = null;

    const tasks: Promise<any>[] = [];

    for (const dataFolder of dataFolders) {
      const newDataFolder = await createDataFolderMutation({
        variables: {
          name: dataFolder.name,
          dashboardId: newBoard.id,
        },
      });
      newDataFolders[dataFolder.id] = newDataFolder.data?.createDataFolder?.dataFolder?.id;
    }

    for (const note of sortedNotes) {
      if (note.theme) {
        if (note.theme.id != currentThemeId) {
          currentThemeId = note.theme.id;
          const newTheme = await createTheme(
            newBoard.id,
            {
              name: note.theme.name,
              x: note.theme.x,
              y: note.theme.y,
              color: note.theme.color,
            },
            []
          );
          newThemeId = newTheme.id;
        }
      }

      tasks.push(
        createNote(newBoard.id, {
          x: note.x,
          y: note.y,
          color: note.color,
          text: note.text,
          sentimentScore: note.sentimentScore,
          themeId: note.theme ? newThemeId : null,
          tagsNotes: {
            create: note.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
          },
        })
      );
    }

    await Promise.all(tasks);

    const copiedTranscripts: string[] = [];

    for (const file of boardToCopy.files ?? []) {
      if (file.transcription) {
        copiedTranscripts.push(file.transcription.id);
      }
      tasks.push(
        (async () => {
          const newFile = await createFile(newBoard.id, {
            name: file.name,
            type: file.type,
            s3VideoPath: file.s3VideoPath ? file.s3VideoPath + `?copy=${random}` : null,
            s3AudioPath: file.s3AudioPath ? file.s3AudioPath + `?copy=${random}` : null,
            status: file.status,
            folderId: file.folderId ? newDataFolders[file.folderId] : null,
          });

          if (file.transcription) {
            await createTranscript(newBoard.id, {
              fileId: newFile.id,
              name: file.transcription.name,
              text: file.transcription.text,
              tags: file.transcription.tags,
              folderId: file.transcription.folderId
                ? newDataFolders[file.transcription.folderId]
                : null,
            });
          }
        })()
      );
    }

    await Promise.all(tasks);

    for (const transcript of boardToCopy.transcriptions ?? []) {
      if (copiedTranscripts.includes(transcript.id)) {
        continue;
      }
      tasks.push(
        await createTranscript(newBoard.id, {
          name: transcript.name,
          text: transcript.text,
          tags: transcript.tags,
          folderId: transcript.folderId ? newDataFolders[transcript.folderId] : null,
        })
      );
    }

    for (const document of boardToCopy.documents ?? []) {
      tasks.push(
        await createDocument(newBoard.id, {
          content: document.content,
          name: document.name,
          tags: document.tags,
          folderId: document.folderId ? newDataFolders[document.folderId] : null,
        })
      );
    }

    tasks.push(
      createProjectInfo({
        dashboardId: newBoard.id,
        name: boardToCopy.projectInfo?.name,
        description: boardToCopy.projectInfo?.description,
      })
    );

    await Promise.all(tasks);

    return newBoard;
  }

  return {
    copyBoard,
    createBoard,
    deleteBoard,
    fetchBoards,
    refetchBoards,
    fetchBoard,
    updateBoard,
  };
}

export const useFetchLightBoards = (): [boolean, TBoardsLight[]] => {
  const { loading, data } = useQuery(FETCH_LIGHT_DASHBOARDS);
  return [loading, data?.dashboards];
};

export const useFetchBoard = (dashboardId: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_DASHBOARD, {
    variables: { dashboardId },
  });
  return [loading, data?.dashboard];
};

export interface Folder {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}
export const useFetchFolders = (): [boolean, Folder[] | undefined] => {
  const { loading, data } = useQuery<{ dashboardFolders: Folder[] }>(FETCH_DASHBOARD_FOLDERS);
  return [loading, data?.dashboardFolders];
};

export const useCreateFolder = (): [(name?: string) => void] => {
  const [createFolderMutation] = useMutation(CREATE_DASHBOARD_FOLDER);

  return [
    (name?: string) => {
      return createFolderMutation({
        variables: {
          name: name,
        },
        refetchQueries: [
          {
            query: FETCH_DASHBOARD_FOLDERS,
          },
        ],
      });
    },
  ];
};

export const useUpdateFolder = (): [(id: string, name: string) => void] => {
  const [updateFolderMutation] = useMutation(UPDATE_DASHBOARD_FOLDER);

  return [
    (id: string, name: string) => {
      return updateFolderMutation({
        variables: {
          id,
          name,
        },
        refetchQueries: [
          {
            query: FETCH_DASHBOARD_FOLDERS,
          },
        ],
      });
    },
  ];
};

export const useUpdateBoard = (): [(id: ID, input: Partial<getDashboards_dashboards>) => void] => {
  const [updateDashboardMutation] = useMutation(UPDATE_DASHBOARD);

  return [
    (id: ID, input: Partial<getDashboards_dashboards>) => {
      return updateDashboardMutation({
        variables: {
          id,
          input: input,
        },
      });
    },
  ];
};

export const useDeleteFolder = (): [(id: string) => void] => {
  const [deleteFolderMutation] = useMutation(DELETE_DASHBOARD_FOLDER);

  return [
    (id: string) => {
      return deleteFolderMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_DASHBOARD_FOLDERS,
          },
        ],
      });
    },
  ];
};

export const useCopyBoard = (): [(dashboardId: string) => Promise<FetchResult<any>>] => {
  const [copyBoardMutation] = useMutation(COPY_DASHBOARD);

  return [
    (dashboardId: string) => {
      return copyBoardMutation({
        variables: {
          dashboardId,
        },
        refetchQueries: [
          {
            query: FETCH_DASHBOARDS,
          },
        ],
      });
    },
  ];
};
