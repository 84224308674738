import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import useUsers from '../../../Hooks/useUsers';

interface CheckAccountStepProps {
  onOnboardingContinue(): void;
}

const CheckAccountStep: React.FC<CheckAccountStepProps> = ({ onOnboardingContinue }) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth0();
  const { mergeLogins } = useUsers();

  useEffect(() => {
    const checkEmail = async () => {
      setLoading(true);
      const data = await mergeLogins(user?.email || '');
      if (data.mergeLogins.status === 'successfully_merged') {
        window.location.reload();
        return;
      }
      setLoading(false);
      onOnboardingContinue();
    };
    if (user?.sub?.includes('google') || user?.sub?.includes('okta')) {
      checkEmail();
    } else {
      onOnboardingContinue();
    }
  }, [user?.email, user?.sub]);

  return (
    <div className="h-10 flex justify-center">
      {loading && <ReactLoading type={'spin'} color={'#382152'} height={'10%'} width={'10%'} />}
    </div>
  );
};

export default CheckAccountStep;
