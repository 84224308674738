import React, { FC, useMemo } from 'react';
import { Icon24 } from '../../Icons/Icon';
import {
  Container,
  IconContainer,
  StatsContent,
  StatsItem,
  StatsTitle,
  StatsValue,
} from './styles';
import { useFetchInsightComments, useFetchInsightReactions } from '../../Hooks/useInsights';
import { ID } from '../../Models';
import Spinner from '../Spinner';
import getClustersFromReactions from '../Reactions/utils';
import useUsers from '../../Hooks/useUsers';

interface InsightStatsProps {
  insightId: ID;
  viewsCount?: number;
  evidenceCount?: number;
}

const InsightStats: FC<InsightStatsProps> = ({ insightId, viewsCount = 0, evidenceCount = 0 }) => {
  const { currentUser: getCurrentUser } = useUsers();
  const [userLoading, currentUser] = getCurrentUser();
  const { data: comments, loading: commentsLoading } = useFetchInsightComments(insightId);
  const { loading: reactionsLoading, data: reactions } = useFetchInsightReactions(insightId);

  const countOfReactions = useMemo(() => {
    if (!reactions || !currentUser) return 0;
    return getClustersFromReactions(reactions, currentUser).filter(
      (cluster) => !!cluster.reactions.length
    ).length;
  }, [reactions, currentUser]);

  return (
    <Container>
      <StatsItem>
        <IconContainer>
          <Icon24.Eye />
        </IconContainer>
        <StatsContent>
          <StatsTitle>Views</StatsTitle>
          <StatsValue>{viewsCount}</StatsValue>
        </StatsContent>
      </StatsItem>

      <StatsItem>
        <IconContainer>
          <Icon24.LightBulb />
        </IconContainer>
        <StatsContent>
          <StatsTitle>Evidence</StatsTitle>
          <StatsValue>{evidenceCount}</StatsValue>
        </StatsContent>
      </StatsItem>

      <StatsItem>
        <IconContainer>
          <Icon24.Smile />
        </IconContainer>
        <StatsContent>
          <StatsTitle>Reactions</StatsTitle>
          <StatsValue>
            {reactionsLoading || userLoading || !reactions ? <Spinner /> : countOfReactions}
          </StatsValue>
        </StatsContent>
      </StatsItem>

      <StatsItem>
        <IconContainer>
          <Icon24.Comment />
        </IconContainer>
        <StatsContent>
          <StatsTitle>Comments</StatsTitle>
          <StatsValue>{commentsLoading || !comments ? <Spinner /> : comments.length}</StatsValue>
        </StatsContent>
      </StatsItem>
    </Container>
  );
};

export default InsightStats;
