import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ModalWindow from '../../Components/ModalWindow';
import { PopupModal } from 'react-calendly';

import { ModalTitle, ModalText, ModalSmallText, ModalSmallTextLink } from './styles';
import { useOrganization } from '../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose(): void;
}

const UpgradeModal: FC<UpgradeModalProps> = ({ isOpen, onClose }) => {
  const [demoBookingWindowOpen, setDemoBookingWindowOpen] = useState(false);
  const history = useHistory();
  const rootElt = document.getElementById('root');
  const [_, org] = useOrganization();
  const { user } = useAuth0();

  return (
    <>
      {isOpen && (
        <ModalWindow
          headerText="Available on Teams & Enterprise"
          confirmText="View plans"
          cancelText="Book demo"
          onConfirm={async () => {
            onClose();
            history.push('/settings/plans');
          }}
          onCancel={() => {
            onClose();
            setDemoBookingWindowOpen(true);
          }}
          onClose={onClose}
        >
          <>
            <ModalTitle>Upgrade to create global tags</ModalTitle>
            <ModalText>
              Global tags are automatically added to all projects. Use these pre-defined tags or
              upgrade to create custom global tags.
            </ModalText>

            <ModalSmallText>
              <ModalSmallTextLink href="https://help.notably.ai/en/articles/6657217-what-are-project-tags-global-tags-suggested-tags">
                Learn
              </ModalSmallTextLink>
              how to use global tags.
            </ModalSmallText>
          </>
        </ModalWindow>
      )}

      {rootElt && (
        <PopupModal
          url="https://calendly.com/notably-sales/notably-onboarding"
          pageSettings={{
            hideEventTypeDetails: true,
            hideGdprBanner: true,
            textColor: '382152',
            primaryColor: '3b2651',
          }}
          prefill={{
            email: user?.email || '',
            name: user?.name || '',
            customAnswers: { a1: org.name || '' },
          }}
          onModalClose={() => setDemoBookingWindowOpen(false)}
          open={demoBookingWindowOpen}
          rootElement={rootElt}
        />
      )}
    </>
  );
};

export default UpgradeModal;
