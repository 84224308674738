import { Popover } from '@headlessui/react';
import React, { useState } from 'react';
import { ShareIcon, DuplicateIcon } from '@heroicons/react/outline';
import {
  FormControl,
  FormLabel,
  Switch,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import Button from '../Button';

export default function Sharing({
  link,
  onChange,
  type,
}: {
  link?: string | null;
  onChange: (isShared: boolean) => void;
  type?: 'primary' | 'secondary';
}): JSX.Element {
  const [justCopied, setJustCopied] = useState(false);
  return (
    <Popover className="relative">
      <Popover.Button className="flex">
        <Button type={type || 'primary'} as="div">
          <ShareIcon className={'w-4 h-4 mr-1'} />
          Share
        </Button>
      </Popover.Button>

      <Popover.Panel className="absolute z-100 mt-2 bg-white rounded shadow-base border border-primary-purple-light w-80 right-0 p-3">
        <div className="flex justify-between">
          <FormControl display="flex" alignItems="center" justifyContent="between">
            <FormLabel htmlFor="shareable-link" mb="0" width="100%">
              <span className="text-sm font-medium">Shareable link</span>
            </FormLabel>
            <Switch
              id="shareable-link"
              colorScheme="purple"
              isChecked={!!link}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.checked);
              }}
            />
          </FormControl>
        </div>
        {link && (
          <div className="mt-3">
            <InputGroup>
              <Input
                placeholder="Link"
                colorScheme="purple"
                isReadOnly
                value={justCopied ? 'Copied!' : link || ''}
                className="text-sm font-medium"
                style={{ fontSize: '14px' }}
              />
              <InputRightElement>
                <DuplicateIcon
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => {
                    if (link) {
                      navigator.clipboard.writeText(link);
                      setJustCopied(true);
                      setTimeout(() => {
                        setJustCopied(false);
                      }, 2000);
                    }
                  }}
                />
              </InputRightElement>
            </InputGroup>
          </div>
        )}
        <div className="text-primary-purple-light-40 text-sm font-medium mt-3">
          Anyone with the link can view this insight.
        </div>
      </Popover.Panel>
    </Popover>
  );
}
