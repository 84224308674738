import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import SpeakerComponent from './SpeakerComponent';

export interface SpeakerOptions {
  HTMLAttributes: Record<string, any>;
}

const Speaker = Node.create<SpeakerOptions>({
  name: 'speaker',
  group: 'block',
  content: 'inline*',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      speakerName: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-speaker-name') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.speakerName) {
            return {};
          }

          return {
            'data-speaker-name': attributes.speakerName,
          };
        },
      },
      startTime: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-start-time') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.startTime) {
            return {};
          }

          return {
            'data-start-time': attributes.startTime,
          };
        },
      },
      endTime: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-end-time') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.startTime) {
            return {};
          }

          return {
            'data-end-time': attributes.end,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `speaker`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['speaker', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addKeyboardShortcuts() {
    return {
      // 'Shift-Enter': ({ editor }) => {
      //   return false;
      // },
      // Enter: ({ editor }) => {
      //   return editor.commands.setHardBreak();
      // },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(SpeakerComponent);
  },
});

export default Speaker;
