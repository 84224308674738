import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 20px;
`;

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 999;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1020px;
  background-color: #ffffff;
  border-radius: 12px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  transition: opacity 0.12s ease-in;
  z-index: 9999;
  opacity: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const HeaderSubtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(59, 38, 81, 0.6);
  margin-bottom: 20px;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  margin: 0 10px 0;

  &:last-child {
    margin-right: 0;
  }
`;

export const Samples = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const SelectedIcon = styled.div`
  width: 22px;
  height: 22px;
  background-color: #3b2651;
  color: #ffffff;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -9px;
  top: -9px;
`;

const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`;

export const Sample = styled.div<{
  src?: string;
  selected?: boolean;
  disabled?: boolean;
  isGenerating: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: 260px;
  height: 141px;
  border-radius: 6px;
  box-shadow: 0px 1px 0px 0px #d7d3dc;
  cursor: pointer;

  ${({ src, isGenerating }) =>
    src
      ? css`
          background-image: url(${src});
          background-size: cover;
          background-repeat: no-repeat;
        `
      : isGenerating
      ? css`
          background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
          border-radius: 5px;
          background-size: 200% 100%;
          animation: 1s ${shine} linear infinite;
        `
      : css`
          background-color: #ececec;
        `}

  ${({ selected }) =>
    selected &&
    `
    ${SelectedIcon} {
      display: flex;
    }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 141px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 6px;
      }
    `}
`;

export const LeftSide = styled.div`
  width: 100%;
  max-width: 441px;
  flex-shrink: 0;
  padding: 20px;
  border-right: 1px solid #d7d3dc;
`;

export const RightSide = styled.div`
  position: relative;
  width: 100%;
  padding: 84px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #d7d3dc;
  padding: 20px;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const CountOfWords = styled.div`
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(59, 38, 81, 0.4);
  margin-bottom: 20px;
`;

export const Selects = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const SelectLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;
`;

export const DraftFromInsightWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 7px;
  left: 0;
`;

export const DraftFromInsight = styled.div`
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 8px;
  padding-right: 8px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);
  text-decoration: underline;
  cursor: pointer;
  box-shadow: 0px -10px 14px 4px rgba(255, 255, 255, 1);
  background-color: #ffffff;
`;

export const IconWrapper = styled.div`
  margin-right: 4px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const NotificationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 20px 0;
  text-align: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 0.5s linear infinite;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const NotificationText = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);
  max-width: 260px;
`;

export const LoadingState = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingStateText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(59, 38, 81, 0.6);
  font-weight: 500;
`;

export const PromptGenerationLoadingState = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  top: 20px;
`;

export const GenerationButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GenerationNotificationText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 160px;
`;
