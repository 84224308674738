import { useMutation, useQuery } from '@apollo/client';

import { FETCH_INSIGHT_EVIDENCES } from '../GraphQL/queries';
import { CREATE_EVIDENCE, DELETE_EVIDENCE } from '../GraphQL/mutations';
import { ID } from '../Models';
import { TEvidence } from '../Context/EvidenceContext';

export type TCreateEvidance = {
  dashboardId: ID;
  parentInsightId: ID;
  entityId: string;
  insightId?: ID;
  noteId?: ID;
  tagId?: ID;
  themeId?: ID;
};

export const useFetchInsightEvidences = (insightId: ID): [boolean, TEvidence[], any] => {
  const { loading, data, refetch } = useQuery(FETCH_INSIGHT_EVIDENCES, {
    variables: { parentInsightId: insightId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  return [loading, data?.evidences || [], refetch];
};

export const useCreateEvidence = (): [(evidence: TCreateEvidance) => any] => {
  const [createMutation] = useMutation(CREATE_EVIDENCE);

  return [
    async (evidence: TCreateEvidance) => {
      const res = await createMutation({
        variables: {
          input: { ...evidence },
        },
      });
      return res?.data?.createEvidence?.evidence;
    },
  ];
};

export const useDeleteEvidence = (): [(id: ID) => any] => {
  const [deleteMutation] = useMutation(DELETE_EVIDENCE);

  return [
    async (id: ID) => {
      const res = await deleteMutation({
        variables: {
          id,
        },
      });

      return res;
    },
  ];
};
