import React from 'react';

export default function GrabIcon() {
  return (
    <svg width="16" height="19" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66667 11.3889V14.4444M4.66667 11.3889V4.05556C4.66667 3.04303 5.48747 2.22222 6.5 2.22222C7.51253 2.22222 8.33333 3.04303 8.33333 4.05556M4.66667 11.3889C4.66667 10.3764 3.84586 9.55556 2.83333 9.55556C1.82081 9.55556 1 10.3764 1 11.3889V13.8333C1 18.8959 5.10405 23 10.1667 23C15.2292 23 19.3333 18.8959 19.3333 13.8333V7.72222C19.3333 6.7097 18.5125 5.88889 17.5 5.88889C16.4875 5.88889 15.6667 6.7097 15.6667 7.72222M8.33333 4.05556V10.7778M8.33333 4.05556V2.83333C8.33333 1.82081 9.15418 1 10.1667 1C11.1792 1 12 1.82081 12 2.83333V4.05556M15.6667 7.72222V4.05556C15.6667 3.04303 14.8458 2.22222 13.8333 2.22222C12.8208 2.22222 12 3.04303 12 4.05556M15.6667 7.72222V10.7778M12 4.05556V10.7778"
        stroke="#3B2651"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
