import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useInterval } from 'usehooks-ts';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useDebouncedCallback } from 'use-debounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import useNotes from '../../Hooks/useNotes';
import { useHistory } from 'react-router';
import useTranscripts, { useFetchTranscript } from '../../Hooks/useTranscripts';
import { Loader } from '../../Components';
import Button from '../../Components/Button';
import SecondaryToolbar from '../../Components/SecondaryToolbar';
import Well from '../../Components/Well';
import { toast } from 'react-toastify';
import Avatar from '../../Components/Avatar';

import { TrashIcon, DocumentDuplicateIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import moment from 'moment';
import useFiles from '../../Hooks/useFiles';
import ParticipantPicker from '../../Components/ParticipantPicker';
import VideoPlayer from '../../Components/VideoPlayer';
import AudioPlayer from '../../Components/AudioPlayer';
import TagsPicker from '../../Components/TagsPicker';
import usePermissions from '../../Hooks/usePermissions';

import { Tag } from '../../Models';
import TipTapEditor from '../../Components/TipTapEditor';
import { HighlightsContext, HighlightsContextProvider } from '../../Context/HighlightsContext';
import { SpeakersContextProvider } from '../../Context/SpeakersContext';
import {
  CalendarIconI,
  Details,
  DetailsRow,
  EditorContainer,
  EditorLeftPanel,
  EditorRightPanel,
  Label,
  LabelWithIcon,
  NameInput,
  TagIconI,
  TranscriptionStatus,
  TranscriptionStatusText,
  UserIconI,
  UserName,
} from './styles';
import TagsPanel from '../../Components/TagsPanel/TagsPanel';
import NavigationPrompt from '../../Components/NavigationPrompt';
import { Editor } from '@tiptap/react';
import { SummaryFeedbackContextProvider } from '../../Context/SummaryFeedbackContext';
import useAnalytics from '../../Hooks/useAnalytics';
import { ProjectChecklistContext } from '../../Context/ProjectChecklistContext';

export type TimeOffset = {
  offset: number | null;
  shouldPlay?: boolean;
};

function Transcript(): JSX.Element {
  const { dashboardId, transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();

  const [editorContent, setEditorContent] = useState('');
  const { canEditTranscripts } = usePermissions();
  const [initialized, setInitialized] = useState(false);
  const [hasNewHighlights, setHasNewHighlights] = useState(false);
  const [enableStopNavigation, setEnableStopNavigation] = useState(true);
  const [showReaddPrompt, setShowReaddPrompt] = useState(false);

  const { updateTranscript, deleteTranscript, createTranscript } = useTranscripts();
  const { updateFile, deleteFile, createFile } = useFiles();
  const { createNotesFromEntitiesWithTags } = useNotes(dashboardId);
  const [loading, transcript, refetch] = useFetchTranscript(transcriptId);
  const {
    highlights,
    selectedEntityId,
    setHighlightsAddedToAnalysis,
    updateDocumentTags,
  } = useContext(HighlightsContext);

  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const history = useHistory();
  const { analytics } = useAnalytics();
  // Default offset to _slightly_ after video start in order to show the first frame as the thumbnail, but '0:00' as the time
  const [timeOffset, setTimeOffset] = useState<TimeOffset>({ offset: 0.9 });

  useInterval(
    () => {
      if (loading || !transcript) {
        return;
      }
      if (transcript?.file?.status !== 'READY' || !transcript?.text) {
        refetch();
      }
    },
    // Delay in milliseconds or null to stop it
    !transcript?.file || (transcript?.file?.status === 'READY' && transcript?.text) ? null : 3000
  );

  useEffect(() => {
    analytics.sendEvent('pageView.Transcript', {
      id: transcriptId,
      projectId: dashboardId,
    });
  }, [analytics, transcriptId, dashboardId]);

  useEffect(() => {
    if (initialized || loading || !transcript || !transcript.text) return;
    const parsedTranscript = JSON.parse(transcript.text);

    setInitialized(true);
    setEditorContent(parsedTranscript);
  }, [loading, transcript, initialized]);

  useEffect(() => {
    const handleTimestampClick = (e: any) => {
      setTimeOffset({ offset: e.detail.startTime, shouldPlay: e.detail.shouldPlay || false });
    };
    document.addEventListener('timestampClick', handleTimestampClick);

    return () => {
      document.removeEventListener('timestampClick', handleTimestampClick);
    };
  }, []);

  const debouncedSave = useDebouncedCallback(async (content: any) => {
    const transcriptJSON = JSON.stringify(content);

    if (isEmpty(transcriptJSON)) {
      return;
    }

    try {
      await updateTranscript(transcriptId, {
        text: transcriptJSON,
      });
    } catch (error) {
      toast.error(
        'An unexpected error occurred. Please refresh the page and try again. If the error persists, please contact support.'
      );
    }
  }, 500);

  useEffect(() => {
    if (highlights) {
      setHasNewHighlights(
        !highlights.every((highlight) => {
          return highlight.addedToAnalysis === true;
        })
      );
    }
  }, [highlights]);

  useEffect(() => {
    updateDocumentTags(transcript?.tagsList || []);
  }, [transcript?.tagsList]);

  async function createNotes(force = false) {
    if (highlights.length) {
      const toastId = toast.loading('Creating notes from highlighted text...');
      setEnableStopNavigation(false);

      await createNotesFromEntitiesWithTags(highlights, {
        transcriptId,
        participantId: transcript.participantId,
        tags: transcript.tagsList,
        force: !hasNewHighlights,
      });

      setHighlightsAddedToAnalysis(highlights);

      toast.update(toastId, {
        render: 'Successfully created notes from transcript!',
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      });
    }
  }

  const addHighlightsToAnalysis = async () => {
    if (!hasNewHighlights) {
      setShowReaddPrompt(true);
    } else {
      await createNotes();
      history.push('/projects/' + dashboardId + '/notes');
    }
  };

  async function updateName(newName: string) {
    if (newName === transcript.name) {
      return;
    }
    await updateTranscript(transcriptId, {
      name: newName,
    });
    if (!transcript.fileId) {
      return;
    }
    await updateFile(transcript.fileId, {
      name: newName,
    });
  }

  const debouncedUpdateName = useDebouncedCallback(updateName, 600);

  async function updateParticipant(participantId: string | null) {
    await updateTranscript(transcriptId, {
      participantId,
    });
  }

  async function handleCopy() {
    const random = nanoid(4);
    const file = transcript.file;
    let newFile;
    if (file) {
      newFile = await createFile(dashboardId, {
        name: file.name + ' copy',
        type: file.type,
        mimeType: file.mimeType,
        s3VideoPath: file.s3VideoPath ? file.s3VideoPath + `?copy=${random}` : null,
        s3AudioPath: file.s3AudioPath ? file.s3AudioPath + `?copy=${random}` : null,
        status: file.status,
        duration: null,
        metadata: file.metadata,
        size: null,
        statusText: file.statusText,
        isCopy: true,
      });
    }

    const res = await createTranscript(dashboardId, {
      name: transcript.name + ' copy',
      fileId: file ? newFile.id : null,
      text: transcript.text,
      participantId: transcript.participantId,
      tagsTranscriptions: {
        create: transcript.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
      },
    });

    history.push(`/projects/${dashboardId}/data/${res.id}`);
  }

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete "${transcript.name}"? This cannot be undone.`)) {
      return;
    }
    setEnableStopNavigation(false);
    await deleteTranscript(transcriptId);
    if (transcript.fileId) {
      await deleteFile(transcript.fileId);
    }

    analytics.sendEvent('DeleteTranscript', {
      id: transcriptId,
    });

    history.push('/projects/' + dashboardId + '/data');
  }

  async function updateTags(tags: Array<Tag> | null) {
    updateTranscript(transcriptId, {
      tagsTranscriptions: {
        deleteOthers: true,
        create: tags?.map((tag) => ({ tagId: tag.id })) || [],
      },
    });
    updateDocumentTags(tags || []);
  }

  const handleContainerRef = useCallback((node) => {
    setContainerElement(node);
  }, []);

  const getWellText = () => {
    if (transcript?.file?.type === 'video') {
      return `
        Analyze video and transcripts to discover emerging patterns. Click “Summarize” 
        to summarize this video using AI. Highlight and tag important parts to analyze then click “Add highlights to analysis.”`;
    } else if (transcript?.file?.type === 'audio') {
      return `Analyze audio and transcripts to discover emerging patterns. Click “Summarize” to summarize this recording using AI. 
        Highlight and tag important parts to analyze then click “Add highlights to analysis.”`;
    }

    return `Your data was added below. Click and drag on the text to highlight the content you want to turn into notes.`;
  };

  if (loading || !transcript) {
    return <Loader />;
  }

  const createEnabled = !!highlights.length;

  return (
    <div className={'flex-col h-full'}>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>
              <Link to={`/projects/${dashboardId}/data`}>Data</Link> /{' '}
              {transcript.name ?? 'Untitled Transcript'}
            </h1>
          </div>
          {canEditTranscripts && (
            <div className={'flex'}>
              <Button
                className={'mr-2 flex-inline'}
                onClick={() => handleCopy()}
                type={'secondary'}
              >
                <DocumentDuplicateIcon className={'w-4 h-4 mr-1'} />
                Copy
              </Button>
              <Button
                className={'mr-2 flex-inline'}
                onClick={() => handleDelete()}
                type={'secondary'}
              >
                <TrashIcon className={'w-4 h-4 mr-1'} />
                Delete
              </Button>
              <Button
                className={createEnabled ? '' : 'pointer-events-none opacity-75'}
                onClick={addHighlightsToAnalysis}
              >
                Add highlights to Analysis
              </Button>
            </div>
          )}
        </div>
      </SecondaryToolbar>
      <EditorContainer>
        <EditorLeftPanel ref={handleContainerRef}>
          <Well wellKey="transcript-record-well">{getWellText()}</Well>
          {transcript.file?.signedVideoUrl && transcript.file.type === 'video' && (
            <VideoPlayer
              src={transcript.file?.signedVideoUrl}
              timeOffset={timeOffset}
              scrollingContainer={containerElement}
            />
          )}
          {transcript.file?.signedVideoUrl && transcript.file.type === 'audio' && (
            <AudioPlayer
              src={transcript.file?.signedVideoUrl}
              srcType={transcript.file.mimeType}
              timeOffset={timeOffset}
              scrollingContainer={containerElement}
            />
          )}

          <Details>
            <NameInput
              defaultValue={transcript?.name}
              onBlur={(e) => updateName(e.target.value)}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              onKeyUp={(e) => debouncedUpdateName(e.currentTarget?.value)}
              placeholder="Untitled"
              className={'text-3xl my-6 w-full'}
              autoFocus={!transcript?.name}
              readOnly={!canEditTranscripts}
            />
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Created by</Label>
              </LabelWithIcon>
              <div className="flex items-center">
                <Avatar user={transcript?.userByCreatedBy} />
                <UserName>{transcript?.userByCreatedBy?.name || ''}</UserName>
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <div className={'cursor-pointer'}>
                  <UserIconI />
                </div>
                <Label>Participant</Label>
              </LabelWithIcon>
              <div>
                <ParticipantPicker
                  participant={transcript.participant}
                  onChange={updateParticipant}
                  readOnly={!canEditTranscripts}
                />
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <CalendarIconI />
                <Label>Date</Label>
              </LabelWithIcon>
              <div className="cursor-pointer">
                <DatePicker
                  onChange={async (date) => {
                    await updateTranscript(transcriptId, {
                      customDate: date,
                    });
                  }}
                  customInput={
                    <div>
                      {moment(transcript?.customDate || transcript?.createdAt).format('MM/DD/YYYY')}
                    </div>
                  }
                  selected={new Date(transcript?.customDate || transcript?.createdAt)}
                  popperPlacement="bottom"
                />
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <div className={'cursor-pointer'}>
                  <TagIconI />
                </div>
                <Label>Tags</Label>
              </LabelWithIcon>
              <div>
                <TagsPicker
                  tags={transcript.tagsList}
                  onChange={updateTags}
                  dashboardId={dashboardId}
                  readOnly={!canEditTranscripts}
                />
              </div>
            </DetailsRow>
          </Details>
          {transcript?.file?.status === 'PROCESSING' && (
            <TranscriptionStatus>
              <ReactLoading type={'spin'} color={'#382152'} height={'14px'} width={'14px'} />
              <TranscriptionStatusText>Transcribing...</TranscriptionStatusText>
            </TranscriptionStatus>
          )}
          {transcript?.file?.status === 'ERRORED' && (
            <TranscriptionStatus>
              <ExclamationCircleIcon className={'w-6 h-6 text-red-500'} />
              <TranscriptionStatusText>
                Unable to transcribe video. Re-upload the file to try again.
              </TranscriptionStatusText>
            </TranscriptionStatus>
          )}
          {initialized && containerElement && (
            <TipTapEditor
              content={editorContent}
              onChange={debouncedSave}
              editable={canEditTranscripts}
              containerElt={containerElement}
              withHighlights
              withSpeakers
              withToolbar
              getDownloadData={(editor: Editor | null) => {
                const filename =
                  transcript?.name?.replace(/[^a-z0-9]/gi, '_').toLowerCase() || 'transcript';
                let content = '';
                if (!editor) return { content, filename };

                const summaryEls = editor?.view?.dom?.querySelectorAll('.node-summary');
                const texts = [...(summaryEls || [])].map((el: any) => `${el.innerText}\n\n\n`);
                content += texts.join('');
                content += 'Transcript:\n\n';
                editor.state.doc.content.nodesBetween(
                  0,
                  editor.state.doc.content.size,
                  (node: any) => {
                    if (node.type.name === 'speaker') {
                      const speakerName = node.attrs.speakerName;
                      content += `${speakerName ? speakerName + ':\n' : ''}${
                        node?.textContent || ''
                      }\n\n`;
                    }
                  }
                );

                return {
                  content,
                  filename,
                };
              }}
              getSummaryGenerationData={(editor: Editor | null) => {
                if (!editor) return { content: [], documentId: '' };
                const quotes: string[] = [];

                editor.state.doc.content.nodesBetween(
                  0,
                  editor.state.doc.content.size,
                  (node: any) => {
                    if (node.type.name === 'speaker') {
                      const speakerName = node.attrs.speakerName;
                      quotes.push(
                        `${speakerName ? speakerName + ':\n' : ''}${node?.textContent || ''}`
                      );
                    }
                  }
                );
                return {
                  content: quotes,
                  transcriptId,
                };
              }}
            />
          )}
        </EditorLeftPanel>
        <EditorRightPanel>
          <TagsPanel editable={canEditTranscripts} />
        </EditorRightPanel>
      </EditorContainer>

      <NavigationPrompt
        name="prompt-start-analysis"
        title="Ready to start analysis?"
        text="Click “Add to analysis” to add new highlights to the Analysis section of a project. 
          Every highlight becomes a sticky note on a canvas and row in a table to analyze."
        confirmationText="Yes, add to analysis"
        cancelText="Not yet"
        onConfirm={createNotes}
        when={hasNewHighlights && enableStopNavigation}
        locationAfterConfirm={'/projects/' + dashboardId + '/notes'}
      />

      <NavigationPrompt
        name="prompt-readd-highlights"
        title="Add highlights to analysis?"
        text="Highlights have already been added as notes to analysis. Do you want to add them again?"
        confirmationText="Yes, add to analysis"
        cancelText="Not yet"
        onCancel={() => setShowReaddPrompt(false)}
        onConfirm={() => createNotes(true)}
        when={showReaddPrompt}
        basedOnNavigation={false}
        locationAfterConfirm={'/projects/' + dashboardId + '/notes'}
      />
    </div>
  );
}

const TranscriptPage = () => {
  const { dashboardId, transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();
  return (
    <HighlightsContextProvider
      key={dashboardId + transcriptId}
      dashboardId={dashboardId}
      transcriptionId={transcriptId}
    >
      <SpeakersContextProvider>
        <SummaryFeedbackContextProvider>
          <Transcript key={dashboardId + transcriptId} />
        </SummaryFeedbackContextProvider>
      </SpeakersContextProvider>
    </HighlightsContextProvider>
  );
};

export default TranscriptPage;
