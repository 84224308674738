export function transcriptRoute(dashboardId: string, transcriptId: string): string {
  return `/projects/${dashboardId}/data/${transcriptId}`;
}
export function documentRoute(dashboardId: string, documentId: string): string {
  return `/projects/${dashboardId}/data/docs/${documentId}`;
}
export function insightRoute(dashboardId: string, insightClientId: string): string {
  return `/projects/${dashboardId}/insights/${insightClientId}`;
}
export function noteRoute(dashboardId: string, noteId: string): string {
  return `/projects/${dashboardId}/notes/${noteId}`;
}
