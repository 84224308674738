import styled from 'styled-components';
import { breakpoint } from '../Shared';

export const TableSimple = styled.div.attrs({
  className: 'bg-white flex-col pb-52 w-full',
})``;

export const TableRow = styled.div<{ hover?: boolean | undefined }>`
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: rgb(56 33 82 / 0.1);
    cursor: pointer;
  }

  ${breakpoint.Mobile`
    padding: 6px 4px;
  `}
`;

// export const TableRow = styled.div.attrs<{ hover?: boolean | undefined }>((props) => ({
//   className: `${
//     props.hover === false ? '' : 'hover:bg-primary-purple/10 hover:cursor-pointer'
//   } flex py-2 px-3 justify-between items-center`,
// }))<{ hover?: boolean | undefined }>``;

export const TableCell = styled.div.attrs({
  className: 'text-ellipsis whitespace-nowrap flex text-left items-center',
})``;

export const MenuRow = styled.a.attrs<{ disabled?: boolean | undefined }>((props) => ({
  className: `${
    props.disabled
      ? 'text-primary-purple-light-60 cursor-not-allowed pointer-events-none'
      : 'text-primary-purple hover:bg-primary-purple-gray'
  } group flex items-center px-4 py-2 text-sm`,
}))<{ disabled?: boolean | undefined }>``;
