import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ID, TTagGroup, TTagGroupWithTags, TagWithDetails } from '../../Models';
import { Loader } from '../../Components';
import TagsEmptyState from './TagsEmptyState';
import TagsLayout from './TagsLayout';
import {
  useCreateGlobalTagGroup,
  useCreateGlobalTagGroupSorting,
  useCreateTag,
  useDeleteTag,
  useFetchGlobalTagGroupSorting,
  useFetchGlobalTagGroups,
  useGlobalTags,
  useMergeTags,
  useUpdateTag,
  useUpdateTagGroupSortings,
} from '../../Hooks/useTags';

import {
  ButtonText,
  ContentContainer,
  GhostButton,
  TagGroupWrapper,
  TagsWithoutGroupContainer,
} from './styles';
import TagsUpgradeSuggestion from '../../Components/TagsUpgradeSuggestion';
import TagModal from '../../Components/TagModal';
import useFeatures from '../../Hooks/useFeatures';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TagGroup from '../../Components/TagGroup';
import { DropArea, DragItem } from '../../Components/DragAndDrop';
import TagItem from '../../Components/TagItem';
import { Icon24 } from '../../Icons/Icon';
import usePermissions from '../../Hooks/usePermissions';

interface TagsProps {
  type: string;
}

const TagsSettings: FC<TagsProps> = ({ type }) => {
  const [checkedTags, setCheckedTags] = useState<TagWithDetails[]>([]);
  const [modalTagId, setModalTagId] = useState<ID | null>(null);
  const { globalTagsEnabled, globalTagsEditable } = useFeatures();
  const isFreePlanOnGlobal = !globalTagsEnabled && type !== 'project';
  const { canEditTags } = usePermissions();

  const [tagsLoading, externalTags, refetchTags] = useGlobalTags(true);
  const [tagGroupsLoading, externalTagGroups, refetchTagGroups] = useFetchGlobalTagGroups();
  const [, externalTagGroupSorting, refetchTagGroupSorting] = useFetchGlobalTagGroupSorting();

  const [tags, setTags] = useState<TagWithDetails[]>(externalTags as TagWithDetails[]);
  const [tagGroups, setTagGroups] = useState(externalTagGroups);

  const createTag = useCreateTag();
  const deleteTag = useDeleteTag();
  const updateTag = useUpdateTag();
  const mergeTags = useMergeTags();
  const createTagGroupSortings = useCreateGlobalTagGroupSorting();
  const updateTagGroupSortings = useUpdateTagGroupSortings();

  const createTagGroup = useCreateGlobalTagGroup();

  const groupsWithTags = useMemo(() => {
    const filteredTagGroups =
      tagGroups?.map((group) => {
        const tagsInGroup = tags
          .filter((tag) => tag.groupId === group.id)
          .sort((firstTag, secondTag) =>
            firstTag.name.toLowerCase() < secondTag.name.toLowerCase() ? -1 : 1
          );

        return {
          ...group,
          tags: tagsInGroup,
        };
      }) || [];

    return externalTagGroupSorting
      ? externalTagGroupSorting.sorting
          .map((id) => filteredTagGroups.find((group) => group.id === id) as TTagGroupWithTags)
          .filter((group) => !!group)
      : filteredTagGroups;
  }, [tagGroups, tags, externalTagGroupSorting]);

  const tagsWithoutGroup = useMemo(() => {
    return tags.filter((tag) => !tag.groupId) as TagWithDetails[];
  }, [tags]);

  useEffect(() => {
    if (!tagsLoading && externalTags) setTags(externalTags as TagWithDetails[]);
  }, [tagsLoading, externalTags]);

  useEffect(() => {
    if (!tagGroupsLoading && externalTagGroups) setTagGroups(externalTagGroups);
  }, [tagGroupsLoading, externalTagGroups]);

  const handleChangeTagGroup = () => {
    refetchTagGroups();
    refetchTags();
  };

  const handleAddNewGroup = async () => {
    if (!canEditTags) return;
    const newGroup = await createTagGroup();
    refetchTagGroups();
    await updateSorting(
      tagGroups.map((group) => group.id).concat(newGroup.id),
      externalTagGroupSorting?.id
    );
    refetchTagGroupSorting();
  };

  const handleConfirmDeletingTags = async () => {
    if (!canEditTags) return;
    for (const tag of checkedTags) {
      await deleteTag(tag.id);
    }

    setCheckedTags([]);
    refetchTags();
  };

  const handleConfirmMergingTags = async (newTagName: string) => {
    if (!canEditTags) return;
    await mergeTags(
      newTagName,
      checkedTags.map((tag) => tag.id),
      checkedTags
        .map((tag) => tag.groupId)
        .filter((id) => !!id)
        .reduce((a, b) => (a === b ? a : null)),
      true
    );

    setCheckedTags([]);
    refetchTags();
  };

  const handleAddNewTag = async (groupId?: ID) => {
    if (!canEditTags) return;
    const newTag = await createTag({ name: '', isGlobal: true, groupId });
    setModalTagId(newTag.id as string);
    refetchTags();
  };

  const handleDropTagOutsideOfGroup = async (tag: TagWithDetails) => {
    if (!canEditTags) return;
    await updateTag(tag.id, { groupId: null });
    refetchTags();
  };

  const generateTitle = () => {
    if (!tags.length) return 'Tags';
    return `${tags.length} ${tags.length > 1 ? 'Tags' : 'Tag'}`;
  };

  const updateSorting = async (sorting: ID[], id?: ID) => {
    if (id) {
      await updateTagGroupSortings(id, sorting);
    } else {
      await createTagGroupSortings(sorting);
    }
    refetchTagGroupSorting();
  };

  const moveGroup = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      if (!canEditTags) return;
      let newArray: TTagGroup[] = [];
      setTagGroups((prevCards) => {
        newArray = prevCards.slice();
        const temp = newArray[dragIndex];
        newArray[dragIndex] = newArray[hoverIndex];
        newArray[hoverIndex] = temp;
        return newArray;
      });

      updateSorting(
        newArray.map((group) => group.id),
        externalTagGroupSorting?.id
      );
    },
    [externalTagGroupSorting]
  );

  if (tagsLoading) return <Loader />;

  return (
    <TagsLayout
      title={generateTitle()}
      onCreateTag={handleAddNewTag}
      onDeleteTag={handleConfirmDeletingTags}
      onMergeTags={handleConfirmMergingTags}
      checkedTags={checkedTags}
      isGlobal
      hideButtons={!canEditTags}
    >
      <ContentContainer>
        {isFreePlanOnGlobal ? (
          <TagsUpgradeSuggestion />
        ) : tags.length ? (
          <DndProvider backend={HTML5Backend}>
            {groupsWithTags.map((group, index) => (
              <TagGroupWrapper key={group.id}>
                <TagGroup
                  index={index}
                  group={group}
                  checkedTags={checkedTags}
                  isGlobal
                  disabled={!canEditTags || !globalTagsEditable}
                  onCheckedTagsChange={setCheckedTags}
                  onTagClick={setModalTagId}
                  onCreateNewTag={handleAddNewTag}
                  onTagDrop={refetchTags}
                  onChange={handleChangeTagGroup}
                  moveGroup={moveGroup}
                />
              </TagGroupWrapper>
            ))}
            {canEditTags && (
              <GhostButton>
                <Icon24.Plus />
                <ButtonText onClick={() => handleAddNewGroup()}>New group</ButtonText>
              </GhostButton>
            )}

            <DropArea<TagWithDetails> onDrop={handleDropTagOutsideOfGroup} type="tagGroup">
              <TagsWithoutGroupContainer isOver={false}>
                {tagsWithoutGroup.map((tag) => (
                  <DragItem<TagWithDetails> key={tag.id} item={tag} type="tagGroup">
                    <TagItem
                      key={tag.id}
                      tag={tag}
                      isChecked={
                        checkedTags.findIndex((currentTag) => tag.id === currentTag.id) !== -1
                      }
                      onCheckedChange={(tagId, isChecked) =>
                        isChecked
                          ? setCheckedTags([...checkedTags, tag])
                          : setCheckedTags(
                              checkedTags.filter((currentTag) => currentTag.id !== tag.id)
                            )
                      }
                      onTagClick={setModalTagId}
                      disabled={!canEditTags}
                    />
                  </DragItem>
                ))}
              </TagsWithoutGroupContainer>
            </DropArea>
          </DndProvider>
        ) : (
          <TagsEmptyState isGlobal onAddNewTag={() => handleAddNewTag()} />
        )}
      </ContentContainer>
      {modalTagId && (
        <TagModal
          tagId={modalTagId}
          onClose={() => {
            setModalTagId(null);
          }}
        />
      )}
    </TagsLayout>
  );
};

export default TagsSettings;
