import React, { useContext, useRef } from 'react';
import { useEditor, EditorContent, Editor, Extension, Mark, Node } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import InfoEditorToolbar from '../InfoEditorToolbar';
import ResizableImage from './components/ResizableImage/ResizableImage';
import BubbleMenu from './components/BubbleMenu';
import Focus from '@tiptap/extension-focus';
import { generateEditorEntities } from './components/HighlightTag/utils';
import { TInnerHighlight } from '../../Context/HighlightsContext';
import HighlightTag from './components/HighlightTag';
import { ID, Insight } from '../../Models';

import Speaker from './components/Speaker';
import { SpeakersContext } from '../../Context/SpeakersContext';
import { generateNewSpeakers } from './components/Speaker/utils';
import Summary from './components/Summary';
import TranscriptLabel from './components/TranscriptLabel';
import { DownloadData, SummaryGenerationData } from '../InfoEditorToolbar/InfoEditorToolbar';
import NoteBlock from './components/NoteBlock/NoteBlock';
import withApiCallbacks from './WithApiCallbacks/WithApiCallbacks';
import InsightBlock from './components/InsightBlock/InsightBlock';
import { TEditorEvidence } from '../../Context/EvidenceContext';
import TagBlock from './components/TagBlock/TagBlock';
import ThemeBlock from './components/ThemeBlock/ThemeBlock';
import { THighlight } from '../../Models/Highlight';

interface TipTapEditorProps {
  content?: string;
  editable?: boolean;
  shared?: boolean;
  withHighlights?: boolean;
  withSpeakers?: boolean;
  withEvidences?: boolean;
  withToolbar?: boolean;
  withToolbarMargin?: boolean;
  containerElt?: HTMLDivElement;
  placeholder?: string;
  insight?: Insight;
  isInsightsPanel?: boolean;
  setHighlightsListRef?: React.MutableRefObject<(highlights: TInnerHighlight[]) => void>;
  setEvidencesListRef?: React.MutableRefObject<(evidences: TEditorEvidence[]) => void>;
  rawHighlightsRef?: React.MutableRefObject<THighlight[]>;
  removeHighlightFromDBRef?: React.MutableRefObject<(ids: ID[]) => void>;
  onChange?(content: any): void;
  onChangeText?(content: string): void;
  getSummaryGenerationData?(editor: Editor | null): SummaryGenerationData;
  getDownloadData?(editor: Editor | null): DownloadData;
}

type TExtension = Extension | Mark | Node;

export const PureTipTapEditor: React.FC<TipTapEditorProps> = ({
  content,
  withHighlights,
  withSpeakers,
  withEvidences,
  withToolbarMargin,
  withToolbar,
  containerElt,
  insight,
  isInsightsPanel,
  setHighlightsListRef,
  setEvidencesListRef,
  shared,
  rawHighlightsRef,
  removeHighlightFromDBRef,
  getSummaryGenerationData,
  getDownloadData,
  onChange,
  onChangeText,
  placeholder = '',
  editable = true,
}) => {
  const { setSpeakerNames } = useContext(SpeakersContext);
  const editorContainerRef = useRef<HTMLDivElement>(null);

  const setSpeakersNamesRef = useRef(setSpeakerNames);
  setSpeakersNamesRef.current = setSpeakerNames;

  const addComponentsToTranscript = (editor: Editor) => {
    let hasTranscriptLabel = false;
    editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node) => {
      if (node.type.name === 'transcriptLabel') {
        hasTranscriptLabel = true;
      }
    });

    if (!hasTranscriptLabel) {
      setTimeout(
        () => editor.chain().focus().insertContentAt(0, { type: 'transcriptLabel' }).run(),
        0
      );
    }
  };

  const extensionList: TExtension[] = [
    StarterKit,
    Placeholder.configure({
      placeholder,
    }),
    Link.configure({
      openOnClick: true,
    }),
    Image,
    ResizableImage.configure({ editable }),
    Focus,
    Table,
    TableCell,
    TableRow,
    TableHeader,
  ];

  if (withHighlights) {
    extensionList.push(
      HighlightTag.configure({
        editable,
        parentContainer: containerElt,
        editorContainerRef: editorContainerRef,
      })
    );

    extensionList.push(
      Summary.configure({ getSummaryGenerationData, parentContainer: containerElt, editable })
    );
  }

  if (withSpeakers) {
    extensionList.push(Speaker);
    extensionList.push(TranscriptLabel);
  }

  if (withEvidences) {
    extensionList.push(NoteBlock);
    extensionList.push(InsightBlock);
    extensionList.push(TagBlock.configure({ shared }));
    extensionList.push(ThemeBlock.configure({ shared }));
  }

  const editor = useEditor({
    extensions: extensionList,
    content: content,
    editable: editable,
    onUpdate: ({ editor }) => {
      setTimeout(
        () =>
          generateEditorEntities(editor as Editor, {
            setHighlightsListRef,
            setEvidencesListRef,
            rawHighlightsRef,
            removeHighlightFromDBRef,
          }),
        0
      );
      withSpeakers && generateNewSpeakers(editor as Editor, setSpeakersNamesRef);
      onChange && editable && onChange(editor.getJSON());
      onChangeText && editable && onChangeText(editor.getHTML());
    },
    onCreate({ editor }) {
      generateEditorEntities(editor as Editor, { setHighlightsListRef, setEvidencesListRef });
      withSpeakers && generateNewSpeakers(editor as Editor, setSpeakersNamesRef);
      withSpeakers && addComponentsToTranscript(editor as Editor);
      onChangeText && editable && onChangeText(editor.getHTML());
    },
  });

  return (
    <>
      {withToolbar && editable && (
        <InfoEditorToolbar
          editor={editor}
          insight={insight}
          isInsightsPanel={isInsightsPanel}
          withToolbarMargin={withToolbarMargin}
          getDownloadData={getDownloadData}
          getSummaryGenerationData={getSummaryGenerationData}
        />
      )}
      {withHighlights && editor && editable && <BubbleMenu editor={editor} />}
      <div ref={editorContainerRef} style={{ position: 'relative' }}>
        <EditorContent editor={editor} />
      </div>
    </>
  );
};

export default withApiCallbacks(PureTipTapEditor);
