import React from 'react';

export default function ComboLineArrowTypeIcon({ type }: { type: string }): JSX.Element {
  return (
    <>
      {type === 'line' && (
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8441 1.53033C13.1369 1.23744 13.1369 0.762563 12.8441 0.46967C12.5512 0.176777 12.0763 0.176777 11.7834 0.46967L12.8441 1.53033ZM11.7834 0.46967L0.469803 11.7833L1.53046 12.8439L12.8441 1.53033L11.7834 0.46967Z"
            fill="#3B2651"
          />
        </svg>
      )}
      {type === 'arrowLeft' && (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8441 1.53033C13.1369 1.23744 13.1369 0.762563 12.8441 0.46967C12.5512 0.176777 12.0763 0.176777 11.7834 0.46967L12.8441 1.53033ZM0.250133 12.3136C0.250133 12.7278 0.585919 13.0636 1.00013 13.0636L7.75013 13.0636C8.16435 13.0636 8.50013 12.7278 8.50013 12.3136C8.50013 11.8994 8.16435 11.5636 7.75013 11.5636H1.75013V5.56359C1.75013 5.14937 1.41435 4.81359 1.00013 4.81359C0.585919 4.81359 0.250133 5.14937 0.250133 5.56359L0.250133 12.3136ZM11.7834 0.46967L0.469803 11.7833L1.53046 12.8439L12.8441 1.53033L11.7834 0.46967Z"
            fill="#3B2651"
          />
        </svg>
      )}
      {type === 'arrowRight' && (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.469914 11.7831C0.177021 12.076 0.177021 12.5509 0.469914 12.8438C0.762807 13.1367 1.23768 13.1367 1.53057 12.8438L0.469914 11.7831ZM13.0638 0.999889C13.0638 0.585675 12.728 0.249888 12.3138 0.249889L5.56383 0.249889C5.14962 0.249889 4.81383 0.585675 4.81383 0.999889C4.81383 1.4141 5.14962 1.74989 5.56383 1.74989H11.5638V7.74989C11.5638 8.1641 11.8996 8.49989 12.3138 8.49989C12.728 8.49989 13.0638 8.1641 13.0638 7.74989L13.0638 0.999889ZM1.53057 12.8438L12.8442 1.53022L11.7835 0.469559L0.469914 11.7831L1.53057 12.8438Z"
            fill="#3B2651"
          />
        </svg>
      )}
      {type === 'arrowBoth' && (
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.25 14.3135C0.25 14.7277 0.585786 15.0635 1 15.0635L7.75 15.0635C8.16421 15.0635 8.5 14.7277 8.5 14.3135C8.5 13.8993 8.16421 13.5635 7.75 13.5635L1.75 13.5635L1.75 7.56348C1.75 7.14926 1.41421 6.81348 1 6.81348C0.585786 6.81348 0.25 7.14926 0.25 7.56348L0.25 14.3135ZM14.4779 1.58555C14.4779 1.17134 14.1421 0.835555 13.7279 0.835554L6.97792 0.835555C6.56371 0.835554 6.22792 1.17134 6.22792 1.58555C6.22792 1.99977 6.56371 2.33555 6.97792 2.33555H12.9779V8.33555C12.9779 8.74977 13.3137 9.08555 13.7279 9.08555C14.1421 9.08555 14.4779 8.74977 14.4779 8.33555L14.4779 1.58555ZM1.53033 14.8438L14.2583 2.11588L13.1976 1.05522L0.46967 13.7831L1.53033 14.8438Z"
            fill="#3B2651"
          />
        </svg>
      )}
    </>
  );
}
