import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { useDashboardTags, useFetchTagGroupSorting, useFetchTagGroups } from '../../Hooks/useTags';
import { TTagGroupWithTags, TagWithDetails } from '../../Models';
import EntityDropdown from '../../Components/EntityDropdown';
import { TEntityOption, TOptionSublist } from '../../Components/EntityDropdown/EntityDropdown';

interface EntityDropdownWithTagsOptons {
  value?: TagWithDetails[];
  multiselect?: boolean;
  hideSelected?: boolean;
  noOptionsPlaceholder?: string;
  ghost?: boolean;
  closeOnSelect?: boolean;
  alignMenu?: 'bottom' | 'right';
  placeholder?: string;
  onChange?(tags: TagWithDetails[]): void;
  onBlur?(): void;
  maxListHeight?: string;
}

const EntityDropdownWithTags: FC<EntityDropdownWithTagsOptons> = ({
  value,
  multiselect,
  hideSelected,
  noOptionsPlaceholder,
  ghost,
  alignMenu,
  placeholder,
  onChange,
  onBlur,
  closeOnSelect,
  maxListHeight,
}) => {
  const { dashboardId } = useParams<{ dashboardId: string; documentId: string }>();
  const [, tags, refetchTags] = useDashboardTags(dashboardId, true, true);
  const [, externalTagGroups] = useFetchTagGroups(dashboardId);
  const [, tagGroupSortings, ,] = useFetchTagGroupSorting(dashboardId);

  useEffectOnce(() => {
    refetchTags();
  });

  const getOptionByTag = (tag: TagWithDetails): TEntityOption => ({
    value: tag.id.toString(),
    label: tag.name,
    properties: {
      itemColor: tag.color,
      count:
        tag.tagsNotesConnection.totalCount +
        tag.tagsTranscriptionsConnection.totalCount +
        tag.tagsDocumentsConnection.totalCount,
    },
  });

  const groupsWithTags = useMemo<TOptionSublist[]>((): TOptionSublist[] => {
    const filteredTagGroups =
      externalTagGroups
        ?.map((group) => {
          const tagsInGroup = (tags as TagWithDetails[])
            .filter((tag) => tag.groupId === group.id && tag.tagsNotesConnection.totalCount)
            .sort((firstTag, secondTag) =>
              firstTag.name.toLowerCase() < secondTag.name.toLowerCase() ? -1 : 1
            );

          return {
            ...group,
            tags: tagsInGroup as TagWithDetails[],
          };
        })
        .filter((group) => group.tags.length) || [];

    const projectSorting = tagGroupSortings.find((item) => !item.isGlobal);
    const globalSorting = tagGroupSortings.find((item) => item.isGlobal);
    const projectGroups = projectSorting
      ? projectSorting.sorting
          .map((id) => filteredTagGroups.find((group) => group.id === id) as TTagGroupWithTags)
          .filter((group) => !!group)
      : filteredTagGroups.filter((group) => !group.isGlobal);

    const globalGroups = globalSorting
      ? globalSorting.sorting
          .map((id) => filteredTagGroups.find((group) => group.id === id) as TTagGroupWithTags)
          .filter((group) => !!group)
      : filteredTagGroups.filter((group) => group.isGlobal);

    return projectGroups.concat(globalGroups).map((group) => ({
      id: group.id,
      name: group.name,
      options: group.tags.map((tag) => getOptionByTag(tag)),
    }));
  }, [externalTagGroups, tags, tagGroupSortings]);

  const tagsWithoutGroup = useMemo(() => {
    return (tags as TagWithDetails[])
      .filter((tag) => !tag.groupId && tag.tagsNotesConnection.totalCount)
      .map((tag) => getOptionByTag(tag));
  }, [tags]);

  return (
    <EntityDropdown
      sublists={groupsWithTags}
      options={tagsWithoutGroup}
      value={value?.map((tag) => getOptionByTag(tag))}
      multiselect={multiselect}
      hideSelected={hideSelected}
      closeOnSelect={closeOnSelect}
      maxListHeight={maxListHeight}
      noOptionsPlaceholder={noOptionsPlaceholder || 'No tags'}
      onChange={(options) =>
        onChange &&
        onChange(
          options.map(
            (option) => tags.find((tag) => tag.id.toString() === option.value) as TagWithDetails
          )
        )
      }
      onBlur={onBlur}
      ghost={ghost}
      alignMenu={alignMenu}
      placeholder={placeholder}
    />
  );
};

export default EntityDropdownWithTags;
