import React, { PropsWithChildren } from 'react';
import dataEmptyImage from '../assets/empty-states/data.png';
import { HeroImage } from './EmptyState2';

export default function EmptyState({ children }: PropsWithChildren<any>) {
  return (
    <div className="flex flex-col items-center mt-10">
      <HeroImage src={dataEmptyImage} wide></HeroImage>
      <div className="text-gray-500 font-md">{children}</div>
    </div>
  );
}
