import React, { FC, useContext, useEffect, useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import {
  BackgroundImage,
  Container,
  Content,
  CreatedAt,
  Divider,
  LogoImage,
  Name,
  NameContainer,
  RoundDivider,
  Title,
  TitleContainer,
} from './styles';
import { Icon24 } from '../../../../Icons/Icon';
import { EvidenceContext } from '../../../../Context/EvidenceContext';
import Loader from '../../../Loader';

const ThemeBlock: FC = (props: any) => {
  const { isInitialized, findEvidenceById } = useContext(EvidenceContext);
  const [theme, setTheme] = useState<any | null>(null);
  const isShared = props.extension.options.shared;

  useEffect(() => {
    if (isInitialized) {
      setTheme(findEvidenceById(props.node.attrs.id)?.theme || null);
    }
  }, [isInitialized]);

  if (isShared) return null;

  return (
    <NodeViewWrapper contentEditable={false}>
      {!theme ? (
        <Loader />
      ) : (
        <Container
          onClick={() => {
            const url = `${window.location.origin}/projects/${theme.dashboardId}/notes/themes/${theme.id}`;
            window?.open(url, '_blank')?.focus();
          }}
        >
          <div className="flex justify-between items-center p-3">
            <div className="font-medium text-md text-primary-purple flex items-center">
              <div className="mr-2">
                <Icon24.Theme />
              </div>
              {theme?.name}
            </div>
            <div className="font-medium text-sm text-primary-purple-light-40">
              {theme?.notesConnection?.totalCount} notes
            </div>
          </div>
        </Container>
      )}
    </NodeViewWrapper>
  );
};

export default ThemeBlock;
