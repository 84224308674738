import React, { useState } from 'react';
import styled from 'styled-components';
import { EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/outline';
import EmojiNeutralIcon from '../Icons/EmojiNeutralIcon';
import { isNil } from 'lodash-es';
import CreatableSelect from 'react-select/creatable';
import { OptionTypeBase, components } from 'react-select';

type Props = {
  sentimentScore?: number | null;
  onChange: (sentimentScore: number | null) => void;
  readOnly?: boolean;
};

const Sentiment = ({ value }: { value: number }): JSX.Element => {
  if (value > 0) {
    return (
      <div className={'flex items-center cursor-pointer'}>
        <EmojiHappyIcon className={'w-5 h-5 mr-1 text-green-500'} /> Positive
      </div>
    );
  }

  if (value < 0) {
    return (
      <div className={'flex items-center cursor-pointer'}>
        <EmojiSadIcon className={'w-5 h-5 mr-1 text-red-500'} /> Negative
      </div>
    );
  }

  return (
    <div className={'flex items-center cursor-pointer'}>
      <EmojiNeutralIcon className={'mr-1'} /> Neutral
    </div>
  );
};

const SentimentPicker = ({ sentimentScore, onChange, readOnly = false }: Props): JSX.Element => {
  const [showSelect, setShowSelect] = useState<boolean>(false);

  const options = [
    { value: 1, label: 'Positive', color: 'LightGreen' },
    { value: 0, label: 'Neutral', color: 'LightGray' },
    { value: -1, label: 'Negative', color: 'Pink' },
  ];

  const calc =
    sentimentScore == null
      ? sentimentScore
      : Math.sign(sentimentScore) * Math.ceil(Math.abs(sentimentScore));
  const computedValue = options.find((o) => o.value == calc);

  const handleChange = (newValue: OptionTypeBase) => {
    onChange(newValue?.value);
  };

  if (!showSelect) {
    return (
      <Container
        onClick={() => {
          if (readOnly) return;
          setShowSelect(true);
        }}
      >
        {isNil(sentimentScore) && <Placeholder>add sentiment</Placeholder>}
        {!isNil(sentimentScore) && <Sentiment value={sentimentScore} />}
      </Container>
    );
  }

  return (
    <SelectContainer>
      <CreatableSelect
        placeholder="add sentiment"
        defaultMenuIsOpen
        autoFocus
        onBlur={() => {
          setShowSelect(false);
        }}
        onChange={(value) => handleChange(value as OptionTypeBase)}
        isMulti={false}
        value={computedValue ?? null}
        options={options}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,

          // eslint-disable-next-line react/display-name
          MenuList: ({ children, ...rest }) => {
            return (
              <>
                <components.MenuList {...rest}>{children}</components.MenuList>
              </>
            );
          },
        }}
        styles={{
          container(base) {
            return { ...base, ...containerStyle };
          },
          control(base) {
            return { ...base, ...controlStyle };
          },
          menu(base) {
            return {
              ...base,
              ...menuStyle,
            };
          },
          menuList(base) {
            return {
              ...base,
              ...menuListStyle,
            };
          },
          option(base) {
            return {
              ...base,
              ...optionStyle,
            };
          },
          singleValue(base) {
            return {
              ...base,
              ...singleValueStyle,
            };
          },
          multiValue(base) {
            return {
              ...base,
              ...multiValueStyle,
            };
          },
          multiValueLabel(base) {
            return {
              ...base,
              ...multiValueLabelStyle,
            };
          },
          multiValueRemove(base) {
            return {
              ...base,
              ...multiValueRemoveStyle,
            };
          },
          menuPortal(base) {
            return { ...base, zIndex: 9999 };
          },
          placeholder(base) {
            return { ...base, ...placeholderStyle };
          },
          valueContainer(base) {
            return { ...base, ...valueContainerStyle };
          },
          clearIndicator(base) {
            return { ...base, ...clearIndicatorStyle };
          },
          input(base) {
            return { ...base, ...inputStyle };
          },
        }}
      />
    </SelectContainer>
  );
};

const menuStyle = {
  borderRadius: 0,
  border: 'none',
  marginTop: 0,
  zIndex: 9999,
};

const menuListStyle = {
  padding: 0,
  zIndex: 9999,
};

const placeholderStyle = {
  color: 'rgba(56, 33, 82, 0.41)',
  fontWeight: 500,
  fontSize: '14px',
  margin: 0,
};

const inputStyle = {
  color: '#382152',
  fontWeight: 500,
  fontSize: '14px',
  margin: 0,
  padding: 0,
};

const clearIndicatorStyle = {
  color: 'rgba(56, 33, 82, 0.2)',
  '&:hover': {
    color: 'rgba(56, 33, 82, 0.4)',
  },
};

const valueContainerStyle = {
  padding: 0,
};

const controlStyle = {
  boxShadow: '0px 0px 0px #E5E5E5',
  border: 'none',
  backgroundColor: 'transparent',
  outlineColor: 'transparent',
  borderTopWidth: 0,
  borderRadius: 0,
  color: '#382152',
  fontWeight: 500,
  fontSize: '14px',
};

const containerStyle = {
  borderWidth: '0',
  boxShadow: 'none',
  backgroundColor: 'white',
  outlineColor: 'transparent',
};

const optionStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  padding: '6px 6px',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3EFF6',
  },
  zIndex: 9999,
};

const singleValueStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  margin: 0,
  padding: 0,
};

const multiValueStyle = {
  backgroundColor: 'rgba(56, 33, 82, 0.1)',
};

const multiValueLabelStyle = {
  fontWeight: 500,
  fontSize: '14px',
  color: '#382152',
  margin: 0,
  padding: 0,
};

const multiValueRemoveStyle = {
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const Container = styled.div`
  min-height: 38px;
  display: flex;
  align-items: center;
`;

const Placeholder = styled.span`
  color: ${(props) => props.theme.colors.text.placeholder2};
  cursor: pointer;
`;

const SelectContainer = styled.div`
  min-width: 240px;
  z-index: 9999;
`;

export default React.memo(SentimentPicker);
