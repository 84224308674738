import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AILoadingState from '../../../AILoadingState';
import { ChevronUpIcon, ChevronDownIcon, DotsHorizontalIcon } from '@heroicons/react/outline';
import { useGenerateSummary } from '../../../../Hooks/useSummary';
import { Menu } from '@headlessui/react';
import { Popover, PopoverTrigger } from '@chakra-ui/react';

import Showdown from 'showdown';

import {
  ErrorContainer,
  ErrorMessage,
  Buttons,
  ButtonsWrapper,
  Button,
  ButtonName,
  ErrorIcon,
  TitleContainer,
  LoadingStateContainer,
  NodeWrapper,
  TitleLeftSide,
  TitleRightSide,
  DeleteButton,
  InfoText,
  ContentWrapper,
  MenuItemText,
  MenuRow,
} from './styles';
import { Icon24 } from '../../../../Icons/Icon';
import {
  NodeViewContent,
  NodeViewWrapper,
  generateJSON,
  NodeViewProps,
  Editor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { SummaryFeedbackContext } from '../../../../Context/SummaryFeedbackContext';
import useAnalytics from '../../../../Hooks/useAnalytics';
import { DropDownMenu } from '../../../DropDownMenu';
import LanguagePicker from './LanguagePicker';
import {
  EDITOR_TOOLBAR_HEIGHT,
  SECONDARY_NAV_HEIGHT,
  TOP_NAV_HEIGHT,
} from '../../../../Consts/layout';
import { HighlightsContext } from '../../../../Context/HighlightsContext';
import HighlightIcon from '../../../../Icons/HighlightIcon';
import { Node } from 'prosemirror-model';
import sortTags from '../../../../Hocs/WithTagManagement/tagsSuggestions';
import { TagWithDetails } from '../../../../Models';
import { useDashboardTags } from '../../../../Hooks/useTags';
import useTranscripts from '../../../../Hooks/useTranscripts';
import { useFetchHighlights } from '../../../../Hooks/useHighlights';
import { useFetchDocumentsWithText } from '../../../../Hooks/useDocuments';
import { useParams } from 'react-router-dom';

type dataAttribute = {
  key: string;
  value: string | boolean;
};

export const SummaryComponent = (props: NodeViewProps) => {
  const { dashboardId } = useParams<{ dashboardId: string; documentId: string }>();
  const jobId = props.node.attrs.jobId;
  const status = props.node.attrs.status;
  const summaryContent = props.node.textContent;
  const isEmpty = !summaryContent?.length;
  const parentContainer: HTMLDivElement = props.extension.options.parentContainer;

  const { analytics } = useAnalytics();
  const { addSummaryForFeedback, trackKeepSummary } = useContext(SummaryFeedbackContext);
  const { generateDataSummary, fetchSummary, generateSummaryTranslation } = useGenerateSummary();
  const [isGenerating, setIsGenerating] = useState(status === 'generating');
  const [collapsed, setCollapsed] = useState(props.node.attrs.collapsed || false);

  const showError = status === 'error';
  const showEditorBlock = status === 'activated' || status === 'finalized';
  const getSummaryGenerationData = props.extension.options.getSummaryGenerationData;
  const isEditable = props.extension.options.editable;

  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (status === 'readyToStartGeneration') {
      retryGeneration({ firstTime: true });
    }
  }, [status]);

  useEffect(() => {
    if (
      isEmpty &&
      status !== 'inactive' &&
      status !== 'generating' &&
      status !== 'readyToStartGeneration'
    ) {
      handleDeleteSummary();
    }
    if (!isEmpty && status === 'inactive') {
      changeBlockContent({
        data: [
          { key: 'jobId', value: '' },
          { key: 'status', value: 'activated' },
        ],
      });
    }
  }, [isEmpty]);

  useEffect(() => {
    if (jobId) {
      const intervalId = setInterval(async () => {
        const data = await fetchSummary(jobId);
        if (data.status === 'ready') {
          setIsGenerating(false);
          clearInterval(intervalId);
          changeBlockContent({
            data: [
              { key: 'jobId', value: '' },
              { key: 'status', value: 'activated' },
            ],
            text: data.content || '',
          });
        } else if (data.status === 'error') {
          setIsGenerating(false);
          clearInterval(intervalId);
          changeBlockContent({
            data: [
              { key: 'jobId', value: '' },
              { key: 'status', value: 'error' },
            ],
          });
        }
      }, 2000);
    }
  }, [jobId]);

  const keepSummary = () => {
    analytics.sendEvent('summary_feedback_keep');
    trackKeepSummary(props.node.attrs.id);
    changeBlockContent({
      data: [{ key: 'status', value: 'finalized' }],
    });
  };

  const retryGeneration = async (options?: { firstTime: boolean }) => {
    if (!options?.firstTime) {
      analytics.sendEvent('summary_feedback_retry');
      addSummaryForFeedback({
        id: props.node.attrs.id,
        status: 'retry',
      });
    }

    const { content, documentId, transcriptId } = getSummaryGenerationData(props.editor);
    setIsGenerating(true);

    const { success, summaryId, status } = await generateDataSummary({
      quotes: content,
      documentId,
      transcriptId,
      templateId: props.node.attrs.templateId,
    });
    changeBlockContent({
      data: [
        { key: 'id', value: summaryId },
        { key: 'jobId', value: summaryId },
        { key: 'status', value: 'generating' },
      ],
      text: '',
    });
  };

  const handleDeleteSummary = (status?: 'keep' | 'delete' | 'discard' | 'retry') => {
    analytics.sendEvent('summary_feedback_discard');
    addSummaryForFeedback({
      id: props.node.attrs.id,
      status: status ? status : 'delete',
    });
    setTimeout(() => {
      props.editor
        .chain()
        .focus()
        .insertContentAt({ from: props.getPos(), to: props.getPos() + props.node.nodeSize }, ' ')
        .deleteNode('paragraph')
        .run();
    }, 0);
  };

  const handleTranslateSummary = async (language: string) => {
    analytics.sendEvent('summary_translate');

    const topBarsOffset = TOP_NAV_HEIGHT + SECONDARY_NAV_HEIGHT + EDITOR_TOOLBAR_HEIGHT + 20;
    const scrollOffset =
      (wrapperRef?.current?.getBoundingClientRect().top || topBarsOffset) - topBarsOffset;
    parentContainer.scrollBy({
      top: scrollOffset,
      behavior: 'smooth',
    });

    const { success, summaryId, status } = await generateSummaryTranslation({
      content: contentRef.current?.innerHTML || '',
      language,
      sourceSummaryId: props.node.attrs.id || '',
    });

    const newSummaryNode = {
      type: 'summary',
      attrs: {
        id: summaryId,
        jobId: summaryId,
        status: 'generating',
        templateId: props.node.attrs.templateId,
        templateTitle: props.node.attrs.templateTitle,
        language,
      },
      content: [],
    };

    const newNodePos = props.getPos() + props.node.nodeSize;

    setTimeout(() => {
      setCollapsed(!collapsed);
      props.updateAttributes({ collapsed: !collapsed });
      props.editor.chain().focus().insertContentAt(newNodePos, newSummaryNode).run();
    }, 800);
  };

  const changeBlockContent = useCallback(
    ({ data, text }: { data?: dataAttribute[]; text?: string }) => {
      let attributes = {};
      if (data && data?.length) {
        attributes = data.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
        setTimeout(() => props.updateAttributes(attributes));
      }

      if (text !== undefined) {
        const converter = new Showdown.Converter();
        const convertedText = converter.makeHtml(text.trim());
        const jsonContent = generateJSON(convertedText || '', [StarterKit]).content;

        const newSummaryNode = {
          type: 'summary',
          attrs: { ...props.node.attrs, ...attributes },
          content: jsonContent,
        };

        setTimeout(
          () =>
            props.editor
              .chain()
              .focus()
              .insertContentAt(
                { from: props.getPos(), to: props.getPos() + props.node.nodeSize },
                newSummaryNode
              )
              .run(),
          0
        );
      }
    },
    [props, summaryContent]
  );

  const getTitle = () => {
    const templateTitle = props.node.attrs.templateTitle;
    if (!templateTitle.length) return 'Summary';

    const lang = props.node.attrs.language;

    return `${templateTitle.trim()} Summary${lang ? ` (${lang})` : ''}`
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <NodeViewWrapper as={NodeWrapper}>
      <div contentEditable={false} ref={wrapperRef}>
        <TitleContainer>
          <TitleLeftSide>
            <Icon24.AIStar />
            <ButtonName>{getTitle()}</ButtonName>
          </TitleLeftSide>

          <TitleRightSide>
            {isEditable && (
              <DropDownMenu
                icon={<DotsHorizontalIcon className={'mt-1 mx-2 w-4 h-4'} />}
                dropDownWidth="w-40"
              >
                <Menu.Item>
                  <MenuRow
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDeleteSummary();
                    }}
                  >
                    <Icon24.Trash />
                    <MenuItemText>Delete</MenuItemText>
                  </MenuRow>
                </Menu.Item>
              </DropDownMenu>
            )}
            <DeleteButton
              onClick={() => {
                setCollapsed(!collapsed);
                setTimeout(() => props.updateAttributes({ collapsed: !collapsed }));
              }}
            >
              {collapsed ? (
                <ChevronDownIcon height={20} width={20} />
              ) : (
                <ChevronUpIcon height={20} width={20} />
              )}
            </DeleteButton>
          </TitleRightSide>
        </TitleContainer>

        {isGenerating && (
          <LoadingStateContainer>
            <AILoadingState type="summary" />
          </LoadingStateContainer>
        )}

        {!isGenerating && showError && (
          <ErrorContainer>
            <ErrorMessage>
              <ErrorIcon>
                <Icon24.ExclamationCircle />
              </ErrorIcon>
              We were unable to generate a summary. No credits were used.
            </ErrorMessage>
            {props.node.attrs.templateId && (
              <Buttons>
                <Button onClick={() => retryGeneration()}>
                  <Icon24.Refresh />
                  Try again
                </Button>
              </Buttons>
            )}
          </ErrorContainer>
        )}
      </div>

      {!isGenerating && showEditorBlock && (
        <>
          <ContentWrapper collapsed={collapsed}>
            <div ref={contentRef}>
              <NodeViewContent />
            </div>

            {isEditable && (
              <ButtonsWrapper contentEditable={false}>
                <Buttons>
                  {/* <Button onClick={keepSummary}>
                  <Icon24.Chevron />
                  Keep
                </Button> */}
                  {props.node.attrs.templateId && (
                    <Button onClick={() => retryGeneration()}>
                      <Icon24.Refresh />
                      Re-generate
                    </Button>
                  )}
                  {/* <Button onClick={() => handleDeleteSummary('discard')}>
                  <Icon24.Trash />
                  Discard
                </Button> */}

                  <Popover>
                    <PopoverTrigger>
                      <Button>
                        <Icon24.Translate />
                        Translate
                        <ChevronDownIcon height={20} width={20} />
                      </Button>
                    </PopoverTrigger>
                    <LanguagePicker onConfirm={handleTranslateSummary} />
                  </Popover>
                  <HighlightAllButton
                    node={props.node}
                    summaryPos={props.getPos()}
                    editor={props.editor as Editor}
                    dashboardId={dashboardId}
                  />
                </Buttons>
                {props.node.attrs.templateId && (
                  <InfoText>Retrying or translating will use 1 AI credit</InfoText>
                )}
              </ButtonsWrapper>
            )}
          </ContentWrapper>
        </>
      )}
    </NodeViewWrapper>
  );
};

interface HighlightAllButtonProps {
  node: Node;
  summaryPos: number;
  editor: Editor;
  dashboardId: string;
}

function HighlightAllButton(props: HighlightAllButtonProps) {
  const [isAddingHighlights, setIsAddingHighlights] = useState(false);
  const { addHighlight } = useContext(HighlightsContext);
  const [, tags] = useDashboardTags(props.dashboardId, true, true);
  const { fetchTranscriptsWithText } = useTranscripts();
  const [, transcripts] = fetchTranscriptsWithText(props.dashboardId);
  const [, rawHighlights, refetchHighlights] = useFetchHighlights({
    dashboardId: props.dashboardId,
  });
  const [, documents] = useFetchDocumentsWithText(props.dashboardId);

  const autoHighlightSummary = async () => {
    let posOffset = 0;
    const descendants: { node: Node; pos: number; parent: Node | null }[] = [];
    props.node.descendants((node, pos, parent) => {
      descendants.push({ node, pos, parent });
    });

    if (!descendants.length) return;

    setIsAddingHighlights(true);
    let chain = props.editor.chain();
    const highlightsToAdd: {
      generatedId: string;
      text: string;
      sortedTags: TagWithDetails[];
    }[] = [];

    for (const { node, pos, parent } of descendants) {
      if (
        node.type.isText &&
        node.marks[0]?.type.name !== 'bold' &&
        parent?.type.name !== 'highlightTag' &&
        parent?.type.name !== 'heading'
      ) {
        const from = props.summaryPos + pos + posOffset + 1;
        posOffset += 2;

        const generatedId = Math.floor(Math.random() * Date.now()).toString(16);
        const text = node.textContent || '';
        const sortedTags = sortTags(
          tags as TagWithDetails[],
          rawHighlights,
          text,
          transcripts
            .map((item: any) => JSON.parse(item.text))
            .concat(documents.map((item) => JSON.parse(item.content)))
        ).slice(0, 1);

        highlightsToAdd.push({
          generatedId,
          text,
          sortedTags,
        });

        const newHighlightTag = {
          type: 'highlightTag',
          attrs: {
            id: generatedId,
            newTag: false,
            serialNumber: 0,
          },
          content: [
            {
              type: 'text',
              text: text,
            },
          ],
        };

        chain = chain.setNodeSelection(from).command(({ tr }) => {
          const newNode = Node.fromJSON(props.editor.schema, newHighlightTag);
          tr.replaceSelectionWith(newNode);

          return true;
        });
      }
    }

    chain.run();
    let i = 0;
    for (const highlight of highlightsToAdd) {
      const shouldSkipRefetch = i !== highlightsToAdd.length - 1;
      i++;
      await addHighlight(
        {
          entityId: highlight.generatedId,
          texts: [highlight.text],
          tags: highlight.sortedTags,
          addedToAnalysis: false,
          tagsHighlights: {
            deleteOthers: true,
            create: highlight.sortedTags?.map((tag) => ({ tagId: tag.id })) || [],
          },
        },
        shouldSkipRefetch
      );
    }
    setIsAddingHighlights(false);
    refetchHighlights();
  };
  return (
    <Button
      onClick={() => {
        if (!isAddingHighlights) {
          autoHighlightSummary();
        }
      }}
    >
      <div style={{ paddingRight: 5 }}>
        <Icon24.HighlightAll />
      </div>
      Highlight & Tag
    </Button>
  );
}

export default SummaryComponent;
