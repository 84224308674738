import React, { Fragment } from 'react';
import { Popover, Listbox, Transition, Menu } from '@headlessui/react';
import { LINE_STROKE_SIZES, TEXT_COLORS } from '../../Consts';
import { ShapesStyles, sidesToShapeConversion } from './ShapeComponent';
import CircleShapeIcon from '../../Icons/CircleShapeIcon';
import TriangleShapeIcon from '../../Icons/TriangleShapeIcon';
import SquareShapeIcon from '../../Icons/SquareShapeIcon';
import StrokeWidthIcon from '../../Icons/StrokeWidthIcon';
import DropdownMenuIcon from '../../Icons/DropdownMenuIcon';
import CheckmarkIcon from '../../Icons/CheckmarkIcon';
import { DropDownMenu } from '../DropDownMenu';
import DotsIcon from '../../Icons/DotsIcon';
import { shapeHash } from '../../Consts/canvasActions';
import RhombusIcon from '../../Icons/RhombusIcon';
import { backZIndex, frontZIndex } from '../../Consts/canvasZIndex';
import { TrashIcon } from '@heroicons/react/outline';

export default function ShapeMenu({
  onUpdate,
  onDelete,
  styles,
}: {
  onUpdate: (updates: {
    text?: string;
    styles?: ShapesStyles;
    x?: number;
    y?: number;
  }) => Promise<void>;
  onDelete: () => void;
  styles: ShapesStyles;
}): JSX.Element {
  return (
    <div className={'-mt-12 z-100'}>
      <span className="relative z-0 inline-flex shadow-md rounded-md">
        <Popover className="relative">
          <Popover.Button
            style={{ height: '100%' }}
            className="-ml-px relative inline-flex items-center px-2 py-1.5 border-gray-300 border border-r-0 rounded-l-md bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
          >
            {styles.shapeType === shapeHash.circle && <CircleShapeIcon />}
            {styles.shapeType === shapeHash.triangle && <TriangleShapeIcon />}
            {styles.shapeType === shapeHash.square && <SquareShapeIcon />}
            {styles.shapeType === shapeHash.rhombus && <RhombusIcon />}
            {!styles.shapeType && <SquareShapeIcon />}
          </Popover.Button>
          <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md border-gray-300 border rounded">
            <div className="grid grid-cols-4" style={{ width: 'max-content', gap: '0.75rem' }}>
              <a
                href="#"
                onClick={() => {
                  onUpdate({ styles: { ...styles, sides: 4, shapeType: shapeHash.square } });
                }}
              >
                <SquareShapeIcon />
              </a>
              <a
                href="#"
                onClick={() => {
                  onUpdate({ styles: { ...styles, sides: 3, shapeType: shapeHash.triangle } });
                }}
              >
                <TriangleShapeIcon />
              </a>
              <a
                href="#"
                onClick={() => {
                  onUpdate({ styles: { ...styles, sides: 0, shapeType: shapeHash.circle } });
                }}
              >
                <CircleShapeIcon />
              </a>
              <a
                href="#"
                onClick={() => {
                  onUpdate({ styles: { ...styles, sides: 4, shapeType: shapeHash.rhombus } });
                }}
              >
                <RhombusIcon />
              </a>
            </div>
          </Popover.Panel>
        </Popover>

        <Popover className="relative">
          <Popover.Button
            style={{ height: '100%' }}
            className="-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
          >
            <div
              className={'h-4 w-4 rounded-lg'}
              style={{
                backgroundColor: styles.fill,
              }}
            />
          </Popover.Button>
          <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white shadow-md border-gray-300 border rounded">
            <div className="grid gap-1 grid-cols-3 w-20">
              {TEXT_COLORS.map((fillColor, index) => (
                <a
                  href="#"
                  onClick={() => {
                    onUpdate({ styles: { ...styles, fill: fillColor } });
                  }}
                  key={index}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="11" fill={fillColor} />
                    {styles.fill == fillColor && (
                      <path
                        d="M7 10.143L10.4286 13.5714L15 8.42871"
                        stroke="#382152"
                        strokeWidth="1.5"
                      />
                    )}
                  </svg>
                </a>
              ))}
            </div>
          </Popover.Panel>
        </Popover>
        <div className="relative">
          <Listbox
            value={styles.strokeWidth}
            onChange={(value) => {
              onUpdate({ styles: { ...styles, strokeWidth: value } });
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  className="-ml-px relative inline-flex items-center px-2 border-gray-300 border border-r-0 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                  style={{ paddingTop: '0.4rem', paddingBottom: '0.4rem' }}
                >
                  <StrokeWidthIcon />

                  <span
                    style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    {styles.strokeWidth}
                    <DropdownMenuIcon />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Listbox.Options
                    style={{ width: '56px', top: '34px' }}
                    className="z-100 origin-top-right absolute right-1 mt-1 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none border-gray-300 border rounded"
                  >
                    {LINE_STROKE_SIZES.map((size, index) => (
                      <Listbox.Option key={index} value={size}>
                        {({ selected }) => (
                          <div
                            className="hover:cursor-pointer hover:bg-gray-50"
                            style={{
                              paddingLeft: '24px',
                              borderBottom: '1px solid #E5E5E5',
                              position: 'relative',
                            }}
                          >
                            {selected && (
                              <span
                                style={{
                                  position: 'absolute',
                                  left: '9px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                }}
                              >
                                <CheckmarkIcon />
                              </span>
                            )}
                            {size}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
        </div>
        <Popover className="relative">
          <Popover.Button
            style={{ height: '100%' }}
            className="-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
          >
            {styles.stroke == 'transparent' ? (
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8.50024" cy="8" r="7" stroke="#D7D3DC" strokeWidth="2" />
                <line
                  x1="2.79289"
                  y1="13.2929"
                  x2="13.7929"
                  y2="2.29289"
                  stroke="#D7D3DC"
                  strokeWidth="2"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8" cy="8" r="7" stroke={styles.stroke} strokeWidth="2" />
              </svg>
            )}
          </Popover.Button>
          <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md border-gray-300 border rounded">
            <div className="grid gap-1 grid-cols-3 w-20">
              <a
                href="#"
                onClick={() => {
                  onUpdate({ styles: { ...styles, stroke: 'transparent' } });
                }}
              >
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.50024" cy="8" r="7" stroke="#D7D3DC" strokeWidth="2" />
                  <line
                    x1="2.79289"
                    y1="13.2929"
                    x2="13.7929"
                    y2="2.29289"
                    stroke="#D7D3DC"
                    strokeWidth="2"
                  />
                </svg>
              </a>
              {TEXT_COLORS.map((fillColor, index) => (
                <a
                  href="#"
                  onClick={() => {
                    if (styles.strokeWidth === 0 && fillColor !== 'transparent') {
                      onUpdate({ styles: { ...styles, stroke: fillColor, strokeWidth: 6 } });
                    } else {
                      onUpdate({ styles: { ...styles, stroke: fillColor } });
                    }
                  }}
                  key={index}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="7" stroke={fillColor} strokeWidth="2" />
                    {styles.stroke == fillColor && (
                      <svg x="-3" y="-3">
                        <path
                          d="M7 10.143L10.4286 13.5714L15 8.42871"
                          stroke="#382152"
                          strokeWidth="1.5"
                        />
                      </svg>
                    )}
                  </svg>
                </a>
              ))}
            </div>
          </Popover.Panel>
        </Popover>
        <button
          onClick={onDelete}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-gray-300 border bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <TrashIcon className={'h-5 w-5'} />
        </button>
        <DropDownMenu
          button={
            <Menu.Button
              style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
              className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none h-full rounded-r-md"
            >
              <DotsIcon />
            </Menu.Button>
          }
        >
          {' '}
          <Menu.Item>
            <label
              onClick={() => {
                onUpdate({ styles: { ...styles, zIndex: frontZIndex } });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Bring to front
            </label>
          </Menu.Item>
          <Menu.Item>
            <label
              onClick={() => {
                onUpdate({ styles: { ...styles, zIndex: backZIndex } });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Send to back
            </label>
          </Menu.Item>
          <Menu.Item>
            <label
              onClick={() => {
                onUpdate({ styles: { ...styles, rotation: styles.rotation + 90 } });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Rotate 90&deg; right
            </label>
          </Menu.Item>
          <Menu.Item>
            <label
              onClick={() => {
                onUpdate({ styles: { ...styles, rotation: styles.rotation - 90 } });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Rotate 90&deg; left
            </label>
          </Menu.Item>
        </DropDownMenu>
      </span>
    </div>
  );
}
