import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CalendarIcon, UserIcon } from '@heroicons/react/outline';

interface InsightEditorContainerProps {
  readonly maxWidth?: number;
}

interface DetailsProps {
  readonly maxWidth?: number;
}

export const InsightEditorContainer = styled.div<InsightEditorContainerProps>`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  ${(props) => {
    if (props.maxWidth) {
      return `overflow: scroll;`;
    }
  }}
`;

export const TitleInput = styled(TextareaAutosize)`
  outline: none;
  transition: 0.3s ease 0s;
`;

export const Details = styled.div<DetailsProps>`
  padding: 0 12px 16px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + 'px' : '800px')};
  margin: auto;
`;

export const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
`;

export const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

export const UserIconI = styled(UserIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;
export const CalendarIconI = styled(CalendarIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const UserName = styled.span`
  margin-left: 8px;
  flex: 1 1 0%;
`;
