import React from 'react';

const DotsIcon = (): JSX.Element => (
  <svg width="19" height="4" viewBox="0 0 19 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 2H2.51H2.5ZM9.5 2H9.51H9.5ZM16.5 2H16.51H16.5ZM3.5 2C3.5 2.5523 3.05228 3 2.5 3C1.94772 3 1.5 2.5523 1.5 2C1.5 1.4477 1.94772 1 2.5 1C3.05228 1 3.5 1.4477 3.5 2ZM10.5 2C10.5 2.5523 10.0523 3 9.5 3C8.9477 3 8.5 2.5523 8.5 2C8.5 1.4477 8.9477 1 9.5 1C10.0523 1 10.5 1.4477 10.5 2ZM17.5 2C17.5 2.5523 17.0523 3 16.5 3C15.9477 3 15.5 2.5523 15.5 2C15.5 1.4477 15.9477 1 16.5 1C17.0523 1 17.5 1.4477 17.5 2Z"
      stroke="#3B2651"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DotsIcon;
