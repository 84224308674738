import React from 'react';
import transcriptionWidgetBg from '../assets/transcriptionWidgetBg.png';
import Button from '../Components/Button';
import { useOrganization } from '../Hooks/useOrganization';
import { useHistory } from 'react-router';

const TranscriptionHoursWidget = ({ className }: React.PropsWithRef<any>): JSX.Element => {
  const [loading, org] = useOrganization();
  const history = useHistory();

  if (loading || org?.plan !== 'free') return <></>;

  const durationLimit = org?.durationLimitTotal ? Math.round(org.durationLimitTotal / 3600) : 1;
  const durationTotal = org?.durationTotal ? Math.round(org.durationTotal / 3600) : 0;

  return (
    <div
      className={`flex flex-col rounded-md ${className}`}
      style={{
        border: '1px solid rgba(215, 211, 220, 1)',
        boxShadow: '0px 1px 0px #E5E5E5, 2px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div className="flex flex-row">
        <img src={transcriptionWidgetBg} alt="cover image" className="rounded-t-md" />
      </div>
      <div className="flex flex-col py-5 px-2">
        <div className="text-lg font-secondary font-semibold pb-2">Free account</div>
        <div className="font-medium">
          {durationTotal}/{durationLimit} transcript hours used
        </div>
        <div className="w-full bg-primary-purple-gray h-1 mb-5 mt-1 rounded-sm">
          <div
            className="bg-primary-purple h-1 rounded-sm"
            style={{
              width: `${Math.min(100, (durationTotal / durationLimit) * 100)}%`,
            }}
          ></div>
        </div>
        <div>
          <Button onClick={() => history.push('/settings/plans')} type={'secondary'}>
            Upgrade
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TranscriptionHoursWidget;
