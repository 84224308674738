import styled from 'styled-components';

export const ReactionsAndComments = styled.div`
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const ReactionsAndCommentsItem = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-bottom: 1px solid #dddddd;
`;
