import React from 'react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FolderAddIcon } from '@heroicons/react/outline';
import Folder, { TFolder } from './Folder';
import ListItem, { TListItem } from './ListItem';
import ListItemDropTarget from './ListItemDropTarget';

type ListWithFoldersProps = {
  items: TListItem[];
  folders: TFolder[] | null;
  setItemFolder?: (item: TListItem, folderId: string | null) => void;
  onCreateFolder?: (() => void) | null;
  onCreateItem?: ((folderId: string) => void | Promise<void>) | null;
  onItemClick?: (item: TListItem) => void;
  onRenameFolder?: ((folderId: string, name: string) => void | Promise<void>) | null;
  onDeleteFolder?: ((folderId: string) => void | Promise<void>) | null;
  defaultItemTitle: string;
};
const ListWithFolders = ({
  items,
  folders,
  setItemFolder,
  onCreateFolder,
  onItemClick,
  onCreateItem,
  onRenameFolder,
  onDeleteFolder,
  defaultItemTitle,
}: ListWithFoldersProps): JSX.Element => {
  if (!items) return <></>;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="mb-5">
        <ListItemDropTarget onDrop={(item) => setItemFolder && setItemFolder(item, null)}>
          {items
            ?.filter((item: TListItem) => !item.folderId || folders === null)
            .map((item: TListItem) => {
              return (
                <Wrapper key={item.id + item.name + item.itemType}>
                  <ListItem item={item} onItemClick={onItemClick} />
                </Wrapper>
              );
            })}
        </ListItemDropTarget>
        {(folders || []).map((folder: TFolder) => {
          const folderItems = items?.filter((item: TListItem) => item.folderId === folder.id);
          return (
            <ListItemDropTarget
              key={'folder' + folder.id}
              onDrop={(item) => setItemFolder && setItemFolder(item, folder.id)}
            >
              <Wrapper>
                <Folder
                  folder={folder}
                  defaultItemTitle={defaultItemTitle}
                  onCreateItem={onCreateItem}
                  onRenameFolder={onRenameFolder}
                  onDeleteFolder={onDeleteFolder}
                  itemsCount={folderItems?.length}
                >
                  {folderItems.map((item) => {
                    return (
                      <ListItem
                        item={item}
                        key={item.id + item.name + item.itemType}
                        onItemClick={onItemClick}
                        className="pl-5"
                      />
                    );
                  })}
                </Folder>
              </Wrapper>
            </ListItemDropTarget>
          );
        })}
        {onCreateFolder && (
          <div className="flex">
            <div
              className="flex p-2 items-center cursor-pointer text-primary-purple-light-60"
              onClick={onCreateFolder}
            >
              <div>
                <FolderAddIcon className={'w-5 h-5'} />
              </div>
              <div className="ml-2">New folder</div>
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 8px;
`;

export default ListWithFolders;
