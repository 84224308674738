import { default as UserPhoto1 } from '../assets/userAvatars/128/13.png';
import { default as UserPhoto1x2 } from '../assets/userAvatars/256/13.png';
import { default as UserPhoto2 } from '../assets/userAvatars/128/12.png';
import { default as UserPhoto2x2 } from '../assets/userAvatars/256/12.png';
import { default as UserPhoto3 } from '../assets/userAvatars/128/01.png';
import { default as UserPhoto3x2 } from '../assets/userAvatars/256/01.png';
import { default as UserPhoto4 } from '../assets/userAvatars/128/02.png';
import { default as UserPhoto4x2 } from '../assets/userAvatars/256/02.png';
import { default as UserPhoto5 } from '../assets/userAvatars/128/03.png';
import { default as UserPhoto5x2 } from '../assets/userAvatars/256/03.png';
import { default as UserPhoto6 } from '../assets/userAvatars/128/04.png';
import { default as UserPhoto6x2 } from '../assets/userAvatars/256/04.png';
import { default as UserPhoto7 } from '../assets/userAvatars/128/05.png';
import { default as UserPhoto7x2 } from '../assets/userAvatars/256/05.png';
import { default as UserPhoto8 } from '../assets/userAvatars/128/06.png';
import { default as UserPhoto8x2 } from '../assets/userAvatars/256/06.png';
import { default as UserPhoto9 } from '../assets/userAvatars/128/07.png';
import { default as UserPhoto9x2 } from '../assets/userAvatars/256/07.png';
import { default as UserPhoto10 } from '../assets/userAvatars/128/08.png';
import { default as UserPhoto10x2 } from '../assets/userAvatars/256/08.png';
import { default as UserPhoto11 } from '../assets/userAvatars/128/09.png';
import { default as UserPhoto11x2 } from '../assets/userAvatars/256/09.png';
import { default as UserPhoto12 } from '../assets/userAvatars/128/10.png';
import { default as UserPhoto12x2 } from '../assets/userAvatars/256/10.png';
import { default as UserPhoto13 } from '../assets/userAvatars/128/11.png';
import { default as UserPhoto13x2 } from '../assets/userAvatars/256/11.png';
import { default as UserPhoto14 } from '../assets/userAvatars/128/21.png';
import { default as UserPhoto14x2 } from '../assets/userAvatars/256/21.png';
import { default as UserPhoto15 } from '../assets/userAvatars/128/22.png';
import { default as UserPhoto15x2 } from '../assets/userAvatars/256/22.png';
import { default as UserPhoto16 } from '../assets/userAvatars/128/23.png';
import { default as UserPhoto16x2 } from '../assets/userAvatars/256/23.png';

import { default as UserPhoto24 } from '../assets/userAvatars/128/24.png';
import { default as UserPhoto24x2 } from '../assets/userAvatars/256/24.png';
import { default as UserPhoto25 } from '../assets/userAvatars/128/25.png';
import { default as UserPhoto25x2 } from '../assets/userAvatars/256/25.png';
import { default as UserPhoto26 } from '../assets/userAvatars/128/26.png';
import { default as UserPhoto26x2 } from '../assets/userAvatars/256/26.png';
import { default as UserPhoto27 } from '../assets/userAvatars/128/27.png';
import { default as UserPhoto27x2 } from '../assets/userAvatars/256/27.png';
import { default as UserPhoto28 } from '../assets/userAvatars/128/28.png';
import { default as UserPhoto28x2 } from '../assets/userAvatars/256/28.png';

import { default as UserPhotoCuriosity } from '../assets/userAvatars/128/Curiosity.png';
import { default as UserPhotoCuriosityx2 } from '../assets/userAvatars/256/Curiosity.png';
import { default as UserPhotoDaring } from '../assets/userAvatars/128/Daring.png';
import { default as UserPhotoDaringx2 } from '../assets/userAvatars/256/Daring.png';
import { default as UserPhotoEmpathy } from '../assets/userAvatars/128/Empathy.png';
import { default as UserPhotoEmpathyx2 } from '../assets/userAvatars/256/Empathy.png';
import { default as UserPhotoTrust } from '../assets/userAvatars/128/Trust.png';
import { default as UserPhotoTrustx2 } from '../assets/userAvatars/256/Trust.png';

import { default as UserPhotoAllison } from '../assets/userAvatars/128/Allison.png';
import { default as UserPhotoAllisonx2 } from '../assets/userAvatars/256/Allison.png';
import { default as UserPhotoBrittany } from '../assets/userAvatars/128/Brittany.png';
import { default as UserPhotoBrittanyx2 } from '../assets/userAvatars/256/Brittany.png';
import { default as UserPhotoEtan } from '../assets/userAvatars/128/Etan.png';
import { default as UserPhotoEtanx2 } from '../assets/userAvatars/256/Etan.png';
import { default as UserPhotoJessica } from '../assets/userAvatars/128/Jessica.png';
import { default as UserPhotoJessicax2 } from '../assets/userAvatars/256/Jessica.png';
import { default as UserPhotoJohn } from '../assets/userAvatars/128/John.png';
import { default as UserPhotoJohnx2 } from '../assets/userAvatars/256/John.png';
import { default as UserPhotoPaul } from '../assets/userAvatars/128/Paul.png';
import { default as UserPhotoPaulx2 } from '../assets/userAvatars/256/Paul.png';
import { default as UserPhotoSergey } from '../assets/userAvatars/128/Sergey.png';
import { default as UserPhotoSergeyx2 } from '../assets/userAvatars/256/Sergey.png';

export const defaultUserImages = [
  { name: 'DefaultUserPhoto1', url: UserPhoto1, position: '50% 50%', url2x: UserPhoto1x2 },
  { name: 'DefaultUserPhoto2', url: UserPhoto2, position: '50% 50%', url2x: UserPhoto2x2 },
  { name: 'DefaultUserPhoto3', url: UserPhoto3, position: '50% 50%', url2x: UserPhoto3x2 },
  { name: 'DefaultUserPhoto4', url: UserPhoto4, position: '50% 50%', url2x: UserPhoto4x2 },
  { name: 'DefaultUserPhoto5', url: UserPhoto5, position: '50% 50%', url2x: UserPhoto5x2 },
  { name: 'DefaultUserPhoto6', url: UserPhoto6, position: '50% 50%', url2x: UserPhoto6x2 },
  { name: 'DefaultUserPhoto7', url: UserPhoto7, position: '50% 50%', url2x: UserPhoto7x2 },
  { name: 'DefaultUserPhoto8', url: UserPhoto8, position: '50% 50%', url2x: UserPhoto8x2 },
  { name: 'DefaultUserPhoto9', url: UserPhoto9, position: '50% 50%', url2x: UserPhoto9x2 },
  { name: 'DefaultUserPhoto10', url: UserPhoto10, position: '50% 50%', url2x: UserPhoto10x2 },
  { name: 'DefaultUserPhoto11', url: UserPhoto11, position: '50% 50%', url2x: UserPhoto11x2 },
  { name: 'DefaultUserPhoto12', url: UserPhoto12, position: '50% 50%', url2x: UserPhoto12x2 },
  { name: 'DefaultUserPhoto13', url: UserPhoto13, position: '50% 50%', url2x: UserPhoto13x2 },
  { name: 'DefaultUserPhoto14', url: UserPhoto14, position: '50% 50%', url2x: UserPhoto14x2 },
  { name: 'DefaultUserPhoto15', url: UserPhoto15, position: '50% 50%', url2x: UserPhoto15x2 },
  { name: 'DefaultUserPhoto16', url: UserPhoto16, position: '50% 50%', url2x: UserPhoto16x2 },
  { name: 'DefaultUserPhoto24', url: UserPhoto24, position: '50% 50%', url2x: UserPhoto24x2 },
  { name: 'DefaultUserPhoto25', url: UserPhoto25, position: '50% 50%', url2x: UserPhoto25x2 },
  { name: 'DefaultUserPhoto26', url: UserPhoto26, position: '50% 50%', url2x: UserPhoto26x2 },
  { name: 'DefaultUserPhoto27', url: UserPhoto27, position: '50% 50%', url2x: UserPhoto27x2 },
  { name: 'DefaultUserPhoto28', url: UserPhoto28, position: '50% 50%', url2x: UserPhoto28x2 },
  {
    name: 'DefaultUserPhotoCuriosity',
    url: UserPhotoCuriosity,
    position: '50% 50%',
    url2x: UserPhotoCuriosityx2,
  },
  {
    name: 'DefaultUserPhotoDaring',
    url: UserPhotoDaring,
    position: '50% 50%',
    url2x: UserPhotoDaringx2,
  },
  {
    name: 'DefaultUserPhotoEmpathy',
    url: UserPhotoEmpathy,
    position: '50% 50%',
    url2x: UserPhotoEmpathyx2,
  },
  {
    name: 'DefaultUserPhotoTrust',
    url: UserPhotoTrust,
    position: '50% 50%',
    url2x: UserPhotoTrustx2,
  },
  {
    name: 'DefaultUserPhotoAllison',
    url: UserPhotoAllison,
    position: '50% 50%',
    url2x: UserPhotoAllisonx2,
  },
  {
    name: 'DefaultUserPhotoBrittany',
    url: UserPhotoBrittany,
    position: '50% 50%',
    url2x: UserPhotoBrittanyx2,
  },
  {
    name: 'DefaultUserPhotoEtan',
    url: UserPhotoEtan,
    position: '50% 50%',
    url2x: UserPhotoEtanx2,
  },
  {
    name: 'DefaultUserPhotoJessica',
    url: UserPhotoJessica,
    position: '50% 50%',
    url2x: UserPhotoJessicax2,
  },
  {
    name: 'DefaultUserPhotoJohn',
    url: UserPhotoJohn,
    position: '50% 50%',
    url2x: UserPhotoJohnx2,
  },
  {
    name: 'DefaultUserPhotoPaul',
    url: UserPhotoPaul,
    position: '50% 50%',
    url2x: UserPhotoPaulx2,
  },
  {
    name: 'DefaultUserPhotoSergey',
    url: UserPhotoSergey,
    position: '50% 50%',
    url2x: UserPhotoSergeyx2,
  },
];
