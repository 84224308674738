import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const SelectedContainer = styled.div<{ type?: 'regular' | 'ghost' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  ${({ type }) => type !== 'ghost' && `background-color: rgba(56, 33, 82, 0.08)`};
  border-radius: 20px;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div<{ isShort: boolean }>`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 5px;
  user-select: none;

  max-width: ${({ isShort }) => (isShort ? '75px' : '210px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TitleExtraNumber = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 0 3px;
  background-color: #d7d3dc;
  border-radius: 2px;
`;

export const Icon = styled.div<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? '-90deg' : '90deg')});
`;

export const Menu = styled.div<{ isOpen: boolean; top: number; right: number }>`
  position: fixed;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 300px;
  padding-bottom: 10px;
  overflow-y: auto;
  top: ${({ top }) => top + 4}px;
  right: ${({ right }) => right}px;
  background-color: #ffffff;
  border-radius: 6px;
  display: none;
  border: 1px solid #d7d3dc;
  z-index: 999;

  ${({ isOpen }) => isOpen && `display: block;`}
`;

export const MenuItem = styled.div`
  padding: 4px 10px;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ProjectLogo = styled.div<{ src: string }>`
  width: 24px;
  height: 24px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
`;

export const MenuItemLabel = styled.div<{ withMargin?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-right: ${({ withMargin }) => (withMargin ? '10px' : '0')};
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWithLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const TitlePlaceholder = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const FolderContainer = styled.div``;

export const FolderNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export const CollapseIcon = styled.div<{ isCollapsed: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  margin-left: 4px;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(0deg)' : 'rotate(90deg)')};
  cursor: pointer;
`;

export const FolderIcon = styled.div`
  margin-right: 8px;
`;

export const FolderName = styled.div`
  font-weight: 500;
  line-height: 28px;
  font-size: 14px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;

export const FolderList = styled.div<{ isCollapsed: boolean }>`
  padding-left: 16px;
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
`;

export const FolderNameWithCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SearchInputWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  padding: 10px 10px 0;
  margin-bottom: 8px;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  background-color: rgba(56, 33, 82, 0.06);
  border-radius: 12px;
`;

export const SearchIcon = styled.div`
  margin-right: 10px;
`;

export const FolderNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const SelectedProjectsNumber = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  margin-left: 4px;
`;

export const AllProjectsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  cursor: pointer;
`;

export const AllProjectsLabel = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
`;
