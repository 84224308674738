import { useQuery, gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { CURRENT_ORG, FETCH_USER_DASHBOARDS } from '../GraphQL/queries';
import { UPDATE_ORGANIZATION } from '../GraphQL/mutations';

export function checkOrganizationLimits(
  org: any,
  history: any,
  whatToCheck = ['projects', 'storage', 'duration']
) {
  const {
    id,
    projectsLimitExceeded,
    durationLimitExceeded,
    fileLimitExceeded,
    projectsLimitTotal,
    projectsCount,
  } = org;

  if (
    whatToCheck.includes('projects') &&
    (projectsLimitExceeded || parseInt(projectsLimitTotal) == parseInt(projectsCount))
  ) {
    toast.info(
      "You've reached the limit of projects available on your current plan. Please upgrade to create more projects."
    );
    history.push('/settings/plans');
    return false;
  }

  if (whatToCheck.includes('duration') && durationLimitExceeded) {
    toast.info(
      "You've reached the limit of transcription hours on your current plan. Please upgrade."
    );
    history.push('/settings/plans');
    return false;
  }

  if (whatToCheck.includes('storage') && fileLimitExceeded) {
    toast.info("You've reached the storage limit on your current plan. Please upgrade.");
    history.push('/settings/plans');
    return false;
  }

  return true;
}

export function useOrganization() {
  const { data, loading, refetch } = useQuery(CURRENT_ORG);

  return [loading, data?.currentOrganization, refetch];
}

export function useViewOnlyProjects() {
  const [loadingOrg, org, refetchOrg] = useOrganization();
  const { loading, data, refetch } = useQuery(FETCH_USER_DASHBOARDS);
  let viewOnlyProjects: string[] = [];

  if (org.projectsLimitExceeded && !loading) {
    viewOnlyProjects = data.dashboards.map((board: any) => board.id).slice(org.projectsLimitTotal);
  }
  return [
    loadingOrg && loading,
    viewOnlyProjects,
    () => {
      refetchOrg();
      refetch();
    },
  ];
}

export const useUpdateOrganization = (): [
  (input: { logo?: string | null; name?: string | null }) => Promise<any>
] => {
  const [updateMutation] = useMutation(UPDATE_ORGANIZATION);

  return [
    async (input: { logo?: string | null; name?: string | null }) => {
      return updateMutation({
        variables: {
          input: { ...input },
        },
        refetchQueries: [{ query: CURRENT_ORG }],
      });
    },
  ];
};
