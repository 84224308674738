import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 10px 0;
  cursor: pointer;
`;

export const BackgroundImage = styled.img<{ objectPosition: string }>`
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: ${({ objectPosition }) => objectPosition};
  border-radius: 3px 3px 0 0;
`;

export const Content = styled.div`
  width: 100%;
  padding: 12px;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #d7d3dc;
  margin: 10px 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 256px;
  margin-right: 8px;
`;

export const LogoImage = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  object-fit: 'cover';
  border-radius: 2px;
`;

export const Name = styled.div`
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RoundDivider = styled.div`
  margin-right: 8px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #e2e8f0;
`;

export const CreatedAt = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: rgba(59, 38, 81, 0.4);
`;
