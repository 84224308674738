import { format } from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { defaultCoverImages } from '../../../../Consts/coverImages';
import { defaultProjectIcons } from '../../../../Consts/projectIcons';
import { NodeViewWrapper } from '@tiptap/react';
import {
  BackgroundImage,
  Container,
  Content,
  CreatedAt,
  Divider,
  LogoImage,
  Name,
  NameContainer,
  RoundDivider,
  Title,
  TitleContainer,
} from './styles';
import Loader from '../../../Loader';
import { EvidenceContext } from '../../../../Context/EvidenceContext';

const InsightBlock = (props: any): JSX.Element => {
  const { isInitialized, findEvidenceById } = useContext(EvidenceContext);
  const [insight, setInsight] = useState<any | null>(null);

  useEffect(() => {
    if (isInitialized) {
      setInsight(findEvidenceById(props.node.attrs.id)?.insight || null);
    }
  }, [isInitialized]);

  const coverImage = useMemo(() => {
    if (!insight) return;
    return insight.customCover
      ? { name: 'custom', url: insight.customCover, position: '50% 50%' }
      : defaultCoverImages.find((image) => image.name === insight.cover);
  }, [insight]);

  const icon = useMemo(() => {
    if (!insight) return;
    return (
      defaultProjectIcons.find((icon) => icon.name === insight.dashboard?.cover) ||
      defaultProjectIcons[0]
    );
  }, [insight]);

  return (
    <NodeViewWrapper>
      {!insight ? (
        <Loader />
      ) : (
        <Container
          contentEditable={false}
          onClick={() => {
            const url = `${window.location.origin}/projects/${insight?.dashboardId}/insights/${insight.clientId}`;
            window?.open(url, '_blank')?.focus();
          }}
        >
          {coverImage && (
            <BackgroundImage
              src={coverImage.url}
              alt="cover image"
              objectPosition={coverImage.position}
            />
          )}
          <Content>
            <Title>{insight?.title}</Title>
            <Divider />
            <TitleContainer>
              <NameContainer>
                <LogoImage src={icon?.url} alt="Project cover" />
                <Name>{insight?.dashboard?.name || 'Untitled project'}</Name>
              </NameContainer>
              <RoundDivider />
              <CreatedAt>{format(new Date(insight.createdAt), 'LLL d, yyyy')}</CreatedAt>
            </TitleContainer>
          </Content>
        </Container>
      )}
    </NodeViewWrapper>
  );
};

export default InsightBlock;
