import React, { useRef, useState } from 'react';
import { Tag, TagWithDetails } from '../../Models';
import PortalWrapper from '../PortalWrapper';
import TagsDropdown from '../TagsDropdown';

import { TagsContainer, Placeholder, TagBadge } from './styles';

type Props = {
  tags: Tag[] | null;
  onChange: (tags: Array<Tag>) => void;
  dashboardId: string;
  readOnly?: boolean;
  selectedText?: string;
  hideManageButton?: boolean;
};

const TagsPicker = ({
  tags = [],
  selectedText,
  onChange,
  hideManageButton,
  readOnly = false,
}: Props): JSX.Element => {
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleChange = (selectedTags: TagWithDetails[]) => {
    onChange(
      selectedTags.map((tag) => ({
        id: tag.id,
        name: tag.name,
        groupId: tag.groupId,
        color: tag.color,
      }))
    );
  };

  return (
    <TagsContainer
      ref={buttonRef}
      onClick={() => {
        if (readOnly) return;
        setShowSelect(true);
      }}
    >
      {(!tags || tags.length === 0) && <Placeholder>Add tags</Placeholder>}
      {tags && (
        <span>
          {tags.map((tag) => (
            <TagBadge key={tag.id} name={tag.name} color={tag.color || 'rgba(56, 33, 82, 0.1)'} />
          ))}
        </span>
      )}

      {showSelect && (
        <PortalWrapper targetElement={buttonRef.current as HTMLDivElement}>
          <TagsDropdown
            value={tags as TagWithDetails[]}
            onChange={handleChange}
            onBlur={() => setShowSelect(false)}
            selectedText={selectedText}
            hideManageButton={hideManageButton}
          />
        </PortalWrapper>
      )}
    </TagsContainer>
  );
};

export default React.memo(TagsPicker);
