import { Popover } from '@headlessui/react';
import React, { useState } from 'react';
import Button from './Button';
import { ShareIcon, DuplicateIcon } from '@heroicons/react/outline';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import usePermissions from '../Hooks/usePermissions';

export default function InternalSharing({
  link,
  type,
}: {
  link: string;
  type?: 'primary' | 'secondary';
}): JSX.Element {
  const [justCopied, setJustCopied] = useState(false);
  const { canEditTeam } = usePermissions();
  return (
    <Popover className="relative">
      <Popover.Button className="flex">
        <Button type={type || 'primary'} className="mr-2">
          <ShareIcon className={'w-4 h-4 mr-1'} />
          Share
        </Button>
      </Popover.Button>

      <Popover.Panel className="absolute z-100 mt-2 bg-white rounded shadow-base border border-primary-purple-light w-80 right-0 p-3">
        <div className="mt-1">
          <InputGroup>
            <Input
              placeholder="Link"
              colorScheme="purple"
              isReadOnly
              value={justCopied ? 'Copied!' : link}
              className="text-sm font-medium"
              style={{ fontSize: '14px' }}
            />
            <InputRightElement>
              <DuplicateIcon
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  setJustCopied(true);
                  setTimeout(() => {
                    setJustCopied(false);
                  }, 2000);
                }}
              />
            </InputRightElement>
          </InputGroup>
        </div>

        <div className="text-primary-purple-light-80 text-sm font-medium mt-3">
          Viewers must have a Notably account to access this data.{' '}
          {canEditTeam && (
            <Link className="underline" to={'/settings/users'}>
              Go to team settings.
            </Link>
          )}
        </div>
      </Popover.Panel>
    </Popover>
  );
}
