import React, { FC, useEffect, useMemo, useState } from 'react';
import Button from '../../Button';
import ReactLoading from 'react-loading';
import {
  ButtonWrapper,
  Container,
  Content,
  SkipButton,
  Step,
  Subtitle,
  TemplateList,
  Title,
} from './styles';

import { TPrompt, usePrompts } from '../../../Hooks/usePrompts';
import { PROMPT_ICONS } from '../../../Consts/promptTemplates';
import PromptDefaultIcon from '../../../assets/projects/default-icons/Red-Thread.svg';
import TemplateCard from '../../TemplateCard';

type ChooseTemplateStepProps = {
  onContinue: (template: TPrompt | null) => void;
  totalSteps: number;
};

const promptsToShow = [
  'Stakeholder Interview',
  'Usability test',
  'Secondary Research',
  'Focus group',
  'Discovery',
  'The 5 E’s of Customer Journey',
];

const ChooseTemplateStep: FC<ChooseTemplateStepProps> = ({ onContinue, totalSteps }) => {
  const [currentTemplate, setCurrentTemplate] = useState<TPrompt | null>(null);
  const [loadingPrompts, prompts] = usePrompts({ promptType: 'transcript' });

  useEffect(() => {
    if (!loadingPrompts && prompts) {
      setCurrentTemplate(prompts[0]);
    }
  }, [loadingPrompts, prompts]);

  const getPrompts = useMemo(() => {
    return (
      prompts
        ?.filter((prompt) => promptsToShow.includes(prompt.title))
        .map((prompt: any) => {
          return {
            id: prompt?.id,
            title: prompt?.title,
            description: prompt?.description,
            icon: prompt?.icon || PROMPT_ICONS.get(prompt?.defaultIcon || '') || PromptDefaultIcon,
            isPublic: prompt?.isPublic,
            category: prompt?.category,
            favoriteId: prompt?.favoriteId,
          };
        }) || []
    );
  }, [prompts]);

  return (
    <Container>
      <Content>
        <Step>{`Step 4 of ${totalSteps}`}</Step>
        <Title>Choose an AI template to start analysis</Title>
        <Subtitle>
          Notably’s AI Templates analyze your data for unique insight. Choose one to get started.
        </Subtitle>

        {loadingPrompts ? (
          <ReactLoading type={'spin'} color={'#382152'} height={'5%'} width={'5%'} />
        ) : (
          <TemplateList>
            {getPrompts.map((prompt: any) => (
              <TemplateCard
                key={prompt.id}
                template={prompt}
                isActive={currentTemplate?.id === prompt.id}
                onClick={() => setCurrentTemplate(prompt)}
              />
            ))}
          </TemplateList>
        )}

        <ButtonWrapper>
          <Button
            size={'lg'}
            className="w-full flex justify-center"
            disabled={!currentTemplate}
            onClick={() => onContinue(currentTemplate)}
          >
            Continue
          </Button>
        </ButtonWrapper>
        <SkipButton onClick={() => onContinue(null)}>Skip this step</SkipButton>
      </Content>
    </Container>
  );
};

export default ChooseTemplateStep;
