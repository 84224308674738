import React from 'react';

export default function CaretIcon({ direction = 'down' }: { direction: string }): JSX.Element {
  return (
    <>
      {direction === 'down' && (
        <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.83341 1L3.50008 3.33333L1.16675 1"
            stroke="#3B2651"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {direction === 'up' && (
        <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.9777 4L3.64437 1.66667L1.31104 4"
            stroke="#3B2651"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
}
