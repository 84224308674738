import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { XIcon, SearchIcon, FilterIcon } from '@heroicons/react/outline';
import { Input } from '@chakra-ui/react';
import Button from '../Components/Button';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { defaultProjectIcons } from '../Consts/projectIcons';
import Masonry from 'react-masonry-css';
import { format } from 'date-fns';
import Avatar from './Avatar';
import { composeSearchEvidence } from '../GraphQL/queries/searchEverything';
import { defaultCoverImages } from '../Consts/coverImages';
import { useFetchParticipantNames } from '../Hooks/useParticipants';
import Select from './Select';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';
import Loader from './Loader';
import useTags from '../Hooks/useTags';
import { ID, Insight, Tag } from '../Models';
import TagsPicker from './TagsPicker';
import ProjectsDropdown from './ProjectsDropdown';
import useBoards, { Folder, useFetchFolders } from '../Hooks/useBoards';
import { getDashboards_dashboards } from '../GraphQL/__generated__/getDashboards';
import { ProjectDropDownOption } from './ProjectsDropdown/ProjectsDropdown';
import { Icon24 } from '../Icons/Icon';

const INSIGHTS_SEARCH_ID = 'insights';
const NOTES_SEARCH_ID = 'notes';
const DEFAULT_SEARCH_TYPES = [INSIGHTS_SEARCH_ID, NOTES_SEARCH_ID];

interface Props extends ReactModal.Props {
  classNames?: string;
  onClose: () => void;
  dashboardId: string;
  insightId: ID;
  onSubmit: (evidences: any) => void;
}

type SearchResult = {
  notes: Note[] | undefined;
  projectInsights: Insight[] | undefined;
};

type Note = {
  id: string;
  text: string;
  dashboardId: string;
  createdAt: string;
  userByCreatedBy: {
    name: string;
    picture: string;
  };
  participant?: {
    name: string;
    picture: string;
  };
  tagsList?: {
    id: string;
    name: string;
  }[];
};

const useDisableBackgroundScroll = (isEnabled: boolean) => {
  useEffect(() => {
    document.body.style.overflow = isEnabled ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isEnabled]);
};

export default function EvidenceModal({
  // classNames,
  dashboardId,
  insightId,
  isOpen = true,
  onClose,
  onSubmit,
  ...rest
}: React.PropsWithChildren<Props>): JSX.Element {
  useDisableBackgroundScroll(isOpen);
  const client = useApolloClient();
  const { fetchTags } = useTags();

  const [, tags] = fetchTags();

  const [selectedNotes, setSelectedNotes] = useState<string[]>([]);
  const [selectedInsights, setSelectedInsights] = useState<string[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<OptionsType<OptionTypeBase>>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [loadingEvidences, setLoadingEvidences] = useState(false);
  const [foldersLoading, folders] = useFetchFolders();

  const [loadingParticipants, participants] = useFetchParticipantNames();
  const { fetchBoards } = useBoards();
  const [loadingDashboards, dashboards] = fetchBoards();
  const [currentProjectFilterOptions, setCurrentProjectFilterOptions] = useState<
    ProjectDropDownOption[]
  >([]);
  const [hasVideo, setHasVideo] = useState(false);
  const [selectedSearchTypes, setSelectedSearchTypes] = useState<string[]>(DEFAULT_SEARCH_TYPES);

  const [searchResult, setSearchResult] = useState<SearchResult>({
    notes: [],
    projectInsights: [],
  });

  const [searchText, setSearchText] = useState<string>('');

  const debouncedFetchSearchData = useDebouncedCallback(
    async (
      searchText: string,
      dashboardIds,
      searchTypes: string[],
      participantIds,
      tags,
      hasVideo: boolean
    ) => {
      const { data } = await client.query({
        query: composeSearchEvidence(
          searchText,
          participantIds && participantIds.length > 0,
          tags && tags.length > 0,
          hasVideo
        ),
        variables: {
          search: searchText,
          withNotes: searchTypes.includes(NOTES_SEARCH_ID),
          withInsights: searchTypes.includes(INSIGHTS_SEARCH_ID),
          dashboardIds,
          participantIds,
          withTags: Boolean(tags && tags.length > 0),
          tags,
        },
      });
      setSearchResult(data);
      setLoadingEvidences(false);
    },
    500
  );

  useEffect(() => {
    setLoadingEvidences(true);
    debouncedFetchSearchData(
      searchText,
      currentProjectFilterOptions.map((option) => option.value),
      selectedSearchTypes,
      selectedParticipants.length > 0 ? selectedParticipants.map((x) => x.value) : null,
      selectedTags.length > 0 ? selectedTags.map((x) => x.id) : [],
      hasVideo
    );
  }, [
    searchText,
    selectedSearchTypes,
    currentProjectFilterOptions,
    selectedParticipants,
    hasVideo,
    selectedTags,
  ]);

  const projectFolderOptions = useMemo(() => {
    return (
      folders?.map((folder: Folder) => {
        const projectsInFolder = dashboards?.filter(
          (project: getDashboards_dashboards) => project.folderId === folder.id
        );
        return {
          folderId: folder.id,
          folderName: folder.name,
          options:
            projectsInFolder?.map((project) => {
              const iconData = defaultProjectIcons.find((icon) => icon.name === project.cover);
              return {
                value: project.id,
                label: project.name || 'Untitled project',
                icon: iconData?.url || defaultProjectIcons[0].url,
              };
            }) || [],
        };
      }) || []
    );
  }, [dashboards, folders]);

  const projectsWithoutFolderOptions = useMemo(() => {
    return (
      dashboards
        ?.filter((project: getDashboards_dashboards) => !project.folderId)
        .map((project) => {
          const iconData = defaultProjectIcons.find((icon) => icon.name === project.cover);
          return {
            value: project.id,
            label: project.name || 'Untitled project',
            icon: iconData?.url || defaultProjectIcons[0].url,
          };
        }) || []
    );
  }, [dashboards]);

  useEffect(() => {
    if (projectFolderOptions.length || projectsWithoutFolderOptions.length) {
      const options = projectFolderOptions
        .flatMap((folder) => folder.options)
        .concat(projectsWithoutFolderOptions);
      const defaultOption = options.find((option) => option.value === dashboardId);
      setCurrentProjectFilterOptions(defaultOption ? [defaultOption] : []);
    }
  }, [projectFolderOptions, projectsWithoutFolderOptions, dashboardId]);

  const handleClose = () => {
    onClose?.();
    setSelectedNotes([]);
    setSearchText('');
  };

  const selectedCount = selectedNotes.length + selectedInsights.length;

  const filteredNotes = searchResult.notes
    ? searchResult.notes.filter((note) => {
        if (selectedTags.length > 0 && note.tagsList && note.tagsList.length === 0) return false;
        return true;
      })
    : [];

  return (
    <ReactModal onRequestClose={handleClose} isOpen={isOpen} {...rest}>
      <div className="bg-white shadow-md rounded h-[calc(100vh-32px)]">
        <div className="flex flex-1 items-center border-b border-primary-purple-light">
          <div className="flex flex-1 items-center rounded-md">
            <SearchIcon className="w-6 h-6 ml-4" />
            <div className="h-12 flex flex-1 items-center ml-2">
              <Input
                variant="unstyled"
                autoFocus={true}
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                placeholder="Search for insights and notes…"
              />
            </div>
            <button
              onClick={() => {
                setSearchText('');
              }}
            >
              <XIcon className="w-5 h-5 mr-3" />
            </button>
            <div className="w-[304px]">
              <ProjectsDropdown
                folderOptions={projectFolderOptions}
                projectOptions={projectsWithoutFolderOptions}
                currentOptions={currentProjectFilterOptions}
                onChange={setCurrentProjectFilterOptions}
                type="ghost"
              />
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="w-64 border-r border-primary-purple-light overflow-y-scroll h-[calc(100vh-140px)]">
            <CheckboxGroup
              colorScheme="purple"
              value={selectedSearchTypes}
              onChange={(value: string[]) => setSelectedSearchTypes(value)}
            >
              <div className="font-medium mt-5 mx-3 mb-3">
                <div className="flex items-center mb-4">
                  <Icon24.Filter />
                  <h3 className="ml-[5px]">Filter</h3>
                </div>

                <div className="my-1">
                  <Checkbox value={INSIGHTS_SEARCH_ID} className="flex">
                    Insights
                  </Checkbox>
                </div>
              </div>
              <hr />
              <div className="font-medium m-3">
                <div className="my-1">
                  <Checkbox value={NOTES_SEARCH_ID} className="flex">
                    Notes
                  </Checkbox>
                </div>
              </div>
            </CheckboxGroup>
            {selectedSearchTypes.includes(NOTES_SEARCH_ID) && (
              <>
                <div className="m-3">
                  <div className="flex justify-between items-center">
                    <h3 className="font-medium">Tags</h3>
                    <div
                      className="font-semibold text-xs cursor-pointer"
                      onClick={() => {
                        setSelectedTags([]);
                      }}
                    >
                      Clear
                    </div>
                  </div>

                  <div className="mt-2">
                    {tags && (
                      <TagsPicker
                        tags={selectedTags}
                        onChange={(tags) => setSelectedTags(tags)}
                        dashboardId={dashboardId}
                        hideManageButton
                      />
                    )}
                  </div>
                </div>
                <div className="m-3">
                  <div className="flex justify-between items-center">
                    <h3 className="font-medium">Participants</h3>
                    <div
                      className="font-semibold text-xs cursor-pointer"
                      onClick={() => {
                        setSelectedParticipants([]);
                      }}
                    >
                      Clear
                    </div>
                  </div>
                  <div className="mt-2">
                    {participants && (
                      <Select
                        isMulti
                        isClearable={false}
                        placeholder="Select participants"
                        options={[
                          ...participants.map((participant) => ({
                            value: participant.id,
                            label: participant.name,
                            color: participant.color,
                          })),
                        ]}
                        onChange={(option) => {
                          setSelectedParticipants((option as OptionsType<OptionTypeBase>) || []);
                        }}
                        isSearchable
                        value={selectedParticipants}
                      />
                    )}
                  </div>
                </div>
                <div className="m-3">
                  <Checkbox
                    className="flex"
                    colorScheme="purple"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setHasVideo(event.target.checked);
                    }}
                  >
                    <span className="font-medium text-sm">Has video</span>
                  </Checkbox>
                </div>
              </>
            )}
          </div>
          <div className="flex-1 p-5 overflow-y-scroll h-[calc(100vh-140px)]">
            {loadingEvidences && <Loader />}
            {!loadingEvidences && (
              <>
                {/* NOTES */}
                {selectedSearchTypes.includes(NOTES_SEARCH_ID) && (
                  <div>
                    <h2 className="mb-2 font-medium">Notes</h2>

                    {filteredNotes.length === 0 && (
                      <div className="pb-2 text-primary-purple-light-40">
                        No notes matching “{searchText}”
                      </div>
                    )}
                    {filteredNotes.length > 0 && (
                      <CheckboxGroup
                        colorScheme="purple"
                        value={selectedNotes}
                        onChange={(value: string[]) => setSelectedNotes(value)}
                      >
                        <Masonry
                          breakpointCols={2}
                          className="basic-masonry-grid"
                          columnClassName="basic-masonry-grid_column"
                        >
                          {filteredNotes.map(
                            (note: Note): JSX.Element => (
                              <div
                                key={note.id}
                                className="border border-primary-purple-light rounded shadow-notes cursor-pointer mb-5"
                              >
                                <div className="p-3">
                                  <Checkbox
                                    key={note.id}
                                    value={`${note.id}:${note.dashboardId}`}
                                    className="flex"
                                    style={{
                                      alignItems: 'flex-start',
                                    }}
                                    variant="card"
                                  >
                                    <div className="font-medium text-sm">
                                      {note.text || 'Untitled'}
                                    </div>
                                  </Checkbox>
                                  <hr className="my-2" />
                                  <div className="flex items-center text-sm mt-2">
                                    {note.participant && (
                                      <>
                                        <div className="flex items-center">
                                          <Avatar user={note.participant} />
                                          <div className="ml-2">{note.participant?.name}</div>
                                        </div>
                                        <div className="rounded-full bg-primary-purple-light h-1 w-1 mx-1"></div>
                                      </>
                                    )}
                                    <div className="text-right max-w-[96px] text-primary-purple-light-40">
                                      {format(new Date(note.createdAt), 'LLL d, yyyy')}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </Masonry>
                      </CheckboxGroup>
                    )}
                  </div>
                )}
                {/* Insights */}
                {selectedSearchTypes.includes(INSIGHTS_SEARCH_ID) && (
                  <div>
                    <h2 className="mb-2 font-medium">Insights</h2>
                    {(!searchResult.projectInsights ||
                      searchResult.projectInsights.length === 0) && (
                      <div className="pb-2 text-primary-purple-light-40">
                        No insights matching “{searchText}”
                      </div>
                    )}
                    {searchResult.projectInsights && searchResult.projectInsights?.length > 0 && (
                      <CheckboxGroup
                        colorScheme="purple"
                        value={selectedInsights}
                        onChange={(value: string[]) => setSelectedInsights(value)}
                      >
                        <Masonry
                          breakpointCols={2}
                          className="basic-masonry-grid"
                          columnClassName="basic-masonry-grid_column"
                        >
                          {searchResult.projectInsights.map(
                            (insight: Insight): JSX.Element => {
                              if (insight.id === insightId) return <div key={insight.id}></div>;
                              const coverImage = insight?.customCover
                                ? { name: 'custom', url: insight.customCover, position: '50% 50%' }
                                : defaultCoverImages.find((image) => image.name === insight?.cover);
                              const icon =
                                defaultProjectIcons.find(
                                  (icon) => icon.name === insight?.dashboard?.cover
                                ) || defaultProjectIcons[0];
                              return (
                                <div
                                  key={insight.id}
                                  className="border border-primary-purple-light rounded shadow-notes cursor-pointer mb-5"
                                >
                                  {coverImage && (
                                    <img
                                      className="m-0 rounded-t"
                                      src={coverImage.url}
                                      alt="cover image"
                                      style={{
                                        width: '100%',
                                        height: 80,
                                        objectFit: 'cover',
                                        objectPosition: coverImage.position,
                                      }}
                                    />
                                  )}
                                  <div className="p-3">
                                    <Checkbox
                                      key={insight.id}
                                      value={`${insight.id}:${insight.dashboardId}:${insight.clientId}`}
                                      className="flex"
                                      style={{
                                        alignItems: 'flex-start',
                                      }}
                                      variant="card"
                                    >
                                      <div className="font-medium text-sm">
                                        {insight.title || 'Untitled'}
                                      </div>
                                    </Checkbox>

                                    <hr className="m-0 my-2" />
                                    <div className="flex items-center text-sm">
                                      <div className="flex items-center font-medium max-w-[160px] ">
                                        <img
                                          className="m-0 mr-2 rounded-md"
                                          src={icon?.url}
                                          alt="Project cover"
                                          style={{
                                            width: 24,
                                            height: 24,
                                            objectFit: 'cover',
                                          }}
                                        />
                                        <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                                          {insight?.dashboard?.name || 'Untitled project'}
                                        </div>
                                      </div>
                                      <div className="flex items-center text-right text-primary-purple-light-40 ml-2">
                                        <div className="rounded-full bg-primary-purple-light h-1 w-1 mr-2"></div>
                                        {format(new Date(insight.createdAt), 'LLL d, yyyy')}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </Masonry>
                      </CheckboxGroup>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <hr />
          <div className="p-3">
            <Button
              type={'primary'}
              onClick={() => {
                onSubmit({
                  notes: selectedNotes.map((item) => {
                    const [id, dashboardId] = item.split(':');
                    return { id, dashboardId };
                  }),
                  insights: selectedInsights.map((item) => {
                    const [id, dashboardId, clientId] = item.split(':');
                    return { id, dashboardId, clientId };
                  }),
                });
                setSelectedNotes([]);
                setSelectedInsights([]);
                setSearchText('');
              }}
              disabled={selectedCount === 0}
            >
              Add{selectedCount > 0 && ' ' + selectedCount} to insight
            </Button>
            <Button type={'secondary'} className="ml-2" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
