import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

function InPortal({ top, left, height = null, width = null, children }: PropsWithChildren<any>) {
  const rowHeight = 40;
  const padding = 6;

  const [dimensions, setDimensions] = useState({ left: 0, bottom: 0 });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current: divRef } = containerRef;

    let computedTop, computedLeft;

    if (!divRef) {
      return;
    }

    const { offsetWidth, offsetHeight } = divRef;

    const computedHeight = height ?? offsetHeight;
    const computedWidth = width ?? offsetWidth;

    if (top + computedHeight > window.innerHeight) {
      computedTop = top - computedHeight + rowHeight + padding;
    } else {
      computedTop = top - padding;
    }

    if (left + computedWidth > window.innerWidth) {
      computedLeft = left - computedWidth + padding;
    } else {
      computedLeft = left - padding;
    }

    setDimensions({
      bottom: window.innerHeight - computedTop - computedHeight,
      left: computedLeft,
    });
  }, [containerRef, top, left]);

  return createPortal(
    <div className={'absolute z-100 top-0 left-0 w-full h-full'}>
      <div
        ref={containerRef}
        style={{
          bottom: dimensions.bottom,
          left: dimensions.left,
        }}
        className="absolute z-100"
      >
        {children}
      </div>
    </div>,
    window.document.body
  );
}

export default InPortal;
