import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import languageOptions from '../../Consts/languageOptions';
import { Icon24 } from '../../Icons/Icon';
import Avatar from '../../Components/Avatar';
import Button from '../../Components/Button';
import Dropdown from '../../Components/Dropdown';
import Input from '../../Components/Input/Input';
import { useHistory, useParams } from 'react-router-dom';
import {
  TUpdatePromptObject,
  useCreatePrompt,
  useDeletePrompt,
  useFetchPromptById,
  useUpdatePrompt,
} from '../../Hooks/usePrompts';
import { useDebouncedCallback } from 'use-debounce';
import Showdown from 'showdown';
import PromptDefaultIcon from '../../assets/projects/default-icons/Red-Thread.svg';

import {
  BackButton,
  Breadcrumbs,
  ChevronLabelText,
  Container,
  Controls,
  CreatedBy,
  CreatedByText,
  CreditInfo,
  CtaButton,
  CtaInfo,
  CtaLabel,
  CtaOverlay,
  CustomTemplateText,
  DotsButton,
  EmptyStateButton,
  EmptyStateText,
  FakeDropdown,
  FakeDropdownText,
  Filters,
  IconOption,
  IconWrapper,
  IconsDropdown,
  InputContainer,
  InputWrapper,
  Label,
  LeftSide,
  MainContent,
  MenuPanel,
  MenuPanelItem,
  OptionWrapper,
  Options,
  OutputResult,
  ProjectIcon,
  RightSide,
  RightSideWrapper,
  StartWithOutline,
  StartWithOutlineIcon,
  TemplateDescription,
  TemplateDetails,
  TemplateDetailsContainer,
  TemplateDetailsWrapper,
  TemplateIcon,
  TemplateInfo,
  TemplateInfoContainer,
  TemplateName,
  Title,
  Toolbar,
  UpgradeFeatrueItem,
  UpgradeFeatureList,
  UpgradeFeatureText,
  UpgradeIconWrapper,
  UpgradeSubtitle,
  UpgradeTitle,
} from './styles';
import ChooseDataModal from '../../Components/ChooseDataModal';
import { defaultProjectIcons } from '../../Consts/projectIcons';
import { InsightGenerationContext } from '../../Context/InsightGenerationContext';
import { TModalEntity } from '../../Components/ChooseDataModal/ChooseDataModal';
import {
  MusicNoteIcon,
  VideoCameraIcon,
  DocumentTextIcon,
  DocumentIcon,
} from '@heroicons/react/outline';
import {
  SummaryGenerationContext,
  SummaryGenerationContextProvider,
} from '../../Context/SummaryGenerationContext';
import { PureTipTapEditor } from '../../Components/TipTapEditor/TipTapEditor';
import Spinner from '../../Components/Spinner';
import { formatDistance } from 'date-fns';
import { insightPromptBoilerplate, summaryPromptBoilerplate } from './boilerplates';
import { CATEGORIES, PROMPT_ICONS } from '../../Consts/promptTemplates';
import Portal from '../../Components/PortalNew/PortalNew';
import { useOrganization } from '../../Hooks/useOrganization';
import { tiersArray as tiers } from '../../Consts/tiers-08-23';
import { useIntercom } from 'react-use-intercom';
import ModalWindow from '../../Components/ModalWindow';
import usePermissions from '../../Hooks/usePermissions';

type TContent = {
  type: string;
  content?: TContent[];
  text?: string;
};

type TDoc = {
  content: {
    [key: string]: TContent;
  };
};

type TTypeOption = {
  label: string;
  value: 'insight' | 'transcript';
};

const typeOptions: TTypeOption[] = [
  { label: 'Insight template', value: 'insight' },
  { label: 'Summary template', value: 'transcript' },
];

const lengthOptions = [
  { label: 'Short (500 words)', value: 'short' },
  { label: 'Medium (1000 words)', value: 'medium' },
  { label: 'Long (2000 words)', value: 'long' },
];

const upgradeFeatures = [
  'Custom AI Templates',
  'More AI credits & transcription hours',
  'Global tags & search',
  'Integrations and add-ons',
  '...and so much more',
];

const EditTemplate: FC = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const [loading, template] = useFetchPromptById(templateId);
  const [updatePrompt] = useUpdatePrompt();
  const [deletePrompt] = useDeletePrompt();
  const [prompt, setPrompt] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showMenuPanel, setShowMenuPanel] = useState(false);
  const [isCopyingTemplate, setIsCopyingTemplate] = useState(false);
  const [currentIcon, setCurrentIcon] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [currentTypeOption, setCurrentTypeOption] = useState<{
    label: string;
    value: 'insight' | 'transcript';
  }>(typeOptions[0]);
  const [currentLengthOption, setCurrentLengthOption] = useState(lengthOptions[0]);
  const [currentLanguage, setCurrentLanguage] = useState<{ label: string; value: string }>();
  const [isChooseDataModalOpen, setChooseDataModalOpen] = useState(false);
  const [chosenEntity, setChosenEntity] = useState<TModalEntity>();
  const [outputResult, setOutputResult] = useState('');
  const { generateInsightFromNotes, generating: insightGenerating } = useContext(
    InsightGenerationContext
  );
  const intercom = useIntercom();
  const { generateSummary, generating: summaryGenerating } = useContext(SummaryGenerationContext);
  const [createPrompt] = useCreatePrompt();
  const history = useHistory();
  const [, organization] = useOrganization();
  const currentTier = tiers.find((x) => x.id === organization.plan);
  const templateIconRef = useRef<HTMLDivElement>(null);
  const iconDropdownRef = useRef<HTMLDivElement>(null);
  const [showIconsDropdown, setShowIconsDropdown] = useState(false);
  const isPublicTemplate = !!template?.isPublic;
  const hasAccessToViewAndEdit = currentTier?.id === 'enterprise';
  const needsUpgrade = currentTier?.id === 'free' || currentTier?.id === 'pro';
  const { canEditAndPreviewPrompts } = usePermissions();

  useEffect(() => {
    if (!loading) {
      setPrompt(prompt || template?.mainPrompt || '');
      setTitle(title || template?.title);
      setDescription(description || template?.description);
      setCurrentTypeOption(
        currentTypeOption ||
          typeOptions.find((option) => option.value === template?.type) ||
          typeOptions[0]
      );
      setCurrentLanguage(
        currentLanguage || languageOptions.find((option) => option.label === template?.defaultLang)
      );
      setCurrentLengthOption(
        currentLengthOption ||
          lengthOptions.find((option) => option.value === template?.outputLength) ||
          lengthOptions[0]
      );
      setCurrentIcon(currentIcon || template?.defaultIcon || '');
    }
  }, [template, loading]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        templateIconRef.current &&
        iconDropdownRef.current &&
        !templateIconRef.current.contains(e.target as Node) &&
        !iconDropdownRef.current.contains(e.target as Node)
      ) {
        e.stopPropagation();
        setShowIconsDropdown(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      e.stopPropagation();
      setShowMenuPanel(false);
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleUpdatePrompt = useDebouncedCallback((patch: TUpdatePromptObject) => {
    updatePrompt(template, patch);
  }, 200);

  const handleUpdateTitle = (newTitle: string) => {
    setTitle(newTitle);
    handleUpdatePrompt({ title: newTitle });
  };

  const handleUpdateDescription = (newDescription: string) => {
    setDescription(newDescription);
    handleUpdatePrompt({ description: newDescription });
  };

  const handleUpdateMainPrompt = (newPrompt: string) => {
    setPrompt(newPrompt);
    handleUpdatePrompt({ mainPrompt: newPrompt });
  };

  const handleDeleteTemplate = () => {
    if (template) {
      deletePrompt(template.id);
    }
    history.goBack();
  };

  const handleUpdateType = (newTypeOption: TTypeOption) => {
    setCurrentTypeOption(newTypeOption);
    setChosenEntity(undefined);
    handleUpdatePrompt({ type: newTypeOption.value });
  };

  const handleUpdateLanguage = (newLanguageOption: { label: string; value: string }) => {
    setCurrentLanguage(newLanguageOption);
    handleUpdatePrompt({ defaultLang: newLanguageOption.label });
  };

  const handleUpdateLength = (newLengthOption: { label: string; value: string }) => {
    setCurrentLengthOption(newLengthOption);
    handleUpdatePrompt({ outputLength: newLengthOption.value });
  };

  const handleGenerateInsight = () => {
    if (!canEditAndPreviewPrompts || !chosenEntity?.id) return;
    if (organization.aiCredits < 1) {
      setShowUpgradeModal(true);
      return;
    }
    setOutputResult('');

    generateInsightFromNotes([], [], {
      outputSize: 900,
      templateId: template.id,
      dashboardId: chosenEntity.id,
      onFinish: (result) => {
        const converter = new Showdown.Converter();
        const convertedText = converter.makeHtml(result.content.trim());
        setOutputResult(convertedText);
      },
    });
  };

  const getTextContent = (block: TContent, sum: string) => {
    if (block.type === 'hardBreak') return '';
    if (block.type === 'text') {
      return block.text;
    }

    block.content?.forEach((item: any) => {
      if (item.type === 'text') {
        sum += item.text;
      } else {
        const res = getTextContent(item, sum);
        sum += res;
      }
    });
    return sum;
  };

  const getAllSpeakerContents = (content?: string) => {
    const doc: TDoc = JSON.parse(content || '{}');
    if (!doc.content) return [];
    const contents: string[] = [];
    for (const [key, block] of Object.entries(doc.content)) {
      if (block.type === 'speaker') {
        contents.push(getTextContent(block, '') || '');
      }
    }
    return contents.filter((content) => content !== '');
  };

  const getDocumentContent = (content?: string) => {
    const doc: TDoc = JSON.parse(content || '{}');
    if (!doc.content) return [];
    const contents: string[] = [];
    for (const [key, block] of Object.entries(doc.content)) {
      if (block.type !== 'image' && block.type !== 'ResizableImage') {
        contents.push(getTextContent(block, '') || '');
      }
    }
    return contents.filter((content) => content !== '');
  };

  const handleGenerateSummary = () => {
    if (!canEditAndPreviewPrompts || !chosenEntity?.id) return;
    if (organization.aiCredits < 1) {
      setShowUpgradeModal(true);
      return;
    }
    setOutputResult('');

    let content: string[] = [];
    if (chosenEntity?.type === 'transcript') {
      content = getAllSpeakerContents(chosenEntity?.content);
    } else {
      content = getDocumentContent(chosenEntity?.content);
    }

    if (!content) return;

    const generationSettings = {
      content,
      transcriptId: chosenEntity?.type === 'transcript' ? chosenEntity?.id : undefined,
      documentId: chosenEntity?.type === 'document' ? chosenEntity?.id : undefined,
      outputSize: 900,
      templateId: template.id,
    };
    generateSummary({
      ...generationSettings,
      onFinish: (result) => {
        const converter = new Showdown.Converter();
        const convertedText = converter.makeHtml(result.content.trim());
        setOutputResult(convertedText);
      },
    });
  };

  const handleUseSampleData = () => {
    if (currentTypeOption.value === 'insight') {
      handleUpdateMainPrompt(insightPromptBoilerplate);
    } else {
      handleUpdateMainPrompt(summaryPromptBoilerplate);
    }
  };

  const handleChangeIcon = (icon: string) => {
    setShowIconsDropdown(false);
    setCurrentIcon(icon);
    handleUpdatePrompt({ defaultIcon: icon });
  };

  const handleCopyTemplate = async () => {
    if (!template) return;
    const newTemplate = {
      title: `${template.title} (copy)`,
      description: template.description,
      type: template.type,
      defaultLang: template.defaultLang,
      outputLength: template.outputLength,
      defaultIcon: template.defaultIcon,
      mainPrompt: template.mainPrompt,
      isEnabled: false,
    };

    setIsCopyingTemplate(true);
    const newPrompt = await createPrompt({
      ...newTemplate,
    });
    setIsCopyingTemplate(false);
    history.push(`/templates/${newPrompt.id}`);
  };

  const handleContactUsClick = () => {
    intercom.showNewMessage('Hi, I want to upgrade to enterprise. ');
  };

  const handlePublishTemplate = () => {
    if (needsUpgrade) {
      setShowUpgradeModal(true);
      return;
    }
    handleUpdatePrompt({ isEnabled: true });
  };

  const getIconUrl = (cover?: string | null) => {
    const iconData = defaultProjectIcons.find((icon) => icon.name === cover);
    return iconData?.url || defaultProjectIcons[0].url;
  };

  const getFileIcon = (doc?: TModalEntity) => {
    if (!doc) return null;
    if (doc.type === 'transcript') {
      return doc.file ? (
        doc.file.type === 'audio' ? (
          <MusicNoteIcon className={'w-6 h-6 mr-2'} />
        ) : (
          <VideoCameraIcon className={'w-6 h-6 mr-2'} />
        )
      ) : (
        <DocumentTextIcon className={'w-6 h-6 mr-2'} />
      );
    }
    return <DocumentIcon className={'w-6 h-6 mr-2'} />;
  };

  const getEmptyStateText = () => {
    if (currentTypeOption.value === 'insight') {
      return (
        <>
          <EmptyStateButton
            onClick={() => canEditAndPreviewPrompts && setChooseDataModalOpen(true)}
          >
            Choose a project
          </EmptyStateButton>{' '}
          to preview your AI template
        </>
      );
    }

    return (
      <>
        <EmptyStateButton onClick={() => setChooseDataModalOpen(true)}>
          Choose a data file
        </EmptyStateButton>{' '}
        to preview your AI template.
      </>
    );
  };

  const getCategoryTitle = (category: string | null) => {
    if (category === 'custom' || !category) return 'Custom template';
    return CATEGORIES.find((item) => item.id === category)?.title;
  };

  const getIconsDropdownPosition = useMemo(() => {
    if (!templateIconRef.current) return { left: 0, top: 0 };
    const { left, top, height } = templateIconRef.current.getBoundingClientRect();
    return { left, top: height + top + 4 };
  }, [templateIconRef.current]);

  return (
    <Container>
      <Toolbar>
        <Breadcrumbs>
          <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
            AI Templates
          </span>{' '}
          / {template?.title || 'Current template'}
        </Breadcrumbs>
        <Controls>
          {template?.userByCreatedBy && (
            <CreatedBy>
              <IconWrapper>
                <Icon24.Clock />
              </IconWrapper>
              <CreatedByText>
                Draft updated{' '}
                {formatDistance(
                  template?.updatedAt ? new Date(template?.updatedAt) : new Date(),
                  new Date(),
                  { addSuffix: true }
                )}{' '}
                by
              </CreatedByText>
              <IconWrapper>
                <Avatar user={template?.userByCreatedBy} />
              </IconWrapper>
              <CreatedByText>{template?.userByCreatedBy?.name}</CreatedByText>
            </CreatedBy>
          )}
          {!template?.isEnabled && canEditAndPreviewPrompts && (
            <Button type="primary" onClick={handlePublishTemplate}>
              Publish Template
            </Button>
          )}
          {canEditAndPreviewPrompts && (
            <DotsButton
              onClick={() => {
                setShowMenuPanel(!showMenuPanel);
              }}
            >
              <Icon24.Dots />

              <MenuPanel show={showMenuPanel}>
                {template?.isEnabled && !isPublicTemplate && hasAccessToViewAndEdit && (
                  <MenuPanelItem
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => handleUpdatePrompt({ isEnabled: false })}
                  >
                    Unpublish
                  </MenuPanelItem>
                )}

                <MenuPanelItem
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={handleCopyTemplate}
                >
                  Copy this template
                </MenuPanelItem>

                {!isPublicTemplate && (
                  <MenuPanelItem
                    itemColor="red"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={handleDeleteTemplate}
                  >
                    Delete
                  </MenuPanelItem>
                )}
              </MenuPanel>
            </DotsButton>
          )}
        </Controls>
      </Toolbar>

      <TemplateInfo>
        <BackButton onClick={() => history.goBack()}>
          <Icon24.ChevronBack />
        </BackButton>
        <TemplateInfoContainer>
          <TemplateDetailsWrapper>
            <CustomTemplateText>{getCategoryTitle(template?.category) || ''}</CustomTemplateText>
            <TemplateDetailsContainer>
              <TemplateIcon
                src={template?.icon || PROMPT_ICONS.get(currentIcon) || PromptDefaultIcon}
                ref={templateIconRef}
                onClick={() => canEditAndPreviewPrompts && setShowIconsDropdown(!showIconsDropdown)}
              />
              {templateIconRef.current && (
                <Portal wrapperId="templateIconDropdown">
                  <IconsDropdown
                    show={showIconsDropdown}
                    left={getIconsDropdownPosition.left}
                    top={getIconsDropdownPosition.top}
                    ref={iconDropdownRef}
                  >
                    {Array.from(PROMPT_ICONS, ([name, icon]) => ({ name, icon })).map(
                      ({ name, icon }) => (
                        <IconOption key={name} src={icon} onClick={() => handleChangeIcon(name)} />
                      )
                    )}
                  </IconsDropdown>
                </Portal>
              )}
              <TemplateDetails>
                <TemplateName
                  value={title}
                  onChange={(e) => handleUpdateTitle(e.target.value)}
                  disabled={!canEditAndPreviewPrompts}
                />
                <TemplateDescription
                  value={description}
                  onChange={(e) => handleUpdateDescription(e.target.value)}
                  placeholder="Describe this template"
                  disabled={!canEditAndPreviewPrompts}
                />
              </TemplateDetails>
            </TemplateDetailsContainer>
          </TemplateDetailsWrapper>

          {!isPublicTemplate && (
            <Filters>
              <Label>Type</Label>
              <Dropdown
                options={typeOptions}
                currentOption={currentTypeOption}
                onChange={(option) => handleUpdateType(option as TTypeOption)}
                menuWidth={200}
                dontCutTitle
                disabled={!canEditAndPreviewPrompts}
              />
            </Filters>
          )}
        </TemplateInfoContainer>
      </TemplateInfo>

      <MainContent>
        <LeftSide>
          <InputWrapper>
            <Title>Prompt</Title>
            <InputContainer>
              <Input
                value={prompt}
                onChange={(e) => handleUpdateMainPrompt(e.target.value)}
                textarea
                customBottomPadding={30}
                customHeight={480}
                disabled={isPublicTemplate || !canEditAndPreviewPrompts}
                disableScroll={isPublicTemplate && !hasAccessToViewAndEdit}
              />
              {!prompt && (
                <StartWithOutline onClick={handleUseSampleData}>
                  <StartWithOutlineIcon>
                    <Icon24.Insight />
                  </StartWithOutlineIcon>
                  Start with an outline
                </StartWithOutline>
              )}
              {isPublicTemplate && !hasAccessToViewAndEdit && (
                <CtaOverlay>
                  <CtaLabel>
                    <Icon24.Badge />
                    <ChevronLabelText>Enterprise only</ChevronLabelText>
                  </CtaLabel>
                  <CtaInfo>
                    Upgrade to Enterprise to view and edit prompts that power our library of AI
                    templates.
                  </CtaInfo>
                  <CtaButton>
                    <Button type="primary" onClick={handleContactUsClick}>
                      Contact us
                    </Button>
                  </CtaButton>
                </CtaOverlay>
              )}
            </InputContainer>
          </InputWrapper>
          <OptionWrapper>
            <Label>Data</Label>
            <FakeDropdown onClick={() => canEditAndPreviewPrompts && setChooseDataModalOpen(true)}>
              {currentTypeOption.value === 'insight' ? (
                <ProjectIcon src={getIconUrl(chosenEntity?.cover)} />
              ) : (
                getFileIcon(chosenEntity)
              )}
              <FakeDropdownText isPlaceholder={!chosenEntity}>
                {chosenEntity
                  ? chosenEntity.name || 'Untitled project'
                  : currentTypeOption.value === 'insight'
                  ? 'Choose a project...'
                  : 'Choose a file...'}
              </FakeDropdownText>
            </FakeDropdown>
          </OptionWrapper>

          <Options>
            {!isPublicTemplate && (
              <OptionWrapper>
                <Label>Length</Label>
                <Dropdown
                  options={lengthOptions}
                  currentOption={currentLengthOption}
                  onChange={handleUpdateLength}
                  align="top"
                  menuWidth={200}
                  dontCutTitle
                  disabled={!canEditAndPreviewPrompts}
                />
              </OptionWrapper>
            )}
            {!isPublicTemplate && (
              <OptionWrapper>
                <Label>Language</Label>
                <Dropdown
                  options={languageOptions}
                  currentOption={currentLanguage}
                  onChange={handleUpdateLanguage}
                  placeholder="Auto-detect"
                  menuWidth={200}
                  maxHeight={400}
                  align="top"
                  dontCutTitle
                  disabled={!canEditAndPreviewPrompts}
                />
              </OptionWrapper>
            )}
          </Options>
          <Button
            type="secondary"
            onClick={
              currentTypeOption.value === 'insight' ? handleGenerateInsight : handleGenerateSummary
            }
            disabled={insightGenerating || summaryGenerating}
          >
            Preview template
          </Button>
          <CreditInfo
            isRed={!organization.aiCredits}
            onClick={() => !organization.aiCredits && history.push('/settings/plans')}
          >
            {organization.aiCredits
              ? `This will use 1/${organization.aiCredits} credits`
              : 'You need 1 credit to preview. Upgrade for more.'}
          </CreditInfo>
        </LeftSide>
        <RightSide>
          <Title>Preview</Title>
          <OutputResult>
            {outputResult ? (
              <PureTipTapEditor content={outputResult} editable={false} />
            ) : insightGenerating || summaryGenerating ? (
              <RightSideWrapper>
                <Spinner size="large" />
              </RightSideWrapper>
            ) : (
              <RightSideWrapper>
                <EmptyStateText>{getEmptyStateText()}</EmptyStateText>
              </RightSideWrapper>
            )}
          </OutputResult>
        </RightSide>
      </MainContent>

      {isChooseDataModalOpen && (
        <ChooseDataModal
          type={currentTypeOption.value}
          onClose={() => setChooseDataModalOpen(false)}
          onChooseEntity={setChosenEntity}
        />
      )}

      {showUpgradeModal && (
        <ModalWindow
          headerText="Teams and Enterprise"
          confirmText="View plans"
          cancelText="Contact us"
          onConfirm={() => history.push('/settings/plans')}
          onCancel={() => {
            intercom.showNewMessage('Hi, I want to upgrade for more features. ');
            setShowUpgradeModal(false);
          }}
          onClose={() => setShowUpgradeModal(false)}
        >
          <>
            <UpgradeTitle>Upgrade for more features</UpgradeTitle>
            <UpgradeSubtitle>
              You need to upgrade to Teams or Enterprise to preview and publish custom AI templates
              to your workspace.
            </UpgradeSubtitle>
            <UpgradeFeatureList>
              {upgradeFeatures.map((feature, index) => (
                <UpgradeFeatrueItem key={index}>
                  <UpgradeIconWrapper>
                    <Icon24.Chevron />
                  </UpgradeIconWrapper>
                  <UpgradeFeatureText>{feature}</UpgradeFeatureText>
                </UpgradeFeatrueItem>
              ))}
            </UpgradeFeatureList>
          </>
        </ModalWindow>
      )}
    </Container>
  );
};

const EditTemplateWrapper: FC = () => {
  return (
    <SummaryGenerationContextProvider>
      <EditTemplate />
    </SummaryGenerationContextProvider>
  );
};

export default EditTemplateWrapper;
