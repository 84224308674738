import React from 'react';

export default function DataIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.8023 15.1111H10.3579M22.8023 15.1111C23.7842 15.1111 24.5801 15.907 24.5801 16.8889V22.2222C24.5801 23.2041 23.7842 24 22.8023 24H10.3579C9.37602 24 8.58008 23.2041 8.58008 22.2222V16.8889C8.58008 15.907 9.37602 15.1111 10.3579 15.1111M22.8023 15.1111V13.3333C22.8023 12.3515 22.0064 11.5556 21.0245 11.5556M10.3579 15.1111V13.3333C10.3579 12.3515 11.1538 11.5556 12.1356 11.5556M21.0245 11.5556V9.77778C21.0245 8.79594 20.2286 8 19.2467 8H13.9134C12.9316 8 12.1356 8.79594 12.1356 9.77778V11.5556M21.0245 11.5556H12.1356"
        stroke="#1D1029"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
