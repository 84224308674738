import React, { PropsWithChildren } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FolderIcon,
  PlusIcon,
  DotsHorizontalIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { DropDownMenu } from '../DropDownMenu';
import { Menu } from '@headlessui/react';
import { MenuRow } from '../TableSimple';
import EditableText from './EditableText';

export type TFolder = {
  id: string;
  name: string;
};

const Folder = ({
  folder,
  defaultItemTitle,
  children,
  onCreateItem,
  onRenameFolder,
  onDeleteFolder,
  itemsCount,
}: PropsWithChildren<{
  folder: TFolder;
  defaultItemTitle: string;
  onCreateItem?: ((folderId: string) => void | Promise<void>) | null;
  onRenameFolder?: ((folderId: string, name: string) => void | Promise<void>) | null;
  onDeleteFolder?: ((folderId: string) => void | Promise<void>) | null;
  itemsCount?: number;
}>): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `${defaultItemTitle} folder ${folder.id}`,
    false
  );

  return (
    <div>
      <div className="flex justify-between align-middle p-1">
        <div className="flex items-center">
          <div className="p-2">
            <FolderIcon className={'w-5 h-5'} />
          </div>
          <div className="cursor-poiter">
            <EditableText
              text={folder.name}
              onSave={onRenameFolder ? (text: string) => onRenameFolder(folder.id, text) : null}
            />
          </div>
        </div>

        <div className="p-2 cursor-pointer flex items-center flex-row">
          <div className="pr-3" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? (
              <ChevronDownIcon className={'w-4 h-4'} />
            ) : (
              <ChevronUpIcon className={'w-4 h-4'} />
            )}
          </div>
          <div>
            <DropDownMenu icon={<DotsHorizontalIcon className={'mx-2 w-4 h-4'} />}>
              {onDeleteFolder && (
                <Menu.Item>
                  <MenuRow
                    disabled={(itemsCount || 0) > 0}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDeleteFolder(folder.id);
                    }}
                  >
                    <TrashIcon className="mr-3 h-5 w-5" />
                    Delete
                  </MenuRow>
                </Menu.Item>
              )}
            </DropDownMenu>
          </div>
        </div>
      </div>
      <div
        style={
          isCollapsed
            ? {
                transition: 'max-height 0.2s ease-out',
                maxHeight: '0px',
                overflow: 'hidden',
              }
            : {
                transition: 'max-height 0.3s ease-in',
                maxHeight: itemsCount ? `${itemsCount * 50 + 100}px` : '800px',
                overflow: 'visible',
                animation: '1s delay-overflow',
              }
        }
      >
        {children}
        {onCreateItem && (
          <div className="flex">
            <div
              className="flex p-2 items-center cursor-pointer text-primary-purple-light-60"
              onClick={() => {
                onCreateItem(folder.id);
              }}
            >
              <div>
                <PlusIcon className={'w-5 h-5'} />
              </div>
              <div className="ml-1">New {defaultItemTitle}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Folder;
