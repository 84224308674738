import React, { useContext, useEffect, useRef, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { Icon16 } from '../../Icons/Icon';
import { isDocx, isTxt, isVideoOrAudio } from '../../Utils/fileTypes';
import { useLocalStorage } from 'usehooks-ts';
import { Overlay, Container, Header, HeaderTitle, CloseButton, Wrapper } from './styles';
import useUpload from '../../Hooks/useUpload';
import languageOptions from '../../Consts/languageOptions';
import { UploadFilesContext } from '../../Context/UploadFilesContext';
import AITemplates from '../AITemplates';
import { TPrompt } from '../../Hooks/usePrompts';
import { getZoomPublishableUrl } from '../../Utils/zoomUtils';
import { FETCH_INTEGRATIONS } from '../../GraphQL/queries';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { AUTHORIZE_ZOOM } from '../../GraphQL/mutations';
import Loader from '../Loader';

import { CloudFile } from '../../Models';
import ZoomCloudRecordingsListView from '../../Components/ZoomCloudRecordingsListView';
import useFeatureFlags, { featureNameGoogle, featureNameOneDrive } from '../../Hooks/useFeatureFlags';
import FileUploader from '../FileUploader';
import { ProjectChecklistContext } from '../../Context/ProjectChecklistContext';

interface UploadFileModalProps {
  isOpen: boolean;
  dashboardId: string;
  onClose(): void;
  onUpload(file: File): void;
}

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  isOpen,
  dashboardId,
  onClose,
  onUpload,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { data, loading, refetch } = useQuery(FETCH_INTEGRATIONS);
  const [authorizeZoom] = useMutation(AUTHORIZE_ZOOM);
  const history = useHistory();
  const { user } = useAuth0();
  const [currentStep, setCurrentStep] = useState<'upload' | 'template' | 'zoom' | 'onedrive'>('upload');
  // const [dragActive, setDragActive] = useState(false);
  const [currentFiles, setCurrentFiles] = useState<File[] | null>(null);
  const [currentCloudFiles, setCurrentCloudFiles] = useState<CloudFile[]>([]);
  const [currentLanguage, setCurrentLanguage] = useLocalStorage<OptionTypeBase>(
    'dataTranscriptionLanguage',
    languageOptions[0]
  );
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const {
    removeUploadingFile,
    removeAllUploadingFiles,
    setFilesUploadingModalOpen,
    updateFileProgress,
    setFileUploadingStatus,
    uploadFile,
  } = useContext(UploadFilesContext);
  const { markSummaryAdded } = useContext(ProjectChecklistContext);

  const {
    handleFileUpload,
    handleTranscriptUpload,
    handleCloudFileUpload,
    handleTxtUpload,
    handleDocxUpload,
  } = useUpload();

  const [maxHeight, setMaxHeight] = useState(0);
  const { isFeatureEnabled } = useFeatureFlags();
  const zoomEnabled = isFeatureEnabled('zoom');
  const googleDriveEnabled = isFeatureEnabled(featureNameGoogle);
  const oneDriveEnabled = isFeatureEnabled(featureNameOneDrive);

  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const REDIRECT_URI =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '') +
    `/projects/${dashboardId}/data`;

  const zoomConnected = data?.integrations?.find(
    (x: any) => x.type === 'zoom' && x.createdBy === user?.['https://notably.ai/claims/user_id']
  );

  useEffect(() => {
    if (code) {
      authorizeZoom({
        variables: {
          code,
          redirectUri: REDIRECT_URI,
        },
      })
        .then(refetch)
        .finally(() => {
          history.replace(`/projects/${dashboardId}/data`);
        });
    }
  }, [code]);

  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(
        window.innerHeight - 240 > 600 ? window.innerHeight - 240 : window.innerHeight - 120
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFilesUploadingModalOpen(true);
      document.body.style.overflow = 'hidden';
      localStorage.setItem('isDataUplodaModalOpen', 'true');
    } else {
      setCurrentStep('upload');
      setFilesUploadingModalOpen(false);
      document.body.style.overflow = 'visible';
      localStorage.setItem('isDataUplodaModalOpen', 'false');
    }
  }, [isOpen, setFilesUploadingModalOpen]);

  const openZoomLogin = () => {
    window.location.href = getZoomPublishableUrl(REDIRECT_URI);
  };

  const handleZoomButtonClick = (isZoomConnected: boolean) => {
    if (isZoomConnected) {
      setCurrentStep('zoom');
    } else {
      setFilesUploadingModalOpen(false);
      openZoomLogin();
    }
  };

  const handleOneDriveButtonClick = () => {
    setCurrentStep('onedrive');
  };

  const runFakeProgress = (fileName: string, callback: () => void) => {
    let currentProgress = 0;
    const interval = setInterval(function () {
      updateFileProgress(fileName, currentProgress / 100);
      currentProgress++;

      if (currentProgress > 100) {
        clearInterval(interval);
        callback();
      }
    }, 10);
  };

  const resetModal = () => {
    setCurrentFiles(null);
    setCurrentCloudFiles([]);
    setIsUploadingFiles(false);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleUpload = async (template?: TPrompt | null) => {
    if (!currentFiles?.length && !currentCloudFiles.length) return;
    setCurrentStep('upload');

    if (currentCloudFiles.length) {
      setIsUploadingFiles(true);
      for (const cloudFile of currentCloudFiles) {
        await handleCloudFileUpload(dashboardId, cloudFile, {
          language: currentLanguage?.value,
          aiTemplate: template,
        });
      }
      resetModal();
      onClose();
    }

    if (currentFiles?.length) {
      let uploadedFilesCounter = 0;

      currentFiles.forEach((currentFile) => {
        if (isVideoOrAudio(currentFile.name)) {
          uploadFile(
            dashboardId,
            currentFile,
            handleFileUpload,
            { language: currentLanguage?.value, aiTemplate: template?.id },
            (file) => {
              ++uploadedFilesCounter;
              onUpload(file);
              handleCancelFile(file.name);
              if (uploadedFilesCounter === currentFiles.length) {
                resetModal();
                onClose();
              }
            }
          );
          return;
        }

        setFileUploadingStatus(currentFile.name, true);
        runFakeProgress(currentFile.name, async () => {
          ++uploadedFilesCounter;
          handleCancelFile(currentFile.name);
          removeUploadingFile(currentFile.name);
          if (uploadedFilesCounter === currentFiles.length && inputRef.current) {
            resetModal();
            onClose();
          }

          if (isDocx(currentFile.name)) {
            handleDocxUpload(dashboardId, currentFile, {
              redirectAfterUpload: currentFiles.length === 1,
              template: template,
            });
            resetModal();
            onClose();
            return;
          }

          if (isTxt(currentFile.name)) {
            handleTxtUpload(dashboardId, currentFile, {
              redirectAfterUpload: currentFiles.length === 1,
              template: template,
            });
            resetModal();
            onClose();
            return;
          }

          handleTranscriptUpload(dashboardId, currentFile, {
            redirectAfterUpload: currentFiles.length === 1,
            template: template,
          });

          if (template) {
            markSummaryAdded();
          }

          resetModal();
          onClose();
        });
      });
    }
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  const handleCancelFile = (fileName?: string) => {
    if (!currentFiles?.length) return;

    if (fileName) {
      removeUploadingFile(fileName);
      setCurrentFiles((currentFiles) =>
        currentFiles ? currentFiles.filter((currentFile) => currentFile.name !== fileName) : []
      );
    } else {
      setCurrentFiles(null);
      removeAllUploadingFiles();
    }
  };

  const handleCancelCloudFile = (fileName?: string) => {
    if (!fileName) return;

    setCurrentCloudFiles((currentFiles) =>
      currentFiles.filter((currentFile) => currentFile.name !== fileName)
    );
  };

  if (loading || code) {
    return <Loader />;
  }
  return (
    <Wrapper isOpen={isOpen}>
      <Overlay isOpen={isOpen} onClick={handleClose} />
      <Container
        isOpen={isOpen}
        isTemplatesStep={currentStep === 'template'}
        isZoomStep={currentStep === 'zoom'}
        maxHeight={maxHeight}
      >
        {currentStep === 'upload' && (
          <>
            <Header>
              <HeaderTitle>Upload data</HeaderTitle>
              <CloseButton onClick={handleClose}>
                <Icon16.CloseBig />
              </CloseButton>
            </Header>

            <FileUploader
              isGoogleDriveUploading={isUploadingFiles}
              currentFiles={currentFiles}
              onCurrentFilesChange={setCurrentFiles}
              currentCloudFiles={currentCloudFiles}
              currentLanguage={currentLanguage}
              onCurrentCloudFilesChange={setCurrentCloudFiles}
              onZoomButtonClick={() => (zoomEnabled ? handleZoomButtonClick(zoomConnected) : null)}
              onOneDriveButtonClick={() => (oneDriveEnabled ? handleOneDriveButtonClick() : null)}
              onCurrentLanguageChange={setCurrentLanguage}
              onCancelFile={handleCancelFile}
              onCancelCloudFile={handleCancelCloudFile}
              zoomEnabled={zoomEnabled}
              googleDriveEnabled={googleDriveEnabled}
              onAccept={() => setCurrentStep('template')}
            />
          </>
        )}

        {currentStep === 'template' && (
          <AITemplates
            type="transcript"
            confirmButtonText="Create summary"
            cancelButtonText="Skip this"
            onConfirm={handleUpload}
            onCancel={() => handleUpload()}
          />
        )}

        {currentStep === 'zoom' && (
          <ZoomCloudRecordingsListView
            onClose={onClose}
            onCancel={() => setCurrentStep('upload')}
            onContinue={(files: CloudFile[], language) => {
              setCurrentLanguage(language);
              setCurrentCloudFiles(files);
              setCurrentStep('template');
            }}
          />
        )}

        {/* {currentStep === 'onedrive' && <OneDriveModal />} */}
      </Container>
    </Wrapper>
  );
};

export default UploadFileModal;
