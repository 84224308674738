import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 16px 0;
`;

export const Text = styled.div`
  padding: 12px 20px;
  font-weight: 500;
  cursor: pointer;
`;
