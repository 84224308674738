import styled from 'styled-components';

const hexToRgbA = (hex: string, opacity: number) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `, ${opacity})`;
  }
  return 'rgba(133, 95, 168, 0.2)';
};

export const ContentWrapper = styled.span<{ color?: string; isActive: boolean }>`
  position: relative;
  padding: 1px 0 2px;
  ${({ color, isActive }) => {
    if (isActive) {
      return `
        background-color: ${color ? hexToRgbA(color, 0.7) : 'rgba(133, 95, 168, 0.4)'};
        box-shadow: inset 0 -2px 0 ${color ? color : 'rgba(133, 95, 168, 1)'};
      `;
    } else {
      return `background-color: ${color ? hexToRgbA(color, 0.5) : 'rgba(133, 95, 168, 0.2)'};`;
    }
  }}
`;

export const MenuWrapper = styled.span`
  position: relative;
`;

export const MenuContainer = styled.div<{ top: number; left: number }>`
  position: absolute;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top - 8}px;
  display: flex;
  align-items: center;
  z-index: 2;
`;
