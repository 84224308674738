import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Container = styled.div`
  border-right: 1px solid #e5e7eb;
  width: 280px;

  position: sticky;
  top: 49px;
  height: calc(100vh - 49px);
  flex-shrink: 0;

  ${breakpoint.Mobile`
    width: 48px;
  `}
`;

export const Link = styled(NavLink)`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }

  &.active {
    background-color: rgba(133, 95, 168, 0.1);
  }

  ${breakpoint.Mobile`
    padding: 2px;
    justify-content: center;
    margin-bottom: 4px;
  `}
`;

export const LinkIcon = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint.Mobile`
    margin: 0;
  `}
`;

export const TagsLink = styled(NavLink)`
  margin-bottom: 4px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  border-radius: 3px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f2eff6;
  }

  &.${(props) => props.activeClassName} {
    background-color: #f2eff6;
  }
`;

export const TagsLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;

  padding: ${({ isOpen }) => (isOpen ? ' 8px 0 0 32px' : '0 0 0 32px')};
  max-height: ${({ isOpen }) => (isOpen ? '92px' : '0px')};
  transition: max-height 0.16s ease-in-out, padding 0.12s ease-in-out;
  overflow: hidden;
`;

export const LinksContainer = styled.nav`
  padding: 0 2px;
  margin-top: 8px;
  background-color: #ffffff;
`;
