import { Reference, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  CREATE_FILE,
  CREATE_SIGNED_S3_URL,
  DELETE_FILE,
  IMPORT_FILE,
  UPDATE_FILE,
} from '../GraphQL/mutations';
import { ID } from '../Models';
import { CURRENT_ORG, FETCH_ALL_FILES, FETCH_FILE, FETCH_FILES } from '../GraphQL/queries';
import axios, { AxiosError, CancelToken } from 'axios';
import { captureException } from '@sentry/react';

const extToLowerCase = (str: string): string => {
  const tmp = str.split('.');
  tmp[tmp.length - 1] = tmp[tmp.length - 1].toLowerCase();
  return tmp.join('.');
};

export const useFetchFile = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_FILE, {
    variables: { id },
  });
  return [loading, data?.file];
};

export default function useFiles() {
  const [mutation] = useMutation(CREATE_SIGNED_S3_URL);
  const [createFileMutation] = useMutation(CREATE_FILE);
  const [importFileMutation] = useMutation(IMPORT_FILE);
  const [updateFileMutation] = useMutation(UPDATE_FILE);
  const [deleteFileMutation] = useMutation(DELETE_FILE);
  const [fetchFilesQuery, { loading, data, called, refetch }] = useLazyQuery(FETCH_FILES);
  const [
    fetchAllFilesQuery,
    { loading: allFilesLoading, data: allFilesData, called: allFilesCalled },
  ] = useLazyQuery(FETCH_ALL_FILES);
  const [fetchFileQuery, { loading: loadingOne, data: dataOne, called: calledOne }] = useLazyQuery(
    FETCH_FILE
  );

  async function uploadFileToS3(
    file: File,
    options: {
      cancelToken?: CancelToken;
      onProgress?: (progressEvent: any) => void;
      isPublic?: boolean;
    } = {}
  ) {
    const { cancelToken, onProgress, isPublic = false } = options;

    const {
      data: {
        createSignedS3Url: { uploadUrl },
      },
    } = await mutation({
      variables: {
        fileName: extToLowerCase(file.name),
        fileType: file.type,
        isPublic,
      },
    });

    const { pathname, hostname } = new URL(uploadUrl);

    try {
      const response = await axios.put(uploadUrl, file, {
        headers: {
          'content-type': file.type,
        },
        onUploadProgress: onProgress,
        cancelToken: cancelToken,
      });
      const ok = response.status < 400 && response.status >= 200;
      if (ok) {
        return {
          ok,
          path: `s3://${hostname.split('.')[0]}${pathname}`,
          url: `https://${hostname}${pathname}`,
        };
      }
      const err = new Error('Non 2xx response from file upload network call');
      (err as AxiosError).response = response;
      throw err;
    } catch (err) {
      captureException(err, (scope) => {
        scope.setExtra('file', file);
        scope.setExtra('response', err.response);
        return scope;
      });
      console.error('File upload network call error: ', err, 'Response:', err.response);
      return { ok: false, path: '', url: '' };
    }
  }

  async function createFile(dashboardId: ID, input: any) {
    const {
      data: {
        createFile: { file },
      },
    } = await createFileMutation({
      variables: {
        input: {
          ...input,
          dashboardId,
        },
      },
      refetchQueries: [
        {
          query: FETCH_FILES,
          variables: {
            dashboardId,
          },
        },
        {
          query: CURRENT_ORG,
        },
      ],
    });
    return file;
  }

  async function updateFile(id: ID, input: any) {
    await updateFileMutation({
      variables: {
        id,
        input,
      },
    });
  }

  async function importFile(dashboardId: ID, name: string, importUrl: string, status: string) {
    const { data } = await importFileMutation({
      variables: {
        dashboardId,
        name,
        importUrl,
        status,
      },
      refetchQueries: [{ query: FETCH_FILES, variables: { dashboardId } }, { query: CURRENT_ORG }],
    });

    return data.importFile;
  }

  function fetchFiles(dashboardId: ID) {
    if (!called) {
      fetchFilesQuery({
        variables: {
          dashboardId,
        },
      });
    }
    return [loading, data?.files, refetch];
  }

  function fetchAllFiles() {
    if (!allFilesCalled) {
      fetchAllFilesQuery();
    }
    return [allFilesLoading, allFilesData?.files];
  }

  function fetchFile(id: ID) {
    if (!calledOne) {
      fetchFileQuery({
        variables: {
          id,
        },
      });
    }
    return [loadingOne, dataOne?.file];
  }

  async function deleteFile(id: ID) {
    await deleteFileMutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            files(existingRefs, { readField }) {
              return existingRefs.filter((ref: Reference) => id !== readField('id', ref));
            },
          },
        });
      },
    });
  }

  return {
    uploadFileToS3,
    fetchFile,
    createFile,
    updateFile,
    fetchFiles,
    fetchAllFiles,
    deleteFile,
    importFile,
  };
}
