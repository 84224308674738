import styled from 'styled-components';
import GenerateInsightEmptyStateImage from '../../../assets/empty-states/generateInsight.svg';

export const StepContainer = styled.div`
  width: 100%;
`;

export const PanelsContainer = styled.div`
  display: flex;
  border-top: 1px solid var(--Light-Purple-Gray, #d7d3dc);
  border-bottom: 1px solid var(--Light-Purple-Gray, #d7d3dc);
  min-height: 480px;
  max-height: 560px;
`;
export const LeftPanel = styled.div`
  padding: 20px;
`;
export const RightPanel = styled.div`
  border-left: 1px solid var(--Light-Purple-Gray, #d7d3dc);
  padding: 20px;
  flex: 1;
  overflow: scroll;
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 120px;
  height: 100%;
`;

export const EmptyStateText = styled.div`
  color: #3b2651;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  letter-spacing: 0.16px;
`;

export const EmptyStateAction = styled.span`
  text-decoration-line: underline;
  cursor: pointer;
`;
export const EmptyStateImage = styled.div`
  width: 72px;
  height: 72px;
  background-image: url(${GenerateInsightEmptyStateImage});
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
`;

export const StepButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Subtitle = styled.div`
  color: rgba(59, 38, 81, 0.6);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;

  padding: 0px 20px 20px 20px;
  margin-top: -8px;
`;

export const StepButtonIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: rgba(56, 33, 82, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  flex-shrink: 0;
  transition: background-color 0.2s ease;
`;

export const StepButton = styled.div<{ selected: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  border: 1px solid #d7d3dc;
  border-radius: 8px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    border: 1px solid transparent;

    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      bottom: -2px;
      right: -2px;
      border: 2px solid #382152;
      border-radius: 8px;
    }
  `};

  &:last-child {
    margin: 0;
  }
`;

export const ChevronIcon = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  right: -10px;
  top: -10px;
  color: #ffffff;
  background-color: #382152;
  z-index: 1;
`;

export const StepButtonBadge = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
`;

export const StepButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepButtonText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
`;

export const StepButtonSubtitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  max-width: 192px;
  color: rgba(59, 38, 81, 0.6);
`;

export const InputLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 5px;
`;
export const SelectWrapper = styled.div`
  max-width: 224px;
  margin-bottom: 20px;
`;

export const GenerationSettings = styled.div`
  /* margin-top: 20px;
  padding-top: 18px;
  border-top: 1px solid #d7d3dc; */
`;
export const GenerationSettingsSelector = styled.div`
  width: 220px;
  margin-bottom: 20px;
`;

export const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
`;

export const NextButton = styled.div<{ disabled: boolean }>`
  padding: 12px 10px;
  background-color: #382152;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    background-color: rgba(59, 38, 81, 0.4);
  `}
`;

export const CancelButton = styled.div`
  padding: 11px 10px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;
`;

export const SelectedTags = styled.div``;

export const SelectedTag = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const SelectedTagDeleteButton = styled.div`
  position: absolute;
  right: -7px;
  top: -7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #3b2651;
  color: #ffffff;
  cursor: pointer;
`;

export const SelectedTagIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
export const SelectedTagName = styled.div<{ color?: string }>`
  padding: 3px 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  background-color: ${({ color }) => (color ? color : '#d8c0f880')};
  border-radius: 3px;
  margin-right: 8px;
`;
export const SelectedTagCount = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;
