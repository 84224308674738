import React from 'react';
import StickyIcon from '../Icons/StickyIcon';
import TextIcon from '../Icons/TextIcon';
import { actionHash, shapeHash, ShapeTypes } from '../Consts/canvasActions';
import LineIcon from '../Icons/LineIcon';
import ShapesIcon from '../Icons/ShapesIcon';
import CaretIcon from '../Icons/CaretIcon';
import SquareShapeIcon from '../Icons/SquareShapeIcon';
import TriangleShapeIcon from '../Icons/TriangleShapeIcon';
import CircleShapeIcon from '../Icons/CircleShapeIcon';
import RhombusIcon from '../Icons/RhombusIcon';
import ImageIcon from '../Icons/ImageIcon';

export function CanvasToolbar({
  handleOnClick,
  canvasAction,
}: {
  handleOnClick: (input: string, optionalInput?: ShapeTypes) => void;
  canvasAction: string;
}) {
  return (
    <div
      className="absolute z-30 flex items-center left-1/2 -translate-x-2/4  bg-white shadow-md border-gray-300 border"
      style={{ bottom: '16px', borderRadius: '3px' }}
    >
      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => handleOnClick(actionHash.sticky)}
        className={
          ' hover:bg-gray-100 h-8 w-8 flex items-center justify-center z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (canvasAction === actionHash.sticky ? 'bg-gray-100' : '')
        }
      >
        <StickyIcon />
      </div>
      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => handleOnClick(actionHash.image)}
        className={
          ' hover:bg-gray-100 h-8 w-8 flex items-center justify-center z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (canvasAction === actionHash.image ? 'bg-gray-100' : '')
        }
      >
        <ImageIcon />
      </div>
      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => handleOnClick(actionHash.line)}
        className={
          ' hover:bg-gray-100 h-8 w-8 flex items-center justify-center z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (canvasAction === actionHash.line ? 'bg-gray-100' : '')
        }
      >
        <LineIcon />
      </div>
      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => handleOnClick(actionHash.text)}
        className={
          ' hover:bg-gray-100 h-8 w-8 p-2 flex items-center justify-center z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (canvasAction === actionHash.text ? 'bg-gray-100' : '')
        }
      >
        <TextIcon />
      </div>
      <div
        onClick={() => {
          handleOnClick(actionHash.shape);
        }}
        className={
          ' hover:bg-gray-100 h-8 p-2 flex items-center justify-center z-50 p-1 rounded-sm-md hover:cursor-pointer ' +
          (canvasAction === actionHash.shape ? 'bg-gray-100' : '')
        }
        style={{ width: '2.5rem' }}
      >
        {canvasAction === actionHash.shape && (
          <div
            className="absolute z-10 mt-1 p-2 bg-white border shadow-md"
            style={{ top: '-46px', borderRadius: '3px' }}
          >
            <div className="grid grid-cols-4" style={{ width: 'max-content', gap: '0.75rem' }}>
              <a
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClick(actionHash.shape, shapeHash.square as ShapeTypes);
                }}
              >
                <SquareShapeIcon />
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClick(actionHash.shape, shapeHash.triangle as ShapeTypes);
                }}
              >
                <TriangleShapeIcon />
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClick(actionHash.shape, shapeHash.circle as ShapeTypes);
                }}
              >
                <CircleShapeIcon />
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClick(actionHash.shape, shapeHash.rhombus as ShapeTypes);
                }}
              >
                <RhombusIcon />
              </a>
            </div>
          </div>
        )}

        <ShapesIcon />
        <span style={{ width: '4px' }}></span>
        <CaretIcon direction={canvasAction === actionHash.shape ? 'down' : 'up'} />
      </div>
    </div>
  );
}
