import { Checkbox } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { Icon16, Icon24 } from '../../Icons/Icon';
import {
  FolderContainer,
  FolderNameContainer,
  CollapseIcon,
  FolderIcon,
  FolderName,
  FolderList,
  MenuItemContainer,
  LogoWithLabel,
  ProjectLogo,
  MenuItemLabel,
  MenuItem,
  FolderNameWrapper,
  SelectedProjectsNumber,
} from './styles';
import { ProjectDropDownOption } from './ProjectsDropdown';
import { ID } from '../../Models';

interface DropdownFolderProps {
  folder: { folderId: ID; folderName: string; options: ProjectDropDownOption[] };
  currentOptions: ProjectDropDownOption[];
  onChangeFolderCheckbox: (folderId: ID, checked: boolean) => void;
  onChangeProjectCheckbox: (projectId: ID) => void;
}

const DropdownFolder: FC<DropdownFolderProps> = ({
  folder,
  currentOptions,
  onChangeFolderCheckbox,
  onChangeProjectCheckbox,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const allProjectsAreChecked = useMemo(() => {
    return folder.options.every((folderOption) =>
      currentOptions.find((option) => option.value === folderOption.value)
    );
  }, [folder, currentOptions]);

  const someProjectsAreChecked = useMemo(() => {
    return (
      !allProjectsAreChecked &&
      folder.options.some((folderOption) =>
        currentOptions.find((option) => option.value === folderOption.value)
      )
    );
  }, [folder, currentOptions, allProjectsAreChecked]);

  const numberOfCheckedProjects = useMemo(() => {
    return folder.options.filter((folderOption) =>
      currentOptions.find((option) => option.value === folderOption.value)
    ).length;
  }, [folder, currentOptions]);

  return (
    <FolderContainer>
      <FolderNameContainer>
        <CollapseIcon isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
          <Icon16.Arrow />
        </CollapseIcon>
        <FolderNameWrapper
          onClick={() => onChangeFolderCheckbox(folder.folderId, !allProjectsAreChecked)}
        >
          <FolderIcon>
            <Icon24.Folder />
          </FolderIcon>
          <FolderName>{folder.folderName}</FolderName>
          {isCollapsed && numberOfCheckedProjects > 0 && (
            <SelectedProjectsNumber>{numberOfCheckedProjects}</SelectedProjectsNumber>
          )}
        </FolderNameWrapper>
        <Checkbox
          icon={someProjectsAreChecked ? <Icon16.Dash /> : undefined}
          colorScheme="purple"
          isChecked={someProjectsAreChecked || allProjectsAreChecked}
          onChange={(e) => {
            e.stopPropagation();
            onChangeFolderCheckbox(folder.folderId, e.target.checked);
          }}
        />
      </FolderNameContainer>
      <FolderList isCollapsed={isCollapsed}>
        {folder.options.map((option) => (
          <MenuItem key={option.value} onClick={() => onChangeProjectCheckbox(option.value)}>
            <MenuItemContainer>
              <LogoWithLabel>
                {option.icon && <ProjectLogo src={option.icon} />}
                <MenuItemLabel withMargin>{option.label}</MenuItemLabel>
              </LogoWithLabel>
              <Checkbox
                isChecked={currentOptions.some(
                  (currentOption) => option.value === currentOption.value
                )}
                colorScheme="purple"
                onChange={(e) => onChangeProjectCheckbox(option.value)}
              />
            </MenuItemContainer>
          </MenuItem>
        ))}
      </FolderList>
    </FolderContainer>
  );
};

export default DropdownFolder;
