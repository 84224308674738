import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { SpeakersContext } from '../../Context/SpeakersContext';
import { Icon16, Icon24 } from '../../Icons/Icon';
import LetteredAvatar from '../LetteredAvatar';

import {
  ModalWrapper,
  Overlay,
  Container,
  Title,
  BottomControls,
  AddButton,
  CloseButton,
  Speakers,
  Speaker,
  LetterAvatarWrapper,
  SpeakerName,
  PencilIconWrapper,
  ChevronIconWrapper,
  SpeakerNameField,
  NewNameField,
} from './styles';

interface SpeakerNameModalProps {
  isOpen: boolean;
  currentSpeakerName: string;
  onClose(): void;
  onEditName(editingName: string, newName: string): void;
  onChangeName(newName: string): void;
}

const SpeakerNameModal: React.FC<SpeakerNameModalProps> = ({
  isOpen,
  currentSpeakerName,
  onClose,
  onEditName,
  onChangeName,
}) => {
  const { speakerNames } = useContext(SpeakersContext);
  const [editingName, setEditingName] = useState('');
  const [editedName, setEditedName] = useState('');
  const [newName, setNewName] = useState('');

  const resetFields = () => {
    setEditingName('');
    setEditedName('');
    setNewName('');
  };

  const saveEditingChanges = () => {
    if (editingName && editedName && editedName !== editingName) {
      onEditName(editingName, editedName);
      setEditedName('');
      setEditingName('');
    }
  };

  const handleChoose = (chosenSpeaker: string) => {
    if (chosenSpeaker === currentSpeakerName) {
      onClose();
      return;
    }

    onChangeName(chosenSpeaker);
    resetFields();
    onClose();
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleStartEditing = (e: MouseEvent<HTMLDivElement>, speakerName: string) => {
    e.stopPropagation();
    setEditingName(speakerName);
    setEditedName(editingName);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  return (
    <ModalWrapper isOpen={isOpen}>
      <Overlay isOpen={isOpen} onClick={handleClose} />
      <Container isOpen={isOpen}>
        <CloseButton onClick={handleClose}>
          <Icon24.Close />
        </CloseButton>

        <Title>Change speaker name</Title>

        <Speakers>
          {speakerNames.map((speakerName) => (
            <Speaker
              key={speakerName}
              isActive={currentSpeakerName === speakerName}
              hidePencil={editingName === speakerName}
              onClick={() => handleChoose(speakerName)}
            >
              <LetterAvatarWrapper>
                <LetteredAvatar name={speakerName} />
              </LetterAvatarWrapper>
              {editingName === speakerName ? (
                <SpeakerNameField
                  onChange={(el) => setEditedName(el.target.value)}
                  defaultValue={speakerName}
                  onClick={(e) => e.stopPropagation()}
                  onBlur={saveEditingChanges}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      e.preventDefault();
                      saveEditingChanges();
                    }
                  }}
                  autoFocus
                />
              ) : (
                <SpeakerName>{speakerName}</SpeakerName>
              )}
              <PencilIconWrapper onClick={(e) => handleStartEditing(e, speakerName)}>
                <Icon16.Pencil />
              </PencilIconWrapper>

              <ChevronIconWrapper>
                <Icon24.Chevron />
              </ChevronIconWrapper>
            </Speaker>
          ))}
        </Speakers>

        <BottomControls>
          <NewNameField
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Add new speaker"
            onKeyDown={(e) => {
              if (e.key == 'Enter') {
                e.preventDefault();
                handleChoose(newName);
              }
            }}
          />
          <AddButton onClick={() => handleChoose(newName)} disabled={!newName.length}>
            Add
          </AddButton>
        </BottomControls>
      </Container>
    </ModalWrapper>
  );
};

export default SpeakerNameModal;
