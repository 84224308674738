import React from 'react';

export default function BoldIcon() {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.7499 6.8C9.23953 6.50184 9.65273 6.09342 9.95657 5.60729C10.2604 5.12116 10.4465 4.57077 10.4999 4C10.5092 3.48388 10.4167 2.971 10.2277 2.49063C10.0387 2.01027 9.75697 1.57184 9.39852 1.20039C9.04007 0.828938 8.61195 0.531742 8.13862 0.325776C7.66529 0.119811 7.15602 0.00911107 6.6399 0H0.149902V14H7.1499C7.64105 13.9948 8.12637 13.8929 8.57813 13.7001C9.02989 13.5073 9.43925 13.2274 9.78283 12.8764C10.1264 12.5253 10.3975 12.1101 10.5806 11.6543C10.7637 11.1985 10.8552 10.7112 10.8499 10.22V10.1C10.8502 9.40711 10.6528 8.72852 10.2808 8.14395C9.90884 7.55939 9.37772 7.09315 8.7499 6.8V6.8ZM2.1499 2H6.3499C6.77607 1.98681 7.19609 2.10428 7.55358 2.33665C7.91107 2.56902 8.18894 2.90517 8.3499 3.3C8.51278 3.82779 8.4601 4.39859 8.20339 4.88765C7.94667 5.37672 7.5068 5.74428 6.9799 5.91C6.77528 5.96996 6.56313 6.00027 6.3499 6H2.1499V2ZM6.7499 12H2.1499V8H6.7499C7.17607 7.98681 7.59609 8.10428 7.95358 8.33665C8.31107 8.56902 8.58894 8.90517 8.7499 9.3C8.91278 9.82779 8.8601 10.3986 8.60339 10.8877C8.34668 11.3767 7.9068 11.7443 7.3799 11.91C7.17529 11.97 6.96313 12.0003 6.7499 12V12Z"
        fill="#3B2651"
      />
    </svg>
  );
}
