import { PublicClientApplication } from '@azure/msal-browser';

export const defaultRedirectURI =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '') +
  '/settings/integrations';

export const clientId = 'aca9c131-fc95-44da-b928-f13400056354';

// Used for OneDrive Business and SharePoint
const commonAuthority = 'https://login.microsoftonline.com/common/';

// Used for OneDrive Personal
const consumersAuthority = 'https://login.microsoftonline.com/consumers/';

export const defaultMSALInstance = new PublicClientApplication({
  auth: {
    clientId,
    authority: commonAuthority,
    redirectUri: defaultRedirectURI,
  },
});

export const consumerMSALInstance = new PublicClientApplication({
  auth: {
    clientId,
    authority: consumersAuthority,
    redirectUri: defaultRedirectURI,
  },
});

export interface GetTokenCommand {
  resource?: string;
  command?: string;
  type?: string;
  scopes?: string[];
  msalInstance?: PublicClientApplication;
}

export async function getToken(command: GetTokenCommand): Promise<string> {
  const app = command.msalInstance || defaultMSALInstance;
  await app.initialize();

  let accessToken = '';
  const authParams = {
    scopes: command.scopes || [`${combine(command.resource, '.default')}`],
  };

  try {
    // Check for existing token
    const resp = await app.acquireTokenSilent(authParams);
    accessToken = resp.accessToken;
  } catch (e) {
    // Fall back to login popup
    const resp = await app.loginPopup(authParams);
    app.setActiveAccount(resp.account);

    if (resp.idToken) {
      const resp2 = await app.acquireTokenSilent(authParams);
      accessToken = resp2.accessToken;
    } else {
      throw e;
    }
  }

  return accessToken;
}

function combine(...paths: (string | null | undefined)[]): string {
  return (
    paths
      .filter((path) => typeof path === 'string' && path !== null)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((path) => path!.replace(/^[\\|/]/, '').replace(/[\\|/]$/, ''))
      .join('/')
      .replace(/\\/g, '/')
  );
}
