import React from 'react';

const ImageIcon = (): JSX.Element => (
  <svg width="17" height="16" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.07129 17.4998L7.37675 11.1943C8.45068 10.1204 10.1919 10.1204 11.2658 11.1943L17.5713 17.4998M14.8213 14.7498L17.0018 12.5693C18.0756 11.4954 19.8169 11.4954 20.8908 12.5693L23.0713 14.7498M14.8213 6.49976H14.835M3.82129 22.9998H20.3213C21.8401 22.9998 23.0713 21.7686 23.0713 20.2498V3.74976C23.0713 2.23097 21.8401 0.999756 20.3213 0.999756H3.82129C2.30251 0.999756 1.07129 2.23097 1.07129 3.74976V20.2498C1.07129 21.7686 2.30251 22.9998 3.82129 22.9998Z"
      stroke="#3B2651"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImageIcon;
