import styled from 'styled-components';
import promptsCtaImage from '../../assets/illustrations/prompts-cta.png';

export const Container = styled.div`
  width: 100%;
  max-width: 730px;
  display: flex;
  justify-content: space-between;
`;

export const CtaButton = styled.div`
  width: 100%;
  max-width: 347px;
  border-radius: 12px;
  background-color: blue;
  padding: 40px 20px 0;
  background-image: url(${promptsCtaImage});
  background-size: cover;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const CtaTitle = styled.div`
  font-family: acumin-pro-wide;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #ffffff;
  max-width: 180px;
`;

export const LearnMoreContainer = styled.div`
  width: 100%;
  max-width: 347px;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
`;

export const LearnMoreTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(59, 38, 81, 0.4);
  margin-bottom: 10px;
`;

export const LearnMoreText = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const LearnMoreGuarantee = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: rgba(59, 38, 81, 0.4);
`;

export const LearnMoreButton = styled.div`
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  border: 1px solid #d7d3dc;
  border-radius: 4px;
`;

export const LearnMoreIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;
