import { TCreatedByUser, TReaction } from '../../Models';

export type TReactionCluster = {
  clusterId: string;
  users: TCreatedByUser[];
  reactions: TReaction[];
};

const getClustersFromReactions = (reactions: TReaction[], currentUser: any) => {
  let reactionsByCluster: TReactionCluster[] = [];

  reactions?.forEach((reaction: TReaction) => {
    const existingClusterIndex = reactionsByCluster.findIndex(
      (cluster) => cluster.clusterId === reaction.clusterId
    );

    if (existingClusterIndex === -1) {
      reactionsByCluster.push({
        clusterId: reaction.clusterId,
        users: [reaction.userByCreatedBy],
        reactions: [reaction],
      });
    } else {
      reactionsByCluster[existingClusterIndex].reactions.push(reaction);

      const userAlreadyExist = reactionsByCluster[existingClusterIndex].users.find(
        (user) => user.id === reaction.userByCreatedBy.id
      );
      if (!userAlreadyExist) {
        reactionsByCluster[existingClusterIndex].users.push(reaction.userByCreatedBy);
      }
    }
  });

  const currentUserClusterIndex = reactionsByCluster.findIndex(
    (cluster) =>
      cluster.users.find((user) => user.id === currentUser?.id) && cluster.users.length === 1
  );

  if (currentUserClusterIndex === -1) {
    reactionsByCluster.push({
      clusterId: Math.floor(Math.random() * Date.now()).toString(16),
      users: [currentUser],
      reactions: [],
    });
  } else {
    reactionsByCluster = reactionsByCluster
      .slice(0, currentUserClusterIndex)
      .concat(reactionsByCluster.slice(currentUserClusterIndex + 1))
      .concat(reactionsByCluster[currentUserClusterIndex]);
  }

  return reactionsByCluster;
};

export default getClustersFromReactions;
