import React from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import ImageIcon from '../../Icons/ImageIcon';

import { Menu } from '@headlessui/react';
import { DropDownMenu } from '../DropDownMenu';
import DotsIcon from '../../Icons/DotsIcon';
import { ImageStyles } from './ImageComponent';
import { backZIndex, frontZIndex } from '../../Consts/canvasZIndex';

export default function ImageMenu({
  onDelete,
  onUpdateImage,
  onUpdateStyles,
  styles,
}: {
  onDelete: () => void;
  onUpdateImage: () => void;
  styles: ImageStyles;
  onUpdateStyles: (styles: ImageStyles) => void;
}): JSX.Element {
  return (
    <div className={'-mt-12 z-100'}>
      <span className="relative z-0 inline-flex shadow-md rounded">
        <button
          onClick={onUpdateImage}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-gray-300 border rounded-l-md bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <ImageIcon />
        </button>
        <button
          onClick={onDelete}
          style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border-gray-300 border bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
        >
          <TrashIcon className={'h-5 w-5'} />
        </button>

        <DropDownMenu
          button={
            <Menu.Button
              style={{ width: '36px', display: 'flex', justifyContent: 'center' }}
              className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none h-full rounded-r-md"
            >
              <DotsIcon />
            </Menu.Button>
          }
        >
          {' '}
          <Menu.Item>
            <label
              onClick={() => {
                onUpdateStyles({ ...styles, zIndex: frontZIndex });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Bring to front
            </label>
          </Menu.Item>
          <Menu.Item>
            <label
              onClick={() => {
                onUpdateStyles({ ...styles, zIndex: backZIndex });
              }}
              className={
                'group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100 hover:cursor-pointer'
              }
            >
              Send to back
            </label>
          </Menu.Item>
        </DropDownMenu>
      </span>
    </div>
  );
}
