import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CommentsNumber = styled.div`
  margin: 0 5px;
  color: rgba(59, 38, 81, 0.8);
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
`;

export const CommentsContainer = styled.div`
  margin-bottom: 20px;
`;

export const InputContainer = styled.div``;

export const InputHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const InputUserName = styled.div`
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  margin-left: 5px;
`;

export const InputButtons = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 16px;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: opacity 0.15s ease 0s;

  ${(props) => {
    if (props.show) {
      return `
        opacity: 1;
        pointer-events: all;
      `;
    }
  }}
`;

export const ButtonWrapper = styled.div`
  margin-right: 5px;
`;
