import React, { SyntheticEvent, useRef, useEffect, useMemo } from 'react';

export default function VideoClip({
  src,
  offset,
  endTime,
  onSeek,
}: {
  src: string;
  offset?: number | null;
  endTime?: number | null;
  onSeek?: (s: number) => void | Promise<void>;
}): JSX.Element {
  const [stopOnce, setStopOnce] = React.useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);
  const stopOnceRef = useRef(stopOnce);
  stopOnceRef.current = stopOnce;

  const getPlayerCurrentTime = () => Math.floor(playerRef?.current?.currentTime || 0);

  const onVideoLoad = (e: SyntheticEvent<HTMLVideoElement>) => {
    if (offset) e.currentTarget.currentTime = offset;
  };

  useEffect(() => {
    if (playerRef.current && offset) playerRef.current.currentTime = offset;
  }, [offset]);

  useEffect(() => {
    if (playerRef.current && onSeek) {
      playerRef.current.addEventListener('seeking', (event) => {
        if (playerRef.current) onSeek(getPlayerCurrentTime());
      });
    }
  });

  const handleTimeUpdate = () => {
    if (
      !stopOnceRef.current &&
      playerRef.current &&
      endTime &&
      playerRef.current.currentTime >= endTime
    ) {
      playerRef.current.pause();
      setStopOnce(true);
    }
  };

  const memoizedVideo = useMemo(() => {
    return (
      <video
        className="my-0"
        onLoadedData={onVideoLoad}
        ref={playerRef}
        style={{
          width: '100%',
          zIndex: 3,
        }}
        controls
        onTimeUpdate={() => handleTimeUpdate()}
      >
        <source src={src} type="video/mp4" />
      </video>
    );
  }, [src]);

  return <div className={'bg-white'}>{memoizedVideo}</div>;
}
