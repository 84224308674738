import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Icon24 } from '../../Icons/Icon';
import { default as lightbulbIcon } from '../../assets/projects/default-icons/Idea.svg';

import {
  BackButton,
  Breadcrumbs,
  BreadcrumbsIcon,
  BreadcrumbsItem,
  Container,
  Details,
  DetailsRow,
  DotsButton,
  EditorContainer,
  Header,
  Label,
  LeftSide,
  MenuPanel,
  MenuPanelItem,
  NameInput,
  ProjectIcon,
  RightSide,
  UserIcon,
} from './styles';
import ParticipantPicker from '../../Components/ParticipantPicker';
import { PureTipTapEditor } from '../../Components/TipTapEditor/TipTapEditor';
import usePermissions from '../../Hooks/usePermissions';
import {
  useCreateDocument,
  useCreateLightDocument,
  useDeleteLightDocument,
  useFetchLightDocument,
  useUpdateLightDocument,
} from '../../Hooks/useDocuments';
import { Loader } from '../../Components';
import { useDebouncedCallback } from 'use-debounce';
import Button from '../../Components/Button';
import ChooseDataModal from '../../Components/ChooseDataModal';
import { checkOrganizationLimits, useOrganization } from '../../Hooks/useOrganization';
import useAnalytics from '../../Hooks/useAnalytics';
import useBoards from '../../Hooks/useBoards';

const EditTemplate: FC = () => {
  const { noteId } = useParams<{ noteId: string }>();
  const history = useHistory();
  const { canEditDocuments } = usePermissions();
  const [loadingLightDoc, lightDoc] = useFetchLightDocument(noteId);
  const [, org] = useOrganization();
  const { analytics } = useAnalytics();
  const { createBoard } = useBoards();
  const [updateLightDocument] = useUpdateLightDocument();
  const [deleteLightDocument] = useDeleteLightDocument();
  const [createLightDocument] = useCreateLightDocument();
  const [createDocument] = useCreateDocument();
  const [editorContent, setEditorContent] = useState('');
  const [editorInitialized, setEditorInitialized] = useState(false);
  const [isChooseDataModalOpen, setChooseDataModalOpen] = useState(false);
  const [isChooseDataPopupOpen, setChooseDataPopupOpen] = useState(false);
  const [showMenuPanel, setShowMenuPanel] = useState(false);
  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  const dotButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (dotButtonRef.current && !dotButtonRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setShowMenuPanel(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!loadingLightDoc && document) {
      setEditorContent(JSON.parse(lightDoc.content));
      setEditorInitialized(true);
    }
  }, [loadingLightDoc, document]);

  async function updateName(newName: string) {
    updateLightDocument(noteId, {
      name: newName,
    });
  }

  async function updateParticipant(participantId: string | null) {
    if (!canEditDocuments) return;
    updateLightDocument(noteId, {
      participantId,
    });
  }

  const handleContentChange = useDebouncedCallback(async (content: any) => {
    await updateLightDocument(noteId, {
      content: JSON.stringify(content),
    });
  }, 500);

  const handleChooseProject = async (project: any) => {
    if (!lightDoc) return;
    const newDoc = await createDocument(project.id, {
      name: lightDoc.name,
      content: lightDoc.content,
      participantId: lightDoc.participantId,
    });
    deleteLightDocument(lightDoc.id);
    history.push(`/projects/${project.id}/data/docs/${newDoc.id}`);
  };

  const handleSaveToNewProject = async (projectName: string) => {
    if (!lightDoc) return;
    if (!checkOrganizationLimits(org, history, ['projects'])) {
      return;
    }

    const newProject = await createBoard(projectName);
    const newDoc = await createDocument(newProject.id, {
      name: lightDoc.name,
      content: lightDoc.content,
      participantId: lightDoc.participantId,
    });
    deleteLightDocument(lightDoc.id);
    analytics.sendEvent('CreateProject', { id: newProject.id });
    history.push(`/projects/${newProject.id}/data/docs/${newDoc.id}`);
  };

  const handleCopyDocument = async () => {
    const newDoc = await createLightDocument({
      content: lightDoc.content,
      name: lightDoc.name + ' copy',
      participantId: lightDoc.participantId,
      createdBy: lightDoc.createdBy,
    });
    history.push(`/notes/${newDoc.id}`);
  };

  const handleDeleteDocuemnt = async () => {
    if (confirm(`Are you sure you want to delete "${lightDoc.name}"?. This cannot be undone.`)) {
      deleteLightDocument(lightDoc.id);
      history.replace('/');
    }
  };

  const getPopupPosition = () => {
    if (!breadcrumbsRef.current) return { left: 0, top: 0 };
    const { left, top, height } = breadcrumbsRef.current.getBoundingClientRect();
    return { left: left - 34, top: top + height + 5 };
  };

  if (loadingLightDoc || !editorInitialized) {
    return <Loader />;
  }

  return (
    <Container>
      <Header>
        <LeftSide>
          <BackButton onClick={() => history.goBack()}>
            <Icon24.ChevronBack />
          </BackButton>
          <ProjectIcon src={lightbulbIcon} />
          <Breadcrumbs
            ref={breadcrumbsRef}
            onClick={() => setChooseDataPopupOpen(!isChooseDataPopupOpen)}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <BreadcrumbsItem>
              Save to project{' '}
              <BreadcrumbsIcon>
                <Icon24.ChevronDown />
              </BreadcrumbsIcon>
            </BreadcrumbsItem>
            /<BreadcrumbsItem>{lightDoc?.name || 'Untitled document'}</BreadcrumbsItem>
          </Breadcrumbs>
        </LeftSide>
        <RightSide>
          <Button type="secondary" size="md" onClick={() => setChooseDataModalOpen(true)}>
            Save to project
          </Button>

          <DotsButton
            ref={dotButtonRef}
            onClick={() => {
              setShowMenuPanel(!showMenuPanel);
            }}
          >
            <Icon24.Dots />

            <MenuPanel show={showMenuPanel}>
              <MenuPanelItem onMouseDown={(e) => e.stopPropagation()} onClick={handleCopyDocument}>
                Copy this document
              </MenuPanelItem>

              <MenuPanelItem
                itemColor="red"
                onMouseDown={(e) => e.stopPropagation()}
                onClick={handleDeleteDocuemnt}
              >
                Delete
              </MenuPanelItem>
            </MenuPanel>
          </DotsButton>
        </RightSide>
      </Header>

      <EditorContainer>
        <Details>
          <NameInput
            defaultValue={lightDoc?.name || ''}
            onBlur={(e) => updateName(e.target.value)}
            onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
            placeholder="Untitled document"
            autoFocus={!lightDoc?.name}
            readOnly={!canEditDocuments}
          />
          <DetailsRow>
            <UserIcon />
            <Label>Participant</Label>
            <ParticipantPicker
              participant={lightDoc?.participant}
              onChange={updateParticipant}
              readOnly={!canEditDocuments}
            />
          </DetailsRow>
        </Details>

        <PureTipTapEditor
          content={editorContent}
          onChange={handleContentChange}
          editable={canEditDocuments}
          placeholder="Start taking notes..."
          withToolbar
        />
      </EditorContainer>

      {isChooseDataModalOpen && (
        <ChooseDataModal
          type="insight"
          onClose={() => setChooseDataModalOpen(false)}
          onChooseEntity={handleChooseProject}
          onCreateNewProject={handleSaveToNewProject}
          title="Save to project"
          subtitile="Find an existing project to save this document to or continue with a new project."
        />
      )}

      {isChooseDataPopupOpen && (
        <ChooseDataModal
          type="insight"
          onClose={() => setChooseDataPopupOpen(false)}
          onChooseEntity={handleChooseProject}
          onCreateNewProject={handleSaveToNewProject}
          subtitile="Save this document to a project for analysis."
          minimized
          position={getPopupPosition()}
        />
      )}
    </Container>
  );
};

export default EditTemplate;
