import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const HiddenSpan = styled.span`
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
`;

export const Input = styled.input`
  font-size: 14px;
  outline: none;
  font-weight: 500;
  background: transparent;
  resize: none;
  /* padding-bottom: 2px;
  padding-top: 2px; */

  /* &:focus {
    border-bottom: 1px solid rgba(59, 38, 81, 0.2);
    margin-bottom: -1px;
  } */
`;
