export const actionHash = {
  default: 'default',
  sticky: 'sticky',
  text: 'text',
  line: 'line',
  shape: 'shape',
  image: 'image',
};

export const shapeHash = {
  square: 'square',
  triangle: 'triangle',
  circle: 'circle',
  rhombus: 'rhombus',
};

export type ShapeTypes = 'square' | 'triangle' | 'circle' | 'rhombus';
