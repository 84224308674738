import React, { useRef } from 'react';
import { NodeViewWrapper } from '@tiptap/react';

import { ImageWrapper, ResizePoint, ComponentWrapper, ButtonPanel, AlignButton } from './styles';

const ImageResizeComponent = (props: any) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const isEditable = props.extension.options.editable;

  const handleResizeClick = (mouseDownEvent: React.MouseEvent<HTMLImageElement>) => {
    const imageEl = imageRef.current;
    if (!imageEl) return;
    const startSize = { x: imageEl.clientWidth, y: imageEl.clientHeight };
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    const onMouseMove = (mouseMoveEvent: MouseEvent) => {
      props.updateAttributes({
        width: startSize.x - startPosition.x + mouseMoveEvent.pageX,
        height: startSize.y - startPosition.y + mouseMoveEvent.pageY,
      });
    };

    const onMouseUp = () => {
      document.body.removeEventListener('mousemove', onMouseMove);
    };

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, { once: true });
  };

  return (
    <NodeViewWrapper>
      <ComponentWrapper align={props.node.attrs.align}>
        <ImageWrapper selected={props.selected}>
          <img {...props.node.attrs} data-drag-handle={true} ref={imageRef} />
          {isEditable && (
            <ButtonPanel>
              <AlignButton
                onClick={() => props.updateAttributes({ align: 'left' })}
                selected={props.node.attrs.align === 'left'}
              >
                Left
              </AlignButton>
              <AlignButton
                onClick={() => props.updateAttributes({ align: 'center' })}
                selected={props.node.attrs.align === 'center'}
              >
                Center
              </AlignButton>
              <AlignButton
                onClick={() => props.updateAttributes({ align: 'right' })}
                selected={props.node.attrs.align === 'right'}
              >
                Right
              </AlignButton>
            </ButtonPanel>
          )}
          {isEditable && <ResizePoint onMouseDown={handleResizeClick} />}
        </ImageWrapper>
      </ComponentWrapper>
    </NodeViewWrapper>
  );
};

export default ImageResizeComponent;
