import React, { useRef, useState, KeyboardEvent, RefObject, useEffect } from 'react';
import { Text, Group, Transformer } from 'react-konva';
import TextMenu from './TextMenu';
import * as KonvaUtils from 'react-konva-utils';
import './styles.css';
import TextareaAutosize from 'react-textarea-autosize';

import Konva from 'konva';
import KonvaEventObject = Konva.KonvaEventObject;

export const DEFAULT_STYLES = {
  fontSize: '16',
  fontStyleBold: false,
  fontStyleItalic: false,
  textDecoration: '',
  align: 'left',
  fillColor: '#3B2651',
  borderColor: undefined,
  strokeWidth: '1',
};

export type TextStyles = {
  fontSize: string;
  fontStyleBold: boolean;
  fontStyleItalic: boolean;
  textDecoration: string;
  align: string;
  fillColor: string;
  borderColor: undefined | string;
  width?: number;
  zIndex?: number;
};

interface Props {
  id: string;
  x: number;
  y: number;
  text: string;
  styles: TextStyles;
  onUpdate: (updates: {
    text?: string;
    styles?: TextStyles;
    x?: number;
    y?: number;
  }) => Promise<void>;
  onDelete: () => void;
  setSelected: () => void;
  selected: boolean;
  draggable?: boolean;
}

function TextComponent({
  id,
  x,
  y,
  text,
  styles,
  onUpdate,
  onDelete,
  setSelected,
  selected,
  draggable = true,
}: Props) {
  const textRef = useRef<Konva.Text>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const groupRef = useRef<Konva.Group>(null);
  const inputRef = useRef<HTMLElement>(null);
  const textStyleObj = { ...DEFAULT_STYLES, ...styles };
  const [textAreaHasFocus, setTextAreaHasFocus] = useState(text ? false : true);

  useEffect(() => {
    const transformNode = trRef.current;
    if (transformNode && transformNode.nodes && textRef.current && transformNode.getLayer) {
      transformNode.nodes([textRef.current]);
      transformNode.getLayer()?.batchDraw();
    }
  }, [trRef, textRef, selected]);

  useEffect(() => {
    if (!selected) {
      setTextAreaHasFocus(false);
    }
  }, [selected]);

  function handleTextInput(e: KeyboardEvent) {
    e.preventDefault();
    const keyTarget = e.target as HTMLInputElement;

    if (e.key === 'Enter' && !e.shiftKey && keyTarget.value === '') {
      onDelete();
    } else if (e.key === 'Enter' && !e.shiftKey) {
      keyTarget.blur();
      setTextAreaHasFocus(false);
    } else {
      onUpdate({ text: keyTarget.value });
    }
  }

  function handleFontStyle() {
    if (textStyleObj.fontStyleBold && textStyleObj.fontStyleItalic) {
      return 'italic bold';
    } else if (textStyleObj.fontStyleBold) {
      return 'bold';
    } else if (textStyleObj.fontStyleItalic) {
      return 'italic';
    } else {
      return 'normal';
    }
  }

  return (
    <Group
      id={id}
      x={x}
      y={y}
      onDblClick={() => {
        setTextAreaHasFocus(!textAreaHasFocus);
      }}
      onClick={(e) => {
        e.cancelBubble = true;
        setSelected();
      }}
      draggable={draggable}
      onDragEnd={() => {
        const node = groupRef.current;

        onUpdate({
          x: node?.attrs.x,
          y: node?.attrs.y,
        });
      }}
      ref={groupRef}
    >
      {selected && (
        <KonvaUtils.Html
          divProps={{ style: { zIndex: 100 } }}
          transformFunc={({ x, ...attrs }) => {
            return { ...attrs, scaleX: 1, scaleY: 1, x: x + attrs.scaleX };
          }}
        >
          <TextMenu
            onUpdate={onUpdate}
            onDelete={onDelete}
            textStyleObj={textStyleObj}
            setTextAreaHasFocus={() => {
              setTextAreaHasFocus(true);
            }}
          />
        </KonvaUtils.Html>
      )}

      <KonvaUtils.Html>
        {textAreaHasFocus && (
          <TextareaAutosize
            className="rounded-md border border-primary-purple"
            style={{
              resize: 'both',
              backgroundColor: 'transparent',
              fontSize: `${textStyleObj.fontSize}px`,
              fontStyle: textStyleObj.fontStyleItalic ? 'italic' : '',
              fontWeight: textStyleObj.fontStyleBold ? 'bold' : '',
              textAlign: textStyleObj.align as 'left' | 'center',
              textDecoration: textStyleObj.textDecoration,
              color: text ? textStyleObj.fillColor : 'gray',
              caretColor: 'black',
              outlineColor: '#382152',
              padding: `${Math.ceil(parseInt(textStyleObj.fontSize) / 3)}px`,
            }}
            onKeyDown={(e) => e.stopPropagation()}
            onKeyUp={handleTextInput}
            ref={inputRef as RefObject<HTMLTextAreaElement>}
            defaultValue={text}
            placeholder={text ? '' : 'Add text'}
            minRows={2}
          ></TextareaAutosize>
        )}
      </KonvaUtils.Html>
      {!textAreaHasFocus && (
        <>
          <Text
            ref={textRef}
            text={text}
            lineHeight={1.5}
            padding={Math.ceil(parseInt(textStyleObj.fontSize) / 3)}
            fontSize={parseInt(textStyleObj.fontSize)}
            fontStyle={handleFontStyle()}
            textDecoration={textStyleObj.textDecoration}
            align={textStyleObj.align}
            fill={textStyleObj.fillColor}
            onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
              const container = e.target.getStage()?.container();
              if (container) {
                if (container.style.cursor == 'default') {
                  container.style.cursor = 'pointer';
                }
              }
            }}
            onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
              const container = e.target.getStage()?.container();
              if (container) {
                if (container.style.cursor == 'pointer') {
                  container.style.cursor = 'default';
                }
              }
            }}
            onTransform={() => {
              if (textRef.current === null) return;

              const textNode = textRef.current;
              const newWidth = textNode.width() * textNode.scaleX();

              textNode.setAttrs({
                width: newWidth,
                scaleX: 1,
                scaleY: 1,
              });
            }}
            onTransformEnd={() => {
              if (textRef.current === null) return;

              const textNode = textRef.current;
              onUpdate({ styles: { ...textStyleObj, width: textNode.width() } });
            }}
            width={textStyleObj.width}
          />
          <Transformer
            borderStroke="#382152"
            anchorStroke="#382152"
            anchorSize={8}
            visible={selected}
            flipEnabled={false}
            ref={trRef}
            rotateEnabled={false}
            borderStrokeWidth={2}
            enabledAnchors={['middle-right']}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 50) return oldBox;
              return newBox;
            }}
          />
        </>
      )}
    </Group>
  );
}

export default TextComponent;
