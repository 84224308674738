import styled from 'styled-components';

export const Container = styled.div``;

export const InsightPageWrapper = styled.div`
  padding: 0 0 80px;
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const Delimiter = styled.div`
  margin: 0 8px;
`;

export const InsightTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 336px;
  user-select: none;
`;

export const Actions = styled.div`
  display: flex;
`;
