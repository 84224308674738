import React, { useMemo, useState } from 'react';
import { useDashboardsTags, useFetchTagGroupsByDashboardIds } from '../../Hooks/useTags';
import { TTagGroupWithTags, TagWithDetails } from '../../Models';

interface WithTagManagementOptons {
  value?: TagWithDetails[];
  selectedText?: string;
  dashboardIds: string[];
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tag: TagWithDetails): void;
  onDelete?(tag: TagWithDetails): void;
  onBlur?(): void;
}

export interface PassedComponentProps {
  groups: TTagGroupWithTags[];
  tagsWithoutGroup: TagWithDetails[];
  value?: TagWithDetails[];
  dashboardId?: string;
  suggestedTags?: TagWithDetails[];
  hideManageButton?: boolean;
  isLoading?: boolean;
  onRefetchTags(): void;
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tagName: string): Promise<TagWithDetails>;
  onBlur?(): void;
  onUpdate?(): void;
}

const withAllTagsManagement = (
  PassedComponent: React.ComponentType<PassedComponentProps>
): React.FC<WithTagManagementOptons> => {
  const WrappedComponent: React.FC<WithTagManagementOptons> = ({
    value,
    dashboardIds,
    onChange,
    onBlur,
  }) => {
    const [loadingTags, tags, refetchTags] = useDashboardsTags(dashboardIds, true, true);
    const [suggestedTags, setSuggestedTags] = useState<TagWithDetails[]>([]);
    const [tagGroupsLoading, externalTagGroups, refetchTagGroups] = useFetchTagGroupsByDashboardIds(
      dashboardIds
    );

    const groupsWithTags = useMemo(() => {
      const filteredTagGroups =
        externalTagGroups
          ?.map((group) => {
            const tagsInGroup = tags
              .filter((tag) => tag.groupId === group.id)
              .sort((firstTag, secondTag) =>
                firstTag.name.toLowerCase() < secondTag.name.toLowerCase() ? -1 : 1
              );

            return {
              ...group,
              tags: tagsInGroup as TagWithDetails[],
            };
          })
          .filter((group) => group.tags.length) || [];
      return filteredTagGroups;
    }, [externalTagGroups, tags]);

    const tagsWithoutGroup = useMemo(() => {
      return tags.filter((tag) => !tag.groupId) as TagWithDetails[];
    }, [tags]);

    return (
      <PassedComponent
        groups={groupsWithTags}
        tagsWithoutGroup={tagsWithoutGroup}
        value={value}
        onRefetchTags={() => {
          refetchTagGroups();
          refetchTags();
        }}
        onChange={onChange}
        onBlur={onBlur}
        hideManageButton
        isLoading={loadingTags || tagGroupsLoading}
      />
    );
  };

  return WrappedComponent;
};

export default withAllTagsManagement;
