import React, { FC } from 'react';
import { Container, Image, Text, QuestionIconWrapper, FAQLink } from './styles';
import emptyStateImgSrc from '../../assets/empty-states/notes.png';
import Icon24 from '../../Icons/Icon/Icon24';

const TagsPanelEmptyState: FC = () => {
  return (
    <Container>
      <Image src={emptyStateImgSrc} />
      <Text>
        Highlight and tag important snippets on the left, and then click “Add highlights to
        Analysis.”{' '}
      </Text>

      <FAQLink
        href="https://help.notably.ai/en/articles/8704742-what-are-highlights"
        target="_blank"
      >
        <QuestionIconWrapper>
          <Icon24.QuestionMark />
        </QuestionIconWrapper>{' '}
        Learn more about highlighting
      </FAQLink>
    </Container>
  );
};

export default TagsPanelEmptyState;
