import React, { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import { default as SharingLogo } from '../assets/sharing/Notably-Logotype-Dark.svg';
import { defaultProjectIcons } from '../Consts/projectIcons';

type SharingLayoutProps = {
  isUserAuthenticated?: boolean;
  dashboard?: { name?: string; cover?: string; id?: string; shareId?: string };
  children: ReactNode;
  logo?: string | null;
};

function SharingLayout({
  children,
  dashboard,
  logo,
  isUserAuthenticated,
}: SharingLayoutProps): JSX.Element {
  const { orgId } = useParams<{ orgId: string }>();
  const icon =
    defaultProjectIcons.find((icon) => icon.name === dashboard?.cover) || defaultProjectIcons[0];
  return (
    <div>
      <div className="px-3 py-2 flex justify-between">
        <div className="flex items-center">
          <Link to={'/'}>
            {logo ? (
              <img
                src={logo}
                alt="cover image"
                style={{
                  width: 32,
                  height: 32,
                  objectFit: 'cover',
                }}
              />
            ) : (
              <svg
                className={'hover:opacity-75'}
                width={32}
                height={32}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.5097 1.85339H2.52347C1.69125 1.85339 1.0166 2.52804 1.0166 3.36027V12.6397C1.0166 13.472 1.69125 14.1466 2.52347 14.1466H10.1946C10.2564 14.1466 10.3179 14.1455 10.3792 14.1431V14.142C10.6881 14.1419 10.99 14.1116 11.2821 14.0539C13.174 13.68 14.6522 12.1554 14.9573 10.2397C14.9954 10.0003 15.0152 9.75484 15.0152 9.50474V9.34188H15.0166L15.0166 9.32462V3.36027C15.0166 2.52804 14.342 1.85339 13.5097 1.85339ZM14.1125 9.33558L14.1125 9.32462V8.4479H5.40547V10.3853H10.3779V10.2397C10.3779 9.74037 10.7827 9.33558 11.2821 9.33558H14.1125ZM10.3779 11.2894H5.40547V13.2425H10.1946C10.2561 13.2425 10.3172 13.2411 10.3779 13.2383V11.2894ZM4.37475 11.2894V13.2425H2.52347C2.19059 13.2425 1.92073 12.9726 1.92073 12.6397V11.2894H4.37475ZM4.37475 10.3853H1.92073V8.4479H4.37475V10.3853ZM4.37475 7.54378H1.92073V5.61365H4.37475V7.54378ZM4.37475 4.70952H1.92073V3.36027C1.92073 3.02738 2.19059 2.75752 2.52347 2.75752H4.37475V4.70952ZM5.40547 4.70952V2.75752H13.5097C13.8426 2.75752 14.1125 3.02738 14.1125 3.36027V4.70952H5.40547ZM5.40547 5.61365H14.1125V7.54378H5.40547V5.61365ZM14.0388 10.2397C13.7562 11.655 12.6734 12.7816 11.2821 13.1277V10.2397H14.0388Z"
                  fill="#3B2651"
                />
              </svg>
            )}
          </Link>
          {dashboard && (
            <Link to={`/s/${orgId}/insights/${dashboard.shareId}`}>
              <div className="ml-5 flex items-center">
                <img
                  className="rounded-md mr-2"
                  src={icon?.url}
                  alt="Project icon"
                  style={{
                    width: 24,
                    height: 24,
                    objectFit: 'cover',
                  }}
                />
                <div className="font-medium">{dashboard?.name || 'Untitled'}</div>
              </div>
            </Link>
          )}
        </div>
        {!isUserAuthenticated && (
          <div className="flex items-center">
            <Link className="font-medium" to={'/'}>
              Log in
            </Link>
          </div>
        )}
      </div>
      <div style={{ minHeight: 'calc(100vh - 168px)' }}>{children}</div>
      <div className="flex items-center py-5 pb-10 px-3 max-w-800 mx-auto w-full">
        <img src={SharingLogo} className="object-cover" />
        <div className="bg-primary-purple-gray mx-5" style={{ width: '1px', height: '16px' }}></div>
        <div className="font-medium text-primary-purple-gray">
          Collect, analyze, and share research with Notably.
        </div>
      </div>
    </div>
  );
}

export default SharingLayout;
