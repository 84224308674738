import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Spacer,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import {
  CheckIcon,
  InformationCircleIcon,
  XIcon,
  ClipboardCheckIcon,
} from '@heroicons/react/outline';
import styled from 'styled-components';
import colors from '../../Consts/colors';
import Button from '../Button';
import { useLocalStorage } from 'usehooks-ts';

const MAIN_WIDTH = '260px';

type Position = 'left' | 'right';

const ChecklistContainer = styled.div<{ minimized: boolean; position: Position }>`
  position: fixed;
  right: ${(props) => (props.position === 'right' ? '10px' : '')};
  left: ${(props) => (props.position === 'left' ? '10px' : '')};
  bottom: 10px;
  width: ${MAIN_WIDTH};
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #e5e5e5, 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 100;
  overflow: hidden;
  /* max-height: ${(props) => (props.minimized ? '0px' : '100%')};
  max-width: ${(props) => (props.minimized ? '0px' : '100%')}; */
  transition: all 0.3s;
  color: #382152;
  border-radius: 8px;
  transform: ${(props) => (props.minimized ? 'scale(0)' : 'scale(1)')};
  transform-origin: left bottom 0px;
`;

const MinimizedChecklistContainer = styled.div<{ minimized: boolean; position: Position }>`
  position: fixed;
  right: ${(props) => (props.position === 'right' ? '10px' : '')};
  left: ${(props) => (props.position === 'left' ? '10px' : '')};
  bottom: 10px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #e5e5e5, 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 100;
  overflow: hidden;
  color: #382152;
  border-radius: 8px;
  transform: ${(props) => (props.minimized ? 'scale(1)' : 'scale(0)')};
  transform-origin: left bottom 0px;
  transition: all 0.3s;
`;

const ContentContainer = styled.div`
  padding-top: 4px;
`;

const RibbonImage = styled.div<{ src: string; height: number }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: ${(props) => props.height}px;
  width: ${MAIN_WIDTH};
`;

const Header = styled.p`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: ${colors.text.purple};
  margin-top: 15px;
  margin-bottom: 10px;
`;

const SubHeader = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.text.purple};
  margin-top: 4px;
  margin-bottom: 12px;
`;

const AccordionHeader = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${colors.text.purple};
  /* padding-bottom: 10px;
  padding-top: 10px; */
`;

const ProgressBarContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ProgressBarBackground = styled.div`
  width: 100%;
  height: 3px;
  background-color: #d7d3dc;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: calc(100% * ${(props) => props.progress});
  height: 3px;
  background-color: ${colors.text.purple};
  margin-top: -3px;
`;

const MinimizeContainer = styled.div`
  z-index: 101;
  position: absolute;
  right: 10px;
  top: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const ProgressIconContainer = styled.div`
  margin-right: 10px;
`;

const ProgressWrapper = styled.div`
  margin-right: 10px;
  margin-left: 10px;
`;

const EmptyCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #d7d3dc;
`;

const ChecklistItemDescriptionContainer = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 24px;
`;

export interface ChecklistItem {
  header: string;
  description: string;
  cta?: string;
  onCta?: () => void;
  learnMore?: string;
  learnMoreHref?: string;
  onIgnore?: () => void;
  completed: boolean;
}

interface Props {
  checklistItems: ChecklistItem[];
  ribbonSrc: string;
  id: string;
  position: Position;
  heading: string;
}

const MinimizedContent = styled.div`
  display: flex;
  padding: 8px;
  cursor: pointer;
`;
const Counter = styled.div`
  border-radius: 8px;
  background: ${colors.primary.purple10};
  padding: 4px 8px;
  font-weight: 500;
`;

const Checklist = ({ checklistItems, ribbonSrc, position, heading, id }: Props): JSX.Element => {
  const [minimized, setMinimized] = useLocalStorage(id, false);

  if (checklistItems.every((item) => item.completed)) {
    // all items are completed, render nothing
    return <></>;
  }

  return (
    <>
      <ChecklistContainer minimized={minimized} position={position}>
        <RibbonImage src={ribbonSrc} height={40} />
        <MinimizeContainer onClick={() => setMinimized(!minimized)}>
          <XIcon stroke="white" width="20px" height="20px" />
        </MinimizeContainer>
        <ContentContainer>
          <ProgressWrapper>
            <Header>{heading}</Header>
            <ProgressBarContainer>
              <ProgressBarBackground />
              <ProgressBar
                progress={
                  checklistItems.filter((item) => item.completed).length / checklistItems.length
                }
              />
            </ProgressBarContainer>
            <SubHeader>
              {checklistItems.filter((item) => item.completed).length}/{checklistItems.length}
            </SubHeader>
          </ProgressWrapper>
          <Accordion defaultIndex={checklistItems.findIndex((item) => !item.completed) ?? 0}>
            {checklistItems.map((item) => {
              return (
                // <AccordionItem key={item.header} borderBottom="1px solid #D7D3DC">
                <AccordionItem key={item.header}>
                  <AccordionHeader>
                    <AccordionButton w="100%" paddingX="8px">
                      <ProgressIconContainer>
                        {item.completed ? <CheckIcon width={24} /> : <EmptyCheckbox />}
                      </ProgressIconContainer>
                      <div style={{ textAlign: 'left' }}>{item.header}</div>
                      <Spacer />
                      <AccordionIcon />
                    </AccordionButton>
                  </AccordionHeader>
                  <AccordionPanel p="16px">
                    <ChecklistItemDescriptionContainer>
                      {item.description}
                    </ChecklistItemDescriptionContainer>
                    {!!item.cta && (
                      <Button onClick={item.onCta} type="secondary">
                        {item.cta}
                      </Button>
                    )}
                    {!!item.learnMore && (
                      <a
                        style={{ display: 'flex', fontSize: '14px', alignItems: 'center' }}
                        href={item.learnMoreHref}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InformationCircleIcon width={24} style={{ marginRight: '4px' }} />
                        {item.learnMore}
                      </a>
                    )}
                    {!!item.onIgnore && (
                      <div style={{ marginTop: '10px' }}>
                        <Link href="#" onClick={item.onIgnore}>
                          Do this later
                        </Link>
                      </div>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </ContentContainer>
      </ChecklistContainer>
      <MinimizedChecklistContainer minimized={minimized} position={position}>
        <MinimizedContent onClick={() => setMinimized(!minimized)}>
          <ClipboardCheckIcon width={24} style={{ marginRight: '4px' }} />
          <Counter>
            {checklistItems.filter((item) => item.completed).length}/{checklistItems.length}
          </Counter>
        </MinimizedContent>
      </MinimizedChecklistContainer>
    </>
  );
};

export default Checklist;
