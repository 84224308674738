import React from 'react';

export default function RhombusIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="9.07104"
        y="0.585786"
        width="12"
        height="12"
        transform="rotate(45 9.07104 0.585786)"
        stroke="#3B2651"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
