import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import TranscriptLabelComponent from './TranscriptLabelComponent';

export interface TranscriptLabelOptions {
  HTMLAttributes: Record<string, any>;
}

const TranscriptLabel = Node.create<TranscriptLabelOptions>({
  name: 'transcriptLabel',
  group: 'block',
  content: 'inline*',
  selectable: false,
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: `transcriptLabel`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['transcriptLabel', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TranscriptLabelComponent);
  },
});

export default TranscriptLabel;
