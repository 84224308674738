import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Icon24 } from '../../Icons/Icon';
import { ID, TGroupSort, TTagGroup, TTagGroupWithTags, TagWithDetails } from '../../Models';
import { Loader } from '../../Components';
import TagsEmptyState from './TagsEmptyState';
import TagsLayout from './TagsLayout';

import {
  useCreateTag,
  useCreateTagGroup,
  useCreateTagGroupSorting,
  useDashboardTags,
  useDeleteTag,
  useFetchTagGroupSorting,
  useFetchTagGroups,
  useMergeTags,
  useUpdateTag,
  useUpdateTagGroupSortings,
} from '../../Hooks/useTags';
import TagModal from '../../Components/TagModal';

import {
  ContentContainer,
  GhostButton,
  ButtonText,
  TagsWithoutGroupContainer,
  TagGroupWrapper,
} from './styles';
import TagsUpgradeSuggestion from '../../Components/TagsUpgradeSuggestion';
import useFeatures from '../../Hooks/useFeatures';
import { toast } from 'react-toastify';
import TagsUpgradeModal from './TagsUpgradeModal';
import TagGroup from '../../Components/TagGroup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TagItem from '../../Components/TagItem';
import { DragItem, DropArea } from '../../Components/DragAndDrop';
import usePermissions from '../../Hooks/usePermissions';

interface TagsProps {
  type: string;
}

const Tags: FC<TagsProps> = ({ type }) => {
  const history = useHistory();
  const { dashboardId, tagId } = useParams<{ dashboardId: string; tagId: string }>();
  const { canEditTags } = usePermissions();

  const [checkedTags, setCheckedTags] = useState<TagWithDetails[]>([]);
  const [isModalOpened, setModalOpened] = useState(false);
  const [modalTagId, setModalTagId] = useState<string | null>(tagId ? tagId : null);

  const [tagsLoading, externalTags, refetchTags] = useDashboardTags(dashboardId, true, true);
  const [tagGroupsLoading, externalTagGroups, refetchTagGroups] = useFetchTagGroups(dashboardId);
  const [
    tagGroupSortingsLoading,
    externalTagGroupSortings,
    refetchTagGroupSortings,
  ] = useFetchTagGroupSorting(dashboardId);

  const [tags, setTags] = useState<TagWithDetails[]>(externalTags as TagWithDetails[]);
  const [tagGroups, setTagGroups] = useState<TTagGroup[]>([]);
  const [tagGroupSorting, setTagGroupSorting] = useState<TGroupSort | null>(null);

  const createTag = useCreateTag();
  const deleteTag = useDeleteTag();
  const updateTag = useUpdateTag();
  const mergeTags = useMergeTags();
  const createTagGroupSortings = useCreateTagGroupSorting();
  const updateTagGroupSortings = useUpdateTagGroupSortings();
  const createTagGroup = useCreateTagGroup();

  const { globalTagsEnabled, globalTagsEditable } = useFeatures();
  const isTypeGlobal = type === 'global';

  useEffect(() => {
    if (!tagsLoading && externalTags)
      setTags(
        externalTags.filter((tag) =>
          isTypeGlobal ? tag.isGlobal : !tag.isGlobal
        ) as TagWithDetails[]
      );
  }, [tagsLoading, externalTags, isTypeGlobal]);

  useEffect(() => {
    if (!tagGroupsLoading && externalTagGroups)
      setTagGroups(
        externalTagGroups.filter((group) => (isTypeGlobal ? group.isGlobal : !group.isGlobal))
      );
  }, [tagGroupsLoading, externalTagGroups, isTypeGlobal]);

  useEffect(() => {
    if (!tagGroupSortingsLoading && externalTagGroupSortings) {
      setTagGroupSorting(
        externalTagGroupSortings.find((sorting) =>
          isTypeGlobal ? sorting.isGlobal : !sorting.isGlobal
        ) || null
      );
    }
  }, [tagGroupSortingsLoading, externalTagGroupSortings, isTypeGlobal]);

  useEffect(() => {
    setCheckedTags([]);
  }, [type, tags]);

  useEffect(() => {
    setModalTagId(tagId || null);
  }, [tagId]);

  const groupsWithTags = useMemo(() => {
    const filteredTagGroups =
      tagGroups?.map((group) => {
        const tagsInGroup = tags
          .filter((tag) => tag.groupId === group.id)
          .sort((firstTag, secondTag) =>
            firstTag.name.toLowerCase() < secondTag.name.toLowerCase() ? -1 : 1
          );

        return {
          ...group,
          tags: tagsInGroup,
        };
      }) || [];

    return tagGroupSorting
      ? tagGroupSorting.sorting
          .map((id) => filteredTagGroups.find((group) => group.id === id) as TTagGroupWithTags)
          .filter((group) => !!group)
      : filteredTagGroups;
  }, [tagGroups, tags, tagGroupSorting]);

  const tagsWithoutGroup = useMemo(() => {
    return tags.filter((tag) => !tag.groupId) as TagWithDetails[];
  }, [tags]);

  const handleConfirmDeletingTags = async () => {
    for (const tag of checkedTags) {
      await deleteTag(tag.id);
    }

    setCheckedTags([]);
    refetchTags();
  };

  const handleConfirmMergingTags = async (newTagName: string) => {
    const allGroupIds = checkedTags.map((tag) => tag.groupId).filter((id) => !!id);
    const groupId = !!allGroupIds.length
      ? allGroupIds.reduce((a, b) => (a === b ? a : null))
      : null;

    await mergeTags(
      newTagName,
      checkedTags.map((tag) => tag.id),
      groupId,
      isTypeGlobal,
      dashboardId
    );
    setCheckedTags([]);
    refetchTags();
  };

  const handleAddNewTag = async (groupId?: ID) => {
    if (!canEditTags) return;
    if (isTypeGlobal && !globalTagsEditable) {
      setModalOpened(true);
      return;
    }

    const newTag = await createTag(
      isTypeGlobal ? { name: '', isGlobal: true, groupId } : { dashboardId, name: '', groupId }
    );
    setModalTagId(newTag.id as string);
    refetchTags();
  };

  const handleMakeGlobal = async () => {
    if (!canEditTags) return;
    if (!globalTagsEditable) {
      setModalOpened(true);
      return;
    }

    toast.success(
      `"${checkedTags
        .map((tag) => tag.name)
        .join(
          '", "'
        )}" has been added to global tags and can be used across all projects in your workspace.`,
      {
        autoClose: 3000,
      }
    );
    await Promise.all(
      checkedTags.map((tag) => updateTag(tag.id, { isGlobal: true, groupId: null }))
    );

    refetchTags();
    setCheckedTags([]);
  };

  const handleTagClick = (tagId: string) => {
    if (!canEditTags) return;
    history.push(`/projects/${dashboardId}/tags/${type}/${tagId}`);
  };

  const handleAddNewGroup = async () => {
    if (!canEditTags) return;
    const newGroup = await createTagGroup(dashboardId, isTypeGlobal);
    refetchTagGroups();
    await updateSorting(
      tagGroups.map((group) => group.id).concat(newGroup.id),
      tagGroupSorting?.id
    );
    refetchTagGroupSortings();
  };

  const handleDropTagOutsideOfGroup = async (tag: TagWithDetails) => {
    if (!canEditTags) return;
    await updateTag(tag.id, { groupId: null });
    refetchTags();
  };

  const handleChangeTagGroup = () => {
    refetchTagGroups();
    refetchTags();
  };

  const generateTitle = () => {
    let titleEnd = '';
    if (type === 'global') {
      titleEnd = '/ Global tags';
    } else if (type === 'project') {
      titleEnd = '/ Project tags';
    }
    return `Tags ${titleEnd}`;
  };

  const updateSorting = async (sorting: ID[], id?: ID) => {
    if (id) {
      await updateTagGroupSortings(id, sorting);
    } else {
      await createTagGroupSortings(dashboardId, sorting, isTypeGlobal);
    }
    refetchTagGroupSortings();
  };

  const moveGroup = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      if (!canEditTags) return;
      let newArray: TTagGroup[] = [];
      setTagGroups((prevCards) => {
        newArray = prevCards.slice();
        const temp = newArray[dragIndex];
        newArray[dragIndex] = newArray[hoverIndex];
        newArray[hoverIndex] = temp;
        return newArray;
      });

      updateSorting(
        newArray.map((group) => group.id),
        tagGroupSorting?.id
      );
    },
    [dashboardId, isTypeGlobal, tagGroupSorting]
  );

  if (tagsLoading || tagGroupsLoading || tagGroupSortingsLoading) return <Loader />;

  return (
    <>
      <TagsLayout
        title={generateTitle()}
        onCreateTag={handleAddNewTag}
        onDeleteTag={handleConfirmDeletingTags}
        onMergeTags={handleConfirmMergingTags}
        onMakeGlobal={handleMakeGlobal}
        checkedTags={checkedTags}
        isGlobal={isTypeGlobal}
        hideButtons={!canEditTags}
      >
        <ContentContainer>
          {!globalTagsEnabled && isTypeGlobal ? (
            <TagsUpgradeSuggestion />
          ) : tags.length ? (
            <>
              <DndProvider backend={HTML5Backend}>
                {groupsWithTags.map((group, index) => (
                  <TagGroupWrapper key={group.id}>
                    <TagGroup
                      index={index}
                      group={group}
                      checkedTags={checkedTags}
                      isGlobal={isTypeGlobal}
                      disabled={!canEditTags || (isTypeGlobal && !globalTagsEditable)}
                      onCheckedTagsChange={setCheckedTags}
                      onTagClick={handleTagClick}
                      onCreateNewTag={handleAddNewTag}
                      onTagDrop={refetchTags}
                      onChange={handleChangeTagGroup}
                      moveGroup={moveGroup}
                    />
                  </TagGroupWrapper>
                ))}
                {canEditTags && (
                  <GhostButton>
                    <Icon24.Plus />
                    <ButtonText onClick={() => handleAddNewGroup()}>New group</ButtonText>
                  </GhostButton>
                )}

                <DropArea<TagWithDetails> onDrop={handleDropTagOutsideOfGroup} type="tagGroup">
                  <TagsWithoutGroupContainer isOver={false}>
                    {tagsWithoutGroup.map((tag) => (
                      <DragItem<TagWithDetails> key={tag.id} item={tag} type="tagGroup">
                        <TagItem
                          key={tag.id}
                          tag={tag}
                          isChecked={
                            checkedTags.findIndex((currentTag) => tag.id === currentTag.id) !== -1
                          }
                          onCheckedChange={(tagId, isChecked) =>
                            isChecked
                              ? setCheckedTags([...checkedTags, tag])
                              : setCheckedTags(
                                  checkedTags.filter((currentTag) => currentTag.id !== tag.id)
                                )
                          }
                          onTagClick={handleTagClick}
                          disabled={!canEditTags}
                        />
                      </DragItem>
                    ))}
                  </TagsWithoutGroupContainer>
                </DropArea>
              </DndProvider>
            </>
          ) : (
            <TagsEmptyState isGlobal={isTypeGlobal} onAddNewTag={() => handleAddNewTag()} />
          )}
        </ContentContainer>

        <TagsUpgradeModal isOpen={isModalOpened} onClose={() => setModalOpened(false)} />
      </TagsLayout>
      {modalTagId && (
        <TagModal
          tagId={modalTagId}
          onClose={() => {
            setModalTagId(null);
            history.push(`/projects/${dashboardId}/tags/${type}`);
            refetchTags();
          }}
        />
      )}
    </>
  );
};

export default Tags;
