import * as React from 'react';
import ReactLoading from 'react-loading';

type LoaderProps = {
  isFullScreen?: boolean;
  size?: string;
};

const Loader = ({ isFullScreen = true, size }: LoaderProps): JSX.Element => {
  const classNames = (isFullScreen ? 'mt-15pct ' : '') + 'flex justify-center';
  return (
    <div className={classNames}>
      <ReactLoading
        type={'spin'}
        color={'#382152'}
        height={size ? `${size}px` : '50px'}
        width={size ? `${size}px` : '50px'}
      />
    </div>
  );
};

export default Loader;
