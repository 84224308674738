import InPortal from './InPortal';
import MultiSelect, { OptionType } from './MultiSelect';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';

type DropDownEditorProps = {
  autoFocus?: boolean;
  cell?: {
    completeEdit: (value: any) => void;
    domRef: MutableRefObject<HTMLElement>;
  };
  isMulti?: boolean;
  options: OptionType[];
  placeholder?: string;
  noOptionsMessage?: string;
  value: OptionType | OptionType[] | null;
  onChange?: () => void;
  onCancel?: () => void;
  onComplete?: () => void;
  onEnterNavigation?: (a: boolean, b: number) => void;
  onTabNavigation?: (a: boolean, b: number) => void;
};

export default function DropDownEditor({
  autoFocus = true,
  cell,
  isMulti = true,
  noOptionsMessage = 'No Options',
  placeholder = '',
  options,
  value,
  onComplete,
  onCancel,
  onChange,
  ...props
}: DropDownEditorProps): JSX.Element {
  const rect = cell?.domRef?.current?.getBoundingClientRect();
  const ref = useRef<CreatableSelect<any>>(null);

  if (!rect) {
    return <></>;
  }

  const { top = 0, left = 0 } = rect;

  return (
    <InPortal top={top} left={left}>
      <div className="w-64">
        <MultiSelect
          autoFocus={autoFocus}
          ref={ref}
          menuPlacement={top * 2 > window.innerHeight ? 'top' : 'bottom'}
          menuShouldScrollIntoView={false}
          isClearable={true}
          onBlur={() => (isMulti ? onComplete?.() : onCancel?.())}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(a, b) => (isMulti ? onChange?.(a, b) : cell?.completeEdit?.(a ?? b))}
          closeMenuOnSelect={true}
          onKeyDown={(e) => {
            if (e.key == 'Tab') {
              e.preventDefault();
              props.onTabNavigation?.(false, e.shiftKey ? -1 : 1);
            }
          }}
          noOptionsMessage={() => noOptionsMessage}
          placeholder={placeholder}
          isMulti={isMulti}
          defaultValue={value}
          options={options}
          menuIsOpen={true}
        />
      </div>
    </InPortal>
  );
}
