import { DocumentDuplicateIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { defaultProjectIcons } from '../Consts/projectIcons';
import useBoards, {
  Folder,
  useCopyBoard,
  useCreateFolder,
  useDeleteFolder,
  useFetchFolders,
  useUpdateFolder,
} from '../Hooks/useBoards';
import {
  checkOrganizationLimits,
  useOrganization,
  useViewOnlyProjects,
} from '../Hooks/useOrganization';
import useUsers from '../Hooks/useUsers';
import ListWithFolders from './ListWithFolders';
import usePermissions from '../Hooks/usePermissions';
import Loader from './Loader';
import { Dashboard } from '../Models/Dashboards';
import useAnalytics from '../Hooks/useAnalytics';

export interface ProjectsListProps {
  loading: boolean;
  dashboards?: Dashboard[] | null;
  folders: Folder[] | null;
}
export function ProjectsList({ folders, dashboards, loading }: ProjectsListProps): JSX.Element {
  const history = useHistory();
  const { analytics } = useAnalytics();

  const { canEditProjects, canCreateProjects } = usePermissions();

  const { createBoard, deleteBoard, updateBoard } = useBoards();

  const [createFolder] = useCreateFolder();
  const [updateFolder] = useUpdateFolder();
  const [deleteFolder] = useDeleteFolder();
  const [copyBoard] = useCopyBoard();

  const [, viewOnlyProjectIds] = useViewOnlyProjects();

  const { currentUser, fetchUsers } = useUsers();
  const [, user] = currentUser();
  const [, users] = fetchUsers();

  const [, org] = useOrganization();

  const handleDashboardClick = async (item: any) => {
    history.push(`/projects/${item.id}/data`);
    const dashboard = dashboards?.find((dashboard) => dashboard.id === item.id);
    if (dashboard?.isSample && !dashboard.isVisited) {
      await updateBoard(dashboard.id, {
        isVisited: true,
      });
    }
  };

  const handleClick = (path: string) => {
    history.push(path);
  };

  const handleDelete = async (dashboardId: string) => {
    if (
      confirm(
        'Are you sure you want to delete this project? All data, analysis, and insights will be permanently deleted. This cannot be undone.'
      )
    ) {
      await deleteBoard(dashboardId);

      analytics.sendEvent('DeleteProject', {
        id: dashboardId,
      });

      toast.success('The project has been successfully deleted', {
        autoClose: 5000,
      });

      history.push(history.location.pathname);
    }
  };

  const dashboardItems = dashboards?.map((dashboard) => {
    const icon = defaultProjectIcons.find((icon) => icon.name === dashboard.cover);
    return {
      icon,
      id: dashboard.id,
      timestamp: dashboard.updatedAt,
      user: dashboard.userByCreatedBy,
      name: dashboard.name || 'Untitled Project',
      viewOnly: viewOnlyProjectIds.includes(dashboard.id),
      folderId: dashboard.folderId,
      folder: dashboard.folder,
      actions: canEditProjects
        ? [
            {
              icon: <PencilIcon className="mr-3 h-5 w-5" />,
              name: 'Rename',
              handler: () => handleClick(`/projects/${dashboard.id}/edit`),
            },
            {
              icon: <DocumentDuplicateIcon className="mr-3 h-5 w-5" />,
              name: 'Copy',
              handler: async () => {
                if (!checkOrganizationLimits(org, history, ['projects'])) {
                  return false;
                }
                const res = await copyBoard(dashboard.id);
                toast.success('The project has been successfully copied', {
                  autoClose: 3000,
                });
                history.push(`/projects/${res.data.copyProject.dashboardId}/info`);
              },
            },
            {
              icon: <TrashIcon className="mr-3 h-5 w-5" />,
              name: 'Delete',
              handler: () => handleDelete(dashboard.id),
            },
          ]
        : [],
    };
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <ListWithFolders
      defaultItemTitle="project"
      items={dashboardItems || []}
      folders={folders}
      setItemFolder={async (item, folderId) => {
        await updateBoard(item.id, {
          folderId,
        });
      }}
      onCreateFolder={
        canCreateProjects && folders !== null
          ? () => {
              createFolder();
            }
          : null
      }
      onRenameFolder={
        canCreateProjects && folders !== null
          ? (id: string, name: string) => {
              updateFolder(id, name);
            }
          : null
      }
      onDeleteFolder={
        canCreateProjects && folders !== null
          ? (id: string) => {
              deleteFolder(id);
            }
          : null
      }
      onCreateItem={
        canCreateProjects && folders !== null
          ? async (folderId) => {
              if (!checkOrganizationLimits(org, history, ['projects'])) {
                return;
              }

              const dashboard = await createBoard('Untitled project', { folderId });

              analytics.sendEvent('CreateProject', {
                id: dashboard.id,
              });

              history.push(`/projects/${dashboard.id}/info`);
            }
          : null
      }
      onItemClick={handleDashboardClick}
    />
  );
}
