import styled from 'styled-components';
import Badge from '../Badge1';

export const TagBadge = styled(Badge)`
  font-weight: 500;
  font-size: 14px;
  color: #382152;
  padding: 4px 6px;
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
`;

export const TagsContainer = styled.div`
  min-height: 38px;
  display: flex;
  align-items: center;
`;

export const Placeholder = styled.span`
  color: ${(props) => props.theme.colors.text.placeholder2};
  cursor: pointer;
`;