import styled from 'styled-components';

export const SpeakerInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const SpeakerNameWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const LetterAvatarWrapper = styled.div`
  margin-right: 5px;
`;

export const SpeakerName = styled.div`
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  margin-right: 10px;
`;

export const SpeakerTiming = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
`;
