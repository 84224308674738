import { useMutation } from '@apollo/client';
import { UPDATE_SUMMARY } from '../GraphQL/mutations';
import { ID } from '../Models';

export type FeedbackInput = {
  feedbackCategories: string | string[] | null;
  feedbackMessage: string | null;
  feedbackStatus: 'keep' | 'discard' | 'retry' | 'delete';
};

export const useShareFeedback = (): [(id: ID, input: FeedbackInput) => void] => {
  const [updateMutation] = useMutation(UPDATE_SUMMARY);

  return [
    (id: ID, input: FeedbackInput) => {
      return updateMutation({
        variables: {
          id,
          input,
        },
      });
    },
  ];
};
