import React from 'react';

function ThemeIcon({ width, height }: { width?: string; height?: string }): JSX.Element {
  return (
    <svg
      width={width || '20'}
      height={height || '14'}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="18.5"
        height="12.5"
        rx="2.25"
        stroke="#3B2651"
        strokeWidth="1.5"
      />
      <mask id="path-2-inside-1_98_70" fill="white">
        <rect x="3" y="3" width="6" height="8" rx="1" />
      </mask>
      <rect
        x="3"
        y="3"
        width="6"
        height="8"
        rx="1"
        stroke="#3B2651"
        strokeWidth="3"
        mask="url(#path-2-inside-1_98_70)"
      />
      <mask id="path-3-inside-2_98_70" fill="white">
        <rect x="11" y="3" width="6" height="8" rx="1" />
      </mask>
      <rect
        x="11"
        y="3"
        width="6"
        height="8"
        rx="1"
        stroke="#3B2651"
        strokeWidth="3"
        mask="url(#path-3-inside-2_98_70)"
      />
    </svg>
  );
}

export default ThemeIcon;
