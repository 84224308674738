import React, { FC, useState } from 'react';
import Button from '../Button';

import {
  ButtonText,
  ButtonWrapper,
  Container,
  Overlay,
  ModalWindow,
  Content,
  Header,
  Title,
  CloseButton,
  Tags,
  Tag,
  TagName,
  TagCount,
  Text,
  Buttons,
  MergeWrapper,
  IconWrapper,
  NewTagWrapper,
  InputWrapper,
  InputField,
  DotsDivider,
} from './styles';
import { Icon24 } from '../../Icons/Icon';
import { ID } from '../../Models';
import { reduce } from 'lodash-es';

export type Tag = {
  id?: ID;
  name: string;
  description?: string;
  color?: string;
  keywords?: string;
  tagsDocumentsConnection: {
    totalCount: number;
  };
  tagsNotesConnection: {
    totalCount: number;
  };
  tagsTranscriptionsConnection: {
    totalCount: number;
  };
};

interface MergeTagsModalProps {
  checkedTags: Tag[];
  onConfirm: (newTagName: string) => void;
  onCancel(): void;
}

const MergeTagsModal: FC<MergeTagsModalProps> = ({ checkedTags, onConfirm, onCancel }) => {
  const [newTagName, setNewTagName] = useState('');
  const isLongTagList = checkedTags.length > 5;

  const handleTagNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.parentElement) {
      e.target.parentElement.dataset.value = e.target.value;
    }
  };

  const renderTag = (tag: Tag) => {
    return (
      <Tag key={tag.id}>
        <TagName color={tag.color}>{tag.name}</TagName>
        <TagCount>
          {tag.tagsDocumentsConnection.totalCount +
            tag.tagsNotesConnection.totalCount +
            tag.tagsTranscriptionsConnection.totalCount}
        </TagCount>
      </Tag>
    );
  };

  const renderTags = () => {
    return isLongTagList ? (
      <>
        {checkedTags.slice(0, 2).map((tag) => renderTag(tag))}
        <DotsDivider>...</DotsDivider>
        {checkedTags.slice(checkedTags.length - 2, checkedTags.length).map((tag) => renderTag(tag))}
      </>
    ) : (
      checkedTags.map((tag) => renderTag(tag))
    );
  };

  return (
    <Container>
      <Overlay />
      <ModalWindow>
        <Header>
          <Title>Merge tags</Title>
          <CloseButton onClick={onCancel}>
            <Icon24.Close />
          </CloseButton>
        </Header>
        <Content>
          <MergeWrapper>
            <Tags>{renderTags()}</Tags>
            <IconWrapper>
              <Icon24.Arrow />
            </IconWrapper>
            <NewTagWrapper>
              <Tag>
                <InputWrapper data-value={newTagName || ' '}>
                  <InputField
                    defaultValue={''}
                    onInput={handleTagNameInput}
                    onChange={(e) => setNewTagName(e.target.value)}
                    placeholder="New tag"
                    size={7}
                    autoFocus
                  ></InputField>
                </InputWrapper>
                <TagCount>
                  {checkedTags
                    .map(
                      (item) =>
                        item.tagsDocumentsConnection.totalCount +
                        item.tagsNotesConnection.totalCount +
                        item.tagsTranscriptionsConnection.totalCount
                    )
                    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}
                </TagCount>
              </Tag>
            </NewTagWrapper>
          </MergeWrapper>
          <Text>All instances will be merged and re-tagged. This cannot be undone.</Text>
          <Buttons>
            <ButtonWrapper>
              <Button
                disabled={!newTagName.length}
                onClick={() => {
                  onConfirm(newTagName);
                }}
              >
                <Icon24.Arrow />
                <ButtonText>Merge</ButtonText>
              </Button>
            </ButtonWrapper>

            <ButtonWrapper>
              <Button type="secondary" onClick={onCancel}>
                <ButtonText>Cancel</ButtonText>
              </Button>
            </ButtonWrapper>
          </Buttons>
        </Content>
      </ModalWindow>
    </Container>
  );
};

export default MergeTagsModal;
