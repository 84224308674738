import React from 'react';
import { Icon16 } from '../../Icons/Icon';

import { Container, Text } from './styles';

interface AIButtonProps {
  onClick?(): void;
}

const AIButton: React.FC<AIButtonProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <Icon16.Insight />
      <Text>AI</Text>
    </Container>
  );
};

export default AIButton;
