import styled from 'styled-components';

export const Container = styled.div<{ withToolbarMargin?: boolean }>`
  position: sticky;
  top: ${({ withToolbarMargin }) => (withToolbarMargin ? '107px' : 0)};
  z-index: 3;
  width: 100%;
  padding: 4px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<{ isActive?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  outline: none;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  margin-right: 2px;
  border: none;
  border-radius: 6px;
  transition: background-color 0.2s ease;

  &:last-child {
    margin: 0;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: rgba(59, 38, 81, 0.4);
  `}

  ${({ isActive }) =>
    isActive &&
    `
    background-color: rgba(133, 95, 168, 0.1);
  `}
`;

export const GenerateSummaryButton = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-right: 8px;
  background: linear-gradient(180deg, #e7dbfb 0%, #e4e8fe 100%);
  border-radius: 50px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
`;
