import React, { useContext, useState } from 'react';
import Button from '../../Button';

import {
  ButtonWrapper,
  Container,
  Content,
  ContentForm,
  Step,
  Subtitle,
  Title,
  SampleVideoButton,
  SampleVideoDescription,
  SampleVideoPreview,
  SampleVideoSubtitle,
  SampleVideoTitle,
  ArrowIconWrapper,
  SkipButton,
  Notification,
} from './styles';

import { Icon24 } from '../../../Icons/Icon';
import FileUploader from '../../FileUploader';
import { UploadFilesContext } from '../../../Context/UploadFilesContext';

type ImportDataStepProps = {
  onContinue: (files?: File[] | null, options?: { skip: boolean }) => void;
  totalSteps: number;
};

const ImportDataStep = ({ onContinue, totalSteps }: ImportDataStepProps): JSX.Element => {
  const [currentFiles, setCurrentFiles] = useState<File[] | null>(null);
  const { removeUploadingFile, removeAllUploadingFiles } = useContext(UploadFilesContext);

  const handleCancelFile = (fileName?: string) => {
    if (!currentFiles?.length) return;

    if (fileName) {
      removeUploadingFile(fileName);
      setCurrentFiles((currentFiles) =>
        currentFiles ? currentFiles.filter((currentFile) => currentFile.name !== fileName) : []
      );
    } else {
      setCurrentFiles(null);
      removeAllUploadingFiles();
    }
  };

  return (
    <Container>
      <Content>
        <Step>{`Step 3 of ${totalSteps}`}</Step>
        <Title>Add data to instantly discover insights</Title>
        <Subtitle>
          Upload all of your latest video recordings, research notes, or documents at once.
        </Subtitle>

        <ContentForm size="big">
          <FileUploader
            currentFiles={currentFiles}
            onCurrentFilesChange={setCurrentFiles}
            onCancelFile={handleCancelFile}
            googleDriveEnabled={false}
            zoomEnabled={false}
            hideFaq
          />
        </ContentForm>
      </Content>

      <Notification>Your data belongs to you and isn’t used for training purposes.</Notification>

      {!!currentFiles?.length && (
        <ButtonWrapper>
          <Button
            size={'lg'}
            className="w-full flex justify-center"
            onClick={() => onContinue(currentFiles)}
          >
            Continue
          </Button>
        </ButtonWrapper>
      )}

      {!currentFiles?.length && (
        <SampleVideoButton onClick={() => onContinue()}>
          <SampleVideoPreview />
          <SampleVideoDescription>
            <SampleVideoTitle>Not ready yet?</SampleVideoTitle>
            <SampleVideoSubtitle>
              Continue with a sample video
              <ArrowIconWrapper>
                <Icon24.ArrowLeft />
              </ArrowIconWrapper>
            </SampleVideoSubtitle>
          </SampleVideoDescription>
        </SampleVideoButton>
      )}

      <SkipButton onClick={() => onContinue(null, { skip: true })}>Skip this step</SkipButton>
    </Container>
  );
};

export default ImportDataStep;
