import React, { FC, useEffect, useMemo } from 'react';

import {
  Container,
  SelectedContainer,
  Title,
  Icon,
  Menu,
  MenuItem,
  ProjectLogo,
  MenuItemLabel,
  MenuItemContainer,
  LogoWithLabel,
  TitlePlaceholder,
  TitleWrapper,
  TitleExtraNumber,
  SearchInputContainer,
  SearchInputWrapper,
  SearchIcon,
  AllProjectsContainer,
  AllProjectsLabel,
} from './styles';
import { Icon16, Icon24 } from '../../Icons/Icon';
import Portal from '../PortalNew/PortalNew';
import { Checkbox } from '@chakra-ui/react';
import useOrgLogo from '../../Hooks/useOrgLogo';
import { Folder } from '../../Hooks/useBoards';
import Input from '../Input/Input';
import { ID } from '../../Models';
import DropdownFolder from './DropdownFolder';

export type ProjectDropDownOption = {
  label: string;
  value: string;
  icon?: string;
};

type TitleWithIcon = {
  title: string;
  icon?: string;
};

type TitleObject = {
  titles: TitleWithIcon[];
  more?: number;
};

interface ProjectsDropDownProps {
  folderOptions: { folderId: ID; folderName: string; options: ProjectDropDownOption[] }[];
  projectOptions: ProjectDropDownOption[];
  currentOptions: ProjectDropDownOption[];
  folders?: Folder[] | null;
  onChange: any;
  type?: 'regular' | 'ghost';
}

const ProjectsDropDown: FC<ProjectsDropDownProps> = ({
  projectOptions,
  folderOptions,
  currentOptions,
  onChange,
  type,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const orgLogo = useOrgLogo();
  const [searchText, setSearchText] = React.useState('');
  const [currentProjectOptions, setCurrentProjectOptions] = React.useState<ProjectDropDownOption[]>(
    []
  );
  const [currentFolderOptions, setCurrentFolderOptions] = React.useState<
    { folderId: ID; folderName: string; options: ProjectDropDownOption[] }[]
  >([]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        containerRef.current &&
        menuRef.current &&
        !containerRef.current.contains(e.target as Node) &&
        !menuRef.current.contains(e.target as Node)
      ) {
        e.stopPropagation();
        setIsOpen(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!searchText) {
      setCurrentProjectOptions(projectOptions);
      setCurrentFolderOptions(folderOptions);
      return;
    }
    const filteredProjectOptions = projectOptions.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredFolderOptions = folderOptions
      .map((folder) => {
        if (folder.folderName.toLowerCase().includes(searchText.toLowerCase())) return folder;
        return {
          ...folder,
          options: folder.options.filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
          ),
        };
      })
      .filter((folder) => folder.options.length > 0);
    setCurrentProjectOptions(filteredProjectOptions);
    setCurrentFolderOptions(filteredFolderOptions);
  }, [searchText, projectOptions, folderOptions]);

  const getTitleWithIcon = useMemo((): TitleObject => {
    const totalFolderProjectsNumber = folderOptions.reduce((acc, folder) => {
      return acc + folder.options.length;
    }, 0);
    if (currentOptions?.length === projectOptions.length + totalFolderProjectsNumber)
      return { titles: [{ title: 'All projects', icon: orgLogo }] };

    return {
      titles:
        currentOptions?.slice(0, 2).map((option) => ({
          title: option.label || 'Untitled project',
          icon: option.icon,
        })) || [],
      more: currentOptions?.slice(2, currentOptions.length).length,
    };
  }, [currentOptions, projectOptions, folderOptions, orgLogo]);

  const toggleOption = (value: ID) => {
    onChange((currentOptions: any) => {
      const allOptions = [
        ...currentProjectOptions,
        ...currentFolderOptions.flatMap((folder) => folder.options),
      ];
      if (currentOptions?.find((option: any) => option.value === value)) {
        return currentOptions?.filter((option: any) => option.value !== value);
      }
      const optionToAdd = allOptions.find((option) => option.value === value);
      return optionToAdd ? [...currentOptions, optionToAdd] : currentOptions;
    });
  };

  const toggleFolderCheckbox = (folderId: ID, checked: boolean) => {
    onChange((currentOptions: any) => {
      const folder = currentFolderOptions.find((folder: any) => folder.folderId === folderId);
      if (!folder) return currentOptions;
      if (checked) {
        const optionsToAdd = folder.options.filter(
          (folderOption) =>
            !currentOptions.find((option: any) => option.value === folderOption.value)
        );
        return [...currentOptions, ...optionsToAdd];
      } else {
        const folderOptionIds = folder.options.map((option) => option.value);
        return currentOptions?.filter((option: any) => !folderOptionIds.includes(option.value));
      }
    });
  };

  const bottom = containerRef.current?.getBoundingClientRect().bottom || 0;
  const right = window.innerWidth - (containerRef.current?.getBoundingClientRect()?.right || 0);
  const allProjectsAreChecked = useMemo(() => {
    return (
      currentFolderOptions.every((folder) =>
        folder.options.every((folderOption) =>
          currentOptions.find((option) => option.value === folderOption.value)
        )
      ) &&
      currentProjectOptions.every((option) =>
        currentOptions.find((currentOption) => currentOption.value === option.value)
      )
    );
  }, [currentFolderOptions, currentOptions, currentProjectOptions]);

  const someProjectsAreChecked = useMemo(() => {
    return (
      !allProjectsAreChecked &&
      (currentFolderOptions.some((folder) =>
        folder.options.some((folderOption) =>
          currentOptions.find((option) => option.value === folderOption.value)
        )
      ) ||
        currentProjectOptions.some((option) =>
          currentOptions.find((currentOption) => currentOption.value === option.value)
        ))
    );
  }, [allProjectsAreChecked, currentFolderOptions, currentOptions, currentProjectOptions]);

  const toggleAllTags = (checked: boolean) => {
    onChange((currentOptions: any) => {
      if (checked) {
        const optionsToAdd = currentProjectOptions.filter(
          (option) =>
            !currentOptions.find((currentOption: any) => currentOption.value === option.value)
        );
        const folderOptionsToAdd = currentFolderOptions.flatMap((folder) =>
          folder.options.filter(
            (folderOption) =>
              !currentOptions.find(
                (currentOption: any) => currentOption.value === folderOption.value
              )
          )
        );
        return [...currentOptions, ...optionsToAdd, ...folderOptionsToAdd];
      }
      return [];
    });
  };

  return (
    <Container ref={containerRef}>
      <SelectedContainer onClick={() => setIsOpen(!isOpen)} type={type}>
        {getTitleWithIcon.titles?.length ? (
          <TitleWrapper>
            {getTitleWithIcon.titles.map((titleWithIcon, index) => (
              <TitleWrapper key={index}>
                {titleWithIcon.icon && <ProjectLogo src={titleWithIcon.icon} />}
                <Title isShort={getTitleWithIcon.titles.length > 1}>{titleWithIcon.title}</Title>
              </TitleWrapper>
            ))}

            {!!getTitleWithIcon.more && (
              <TitleExtraNumber>+{getTitleWithIcon.more}</TitleExtraNumber>
            )}
          </TitleWrapper>
        ) : (
          <TitlePlaceholder>Select a project</TitlePlaceholder>
        )}
        <Icon isOpen={isOpen}>
          <Icon16.Arrow />
        </Icon>
      </SelectedContainer>

      <Portal wrapperId="SearchProjectsDropdown">
        <Menu isOpen={isOpen} right={right} top={bottom} ref={menuRef}>
          <SearchInputWrapper>
            <SearchInputContainer>
              <SearchIcon>
                <Icon24.Search />
              </SearchIcon>
              <Input
                inputType="ghost"
                placeholder="Search for a folder or project"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                smallSize
              />
            </SearchInputContainer>
          </SearchInputWrapper>

          <AllProjectsContainer>
            <AllProjectsLabel onClick={() => toggleAllTags(!allProjectsAreChecked)}>
              All projects
            </AllProjectsLabel>
            <Checkbox
              colorScheme="purple"
              icon={someProjectsAreChecked ? <Icon16.Dash /> : undefined}
              isChecked={someProjectsAreChecked || allProjectsAreChecked}
              onChange={(e) => {
                e.stopPropagation();
                toggleAllTags(e.target.checked);
              }}
            />
          </AllProjectsContainer>

          {currentFolderOptions.map((folder) => (
            <DropdownFolder
              key={folder.folderId}
              folder={folder}
              currentOptions={currentOptions}
              onChangeFolderCheckbox={(folderId, checked) =>
                toggleFolderCheckbox(folderId, checked)
              }
              onChangeProjectCheckbox={(value) => toggleOption(value)}
            />
          ))}
          {currentProjectOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                toggleOption(option.value);
              }}
            >
              <MenuItemContainer>
                <LogoWithLabel>
                  {option.icon && <ProjectLogo src={option.icon} />}
                  <MenuItemLabel withMargin>{option.label}</MenuItemLabel>
                </LogoWithLabel>
                <Checkbox
                  colorScheme="purple"
                  isChecked={currentOptions.some(
                    (currentOption) => option.value === currentOption.value
                  )}
                  onChange={(e) => toggleOption(option.value)}
                />
              </MenuItemContainer>
            </MenuItem>
          ))}
        </Menu>
      </Portal>
    </Container>
  );
};

export default ProjectsDropDown;
