import { useAuth0 } from '@auth0/auth0-react';

export const featureNameSlack = 'slack';
export const featureNameGoogle = 'google';
export const featureNameOneDrive = 'onedrive';

export default function useFeatureFlags() {
  const { user } = useAuth0();

  function isFeatureEnabled(featureName: string) {
    const isNotablyEmail = /notably\.ai/.test(user?.email ?? '');

    if (import.meta.env.DEV) {
      return true;
    }
    if (featureName === 'zoom') {
      // Enable zoom for everyone
      return true;
    }

    if (featureName === featureNameGoogle) {
      return (
        (user && user['https://notably.ai/claims/google_integration_enabled']) || isNotablyEmail
      );
    }

    if (featureName === featureNameSlack) {
      return true;
    }

    return isNotablyEmail;
  }

  return {
    isFeatureEnabled,
  };
}
