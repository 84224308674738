import React, { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

type DropDownMenuProps = {
  children: ReactNode;
  button?: ReactNode;
  alignLeft?: boolean;
  icon?: ReactNode;
  dropDownWidth?: string;
};

export function DropDownMenu({
  icon,
  children,
  button,
  alignLeft,
  dropDownWidth,
}: DropDownMenuProps): JSX.Element {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="h-full"
          >
            {button || (
              <Menu.Button className="flex items-center hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                <>
                  <span className="sr-only">Open options</span>
                  {icon ? icon : <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />}
                </>
              </Menu.Button>
            )}
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items
              static
              className={`z-100 origin-top-right absolute ${!alignLeft ? 'right-0' : ''} mt-1 ${
                dropDownWidth || 'w-56'
              } shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
            >
              <div
                style={{ border: '1px solid #E5E5E5', boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)' }}
              >
                {children}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
