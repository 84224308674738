const languageOptions = [
  {
    label: 'English, US',
    value: 'en-US',
  },
  {
    label: 'English, British',
    value: 'en-GB',
  },
  {
    label: 'Arabic',
    value: 'ar-SA',
  },
  {
    label: 'Chinese, Simplified',
    value: 'zh-CN',
  },
  {
    label: 'Chinese, Traditional',
    value: 'zh-TW',
  },
  {
    label: 'Danish',
    value: 'da-DK',
  },
  {
    label: 'Dutch',
    value: 'nl-NL',
  },
  {
    label: 'English, Australian',
    value: 'en-AU',
  },
  {
    label: 'English, Indian',
    value: 'en-IN',
  },
  {
    label: 'English, Irish',
    value: 'en-IE',
  },
  {
    label: 'English, New Zealand',
    value: 'en-NZ',
  },
  {
    label: 'English, Scottish',
    value: 'en-AB',
  },
  {
    label: 'English, South African',
    value: 'en-ZA',
  },
  {
    label: 'English, Welsh',
    value: 'en-WL',
  },
  {
    label: 'French',
    value: 'fr-FR',
  },
  {
    label: 'French, Canadian',
    value: 'fr-CA',
  },
  {
    label: 'German',
    value: 'de-DE',
  },
  {
    label: 'German, Swiss',
    value: 'de-CH',
  },
  {
    label: 'Hindi, Indian',
    value: 'hi-IN',
  },
  {
    label: 'Indonesian',
    value: 'id-ID',
  },
  {
    label: 'Italian',
    value: 'it-IT',
  },
  {
    label: 'Japanese',
    value: 'ja-JP',
  },
  {
    label: 'Korean',
    value: 'ko-KR',
  },
  {
    label: 'Portuguese',
    value: 'pt-PT',
  },
  {
    label: 'Portuguese, Brazilian',
    value: 'pt-BR',
  },
  {
    label: 'Russian',
    value: 'ru-RU',
  },
  {
    label: 'Spanish',
    value: 'es-ES',
  },
  {
    label: 'Spanish, US',
    value: 'es-US',
  },
  {
    label: 'Swedish',
    value: 'sv-SE',
  },
  {
    label: 'Thai',
    value: 'th-TH',
  },
  {
    label: 'Turkish',
    value: 'tr-TR',
  },
  {
    label: 'Vietnamese',
    value: 'vi-VN',
  },
];

export const languageNames = [
  'English',
  'Arabic',
  'Chinese, Simplified',
  'Chinese, Traditional',
  'Danish',
  'Dutch',
  'French',
  'German',
  'Hindi',
  'Indonesian',
  'Italian',
  'Japanese',
  'Korean',
  'Portuguese',
  'Russian',
  'Spanish',
  'Swedish',
  'Thai',
  'Turkish',
  'Vietnamese',
];

export const languageNameOptions = languageNames.map((name) => ({
  label: name,
  value: name,
}));

export default languageOptions;
