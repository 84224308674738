import React, { FC } from 'react';
import PopupManager from '../PopupManager';

const TopLevelLayer: FC = () => {
  return (
    <>
      <PopupManager />
    </>
  );
};

export default TopLevelLayer;
