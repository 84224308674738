import { gql } from '@apollo/client/core';

export const GET_DASHBOARD_SHAREID = gql`
  query getDashboardShareId($dashboardId: BigInt!) {
    dashboard(id: $dashboardId) {
      id
      shareId
    }
    currentOrganization {
      id
    }
  }
`;
