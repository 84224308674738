import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import NoteBlockComponent from './NoteBlockComponent';

export interface NoteBlockOptions {
  HTMLAttributes: Record<string, any>;
}

const NoteBlock = Node.create<NoteBlockOptions>({
  name: 'noteBlock',
  group: 'block',
  content: 'inline*',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },
      dashboardId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-dashboard-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.dashboardId) {
            return {};
          }

          return {
            'data-dashboard-id': attributes.dashboardId,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `note`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['note', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(NoteBlockComponent);
  },
});

export default NoteBlock;
