import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const IconContainer = styled.div`
  margin-right: 5px;
  color: rgba(59, 38, 81, 0.4);
`;

export const ReactionsNumber = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.8);
  margin-right: 5px;
`;

export const TitleText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
`;

export const Tooltip = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  padding: 10px 8px;
  border-radius: 20px;
  background-color: #3b2651;
  left: 0;
  bottom: 40px;
  white-space: nowrap;
`;

export const TooltipName = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #ffffff;
  flex-shrink: 0;
  margin-right: 4px;
`;

export const TooltipMessage = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
  flex-shrink: 0;
`;

export const Reaction = styled.div<{ isActive?: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #d7d3dc;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
    background-color: rgba(56, 33, 82, 0.08);
  `}

  &:hover ${Tooltip} {
    display: flex;
  }
`;

export const Author = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3b2651;
  margin-right: 5px;
`;

export const Emojis = styled.div<{ withLeftMargin?: boolean }>`
  display: flex;
  align-items: center;
  ${({ withLeftMargin }) => withLeftMargin && `margin-left: 5px;`}
`;

export const Emoji = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
`;

export const NewEmojiIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export const Avatars = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div<{ multiple: boolean; isActive: boolean }>`
  border: 1px solid transparent;
  ${({ multiple, isActive }) =>
    multiple &&
    `
    border-radius: 50%;
    border: 1px solid ${isActive ? '#EFEEF1' : '#ffffff'} ;

    &:last-child {
      margin-right: 5px;
    }

    &:not(:first-child) {
      margin-left: -5px;
    }
  `}
`;
