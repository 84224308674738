import React, { FC } from 'react';

interface Props {
  children?: React.ReactNode;
}

const Icon48: FC<Props> = ({ children }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

const Upload: FC = () => {
  return (
    <Icon48>
      <circle cx="24" cy="24" r="20.0156" stroke="currentColor" strokeWidth="1.96875" />
      <path
        d="M31.2727 21.1364L23.6364 13.5M23.6364 13.5L16 21.1364M23.6364 13.5V36.4091"
        stroke="#382152"
        strokeWidth="1.99219"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon48>
  );
};

export default { Upload };
