import styled from 'styled-components';

export const Filters = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding: 0 20px;
`;

export const Container = styled.div`
  /* min-height: 394px; */
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
`;

export const CloseButton = styled.div``;

export const DatePickerInput = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 40px;
  background: rgba(56, 33, 82, 0.08);
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`;

export const DatePickerText = styled.div`
  margin: 0 5px;
`;

export const FilterSearch = styled.div`
  width: 100%;
  margin-right: 10px;
`;

export const FilterDate = styled.div`
  flex-shrink: 0;
  cursor: pointer;
`;

export const RecordingsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 10px 10px 10px;
  overflow-y: auto;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  min-height: 300px;
  max-height: 460px;
`;

export const Recording = styled.div`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #d7d3dc;
  background: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const RecordingHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const RecordingInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: -3px;
`;

export const RecordingTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const RecordingDate = styled.div`
  color: rgba(59, 38, 81, 0.6);
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  margin-bottom: 5px;

  &:last-child {
    margin: 0;
  }
`;

export const Video = styled.video`
  border-radius: 6px;
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  border-top: 1px solid #d7d3dc;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  max-width: 260px;
  margin-bottom: 20px;
`;

export const SelectTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin-right: 5px;
`;

export const LoaderContainer = styled.div`
  margin: 40px;
  min-height: 340px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
