import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useSegment } from 'react-segment-hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  EmptyStateButton,
  EmptyStateText,
  EmptyState,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import pageNotFoundImage from '../assets/empty-states/404.png';

const Container = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
`;

const BackButton = styled(EmptyStateButton)`
  padding: 8px;
`;

const LinksContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Link = styled.a`
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: rgba(56, 33, 82, 0.6);
  margin: 0 10px;
`;

export default function NotFoundPage() {
  const { show } = useIntercom();

  const analytics = useSegment();
  const history = useHistory();

  useEffect(() => {
    analytics.page({
      name: '404',
    });
  }, []);

  return (
    <Container>
      <EmptyState>
        <HeroImage src={pageNotFoundImage}></HeroImage>
        <EmptyStateTitle>Oops. Something’s not right</EmptyStateTitle>
        <EmptyStateText>
          We can’t find the page you’re looking for. Here are some helpful links:
        </EmptyStateText>
        <LinksContainer>
          <Link href="#" onClick={() => show()}>
            Support
          </Link>
          <Link href="https://www.notably.ai/blog">Blog</Link>
        </LinksContainer>
        <BackButton onClick={() => history.push(`/`)}>Back to your workspace</BackButton>
      </EmptyState>
    </Container>
  );
}
