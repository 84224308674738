import React from 'react';

export default function Badge({
  name,
  color,
  className,
}: {
  name: string;
  color?: string;
  className?: string;
}): JSX.Element {
  return (
    <span
      className={
        'inline-flex items-center px-2 py-0.5 bg-primary-purple rounded-sm text-sm text-primary-purple mr-0.5 cursor-pointer ' +
          className ?? ''
      }
      style={{
        backgroundColor: color,
      }}
    >
      {name}
    </span>
  );
}
