import React from 'react';
import { Link } from 'react-router-dom';
import ZoomLogo from '../../../assets/integrations/zoom-logo.svg';
import SlackLogo from '../../../assets/integrations/slack-logo.svg';
import GoogleLogo from '../../../assets/integrations/gdrive-logo.svg';
import OneDriveLogo from '../../../assets/integrations/onedrive-logo.svg';
import { SLACK, ZOOM, GOOGLE, ONEDRIVE } from './constants';
import { getZoomPublishableUrl } from '../../../Utils/zoomUtils';
import { slackAuthorizationURL } from '../../../Utils/slackUtils';
import { SlackLongDescription } from '../SlackLongDescription';
import { getOneDrivePublishableUrl } from '../../../Utils/oneDriveUtils';

interface IntegrationUrlMap {
  [key: string]: (redirectUri: string) => string;
}

interface IntegrationLogoMap {
  [key: string]: string;
}

interface IntegrationDescriptionMap {
  [key: string]: string | JSX.Element;
}

export function getSupportHyperlinkText(link: string, text: string): JSX.Element {
  return (
    <a href={link} style={{ textDecoration: 'underline' }}>
      {text}
    </a>
  );
}

const integrationLongDescriptionMap: IntegrationDescriptionMap = {
  [ZOOM]: (
    <React.Fragment>
      This integration allows you to browse your recent recordings from Zoom cloud and import them
      directly into any Notably project. We automatically process those recordings into a
      transcript.
      <br />
      <br />
      Check out{' '}
      {getSupportHyperlinkText(
        'https://help.notably.ai/en/articles/5698448-how-to-use-the-notably-zoom-integration',
        'this support article'
      )}{' '}
      to see how this integration works.
    </React.Fragment>
  ),
  [ONEDRIVE]: (
    <React.Fragment>
      This integration allows you to browse your recent recordings from Microsoft Teams via OneDrive and import them
      directly into any Notably project. We automatically process those recordings into a
      transcript.
    </React.Fragment>
  ),
  [SLACK]: <SlackLongDescription />,
  [GOOGLE]: (
    <React.Fragment>
      This integration allows you to browse your recent video files in Google Drive and import them
      directly into any Notably project. We automatically process those recordings into a
      transcript.
      <br />
    </React.Fragment>
  ),
};

const integrationShortDescriptionMap: IntegrationDescriptionMap = {
  [ZOOM]: (
    <React.Fragment>
      Import video recordings from Zoom cloud into data.{' '}
      <Link to="/settings/integrations/Zoom">
        <span style={{ textDecoration: 'underline' }}>Learn More</span>
      </Link>
    </React.Fragment>
  ),
  [ONEDRIVE]: (
    <React.Fragment>
      Import video recordings from OneDrive, Sharepoint, and Microsoft Teams into data.
    </React.Fragment>
  ),
  [SLACK]: (
    <React.Fragment>Get instant notifications for new video summaries.</React.Fragment>
  ),
  [GOOGLE]: <React.Fragment>Import video recordings from Google drive into data.</React.Fragment>,
};

const integrationsLogoMap: IntegrationLogoMap = {
  [ZOOM]: ZoomLogo,
  [SLACK]: SlackLogo,
  [GOOGLE]: GoogleLogo,
  [ONEDRIVE]: OneDriveLogo,
};

const integrationsUrlMap: IntegrationUrlMap = {
  [ZOOM]: getZoomPublishableUrl,
  [ONEDRIVE]: getOneDrivePublishableUrl,
  [SLACK]: () => slackAuthorizationURL,
};

export function getIntegrationLogo(name: string): string {
  return integrationsLogoMap[name.toLocaleLowerCase()];
}

export function getPublishableUrl(name: string, redirectUri: string): string {
  return integrationsUrlMap[name.toLocaleLowerCase()](redirectUri);
}

export function getIntegrationLongDescription(integrationName: string): string | JSX.Element {
  return integrationLongDescriptionMap[integrationName.toLocaleLowerCase()];
}

export function getIntegrationShortDescription(integrationName: string): string | JSX.Element {
  return integrationShortDescriptionMap[integrationName.toLocaleLowerCase()];
}
