import styled from 'styled-components';

export const Container = styled.div<{ show: boolean }>`
  position: absolute;
  right: 20px;
  bottom: 20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;

  ${({ show }) =>
    show &&
    `
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  `}
`;
