import React, { MouseEventHandler } from 'react';
import { Popover } from '@headlessui/react';
import { PhotographIcon } from '@heroicons/react/outline';

import Button from '../Button';
import { defaultUserImages } from '../../Consts/userImages';
import { CoverProps } from '../../Models';

type UserPhotoPopoverProps = {
  classNames?: string;
  title: string;
  position: string;
  isSettingsPage?: boolean;
  onImageClick: (image: CoverProps) => MouseEventHandler<HTMLDivElement>;
};

const UserPhotoPopover = ({
  classNames,
  title,
  position,
  isSettingsPage = false,
  onImageClick,
}: UserPhotoPopoverProps): JSX.Element => {
  return (
    <Popover className="relative">
      <Popover.Button className="">
        <Button className={classNames} type="secondary">
          {isSettingsPage && <PhotographIcon className="w-5 h-5 mr-1" />}
          {title}
        </Button>
      </Popover.Button>
      <Popover.Panel
        className={
          'absolute bg-white rounded-sm-md p-3' + (position === 'right' ? ' right-0' : ' left-0')
        }
        style={{
          width: '275px',
          boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #D7D3DC',
        }}
      >
        <div className="grid grid-cols-4 gap-2">
          {defaultUserImages.map((image, index) => {
            return (
              <div
                key={`cover-image${index}`}
                className="hover:cursor-pointer"
                onClick={onImageClick(image)}
              >
                <img
                  src={image.url}
                  alt="cover image"
                  style={{
                    width: 56,
                    height: 56,
                    objectFit: 'cover',
                  }}
                />
              </div>
            );
          })}
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default UserPhotoPopover;
