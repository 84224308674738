import { toast } from 'react-toastify';
import useTranscripts from './useTranscripts';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import useFiles from './useFiles';
import axios from 'axios';
import { getFileType } from '../Utils/fileTypes';
import { UploadFilesContext } from '../Context/UploadFilesContext';
import { useIntercom } from 'react-use-intercom';
import { TPrompt } from './usePrompts';
import { CloudFile } from '../Models';
import { useMutation } from '@apollo/client';
import { UPLOAD_FROM_GOOGLE_TO_S3, UPLOAD_FROM_ONEDRIVE_TO_S3 } from '../GraphQL/mutations';
import { useCreateDocument } from './useDocuments';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { generateJSON } from '@tiptap/react';

export const useUploadFromGoogleToS3 = (): ((
  cloudFileId: string,
  fileId: string,
  fileName: string
) => Promise<{ success: boolean }>) => {
  const [uploadFromGoogleToS3Mutation] = useMutation(UPLOAD_FROM_GOOGLE_TO_S3);

  return async (cloudFileId: string, fileId: string, fileName: string) => {
    const res = await uploadFromGoogleToS3Mutation({
      variables: {
        cloudFileId,
        fileId,
        fileName,
      },
    });
    return res?.data?.uploadFromGoogleToS3;
  };
};

export const useUploadFromOneDriveToS3 = (): ((
  cloudFileId: string,
  fileId: string,
  fileName: string
) => Promise<{ success: boolean }>) => {
  const [uploadFromOneDriveToS3Mutation] = useMutation(UPLOAD_FROM_ONEDRIVE_TO_S3);

  return async (cloudFileId: string, fileId: string, fileName: string) => {
    const res = await uploadFromOneDriveToS3Mutation({
      variables: {
        cloudFileId,
        fileId,
        fileName,
      },
    });
    return res?.data?.uploadFromOneDriveToS3;
  };
};

export default function useUpload() {
  const { srtToTiptap, createTranscript } = useTranscripts();
  const [createDocument] = useCreateDocument();
  const { createFile, uploadFileToS3, importFile, updateFile } = useFiles();
  const { setRequestCancelSource } = useContext(UploadFilesContext);
  const uploadFromGoogleToS3 = useUploadFromGoogleToS3();
  const uploadFromOneDriveToS3 = useUploadFromOneDriveToS3();

  const intercom = useIntercom();

  const history = useHistory();

  function handleTranscriptUpload(
    dashboardId: string,
    file: File,
    options?: { redirectAfterUpload: boolean; template?: TPrompt | null }
  ) {
    const toastId = toast.loading(<>Uploading transcript please wait...</>);

    if (!file.name.endsWith('.srt') && !file.name.endsWith('.vtt')) {
      toast.update(toastId, {
        render: <>Only .srt and .vtt files are supported!</>,
        type: 'error',
        isLoading: false,
        autoClose: 1000,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      if (!event.target?.result) {
        return;
      }

      const srt = event.target.result as string;
      try {
        createTranscript(dashboardId, {
          name: file.name.split('/').pop(),
          text: JSON.stringify(srtToTiptap(srt, options?.template)),
        }).then((transcript) => {
          toast.update(toastId, {
            render: <>Successfully uploaded transcript!</>,
            type: 'success',
            isLoading: false,
            autoClose: 1000,
          });

          if (options?.redirectAfterUpload) {
            history.push(`/projects/${dashboardId}/data/${transcript.id}`);
          }
        });
      } catch (e) {
        intercom.showNewMessage(
          `Hi, I'm having trouble uploading a transcript. Please help me out.`
        );
        throw e;
      }
    };

    reader.readAsText(file);
  }

  async function handleDocxUpload(
    dashboardId: string,
    file: File,
    options?: { redirectAfterUpload: boolean; template?: TPrompt | null }
  ) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target?.result) {
        return;
      }
      const mammoth = await import('mammoth');
      const result = await mammoth.convertToHtml({
        arrayBuffer: event.target.result as ArrayBuffer,
      });
      const jsonContent = generateJSON(result.value, [
        StarterKit,
        Table,
        TableCell,
        TableRow,
        TableHeader,
        Image,
      ]).content;

      if (options?.template) {
        jsonContent.unshift({
          type: 'summary',
          content: [],
          attrs: {
            id: Math.floor(Math.random() * Date.now()).toString(16),
            templateTitle: options.template.title,
            templateId: options.template.id,
            status: 'readyToStartGeneration',
          },
        });
      }

      const document = await createDocument(dashboardId, {
        content: JSON.stringify({ type: 'doc', content: jsonContent }),
        name: file.name,
        folderId: null,
      });

      if (options?.redirectAfterUpload) {
        history.push(`/projects/${dashboardId}/data/docs/${document.id}`);
      }
    };
    reader.readAsArrayBuffer(file);
  }

  async function handleTxtUpload(
    dashboardId: string,
    file: File,
    options?: { redirectAfterUpload: boolean; template?: TPrompt | null }
  ) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target?.result) {
        return;
      }

      const text = event.target.result as string;
      const content: any = [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: text,
            },
          ],
        },
      ];

      if (options?.template) {
        content.unshift({
          type: 'summary',
          content: [],
          attrs: {
            id: Math.floor(Math.random() * Date.now()).toString(16),
            templateTitle: options.template.title,
            templateId: options.template.id,
            status: 'readyToStartGeneration',
          },
        });
      }

      const document = await createDocument(dashboardId, {
        content: JSON.stringify({ type: 'doc', content }),
        name: file.name,
        folderId: null,
      });

      if (options?.redirectAfterUpload) {
        history.push(`/projects/${dashboardId}/data/docs/${document.id}`);
      }
    };

    reader.readAsText(file);
  }

  async function handleFileUpload(
    dashboardId: string,
    file: File,
    options?: { onProgressChange?(progress: number): void; language?: string; aiTemplate?: string }
  ) {
    const cancel = axios.CancelToken.source();
    setRequestCancelSource(file.name, cancel);

    function updateProgress(event: ProgressEvent) {
      if (event.lengthComputable && event.type == 'progress') {
        options && options.onProgressChange && options.onProgressChange(event.loaded / event.total);
      }
    }

    try {
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        onProgress: updateProgress,
      });

      if (!result.ok) {
        toast.error('Error uploading file');
        window.Intercom('showNewMessage', 'Hi, I am having trouble uploading a file.');
        throw new Error('Error uploading file');
      }

      const res = await createFile(dashboardId, {
        name: file.name,
        type: getFileType(file.name),
        mimeType: file.type,
        status: 'PROCESSING',
        s3VideoPath: result.path,
        language: options?.language,
        aiTemplate: options?.aiTemplate,
      });

      if (res?.id) {
        await createTranscript(dashboardId, {
          name: file.name.split('/').pop(),
          fileId: res?.id,
        });
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      return;
    }
  }

  async function handleCloudFileUpload(
    dashboardId: string,
    file: CloudFile,
    options?: { language?: string; aiTemplate?: TPrompt | null }
  ) {
    try {
      if (file.source === 'google') {
        const res = await createFile(dashboardId, {
          name: file.name,
          type: 'video',
          mimeType: file.mimeType,
          status: 'PROCESSING',
          language: options?.language,
          aiTemplate: options?.aiTemplate?.id,
        });

        if (res?.id) {
          await createTranscript(dashboardId, {
            name: file.name.split('/').pop(),
            fileId: res?.id,
          });

          const result = await uploadFromGoogleToS3(file.sourceId, res.id, file.name);

          if (!result.success) {
            throw new Error('Error uploading file');
          }
        }
      }
      if (file.source === 'onedrive') {
        const res = await createFile(dashboardId, {
          name: file.name,
          type: 'video',
          mimeType: file.mimeType,
          status: 'PROCESSING',
          language: options?.language,
          aiTemplate: options?.aiTemplate?.id,
        });

        if (res?.id) {
          await createTranscript(dashboardId, {
            name: file.name.split('/').pop(),
            fileId: res?.id,
          });

          const result = await uploadFromOneDriveToS3(file.sourceId, res.id, file.name);

          if (!result.success) {
            throw new Error('Error uploading file');
          }
        }
      }
      if (file.source === 'zoom') {
        if (!file.sourceUrl || !file.status) return;
        const result = await importFile(dashboardId, file.name, file.sourceUrl, file.status);
        if (result.created) {
          await updateFile(result.file.id, {
            language: options?.language,
            aiTemplate: options?.aiTemplate?.id,
          });
          await createTranscript(dashboardId, {
            name: file.name,
            fileId: result.file.id,
            text: file.transcript
              ? JSON.stringify(srtToTiptap(file.transcript, options?.aiTemplate))
              : null,
          });
        }
      }
    } catch (e) {
      console.log(e);
      return;
    }
  }

  return {
    handleTranscriptUpload,
    handleFileUpload,
    handleCloudFileUpload,
    handleTxtUpload,
    handleDocxUpload,
  };
}
