import styled from 'styled-components';

export const Container = styled.div<{ show: boolean; ghost?: boolean }>`
  width: 100%;
  position: relative;
  background-color: #ffffff;
  cursor: pointer;
  ${({ ghost }) => !ghost && `border: 1px solid #d7d3dc`};

  ${({ show, ghost }) =>
    show
      ? `
    border-radius: 3px 3px 0 0;
  `
      : !ghost &&
        `
    border-radius: 3px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  `}
`;

export const ContentWrapper = styled.div`
  padding: 8px 8px 8px 12px;
`;

export const ItemsContainer = styled.div<{
  show: boolean;
  top: number;
  alignMenu: 'bottom' | 'right';
}>`
  width: calc(100% + 2px);
  position: absolute;
  top: ${({ top }) => top}px;
  left: -1px;

  ${({ alignMenu }) =>
    alignMenu === 'right' &&
    `
    top: 0;
    left: unset;
    right: -230px;
  `}

  background-color: #ffffff;
  padding: 5px 10px;

  ${({ show, alignMenu }) =>
    show
      ? `
    display: block;
    z-index: 2;
    ${alignMenu === 'right' ? `border-top: 1px solid #d7d3dc` : ''};
    border-bottom: 1px solid #D7D3DC;
    border-left: 1px solid #D7D3DC;
    border-right: 1px solid #D7D3DC;
    border-radius: 0 0 3px 3px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  `
      : `
    display: none;
  `}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.1s ease;
  border-radius: 3px;
  padding: 5px 4px;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }
`;

export const ItemName = styled.div<{ color?: string; selected?: boolean }>`
  ${({ selected }) => !selected && `padding: 5px;`};
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  max-width: 180px;
  overflow: hidden;
  margin-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ color }) => color && `background-color: ${color}`};

  &:last-child {
    margin: 0;
  }
`;

export const ItemInstances = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #b1a8b9;
`;

export const ManageTagsButton = styled.div`
  display: flex;
  align-items: center;
  color: #b1a8b9;
  cursor: pointer;
  padding: 10px;
`;

export const NoOptionsText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 8px 0;
  text-align: center;
`;

export const TagListTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: rgba(59, 38, 81, 0.4);
  padding: 0 0 0 5px;
`;

export const Placeholder = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(59, 38, 81, 0.4);
`;

export const PlaceholderBold = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const IconWrapper = styled.div<{ show: boolean }>`
  ${({ show }) => show && `transform: rotate(-90deg);`}
`;

export const SelectedItemsContainer = styled.div`
  padding: 5px 10px;
  background-color: rgba(56, 33, 82, 0.06);
  border-radius: 12px;
  margin-bottom: 8px;
`;

export const SelectedItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  margin-top: 8px;
`;

export const SelectedItem = styled.div<{ color?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 2px 2px 0;
  padding: 3px 24px 3px 3px;
  background-color: ${({ color }) => (color ? color : 'rgba(133, 120, 147, 0.20)')};
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  max-width: 180px;
  overflow: hidden;
  margin-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectedItemDeleteButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(133, 95, 168, 0.2);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ItemList = styled.div`
  /* margin-bottom: 8px; */
`;

export const SublistButtonWrapper = styled.div`
  position: relative;
`;

export const SublistButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 4px;
  margin-bottom: 4px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }
`;

export const SublistButtonText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const FolderPanelWrapper = styled.div<{ left: number; top: number }>`
  padding-left: 26px;
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left - 8}px;
  /* top: ${({ top }) => top}px; */
  /* left: ${({ left }) => left}px; */
  /* bottom: -46px; */
  /* right: -100%; */
  width: 100%;
  max-width: 258px;
  max-height: 192px;
  z-index: 9999;
`;

export const FolderPanel = styled.div`
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  border-radius: 3px;
  box-shadow: 0px 0px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 192px;
  overflow: auto;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTextCount = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 3px;
  background-color: rgba(56, 33, 82, 0.1);
  padding: 0 5px;
  margin-left: 5px;
`;

export const ItemIcon = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background-color: ${({ color }) => (color ? color : '#d8c0f880')};
  margin-right: 5px;
  flex-shrink: 0;
`;

export const ItemIconWithName = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  max-width: calc(100% - 14px);
`;

export const SearchField = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchIconContainer = styled.div`
  margin-right: 8px;
`;

export const Content = styled.div<{ maxHeight?: string }>`
  overflow: auto;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight + 'px' : '280px')};
`;

export const RelativeContainer = styled.div`
  position: relative;
`;
