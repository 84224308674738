import styled from 'styled-components';

export const IconContainer = styled.div`
  margin-right: 4px;
`;

export const GrayText = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(59, 38, 81, 0.4);
  text-align: center;
`;

export const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;

export const UploadButton = styled.div`
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
`;
